import dayjs from 'dayjs';
import { defineMessages } from 'react-intl';
import BackendProxy from '../logic/backendproxy';
import { Filter } from '../model/frontendmodel';
//import BackendProxy from'../components/AdminHome/Audit/AuditMock.js';
import { openDialogViewer } from './common';

export const LOAD_DEFAULT_FIELDS = 'AUDIT.LOAD_DEFAULT_FIELDS';
export const LOADING_FILTERS = 'AUDIT.LOADING_FILTERS';

export const START_LOADING_CHATS_LIST = 'AUDIT.START_LOADING_CHATS_LIST';
export const OK_LOADED_CHATS_LIST = 'AUDIT.OK_LOADED_CHATS_LIST';
export const ERROR_LOADED_CHATS_LIST = 'AUDIT.ERROR_LOADED_CHATS_LIST';

export const RESET_ALL_FILTER_VALUES = 'AUDIT.RESET_ALL_FILTER_VALUES';
export const SELECTING_CHAT_ROW = 'AUDIT.SELECTING_CHAT_ROW';

export const START_LOADING_CONTACTS_LIST = 'AUDIT.START_LOADING_CONTACTS_LIST';
export const OK_LOADED_CONTACTS_LIST = 'AUDIT.OK_LOADED_CONTACTS_LIST';
export const ERROR_LOADED_CONTACTS_LIST = 'AUDIT.ERROR_LOADED_CONTACTS_LIST';

export const START_LOADING_AGENTS_LIST = 'AUDIT.START_LOADING_AGENTS_LIST';
export const OK_LOADED_AGENTS_LIST = 'AUDIT.OK_LOADED_AGENTS_LIST';
export const ERROR_LOADED_AGENTS_LIST = 'AUDIT.ERROR_LOADED_AGENTS_LIST';

export const START_LOADING_MESSAGES_LIST = 'AUDIT.START_LOADING_MESSAGES_LIST';
export const OK_LOADED_MESSAGES_LIST = 'AUDIT.OK_LOADED_MESSAGES_LIST';
export const ERROR_LOADED_MESSAGES_LIST = 'AUDIT.ERROR_LOADED_MESSAGES_LIST';

export const TIMEFRAME_SELECTED = 'AUDIT.TIMEFRAME_SELECTED';
export const TIMEFRAME_START_DATE_SELECTED =
  'AUDIT.TIMEFRAME_START_DATE_SELECTED';
export const TIMEFRAME_END_DATE_SELECTED = 'AUDIT.TIMEFRAME_END_DATE_SELECTED';

export const DISABLE_OR_ENABLE_FILTERS = 'AUDIT.DISABLE_OR_ENABLE_FILTERS';
export const SHOW_OR_HIDE_DATE_RANGE_VALIDATION =
  'AUDIT.SHOW_OR_HIDE_DATE_RANGE_VALIDATION';

const messages = defineMessages({
  errorServerTitle: { id: 'Error.serverTitle' },
  errorServer: { id: 'Error.server' },
  errorServerButton: { id: 'Error.serverButton' },
  agentOptionALL: { id: 'AuditToolbar.agentOptionALL' },
  auditExportSuccess: { id: 'Audit.Export.Request.Success.Content' },
  auditCommonCloseButton: { id: 'AuditCommon.closeButtonLbl' },
  auditCommonDialogTitle: { id: 'AuditCommon.dialogTittle' },
});

export function changeFilters(newFilters) {
  return (dispatch, getState) => {
    dispatch(loadingFilters(newFilters));
    commonLoadChats(dispatch, getState);
  };
}

export function blurEmptyText(newText) {
  return (dispatch, getState) => {
    commonLoadChats(dispatch, getState);
  };
}

function commonLoadChats(dispatch, getState) {
  const merchantId = getState().loginAuthentication.success.employer.id;
  const { toolbar } = getState().adminHome.audit;

  const { agentId } = toolbar.filters;
  const { contactValue } = toolbar.filters;
  const { contactId } = toolbar.filters;
  const { text } = toolbar.filters;
  const { activeChat } = toolbar.filters;
  const { timeframe } = toolbar.filters;
  //var startDate = toolbar.filters.startDate;
  //var endDate = toolbar.filters.endDate;
  const startDate = toolbar.filters.timeframe_start_date;
  const endDate = toolbar.filters.timeframe_end_date;
  const searchType = toolbar.filters.search_type;

  const filter = new Filter(
    merchantId,
    agentId,
    contactValue,
    startDate,
    endDate,
    text,
    activeChat,
    timeframe,
    contactId,
    searchType
  );

  dispatch(showOrHideDateRangeValidation(false));
  if (timeframe === 'CUSTOM_TIMEFRAME') {
    if (startDate === null || endDate === null) {
      return false;
    }
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays > 60) {
      dispatch(showOrHideDateRangeValidation(true));
      return false;
    }
  }

  dispatch(startLoadingChatsList());
  dispatch(disableOrEnableFilters(true));

  BackendProxy.getChats(
    filter,
    respData => {
      console.log('OK, Chats list: ', respData);
      const chatResults = respData;

      setTimeout(() => {
        dispatch(okLoadedChatsList(chatResults));
        dispatch(disableOrEnableFilters(false));
      }, 500);
    },
    (respStatus, error) => {
      // Handle errors that might occur while uploading media
      console.log(
        `Error Getting Agent Chats! Status code: ${respStatus}`,
        error
      );
      dispatch(errorLoadedChatsList(error));
    }
  );
}

export function exportDialogView() {
  return (dispatch, getState) => {
    dispatch(
      openDialogViewer(
        true,
        intl.formatMessage(messages.auditCommonDialogTitle),
        intl.formatMessage(messages.auditCommonCloseButton),
        intl.formatMessage(messages.auditExportSuccess),
        null
      )
    );
  };
}

export function loadingFilters(filters) {
  return {
    type: LOADING_FILTERS,
    filters,
  };
}

export function changeEmptyText(filters) {
  return {
    type: LOADING_FILTERS,
    filters,
  };
}

export function loadDefaultChats() {
  return (dispatch, getState) => {
    dispatch(loadDefaultFields());
    commonLoadChats(dispatch, getState);
  };
}
export function loadDefaultFields() {
  const dates = extractDateRangeFromTimeframe('this_1_days');
  return {
    type: LOAD_DEFAULT_FIELDS,
    agentId: '',
    contactValue: '',
    startDate: dates.start,
    endDate: dates.end,
    text: '',
    activeChat: false,
    timeframe: 'this_1_days',
    timeframe_start_date: dates.start,
    timeframe_end_date: dates.end,
    custom_timeframe: false,
  };
}

/**
 * Extract date range of dates from a given timeframe
 * @timeframe: this_1_days / this_7_days / this_14_days / this_30_days / custom_timeframe
 * @start: (optional) only for custom_timeframe
 * @end: (optional) only for custom_timeframe
 * */
export function extractDateRangeFromTimeframe(timeframe, start, end) {
  const response = { start: null, end: null }; // Response object
  const currentDate = dayjs();

  response.end = currentDate.format();
  switch (timeframe) {
    case 'this_1_days':
      response.start = response.end;
      break;
    case 'this_7_days':
      response.start = currentDate.subtract(7, 'd').format();
      break;
    case 'this_14_days':
      response.start = currentDate.subtract(14, 'd').format();
      break;
    case 'this_30_days':
      response.start = currentDate.subtract(30, 'd').format();
      break;
    default:
      return null;
  }

  return response;
}

export function startLoadingChatsList() {
  return {
    type: START_LOADING_CHATS_LIST,
  };
}

export function disableOrEnableFilters(disable) {
  return {
    type: DISABLE_OR_ENABLE_FILTERS,
    disable,
  };
}
export function showOrHideDateRangeValidation(show) {
  return {
    type: SHOW_OR_HIDE_DATE_RANGE_VALIDATION,
    show,
  };
}

export function okLoadedChatsList(results) {
  return {
    type: OK_LOADED_CHATS_LIST,
    results,
    filterCount: 100,
    pageCount: 10,
    currentPage: 1,
    pageSize: 10,
  };
}
export function errorLoadedChatsList(error) {
  return {
    type: ERROR_LOADED_CHATS_LIST,
    message: error,
  };
}

export function clickResetAllFilters() {
  return {
    type: RESET_ALL_FILTER_VALUES,
    agentId: '',
    contactValue: '',
    contactId: '',
    startDate: new Date(),
    endDate: new Date(),
    text: '',
    activeChat: false,
    chats: [],
    filterCount: 0,
    pageCount: 0,
    currentPage: 0,
    pageSize: 0,
    loadStatus: null,
    messages: [],
    timeframe: 'this_1_days',
    timeframe_start_date: null,
    timeframe_end_date: null,
    custom_timeframe: false,
  };
}

//#################### Contacts Manager ##############
export function loadContacts() {
  return (dispatch, getState) => {
    dispatch(startLoadingContactsList());

    const merchantId = getState().loginAuthentication.success.employer.id;

    BackendProxy.getContacts(
      merchantId,
      respData => {
        console.log('OK, Contacts list: ', respData);
        const contacts = respData;

        setTimeout(() => {
          dispatch(okLoadedContactsList(contacts));
        }, 500);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        dispatch(errorLoadedContactsList(error));
      }
    );
  };
}

export function startLoadingContactsList() {
  return {
    type: START_LOADING_CONTACTS_LIST,
  };
}

export function okLoadedContactsList(results) {
  return {
    type: OK_LOADED_CONTACTS_LIST,
    results,
  };
}
export function errorLoadedContactsList(error) {
  return {
    type: ERROR_LOADED_CONTACTS_LIST,
    message: error,
  };
}

//#################### Agents Manager ##############
export function loadAgents() {
  return (dispatch, getState) => {
    dispatch(startLoadingAgentsList());

    const merchantId = getState().loginAuthentication.success.employer.id;

    BackendProxy.getUsersLoginState(merchantId)
      .then(result => {
        console.debug('OK, Agents Login status list:', result);
        const agentsLoginStatus = addItemToAgentList(result);

        setTimeout(() => {
          dispatch(okLoadedAgentsList(agentsLoginStatus));
        }, 1000);
      })
      .catch(errorDetails => {
        console.error('Error Getting Agents login status!', errorDetails);
        dispatch(errorLoadedAgentsList(errorDetails.message));
      });
  };
}

function addItemToAgentList(agents) {
  const label = intl.formatMessage(messages.agentOptionALL);
  agents.agents.unshift({ agent: { fullName: label } });
  return agents;
}

export function startLoadingAgentsList() {
  return {
    type: START_LOADING_AGENTS_LIST,
  };
}

export function okLoadedAgentsList(results) {
  return {
    type: OK_LOADED_AGENTS_LIST,
    results,
  };
}
export function errorLoadedAgentsList(error) {
  return {
    type: ERROR_LOADED_AGENTS_LIST,
    message: error,
  };
}

//#################### Agent_Chats_Messages Manager ##############
export function rowClick(newChatId, createdAtMilli) {
  return (dispatch, getState) => {
    dispatch(setSelectedChatId(newChatId));

    dispatch(startLoadingMessagesList());

    const chatId = getState().adminHome.audit.message.chatIdSelected;

    BackendProxy.getMessages(
      chatId,
      createdAtMilli,
      respData => {
        console.log('OK, Messages list: ', respData);
        const messages = respData;

        setTimeout(() => {
          dispatch(okLoadedMessagesList(messages));
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting Messages! Status code: ${respStatus}`,
          error
        );
        dispatch(errorLoadedMessagesList(error));
      }
    );
  };
}

export function setSelectedChatId(newChatId) {
  return {
    type: SELECTING_CHAT_ROW,
    chatIdSelected: newChatId,
  };
}

export function startLoadingMessagesList() {
  return {
    type: START_LOADING_MESSAGES_LIST,
  };
}

export function okLoadedMessagesList(results) {
  return {
    type: OK_LOADED_MESSAGES_LIST,
    results,
  };
}
export function errorLoadedMessagesList(error) {
  return {
    type: ERROR_LOADED_MESSAGES_LIST,
    message: error,
  };
}

//##########################################

export function onTimeframeSelected(timeframe) {
  const customTimeframe = timeframe === 'CUSTOM_TIMEFRAME';

  let timeframe_start_date;
  let startDate;
  let timeframe_end_date;
  let endDate;
  if (timeframe !== 'CUSTOM_TIMEFRAME') {
    const dates = extractDateRangeFromTimeframe(timeframe);
    timeframe_start_date = startDate = dates.start;
    timeframe_end_date = endDate = dates.end;
  }
  // Selected timeframe as CUSTOM_TIMEFRAMES forces a reset on dates
  else {
    timeframe_start_date = startDate = null;
    timeframe_end_date = endDate = null;
  }

  return (dispatch, getState) => {
    dispatch(
      updateTimeFrame(
        TIMEFRAME_SELECTED,
        timeframe,
        customTimeframe,
        startDate,
        endDate,
        timeframe_start_date,
        timeframe_end_date
      )
    );
    commonLoadChats(dispatch, getState);
  };
}
export function updateTimeFrame(
  type,
  timeframe,
  customTimeframe,
  startDate,
  endDate,
  timeframe_start_date,
  timeframe_end_date
) {
  return {
    type,
    timeframe,
    custom_timeframe: customTimeframe,
    startDate,
    endDate,
    timeframe_start_date,
    timeframe_end_date,
  };
}

export function onTimeframeStartDateSelected(date) {
  return (dispatch, getState) => {
    dispatch(updateTimeFrameDate(TIMEFRAME_START_DATE_SELECTED, date));
    commonLoadChats(dispatch, getState);
  };
}

export function onTimeframeEndDateSelected(date) {
  return (dispatch, getState) => {
    dispatch(updateTimeFrameDate(TIMEFRAME_END_DATE_SELECTED, date));
    commonLoadChats(dispatch, getState);
  };
}

export function updateTimeFrameDate(type, date) {
  return {
    type,
    date,
  };
}

//##########################################

export function onExport(filters) {
  return (dispatch, getState) => {
    // AgentId and ContactId must either have a value or be null
    if (filters.agentId === '') filters.agentId = null;
    if (filters.contactId === '') filters.contactId = null;

    BackendProxy.auditExport(filters, onExportSuccess, onExportFail);
  };
}

function onExportSuccess() {
  console.log('Audit export was successful');
}

function onExportFail() {
  console.error('Audit export just failed');
}
