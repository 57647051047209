import { B2ChatClientEcommerce } from '@client-sdk';

import {
  addShopifyStoreCredentials,
  addShopifyStoreCredentialsFailure,
  addShopifyStoreCredentialsFulfill,
  addShopifyStoreCredentialsSuccess,
  addShoppingCart,
  addShoppingCartFailure,
  addShoppingCartFulfill,
  addShoppingCartSuccess,
  createShopifyOrder,
  deleteShopifyStoreCredentials,
  deleteShopifyStoreCredentialsFailure,
  deleteShopifyStoreCredentialsFulfill,
  deleteShopifyStoreCredentialsSuccess,
  fetchOrderHistoryById,
  fetchOrderHistoryByIdFailure,
  fetchOrderHistoryByIdFulfill,
  fetchOrderHistoryByIdSuccess,
  fetchOrdersHistory,
  fetchOrdersHistoryFailure,
  fetchOrdersHistoryFulfill,
  fetchOrdersHistorySuccess,
  fetchShopifyCollections,
  fetchShopifyCollectionsFailure,
  fetchShopifyCollectionsFulfill,
  fetchShopifyCollectionsSuccess,
  fetchShopifyCredentials,
  fetchShopifyCredentialsFailure,
  fetchShopifyCredentialsFulfill,
  fetchShopifyCredentialsSuccess,
  fetchShopifyInventory,
  fetchShopifyInventoryFailure,
  fetchShopifyInventoryFulfill,
  fetchShopifyInventorySuccess,
  fetchShopifyShop,
  fetchShopifyShopFailure,
  fetchShopifyShopFulfill,
  fetchShopifyShopSuccess,
  fetchShopifyShoppingCart,
  fetchShopifyShoppingCartFailure,
  fetchShopifyShoppingCartFulfill,
  fetchShopifyShoppingCartSuccess,
  fetchShopifySingleProduct,
  fetchShopifySingleProductFailure,
  fetchShopifySingleProductFulfill,
  fetchShopifySingleProductSuccess,
  fetchShopifyStores,
  fetchShopifyStoresFailure,
  fetchShopifyStoresFulfill,
  fetchShopifyStoresSuccess,
  onClickSendShopifyProduct,
  removeProductFromShoppingCart,
  removeProductFromShoppingCartFailure,
  removeProductFromShoppingCartFulfill,
  saveShopifyStoreCredentials,
  saveShopifyStoreCredentialsFailure,
  saveShopifyStoreCredentialsFulfill,
  saveShopifyStoreCredentialsSuccess,
  sendShopifyCatalog,
  sendShopifyProduct,
  setShopifyCredentials,
  setShopifyCredentialsFailure,
  setShopifyCredentialsFulfill,
  setShopifyCredentialsSuccess,
  setShoppingTabView,
  shopifyProductDetails,
  updateProductShoppingCart,
  updateProductShoppingCartFailure,
  updateProductShoppingCartFulfill,
  updateSelectedLocationId,
  updateShopifyCredentialsFailure,
  updateShopifyCredentialsFulfill,
  updateShopifyCredentialsSuccess,
  updateShopifyStoresCredentials,
  updateTryToRetrieveOnlyAvailableProducts,
} from '@reducers/shopify';
import {
  selectChatContextMenuOption,
  toggleChatContextMenu,
} from '@src/actions/agentconsole';
import { SideMenuTabs } from '@src/components/AgentConsole/SideMenu';
import { MediaType } from '@src/model/frontendmodel';
import { ChatMessage, queueChatMessage } from '@src/reducers/chatMessageQueue';
import { activeChatSuccess } from '@src/reducers/chats';
import { PageInfo, ShopifyTabsView } from '@src/reducers/shopify/types';
import { activeChatIdSelector, activeChatSelector } from '@src/selectors/chats';
import { getMerchantId } from '@src/selectors/merchant';
import {
  getSearchPattern,
  getSelectedLocation,
  getSelectedStoreId,
  getShopifyCredentialsConfigShowAvailabilityFilter,
  getShopifyFetchDetailError,
  getShopifyOrderHistoryUrl,
  getShopifyOrdersHistory,
  getShopifyProductsPageInfo,
  getShopifyShoppingCart,
  getTryToRetrieveOnlyAvailableProducts,
  prepareCatalogData,
  prepareFilterParamsRequest,
  prepareProductData,
} from '@src/selectors/shopify';
import { ChatTray } from '@src/types/chats';
import {
  SendShopifyProduct,
  ShopifyProductOrigin,
  ShoppingCart,
  ShoppingCartOrder,
  ShoppingCartOrderUrl,
} from '@src/types/shopify';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { TakeableChannel, buffers } from 'redux-saga';
import {
  ActionChannelEffect,
  actionChannel,
  call,
  debounce,
  delay,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

function* fetchShopifyCredentialsSaga(
  action: ReturnType<typeof fetchShopifyCredentials>
) {
  const { merchantId } = action.payload;
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getCredentials'],
      { params: { merchantId } }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.Credential>;

    if (response.error != null) {
      yield put(fetchShopifyCredentialsFailure(response.error));
    } else {
      yield put(fetchShopifyCredentialsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchShopifyCredentialsFailure(error as ErrorData));
  } finally {
    yield put(fetchShopifyCredentialsFulfill());
  }
}

function* fetchShopifyStoresSaga() {
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getStores'],
      {}
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.Stores>;

    if (response.error != null) {
      yield put(fetchShopifyStoresFailure(response.error));
    } else {
      yield put(fetchShopifyStoresSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchShopifyStoresFailure(error as ErrorData));
  } finally {
    yield put(fetchShopifyStoresFulfill());
  }
}

function* setShopifyCredentialsSaga(
  action: ReturnType<typeof setShopifyCredentials>
) {
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'setCredentials'],

      { data: action.payload }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.Credential>;

    if (response.error != null) {
      yield put(setShopifyCredentialsFailure(response.error));
    } else {
      yield put(setShopifyCredentialsSuccess(response.data));
    }
  } catch (error) {
    yield put(setShopifyCredentialsFailure(error as ErrorData));
  } finally {
    yield put(setShopifyCredentialsFulfill());
  }
}

function* updateShopifyCredentialsSaga(
  action: ReturnType<typeof updateShopifyStoresCredentials>
) {
  const { merchantId } = action.payload;
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'updateCredentials'],

      { data: action.payload, params: { merchantId } }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.Credential>;
    if (response.error != null) {
      yield put(updateShopifyCredentialsFailure(response.error));
    } else {
      yield put(updateShopifyCredentialsSuccess(response.data));
    }
  } catch (error) {
    yield put(updateShopifyCredentialsFailure(error as ErrorData));
  } finally {
    yield put(updateShopifyCredentialsFulfill());
  }
}

function* saveShopifyStoreCredentialsSaga(
  action: ReturnType<typeof saveShopifyStoreCredentials>
) {
  const merchantId: number = yield select(getMerchantId);
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'saveCredentials'],
      { data: action.payload }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.StoreCredentials>;
    if (response.error != null) {
      yield put(saveShopifyStoreCredentialsFailure(response.error));
    } else {
      yield put(fetchShopifyCredentials({ merchantId }));
      yield put(saveShopifyStoreCredentialsSuccess(response.data));
    }
  } catch (error) {
    yield put(saveShopifyStoreCredentialsFailure(error as ErrorData));
  } finally {
    yield put(saveShopifyStoreCredentialsFulfill());
  }
}

function* addShopifyStoreCredentialsSaga(
  action: ReturnType<typeof addShopifyStoreCredentials>
) {
  const merchantId: number = yield select(getMerchantId);
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'addCredentials'],
      { data: action.payload }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.StoreCredentials>;
    if (response.error != null) {
      yield put(addShopifyStoreCredentialsFailure(response.error));
    } else {
      yield put(fetchShopifyCredentials({ merchantId }));
      yield put(addShopifyStoreCredentialsSuccess());
    }
  } catch (error) {
    yield put(addShopifyStoreCredentialsFailure(error as ErrorData));
  } finally {
    yield put(addShopifyStoreCredentialsFulfill());
  }
}

function* deleteShopifyStoreCredentialsSaga(
  action: ReturnType<typeof deleteShopifyStoreCredentials>
) {
  const merchantId: number = yield select(getMerchantId);
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'deleteCredentials'],
      { params: action.payload }
    )) as B2ChatAPI.Response<unknown>;
    if (response.error != null) {
      yield put(deleteShopifyStoreCredentialsFailure(response.error));
    } else {
      yield put(fetchShopifyCredentials({ merchantId }));
      yield put(deleteShopifyStoreCredentialsSuccess());
    }
  } catch (error) {
    yield put(deleteShopifyStoreCredentialsFailure(error as ErrorData));
  } finally {
    yield put(deleteShopifyStoreCredentialsFulfill());
  }
}

function* onClickSendShopifyProductSaga(action: {
  payload: {
    sendVariants?: boolean | undefined;
    productId: number;
    locationId: number;
  };
}) {
  const { productId, locationId, sendVariants } = action.payload;
  yield put(fetchShopifySingleProduct({ productId, locationId }));
  yield take(fetchShopifySingleProductFulfill);
  yield put(
    sendShopifyProduct({ sendVariants, origin: ShopifyProductOrigin.LIST })
  );
}

function* shopifyProductDetailsSaga(
  action: ReturnType<typeof shopifyProductDetails>
) {
  const { locationId, productId } = action.payload;
  yield put(fetchShopifySingleProduct({ locationId, productId }));
  yield take(fetchShopifySingleProductFulfill);

  const error: Error = yield select(getShopifyFetchDetailError);
  if (!error) {
    yield put(updateSelectedLocationId(locationId));
    yield put(setShoppingTabView(ShopifyTabsView.PRODUCT_DETAILS));
  }
  yield put(toggleChatContextMenu(true));
  yield put(selectChatContextMenuOption(SideMenuTabs.Shopify));
}

function* fetchShopifySingleProductSaga(
  action: ReturnType<typeof fetchShopifySingleProduct>
) {
  const { locationId, productId } = action.payload;
  const storeId: string = yield select(getSelectedStoreId);

  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getProduct'],
      {
        params: { storeId, locationId, id: productId },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.Product>;

    if (response.error != null) {
      yield put(fetchShopifySingleProductFailure(response.error));
    } else {
      yield put(fetchShopifySingleProductSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchShopifySingleProductFailure(error as ErrorData));
  } finally {
    yield put(fetchShopifySingleProductFulfill());
  }
}

function* sendShopifyProductSaga(
  action: ReturnType<typeof sendShopifyProduct>
) {
  const chat: ChatTray = yield select(activeChatSelector);
  let data: SendShopifyProduct & { origin: ShopifyProductOrigin } =
    yield select(prepareProductData);

  const { sendVariants, origin } = action.payload;
  if (!sendVariants) {
    data = {
      ...data,
      information: {
        ...data.information,
        variants: [],
      },
    };
  }
  data = {
    ...data,
    origin,
  };

  yield put(queueChatMessage(data, chat.id, chat.provider));
}

function* sendShopifyCatalogSaga() {
  const chat: ChatTray = yield select(activeChatSelector);
  const text: string = yield select(prepareCatalogData);

  const data = {
    type: MediaType.CATALOG,
    text,
  };

  yield put(queueChatMessage(data as ChatMessage, chat.id, chat.provider));
}

function* fetchShopifyInventorySaga() {
  const { id: locationId } = yield select(getSelectedLocation);
  const storeId: string = yield select(getSelectedStoreId);

  const title: string = yield select(getSearchPattern);
  let params: { [key: string]: string } = yield select(
    prepareFilterParamsRequest
  );
  const showAvailabilityFilter: boolean = yield select(
    getShopifyCredentialsConfigShowAvailabilityFilter
  );

  if (showAvailabilityFilter) params.showOnlyAvailableProducts = 'true';

  if (!locationId) return;

  params = {
    ...params,
    locationId,
    storeId,
    title,
  };

  if (!title) delete params.title;

  // NOTE: this logic is intented to resolve a possible issue retrieving "only available products",
  //       due this filter is done in B2Chat endpoint, it's possible to get
  //       an empty result for a page, so this code makes at most 10 requests in order to try to get any result.
  //       This proccess is only performed once;
  let triesCounter = 10;
  let currEndCursor = '';
  const minAmountOfProducts = 10;
  const tryToRetrieveOnlyAvailableProducts: boolean = yield select(
    getTryToRetrieveOnlyAvailableProducts
  );

  function* makeRequest() {
    try {
      const response = (yield call(
        [B2ChatClientEcommerce.resources.ecommerce.actions, 'getInventory'],
        { params }
      )) as B2ChatAPI.Response<B2ChatAPI.Shopify.FetchShopifyInventory>;
      if (response.error)
        yield put(fetchShopifyInventoryFailure(response.error));
      else {
        if (response.data?.products?.length >= minAmountOfProducts) {
          triesCounter = 0;
        }
        yield put(fetchShopifyInventorySuccess(response.data));
      }
    } catch (error) {
      yield put(fetchShopifyInventoryFailure(error as ErrorData));
    } finally {
      yield put(fetchShopifyInventoryFulfill());
    }
  }

  if (
    tryToRetrieveOnlyAvailableProducts &&
    params.showOnlyAvailableProducts === 'true'
  ) {
    while (triesCounter > 0) {
      if (currEndCursor) params.endCursor = currEndCursor;
      yield makeRequest();
      yield delay(200);
      const pageInfo = (yield select(getShopifyProductsPageInfo)) as PageInfo;
      if (pageInfo) currEndCursor = pageInfo.endCursor;
      triesCounter -= 1;
    }
    yield put(updateTryToRetrieveOnlyAvailableProducts(false));
  } else {
    yield put(updateTryToRetrieveOnlyAvailableProducts(true));
    yield makeRequest();
  }
}

function* fetchShopifyShopSaga(action: ReturnType<typeof fetchShopifyShop>) {
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getShop'],
      { params: { ...action.payload } }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.FetchShopifyShop>;
    if (response.error) yield put(fetchShopifyShopFailure(response.error));
    else yield put(fetchShopifyShopSuccess(response.data));
  } catch (error) {
    yield put(fetchShopifyShopFailure(error as ErrorData));
  } finally {
    yield put(fetchShopifyShopFulfill());
  }
}

// Create cart or create/increase product quantity
function* addShoppingCartSaga(action: ReturnType<typeof addShoppingCart>) {
  const merchantId: string = yield select(getMerchantId);
  const chat: ChatTray = yield select(activeChatSelector);
  const contactId = `${chat?.contact?.id}`;
  const storeId: string = yield select(getSelectedStoreId);

  const data = {
    ...action.payload,
    b2chatContactId: contactId,
    merchantId,
    chatId: chat.id,
    storeId,
  };

  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'addCart'],
      { data }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.AddShoppingCart>;
    if (response.error) yield put(addShoppingCartFailure(response.error));
    else
      yield put(
        addShoppingCartSuccess({
          contactId,
          shoppingCart: response.data,
        })
      );
  } catch (error) {
    yield put(addShoppingCartFailure(error as ErrorData));
  } finally {
    yield put(addShoppingCartFulfill());
  }
}

function* fetchShopifyShoppingCartSaga() {
  const activeChat: ChatTray = yield select(activeChatSelector);
  const storeId: string = yield select(getSelectedStoreId);
  const contactId = activeChat?.contact?.id;
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getCartByContactId'],
      { params: { contactId, storeId } }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.FetchShoppingCart>;
    if (response.error) {
      yield put(fetchShopifyShoppingCartFailure(response.error));
    } else
      yield put(
        fetchShopifyShoppingCartSuccess({
          contactId: `${contactId}`,
          shoppingCart: response.data,
        })
      );
  } catch (error) {
    yield put(fetchShopifyShoppingCartFailure(error as ErrorData));
  } finally {
    yield put(fetchShopifyShoppingCartFulfill());
  }
}

function* updateProductShoppingCartSaga(
  action: ReturnType<typeof updateProductShoppingCart>
) {
  const merchantId: string = yield select(getMerchantId);
  const chat: ChatTray = yield select(activeChatSelector);
  const storeId: string = yield select(getSelectedStoreId);
  const contactId = `${chat?.contact?.id}`;
  const data = {
    ...action.payload,
    merchantId,
    b2chatContactId: contactId,
    chatId: chat.id,
    storeId,
  };

  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'updateCart'],
      { params: { contactId }, data }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.updateProductShoppingCart>;

    if (response.error)
      yield put(updateProductShoppingCartFailure(response.error));
    else yield put(fetchShopifyShoppingCart());
  } catch (error) {
    yield put(updateProductShoppingCartFailure(error as ErrorData));
  } finally {
    yield put(updateProductShoppingCartFulfill());
  }
}

function* removeProductFromShoppingCartSaga(
  action: ReturnType<typeof removeProductFromShoppingCart>
) {
  const chat: ChatTray = yield select(activeChatSelector);
  const storeId: string = yield select(getSelectedStoreId);
  const contactId = `${chat?.contact?.id}`;
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'removeCart'],
      { params: { contactId, storeId }, data: action.payload }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.RemoveProductFromShoppingCart>;
    if (response.error) {
      yield put(removeProductFromShoppingCartFailure(response.error));
    } else {
      yield put(fetchShopifyShoppingCart());
    }
  } catch (error) {
    yield put(removeProductFromShoppingCartFailure(error as ErrorData));
  } finally {
    yield put(removeProductFromShoppingCartFulfill());
  }
}

function* createShopifyOrderSaga(
  action: ReturnType<typeof createShopifyOrder>
) {
  const chatId: string = yield select(activeChatIdSelector);
  const chat: ChatTray = yield select(activeChatSelector);
  const cart: ShoppingCart = yield select(getShopifyShoppingCart);

  if (!cart) {
    throw new Error(`cart is empty!`);
  }

  const text = `${action.payload?.textMessage as string}\n\n${
    cart.checkoutUrl
  }`;

  const data = {
    type: MediaType.CART,
    text,
    cartTotalPrice: cart.cost.totalAmount.amount,
  };

  yield put(queueChatMessage(data as ChatMessage, chatId, chat.provider));
}

function* fetchOrdersHistorySaga(
  action: ReturnType<typeof fetchOrdersHistory>
) {
  const pageInfo = action.payload?.pageInfo;
  const orders: ShoppingCartOrder[] = yield select(getShopifyOrdersHistory);
  let nextPage = {};
  const chatId: string = yield select(activeChatIdSelector);
  const storeId: string = yield select(getSelectedStoreId);

  if (pageInfo && orders.length > 0) {
    nextPage = {
      pageInfo: {
        endCursor: pageInfo?.endCursor,
      },
    };
  }

  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'fetchOrders'],
      {
        data: {
          ...action.payload,
          ...nextPage,
          chatId,
          storeId,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.FetchOrders>;
    if (response.error) {
      yield put(fetchOrdersHistoryFailure(response.error));
    } else {
      yield put(fetchOrdersHistorySuccess(response.data));
    }
  } catch (error) {
    yield put(fetchOrdersHistoryFailure(error as ErrorData));
  } finally {
    yield put(fetchOrdersHistoryFulfill());
  }
}

function* sendOrderUrl() {
  const chatId: string = yield select(activeChatIdSelector);
  const chat: ChatTray = yield select(activeChatSelector);
  const { orderNumber, orderStatusUrl }: ShoppingCartOrderUrl = yield select(
    getShopifyOrderHistoryUrl
  );

  const domOrderItemText =
    document.getElementById(`order-history-item-${orderNumber}`)?.innerText ||
    '';
  const textArr = domOrderItemText.split('\n');
  let output = '';
  const totalItems = textArr.length / 2;
  for (let i = 0; i < totalItems; i += 1) {
    output += `${textArr[i]} ${textArr[i + totalItems]}\n`;
  }

  output = `${output}\n${orderStatusUrl}`;

  const data = {
    type: MediaType.ORDER,
    text: output,
    orderId: orderNumber,
  };

  yield put(queueChatMessage(data as ChatMessage, chatId, chat.provider));
}

function* openOrderUrl() {
  const { orderStatusUrl }: ShoppingCartOrderUrl = yield select(
    getShopifyOrderHistoryUrl
  );
  window.open(orderStatusUrl);
}

function* fetchOrderHistoryByIdSaga(
  action: ReturnType<typeof fetchOrderHistoryById>
) {
  const { id, sendUrl } = action.payload;
  const storeId: string = yield select(getSelectedStoreId);
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getOrderById'],
      { params: { id, storeId } }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.FetchOrderById>;
    if (response.error) {
      yield put(fetchOrderHistoryByIdFailure(response.error));
    } else {
      yield put(fetchOrderHistoryByIdSuccess(response.data));
      if (sendUrl) yield sendOrderUrl();
      else yield openOrderUrl();
    }
  } catch (error) {
    yield put(fetchOrderHistoryByIdFailure(error as ErrorData));
  } finally {
    yield put(fetchOrderHistoryByIdFulfill());
  }
}

function* switchActiveChatSaga() {
  yield put(fetchShopifyShoppingCart());
}

function* fetchShopifyCollectionsSaga() {
  const storeId: string = yield select(getSelectedStoreId);
  try {
    const response = (yield call(
      [B2ChatClientEcommerce.resources.ecommerce.actions, 'getCollections'],
      { params: { storeId } }
    )) as B2ChatAPI.Response<B2ChatAPI.Shopify.FetchShopifyCollection>;
    if (response.error)
      yield put(fetchShopifyCollectionsFailure(response.error));
    else yield put(fetchShopifyCollectionsSuccess(response.data));
  } catch (error) {
    yield put(fetchShopifyCollectionsFailure(error as ErrorData));
  } finally {
    yield put(fetchShopifyCollectionsFulfill());
  }
}

function* channels() {
  const fetchShopifyInventoryChan: TakeableChannel<ActionChannelEffect> =
    yield actionChannel(fetchShopifyInventory, buffers.sliding(1));
  yield debounce(500, fetchShopifyInventoryChan, fetchShopifyInventorySaga);
}

function* ShopifySaga() {
  yield takeLatest(fetchShopifyCredentials, fetchShopifyCredentialsSaga);
  yield takeLatest(fetchShopifyStores, fetchShopifyStoresSaga);
  yield takeLatest(addShopifyStoreCredentials, addShopifyStoreCredentialsSaga);
  yield takeLatest(fetchShopifyCollections, fetchShopifyCollectionsSaga);
  yield takeLatest(
    deleteShopifyStoreCredentials,
    deleteShopifyStoreCredentialsSaga
  );
  yield takeLatest(
    saveShopifyStoreCredentials,
    saveShopifyStoreCredentialsSaga
  );
  yield takeLatest(setShopifyCredentials, setShopifyCredentialsSaga);
  yield takeLatest(
    updateShopifyStoresCredentials,
    updateShopifyCredentialsSaga
  );
  yield takeLatest(sendShopifyProduct, sendShopifyProductSaga);
  yield takeLatest(fetchShopifyShop, fetchShopifyShopSaga);
  yield takeLatest(sendShopifyCatalog, sendShopifyCatalogSaga);
  yield takeLatest(fetchShopifySingleProduct, fetchShopifySingleProductSaga);
  yield takeLatest([fetchShopifyShoppingCart], fetchShopifyShoppingCartSaga);
  yield takeLatest(activeChatSuccess, switchActiveChatSaga);
  yield takeLatest(updateProductShoppingCart, updateProductShoppingCartSaga);
  yield takeLatest(addShoppingCart, addShoppingCartSaga);
  yield takeLatest(
    removeProductFromShoppingCart,
    removeProductFromShoppingCartSaga
  );
  yield takeLatest(createShopifyOrder, createShopifyOrderSaga);
  yield takeLatest([fetchOrdersHistory], fetchOrdersHistorySaga);
  yield takeLatest(fetchOrderHistoryById, fetchOrderHistoryByIdSaga);
  yield takeLatest(onClickSendShopifyProduct, onClickSendShopifyProductSaga);
  yield takeLatest(shopifyProductDetails, shopifyProductDetailsSaga);
  yield channels();
}

export default ShopifySaga;
