import { chatHistoryActions } from '@src/reducers/chatHistory';
import {
  fetchChatsOnHold,
  fetchChatsOnHoldFulfill,
  fetchChatsRequest,
  fetchChatsRequestFulfill,
} from '@src/reducers/chats';
import {
  selectWebsocketState,
  updateWebSocketReadyState,
  WebSocketState,
} from '@src/reducers/websocket';
import { activeChatIdSelector } from '@src/selectors/chats';
import { Action } from 'redux';
import { all, put, select, take, takeLeading } from 'redux-saga/effects';

export default function* websocketSaga() {
  yield takeLeading(updateWebSocketReadyState, function* _(action) {
    if (action.payload.readyState === WebSocket.OPEN) {
      yield put(fetchChatsOnHold());
      yield put(fetchChatsRequest());
      const chatId: string = yield select(activeChatIdSelector);
      yield put(
        chatHistoryActions.fetchEvents({
          referenceChatId: chatId,
          resetOffset: true,
        })
      );
    }
  });
}

export function* waitForWebSocketReadyState(value: number) {
  const { readyState }: WebSocketState = yield select(selectWebsocketState);
  if (readyState !== value) {
    yield take(
      (action: Action<unknown>) =>
        updateWebSocketReadyState.match(action) &&
        action.payload.readyState === value
    );
    yield all([
      take(fetchChatsOnHoldFulfill),
      take(fetchChatsRequestFulfill),
      take(chatHistoryActions.fetchEventsFulfill),
    ]);
  }
}
