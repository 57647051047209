// Utilitarian functions intended to be used by Backend Proxies
import { ResponseStatusCode } from '../model/frontendmodel';
import TypeUtils from '../utils/typeutils';

// Verifies that the response retrieved by an HTTP request that was
// performed through the fetch polyfill is OK
export const checkHttpFetchStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

// Extracts the body of a response containing an error report object
// passes it to the specified callback
export const failWithErrorReport = (error, failCallback) => {
  if (!TypeUtils.isFunction(failCallback)) {
    return;
  }

  try {
    error.response
      .json()
      .then(errorDetails => {
        failCallback(ResponseStatusCode.ERROR, error.message, errorDetails);
      })
      .catch(() => {
        failCallback(ResponseStatusCode.ERROR, error.message, null);
      });
  } catch (e) {
    failCallback(ResponseStatusCode.ERROR, error.message, null);
  }
};

export const getResponseHeader = (response, headerKey) => {
  if (
    response !== undefined &&
    response !== null &&
    response.headers &&
    TypeUtils.isFunction(response.headers.get)
  ) {
    return response.headers.get(headerKey);
  }

  return null;
};
