import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
  ErrorData,
  WsConversationReportItem,
  WsConversationSummaryReport,
} from '@types';

type Loading =
  | 'fetch-ws-conversation-report'
  | 'create-ws-conversation-report'
  | 'fetch-ws-conversation-report-lines';

export type CreateReportResponse = {
  code: number;
  message: string;
};

export type CreateConversationReport = {
  broadcastId: string;
  state?: string;
  userName?: string;
  emails: string[];
  nameFile: string;
  locate?: 'es';
  filters: {
    startDate: string;
    endDate: string;
    merchantId: string;
    country?: string;
    line?: string;
  };
};

export type WsConversationReportState = {
  error: ErrorData | null;
  loading: Loading[];
  waSummary: WsConversationSummaryReport | null;
  data: WsConversationReportItem[];
  createReportResponse: CreateReportResponse | null;
  openExportModal: boolean;
  reportLines: string[];
};

type WsConversationReportReducer<P = void> = (
  state: WsConversationReportState,
  action: PayloadAction<P>
) => void;

const initialState: WsConversationReportState = {
  error: null,
  loading: [],
  waSummary: null,
  data: [],
  createReportResponse: null,
  openExportModal: false,
  reportLines: [],
};

export type HandleFetchWsConversationReport = {
  startDate: string;
  endDate: string;
  merchantId: string;
  line?: string;
  countryCode?: string;
};

const handleFetchWsConversationReport: WsConversationReportReducer<
  HandleFetchWsConversationReport
> = state => {
  state.loading.push('fetch-ws-conversation-report');
  state.error = null;
};

export type WsConversationReport = {
  waSummary: WsConversationSummaryReport;
  data: WsConversationReportItem[];
};

const handleFetchWsConversationReportSuccess: WsConversationReportReducer<
  WsConversationReport
> = (state, action) => {
  const { waSummary, data } = action.payload;
  state.waSummary = waSummary;
  state.data = data;
};

const handleFetchWsConversationReportFailure: WsConversationReportReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

const handleFetchWsConversationReportFulfill: WsConversationReportReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'fetch-ws-conversation-report'
    );
  };

const handleCreateWsConversationReport: WsConversationReportReducer<
  CreateConversationReport
> = state => {
  state.loading.push('create-ws-conversation-report');
  state.error = null;
};

const handleCreateWsConversationReportSuccess: WsConversationReportReducer<
  CreateReportResponse
> = (state, action) => {
  state.createReportResponse = action.payload;
};

const handleCreateWsConversationReportFailure: WsConversationReportReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
  state.createReportResponse = { code: 500, message: '' };
};

const handleCreateWsConversationReportFulfill: WsConversationReportReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'create-ws-conversation-report'
    );
  };
const handleClearWsConversationReportResponse: WsConversationReportReducer =
  state => {
    state.createReportResponse = null;
  };

const handleFetchWsConversationReportLines: WsConversationReportReducer<
  HandleFetchWsConversationReport
> = state => {
  state.loading.push('fetch-ws-conversation-report-lines');
  state.error = null;
};

const handleFetchWsConversationReportLinesSuccess: WsConversationReportReducer<
  string[]
> = (state, action) => {
  state.reportLines = action.payload;
};

const handleFetchWsConversationReportLinesFailure =
  handleFetchWsConversationReportFailure;

const handleFetchWsConversationReportLinesFulfill: WsConversationReportReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'fetch-ws-conversation-report-lines'
    );
  };

const wsConversationReportSlice = createSlice({
  name: 'WsConversationReportSlice',
  initialState,
  reducers: {
    fetchWsConversationReport: handleFetchWsConversationReport,
    fetchWsConversationReportSuccess: handleFetchWsConversationReportSuccess,
    fetchWsConversationReportFailure: handleFetchWsConversationReportFailure,
    fetchWsConversationReportFulfill: handleFetchWsConversationReportFulfill,
    createWsConversationReport: handleCreateWsConversationReport,
    createWsConversationReportSuccess: handleCreateWsConversationReportSuccess,
    createWsConversationReportFailure: handleCreateWsConversationReportFailure,
    createWsConversationReportFulfill: handleCreateWsConversationReportFulfill,
    clearWsConversationReportResponse: handleClearWsConversationReportResponse,
    toggleExportModal: state => {
      state.openExportModal = !state.openExportModal;
    },
    fetchWsConversationReportLines: handleFetchWsConversationReportLines,
    fetchWsConversationReportLinesSuccess:
      handleFetchWsConversationReportLinesSuccess,
    fetchWsConversationReportLinesFailure:
      handleFetchWsConversationReportLinesFailure,
    fetchWsConversationReportLinesFulfill:
      handleFetchWsConversationReportLinesFulfill,
  },
});

const { actions, reducer } = wsConversationReportSlice;

export const {
  fetchWsConversationReport,
  fetchWsConversationReportSuccess,
  fetchWsConversationReportFailure,
  fetchWsConversationReportFulfill,
  createWsConversationReport,
  createWsConversationReportSuccess,
  createWsConversationReportFailure,
  createWsConversationReportFulfill,
  clearWsConversationReportResponse,
  toggleExportModal,
  fetchWsConversationReportLines,
  fetchWsConversationReportLinesSuccess,
  fetchWsConversationReportLinesFailure,
  fetchWsConversationReportLinesFulfill,
} = actions;

export default reducer;
