import B2ChatClient from '@client-sdk';
import {
  fetchMerchantPlanUsage,
  fetchMerchantPlanUsageFailure,
  fetchMerchantPlanUsageFulfill,
  fetchMerchantPlanUsageSuccess,
  MerchantPlanUsage,
} from '@src/reducers/merchantPlanUsage';
import { isErrorPayload } from '@src/services/service';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchMerchantPlanUsageSaga() {
  try {
    const response: B2ChatAPI.Response<MerchantPlanUsage> = yield call(
      B2ChatClient.resources.merchant.actions.planUsage
    );

    if (response.error) {
      throw response.error;
    }

    yield put(fetchMerchantPlanUsageSuccess(response.data));
  } catch (error) {
    if (isErrorPayload(error)) {
      yield put(fetchMerchantPlanUsageFailure(error));
    } else {
      yield put(
        fetchMerchantPlanUsageFailure({
          errorCode: 'UNKNOWN',
          details: (error as Error).message,
        })
      );
    }
  } finally {
    yield put(fetchMerchantPlanUsageFulfill());
  }
}

export default function* merchantPlanUsage() {
  yield takeLatest(fetchMerchantPlanUsage, fetchMerchantPlanUsageSaga);
}
