import { createSelector } from '@reduxjs/toolkit';
import { MediaType } from '@src/model/frontendmodel';
import {
  FilterKeys,
  FilterParams,
  LabelShowOnlyAvailableProducts,
  OrderByParam,
  PageInfo,
  ShopifyTabsView,
} from '@src/reducers/shopify/types';
import htmlToText from '@src/utils/htmlToText';
import type { RootState, Selector } from '@types';

import {
  FetchOrdersHistoryType,
  SendProductVariants,
  SendShopifyProduct,
  ShopifyInventory,
  ShopifyLocation,
  ShopifyProduct,
  ShopifyProductImage,
  ShopifyShop,
  ShopifyStore,
  ShopifyStoreCredentials,
  ShopifyVariant,
  ShoppingCart,
  ShoppingCartOrder,
  ShoppingCartProduct,
  UpdateCredentialStatus,
} from '@src/types/shopify';

import { activeChatIdSelector, activeChatSelector } from './chats';

export const getProducts: Selector<ShopifyProduct[]> = (state: RootState) =>
  state.agentConsole?.shopify?.products;

export const getShopifyCurrentStoreShowAvailabilityFilter: Selector<boolean> = (
  state: RootState
) => getSelectedStore(state).showAvailabilityFilter;

export const getShopifyCurrentStoreCanAddItemOutOfStock: Selector<boolean> = (
  state: RootState
) => getSelectedStore(state).canAddItem;

export const getShopifyCredentialsConfigShowAvailabilityFilter: Selector<
  boolean
> = (state: RootState) =>
  state.agentConsole?.shopify.credentials?.config.showAvailabilityFilter;

export const getShopifyCredentialStores: Selector<ShopifyStoreCredentials[]> = (
  state: RootState
) => {
  const stores: ShopifyStoreCredentials[] =
    state.agentConsole?.shopify.credentials?.config.stores;
  if (!stores) return [];
  return stores.slice().sort((a, b) => {
    if (!a.store || !b.store) return -1;
    return a.store < b.store ? -1 : 1;
  });
};

export const getShopifyCredentialStoreById: Selector<
  ShopifyStoreCredentials,
  [storeId: string]
> = (state: RootState, storeId) => {
  const config = state.agentConsole?.shopify.credentials?.config;
  if (config?.stores) {
    const [store] = config.stores.filter(
      (st: ShopifyStoreCredentials) => st.id === storeId
    );
    return store;
  }
  return undefined;
};

// eslint-disable-next-line no-shadow
export enum GetShopifyCredentialsError {
  STORE_URL = 'STORE_URL',
  STORE_TOKEN = 'STORE_TOKEN',
  STORE_FRONT_TOKEN = 'STORE_FRONT_TOKEN',
  DELETE_ERROR = 'DELETE_ERROR',
}

export const getShopifyCredentialsError: Selector<
  GetShopifyCredentialsError | undefined
> = (state: RootState) => {
  const message = state.agentConsole?.shopify?.error?.message;
  if (!message) return undefined;
  if (
    message.includes(
      'unable to find valid certification path to requested target'
    )
  )
    return GetShopifyCredentialsError.STORE_URL;
  if (message.includes('access token'))
    return GetShopifyCredentialsError.STORE_TOKEN;
  if (message.includes('storefront'))
    return GetShopifyCredentialsError.STORE_FRONT_TOKEN;
  if (message.includes('The store cannot delete because is default'))
    return GetShopifyCredentialsError.DELETE_ERROR;
  return undefined;
};

export const getUpdateCredentialStatus: Selector<UpdateCredentialStatus> = (
  state: RootState
) => state.agentConsole?.shopify?.updateCredentialStatus;

export const getUpdatingCredentials: Selector<boolean> = (state: RootState) =>
  state.agentConsole?.shopify?.updatingCredentials;

export type GetProductDetails = ShopifyProduct & {
  strikethrough?: boolean[][];
};

export const getProductDetails: Selector<GetProductDetails> = (
  state: RootState
) => {
  const product: ShopifyProduct = state.agentConsole?.shopify.productDetail;
  const { options, variants } = product;
  const selectedVariantArrId: number[] = getSelectedVariantId(state);

  const outputArr: boolean[][] = [];
  options.forEach(({ values }, optionIndex) => {
    const strikethrough = Array(values.length).fill(false);
    if (options.length === 2) {
      let otherOptions: string[];
      let indexOther = -1;

      if (optionIndex === 0) {
        otherOptions = options[1].values;
        [, indexOther] = selectedVariantArrId;
      } else {
        otherOptions = options[0].values;
        [indexOther] = selectedVariantArrId;
      }

      values.forEach((opt, ind) => {
        const variant = `${otherOptions[indexOther]} / ${opt}`;
        const variant2 = `${opt} / ${otherOptions[indexOther]}`;

        const found = (vari: string) =>
          variants.some(
            item => item.title === vari && item.inventoryQuantity > 0
          );

        strikethrough[ind] = !found(variant) && !found(variant2);
      });
    }
    outputArr.push(strikethrough);
  });

  return {
    ...product,
    strikethrough: outputArr,
  };
};

export const getSelectedProductId: Selector<number | undefined> = (
  state: RootState
) => state.agentConsole?.shopify?.selectedProductId;

export const getShowProductDetail: Selector<boolean> = (state: RootState) =>
  state.agentConsole?.shopify?.currentTabView ===
  ShopifyTabsView.PRODUCT_DETAILS;

export const getShowProductDetailNotFound: Selector<boolean> = (
  state: RootState
) => state.agentConsole?.shopify?.showProductDetailNotFound;

export const getShowShoppingCart: Selector<boolean> = (state: RootState) =>
  state.agentConsole?.shopify?.currentTabView === ShopifyTabsView.SHOPPING_CART;

export const getSelectedVariantId: Selector<[]> = (state: RootState) =>
  state.agentConsole?.shopify?.selectedVariantArrId;

export const getVariantTitleById: Selector<string> = (state: RootState) => {
  const product = getProductDetails(state);
  const { options } = product;
  const variantIdArr = getSelectedVariantId(state);

  const variantId = variantIdArr.reduce((acc: string[], curItem, index) => {
    acc.push(options[index].values[curItem]);
    return acc;
  }, []);
  return variantId.join(' / ');
};

export const getVariantByTitle: Selector<ShopifyVariant> = (
  state: RootState
) => {
  const product = getProductDetails(state);
  const { variants } = product;
  const variantTitle = getVariantTitleById(state);
  const [foundVariant] = variants.filter(item => item.title === variantTitle);
  const [first] = variants;
  if (!foundVariant) return first;
  return foundVariant;
};

export const getVariantQuantityById: Selector<number, [string]> = (
  state: RootState,
  variantId
) => {
  const products = getProductInventoryItems(state);
  const [quantity] = products
    .map(item => {
      const variant = item.variants.find(v => `${v.id}` === variantId);
      return variant?.inventoryQuantity;
    })
    .filter(Boolean);

  return quantity === undefined ? 0 : quantity;
};

export const getImageUrlByVariantId: Selector<string> = (state: RootState) => {
  const product = getProductDetails(state);
  const variant = getVariantByTitle(state);
  const [imageUrl] = product.images;
  return variant?.image?.src || imageUrl?.src;
};

export const getCarouselImagesByVariantId: Selector<ShopifyProductImage[]> = (
  state: RootState
) => {
  const product = getProductDetails(state);
  const variant = getVariantByTitle(state);
  const { image } = variant;
  const { images: defaultImages } = product;

  return variant.image ? ([image] as ShopifyProductImage[]) : defaultImages;
};

export const getCurrentCarouselImage: Selector<string, [string]> = (
  state,
  id
) => state.agentConsole?.shopify?.currentCarouselImage[id];

export const prepareProductData: Selector<SendShopifyProduct | undefined> = (
  state: RootState
) => {
  const chatId = activeChatIdSelector(state);
  const shop = getShopInfo(state);
  const product = getProductDetails(state);
  const variant = getVariantByTitle(state);
  const imageUrl = getImageUrlByVariantId(state);
  const carouselImage = getCurrentCarouselImage(state, `${product.id}`);
  const productVariants = getVariantsForSendingProduct(state);
  const location = getSelectedLocation(state);

  const {
    id,
    bodyHtml: description,
    title: name,
    vendor,
    collections,
    handle,
  } = product;
  const { id: variantId, price, currencyCode, currencySymbol } = variant;
  const variantNumberId = `${variantId}`.split('/').splice(-1);

  const { myShopifyDomain } = shop;

  if (!chatId) {
    // eslint-disable-next-line no-console
    console.error('chatId parameter is missing.');
    return undefined;
  }

  const [baseUrl] = myShopifyDomain.split('.');
  return {
    type: MediaType.PRODUCT_OVERVIEW,
    name,
    imageUrl: carouselImage || imageUrl,
    description: htmlToText(description),
    price,
    currency: currencyCode,
    currencySymbol,
    information: {
      productId: id,
      variantId,
      vendor,
      variants: productVariants,
      collections: collections.map(item => item.name),
      productUrlVariant: `https://${baseUrl}.myshopify.com/products/${handle}?variant=${variantNumberId}`,
      store: myShopifyDomain,
      locationId: location.id,
    },
  };
};

export const getShopifyLocations: Selector<ShopifyLocation[]> = (
  state: RootState
) => state.agentConsole?.shopify?.shop?.locations;

export const getFilterParams: Selector<FilterParams[]> = (state: RootState) =>
  state.agentConsole?.shopify?.filterParams;

export const getFilterParamsEditing: Selector<FilterParams[]> = (
  state: RootState
) => state.agentConsole?.shopify?.filterParamsEditing;

export const getSelectedFilters: Selector<{ key: string; label: string }[]> =
  createSelector(getFilterParams, filters =>
    filters.reduce((output: { key: string; label: string }[], current) => {
      const selected: { key: string; label: string }[] = [];
      current.items.forEach(item => {
        if (item.checked) {
          selected.push({
            key: current.key,
            label:
              item.type === 'range' && item.value
                ? `${item.value[0]} - ${item.value[1]}`
                : item.label,
          });
        }
      });
      output.push(...selected);
      return output;
    }, [])
  );

export const prepareFilterParamsRequest: Selector<{ [key: string]: string }> = (
  state: RootState
) => {
  const orderByParam = state.agentConsole?.shopify?.orderByParam;
  const params: FilterParams[] = state.agentConsole?.shopify?.filterParams;
  const [priceRange] = params.filter(item => item.key === FilterKeys.PRICES);
  const { pageInfo } = state.agentConsole?.shopify?.inventory;
  const pagination = pageInfo
    ? {
        endCursor: pageInfo.endCursor,
      }
    : {};

  let queryObj: { [key: string]: string } = {};

  params.forEach(cur => {
    cur.items.forEach(item => {
      if (item.checked) {
        if (queryObj[cur.key]) queryObj[cur.key] += `,${item.label}`;
        else if (cur.key === FilterKeys.COLLECTIONS)
          queryObj[cur.key] = `${item.id}`;
        else if (cur.key === FilterKeys.SHOW_ONLY_AVAILABLE_PRODUCTS)
          queryObj[cur.key] = `${item.checked}`;
        else queryObj[cur.key] = item.label;
      }
    });
  });

  const priceRangeParamArr = priceRange ? priceRange.items[0].value : [];

  let priceRangeParam = {};
  if (priceRangeParamArr?.length && priceRange.items[0].checked) {
    const [minValue, maxValue] = priceRangeParamArr;
    priceRangeParam = {
      prices: `${minValue}-${maxValue}`,
    };
  }

  queryObj = {
    ...queryObj,
    ...orderByParam,
    ...priceRangeParam,
    ...pagination,
  };

  return queryObj;
};

export const getShopifyProductsPageInfo: Selector<PageInfo> = state =>
  state.agentConsole?.shopify?.inventory.pageInfo;

export const getSelectedLocation: Selector<ShopifyLocation> = (
  state: RootState
) => {
  const { shop } = state.agentConsole?.shopify;
  if (!shop) {
    const store = state.agentConsole?.shopify?.configStores?.stores.at(0);
    if (store?.locations) return store.locations.at(0);
    return undefined;
  }

  const [location] = shop.locations.filter(
    (item: ShopifyLocation) =>
      item.id === Number(state.agentConsole?.shopify?.selectedLocationId)
  );
  return location || shop.locations.at(0);
};

export const getSelectedLocationId: Selector<string> = (state: RootState) =>
  state.agentConsole?.shopify?.selectedLocationId;

export const getInventory: Selector<ShopifyInventory[]> = (state: RootState) =>
  state.agentConsole?.shopify?.inventory;

export const getProductInventoryItems: Selector<ShopifyProduct[]> = (
  state: RootState
) => state.agentConsole?.shopify?.inventory?.products;

export const getTotalInventoryItems: Selector<number> = (state: RootState) =>
  state.agentConsole?.shopify?.inventory?.totalProducts || 0;

export const getSearchPattern: Selector<string> = (state: RootState) =>
  state.agentConsole?.shopify?.searchPattern;

export const getIsLoadingProducts: Selector<string> = (state: RootState) =>
  state.agentConsole?.shopify?.loading.includes('fetch-shopify-inventory');

export const getOrderByParam: Selector<OrderByParam> = (state: RootState) => {
  let sort = state.agentConsole?.shopify?.orderByParam.sort;
  if (state.agentConsole?.shopify?.orderByParam.reverse) sort += '&reverse';
  return sort;
};

export const getShopInfo: Selector<ShopifyShop> = (state: RootState) =>
  state.agentConsole?.shopify?.shop;

export const prepareCatalogData: Selector<string> = (state: RootState) => {
  const shop = getShopInfo(state);

  if (shop) {
    return `**${shop.name}**\n\n${shop.domain}/collections/all`;
  }
  return '';
};

export const getShopifyError: Selector<Error> = (state: RootState) =>
  state.agentConsole?.shopify?.error;

export const getShopifyFetchDetailError: Selector<Error> = (state: RootState) =>
  state.agentConsole?.shopify?.productDetailError;

export const hasShopifyIntegration: Selector<boolean> = (state: RootState) =>
  state.agentConsole?.shopify?.configStores?.stores.length > 0;

export const getShopifyCurrentStoreId: Selector<string> = (
  state: RootState
) => {
  const { configStores } = state.agentConsole?.shopify;
  return configStores?.currentStoreId || configStores?.stores.at(0).id;
};

export const getSelectedStore: Selector<ShopifyStore> = (state: RootState) => {
  const [store] = state.agentConsole?.shopify?.configStores?.stores.filter(
    (item: ShopifyStore) =>
      item.id === state.agentConsole?.shopify.selectedStoreId
  );
  return store || state.agentConsole?.shopify?.configStores?.stores.at(0);
};

export const getSelectedStoreId: Selector<string> = (state: RootState) =>
  state.agentConsole?.shopify.selectedStoreId;

export const getShopifyStores: Selector<ShopifyStore[]> = (state: RootState) =>
  state.agentConsole?.shopify?.configStores?.stores || [];

export const getShopifyStoreById: Selector<ShopifyStore, [id: string]> = (
  state: RootState,
  id
) => {
  const [store] = getShopifyStores(state).filter(item => item.id === id);
  return store;
};

export const getShopifyShoppingCart: Selector<ShoppingCart> = (
  state: RootState
) => {
  const contactId = activeChatSelector(state)?.contact?.id;
  if (contactId) return state.agentConsole?.shopify?.shoppingCart[contactId];
  return undefined;
};

export const getShopifyShoppingCartProduct: Selector<
  ShoppingCartProduct | undefined,
  [string]
> = (state: RootState, id) => {
  const cart = getShopifyShoppingCart(state);
  if (!cart) return undefined;
  const [product] = cart.products.filter(item => item.productVariantId === id);
  return product;
};

export const getShopifyShoppingCartProductQuantity: Selector<
  number,
  [string]
> = (state: RootState, id) => {
  const product = getShopifyShoppingCartProduct(state, id);
  if (!product) return 0;
  return product.quantity;
};

export const getShopifyShoppingCartIsLoading: Selector<boolean> = (
  state: RootState
) =>
  state.agentConsole?.shopify?.loading.includes('fetch-shopify-shopping-cart');

export const getShopifyShoppingCartTotalItems: Selector<number> = (
  state: RootState
) => {
  const contactId = activeChatSelector(state)?.contact?.id;
  if (contactId) {
    let cnt = 0;
    state.agentConsole?.shopify?.shoppingCart[contactId]?.products.forEach(
      (cur: ShoppingCartProduct) => {
        cnt += cur.quantity;
      }
    );
    return cnt;
  }
  // eslint-disable-next-line no-console
  console.error('contactId is undefined after to call activeChatSelector()');
  return 0;
};

export const getAlreadyHasCredentials: Selector<boolean> = (state: RootState) =>
  state.agentConsole?.shopify?.alreadyHasStores;

export const getVariantsForSendingProduct: Selector<SendProductVariants> = (
  state: RootState
) => {
  const selectedVariantArrId = getSelectedVariantId(state);
  const product = getProductDetails(state);
  const { options } = product;

  return selectedVariantArrId.map((valueIndex, index) => {
    const { name, values } = options[index];
    return {
      name,
      value: values[valueIndex],
    };
  });
};

export const getProductUrl: Selector<string> = (state: RootState) => {
  const productDetail = getProductDetails(state);
  const variant = getVariantByTitle(state);
  const shop = getShopInfo(state);

  const { id } = variant;
  const { handle } = productDetail;
  const { myShopifyDomain } = shop;
  const [baseUrl] = myShopifyDomain.split('.');

  return `https://${baseUrl}.myshopify.com/products/${handle}?variant=${id}`;
};

export const getFetchOrderInputValue: Selector<FetchOrdersHistoryType> = (
  state: RootState
) => state.agentConsole?.shopify?.orderInputValue;

export const getShopifyOrdersHistory: Selector<ShoppingCartOrder[]> = (
  state: RootState
) => state.agentConsole?.shopify?.ordersHistory;

export const getShopifyOrderHistoryUrl: Selector<string> = (state: RootState) =>
  state.agentConsole?.shopify?.orderUrl;

export const getShowOrdersHistory: Selector<boolean> = (state: RootState) =>
  state.agentConsole?.shopify?.currentTabView ===
  ShopifyTabsView.ORDERS_HISTORY;

export const getShopifyOrdersHistoryIsLoading: Selector<boolean> = (
  state: RootState
) => state.agentConsole?.shopify?.loading.includes('fetch-orders-history');

export const getShopifyOrdersHistoryNoResults: Selector<boolean> = (
  state: RootState
) => state.agentConsole?.shopify?.error;

export interface IGetContactInfo {
  email: string;
  mobileNumber: string;
}

export const getActiveContactInfo: Selector<IGetContactInfo> = (
  state: RootState
) => {
  const info = state.agentConsole?.contact?.contact;

  return {
    email: info?.email,
    mobileNumber: info?.mobileNumber,
  };
};

export const getActiveContactId: Selector<number | undefined> = (
  state: RootState
) => state.chats.activeChat?.contact.id;

export const getShopifyOrdersHistoryPageInfo: Selector<PageInfo> = (
  state: RootState
) => state.agentConsole?.shopify?.ordersHistoryPageInfo;

export const getShowOnlyAvailableProductsFilterValue: Selector<boolean> = (
  state: RootState
) => {
  const filtersEditing: FilterParams[] =
    state.agentConsole?.shopify?.filterParamsEditing;
  return !!filtersEditing
    .find(({ key }) => key === FilterKeys.SHOW_ONLY_AVAILABLE_PRODUCTS)
    ?.items.find(({ label }) => label === LabelShowOnlyAvailableProducts)
    ?.checked;
};

export const getTryToRetrieveOnlyAvailableProducts: Selector<boolean> = (
  state: RootState
) => state.agentConsole?.shopify?.tryToRetrieveOnlyAvailableProducts;

export default getProducts;
