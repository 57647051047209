// Transforms a text message from the frontend model into the backend model
export const buildBackendTextMessage = ({ messageId, text }) =>
  JSON.stringify({
    id: messageId,
    text,
  });

// Transforms a media message from the frontend model into the backend model
export const buildBackendMediaMessage = ({ messageId, mediaUrl, mediaType }) =>
  JSON.stringify({
    id: messageId,
    fileUrl: mediaUrl,
    mediaType,
  });

// Transforms a location message from the frontend model into the backend model
export const buildBackendLocationMessage = ({
  messageId,
  longitude,
  latitude,
  locationName,
  address,
  mediaType,
}) =>
  JSON.stringify({
    id: messageId,
    longitude,
    latitude,
    locationName,
    address,
    mediaType,
  });

// Builds an instance of a messaging account as known to the backend, encoded as JSON.
// (That is, can de deserialized as instance of class com.vertical.bot.domain.MessagingAccount)
export const buildBackendMessagingAccount = (
  id,
  provider,
  username,
  token,
  alias
) =>
  JSON.stringify({
    id,
    name: username,
    provider,
    token,
    alias,
  });

// Builds an object representing a registration request. That is, a request to create a new merchant and an
// associated admin user, which will be able to access the application
export const buildBackendCustomerRegistration = (
  fullName,
  merchantName,
  email,
  phone,
  username,
  password,
  passwordConfirm,
  country,
  city,
  fromCountryCode,
  timeZone,
  language,
  acceptsTerms,
  acceptsDataPrivacyPolicy,
  reCaptchaValue,
  merchantDetails
) =>
  JSON.stringify({
    fullName,
    merchantName,
    email,
    phone,
    username,
    password,
    passwordConfirm,
    country,
    city,
    fromCountryCode,
    timeZone,
    language,
    acceptsTerms,
    acceptsDataPrivacyPolicy,
    reCaptchaValue,
    merchantDetails,
  });
