import { combineReducers } from 'redux';

import {
  START_LOADING_CATEGORIES_LIST,
  OK_LOADED_CATEGORIES_LIST,
  ERROR_LOADED_CATEGORIES_LIST,
  ERROR_LOADED_PRODUCT_LIST,
  START_LOADING_PRODUCTS_LIST,
  OK_LOADED_PRODUCT_LIST,
  LOADING_FILTERS,
  RESET_ALL_FILTER_VALUES,
  START_LOADING_EMAIL,
  SET_MODAL_EXPORT_OPEN,
  SET_MODAL_EXPORT_ERROR_MESSAGE,
} from '../actions/product';

const productToolBarReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_CATEGORIES_LIST:
      return {
        ...state,
        statusLoadingCategories: 'LOADING',
        categories: [],
        errorMessage: '',
      };
    case OK_LOADED_CATEGORIES_LIST:
      return {
        ...state,
        statusLoadingCategories: 'OK_LOADED',
        categories: action.results,
        errorMessage: '',
      };
    case ERROR_LOADED_CATEGORIES_LIST:
      return {
        ...state,
        statusLoadingCategories: 'ERROR_LOADED',
        categories: [],
        errorMessage: action.message,
      };
    case LOADING_FILTERS:
      return {
        ...state,
        productNameLike: action.filters.productNameLike,
        categoryId: action.filters.categoryId,
        active: action.filters.active,
      };
    case RESET_ALL_FILTER_VALUES:
      return {
        ...state,
        productNameLike: action.productNameLike,
        categoryId: action.categoryId,
        categories: action.categories,
        statusLoadingCategories: action.statusLoadingCategories,
        errorMessage: action.errorMessage,
        active: action.active,
      };

    default:
      return state;
  }
};

const productTableReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_PRODUCTS_LIST:
      return {
        ...state,
        products: [],
        statusLoadingProducts: 'LOADING',
        errorMessage: '',
      };
    case OK_LOADED_PRODUCT_LIST:
      return {
        ...state,
        products: action.products,
        statusLoadingProducts: 'OK_LOADED',
        errorMessage: '',
      };
    case ERROR_LOADED_PRODUCT_LIST:
      return {
        ...state,
        products: [],
        statusLoadingProducts: 'ERROR_LOADED',
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

const productExportReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_EMAIL:
      return { ...state, email: action.email };
    case SET_MODAL_EXPORT_OPEN:
      return { ...state, open: action.open };
    case SET_MODAL_EXPORT_ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };

    default:
      return state;
  }
};

const productReducer = combineReducers({
  toolbar: productToolBarReducer,
  table: productTableReducer,
  export: productExportReducer,
});

export default productReducer;
