/* eslint-disable camelcase */
import B2ChatClient from '@client-sdk';

import {
  fetchWhatsAppProfile,
  fetchWhatsAppProfileFailure,
  fetchWhatsAppProfileFulfill,
  fetchWhatsAppProfileSuccess,
  updateWhatsAppProfile,
  updateWhatsAppProfileFailure,
  updateWhatsAppProfileFulfill,
  updateWhatsAppProfileSuccess,
} from '@src/reducers/whatsAppProfile';
import { B2ChatAPI } from '@src/types/api';
import { isErrorData, isErrorPayload } from '@types';
import { call, put, takeLatest } from 'redux-saga/effects';

type SmoochAccountProfileResponse = {
  alias: string;
  about: string;
  address: string;
  description: string;
  email: string;
  vertical: string;
  websites: string[];
  profile_picture_url: string;
};

function* fetchWhatsAppProfileSaga(
  action: ReturnType<typeof fetchWhatsAppProfile>
) {
  const { accountId } = action.payload;

  try {
    const response: B2ChatAPI.Response<SmoochAccountProfileResponse> =
      yield call(
        B2ChatClient.resources.whatsapp.actions.retrieveAccountProfile,
        { params: { botId: accountId } }
      );

    if (response.error) throw response.error;

    const { profile_picture_url: photoFile, ...profileData } = response.data;

    yield put(
      fetchWhatsAppProfileSuccess({
        accountId,
        data: {
          photoFile,
          ...profileData,
        },
      })
    );
  } catch (error: unknown) {
    if (isErrorData(error)) {
      yield put(
        fetchWhatsAppProfileFailure({
          accountId,
          error: {
            details: error.message,
            errorCode: error.error || 'unknown',
          },
        })
      );
    } else if (isErrorPayload(error)) {
      yield put(fetchWhatsAppProfileFailure({ accountId, error }));
    }
  } finally {
    yield put(fetchWhatsAppProfileFulfill({ accountId }));
  }
}

function* updateWhatsAppProfileSaga(
  action: ReturnType<typeof updateWhatsAppProfile>
) {
  const { accountId, data } = action.payload;
  const { photoFile: profilePhoto, ...profileData } = data;

  try {
    // eslint-disable-next-line no-shadow
    const formData: FormData = new FormData();
    formData.append(
      'profile',
      new Blob([JSON.stringify(profileData)], { type: 'application/json' })
    );
    if ((profilePhoto as File).name !== 'photoFile') {
      formData.append('profilePhoto', profilePhoto as File);
    }

    const response: B2ChatAPI.Response<void> = yield call(
      B2ChatClient.resources.whatsapp.actions.updateAccountProfile,
      {
        data: formData,
        params: { botId: accountId },
      }
    );

    if (response.error) throw response.error;

    yield put(updateWhatsAppProfileSuccess({ accountId, data }));
  } catch (error) {
    if (isErrorData(error)) {
      yield put(
        updateWhatsAppProfileFailure({
          accountId,
          error: {
            details: error.message,
            errorCode: error.error || 'unknown',
          },
        })
      );
    } else if (isErrorPayload(error)) {
      yield put(updateWhatsAppProfileFailure({ accountId, error }));
    } else {
      yield put(
        updateWhatsAppProfileFailure({
          accountId,
          error: { details: 'unknown', errorCode: 'UNKNOWN' },
        })
      );
    }
  } finally {
    yield put(updateWhatsAppProfileFulfill({ accountId }));
  }
}

export default function* whatsAppProfile() {
  yield takeLatest(fetchWhatsAppProfile, fetchWhatsAppProfileSaga);
  yield takeLatest(updateWhatsAppProfile, updateWhatsAppProfileSaga);
}
