/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call } from '@redux-saga/core/effects';
import { B2ChatAPI } from '@src/types/api';
import env from '@src/utils/env';

function* uploadFileSaga(file: File, signal?: AbortSignal) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response: B2ChatAPI.Response<B2ChatAPI.Files.UploadFiles> =
      yield call(B2ChatClient.resources.files.actions.uploadFiles, {
        data: formData,
        params: { prefix: env.APP_ENV },
        signal,
      });

    signal?.throwIfAborted();

    if (response.error) throw response.error;

    return response.data[0];
  } catch (error) {
    if (file.type === 'video/mp4') {
      const videoError = new Error();
      videoError.name = 'UPLOAD_VIDEO';
      videoError.message = file.name;
      throw videoError;
    }
    throw error;
  }
}

export default uploadFileSaga;
