const buildInitialUserState = () => ({
  toolbar: {
    users: [],
    userLoadStatus: null,
    errorUserMessage: null,
    filters: {
      userValue: '',
      status: 'ACTIVE',
    },
  },
  init: {
    merchantUsers: [],
    userLoadStatus: null,
    errorUserMessage: null,
    maxUsers: 0,
    availablesUsers: 0,
    isAllowedCreateUser: true,
  },
  createUserDetails: {
    fullName: '',
    email: '',
    mobileNumber: '',
    role: '',
    userId: '',
    userName: '',
    savedStatus: '',
    errorCode: '',
    messageError: '',
    mobileAccess: 'INACTIVE',
    chatDeletePolicy: false,
  },
  dialogViewerContainer: {
    openCreateDialog: false,
    isToCreateUser: true,
    openSubscriptionDialog: false,
    openActivationDialog: false,
    openValidationErrorDialog: false,
  },
  activateUserDetails: {
    file: null,
    imagePreviewUrl: null,
    imageErrorCode: '',
  },
  switchUserStatus: {
    userId: null,
  },
  resendInvitation: {
    deliveryStatus: '',
    userId: '',
    errorCode: '',
    messageError: '',
    openDialog: false,
  },
});

export default buildInitialUserState;
