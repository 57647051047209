import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '@src/types';

type ConsoleWidgetsState = {
  loading: boolean | 'updating' | 'fetching';
  data: ConsoleWidgetEntry[];
  error?: ErrorPayload;
};

export type ConsoleWidgetEntry = {
  id: number;
  type: string;
  merchantId: number;
  config: {
    name: string;
    url: string;
    iconUrl: string;
  };
  active: true;
};

const initialState: ConsoleWidgetsState = {
  loading: false,
  data: [],
};

const consoleWidgets = createSlice({
  name: 'consoleWidgets',
  initialState,
  reducers: {
    fetchConsoleWidgets(state) {
      state.loading = 'fetching';
    },
    fetchConsoleWidgetsSuccess(
      state,
      action: PayloadAction<ConsoleWidgetEntry[]>
    ) {
      state.data = action.payload.map(item => ({
        ...item,
        config: JSON.parse(item.config as unknown as string),
      }));
    },
    fetchConsoleWidgetsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.error = action.payload;
    },
    fetchConsoleWidgetsFulfill(state) {
      state.loading = false;
    },
    updateConsoleWidgets(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<Partial<ConsoleWidgetEntry>>
    ) {
      state.loading = 'updating';
    },
    updateConsoleWidgetsSuccess(state) {
      state.error = undefined;
    },
    updateConsoleWidgetsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.error = action.payload;
    },
    updateConsoleWidgetsFulfill(state) {
      state.loading = false;
    },
  },
});

export const {
  fetchConsoleWidgets,
  fetchConsoleWidgetsSuccess,
  fetchConsoleWidgetsFailure,
  fetchConsoleWidgetsFulfill,
  updateConsoleWidgets,
  updateConsoleWidgetsSuccess,
  updateConsoleWidgetsFailure,
  updateConsoleWidgetsFulfill,
} = consoleWidgets.actions;

export default consoleWidgets.reducer;
