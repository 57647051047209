/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-cycle
import { RootState } from '@src/types';
import { ChatHistoryDirection } from '@src/types/chatViewert';

export const ChatDetailsSelector = (state: RootState) =>
  state.chatViewer.chatDetails || {};

export const ChatContactSelector = (state: RootState) =>
  state.chatViewer.contactInChat;

export const ChatContactIdSelector = (state: RootState) =>
  state.chatViewer.chatDetails.contact?.id;

export const ChatBotIdSelector = (state: RootState) =>
  state.chatViewer.chatDetails?.botId;

export const ChatContactActivatedAttrsSelector = (state: RootState) =>
  state.chatViewer.contactAttrs?.attrs;

export const ChatReferencesStateSelector = (state: RootState) =>
  state.chatViewer.chatRefs;

export const ChatReferencesSelector = (state: RootState) =>
  state.chatViewer.chatRefs?.chats;

export const TotalChatReferencesSelector =
  (direction: ChatHistoryDirection) => (state: RootState) =>
    state.chatViewer.chatRefs?.direction[direction]?.totalChats || 0;

export const ChatMsgsHistoryStateSelector = (state: RootState) =>
  state.chatViewer.chatMsgsHistory;

export const ChatMsgsHistorySelector = (state: RootState) =>
  state.chatViewer.chatMsgsHistory.chats;

export const TotalChatMsgsHistorySelector = (state: RootState) =>
  state.chatViewer.chatMsgsHistory.chats?.length;

export const ChatMsgsHistoryFirstItemIndexSelector = (state: RootState) =>
  state.chatViewer.chatMsgsHistory.firstItemIndex;

export const showDepartmentList = (state: RootState) =>
  state.chatViewer.showDepartmentList;

export const getDepartmentList = (state: RootState) =>
  state.chatViewer.departments;

export const TakeChatLoading = (state: RootState) =>
  state.chatViewer.takeChatFromAudit.loading;

export const TakeChatError = (state: RootState) =>
  state.chatViewer.takeChatFromAudit.error;

export const TakeChatReady = (state: RootState) =>
  state.chatViewer.takeChatFromAudit.ready;

export const AssignedChatStatusSelector = (state: RootState) =>
  state.chatViewer.assignChat?.status;

export const assignationChatSelector = (state: RootState) =>
  state.chatViewer?.assignChat;
