export const REPORT_AGENT_LIST_REQUEST = 'REPORT_AGENT_LIST_REQUEST';
export const REPORT_AGENT_LIST__FAIL = 'REPORT_AGENT_LIST__FAIL';

const getAgentList = (state = {}, action) => {
  switch (action.type) {
    case REPORT_AGENT_LIST_REQUEST:
      return { ...state, success: action.data, status: true };
    case REPORT_AGENT_LIST__FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default getAgentList;
