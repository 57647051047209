import rootReducer, { RootState } from '@reducers/rootReducer';
import rootSaga from '@sagas';
import apis from '@src/reducers/apis';
// eslint-disable-next-line import/no-extraneous-dependencies
import { setAutoFreeze } from 'immer';
import { applyMiddleware, compose, createStore, PreloadedState } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { initialRootState } from './commonstate';

declare global {
  interface Window {
    store: typeof store;
    debugEnabled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (...args: unknown[]) => any;
  }
}

setAutoFreeze(false);

export const saga = createSagaMiddleware();

// Get an initial, empty state of the store
const initialState = initialRootState();

const composeEnhancers =
  window.debugEnabled && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

// Create the Redux store. Specify the initial state of the store with the data rendered on the server
const store = createStore(
  rootReducer,
  initialState as unknown as PreloadedState<RootState>,
  composeEnhancers(
    applyMiddleware(
      saga,
      thunkMiddleware, // Allows functions to be dispatched instead of actions, thus enabling "branched-actions"
      ...apis.middleware
    )
  )
);

saga.run(rootSaga);

Object.defineProperty(window, 'store', { value: store, configurable: true });

export default store;
