const buildInitialBotSetupState = () => ({
  // List (array) of bots available for the current user
  lstUserBots: null,
  // State of the setup Facebook account process
  fbAccountSetup: {
    currentState: null, // State of the FB account setup process in which the component currently is
    loginData: null, // Object with information about the user currently logged in via Facebook
    mapPermissions: null, // Map (object) of permissions that says for each one if they've been granted (value true)
    lstUserBots: null, // List (array) of bots available for the current user
    lstUserPages: null, // List (array) of Facebook pages of the current user
    linkedPageId: null, // If a page has been successufully liked up with a bot, holds the ID of the page
    linkedBotId: null, // If a page has been successufully liked up with a bot, holds the ID of the bot
    currentProvider: null, // FB, INSTAGRAM
  },
  // State of the setup Livechat account process
  lcAccountSetup: {
    currentState: null, // State of the Livechat account setup process in which the component currently is
    msgAccount: null, // The Livechat messaging account being setup
    livechatConfig: null, // Livechat custom configuration of messaging account being setup
    widgetScript: null, // Livechat widget script of the messaging account being setup
    linkedBotId: null, // ID of the bot linked up with the Livechat messaging account being setup
  },
});

export default buildInitialBotSetupState;
