/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { normalizeCountriesSet } from '@model/transform';
// @ts-ignore
import { appConfig } from '@src/config/appConfig';
// @ts-ignore
import buildInitialAdminhomeState from './adminhome';
// @ts-ignore
import buildInitialAgentConsoleState from './agentconsolestate';

// Constructs an initial state suitable to be set into the store
// eslint-disable-next-line import/prefer-default-export, func-names
export const initialRootState = function () {
  // Check whether there are any errors reported in the query string
  const rawQueryString = window.location.search;
  const hasLoginError = rawQueryString && rawQueryString.indexOf('error') >= 0;
  //todo removed this property from store
  // @ts-ignore
  const arrCountries = normalizeCountriesSet(appConfig.arrCountries);

  return {
    // Glogal error handling
    errors: {
      hasUnreckonedError: false,
      currentError: {
        messsage: null,
      },
      loadedImagesErrors: [],
    },

    // Login page attributes
    login: {
      hasError: hasLoginError,
    },

    // Dataset with the IDs and names of all the countries defined in the backend's data repository
    countriesDataSet: arrCountries,

    // Dataset with the IDs and names of all the cities defined in the backend's data repository (initially empty,
    // as it should be loaded on-demand from the backend)
    forgotPassword: {
      passwordChangeReqForm: {
        passwordError: false,
        matchingPasswordError: false,
        tokenExpired: false,
        unknownError: false,
        success: false,
        usernameOrEmail: '',
      },
      changePasswordForm: {
        username: '',
        password: '',
        usernameRequired: false,
        usernameInvalid: false,
        matchPassword: '',
        validatedToken: false,
        resetToken: '',
        hasError: false,
        success: false,
      },
    },

    // Dataset with the IDs and names of all the cities defined in the backend's data repository
    citiesDataSet: [],

    // Agent console page attributes
    agentConsole: buildInitialAgentConsoleState(),
    adminHome: buildInitialAdminhomeState(),
    // State of the Image Viewer component
    imageViewer: {
      open: false,
      imageUrl: null,
    },
    dialogViewer: {
      open: false,
      dialogType: 'Some title',
      buttonLabel: 'CLOSE',
      message: 'Something',
    },
    loginAuthentication: {
      status: null,
      // success: appConfig.getCurrentUser(),
      success: appConfig.getCurrentUser(),
      failure: [],
    },
    generalProperties: {
      keenioFaqsModuleFlowCollectionName: undefined,
      keenioFaqsQuestionCollectionName: undefined,
      keenioGeneralOrderCollectionName: undefined,
      keenioOrderOrdersCollectionName: undefined,
      keenioOrderProdutsCollectionName: undefined,
      keenioCollectionName: undefined,
      keenioProjectId: undefined,
      fbAppId: undefined,
      reCaptchaSiteKey: undefined,
      arrCountries: undefined,
      allB2chatModules: undefined,
      modules: undefined,
      preferences: {
        uiConfigurationProperties: {},
      },
    },
    logOut: {
      status: null,
      success: [],
      failure: [],
    },
    satisfactionBotList: {
      status: null,
      success: [],
      failure: [],
    },
    messagingAccountList: {
      status: null,
      success: [],
      failure: [],
    },
    renderBotConfiguration: {
      status: null,
      success: [],
      failure: [],
    },
    getDefaultMessage: {
      status: null,
      success: appConfig.getData('defaultMessagesCSM'),
      failure: [],
    },
    saveBotConfiguration: {
      status: null,
      success: [],
      failure: [],
    },
    exportChartDetail: {
      status: null,
      success: [],
      failure: [],
    },
    generalSetting: {
      status: null,
      success: [],
      failure: [],
    },
    customFiledStatus: {
      status: null,
      success: [],
      failure: [],
    },
  };
};
