import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'fetch-validation' | 'import-confirmation';

const validKeys = <const>[
  'total',
  'inserted',
  'invalid',
  'existing',
  'duplicated',
  'missingNames',
  'missingMobiles',
  'invalidMobiles',
  'invalidEmails',
];

type ValidKeys = (typeof validKeys)[number];

export type ErrorCode =
  | 'INVALID_EXTENSION'
  | 'EMPTY'
  | 'MAX_CONTENT_EXCEEDED'
  | 'INVALID_EXCEL_EXTENSION'
  | 'EMPTY_FILE'
  | 'INVALID_FILE_SIZE'
  | 'SENDINGS_PER_DAY_EXCEEDED'
  | 'SENDINGS_NUMBER_EXCEEDED'
  | 'OPERATION_FAILED'
  | 'INVALID_STRUCTURE';

export type ValidationResponse = {
  id: number;
  state: 'VALIDATED' | 'VALIDATED_WITH_ERRORS';
  errorFileUrl?: string;
  validationSummary: {
    [key in ValidKeys]: number;
  };
};

export type ValidationError = {
  timestamp: number;
  errorCode: ErrorCode;
  details: string;
  traceId: string;
  fieldName: string;
};

export type ContactsImportError = (Partial<ErrorData> & ValidationError) | null;

export type ContactsImport = {
  error: ContactsImportError;
  importError: ErrorData | null;
  loading: Loading[];
  validation?: ValidationResponse;
};

type ContactsImportReducer<P = void> = (
  state: ContactsImport,
  action: PayloadAction<P>
) => void;

const initialState: ContactsImport = {
  error: null,
  importError: null,
  loading: [],
  validation: undefined,
};

type FetchValidationPayload = {
  file: File;
};

const handleFetchValidation: ContactsImportReducer<
  FetchValidationPayload
> = state => {
  state.loading.push('fetch-validation');
  state.error = null;
};

const handleFetchValidationSuccess: ContactsImportReducer<
  ValidationResponse
> = (state, action) => {
  state.validation = action.payload;
};

const handleFetchValidationFailure: ContactsImportReducer<
  ContactsImportError
> = (state, action) => {
  state.error = action.payload;
};

const handleFetchValidationFulfill: ContactsImportReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-validation');
};

export type ImportConfirmationPayload = {
  id: number;
  skipTags: boolean;
};

const handleImportConfirmation: ContactsImportReducer<
  ImportConfirmationPayload
> = state => {
  state.loading.push('import-confirmation');
  state.error = null;
};

const handleImportConfirmationSuccess: ContactsImportReducer = state => {
  state.validation = undefined;
};

const handleImportConfirmationFailure: ContactsImportReducer<ErrorData> = (
  state,
  action
) => {
  state.importError = action.payload;
};

const handleImportConfirmationFulfill: ContactsImportReducer = state => {
  state.loading = state.loading.filter(l => l !== 'import-confirmation');
};

const contactsImportSlice = createSlice({
  name: 'ContactsImport',
  initialState,
  reducers: {
    fetchValidation: handleFetchValidation,
    fetchValidationSuccess: handleFetchValidationSuccess,
    fetchValidationFailure: handleFetchValidationFailure,
    fetchValidationFulfill: handleFetchValidationFulfill,
    importConfirmation: handleImportConfirmation,
    importConfirmationSuccess: handleImportConfirmationSuccess,
    importConfirmationFailure: handleImportConfirmationFailure,
    importConfirmationFulfill: handleImportConfirmationFulfill,
  },
});

const { actions, reducer } = contactsImportSlice;

export const {
  fetchValidation,
  fetchValidationSuccess,
  fetchValidationFailure,
  fetchValidationFulfill,
  importConfirmation,
  importConfirmationSuccess,
  importConfirmationFailure,
  importConfirmationFulfill,
} = actions;

export default reducer;
