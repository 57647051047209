/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
// TODO: migrate this file to TS
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createAction } from '@reduxjs/toolkit';
import { saveContactChat } from '@src/reducers/chats';
import { ChatEvent } from '@src/types';
import _get from 'lodash-es/get';
import _size from 'lodash-es/size';
import ChatSorting from '../logic/ChatSorting';
import AgentChatManager from '../logic/agentchatmanager';
import BackendProxy from '../logic/backendproxy';
import ContactForm from '../logic/contactform';
import { EventType } from '../model/frontendmodel';
import Logger from '../utils/logging';
import StringUtils from '../utils/strings';

export const RESET_AGENT_CONSOLE_STATE = 'AGENT_CONSOLE.RESET_STATE';
export const CONNECTED_TO_BACKEND = 'AGENT_CONSOLE.CONNECTED_TO_BACKEND';
export const DISCONNECTED_FROM_BACKEND =
  'AGENT_CONSOLE.DISCONNECTED_FROM_BACKEND';
export const SELECT_ACTIVE_QUEUE = 'AGENT_CONSOLE.SELECT_ACTIVE_QUEUE';
export const START_SENDING_MESSAGE = 'AGENT_CONSOLE.START_SENDING_MESSAGE';
export const START_TRANSMITTING_MESSAGE_MEDIA =
  'AGENT_CONSOLE.START_TRANSMITTING_MESSAGE_MEDIA';
export const SEND_MESSAGE_OK = 'AGENT_CONSOLE.SEND_MESSAGE_OK';
export const SEND_MESSAGE_FAIL = 'AGENT_CONSOLE.SEND_MESSAGE_FAIL';
export const NEW_AGENT_CHAT_FOR_AGENT =
  'AGENT_CONSOLE.NEW_AGENT_CHAT_FOR_AGENT';
export const AGENT_CHAT_FORCE_ASSIGNED_TO_AGENT_RELOADED_IN_TRAY =
  'AGENT_CONSOLE.AGENT_CHAT_FORCE_ASSIGNED_TO_AGENT_RELOADED_IN_TRAY';
export const SET_NEW_CONTACT = 'AGENT_CONSOLE.SET_NEW_CONTACT';
export const UPDATE_CONTACT = 'AGENT_CONSOLE.UPDATE_CONTACT';
export const RESUME_CHAT_REQUESTED = 'AGENT_CONSOLE.RESUME_CHAT_REQUESTED';
export const SELECT_CHAT_CONTEXT_MENU_OPTION =
  'AGENT_CONSOLE.SELECT_CHAT_CONTEXT_MENU_OPTION';
export const TOGGLE_CHAT_CONTEXT_MENU =
  'AGENT_CONSOLE.TOGGLE_CHAT_CONTEXT_MENU';
export const SET_ACTIVE_CHAT = 'AGENT_CONSOLE.SET_ACTIVE_CHAT';
export const OVERSET_ACTIVE_CHAT = 'AGENT_CONSOLE.OVERSET_ACTIVE_CHAT';
export const SET_ACTIVE_CHAT_FAIL = 'AGENT_CONSOLE.SET_ACTIVE_CHAT_FAIL';
export const SET_TIME_LIMITS_RESULT = 'AGENT_CONSOLE.SET_TIME_LIMITS_RESULT';
export const ACCEPT_ASSIGNED_CHAT_REQUEST =
  'AGENT_CONSOLE.ACCEPT_ASSIGNED_CHAT_REQUEST';
export const AGENT_CHAT_PICKUP_FAIL = 'AGENT_CONSOLE.AGENT_CHAT_PICKUP_FAIL';
export const CHAT_REQUEST_PICKED_UP_BY_PEER =
  'AGENT_CONSOLE.CHAT_REQUEST_PICKED_UP_BY_PEER';
export const FAILED_TO_PICKUP_ALREADY_ASSIGNED_CHAT =
  'AGENT_CONSOLE.FAILED_TO_PICKUP_ALREADY_ASSIGNED_CHAT';
export const CHAT_FORCED_ASSIGNED_TO_AGENT =
  'AGENT_CONSOLE.CHAT_FORCED_ASSIGNED_TO_AGENT';
export const START_DRAGGING_CHAT = 'AGENT_CONSOLE.START_DRAGGING_CHAT';
export const STOP_DRAGGING_CHAT = 'AGENT_CONSOLE.STOP_DRAGGING_CHAT';
export const CHAT_REQUESTS_FOCUSED = 'AGENT_CONSOLE.CHAT_REQUESTS_FOCUSED';
export const CHATS_ONGOING_FOCUSED = 'AGENT_CONSOLE.CHATS_ONGOING_FOCUSED';

export const AWAIT_AGENT_CHAT_ACTIVATION =
  'AGENT_CONSOLE.AWAIT_AGENT_CHAT_ACTIVATION';
export const AWAIT_AGENT_CHAT_CLOSE = 'AGENT_CONSOLE.AWAIT_AGENT_CHAT_CLOSE';
export const AWAIT_AGENT_CHAT_EXPORTED_TO_CRM =
  'AGENT_CONSOLE.AWAIT_AGENT_CHAT_EXPORTED_TO_CRM';
export const CHAT_CLOSE_OK = 'AGENT_CONSOLE.CHAT_CLOSE_OK';
export const CHAT_CLOSE_FAIL = 'AGENT_CONSOLE.CHAT_CLOSE_FAIL';
export const ACK_LAST_CHAT_CLOSE_ERROR =
  'AGENT_CONSOLE.ACK_LAST_CHAT_CLOSE_ERROR';
export const ACK_LAST_CHAT_CRM_EXPORT_ERROR =
  'AGENT_CONSOLE.ACK_LAST_CHAT_CRM_EXPORT_ERROR';
export const CHAT_CLOSED_BY_CONTACT = 'AGENT_CONSOLE.CHAT_CLOSED_BY_CONTACT';
export const CHAT_CLOSED_BY_AGENT = 'AGENT_CONSOLE.CHAT_CLOSED_BY_AGENT';
export const ACTIVE_CHAT_CLOSED_BY_AGENT =
  'AGENT_CONSOLE.ACTIVE_CHAT_CLOSED_BY_AGENT';
export const CHAT_LEFT_BY_CONTACT = 'AGENT_CONSOLE.CHAT_LEFT_BY_CONTACT';
export const REOPENED_CHAT = 'AGENT_CONSOLE.REOPENED_CHAT';
export const CHAT_EXPORT_CRM_OK = 'AGENT_CONSOLE.CHAT_EXPORT_CRM_OK';
export const CHAT_EXPORT_CRM_FAIL = 'AGENT_CONSOLE.CHAT_EXPORT_CRM_FAIL';
export const OPEN_EMOTICON_SELECTOR = 'AGENT_CONSOLE.OPEN_EMOTICON_SELECTOR';
export const CLOSE_EMOTICON_SELECTOR = 'AGENT_CONSOLE.CLOSE_EMOTICON_SELECTOR';
export const LOAD_CONTACT_FORM = 'AGENT_CONSOLE.LOAD_CONTACT_FORM';
export const UPDATE_CONTACT_FORM = 'AGENT_CONSOLE.UPDATE_CONTACT_FORM';
export const CHANGE_CONTACT_FORM_FIELD =
  'AGENT_CONSOLE.CHANGE_CONTACT_FORM_FIELD';
export const CHANGE_CUSTOM_CONTACT_FORM_FIELD =
  'AGENT_CONSOLE.CHANGE_CUSTOM_CONTACT_FORM_FIELD';
export const SAVE_CONTACT_REQUEST_INVALID =
  'AGENT_CONSOLE.SAVE_CONTACT_REQUEST_INVALID';
export const START_SAVING_CONTACT = 'AGENT_CONSOLE.SAVE_CONTACT';
export const SAVE_CONTACT_OK = 'AGENT_CONSOLE.SAVE_CONTACT_OK';
export const SAVE_CONTACT_FAIL = 'AGENT_CONSOLE.SAVE_CONTACT_FAIL';
export const LOADING_CHAT_HISTORY = 'AGENT_CONSOLE.LOADING_CHAT_HISTORY';
export const LOADED_CHAT_HISTORY_OK = 'AGENT_CONSOLE.LOADED_CHAT_HISTORY_OK';
export const LOADED_CHAT_HISTORY_FAILED =
  'AGENT_CONSOLE.LOADED_CHAT_HISTORY_FAILED';
export const AGENT_START_CHAT_REQUESTED =
  'AGENT_CONSOLE.AGENT_START_CHAT_REQUESTED';
export const AWAIT_AGENT_STARTED_CHAT =
  'AGENT_CONSOLE.AWAIT_AGENT_STARTED_CHAT';
export const AGENT_STARTED_CHAT_OK = 'AGENT_CONSOLE.AGENT_STARTED_CHAT_OK';
export const AGENT_STARTED_CHAT_FAIL = 'AGENT_CONSOLE.AGENT_STARTED_CHAT_FAIL';
export const AGENT_STARTED_CHAT_RESET =
  'AGENT_CONSOLE.AGENT_STARTED_CHAT_RESET';
export const READ_MESSAGES = 'AGENT_CONSOLE.READ_MESSAGES';
export const MESSAGE_READ_BY_CONTACT = 'AGENT_CONSOLE.MESSAGE_READ_BY_CONTACT';
export const SET_VISIBILITY = 'SET_VISIBILITY';
export const OUT_MESSAGE_DELIVERY_FAILED =
  'AGENT_CONSOLE.OUT_MESSAGE_DELIVERY_FAILED';
export const OUT_MESSAGE_RECEIPT = 'AGENT_CONSOLE.OUT_MESSAGE_RECEIPT';
export const OUT_MESSAGE_DELIVERY_CONFIRMED =
  'AGENT_CONSOLE.OUT_MESSAGE_DELIVERY_CONFIRMED';
export const SET_AGENTCHAT_TRANSFER = 'AGENT_CONSOLE.SET_AGENTCHAT_TRANSFER';
export const UNSET_AGENTCHAT_TRANSFER =
  'AGENT_CONSOLE.UNSET_AGENTCHAT_TRANSFER';
export const START_LOADING_CHAT_INCOMING_TRAY =
  'START_LOADING_CHAT_INCOMING_TRAY';
export const START_LOADING_CHAT_ONGOING_TRAY =
  'START_LOADING_CHAT_ONGOING_TRAY';
export const CHAT_INCOMING_TRAY_CHUNK_LOADED =
  'CHAT_INCOMING_TRAY_CHUNK_LOADED';
export const CHAT_INCOMING_TRAY_CHUNK_LOAD_FAILED =
  'CHAT_INCOMING_TRAY_CHUNK_LOAD_FAILED';
export const CHAT_ONGOING_TRAY_CHUNK_LOADED = 'CHAT_ONGOING_TRAY_CHUNK_LOADED';
export const CHAT_ONGOING_TRAY_CHUNK_LOAD_FAILED =
  'CHAT_ONGOING_TRAY_CHUNK_LOAD_FAILED';
export const APPEND_ONGOING_RELOADED_CHAT = 'APPEND_ONGOING_RELOADED_CHAT';
export const CHAT_SEARCH_SAVE_NEW_CHATS = 'CHAT_SEARCH_SAVE_NEW_CHATS';
export const CHAT_SEARCH_SAVE_NEW = 'CHAT_SEARCH_SAVE_NEW';
export const CHAT_SEARCH_FETCH_SINGLE_CHAT = 'CHAT_SEARCH_FETCH_SINGLE_CHAT';
export const CHAT_SEARCH_CLEAN_STATE = 'CHAT_SEARCH_CLEAN_STATE';
export const SHOW_PIN_CHAT = 'AGENT_CONSOLE.SHOW_PIN_CHAT';
export const UPDATE_PIN_CHAT = 'AGENT_CONSOLE.UPDATE_PIN_CHAT';
export const ERROR_PIN_CHAT = 'AGENT_CONSOLE.ERROR_PIN_CHAT';
export const INCOMING_REACTION = 'INCOMING_REACTION';

const logger = Logger.getLogger('actions-agentconsole');

export function connectedToBackend() {
  return {
    type: CONNECTED_TO_BACKEND,
  };
}

export function disconnectedFromBackend(closeEvent) {
  return {
    type: DISCONNECTED_FROM_BACKEND,
    closeEvent,
  };
}

export function resetAgentConsoleState(newState) {
  return {
    type: RESET_AGENT_CONSOLE_STATE,
    state: newState,
  };
}

// Action Creator: agent selected a queue she is to be logged into
export function selectActiveQueue(selectedQueueId) {
  return {
    type: SELECT_ACTIVE_QUEUE,
    selectedQueueId,
  };
}

// Retrieves from the state, the sorting mode set on the specified tray
const getSortingModeOfTray = (trayName, state) => {
  if (trayName === 'INCOMING') {
    return _get(
      state,
      'generalProperties.preferences.sortModeIncomingChats',
      null
    );
  }
  if (trayName === 'ONGOING') {
    return _get(
      state,
      'generalProperties.preferences.sortModeOngoingChats',
      null
    );
  }
  return null;
};

// Retrieves from the state, the object representing the specified tray
const getTray = (trayName, state) => {
  if (trayName === 'INCOMING') {
    return _get(state, 'agentConsole.chatRequests', {});
  }
  if (trayName === 'ONGOING') {
    return _get(state, 'agentConsole.chatsOngoing', {});
  }
  return null;
};

// Tells if the specified tray is full to the maximum size specified in the merchant's preferences
const isTrayFull = (trayName, state) => {
  const tray = getTray(trayName, state);
  const baseTraySize = _get(tray, 'baseSize', 0);
  const trayItems = _get(tray, 'items', {});
  return (
    baseTraySize > 0 && _size(trayItems) > 0 && _size(trayItems) >= baseTraySize
  );
};

// Determines the ID of the chat that should be evicted from the specified tray, assuming that it's full
const selectChatToEvictChatFromTray = (
  state,
  allChats,
  trayItems,
  sortMode,
  newChat,
  activeChatId
) => {
  const sortedTrayItems = ChatSorting.sortChatArray(
    allChats,
    trayItems,
    sortMode
  );
  const lastChat =
    sortedTrayItems.length > 0
      ? sortedTrayItems[sortedTrayItems.length - 1]
      : null;
  const isNewChatLast = ChatSorting.isBeforeAsToSortingMode(
    lastChat,
    newChat,
    sortMode
  );
  const lastChatId = _get(lastChat, 'id', null);

  if (_get(newChat, 'chatXferRequest', null) !== null) {
    return null;
  }
  if (isNewChatLast && newChat.id !== activeChatId) {
    // New chat is after the last chat in tray and is not active. Discard it
    return newChat.id;
  }
  if (lastChatId !== null && lastChatId === activeChatId) {
    // Last chat is the active chat, remove the chat before
    return sortedTrayItems.length > 1
      ? sortedTrayItems[sortedTrayItems.length - 2].id
      : null;
  }

  return lastChatId;
};

// Before dispatching an action that would add a new chat to the specified tray, decides if the new chat should
// be discarded, based on the size limits defined on that tray
const checkIfShouldEvictChatFromTrayAndSelect = (
  trayName,
  state,
  newChat,
  focusOnChat = false
) => {
  let sortName = null;
  let allChats = null;
  let activeChatId = null;
  const tray = getTray(trayName, state);
  const trayItems = _get(tray, 'items', {});

  if (isTrayFull(trayName, state)) {
    sortName = getSortingModeOfTray(trayName, state);
    allChats = _get(state, 'agentConsole.chats', {});
    if (focusOnChat) {
      activeChatId = _get(newChat, 'id', null);
    } else {
      activeChatId = _get(
        state,
        'agentConsole.chatsOngoing.activeChat.chatId',
        null
      );
    }
    return selectChatToEvictChatFromTray(
      state,
      allChats,
      trayItems,
      sortName,
      newChat,
      activeChatId
    );
  }

  return null;
};

// Action Creator: a new agent chat that is already assigned to an agent has been initiated
export function newAgentChatForAgent(agentChat) {
  return (dispatch, getState) => {
    const chatIdToEvict = checkIfShouldEvictChatFromTrayAndSelect(
      'ONGOING',
      getState(),
      agentChat,
      true
    );
    dispatch({
      type: NEW_AGENT_CHAT_FOR_AGENT,
      agentChat,
      chatIdToEvict,
    });
  };
}

// Action Creator: a new contact is detected (for instance, in an agent chat request)
export function setNewContact(contact) {
  return {
    type: SET_NEW_CONTACT,
    contact,
  };
}

// Action Creator: a contact has been updated
export function updateContact(contact, prevId) {
  return {
    type: UPDATE_CONTACT,
    contact,
    prevId,
  };
}

export function appendOngoingReloadedChat(chat) {
  return {
    type: APPEND_ONGOING_RELOADED_CHAT,
    chat,
  };
}

// Action Creator: sub-action triggered from thunk: sendXXXMessage. Message is being delivered to the backend
export function startSendingMessage(chatId, message) {
  return {
    type: START_SENDING_MESSAGE,
    chatId,
    messageId: message.messageId,
    message,
  };
}

// Action Creator: sub-action triggered from thunk: sendXXXFileMessage. Message is being delivered to the backend
export function startTransmittingMessageMedia(message) {
  return {
    type: START_TRANSMITTING_MESSAGE_MEDIA,
    message,
  };
}

// Action Creator: sub-action triggered from thunk: sendTextMessage. Confirm successful delivery of agent message
export function sendMessageOk(sentMessage) {
  return {
    type: SEND_MESSAGE_OK,
    message: sentMessage,
    messageId: sentMessage.messageId,
  };
}

// Action Creator: sub-action triggered from thunk: sendTextMessage. Report failure delivering agent message
export function sendMessageFail(sentMessage, error) {
  return {
    type: SEND_MESSAGE_FAIL,
    message: sentMessage,
    messageId: sentMessage.messageId,
    error,
  };
}

export const newAutomaticMessage = createAction<{
  chatId: string;
  chatEvent: ChatEvent;
}>('NEW_AUTOMATIC_MESSAGE');

// Action Creator: action triggered when an option is chosen on the Chat Context Menu
export function selectChatContextMenuOption(selectedOption) {
  return {
    type: SELECT_CHAT_CONTEXT_MENU_OPTION,
    selectedOption,
  };
}

// Action Creator: action triggered when the context menu is opened/closed
export function toggleChatContextMenu(opened) {
  return {
    type: TOGGLE_CHAT_CONTEXT_MENU,
    opened,
  };
}

export function updateReadmessages(chatId, providerMessageIds) {
  return {
    type: READ_MESSAGES,
    chatId,
    providerMessageIds,
  };
}

export function notifyReadMessages(chatEvents, chatId) {
  return dispatch => {
    const providerMessageIds = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const event of chatEvents) {
      if (
        event.type === EventType.INCOMING_MESSAGE.id &&
        event.payload.read === false &&
        event.payload.providerMessageId
      ) {
        console.log(
          'Adding incoming msg for read notification',
          event.payload.providerMessageId
        );
        providerMessageIds.push(event.payload.providerMessageId);
      }
    }

    const okCallback = () => {
      dispatch(updateReadmessages(chatId, providerMessageIds));
    };

    const failCallback = (resultCode, error) => {
      console.error('Error notifying read messages', error);
    };

    if (providerMessageIds.length > 0) {
      AgentChatManager.notifyReadMessages(
        providerMessageIds,
        chatId,
        okCallback,
        failCallback
      );
    }
  };
}

export function startDraggingChat(draggedChatId) {
  return {
    type: START_DRAGGING_CHAT,
    chatId: draggedChatId,
  };
}

export function stopDraggingChat(draggedChatId) {
  return {
    type: STOP_DRAGGING_CHAT,
    chatId: draggedChatId,
  };
}

export function awaitAgentChatActivation(chatId) {
  return {
    type: AWAIT_AGENT_CHAT_ACTIVATION,
    chatId,
  };
}

export function saveContactRequestInvalid(errors) {
  return {
    type: SAVE_CONTACT_REQUEST_INVALID,
    errors,
  };
}

export function saveContactOk(contactId) {
  return {
    type: SAVE_CONTACT_OK,
    contactId,
  };
}

export function saveContactFail(contact, error) {
  return {
    type: SAVE_CONTACT_FAIL,
    contact,
    error: error.status,
  };
}

// Executes a thunk action that firstly validates and then saves a contact (new or existing).
// Returns a promise that gets resolved when contact be successfully saved or rejected otherwise.
// The resolve function gets the ID of the contact saved as argument, the reject function gets an object:
// { errorType: <str, type of error, either INVALID_CONTACT or SERVICE_ERROR>, errorDetails: <obj, error info> }
export const execValidateAndSaveContactAction = ({
  botId,
  chatId,
  contactId,
  provider,
  cities = [],
  fields = {},
}) => {
  let saveContactPromise = null;
  const { dispatch, getState } = window.store;

  // Update contact into the state using the data stored into the contact form
  const { contactForm } = getState().agentConsole;

  // Validate the form and if there are errors, reject immediatly
  const fieldsWithError = ContactForm.validateForm(contactForm);

  if (fieldsWithError && fieldsWithError.length > 0) {
    // eslint-disable-next-line prefer-promise-reject-errors
    saveContactPromise = Promise.reject({
      errorType: 'INVALID_CONTACT',
      errorDetails: null,
    });

    saveContactPromise.catch(() => {
      dispatch(saveContactRequestInvalid(fieldsWithError.length));
    });
  } else {
    const newContact = ContactForm.toContactObject(contactForm, cities);
    newContact.attributes = fields.filter(item => item.value?.trim());

    saveContactPromise = AgentChatManager.saveContact({
      botId,
      contactId,
      provider,
      newContact,
    });

    saveContactPromise
      .then(savedContactId => {
        dispatch(
          saveContactChat({
            chatId,
            contact: { ...newContact, id: savedContactId },
          })
        );
        dispatch(saveContactOk(savedContactId));
      })
      .catch(rejVal => {
        dispatch(saveContactFail(newContact, rejVal.errorDetails));
      });
  }

  return saveContactPromise;
};

export function awaitAgentChatExportedToCrm(chatId) {
  return {
    type: AWAIT_AGENT_CHAT_EXPORTED_TO_CRM,
    chatId,
  };
}

// Thunk Action Creator: agent requested a chat to be exported to a CRM service
export function exportActiveChatToCrm(chatId, crmConnector) {
  return () => {
    // Send an export-chat-to-CRM request via the backend
    AgentChatManager.requestExportChatToCrm(chatId, crmConnector);
  };
}

export function oversetActiveChat(chatId) {
  return {
    type: OVERSET_ACTIVE_CHAT,
    chatId,
  };
}

export function setTimeLimitsResult(
  chatId,
  isCloseToMaxTime,
  chatHasPassedMaxTime
) {
  return {
    type: SET_TIME_LIMITS_RESULT,
    chatId,
    isCloseToMaxTime,
    chatHasPassedMaxTime,
  };
}

export function acceptAssignedChatRequest(assignedChat, assignedTo) {
  if (_get(assignedChat, 'id', null) === null) {
    throw new Error('Failed to assign invalid chat');
  }
  return (dispatch, getState) => {
    const chatIdToEvict = checkIfShouldEvictChatFromTrayAndSelect(
      'ONGOING',
      getState(),
      assignedChat,
      true
    );
    dispatch({
      type: ACCEPT_ASSIGNED_CHAT_REQUEST,
      chatId: assignedChat.id,
      assignedTo,
      chatIdToEvict,
    });
  };
}

export function agentChatPickupFail(chatId, reason) {
  return {
    type: AGENT_CHAT_PICKUP_FAIL,
    chatId,
    reason,
  };
}

export function failedToPickupAlreadyAssignedChat(chatId, agentId) {
  return {
    type: FAILED_TO_PICKUP_ALREADY_ASSIGNED_CHAT,
    chat: { id: chatId },
    agentId,
  };
}

export function chatRequestPickedUpByPeer(pickedUpChat, agentId) {
  return {
    type: CHAT_REQUEST_PICKED_UP_BY_PEER,
    chat: pickedUpChat,
    agentId,
  };
}

export function forceAssignChatRequest(assignedChatId, assignedTo) {
  return (dispatch, getState) => {
    const agentChat = _get(
      getState(),
      `agentConsole.chats[${assignedChatId}]`,
      null
    );
    const curUser = _get(getState(), 'loginAuthentication.success', null);
    const forceAssigedAction = {
      type: CHAT_FORCED_ASSIGNED_TO_AGENT,
      chatId: assignedChatId,
      assignedTo,
    };
    let chatIdToEvict = null;

    if (agentChat !== null) {
      chatIdToEvict = checkIfShouldEvictChatFromTrayAndSelect(
        'ONGOING',
        getState(),
        agentChat,
        false
      );
      forceAssigedAction.chatIdToEvict = chatIdToEvict;
      dispatch(forceAssigedAction);
    } else {
      // The chat is not present in the incoming tray. Reload chat from the backend before triggering the action
      if (curUser === null || curUser.id === null) {
        dispatch(forceAssigedAction);
        return;
      }

      BackendProxy.loadChatById(curUser.id, assignedChatId)
        .then(rawAgentChat =>
          AgentChatManager.triggerNewChatReloaded(rawAgentChat, true)
        )
        .catch(error =>
          console.error(`Failed to reload chat ${assignedChatId}`, error)
        );
    }
  };
}

export function acknowledgeLastChatCloseError() {
  return {
    type: ACK_LAST_CHAT_CLOSE_ERROR,
  };
}

export function acknowledgeLastChatCrmExportError() {
  return {
    type: ACK_LAST_CHAT_CRM_EXPORT_ERROR,
  };
}

export function chatClosedByContact(closedChat, newChatStatus, eventPayload) {
  return {
    type: CHAT_CLOSED_BY_CONTACT,
    chat: closedChat,
    newChatStatus,
    eventPayload,
  };
}

export function chatClosedByAgent(
  closedChat,
  isActive = false,
  ownedButNotPresentInTray = false
) {
  return {
    type: isActive ? ACTIVE_CHAT_CLOSED_BY_AGENT : CHAT_CLOSED_BY_AGENT,
    chat: closedChat,
    ownedButNotPresentInTray,
  };
}

export function chatLeftByContact(leftChat, newChatStatus, eventPayload) {
  return {
    type: CHAT_LEFT_BY_CONTACT,
    chat: leftChat,
    newChatStatus,
    eventPayload,
  };
}

export function chatReopened(reOpenedChat, newChatStatus, eventPayload) {
  return {
    type: REOPENED_CHAT,
    chat: reOpenedChat,
    newChatStatus,
    eventPayload,
  };
}

export function chatExportedToCrmOk(chat, ticketId, crmConnector) {
  return {
    type: CHAT_EXPORT_CRM_OK,
    chat,
    crmTicketId: ticketId,
    crmConnector,
  };
}

export function chatExportedToCrmFail(
  chat,
  crmConnector,
  errorMessage,
  errorDetails = null
) {
  return {
    type: CHAT_EXPORT_CRM_FAIL,
    chat,
    crmConnector,
    errorMessage,
    errorDetails,
  };
}

export function chatRequestsFocused() {
  return {
    type: CHAT_REQUESTS_FOCUSED,
  };
}

export function chatsOngoingFocused() {
  return {
    type: CHATS_ONGOING_FOCUSED,
  };
}

export function openEmoticonSelector(anchorEl) {
  return {
    type: OPEN_EMOTICON_SELECTOR,
    anchorEl,
  };
}

export function closeEmoticonSelector() {
  return {
    type: CLOSE_EMOTICON_SELECTOR,
  };
}

export function loadContactForm(contact, msgAccount, arrCitiesDataSet) {
  return {
    type: LOAD_CONTACT_FORM,
    contact,
    messagingAccount: msgAccount,
    arrCitiesDataSet,
  };
}

export function changeContactFormField(fieldName, newFieldValue) {
  return {
    type: CHANGE_CONTACT_FORM_FIELD,
    fieldName,
    fieldValue: newFieldValue,
  };
}

export function changeCustomContactFormField(attrId, fieldValue) {
  return {
    type: CHANGE_CUSTOM_CONTACT_FORM_FIELD,
    attrId,
    fieldValue,
  };
}

export function startSavingContact(contact) {
  return {
    type: START_SAVING_CONTACT,
    contact,
  };
}

export function loadingChatHistory(requestId = null, clearHistory = false) {
  return {
    type: LOADING_CHAT_HISTORY,
    requestId,
    clearHistory,
  };
}

export function loadedChatHistoryOk(chatHistory, requestId = null) {
  logger.log('Triggering action loadedChatHistoryOk. Request ID:', requestId);

  return {
    type: LOADED_CHAT_HISTORY_OK,
    chatHistory,
    requestId,
  };
}

export function loadedChatHistoryFailed(errorMsg, errorResp, requestId = null) {
  logger.log(
    'Triggering action loadedChatHistoryFailed. Request ID:',
    requestId
  );

  return {
    type: LOADED_CHAT_HISTORY_FAILED,
    errorMsg,
    errorResp,
    timestamp: Date.now(),
    requestId,
  };
}

// Thunk action: load chat history of a contact messaging account through a specific bot messaging account
export function requestContactChatHistory(
  msgAccount,
  viaBotAccount = null,
  viaMsgProvider = null,
  fromStartTimestamp = null,
  count = null
) {
  return dispatch => {
    // Generate a UUID to trace the result of this load-chat-history request
    const requestId = StringUtils.getUuid();

    // Clear existing history if no starting point and number of chats to be loaded were specified
    const clearHistory =
      (fromStartTimestamp == null && count == null) ||
      (fromStartTimestamp === 0 && count === 0);

    logger.log(
      'In requestContactChatHistory. Loading chat history. Clear, reqId: ',
      clearHistory,
      requestId
    );

    // Announce that the chat history for the currently selected chat is being loaded
    dispatch(loadingChatHistory(msgAccount, requestId, clearHistory));

    // Load the chat history of the specified contact messaging account via web services
    AgentChatManager.loadChatHistory(
      msgAccount,
      viaBotAccount,
      viaMsgProvider,
      fromStartTimestamp,
      count,
      requestId
    );
  };
}

// Notifies that a message has been read by the destinatary
export function markOutMessageReadByContact(chatId, messageIds) {
  return {
    type: MESSAGE_READ_BY_CONTACT,
    chatId,
    messageIds,
  };
}

// Notifies that a message delivery has been acknowledged by the messaging provider
export function markOutMessageDeliveryAck(chatId, messageId) {
  return {
    type: OUT_MESSAGE_DELIVERY_CONFIRMED,
    chatId,
    messageId,
  };
}

// Notifies that a message has been received by the destinatary
export function markOutMessageReceipt(chatId, messageId) {
  return {
    type: OUT_MESSAGE_RECEIPT,
    chatId,
    messageId,
  };
}

// Notifies that a message has been received by the destinatary
export function markOutMessageDeliveryFailed(chatId, messageId) {
  return {
    type: OUT_MESSAGE_DELIVERY_FAILED,
    chatId,
    messageId,
  };
}

// Notifies that a message has been read by the destinatary
export function markIncomingReaction(chatId, { messageId, emoji }) {
  return {
    type: INCOMING_REACTION,
    chatId,
    messageId,
    emoji,
  };
}

export function agentStartChatRequested(contactMsgAccount, requestTimestamp) {
  return {
    type: AGENT_START_CHAT_REQUESTED,
    contactMsgAccount,
    requestTimestamp,
  };
}

export function awaitAgentStartedChat(
  contactMsgAccount,
  chatId,
  requestTimestamp,
  rqId = null
) {
  return {
    type: AWAIT_AGENT_STARTED_CHAT,
    contactMsgAccount,
    chatId,
    requestTimestamp,
    rqId,
  };
}

export function agentStartedChatOk(chatId, rqId = null) {
  return {
    type: AGENT_STARTED_CHAT_OK,
    chatId,
    rqId,
  };
}

export function agentStartedChatFailed(
  contactMsgAccount,
  requestTimestamp,
  errorCode = null,
  errorDetails = null,
  rqId = null
) {
  return {
    type: AGENT_STARTED_CHAT_FAIL,
    contactMsgAccount,
    requestTimestamp,
    errorCode,
    errorDetails,
    rqId,
  };
}

export function resetAgentStartedChat() {
  return {
    type: AGENT_STARTED_CHAT_RESET,
  };
}

// Thunk action: request a new chart to be started with the specified contact, in behalf of the current user
export function startChatWithContact(
  botId,
  viaBotAccount,
  viaMsgProvider,
  contactMsgAccount,
  contactId,
  templatedMsgId
) {
  return () => {
    // Request agent chat to be started on behalf of the current user
    AgentChatManager.requestStartChat(
      botId,
      viaBotAccount,
      viaMsgProvider,
      contactMsgAccount,
      contactId,
      templatedMsgId
    );
  };
}

export function unsetAgentChatTransfer(
  chatId,
  canRemove = false,
  gotAcceptedOrCancelled = false
) {
  return {
    type: UNSET_AGENTCHAT_TRANSFER,
    chatId,
    canRemove,
    gotAcceptedOrCancelled,
  };
}

export function saveNewChatSearch(chat) {
  return {
    type: CHAT_SEARCH_SAVE_NEW,
    chat,
  };
}

export function saveNewChatsSearch(chats, incoming) {
  return {
    type: CHAT_SEARCH_SAVE_NEW_CHATS,
    chats,
    incoming,
  };
}
export function chatSearchFetchSingle(chatId) {
  return {
    type: CHAT_SEARCH_FETCH_SINGLE_CHAT,
    payload: { chatId },
  };
}

export function cleanChatSearchStateAgentConsole(chatId) {
  return {
    type: CHAT_SEARCH_CLEAN_STATE,
    chatId,
  };
}

export function updateContactForm(contact) {
  return {
    type: UPDATE_CONTACT_FORM,
    contact,
  };
}

export function showPinChat(chatId) {
  return {
    type: SHOW_PIN_CHAT,
    chatId,
  };
}

export function updatePinChat(chat) {
  return {
    type: UPDATE_PIN_CHAT,
    chat,
  };
}

export function errorPinChat() {
  return {
    type: ERROR_PIN_CHAT,
  };
}
