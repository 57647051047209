import { Component } from 'react';
import Log from '../../utils/logging';
import './LogDisplay.css';

class LogDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggingEnabled: Log.isLoggingEnabled(),
      logEntries: [],
      posX: 100,
      posY: 100,
    };

    this.handleNewEntry = this.handleNewEntry.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.updateLog = this.updateLog.bind(this);

    Log.subscribe(this.handleNewEntry, this.handleReset);
  }

  componentDidMount() {
    this.updateLog();
  }

  handleNewEntry(line) {
    // Not implemented
  }

  handleDragEnd(ev) {
    if (this.state.dragStartX === null || this.state.dragStartY === null) {
      return;
    }

    this.setState({
      posX: ev.pageX - this.state.dragStartX,
      posY: ev.pageY - this.state.dragStartY,
      dragStartX: null,
      dragStartY: null,
    });
  }

  handleReset() {
    this.setState({
      logEntries: [],
      dragStartX: null,
      dragStartY: null,
    });
  }

  handleDragStart(ev) {
    this.setState({
      dragStartX: ev.pageX - this.state.posX,
      dragStartY: ev.pageY - this.state.posY,
    });
  }

  updateLog() {
    this.setState({
      logEntries: Log.getLogEntries(),
    });

    setTimeout(this.updateLog, 350);
  }

  render() {
    if (!this.state.isLoggingEnabled) {
      return null;
    }

    const renderedLines = [];

    renderedLines.push(
      <div key={0}>
        <span>--- Log Start ---</span>
      </div>
    );

    if (this.state.logEntries) {
      for (let i = 0; i < this.state.logEntries.length; i++) {
        const logEntry = this.state.logEntries[i];
        const logLine = `${logEntry.time} - ${logEntry.source}. ${logEntry.msg}`;

        renderedLines.push(
          <div key={i + 1}>
            <span>{logLine}</span>
          </div>
        );
      }
    }

    renderedLines.push(
      <div key={renderedLines.length}>
        <span>--- Log End ---</span>
      </div>
    );

    const { posX } = this.state;
    const { posY } = this.state;

    return (
      <div
        className="LogDisplay"
        style={{ left: `${posX}px`, top: `${posY}px` }}
      >
        <h1
          className="LogTitleBar"
          draggable
          onDragStart={this.handleDragStart}
          onDrag={this.handleDrag}
          onDragEnd={this.handleDragEnd}
        >
          DEBUG MODE
        </h1>
        <div className="LogContents">{renderedLines}</div>
      </div>
    );
  }
}

export default LogDisplay;
