const buildInitialCreateProductState = function () {
  return {
    productId: null,
    price: 0,
    skuReference: '',
    productName: '',
    description: '',
    active: null,
    imageUrl: '',
    currentAttribute: '',
    currentValue: '',
    variants: {},
    listAttributes: [],
    listValues: [],
    listCombinations: [],
    file: '',
    imagePreviewUrl: '',
    selectedIndexes: [],
    rows: [],
    errorMessage: '',
    categoriesList: [],
    categoriesListStatus: '',
    categoryId: '',
    scrollTop: 0,
    listReferences: [],
    imageError: '',
    savingProduct: '',
    listCombinationsAllVisible: null,
    categoryModalOpen: false,
    newCategoryName: '',
  };
};

export default buildInitialCreateProductState;
