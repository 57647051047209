/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
const StringUtils = (() => {
  // Private variables
  const emojiRegex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const posIntegerRegex = /^\d+$/;

  return {
    // Robustly checks if the provided object is of type string
    isString(obj: unknown) {
      return Object.prototype.toString.call(obj) === '[object String]';
    },

    // Checks whether the specified string is empty, null or blank (contains whitespaces only)
    isBlank(str?: string) {
      if (str === undefined || str === null) {
        return true;
      }

      return /^\s*$/.test(str);
    },

    // Removes control characters (char <= 32) from both ends of this String returning an empty
    // String ("") if the String is empty ("") after the trim or if it is null
    trimToEmpty(str: string) {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }

      return str.trim();
    },

    // Generates an rfc4122 compliant UUID. Refer to:
    // http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    getUuid() {
      let d = new Date().getTime();
      if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
    },

    // TODO: Remove unused methods getUuidv1 and getUuidv2
    // Generates an rfc4122 compliant UUID. Refer to:
    // http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    getUuidv1() {
      const uuid = 'xxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });

      return uuid;
    },

    // Generates an rfc4122 compliant UUID. Refer to:
    // http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    getUuidv2() {
      // http://www.ietf.org/rfc/rfc4122.txt
      const s: string[] = [];
      const hexDigits = '0123456789abcdef';
      for (let i = 0; i < 36; i += 1) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-';

      const uuid = s.join('');
      return uuid;
    },

    // Returns a regular expression that matches any emoji characters within a string
    getEmojiRegex() {
      return emojiRegex;
    },

    // Checks if the specified value is a valid e-mail. Note that this method does not regard null/unspecified values
    // as invalid, but returns true in such cases
    isValidEmail(email: string) {
      if (!email) {
        return true;
      }

      return emailRegex.test(String(email));
    },

    // Checks if the specified number is a valid positive integer. Note that this method does not regard null/unspecified values
    // as invalid, but returns true in such cases
    isValidPositiveInteger(number: number) {
      if (!number) {
        return true;
      }

      return posIntegerRegex.test(String(number));
    },
  };
})();

export default StringUtils;
