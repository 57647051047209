/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData, User } from '@types';

export type UsersState = {
  loading: 'idle' | 'success' | 'pending';
  items: User[];
  error?: ErrorData;
};

const initialState: UsersState = {
  loading: 'idle',
  items: [],
  error: undefined,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers() {},
    fetchingUsers(state) {
      state.loading = 'pending';
      state.error = undefined;
      state.items = [];
    },
    fetchUsersSuccess(state, action: PayloadAction<User[]>) {
      state.items = action.payload.sort((a, b) =>
        a.fullName.localeCompare(b.fullName)
      );
      state.loading = 'success';
    },
    fetchUsersFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchUsersFulfill(state) {
      state.loading = 'idle';
    },
  },
});

export default usersSlice.reducer;

export const {
  fetchUsers,
  fetchingUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  fetchUsersFulfill,
} = usersSlice.actions;
