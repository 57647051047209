import { defineMessages } from 'react-intl';
import { dialogTitleTypes } from '../model/frontendmodel';
//import BackendProxy from'../components/AdminHome/CreateProduct/CreateProductMock.js';
import BackendProxy from '../logic/backendproxy';
import { openDialogViewer } from './common';

export const LOAD_PRODUCT_ID = 'CREATEPRODUCT.LOAD_PRODUCT_ID';
export const LOAD_PRODUCT_NAME = 'CREATEPRODUCT.LOAD_PRODUCT_NAME';
export const LOAD_PRODUCT_DESC = 'CREATEPRODUCT.LOAD_PRODUCT_DESC';
export const LOAD_ATTRIBUTE_FIELD = 'CREATEPRODUCT.LOAD_ATTRIBUTE_FIELD';
export const LOAD_VALUE_FIELD = 'CREATEPRODUCT.LOAD_VALUE_FIELD';
export const LOAD_LIST_VARIANTS_FIELD =
  'CREATEPRODUCT.LOAD_LIST_VARIANTS_FIELD';
export const LOAD_LIST_ATTRIBUTES_FIELD =
  'CREATEPRODUCT.LOAD_LIST_ATTRIBUTES_FIELD';
export const LOAD_LIST_COMBINATIONS = 'CREATEPRODUCT.LOAD_LIST_COMBINATIONS';
export const LOAD_FILE = 'CREATEPRODUCT.LOAD_FILE';
export const LOAD_IMAGE_PREVIEW_URL = 'CREATEPRODUCT.LOAD_IMAGE_PREVIEW_URL';
export const LOAD_SELECTED_INDEXES = 'CREATEPRODUCT.LOAD_SELECTED_INDEXES';
export const LOAD_ROWS_VALUES = 'CREATEPRODUCT.LOAD_ROWS_VALUES';
export const LOAD_ERROR_MESSAGE = 'CREATEPRODUCT.LOAD_ERROR_MESSAGE';
export const OK_LOADED_CATEGORIES_LIST =
  'CREATEPRODUCT.OK_LOADED_CATEGORIES_LIST';
export const ERROR_LOADED_CATEGORIES_LIST =
  'CREATEPRODUCT.ERROR_LOADED_CATEGORIES_LIST';
export const LOAD_CATEGORY = 'CREATEPRODUCT.LOAD_CATEGORY';
export const LOAD_SCROLL_TOP = 'CREATEPRODUCT.LOAD_SCROLL_TOP';
export const LOAD_LIST_REFERENCES = 'CREATEPRODUCT.LOAD_LIST_REFERENCES';
export const SET_IMAGE_ERROR = 'CREATEPRODUCT.SET_IMAGE_ERROR';
export const SET_CATEGORIES_LIST_STATUS =
  'CREATEPRODUCT.SET_CATEGORIES_LIST_STATUS';
export const SET_CHANGE_PRICE = 'CREATEPRODUCT.SET_CHANGE_PRICE';
export const SET_CHANGE_SKU = 'CREATEPRODUCT.SET_CHANGE_SKU';
export const SET_STATE_ACTIVE = 'CREATEPRODUCT.SET_STATE_ACTIVE';
export const SET_SAVING_PRODUCT = 'CREATEPRODUCT.SET_SAVING_PRODUCT';
export const SET_CATEGORY_MODAL_OPEN = 'CREATEPRODUCT.SET_CATEGORY_MODAL_OPEN';
export const SET_NEW_CATEGORY = 'CREATEPRODUCT.SET_NEW_CATEGORY';

const messages = defineMessages({
  errorServer: { id: 'Error.server' },
  successfullyCreated: { id: 'ProductCreate.successfullyCreated' },
  close: { id: 'ProductCreate.close' },
});

export function createNewCategory() {
  return (dispatch, getState) => {
    const newCategory = getState().adminHome.createProduct.newCategoryName;
    const merchantName = getState().loginAuthentication.success.employer.name;
    const data = {};
    data.newCategory = newCategory;
    data.merchantName = merchantName;
    BackendProxy.createNewCategory(
      data,
      respData => {
        console.log('OK, createNewCategory list: ', respData);
        //dispatch(changeProductIdValue (productId));
        dispatch(categoryModalOpen(false));
        dispatch(changeNewCategory(''));
        dispatch(okLoadedCategoriesList(respData));
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          'Error createNewCategory! Status code: ',
          respStatus,
          error
        );
        dispatch(errorLoadedCategoriesList(error));
      }
    );
  };
}

export function changeProductId(productId) {
  return (dispatch, getState) => {
    console.log(
      '----------------------------------------------productId',
      productId
    );
    dispatch(changeProductIdValue(productId));
    if (productId === null) {
      dispatch(changeProductNameField(''));
      dispatch(changeProductDesc(''));
      dispatch(changeErrorMessage(''));
      dispatch(loadListAttributes([]));
      dispatch(loadListValues([]));
      dispatch(loadCategory(''));
      dispatch(okLoadedCategoriesList([]));
      dispatch(loadListCombinations([]));
      dispatch(clearImage());
      dispatch(setCategoriesListStatus(''));
      dispatch(changePrice(''));
      dispatch(changeSKU(''));
      dispatch(changeStateActive(null));
    }
  };
}

export function changeVariantVisibleAll(newValue) {
  return (dispatch, getState) => {
    const { listCombinations } = getState().adminHome.createProduct;
    for (let i = 0; i < listCombinations.length; i++) {
      listCombinations[i].visible = newValue;
    }
    dispatch(loadListCombinations(listCombinations));
  };
}

export function changeStateProduct() {
  return (dispatch, getState) => {
    const data = {};
    const { productId } = getState().adminHome.createProduct;
    data.productId = productId;
    BackendProxy.changeStateProduct(
      data,
      respData => {
        console.log('OK, changeStateProduct list: ', respData);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          'Error changeStateProduct! Status code: ',
          respStatus,
          error
        );
        dispatch(errorLoadedCategoriesList(error));
      }
    );
  };
}

export function loadProductFromDB(productId) {
  return (dispatch, getState) => {
    dispatch(changeSavingProduct('LOADING'));
    dispatch(changeProductId(productId));
    const data = {};
    const merchantName = getState().loginAuthentication.success.employer.name;
    data.productId = productId;
    data.merchantName = merchantName;
    BackendProxy.getProductList(
      data,
      respData => {
        console.log('OK, loadProductFromDB list: ', respData);
        respData = respData[0];
        //let productId = respData.id;
        const productName = respData.name;
        const productDesc = respData.description;
        const productImage = respData.imageUrl;
        const productCategory = respData.category;
        const { listReferences } = respData;
        const { active } = respData;
        console.log('listReferences', listReferences);
        console.log('active', active);

        dispatch(loadCategories());
        dispatch(changeImagePreviewUrl(productImage));
        dispatch(changeProductListReferences(listReferences));
        dispatch(changeProductNameField(productName));
        dispatch(changeProductDesc(productDesc !== null ? productDesc : ''));
        dispatch(changeStateActive(active));
        if (
          listReferences != null &&
          listReferences.length === 1 &&
          respData.listAttributes.length === 0
        ) {
          dispatch(changePrice(listReferences[0].price));
          dispatch(changeSKU(listReferences[0].skuReference));
        }
        const listAttributesDB = respData.listAttributes;
        if (listAttributesDB !== undefined && listAttributesDB !== null) {
          for (let i = 0; i < listAttributesDB.length; i++) {
            const attributeName = listAttributesDB[i].name;
            dispatch(createNewValue());
            dispatch(changeCurrentAttribute(attributeName, i));
            const { listValues } = listAttributesDB[i];
            const listValuesArrayObject = [];
            if (listValues !== undefined && listValues !== null) {
              for (let j = 0; j < listValues.length; j++) {
                const valueName = listValues[j].name;
                if (valueName !== '') {
                  listValuesArrayObject.push({ id: j, text: valueName });
                }
              }
              dispatch(changeCurrentValue(listValuesArrayObject, i));
            }
          }
        }
        setTimeout(() => {
          if (productCategory !== null) {
            dispatch(loadCategory(productCategory.id));
          }
        }, 1000);
        dispatch(changeSavingProduct('LOADED'));
        console.log('respData', respData);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          'Error loadProductFromDB! Status code: ',
          respStatus,
          error
        );
        dispatch(errorLoadedCategoriesList(error));
      }
    );
  };
}

export function loadCategories() {
  return (dispatch, getState) => {
    const data = {};
    const merchantId = getState().loginAuthentication.success.employer.id;
    const merchantName = getState().loginAuthentication.success.employer.name;
    data.merchantId = merchantId;
    data.merchantName = merchantName;
    BackendProxy.getCategories(
      data,
      respData => {
        console.log('OK, Categories list: ', respData);
        const categories = respData;
        dispatch(okLoadedCategoriesList(categories));
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          'Error Getting categories! Status code: ',
          respStatus,
          error
        );
        dispatch(errorLoadedCategoriesList(error));
      }
    );
  };
}

export function createNewValue() {
  return (dispatch, getState) => {
    let { listAttributes } = getState().adminHome.createProduct;
    let { listValues } = getState().adminHome.createProduct;
    if (listValues === undefined || listValues === null) {
      listValues = [];
      listAttributes = [];
    }
    listValues.push([]);
    listAttributes.push('');
    const errorText = validateAttributesValuesFill(listAttributes, listValues);
    dispatch(changeErrorMessage(errorText));
    dispatch(loadListAttributes(listAttributes));
    dispatch(loadListValues(listValues));
  };
}

export function changeVariantSKU(value, index) {
  return (dispatch, getState) => {
    const { listCombinations } = getState().adminHome.createProduct;
    listCombinations[index].skuReference = value;
    dispatch(loadListCombinations(listCombinations));
  };
}

export function changeVariantPrice(value, index) {
  return (dispatch, getState) => {
    const { listCombinations } = getState().adminHome.createProduct;
    listCombinations[index].price = value;
    dispatch(loadListCombinations(listCombinations));
  };
}

export function changeVariantVisible(value, index) {
  return (dispatch, getState) => {
    const { listCombinations } = getState().adminHome.createProduct;
    listCombinations[index].visible = value;
    dispatch(loadListCombinations(listCombinations));
  };
}

export function clearImage() {
  return (dispatch, getState) => {
    dispatch(changeImagePreviewUrl(''));
    dispatch(changeFile(''));
  };
}

export function validateProductName(productName) {
  return (dispatch, getState) => {
    const { productId } = getState().adminHome.createProduct;
    const merchantName = getState().loginAuthentication.success.employer.name;
    const filters = {};
    filters.merchantName = merchantName;
    //filters['productId'] = productId;
    filters.productName = productName;
    BackendProxy.getProductList(
      filters,
      respData => {
        console.log('OK, changeProductName: ', respData);
        if (respData.length > 0) {
          console.log('............................-> respData', respData);
          dispatch(changeErrorMessage('PRODUCT_ALREADY_EXIST'));
        } else {
          dispatch(changeErrorMessage(''));
          dispatch(changeProductNameField(productName));
        }
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error changeProductName! Status code: ${respStatus}`,
          error
        );
        //dispatch(errorLoadedProductsList(error));
      }
    );
  };
}

export function saveProduct() {
  return (dispatch, getState) => {
    dispatch(changeSavingProduct('LOADING'));
    const { productId } = getState().adminHome.createProduct;
    const { listAttributes } = getState().adminHome.createProduct;
    const { listValues } = getState().adminHome.createProduct;
    const data = {};
    const merchantName = getState().loginAuthentication.success.employer.name;
    const { productName } = getState().adminHome.createProduct;
    const { description } = getState().adminHome.createProduct;
    const { file } = getState().adminHome.createProduct;
    const { imagePreviewUrl } = getState().adminHome.createProduct;
    const { listCombinations } = getState().adminHome.createProduct;
    const { selectedIndexes } = getState().adminHome.createProduct;
    const { rows } = getState().adminHome.createProduct;
    const { categoryId } = getState().adminHome.createProduct;
    const { price } = getState().adminHome.createProduct;
    const { skuReference } = getState().adminHome.createProduct;
    data.productId = productId;
    data.merchantName = merchantName;
    data.productName = productName;
    data.description = description;
    data.listAttributes = listAttributes;
    data.listValues = listValues;
    data.file = file;
    data.imagePreviewUrl = imagePreviewUrl;
    data.listCombinations = listCombinations;
    data.selectedIndexes = selectedIndexes;
    data.rows = rows;
    data.categoryId = categoryId;
    data.price = price;
    data.skuReference = skuReference;
    BackendProxy.saveProduct(
      data,
      respData => {
        dispatch(changeSavingProduct('LOADED'));
        if (respData.success === 'OK') {
          dispatch(
            openDialogViewer(
              true,
              dialogTitleTypes.INFO,
              intl.formatMessage(messages.close),
              intl.formatMessage(messages.successfullyCreated),
              respData.message
            )
          );
        } else if (respData.success === 'NO') {
          dispatch(
            openDialogViewer(
              true,
              dialogTitleTypes.ERROR,
              intl.formatMessage(messages.close),
              intl.formatMessage(messages.errorServer),
              respData.message
            )
          );
        }
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        //dispatch(errorLoadedContactsList(error));
      }
    );
  };
}

function validateAttributesValuesFill(listAttributes, listValues) {
  let errorText = '';
  for (let i = 0; i < listAttributes.length; i++) {
    if (
      listValues === undefined ||
      listValues === null ||
      listValues[i] === undefined ||
      listValues[i] === null ||
      listValues[i] === '' ||
      listValues[i].length === 0
    ) {
      console.log('listValues!!', listValues);
      errorText = 'PRODUCT_EMPTY_VALUES';
    }
  }
  for (let j = 0; j < listValues.length; j++) {
    if (
      listAttributes === undefined ||
      listAttributes === null ||
      listAttributes[j] === undefined ||
      listAttributes[j] === null ||
      listAttributes[j] === '' ||
      listAttributes[j].length === 0
    ) {
      errorText = 'PRODUCT_EMPTY_ATTRIBUTES';
    }
  }
  console.log('listValues', listValues);
  console.log('listAttributes', listAttributes);
  console.log('errorText', errorText);
  return errorText;
}

export function deleteVariant(idVariant) {
  return (dispatch, getState) => {
    const { listAttributes } = getState().adminHome.createProduct;
    const { listValues } = getState().adminHome.createProduct;
    listAttributes.splice(idVariant, 1);
    listValues.splice(idVariant, 1);
    const errorText = validateAttributesValuesFill(listAttributes, listValues);
    dispatch(changeErrorMessage(errorText));
    dispatch(loadListAttributes(listAttributes));
    dispatch(loadListValues(listValues));
    reloadCombinations(dispatch, getState);
  };
}

export function changeCurrentValue(newValue, index) {
  return (dispatch, getState) => {
    let { listValues } = getState().adminHome.createProduct;
    if (listValues === undefined || listValues === null) {
      listValues = [];
    }
    const { listAttributes } = getState().adminHome.createProduct;
    const errorText = validateAttributesValuesFill(listAttributes, listValues);
    dispatch(changeErrorMessage(errorText));
    listValues[index] = newValue;
    dispatch(loadListValues(listValues));
    reloadCombinations(dispatch, getState);
  };
}

export function changeCurrentAttribute(newValue, index) {
  return (dispatch, getState) => {
    const { listAttributes } = getState().adminHome.createProduct;
    const { listValues } = getState().adminHome.createProduct;
    listAttributes[index] = newValue;
    const errorText = validateAttributesValuesFill(listAttributes, listValues);
    dispatch(changeErrorMessage(errorText));
    dispatch(loadListAttributes(listAttributes));
    dispatch(changeCurrentAttributeValue(newValue));
  };
}

function reloadCombinations(dispatch, getState) {
  const { listValues } = getState().adminHome.createProduct;
  const { listAttributes } = getState().adminHome.createProduct;
  const errorText = validateAttributesValuesFill(listAttributes, listValues);
  dispatch(changeErrorMessage(errorText));
  let listCombinations = [];
  const listCombinationsObject = [];
  const listValuesVariant = duplicateArrayClean(listValues);
  console.log('------------------blaa');
  if (listValuesVariant.length === 0) {
    listCombinations = listValuesVariant;
  } else if (listValuesVariant.length === 1) {
    const listCombinationsOneVariant = [];
    for (let i = 0; i < listValuesVariant[0].length; i++) {
      listCombinationsOneVariant.push([listValuesVariant[0][i]]);
    }
    listCombinations = listCombinationsOneVariant;
  } else {
    console.log('listValuesVariant', listValuesVariant);
    listCombinations = allPossibleCases(listValuesVariant);
    /*  let totalCombinations = 1;
    for (var i = 0; i < listValuesVariant.length; i++) {
    totalCombinations = nchoosek(listValuesVariant[i].length,1) * totalCombinations;
    console.log("----------------totalCombinations",totalCombinations);
  }*/
  }
  const { listReferences } = getState().adminHome.createProduct;
  for (let j = 0; j < listCombinations.length; j++) {
    console.log('.........listCombinations[j]', listCombinations[j]);
    const variantsName = calculateVariantName(listCombinations[j]);
    const combinationsObject = {
      variants: listCombinations[j],
      variantsName,
      visible: getVisible(variantsName, listReferences),
      skuReference: getSkuReference(variantsName, listReferences),
      price: getPrice(variantsName, listReferences),
    };
    listCombinationsObject.push(combinationsObject);
  }
  dispatch(updateRow([]));
  dispatch(loadListCombinations(listCombinationsObject));
}

function nchoosek(n, k) {
  return (fact(n) / fact(k)) * fact(n - k);
}

function fact(x) {
  if (x == 0) {
    return 1;
  }
  return x * fact(x - 1);
}

function formatMoney(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c;
  var d = d === undefined ? '.' : d;
  var t = t === undefined ? ',' : t;
  const s = n < 0 ? '-' : '';
  const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
}

function getPrice(variantsName, listReferences) {
  console.log('listReferences', listReferences);
  if (
    listReferences === undefined ||
    listReferences === null ||
    listReferences.length === 0
  ) {
    return '';
  }
  for (let i = 0; i < listReferences.length; i++) {
    const reference = listReferences[i];
    if (
      variantsName === reference.name ||
      variantsName === reference.variants
    ) {
      return formatMoney(reference.price);
    }
  }
  return '';
}

function getSkuReference(variantsName, listReferences) {
  if (
    listReferences === undefined ||
    listReferences === null ||
    listReferences.length === 0
  ) {
    return '';
  }
  for (let i = 0; i < listReferences.length; i++) {
    const reference = listReferences[i];
    if (
      variantsName === reference.name ||
      variantsName === reference.variants
    ) {
      return reference.skuReference;
    }
  }
  return '';
}

function getVisible(variantsName, listReferences) {
  if (
    listReferences === undefined ||
    listReferences === null ||
    listReferences.length === 0
  ) {
    return false;
  }
  for (let i = 0; i < listReferences.length; i++) {
    const reference = listReferences[i];
    console.log('----------------------------variantsName', variantsName);
    if (
      variantsName === reference.name ||
      variantsName === reference.variants
    ) {
      return reference.visible;
    }
  }
  return false;
}

function duplicateArrayClean(listValues) {
  const listValuesVariant = JSON.parse(JSON.stringify(listValues));
  const indexToDelete = [];
  for (let i = 0; i < listValuesVariant.length; i++) {
    if (listValuesVariant[i].length === 0) {
      indexToDelete.push(i);
    }
  }
  for (let j = 0; j < indexToDelete.length; j++) {
    listValuesVariant.splice(indexToDelete[j] - j, 1);
  }
  return listValuesVariant;
}

function allPossibleCases(arr) {
  if (arr.length === 1) {
    return arr[0];
  }
  const result = [];
  const allCasesOfRest = allPossibleCases(arr.slice(1)); // recur with the rest of array
  for (let j = 0; j < arr[0].length; j++) {
    if (allCasesOfRest.length > 0) {
      for (let i = 0; i < allCasesOfRest.length; i++) {
        if (allCasesOfRest[i] instanceof Array) {
          result.push([arr[0][j]].concat(allCasesOfRest[i]));
        } else {
          result.push([arr[0][j], allCasesOfRest[i]]);
        }
      }
    } else {
      result.push([arr[0][j]]);
    }
  }
  return result;
}

export function addToValues() {
  return (dispatch, getState) => {
    const { variants } = getState().adminHome.createProduct;
    const { currentAttribute } = getState().adminHome.createProduct;
    const { currentValue } = getState().adminHome.createProduct;
    let oldValues = variants[currentAttribute];
    if (oldValues === undefined || oldValues === null) {
      oldValues = [];
    }
    oldValues.push(currentValue);
    variants[currentAttribute] = oldValues;
  };
}

function calculateVariantName(listCombinations) {
  let name = '';
  for (let i = 0; i < listCombinations.length; i++) {
    if (i === 0) {
      name += listCombinations[i].text;
    } else {
      name += ` - ${listCombinations[i].text}`;
    }
  }
  return name;
}

export function loadListCombinations(newValue) {
  return {
    type: LOAD_LIST_COMBINATIONS,
    value: newValue,
  };
}

export function loadListValues(newValue) {
  return {
    type: LOAD_LIST_VARIANTS_FIELD,
    value: newValue,
  };
}

export function loadListAttributes(newValue) {
  return {
    type: LOAD_LIST_ATTRIBUTES_FIELD,
    value: newValue,
  };
}

export function changeCurrentValueValue(newValue) {
  return {
    type: LOAD_VALUE_FIELD,
    value: newValue,
  };
}

export function changeCurrentAttributeValue(newValue) {
  return {
    type: LOAD_ATTRIBUTE_FIELD,
    value: newValue,
  };
}

export function changeProductName(productName) {
  return (dispatch, getState) => {
    dispatch(changeProductNameField(productName));
  };
}

export function changeErrorMessage(newValue) {
  return {
    type: LOAD_ERROR_MESSAGE,
    value: newValue,
  };
}

export function changeProductIdValue(productId) {
  return {
    type: LOAD_PRODUCT_ID,
    value: productId,
  };
}

export function changeProductListReferences(newValue) {
  return {
    type: LOAD_LIST_REFERENCES,
    value: newValue,
  };
}

export function changeProductNameField(productName) {
  return {
    type: LOAD_PRODUCT_NAME,
    value: productName,
  };
}

export function changeProductDesc(productDesc) {
  return {
    type: LOAD_PRODUCT_DESC,
    value: productDesc,
  };
}

export function clearImageValue(newValue) {
  return {
    type: LOAD_SELECTED_INDEXES,
    value: newValue,
  };
}

export function loadScrollTop(newValue) {
  return {
    type: LOAD_SCROLL_TOP,
    value: newValue,
  };
}

export function okLoadedCategoriesList(results) {
  return {
    type: OK_LOADED_CATEGORIES_LIST,
    value: results,
  };
}

export function errorLoadedCategoriesList(error) {
  return {
    type: ERROR_LOADED_CATEGORIES_LIST,
    value: error,
  };
}

export function loadCategory(newValue) {
  return {
    type: LOAD_CATEGORY,
    value: newValue,
  };
}

export function changeFile(newValue) {
  return {
    type: LOAD_FILE,
    value: newValue,
  };
}

export function updateRow(newValue) {
  return {
    type: LOAD_ROWS_VALUES,
    value: newValue,
  };
}

export function changeImagePreviewUrl(newValue) {
  return {
    type: LOAD_IMAGE_PREVIEW_URL,
    value: newValue,
  };
}

export function changeSelectedIndexes(newValue) {
  return {
    type: LOAD_SELECTED_INDEXES,
    value: newValue,
  };
}

export function setImageError(newValue) {
  return {
    type: SET_IMAGE_ERROR,
    value: newValue,
  };
}

export function setCategoriesListStatus(newValue) {
  return {
    type: SET_CATEGORIES_LIST_STATUS,
    value: newValue,
  };
}

export function changePrice(newValue) {
  return {
    type: SET_CHANGE_PRICE,
    value: newValue,
  };
}

export function changeSKU(newValue) {
  return {
    type: SET_CHANGE_SKU,
    value: newValue,
  };
}

export function changeStateActive(newValue) {
  return {
    type: SET_STATE_ACTIVE,
    value: newValue,
  };
}

export function changeSavingProduct(newValue) {
  return {
    type: SET_SAVING_PRODUCT,
    value: newValue,
  };
}

export function categoryModalOpen(newValue) {
  return {
    type: SET_CATEGORY_MODAL_OPEN,
    value: newValue,
  };
}

export function changeNewCategory(newValue) {
  return {
    type: SET_NEW_CATEGORY,
    value: newValue,
  };
}
