export type AppEnv =
  | 'development'
  | 'testing'
  | 'testing2'
  | 'beta'
  | 'production'
  | 'local';

const APP_ENV: AppEnv = (() => {
  if (process.env.REACT_APP_ENV) return process.env.REACT_APP_ENV as AppEnv;

  const { origin } = window.location;
  const envLabels: AppEnv[] = [
    'development',
    'testing2',
    'testing',
    'beta',
    'production',
  ];
  const envURLs = ['dev', 'testapp2', 'testapp', 'app2', 'app'];

  for (let index = 0; index < envURLs.length; index += 1) {
    if (origin.includes(envURLs[index])) {
      return envLabels[index];
    }
  }

  return 'production';
})();

const ENV = {
  development: {
    WEB_SERVICE_BASE_URI: process.env.REACT_APP_WEB_SERVICE_BASE_URI_DEV,
    WEB_SERVICE_BROADCAST_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_BROADCAST_BASE_URI_DEV,
    WEB_SERVICE_ECOMMERCE_URI:
      process.env.REACT_APP_WEB_SERVICE_ECOMMERCE_URI_DEV,
    META_APP_ID: process.env.REACT_APP_META_APP_ID_DEV,
    WEB_SERVICE_CONTACTS_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_CONTACTS_BASE_URI_DEV,
  },
  testing: {
    WEB_SERVICE_BASE_URI: process.env.REACT_APP_WEB_SERVICE_BASE_URI_TEST,
    WEB_SERVICE_BROADCAST_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_BROADCAST_BASE_URI_TEST,
    WEB_SERVICE_ECOMMERCE_URI:
      process.env.REACT_APP_WEB_SERVICE_ECOMMERCE_URI_TEST,
    META_APP_ID: process.env.REACT_APP_META_APP_ID_TEST,
    WEB_SERVICE_CONTACTS_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_CONTACTS_BASE_URI_TEST,
  },
  testing2: {
    WEB_SERVICE_BASE_URI: process.env.REACT_APP_WEB_SERVICE_BASE_URI_TEST2,
    WEB_SERVICE_BROADCAST_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_BROADCAST_BASE_URI_TEST2,
    WEB_SERVICE_ECOMMERCE_URI:
      process.env.REACT_APP_WEB_SERVICE_ECOMMERCE_URI_TEST2,
    META_APP_ID: process.env.REACT_APP_META_APP_ID_TEST,
    WEB_SERVICE_CONTACTS_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_CONTACTS_BASE_URI_TEST2,
  },
  production: {
    WEB_SERVICE_BASE_URI: process.env.REACT_APP_WEB_SERVICE_BASE_URI_PROD,
    WEB_SERVICE_BROADCAST_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_BROADCAST_BASE_URI_PROD,
    WEB_SERVICE_ECOMMERCE_URI:
      process.env.REACT_APP_WEB_SERVICE_ECOMMERCE_URI_PROD,
    META_APP_ID: process.env.REACT_APP_META_APP_ID_PROD,
    WEB_SERVICE_CONTACTS_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_CONTACTS_BASE_URI_PROD,
  },
  beta: {
    WEB_SERVICE_BASE_URI: process.env.REACT_APP_WEB_SERVICE_BASE_URI_PROD,
    WEB_SERVICE_BROADCAST_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_BROADCAST_BASE_URI_PROD,
    WEB_SERVICE_ECOMMERCE_URI:
      process.env.REACT_APP_WEB_SERVICE_ECOMMERCE_URI_PROD,
    META_APP_ID: process.env.REACT_APP_META_APP_ID_TEST,
    WEB_SERVICE_CONTACTS_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_CONTACTS_BASE_URI_PROD,
  },
  local: {
    WEB_SERVICE_BASE_URI: process.env.REACT_APP_WEB_SERVICE_BASE_URI_LOCAL,
    WEB_SERVICE_BROADCAST_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_BROADCAST_BASE_URI_LOCAL,
    META_APP_ID: process.env.REACT_APP_META_APP_ID_DEV,
    WEB_SERVICE_ECOMMERCE_URI:
      process.env.REACT_APP_WEB_SERVICE_ECOMMERCE_URI_LOCAL,
    WEB_SERVICE_CONTACTS_BASE_URI:
      process.env.REACT_APP_WEB_SERVICE_CONTACTS_BASE_URI_LOCAL,
  },
}[APP_ENV] as {
  WEB_SERVICE_BASE_URI: string;
  WEB_SERVICE_BROADCAST_BASE_URI: string;
  WEB_SERVICE_ECOMMERCE_URI: string;
  META_APP_ID: string;
  WEB_SERVICE_CONTACTS_BASE_URI: string;
};

const env = Object.freeze({
  ...ENV,
  APP_ENV,
  NODE_ENV: process.env.NODE_ENV,
  VERSION: process.env.REACT_APP_VERSION,
  WEB_SOCKET_BASE_URI: ENV.WEB_SERVICE_BASE_URI,
  SERVICE_MOCK:
    process.env.REACT_APP_WEB_SERVICE_MOCK === 'true' &&
    process.env.NODE_ENV === 'development',
});

declare global {
  export interface Window {
    $env: typeof env;
    version: string;
    spawnNotification: (
      title: string,
      options: NotificationOptions
    ) => Promise<Notification>;
  }
}

Object.defineProperty(window, '$env', {
  value: env,
  configurable: true,
  writable: false,
});

Object.defineProperty(window, 'version', {
  value: process.env.REACT_APP_VERSION,
  configurable: true,
});

export default env;
