import type {
  AgentChatTransferStatus,
  ChatStatus,
  MessagingProvider,
} from '@src/model/frontendmodel';
import { Agent } from './agent';

// eslint-disable-next-line no-shadow
export enum ChatSideType {
  ON_HOLD = 'ON_HOLD',
  REQUEST = 'REQUEST',
}

// eslint-disable-next-line no-shadow
export enum NotificationType {
  NO_RESPONSE_TIME_LIMIT = 'NO_RESPONSE_TIME_LIMIT',
  MAX_CHATS_LIMIT_REACHED = 'MAX_CHATS_LIMIT_REACHED',
}

// eslint-disable-next-line no-shadow
export enum NotificationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

// eslint-disable-next-line no-shadow
export enum ResponseStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  UPDATED = 'updated',
  FAILURE = 'failure',
}

export type ChatsResponse = {
  totalChats: number;
  filteredChats: ChatTray[];
  activeChat: string;
};

export type ChatsOnHold = {
  totalChatsOnHold: number;
  totalFilteredChatsOnHold: number;
  tray: ChatTray[];
};

export type ChatsRequest = {
  totalChatRequests: number;
  totalFilteredChatRequests: number;
  tray: ChatTray[];
};

export type ChatTray = {
  id: string;
  botId: string;
  viaBotAccount: string;
  viaBotAccountId: number;
  viaBotAccountAlias: string;
  viaBotAccountIconUrl?: string;
  startTimestamp: number;
  lastMsgReceivedAt: number;
  lastMsgSentAt?: number;
  lastMessage: ChatLastMessage;
  provider: MessagingProvider;
  contact: ChatContact;
  messagesSinceLastResponse: number;
  hasPassedMaxTime: boolean;
  adId?: string;
  businessProcessNode?: {
    id: number;
    name: string;
    code: string;
    bot?: {
      id: number;
      name: string;
      standardServiceBot: boolean;
    };
  };
  chatXferRequest?: ChatTrayXfer;
  departmentCode?: string;
  closeToMaxTime: boolean;
  pinId?: number;
  status: keyof typeof ChatStatus;
  lastWAConversationStartedAt?: number;
  lastWAWindowStartedAt?: number;
  waConversationsCounter?: number;
  crmTicketId?: number;
  crmProvider?: string;
  tags: {
    color: string;
    emoji: string;
    tagName: string;
  }[];
  assignedTo?: Agent;
  assignedBy?: {
    agent: Agent;
    date: string;
  };
  show: boolean;
  segment?: {
    code: string;
  };
};

export type ChatTrayXfer = {
  chatId: string;
  status: keyof typeof AgentChatTransferStatus;
  sourceAgent: ChatTrayXferAgent;
  targetAgent: ChatTrayXferAgent;
  targetBizProcessNode: {
    id: number;
    code: string;
    name: string;
  };
  comments: string;
  rejectReason?: string;
  startTimestamp?: number;
  pickedUpTimestamp?: number;
  respondedTimestamp?: number;
};

export type ChatTrayXferAgent = {
  id: number;
  fullName: string;
  mobileNumber?: string;
  email?: string;
  avatarUrl?: string;
  userStatus?: string;
};

export type ChatLastMessage = {
  text: string;
  direction: 'INCOMING' | 'OUTGOING';
  mediaType: string;
  sendingTime: number;
  locationName?: string;
  isCaptionDefined: boolean;
  messagingProvider?: MessagingProvider;
};

export type ChatContact = {
  id: number;
  fullName: string;
  isNewContact: boolean;
  avatarUrl?: string;
  cmaId?: string;
  tags?: number[];
};

// eslint-disable-next-line no-shadow
export enum ChatActionType {
  CLOSE_CHAT = 'CLOSE_CHAT',
  PICKUP_CHAT = 'PICKUP_CHAT',
  ACTIVATION_CHAT = 'ACTIVATION_CHAT',
  EXPORT_CRM_CHAT = 'EXPORT_CRM_CHAT',
}

// eslint-disable-next-line no-shadow
export enum ChatActionsStatus {
  AWAIT = 'AWAIT',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
