const images = [
  'sticker_1.webp',
  'sticker_2.webp',
  'sticker_3.webp',
  'sticker_4.webp',
  'sticker_5.webp',
  'sticker_6.webp',
  'sticker_7.webp',
  'sticker_8.webp',
  'sticker_9.webp',
  'sticker_10.webp',
  'sticker_11.webp',
  'sticker_12.webp',
  'sticker_13.webp',
  'sticker_14.webp',
  'sticker_15.webp',
  'sticker_16.webp',
  'sticker_17.webp',
];

const genData = images.map((img, ind) => ({
  imgUrl: `/resources/stickers/good-morning-quaroutine-greetings/${img}`,
  id: `good-morning-quaroutine-greetings-${ind + 1}.custom`,
  names: ['good', 'morning', 'greetings', 'buenos', 'dias', 'saludo'],
}));

export default genData;
