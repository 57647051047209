import buildInitialAuditState from './auditstate';
import buildInitialBotSetupState from './botsetupstate';
import buildInitialContactState from './contactstate';
import buildInitialCreateOrderState from './createorderstate';
import buildInitialCreateProductState from './createproductstate';
import buildInitialFaqsCommonsState from './faqscommonsstate';
import buildInitialFaqsReportsState from './faqsreportsstate';
import buildInitialFaqsSetupState from './faqssetupstate';
import buildInitialOrderDetailState from './orderdetailstate';
import buildInitialOrderState from './orderstate';
import buildInitialProductState from './productstate';
import buildInitialUserState from './userstate';

const buildInitialAdminhomeState = function () {
  return {
    menu: { selectedOption: '/admin/audit', close: false },
    audit: buildInitialAuditState(),
    user: buildInitialUserState(),
    contact: buildInitialContactState(),
    order: buildInitialOrderState(),
    orderDetail: buildInitialOrderDetailState(),
    createOrder: buildInitialCreateOrderState(),
    createProduct: buildInitialCreateProductState(),
    product: buildInitialProductState(),
    botSetupState: buildInitialBotSetupState(),
    faqsCommons: buildInitialFaqsCommonsState(),
    faqsSetup: buildInitialFaqsSetupState(),
    faqsReports: buildInitialFaqsReportsState(),
  };
};

export default buildInitialAdminhomeState;
