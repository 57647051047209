import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContactAssignment,
  ContactAssignmentOptions,
  ContactAssignmentType,
} from '@src/types/contactAssignment';

type ContactAssignmentState = {
  [contactId: string]: {
    assignedTo: ContactAssignment | undefined;
    loading: 'idle' | 'pending' | 'success' | 'failure';
  };
};

const defaultAssignedToContact = {
  id: -1,
  description: '',
  type: ContactAssignmentOptions.UNASIGNED as ContactAssignmentType,
};

export const contactAssignment = createSlice({
  name: 'chats',
  initialState: {} as ContactAssignmentState,
  reducers: {
    getAssignedToContact(state, { payload: contactId }: PayloadAction<string>) {
      state[contactId] = {
        loading: 'idle',
        assignedTo: (state[contactId]?.assignedTo ?? {}) as ContactAssignment,
      };
    },
    getAssignedToContactSuccess(
      state,
      {
        payload,
      }: PayloadAction<{ contactId: string; assignedTo: ContactAssignment }>
    ) {
      state[payload.contactId].assignedTo = payload.assignedTo;
    },
    getAssignedToContactError(
      state,
      { payload: contactId }: PayloadAction<string>
    ) {
      state[contactId].assignedTo = defaultAssignedToContact;
    },

    assignContact(
      state,
      {
        payload,
      }: PayloadAction<{
        contactId: string;
        assignedTo: ContactAssignment;
        replace?: boolean;
      }>
    ) {
      state[payload.contactId].loading = 'pending';
    },

    assignContactSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        contactId: string;
        assignedTo: ContactAssignment;
      }>
    ) {
      state[payload.contactId].loading = 'success';
      state[payload.contactId].assignedTo = payload.assignedTo;
    },
    assignContactError(state, { payload: contactId }: PayloadAction<string>) {
      state[contactId].loading = 'failure';
    },
    assignContactFulfill(state, { payload: contactId }: PayloadAction<string>) {
      state[contactId].loading = 'idle';
    },
    unassignToContact(state, { payload: contactId }: PayloadAction<string>) {
      state[contactId].loading = 'pending';
    },
    unassignToContactSuccess(
      state,
      { payload: contactId }: PayloadAction<string>
    ) {
      state[contactId].assignedTo = defaultAssignedToContact;
      state[contactId].loading = 'success';
    },
    unassignToContactError(
      state,
      { payload: contactId }: PayloadAction<string>
    ) {
      state[contactId].loading = 'failure';
    },
    unassignToContactFulfill(
      state,
      { payload: contactId }: PayloadAction<string>
    ) {
      state[contactId].loading = 'idle';
    },
  },
});

export const contactAssignmentActions = contactAssignment.actions;

export default contactAssignment.reducer;
