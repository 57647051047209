/* eslint-disable no-else-return */
const DEFAULT_ORDER = 1000;

// Extract the time at which the last message was sent by the agent in a chat
const fnLastChatSentMsgTime = c =>
  c.lastMsgSentAt ? c.lastMsgSentAt : c.startTimestamp;

const fnSortLastMsgReceivedFirst = (a, b) => {
  const defaultSort = chatDefaultSort(a, b);

  let rankA = fnLastChatMsgTime(a);
  let rankB = fnLastChatMsgTime(b);

  rankA = rankA || 0;
  rankB = rankB || 0;

  if (defaultSort !== 0) return defaultSort;
  if (rankA < rankB) return 1;
  if (rankA > rankB) return -1;

  return 0;
};

// Extract the time at which the last message was received or sent in a chat
const fnLastChatMsgTime = c => {
  const lastRcptTime = c.lastMsgReceivedAt
    ? c.lastMsgReceivedAt
    : c.startTimestamp;
  const lastSendTime = c.lastMsgSentAt ? c.lastMsgSentAt : c.startTimestamp;

  return lastRcptTime >= lastSendTime ? lastRcptTime : lastSendTime;
};

// Define the sorting functions corresponding to each criterion
const fnSortOldestCreatedFirst = (a, b) => {
  const defaultSort = chatDefaultSort(a, b);

  const rankA = a.startTimestamp ? a.startTimestamp : 0;
  const rankB = b.startTimestamp ? b.startTimestamp : 0;

  if (defaultSort !== 0) return defaultSort;
  else if (rankA > rankB) return 1;
  if (rankA < rankB) return -1;

  return 0;
};

const fnSortLastMsgSentLast = (a, b) => {
  const defaultSort = chatDefaultSort(a, b);

  let rankA = fnLastChatSentMsgTime(a);
  let rankB = fnLastChatSentMsgTime(b);

  rankA = rankA || 0;
  rankB = rankB || 0;

  if (defaultSort !== 0) return defaultSort;
  else if (rankA > rankB) return 1;
  if (rankA < rankB) return -1;

  return 0;
};

const getSegmentOrder = chat => {
  const {
    chats: { segments },
  } = window.store.getState();
  const segment = segments.data.find(item => item.code === chat.segment?.code);
  return segment?.order || DEFAULT_ORDER;
};

const getChatCreatedDate = chat => chat.startTimestamp || 0;

const getComparisonData = (chatA, chatB) => {
  const orderA = getSegmentOrder(chatA);
  const orderB = getSegmentOrder(chatB);
  const creationDateA = getChatCreatedDate(chatA);
  const creationDateB = getChatCreatedDate(chatB);
  return { orderA, orderB, creationDateA, creationDateB };
};

const fnSortLIFO = (chatA, chatB) => {
  const { orderA, orderB, creationDateA, creationDateB } = getComparisonData(
    chatA,
    chatB
  );
  if (orderA !== orderB) return orderA - orderB;
  return creationDateA > creationDateB ? -1 : 1;
};

const fnSortFIFO = (chatA, chatB) => {
  const { orderA, orderB, creationDateA, creationDateB } = getComparisonData(
    chatA,
    chatB
  );
  if (orderA !== orderB) return orderA - orderB;
  return creationDateA > creationDateB ? 1 : -1;
};

function chatDefaultSort(a, b) {
  const assignedDateA = a?.assignedBy?.date ?? -Infinity;
  const assignedDateB = b?.assignedBy?.date ?? -Infinity;

  // Si ambos objetos tienen o no tienen `chats?.assignedBy?.date`, ordenar por `chat?.chatXferRequest`
  const hasChatXferA = !!a?.chatXferRequest?.chatId;
  const hasChatXferB = !!b?.chatXferRequest?.chatId;

  // Si ambos objetos tienen o no tienen `chat?.chatXferRequest`, ordenar por `chat.pinId`
  const pinIdA = a?.pinId ?? 0;
  const pinIdB = b?.pinId ?? 0;

  if (assignedDateA !== assignedDateB) return assignedDateB - assignedDateA;

  if (hasChatXferA && !hasChatXferB) return -1;
  else if (!hasChatXferA && hasChatXferB) return 1;

  if (pinIdA !== pinIdB) return pinIdB - pinIdA;

  return 0;
}

// Define the sorting criteria and the sorting function to be used in each case
// NOTE: Sorting modes are defined at mode/frontendmodel.js:SortingMode
export const sortingModes = {
  OLDEST_CREATED_FIRST: { sortingFunction: fnSortOldestCreatedFirst },
  LAST_RECEIVED_MESSAGE_FIRST: {
    sortingFunction: fnSortLastMsgReceivedFirst,
  },
  LAST_SENT_MESSAGE_LAST: { sortingFunction: fnSortLastMsgSentLast },
  CHAT_SEGMENT_PRIORITY_FIFO: { sortingFunction: fnSortFIFO },
  CHAT_SEGMENT_PRIORITY_LIFO: { sortingFunction: fnSortLIFO },
};

// Maps the name of a sorting mode to an object containing its respective sorting function
export const fnGetSortingMode = sortModeName => {
  let chosenSortingMode = sortingModes[sortModeName];

  if (!chosenSortingMode) {
    // Use last-message-first as default sorting mode
    chosenSortingMode = sortingModes.LAST_RECEIVED_MESSAGE_FIRST;
  }

  return chosenSortingMode;
};

const ChatSorting = (() => ({
  // Sorts an array of chats in accordance with the specified sorting criterion
  // Returns an array of chat objects, sorted in the specified order
  sortChatArray: (mapChats, mapSelectedChatIds, sortModeName) => {
    const arrSortChatRequests = [];
    const chosenSortingMode = fnGetSortingMode(sortModeName);

    Object.keys(mapSelectedChatIds).forEach(chatId => {
      if (mapChats[chatId]) {
        arrSortChatRequests.push(mapChats[chatId]);
      }
    });

    // Sort the array of chat requests by the appropriate criterion
    arrSortChatRequests.sort(chosenSortingMode.sortingFunction);
    return arrSortChatRequests;
  },

  // Sorts an array of chats in accordance with the specified sorting criterion
  // Returns an array of chat IDs, sorted in the specified order
  sortChatArrayIds: (mapChats, mapSelectedChatIds, sortModeName) => {
    const arrSortedChats = this.sortChatArray(
      mapChats,
      mapSelectedChatIds,
      sortModeName
    );
    return arrSortedChats.map(chat => chat.id);
  },

  // Says whether the first chat is positioned before the second chat, as to the specified sorting mode
  isBeforeAsToSortingMode: (chat1, chat2, sortModeName) => {
    const chosenSortingMode = fnGetSortingMode(sortModeName);
    return chosenSortingMode.sortingFunction(chat1, chat2) < 0;
  },
}))();

export default ChatSorting;
