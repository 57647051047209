import { createSlice } from '@reduxjs/toolkit';

export type AppUpdateState = 'redundant' | `updatefound`;

const AppUpdateSlice = createSlice({
  name: 'appUpdate',
  initialState: 'redundant' as AppUpdateState,
  reducers: {
    appUpdateFound() {
      return `updatefound`;
    },
  },
});

export const { appUpdateFound } = AppUpdateSlice.actions;

export default AppUpdateSlice.reducer;
