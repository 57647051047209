export const SET_USER_BOTS = 'BOT_SETUP.SET_USER_BOTS';
export const RESET_BOT_SETUP_STATE = 'BOT_SETUP.RESET';

export function setUserBots(lstUserBots) {
  return {
    type: SET_USER_BOTS,
    lstUserBots,
  };
}

export function resetBotSetup() {
  return {
    type: RESET_BOT_SETUP_STATE,
  };
}
