import B2ChatClient from '@client-sdk';
import {
  ContactsImportError,
  fetchValidation,
  fetchValidationFailure,
  fetchValidationFulfill,
  fetchValidationSuccess,
  importConfirmation,
  importConfirmationFailure,
  importConfirmationFulfill,
  importConfirmationSuccess,
} from '@reducers/contactsImport';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchValidationSaga(action: ReturnType<typeof fetchValidation>) {
  const { file } = action.payload;
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = (yield call(
      [B2ChatClient.resources.contacts.actions, 'validateImportFile'],
      {
        data: formData,
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Contacts.ValidateImportFile>;

    if (response.error != null) {
      yield put(fetchValidationFailure(response.error as ContactsImportError));
    } else {
      yield put(fetchValidationSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchValidationFailure(error as ContactsImportError));
  } finally {
    yield put(fetchValidationFulfill());
  }
}

function* importConfirmationSaga(
  action: ReturnType<typeof importConfirmation>
) {
  const { id, skipTags } = action.payload;

  try {
    const response = (yield call(
      [B2ChatClient.resources.contacts.actions, 'importConfirmation'],
      {
        params: { id, skip_tags: `${skipTags}` },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Contacts.ImportConfirmation>;

    if (response.error != null) {
      yield put(importConfirmationFailure(response.error));
    } else {
      yield put(importConfirmationSuccess());
    }
  } catch (error) {
    yield put(importConfirmationFailure(error as ErrorData));
  } finally {
    yield put(importConfirmationFulfill());
  }
}

function* ContactsImportSaga() {
  yield takeLatest(fetchValidation, fetchValidationSaga);
  yield takeLatest(importConfirmation, importConfirmationSaga);
}

export default ContactsImportSaga;
