import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'upload-files';

export type ResponseFile = {
  url: string;
  name: string;
  mime: string;
  fieldName: string;
};

export type ErrorCode = 'INVALID_VALUE' | 'MAX_FILE_SIZE_EXCEEDED';

export type ErrorResponse = {
  errorCode: ErrorCode;
  details: string;
  traceId: string;
};

export type UploadFiles = {
  error: ErrorData | ErrorResponse | null;
  loading: Loading[];
  errorResponse: ErrorData | ErrorResponse | null;
  files: { [key: string]: ResponseFile };
};

type UploadFilesReducer<P = void> = (
  state: UploadFiles,
  action: PayloadAction<P>
) => void;

const initialState: UploadFiles = {
  error: null,
  errorResponse: null,
  loading: [],
  files: {},
};

type UploadFilesPayload = {
  files: File[];
  prefix?: string;
  fieldName: string;
};

const handleUploadFiles: UploadFilesReducer<UploadFilesPayload> = state => {
  state.loading.push('upload-files');
  state.error = null;
};

const handleUploadFilesSuccess: UploadFilesReducer<ResponseFile[]> = (
  state,
  action
) => {
  const file = action.payload[0];
  state.files = {
    ...state.files,
    [file.fieldName]: file,
  };
};

const handleUploadFilesFailure: UploadFilesReducer<ErrorData | ErrorResponse> =
  (state, action) => {
    state.errorResponse = action.payload;
  };

const handleUploadFilesFulfill: UploadFilesReducer = state => {
  state.loading = state.loading.filter(l => l !== 'upload-files');
};

const uploadFilesSlice = createSlice({
  name: 'UploadFiles',
  initialState,
  reducers: {
    fetchUploadFiles: handleUploadFiles,
    fetchUploadFilesSuccess: handleUploadFilesSuccess,
    fetchUploadFilesFailure: handleUploadFilesFailure,
    fetchUploadFilesFulfill: handleUploadFilesFulfill,
  },
});

const { actions, reducer } = uploadFilesSlice;

export const {
  fetchUploadFiles,
  fetchUploadFilesSuccess,
  fetchUploadFilesFailure,
  fetchUploadFilesFulfill,
} = actions;

export default reducer;
