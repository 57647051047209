import B2ChatClient from '@client-sdk';
import {
  Ad,
  fetchAds,
  fetchAdsFailure,
  fetchAdsFulfill,
  fetchAdsSuccess,
} from '@src/reducers/ads';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLeading } from 'redux-saga/effects';

function* fetchAdsSaga() {
  try {
    const response: B2ChatAPI.Response<Ad[]> = yield call(
      B2ChatClient.resources.audit.actions.ads
    );

    if (response.error) {
      yield put(fetchAdsFailure(response.error));
    } else {
      yield put(fetchAdsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchAdsFailure(error as ErrorData));
  } finally {
    yield put(fetchAdsFulfill());
  }
}

export default function* adsSaga() {
  yield takeLeading(fetchAds, fetchAdsSaga);
}
