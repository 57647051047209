import B2ChatClient from '@client-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import {
  ConsoleWidgetEntry,
  fetchConsoleWidgets,
  fetchConsoleWidgetsFailure,
  fetchConsoleWidgetsFulfill,
  fetchConsoleWidgetsSuccess,
  updateConsoleWidgets,
  updateConsoleWidgetsFailure,
  updateConsoleWidgetsFulfill,
  updateConsoleWidgetsSuccess,
} from '@src/reducers/consoleWidgets';
import { getMerchantId } from '@src/selectors/user';
import { ErrorPayload } from '@src/types';
import { B2ChatAPI } from '@types-api';

function* fetchConsoleWidgetsSaga() {
  try {
    const response: B2ChatAPI.Response<ConsoleWidgetEntry[]> = yield call(
      B2ChatClient.resources.integrations.actions.getIntegration,
      {
        params: {
          type: 'B2CHAT_JS_SDK',
        },
      }
    );
    if (response.error) throw response.error;

    yield put(fetchConsoleWidgetsSuccess(response.data));
  } catch (e) {
    yield put(fetchConsoleWidgetsFailure(e as ErrorPayload));
  }

  yield put(fetchConsoleWidgetsFulfill());
}

function* updateConsoleWidgetsSaga(
  action: ReturnType<typeof updateConsoleWidgets>
) {
  try {
    const merchantId: string = yield select(getMerchantId);
    const { payload } = action;
    const response: B2ChatAPI.Response<ConsoleWidgetEntry> = yield call(
      [
        B2ChatClient.resources.integrations.actions,
        payload.id ? 'updateIntegration' : 'addIntegration',
      ],
      {
        data: payload.id
          ? {
              id: action.payload.id,
              config: JSON.stringify(payload.config),
              active: payload.active,
            }
          : {
              type: 'B2CHAT_JS_SDK',
              merchantId,
              config: JSON.stringify(payload.config),
              active: payload.active,
            },
      }
    );
    if (response.error) throw response.error;
    yield put(fetchConsoleWidgets());

    yield put(updateConsoleWidgetsSuccess());
  } catch (e) {
    yield put(updateConsoleWidgetsFailure(e as ErrorPayload));
  }

  yield put(updateConsoleWidgetsFulfill());
}

export default function* consoleWidgets() {
  yield takeLatest(updateConsoleWidgets, updateConsoleWidgetsSaga);
  yield takeLatest(fetchConsoleWidgets, fetchConsoleWidgetsSaga);
}
