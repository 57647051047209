/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DepartmentHeadings,
  ModeEnum,
  type Department,
  type ErrorData,
  type ErrorPayload,
} from '@types';

type RequestStatus =
  | 'idle'
  | 'pending'
  | 'success'
  | 'failure'
  | 'item-saved'
  | 'item-deleted';

export type DepartmentState = {
  loading: 'idle' | 'pending';
  isDepartmentModuleActive: boolean;
  items: Department[];
  error?: ErrorData | ErrorPayload;
  requestVisibility: 'idle' | 'pending' | 'success' | 'failure';
  headings: {
    items: DepartmentHeadings[];
    request: RequestStatus;
  };
};

export type DepartmentsHeadingListItem = {
  key: string;
  name: string;
  provider: string;
  headerText: string;
};

export type DepartmentHeadingsType = {
  mode: 'create' | 'delete' | 'list';
  bizProcessTreeId: number;
  botAccountId: number;
  data?: DepartmentHeadings;
};

const initialState: DepartmentState = {
  loading: 'idle',
  isDepartmentModuleActive: false,
  items: [],
  error: undefined,
  requestVisibility: 'idle',
  headings: {
    items: [],
    request: 'idle',
  },
};

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setDepartmentModuleActive(state, action: PayloadAction<boolean>) {
      state.isDepartmentModuleActive = action.payload;
    },
    fetchDepartments(state) {
      state.loading = 'pending';
      state.error = undefined;
      state.items = [];
    },
    fetchDepartmentsSuccess(state, action: PayloadAction<Department[]>) {
      const itemsDep = action.payload.reduce(
        (items, department) => {
          items[department.state].push(department);
          return items;
        },
        { ACTIVE: [] as Department[], INACTIVE: [] as Department[] }
      );

      itemsDep.ACTIVE.sort((l, r) => l.name.localeCompare(r.name));
      itemsDep.INACTIVE.sort((l, r) => l.name.localeCompare(r.name));

      const items = [...itemsDep.ACTIVE, ...itemsDep.INACTIVE];

      {
        const standardDepartmentIndex = items.findIndex(d =>
          d.name.startsWith('BotStandardServices')
        );
        if (standardDepartmentIndex !== -1) {
          const stantardDepartment = items.splice(
            standardDepartmentIndex,
            1
          )[0];

          stantardDepartment.name = 'BotStandardServices';
          items.unshift(stantardDepartment);
        }

        const itemsRecord = items.reduce<Record<number, Department>>(
          (record, department) => {
            if (!department.departmentId) return record;

            return { ...record, [department.departmentId]: department };
          },
          {}
        );

        Object.values(itemsRecord).forEach(department => {
          const { parentNodeId } = department;
          const parent =
            parentNodeId !== null ? itemsRecord[parentNodeId] : undefined;

          if (parent) {
            parent.childrens = [...(parent.childrens || []), department];
          }
        });

        items.forEach(department => {
          if (department.parentNodeId)
            department.parentName = itemsRecord[department.parentNodeId].name;
        });

        items.sort((l, r) =>
          (l.parentName || '').localeCompare(r.parentName || '')
        );
      }
      state.items = items;
    },
    fetchDepartmentsFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchDepartmentsFulfill(state) {
      state.loading = 'idle';
    },
    fetchDepartmentVisibility(
      state,
      _action: PayloadAction<{ departmentId: number; visible: boolean }>
    ) {
      state.requestVisibility = 'pending';
    },
    fetchDepartmentVisibilitySuccess(
      state,
      action: PayloadAction<{ departmentId: number }>
    ) {
      const { items } = state;
      state.items = items.map((department: Department) => {
        const { departmentId, hiddenToContacts } = department;
        if (departmentId === action.payload.departmentId)
          department.hiddenToContacts = !hiddenToContacts;
        return department;
      });
      state.requestVisibility = 'success';
    },
    fetchDepartmentVisibilityFailure(
      state,
      action: PayloadAction<ErrorPayload>
    ) {
      state.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
      state.requestVisibility = 'failure';
    },
    fetchDepartmentVisibilityFulfill(state) {
      state.requestVisibility = 'idle';
    },
    fetchDepartmentHeadings(
      state,
      _action: PayloadAction<DepartmentHeadingsType>
    ) {
      state.headings.request = 'pending';
    },
    fetchDepartmentHeadingsSuccess(
      state,
      action: PayloadAction<{
        items: DepartmentHeadings[];
        mode: string;
        botAccountId?: number;
      }>
    ) {
      const { items, mode, botAccountId } = action.payload;
      const { headings } = state;
      let request = mode === ModeEnum.CREATE ? 'item-saved' : 'success';

      if (ModeEnum.LIST === mode) state.headings.items = [...items];

      if (ModeEnum.CREATE === mode)
        state.headings.items = [...headings.items, ...items];

      if (mode === ModeEnum.DELETE) {
        state.headings.items = headings.items.filter(
          (item: DepartmentHeadings) => item.botAccountId !== botAccountId
        );
        request = 'item-deleted';
      }
      state.headings.request = request as RequestStatus;
    },
    fetchDepartmentHeadingsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
      state.headings.request = 'failure';
    },
    changeRequestStatus(state, action: PayloadAction<string>) {
      state.headings.request = action.payload as RequestStatus;
    },
  },
});

export default departmentsSlice.reducer;

export const {
  fetchDepartments,
  fetchDepartmentsFailure,
  fetchDepartmentsSuccess,
  fetchDepartmentsFulfill,
  setDepartmentModuleActive,
  fetchDepartmentVisibility,
  fetchDepartmentVisibilitySuccess,
  fetchDepartmentVisibilityFailure,
  fetchDepartmentVisibilityFulfill,
  fetchDepartmentHeadings,
  fetchDepartmentHeadingsSuccess,
  fetchDepartmentHeadingsFailure,
  changeRequestStatus,
} = departmentsSlice.actions;
