import { AnyAction } from 'redux';

export const GENERAL_SETTING_REQUEST = 'GENERAL_SETTING_REQUEST';
export const GENERAL_SETTING_FAIL = 'GENERAL_SETTING_FAIL';

export type GeneralProperties = {
  keenioFaqsModuleFlowCollectionName: string;
  keenioFaqsQuestionCollectionName: string;
  keenioGeneralOrderCollectionName: string;
  keenioOrderOrdersCollectionName: string;
  keenioOrderProdutsCollectionName: string;
  keenioCollectionName: string;
  keenioProjectId: string;
  fbAppId: string;
  reCaptchaSiteKey: string;
  arrCountries: Record<'isoCode' | 'name' | 'languageCode', string>[];
  keenioSatisfactionReportCollectionName: string;
  allB2chatModules: {
    id: number;
    name: string;
    description: null;
    currentPrice: null;
    implementingClass: null;
  }[];
  preferences: {
    sortModeIncomingChats: string;
    sortModeOngoingChats: string;
    sortModeAutoAssignChats: string;
    agentConsoleConfig: {
      incomingTrayBaseSize: number;
      ongoingTrayBaseSize: number;
    };
    fileSubmission: true;
    audioRecording: true;
    conversationFiltersAlarmHours: number;
    uiConfigurationProperties: {
      desktopLightLogo?: string;
      mobileDarkLogo?: string;
      mobileLightLogo?: string;
    };
  };
  isFileSubmissionEnabled: true;
  isAudioRecordingEnabled: true;
  deptsEarlyAccess: true;
  departmentsState: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const generalSetting = (
  state: Partial<GeneralProperties> = {},
  action: AnyAction
): Partial<GeneralProperties> => {
  switch (action.type) {
    case GENERAL_SETTING_REQUEST:
      return { ...state, success: action.data, status: true };
    case GENERAL_SETTING_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};

export default generalSetting;
