import { combineReducers } from 'redux';

//import { STORE_LIST_BOTS, SELECT_BOT_VALUE} from '../actions/faqsreports';

/*const listBotsReducer = ( state = {}, action) => {

  switch(action.type){

    case SELECT_BOT_VALUE:

    var curState2 = Object.assign({}, state);

    curState2.botValue = action.botValue;

    return curState2;

    case STORE_LIST_BOTS:

      console.log('REDUCER FAQS SETUP list bot object', action.listBots);

      var curState = Object.assign({}, state);
      curState.listBots = action.listBots;
      //curState35.botValue = action.listBots[0].name;

      return curState;

    default:

      return state;

  }//fin switch
}//fin reducer */

const reportReducer = (state = {}, action) => state; //fin reportReducer

const faqsReportsReducer = combineReducers({
  //listBotsObject: listBotsReducer,
  reportsObject: reportReducer,
});

export default faqsReportsReducer;
