import { Box, Button } from '@material-ui/core';
import {
  B2ChatBBlue1,
  B2ChatBBlue2,
  B2ChatBBlue3,
  B2ChatBBlue4,
  B2ChatLogoDesktop,
  B2ChatRobot,
} from '@src/resources';
import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';

const ErrorPage: React.FC<FallbackProps> = ({ resetErrorBoundary }) => (
  <Box
    display="flex"
    role="alert"
    height="100%"
    width="100%"
    minHeight="100%"
    margin="10px 40px"
  >
    <Box
      display="flex"
      flexDirection="column"
      color="#323444"
      width="100%"
      padding="200px 43px"
      style={{
        backgroundImage: `url(${B2ChatRobot})`,
        backgroundPosition: 'right 80px bottom 50px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box maxWidth={720}>
        <Box mb={3}>
          <img
            src={B2ChatLogoDesktop}
            alt="B2ChatLogoDesktop"
            style={{ height: 40 }}
          />
        </Box>
        <Box fontSize="29px" lineHeight="35.96px" fontWeight={600}>
          <FormattedMessage
            id="ErrorPage.msg1"
            values={{
              br: <br />,
            }}
          />
        </Box>
        <Box fontSize="20px" mt={2}>
          <FormattedMessage id="ErrorPage.msg2" />
        </Box>
        <Box fontSize="14px" mt={3} mb={10}>
          <FormattedMessage
            id="ErrorPage.msg3"
            values={{
              br: <br />,
            }}
          />
        </Box>

        <Button
          style={{
            backgroundColor: '#F2F2F5',
            maxWidth: '204px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            textTransform: 'capitalize',
            padding: '10px 50px',
          }}
          color="primary"
          onClick={resetErrorBoundary}
        >
          <FormattedMessage id="ErrorPage.button.label" />
        </Button>
      </Box>
    </Box>
    <img
      src={B2ChatBBlue1}
      alt="B2ChatBBlue1"
      style={{
        position: 'absolute',
        top: '46px',
        left: '66px',
        zIndex: -1,
      }}
    />
    <img
      src={B2ChatBBlue2}
      alt="B2ChatBBlue2"
      style={{
        position: 'absolute',
        left: '364px',
        bottom: '109px',
        zIndex: -1,
      }}
    />
    <img
      src={B2ChatBBlue3}
      alt="B2ChatBBlue3"
      style={{
        position: 'absolute',
        top: '232px',
        right: '220px',
        zIndex: -1,
      }}
    />
    <img
      src={B2ChatBBlue4}
      alt="B2ChatBBlue4"
      style={{
        position: 'absolute',
        right: '0px',
        bottom: '131px',
        overflowX: 'hidden',
        zIndex: -1,
      }}
    />
    {false && (
      <Box
        style={{
          backgroundImage: `url(${B2ChatRobot})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
        width={530}
        height={350}
      >
        {/* <img
        src={B2ChatRobot}
        alt="B2ChatRobot"
        style={{
          height: 350,
          float: 'right',
          marginRight: '31px',
          marginTop: '-127px',
          position: 'relative',
          zIndex: 1,
          // right: '31px',
          // bottom: '127px',
        }}
      /> */}
      </Box>
    )}
  </Box>
);

export default ErrorPage;
