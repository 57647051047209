/* eslint-disable @typescript-eslint/no-unused-vars */
import { MessagingProvider } from '@model/frontendmodel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { BotAccount, ErrorPayload, Pagination } from '@types';

export type BotAccountItem = {
  id: number;
  createdAt: number;
  status: 'ACTIVE' | 'SUSPENDED';
  botAccount: BotAccount;
  toMonitor: boolean;
  createdAtAsString: string;
};

export type BotButtonsSetting = {
  provider: MessagingProvider;
  userName: string;
  buttonsEnabled: boolean;
};

export type BotButtons = {
  forceEnabledButtonsForAllBotAccounts: boolean;
  forceEnabledButtonsForProviders: MessagingProvider[];
  buttonsBotAccountSettings: BotButtonsSetting[];
};

export type Messaging = {
  buttonsSettings: BotButtons;
  interactiveListSettings: {
    forceEnabledListForAllBotAccounts: boolean;
    interactiveListBotAccountSettings: BotButtonsSetting[];
  };
};

export type TChannel = {
  provider: MessagingProvider;
  userName: string;
};

export type BotAccountState = {
  loading: ('idle' | 'pending' | 'success' | 'failure' | 'fulfill')[];
  filter: {
    providers?: MessagingProvider[];
    botAccountState?: 'ACTIVE' | 'SUSPENDED';
    searchFilter?: string;
  };
  sorting: {
    column: string;
    direction: 'ASC';
  }[];
  limit: number;
  offset: number;
  count: number;
  data: BotAccountItem[];
  error?: ErrorPayload;
  messaging: Messaging;
  whatsappButtonsEnabled: boolean;
};

const initialState: BotAccountState = {
  loading: [],
  filter: {},
  limit: 10,
  offset: 0,
  count: 0,
  sorting: [],
  data: [],
  messaging: {
    buttonsSettings: {
      forceEnabledButtonsForAllBotAccounts: false,
      forceEnabledButtonsForProviders: [],
      buttonsBotAccountSettings: [],
    },
    interactiveListSettings: {
      forceEnabledListForAllBotAccounts: false,
      interactiveListBotAccountSettings: [],
    },
  },
  whatsappButtonsEnabled: false,
};

const botAccountsSlice = createSlice({
  name: 'botAccountsSlice',
  initialState,
  reducers: {
    fetchBotAccounts(
      state,
      action: PayloadAction<Omit<Partial<BotAccountState>, 'loading'>>
    ) {
      state.filter = action.payload.filter || state.filter;
      state.limit = action.payload.limit ?? state.limit;
      state.offset = action.payload.offset ?? state.offset;
      state.loading = ['pending'];
    },
    fetchBotAccountsSuccess(
      state,
      action: PayloadAction<Pagination<BotAccountItem>>
    ) {
      state.loading = ['success'];
      state.data = action.payload.data;
      state.count = action.payload.total;
    },
    fetchBotAccountsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.loading = ['failure'];
      state.error = action.payload;
    },
    fetchBotAccountsFulfill(state) {
      state.loading.push('fulfill');
    },
    updateBotAccount(state, _action: PayloadAction<Partial<BotAccountItem>>) {
      state.loading = ['pending'];
    },
    updateBotAccountSuccess(
      state,
      action: PayloadAction<Partial<BotAccountItem>>
    ) {
      const index = state.data.findIndex(({ id }) => id === action.payload.id);

      if (index !== -1)
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
        };

      state.loading = ['success'];
    },
    updateBotAccountFailure(state, action: PayloadAction<ErrorPayload>) {
      state.loading = ['failure'];
      state.error = action.payload;
    },
    updateBotAccountFulfill(state) {
      state.loading.push('fulfill');
    },
    fetchBotButtons(state, _action: PayloadAction<string>) {
      state.loading = ['pending'];
      state.messaging = initialState.messaging;
    },
    fetchBotButtonsSuccess(state, action: PayloadAction<Messaging>) {
      state.loading = ['success'];
      const { interactiveListSettings: listSettings, buttonsSettings } =
        action.payload;
      const { interactiveListSettings } = initialState.messaging;

      state.messaging = {
        buttonsSettings,
        interactiveListSettings: listSettings || interactiveListSettings,
      };
    },
    fetchBotButtonsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.loading = ['failure'];
      state.error = action.payload;
    },
    fetchBotButtonsFulfill(state) {
      const { loading } = state;
      state.loading = [...loading, 'fulfill'];
    },
    updateBotButtons(
      state,
      _action: PayloadAction<{ messaging: Messaging; modulename: string }>
    ) {
      state.loading = ['pending'];
    },
    updateBotButtonsSuccess(state, action: PayloadAction<Messaging>) {
      const { payload } = action;
      state.messaging = payload;
      state.loading = ['success'];
    },
    updateBotButtonsFulfill(state) {
      const { loading } = state;
      state.loading = [...loading, 'fulfill'];
    },
    updateBotButtonsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.loading = ['failure'];
      state.error = action.payload;
      state.messaging = initialState.messaging;
    },
    resetFilter(state) {
      return { ...initialState, data: state.data };
    },
    onVerifyWhatsappButtons(state) {
      const { messaging } = state;

      const {
        interactiveListBotAccountSettings: accounts,
        forceEnabledListForAllBotAccounts: allAccounts,
      } = messaging.interactiveListSettings;

      const enabled = allAccounts || accounts.length > 0;

      state.whatsappButtonsEnabled = enabled;
      state.loading = ['idle'];
    },
  },
});

export default botAccountsSlice.reducer;

export const {
  fetchBotAccounts,
  fetchBotAccountsFailure,
  fetchBotAccountsFulfill,
  fetchBotAccountsSuccess,
  updateBotAccount,
  updateBotAccountSuccess,
  updateBotAccountFailure,
  updateBotAccountFulfill,
  fetchBotButtons,
  fetchBotButtonsSuccess,
  fetchBotButtonsFulfill,
  fetchBotButtonsFailure,
  resetFilter,
  updateBotButtons,
  updateBotButtonsSuccess,
  updateBotButtonsFulfill,
  updateBotButtonsFailure,
  onVerifyWhatsappButtons,
} = botAccountsSlice.actions;
