export const BOT_SPECIFIC_CONFIGURATION_REQUEST =
  'BOT_SPECIFIC_CONFIGURATION_REQUEST';
export const BOT_SPECIFIC_CONFIGURATION_FAIL =
  'BOT_SPECIFIC_CONFIGURATION_FAIL';

const renderBotConfiguration = (state = {}, action) => {
  switch (action.type) {
    case BOT_SPECIFIC_CONFIGURATION_REQUEST:
      return { ...state, success: action.data, status: true };
    case BOT_SPECIFIC_CONFIGURATION_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default renderBotConfiguration;
