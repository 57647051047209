export const MESSAGE_ACCOUNT_LIST_REQUEST = 'MESSAGE_ACCOUNT_LIST_REQUEST';
export const MESSAGE_ACCOUNT_LIST_FAIL = 'MESSAGE_ACCOUNT_LIST_FAIL';

const messagingAccountList = (state = {}, action) => {
  switch (action.type) {
    case MESSAGE_ACCOUNT_LIST_REQUEST:
      return { ...state, success: action.data, status: true };
    case MESSAGE_ACCOUNT_LIST_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default messagingAccountList;
