import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData, WhatsAppTemplate } from '@types';

export type BroadcastTemplates = {
  loading: 'idle' | 'pending';
  botAccountId: number;
  items: WhatsAppTemplate[];
  error?: ErrorData;
};

const initialState: BroadcastTemplates = {
  botAccountId: -1,
  loading: 'idle',
  items: [],
};

const broadcastMessages = createSlice({
  name: 'broadcastTemplate',
  initialState,
  reducers: {
    fetchTemplates(state, action: PayloadAction<{ botAccountId: number }>) {
      state.botAccountId = action.payload.botAccountId;
      state.loading = 'pending';
      state.items = [];
      state.error = undefined;
    },
    fetchTemplatesSuccess(state, action: PayloadAction<WhatsAppTemplate[]>) {
      state.items = action.payload;
    },
    fetchTemplatesFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchTemplatesFulfill(state) {
      state.loading = 'idle';
    },
  },
});

export default broadcastMessages.reducer;

export const {
  fetchTemplates,
  fetchTemplatesSuccess,
  fetchTemplatesFailure,
  fetchTemplatesFulfill,
} = broadcastMessages.actions;
