import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  WSCatalogueProductDetail,
  WSCatalogueShoppingCart,
} from '@src/components/WSCatalogue/types';
import type { ErrorData } from '@types';

type Loading =
  | 'fetch-ws-catalogue-config'
  | 'set-ws-catalogue-config'
  | 'fetch-ws-catalogue-product';

export type WsCatalogueState = {
  error: ErrorData | null;
  loading: Loading[];
  config: {
    isCartEnabled?: boolean;
    isCatalogVisible?: boolean;
  };
  products: WSCatalogueProductDetail[];
  productDetail: WSCatalogueProductDetail | null;
  shoppingCart: WSCatalogueShoppingCart | null;
  showWsCatalogueSideMenu: boolean;
  currentView: FetchWsCatalogueProductTypes | null;
};

type WsCatalogueReducer<P = void> = (
  state: WsCatalogueState,
  action: PayloadAction<P>
) => void;

const initialState: WsCatalogueState = {
  error: null,
  loading: [],
  config: {},
  products: [],
  productDetail: null,
  shoppingCart: null,
  showWsCatalogueSideMenu: false,
  currentView: null,
};

export type WsCatalogueConfig = {
  isCartEnabled: boolean;
  isCatalogVisible: boolean;
};

export type WsCatalogueConfigResponse = {
  // eslint-disable-next-line camelcase
  is_cart_enabled: boolean;
  // eslint-disable-next-line camelcase
  is_catalog_visible: boolean;
};

export type FetchWsCatalogueConfig = {
  botAccountUsername: string;
};

const handleFetchWsCatalogueConfig: WsCatalogueReducer<
  FetchWsCatalogueConfig
> = state => {
  state.loading.push('fetch-ws-catalogue-config');
  state.error = null;
};

const handleFetchWsCatalogueConfigSuccess: WsCatalogueReducer<
  WsCatalogueConfig
> = (state, action) => {
  state.config = action.payload;
};

const handleFetchWsCatalogueConfigFailure: WsCatalogueReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchWsCatalogueConfigFulfill: WsCatalogueReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-ws-catalogue-config');
};

type SetWsCatalogueConfig = FetchWsCatalogueConfig & WsCatalogueConfig;

const handleSetWsCatalogueConfig: WsCatalogueReducer<
  SetWsCatalogueConfig
> = state => {
  state.loading.push('set-ws-catalogue-config');
  state.error = null;
};

const handleSetWsCatalogueConfigSuccess = handleFetchWsCatalogueConfigSuccess;
const handleSetWsCatalogueConfigFailure = handleFetchWsCatalogueConfigFailure;

const handleSetWsCatalogueConfigFulfill: WsCatalogueReducer = state => {
  state.loading = state.loading.filter(l => l !== 'set-ws-catalogue-config');
};

// eslint-disable-next-line no-shadow
export const enum FetchWsCatalogueProductTypes {
  PRODUCT = 'PRODUCT',
  CART = 'CART',
}

type FetchWsCatalogueProduct = {
  retailerIds: string;
  catalogId: string;
  type?: FetchWsCatalogueProductTypes;
  shoppingCart?: WSCatalogueShoppingCart | null;
};

const handleFetchWsCatalogueProduct: WsCatalogueReducer<
  FetchWsCatalogueProduct
> = state => {
  state.loading.push('fetch-ws-catalogue-product');
  state.error = null;
};

type HandleFetchWsCatalogueProductsSuccess = {
  products: WSCatalogueProductDetail[];
  shoppingCart: WSCatalogueShoppingCart | null;
  type?: FetchWsCatalogueProductTypes;
};

const handleFetchWsCatalogueProductsSuccess: WsCatalogueReducer<
  HandleFetchWsCatalogueProductsSuccess
> = (state, action) => {
  const { products, shoppingCart, type } = action.payload;

  if (shoppingCart) {
    state.shoppingCart = shoppingCart;
    state.products = products;
  } else {
    const [product] = products;
    state.productDetail = product;
  }
  state.showWsCatalogueSideMenu = true;
  state.currentView = type || null;
};

const handleFetchWsCatalogueProductsFailure: WsCatalogueReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchWsCatalogueProductsFulfill: WsCatalogueReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-ws-catalogue-product');
};

const handleHideWsCatalogueSideMenu: WsCatalogueReducer = state => {
  state.showWsCatalogueSideMenu = false;
  state.currentView = null;
};

const handleSetCatalogueCurrentView: WsCatalogueReducer<
  FetchWsCatalogueProductTypes
> = (state, action) => {
  state.currentView = action.payload;
};

const wsCatalogueSlice = createSlice({
  name: 'WsCatalogueSlice',
  initialState,
  reducers: {
    fetchWsCatalogueConfig: handleFetchWsCatalogueConfig,
    fetchWsCatalogueConfigSuccess: handleFetchWsCatalogueConfigSuccess,
    fetchWsCatalogueConfigFailure: handleFetchWsCatalogueConfigFailure,
    fetchWsCatalogueConfigFulfill: handleFetchWsCatalogueConfigFulfill,
    setWsCatalogue: handleSetWsCatalogueConfig,
    setWsCatalogueSuccess: handleSetWsCatalogueConfigSuccess,
    setWsCatalogueFailure: handleSetWsCatalogueConfigFailure,
    setWsCatalogueFulfill: handleSetWsCatalogueConfigFulfill,
    fetchWsCatalogueProducts: handleFetchWsCatalogueProduct,
    fetchWsCatalogueProductSuccess: handleFetchWsCatalogueProductsSuccess,
    fetchWsCatalogueProductFailure: handleFetchWsCatalogueProductsFailure,
    fetchWsCatalogueProductFulfill: handleFetchWsCatalogueProductsFulfill,
    hideWsCatalogueSideMenu: handleHideWsCatalogueSideMenu,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleCloseWsCatalogueSideMenu: () => {},
    setCatalogueCurrentView: handleSetCatalogueCurrentView,
  },
});

const { actions, reducer } = wsCatalogueSlice;

export const {
  fetchWsCatalogueConfig,
  fetchWsCatalogueConfigSuccess,
  fetchWsCatalogueConfigFailure,
  fetchWsCatalogueConfigFulfill,
  setWsCatalogue,
  setWsCatalogueSuccess,
  setWsCatalogueFailure,
  setWsCatalogueFulfill,
  fetchWsCatalogueProducts,
  fetchWsCatalogueProductSuccess,
  fetchWsCatalogueProductFailure,
  fetchWsCatalogueProductFulfill,
  hideWsCatalogueSideMenu,
  handleCloseWsCatalogueSideMenu,
  setCatalogueCurrentView,
} = actions;

export default reducer;
