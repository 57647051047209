import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Banner, ErrorData } from '@types';

export type BannerState = {
  items: Banner[];
  error: ErrorData | null;
  show: boolean;
};

const initialState: BannerState = {
  items: [],
  error: null,
  show: false,
};

const BannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    fetchLoadBanners(state, action: PayloadAction<Banner[]>) {
      const { payload } = action;
      state.show = payload.length > 0;
      state.items = payload;
    },
    fetchUpdateBanner(state, action: PayloadAction<Banner>) {
      const { payload } = action;
      state.items.length = 0;
      state.items = [...state.items, payload];
      state.show = true;
    },
    fetchDeleteBanner(state) {
      state.items.length = 0;
      state.show = false;
    },
    fetchBannersFailure(state, action: PayloadAction<ErrorData>) {
      const { payload } = action;
      state.error = payload;
    },
    fetchHideBanner(state) {
      state.show = false;
    },
  },
});

const { actions, reducer } = BannerSlice;

export const {
  fetchLoadBanners,
  fetchUpdateBanner,
  fetchDeleteBanner,
  fetchHideBanner,
  fetchBannersFailure,
} = actions;

export default reducer;
