/* eslint-disable max-lines */
import BackendProxy from '../logic/backendproxy';
import FbApiProxy from '../logic/fbapiproxy';
import { MessagingProvider } from '../model/frontendmodel';
import { normalizeBot } from '../model/transform';

// List of codes (as known by Facebook) of all permissions required by the account setup process
const arrRequiredPermissionsFB = [
  'pages_read_engagement',
  'pages_show_list',
  'pages_messaging',
  'public_profile',
  'pages_manage_metadata'
];
const arrRequiredPermissionsInstagram = [
  ...arrRequiredPermissionsFB,
  'instagram_basic',
  'instagram_manage_messages'
];

export const CHECK_LOGIN_STATUS = 'FB_ACCOUNT_SETUP.CHECK_LOGIN_STATUS';
export const CHECK_PERMISSIONS = 'FB_ACCOUNT_SETUP.CHECK_PERMISSIONS';
export const REASK_PERMISSIONS = 'FB_ACCOUNT_SETUP.REASK_PERMISSIONS';
export const LOAD_USER_PAGES_AND_BOTS =
  'FB_ACCOUNT_SETUP.LOAD_USER_PAGES_AND_BOTS';
export const SET_LOGGED_IN = 'FB_ACCOUNT_SETUP.SET_LOGGED_IN';
export const SET_LOGGED_OUT = 'FB_ACCOUNT_SETUP.SET_LOGGED_OUT';
export const SET_DENIED_SOME_PERMISSIONS =
  'FB_ACCOUNT_SETUP.SET_DENIED_SOME_PERMISSIONS';
export const SET_GRANTED_ALL_PERMISSIONS =
  'FB_ACCOUNT_SETUP.SET_GRANTED_ALL_PERMISSIONS';
export const SET_READY_TO_LINK_PAGE_WITH_BOT =
  'FB_ACCOUNT_SETUP.SET_READY_TO_LINK_PAGE_WITH_BOT';
export const LINK_UP_USER_PAGE_WITH_BOT =
  'FB_ACCOUNT_SETUP.LINK_UP_USER_PAGE_WITH_BOT';
export const SET_LINKED_UP_PAGE_WITH_BOT_OK =
  'FB_ACCOUNT_SETUP.SET_LINKED_UP_PAGE_WITH_BOT_OK';
export const SET_LINKED_UP_PAGE_WITH_BOT_FAIL =
  'FB_ACCOUNT_SETUP.SET_LINKED_UP_PAGE_WITH_BOT_FAIL';
export const START_LOADING_ACCOUNT_TO_EDIT =
  'FB_ACCOUNT_SETUP.START_LOADING_ACCOUNT_TO_EDIT';
export const SET_ACCOUNT_LOADED = 'FB_ACCOUNT_SETUP.SET_ACCOUNT_LOADED';
export const SET_CURRENT_PROVIDER = 'FB_ACCOUNT_SETUP.SET_CURRENT_PROVIDER';

// List of states (i.e. steps) involved in the setup process of a Facebook account
export const enumSetupProcessStates = {
  LOADING_ACCOUNT: 'LOADING_ACCOUNT',
  CHECKING_LOGIN_STATUS: 'CHECKING_LOGIN_STATUS',
  CHECKING_PERMISSIONS: 'CHECKING_PERMISSIONS',
  REASKING_PERMISSIONS: 'REASKING_PERMISSIONS',
  LOADING_USER_PAGES_AND_BOTS: 'LOADING_USER_PAGES_AND_BOTS',
  LOGGED_OUT: 'LOGGED_OUT',
  UNSPECIFIED_PERMISSIONS: 'UNSPECIFIED_PERMISSIONS',
  DENIED_PERMISSIONS_ONCE: 'DENIED_PERMISSIONS_ONCE',
  DEFINITELY_DENIED_PERMISSIONS: 'DEFINITELY_DENIED_PERMISSIONS',
  GRANTED_ALL_PERMISSIONS: 'GRANTED_ALL_PERMISSIONS',
  READY_TO_LINK_PAGE_WITH_BOT: 'READY_TO_LINK_PAGE_WITH_BOT',
  LINKING_UP_PAGE_WITH_BOT: 'LINKING_UP_PAGE_WITH_BOT',
  PAGE_SETUP_COMPLETED: 'PAGE_SETUP_COMPLETED',
  PAGE_SETUP_FAILED: 'PAGE_SETUP_FAILED',
  PAGE_SETUP_FAILED_BOT_CONFLICT: 'PAGE_SETUP_FAILED_BOT_CONFLICT',
};

export function startCheckLoginStatus() {
  return {
    type: CHECK_LOGIN_STATUS,
  };
}

export function setLoggedIn(loginData, llAccessToken) {
  return {
    type: SET_LOGGED_IN,
    loginData,
    llAccessToken,
  };
}

export function setLoggedOut() {
  return {
    type: SET_LOGGED_OUT,
  };
}

export function startCheckPermissions() {
  return {
    type: CHECK_PERMISSIONS,
  };
}

export function startReaskPermissions() {
  return {
    type: REASK_PERMISSIONS,
  };
}

export function setDeniedSomePermissions(mapPermissions) {
  return {
    type: SET_DENIED_SOME_PERMISSIONS,
    mapPermissions,
  };
}

export function setGrantedAllPermissions(mapPermissions) {
  return {
    type: SET_GRANTED_ALL_PERMISSIONS,
    mapPermissions,
  };
}

export function startLoadingUserPages() {
  return {
    type: LOAD_USER_PAGES_AND_BOTS,
  };
}

export function setReadyToLinkBotToPage(lstUserPages, lstUserBots) {
  return {
    type: SET_READY_TO_LINK_PAGE_WITH_BOT,
    lstUserPages,
    lstUserBots,
  };
}

export function startLinkingUpPageWithBot() {
  return {
    type: LINK_UP_USER_PAGE_WITH_BOT,
  };
}

export function setLinkedUpPageWithBotOk(pageId, botId) {
  return {
    type: SET_LINKED_UP_PAGE_WITH_BOT_OK,
    pageId,
    botId,
  };
}

export function setLinkedUpPageWithBotFail(pageId, botId, errDetails) {
  return {
    type: SET_LINKED_UP_PAGE_WITH_BOT_FAIL,
    pageId,
    botId,
    errDetails,
  };
}

// Thunk action. Checks that a long-lived access token has been obtained for the specified login data
export function ensureLoggedInWithLongLivedAccessToken(loginData) {
  return (dispatch, getState) => {
    // The user access token provided by Facebook as part of the login data is short-lived
    const shortLivedAccessToken = loginData.accessToken;

    // To ensure that the page tokens obtained from Facebook are non-expiring, get a long-lived access token
    BackendProxy.requestFbLongLivedAccessToken(
      shortLivedAccessToken,
      longLivedAccessToken => {
        dispatch(setLoggedIn(loginData, longLivedAccessToken));
      },
      error => {
        console.log(
          'Error attempting to obtain long-lived user access token for: ',
          shortLivedAccessToken
        );
        dispatch(setLoggedOut());
      }
    );
  };
}

// Thunk action. Checks the current login status of the user through the Facebook API. Processes the result
export function loginWithFacebook() {
  return (dispatch, getState) => {
    const { currentProvider } =
      getState().adminHome.botSetupState.fbAccountSetup;
    const arrPermissions =
      currentProvider === 'FB'
        ? arrRequiredPermissionsFB
        : arrRequiredPermissionsInstagram;

    // Inform that the Facebook API is now checking the login status
    dispatch(startCheckLoginStatus());

    // Asynchronously check the login status through the Facebook API
    FbApiProxy.login(fbLoginResponse => {
      if (fbLoginResponse.status === 'connected') {
        dispatch(
          ensureLoggedInWithLongLivedAccessToken(fbLoginResponse.authResponse)
        );
      } else {
        dispatch(setLoggedOut());
      }
    }, arrPermissions);
  };
}

// Thunk action. Checks the current login status of the user through the Facebook API. Processes the result
export function checkLoginStatus() {
  return (dispatch, getState) => {
    // Inform that the Facebook API is now checking the login status
    dispatch(startCheckLoginStatus());

    // Asynchronously check the login status through the Facebook API
    FbApiProxy.getLoginStatus(fbLoginResponse => {
      if (fbLoginResponse.status === 'connected') {
        dispatch(
          ensureLoggedInWithLongLivedAccessToken(fbLoginResponse.authResponse)
        );
      } else {
        dispatch(setLoggedOut());
      }
    });
  };
}

// Thunk action. Verifies the set of permissions that have been granted/denied by the user through the Facebook API
export function checkPermissions() {
  return (dispatch, getState) => {
    const { currentProvider } = getState().adminHome.botSetupState;
    const arrPermissions =
      currentProvider === 'FB'
        ? arrRequiredPermissionsFB
        : arrRequiredPermissionsInstagram;
    // Inform that the Facebook API function to check granted permissions is being called
    dispatch(startCheckPermissions());

    // Asynchronously request the permissions granted/denied by the user
    FbApiProxy.checkPermissions(fbPermsReponse => {
      let countReqPermsDenied = 0;
      const mapPermissions = {};
      let arrPermsData = [];

      // Initialize the map of permissions, with all the required permissions set to DENIED by default
      arrPermissions.forEach(p => {
        mapPermissions[p] = true;
      });

      // Update the map of permissions as to the data obtained from Facebook
      if (
        fbPermsReponse &&
        fbPermsReponse.data &&
        fbPermsReponse.data.constructor === Array
      ) {
        arrPermsData = fbPermsReponse.data;
      }

      arrPermsData.forEach(perm => {
        if (perm.status !== 'granted') {
          mapPermissions[perm.permission] = false;
          countReqPermsDenied += 1;
        }
      });

      if (countReqPermsDenied > 0) {
        dispatch(setDeniedSomePermissions(mapPermissions));
      } else {
        dispatch(setGrantedAllPermissions(mapPermissions));
      }
    });
  };
}

// Thunk action. Re-asks any Facebook permissions that be denied by the user in a previous attempt to login
export function reAskPermissions() {
  return (dispatch, getState) => {
    // Inform that the Facebook API function to re-ask denied permissions is being called
    dispatch(startReaskPermissions());

    // Build an array with the permissions to be re-asked (that is, any permissions currently denied)
    const { mapPermissions } =
      getState().adminHome.botSetupState.fbAccountSetup;

    if (mapPermissions) {
      const arrDeniedPerms = [];

      Object.keys(mapPermissions).forEach(permCode => {
        if (!mapPermissions[permCode]) {
          arrDeniedPerms.push(permCode);
        }
      });

      // Check that there are any denied permissions to reask, otherwise means that all permissions are granted
      if (arrDeniedPerms.length > 0) {
        // Login again, this time just to re-ask permissions previously denied
        FbApiProxy.login(
          fbReAskResponse => {
            dispatch(checkPermissions(true));
          },
          arrDeniedPerms,
          true
        );
      } else {
        dispatch(setGrantedAllPermissions(mapPermissions));
      }
    }
  };
}

// Thunk action. Acquires a long-lived user access token and loads all user pages through it in order
// to obtain non-expiring page access tokens for every page
export function loadUserPages() {
  return (dispatch, getState) => {
    // Obtain the long-lived user access token obtained when logged into the Facebook API
    const longLivedAccessToken =
      getState().adminHome.botSetupState.fbAccountSetup.llAccessToken;

    // Inform that the Facebook API function to check granted permissions is being called
    dispatch(startLoadingUserPages());

    let loadedLstUserPages = null;
    let loadedLstUserBots = null;

    // Asynchronously request the list of pages of the user (use the long-lived access token)
    FbApiProxy.listUserPages(longLivedAccessToken, lstUserPages => {
      loadedLstUserPages = lstUserPages;

      // Now load the list of bots associated with the merchant the user belongs to
      BackendProxy.getCurrentUserBots(
        true,
        lstUserBots => {
          if (lstUserBots && lstUserBots.constructor === Array) {
            loadedLstUserBots = lstUserBots.map(rBot => normalizeBot(rBot));
          }

          dispatch(
            setReadyToLinkBotToPage(loadedLstUserPages, loadedLstUserBots)
          );
        },
        (error, details) => {
          console.log('Error attempting to load user bots: ', details);
        }
      ).then(r => {
        console.debug('Loaded bots', r);
      });
    });
  };
}

// Thunk action. Links up a user page with a bot, given their respective IDs and the page access token
export function linkUpUserPageWithBot(
  accountId,
  pageId,
  pageAccessToken,
  alias,
  botId
) {
  return (dispatch, getState) => {
    const { currentProvider } =
      getState().adminHome.botSetupState.fbAccountSetup;
    const providerCode =
      currentProvider === 'FB'
        ? MessagingProvider.FACEBOOK
        : MessagingProvider.INSTAGRAM;

    // Indicate that the process to link up a page with a bot is running
    dispatch(startLinkingUpPageWithBot());

    // 1. Create the account. If no bot was specified, it's necessary to first create a default bot for the merchant
    const fnCreateMessagingAccount = (fnOkCallback, fnErrorCallback) => {
      if (botId !== undefined && botId !== null) {
        // Add/update a Facebook messaging account corresponding to the page and associate it with the bot
        BackendProxy.updateBotMessagingAccount(
          accountId,
          botId,
          providerCode,
          pageId,
          pageAccessToken,
          alias,
          fnOkCallback,
          fnErrorCallback
        );
      } else {
        // Create a default bot and set the Facebook messaging account as its first account
        BackendProxy.createDefaultBotWithMsgAccount(
          providerCode,
          pageId,
          pageAccessToken,
          alias,
          fnOkCallback,
          fnErrorCallback
        );
      }
    };

    // Start the appropriate account creation process
    fnCreateMessagingAccount(
      res => {
        // 2. Subscribe the page to the bot application via the Facebook Messenger infrastructure
        FbApiProxy.linkUpUserPageWithBot(
          pageId,
          pageAccessToken,
          botId,
          response => {
            if (response && response.success && response.success === true) {
              dispatch(setLinkedUpPageWithBotOk(pageId, botId));
            } else {
              dispatch(setLinkedUpPageWithBotFail(pageId, botId));
            }
          }
        );
      },
      (errCode, errMsg, errDetails) => {
        dispatch(setLinkedUpPageWithBotFail(pageId, botId, errDetails));
      }
    );
  };
}

export function startLoadAccountToEdit(accountId) {
  return {
    type: START_LOADING_ACCOUNT_TO_EDIT,
    accountId,
  };
}

/** TODO: REMOVE THE FOLLOWING ACTIONS, REPLACE WITH LOGIC AT THE COMPONENT **/
export function setAccountLoaded(botAccount) {
  return {
    type: SET_ACCOUNT_LOADED,
    msgAccount: botAccount,
  };
}

// Thunk action. Retrieves a bot-account given its ID
export function loadAccountToEdit(accountId) {
  return (dispatch, getState) => {
    // Inform that the requested bot-account is being loaded from the backend
    dispatch(startLoadAccountToEdit());

    // Request the bot-account to be loaded by ID
    BackendProxy.getBotAccount(
      accountId,
      botAccount => {
        dispatch(setAccountLoaded(botAccount));
      },
      (error, details) => {
        throw new Error(
          `Error attempting to load bot-account: ${accountId}: ${error}`
        );
      }
    );
  };
}

export function setCurrentProvider(provider) {
  return {
    type: SET_CURRENT_PROVIDER,
    currentProvider: provider,
  };
}

export function setCurrentProviderThunk(provider) {
  return dispatch => {
    dispatch(setCurrentProvider(provider));
  };
}
