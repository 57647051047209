import { ErrorData } from '@b2chat/chat-center-sdk';
import B2ChatClient from '@client-sdk';
// eslint-disable-next-line import/no-named-as-default
import getMerchantId from '@src/selectors/merchant';
import type { User } from '@types';
import type { B2ChatAPI } from '@types-api';
import { all, call, delay, put, select, takeLeading } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import {
  fetchingUsers,
  fetchUsers,
  fetchUsersFailure,
  fetchUsersFulfill,
  fetchUsersSuccess,
} from '../reducers/users';

type UserWithDepartmentsAndOnlineStatus = {
  loginStatus: boolean;
  user: User & {
    businessProcesses: {
      id: number;
      bot: {
        id: number;
        standardServiceBot: boolean;
        generic: boolean;
      };
      number: 3;
      code: string;
      name: string;
      state: 'ACTIVE' | 'INACTIVE';
    }[];
  };
};

function* fetchUsersSaga() {
  try {
    yield put(fetchingUsers());
    const merchantId: number = yield select(getMerchantId);

    const [users, usersWithDepartmentsAndOnlineStatus]: [
      B2ChatAPI.Response<User[]>,
      B2ChatAPI.Response<UserWithDepartmentsAndOnlineStatus[]>
    ] = yield all([
      call(B2ChatClient.resources.user.actions.getUsers, {}),
      call(
        B2ChatClient.resources.agentConsole.actions
          .searchAgentsLoginStatusActiveDepartments,
        {
          params: { rqId: uuid() },
          data: { merchantId },
        }
      ),
    ]);

    if (users.data && usersWithDepartmentsAndOnlineStatus.data) {
      users.data.forEach(user => {
        // eslint-disable-next-line no-underscore-dangle
        const user_ = usersWithDepartmentsAndOnlineStatus.data.find(
          ({ user: { id } }) => id === user.id
        );

        user.online = user_?.loginStatus || false;
        user.departments =
          user_?.user.businessProcesses?.map(dep => ({
            name: dep.name,
            code: dep.code,
            departmentId: dep.id,
            botId: dep.bot.id,
          })) || [];
      });
    }

    if (users.error) {
      throw users.error;
    } else {
      yield put(fetchUsersSuccess(users.data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    yield put(fetchUsersFailure(e as ErrorData));
  } finally {
    yield put(fetchUsersFulfill());
    yield delay(60_000);
  }
}

export default function* usersSaga() {
  yield takeLeading(fetchUsers, fetchUsersSaga);
}
