import _ from 'lodash-es';
import {
  buildBackendCustomerRegistration,
  buildBackendMessagingAccount,
} from '../model/backendmodel';
import { ResponseStatusCode } from '../model/frontendmodel';
import env from '../utils/env';
import Logger from '../utils/logging';
import StringUtils from '../utils/strings';
import TypeUtils from '../utils/typeutils';
import {
  checkHttpFetchStatus,
  failWithErrorReport,
  getResponseHeader,
} from './backendproxyutils';

const logger = Logger.getLogger('backendproxy');

// Links the Agent Chat frontend with the backend application via websockets and REST services
const BackendProxy = (function (endpointUrl) {
  console.info('API host', endpointUrl);

  // --- Private variables
  let csrfToken = null;
  const confEndpointUrl = endpointUrl;

  // Set the CSRF token to be used in AJAX requests
  const csrfInput = document.getElementsByName('_csrf')[0];

  if (csrfInput !== undefined) {
    csrfToken = csrfInput.value;
  }

  return {
    // Says whether the proxy is already connected to the backend
    notifyReadMessages(
      agentChatId,
      providerMessageIds,
      okCallback,
      failCallback
    ) {
      fetch(
        `${confEndpointUrl}/agent/chat/notify-read-messages/${agentChatId}`,
        {
          method: 'POST',
          body: JSON.stringify(providerMessageIds),
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        }
      )
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.status === ResponseStatusCode.OK) {
            okCallback();
          } else {
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    // Uploads a file into the backend's file repository. The file argument provided must've been
    // obtained from an input[type=file] element
    uploadFile(fileRef, okCallback, failCallback) {
      const data = new FormData();
      data.append('file', fileRef);
      data.append('_csrf', csrfToken);

      return fetch(`${confEndpointUrl}/services/files/uploadfiles/single`, {
        method: 'POST',
        body: data,
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (okCallback && responseEvent.status === ResponseStatusCode.OK) {
            okCallback(responseEvent.response.fileUrl);
          } else if (failCallback) {
            failCallback(responseEvent.status, responseEvent.error);
          }
          return responseEvent;
        })
        .catch(error => {
          if (failCallback) {
            failCallback(
              ResponseStatusCode.ERROR,
              `HTTP error: ${error.message}`
            );
          }
        });
    },

    getContacById(id, success) {
      console.log('BackendProxy.getContactById', id);
      fetch(`${confEndpointUrl}/services/contact/get/${id}`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: null,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          console.log('BackendProxy.getContactById.Response', id, response);
          success(response);
        });
    },

    getContacts(merchantId, okCallback, failCallback) {
      console.log(`(backendProxy) Merchant Id from contacts => ${merchantId}`);

      fetch(`${confEndpointUrl}/services/contact/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Contacts OK', responseEvent);

            const contactObject = {};
            contactObject.contacts = responseEvent;

            okCallback(contactObject);
          } else {
            console.log('Get Contacts FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getAgents(merchantId, okCallback, failCallback) {
      console.log(`(backendProxy) Merchant Id from Agents => ${merchantId}`);

      fetch(`${confEndpointUrl}/services/agent/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agents OK', responseEvent);

            const agentObject = {};
            agentObject.agents = responseEvent;

            okCallback(agentObject);
          } else {
            console.log('Get Agents FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getUsers(userFilter, okCallback, failCallback) {
      console.log(`(backendProxy) User Filters from Users => ${userFilter}`);

      let userValue = '';
      let status = '';
      let allUsers = true;
      let role;

      if (userFilter !== null) {
        userValue = userFilter.userValue;
        status = userFilter.status;
        allUsers = false;
        role = userFilter.role;
      }

      fetch(`${confEndpointUrl}/services/user/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          allUsers,
          userValue,
          status,
          role,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Users OK', responseEvent);

            const agentObject = {};
            agentObject.users = responseEvent;

            okCallback(agentObject);
          } else {
            console.log('Get Users FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    /** Este metodo recibe las siguientes rutas:
     *@ switchStatus (cambia el estado del usuario)
     *@ activate (activa usuario desde pagina publica)
     *@ save (Crea o actualiza usuario desde pagina de usuarios)
     *@**/
    saveOrActivateUser(user, path, okCallback, failCallback) {
      console.log('(backendProxy) User from to save => ', user);

      fetch(`${confEndpointUrl}/services/user/${path}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: user.id,
          fullName: user.fullName,
          mobileNumber: user.mobileNumber,
          email: user.email,
          role: user.role,
          userName: user.userName,
          password: user.password,
          matchPassword: user.matchPassword,
          token: user.token,
          acceptsTerms: user.acceptsTerms,
          acceptsDataPrivacyPolicy: user.acceptsDataPrivacyPolicy,
          imagePreviewUrl: user.avatar,
          mobileAccess: user.mobileAccess,
          deleteChatsPolicy: user.chatDeletePolicy,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            console.log('Save User OK', responseEvent);

            const agentObject = {};
            agentObject.user = responseEvent;

            okCallback(agentObject);
          } else {
            console.log('Saving user FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Retrieves all users of the specified merchant with information about their login state.
    // Returns a promise that resolves to {userStates: loginStates}, with userLoginStates an array of UserLoginStatus
    getUsersLoginState(merchantId, filter = { onlyActive: false }) {
      // Append a request ID as a querystring argument to ensure that responses are not cached
      const targetUrl = filter?.onlyActive
        ? `${confEndpointUrl}/services/agent/searchAgentsLoginStatusActiveDepartments?rqId=${StringUtils.getUuid()}`
        : `${confEndpointUrl}/services/agent/searchAgentsLoginStatus?rqId=${StringUtils.getUuid()}`;

      return fetch(targetUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (!_.isNil(responseEvent)) {
            console.debug('Get Agents OK', responseEvent);
            return {
              userStates: responseEvent,
            };
          }
          throw new Error('Empty response');
        })
        .catch(error => {
          console.debug('Get Agents FAILED. Error:', error);
          return Promise.reject({
            status: ResponseStatusCode.ERROR,
            message: String(error.message),
            error,
          });
        });
    },

    getProducts(merchantId, merchantName, okCallback, failCallback) {
      console.log(`(backendProxy) Merchant Id from products => ${merchantId}`);

      fetch(`${confEndpointUrl}/services/product/getProductList`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
          merchantName,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Products OK', responseEvent);

            const productObject = {};
            productObject.lstPagedItems = responseEvent;

            okCallback(productObject);
          } else {
            console.log('Get Products FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    createNewCategory(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/product/createNewCategory`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Create Order FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    changeStateProduct(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/product/changeStateProduct`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Create Order FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getProductList(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/product/getProductList`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Create Order FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getCategories(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/product/getCategories`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            console.log('getCategories: ', responseEvent);
            okCallback(responseEvent);
          } else {
            console.log('Create Order FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    createOrder(order, okCallback, failCallback) {
      console.log(
        `(backendProxy) Merchant Id from createOrder => ${order.merchant.id}`
      );

      fetch(`${confEndpointUrl}/services/order/createOrder`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          order,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            console.log('Order number: ', responseEvent.orderNumber);

            let orderNumber;
            orderNumber = responseEvent.orderNumber;

            okCallback(orderNumber);
          } else {
            console.log('Create Order FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    exportProduct(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/product/export`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);

            const agentChatsObject = {};
            agentChatsObject.states = responseEvent;

            okCallback(agentChatsObject);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    orderExport(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/export`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);

            const agentChatsObject = {};
            agentChatsObject.states = responseEvent;

            okCallback(agentChatsObject);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getOrderStates(filter, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/listStatus`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(filter),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);

            const agentChatsObject = {};
            agentChatsObject.states = responseEvent;

            okCallback(agentChatsObject);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    ///

    getOrderContacts(merchantId, okCallback, failCallback) {
      console.log(`(backendProxy) Merchant Id from contacts => ${merchantId}`);

      fetch(`${confEndpointUrl}/services/contact/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Contacts OK', responseEvent);

            const contactObject = {};
            contactObject.contacts = responseEvent;

            okCallback(contactObject);
          } else {
            console.log('Get Contacts FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getOrdersList(filter, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(filter),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);

            const agentChatsObject = {};
            agentChatsObject.orders = responseEvent;

            okCallback(agentChatsObject);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    updateOrderState(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/updateOrderState`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);
            okCallback(responseEvent);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getOrderDetail(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/getOrderDetail`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);
            let order = null;
            if (
              responseEvent !== undefined &&
              responseEvent !== null &&
              responseEvent.length > 0
            ) {
              order = responseEvent[0];
            }
            okCallback(order);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    cancelOrderState(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/cancelOrder`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get cancelOrder OK', responseEvent);
            okCallback(responseEvent);
          } else {
            console.log('Get cancelOrder FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    saveProduct(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/product/saveProduct`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get cancelOrder OK', responseEvent);
            okCallback(responseEvent);
          } else {
            console.log('Get cancelOrder FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getAllOrderStates(data, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/order/getOrderStates`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get getAllOrderStates OK', responseEvent);
            okCallback(responseEvent);
          } else {
            console.log('Get getAllOrderStates FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getChats(filter, okCallback, failCallback) {
      console.log(
        `(backendProxy) Filterby Agent chats => ${filter.merchantId} || ${filter.agentId} || ${filter.contactValue} || ${filter.startDate} || ${filter.endDate} || ${filter.text} || ${filter.activeChat} || ${filter.timeframe} || ${filter.contactId}`
      );

      fetch(`${confEndpointUrl}/services/agentChat/search`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId: filter.merchantId,
          agentId: filter.agentId,
          contactValue: filter.contactValue,
          contactId: filter.contactId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          text: filter.text,
          activeChat: filter.activeChat,
          timeframe: filter.timeframe,
          searchType: filter.searchType,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          //if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Agent Chats OK', responseEvent);

            const agentChatsObject = {};
            agentChatsObject.chats = responseEvent;

            okCallback(agentChatsObject);
          } else {
            console.log('Get Agent Chats FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },
    getMessages(chaiId, createdAtMilli, okCallback, failCallback) {
      console.log(
        '(backendProxy) Filter by chat Id and Creation date: ',
        chaiId,
        createdAtMilli
      );

      fetch(`${confEndpointUrl}/services/agentChatMessage/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          chatId: chaiId,
          createdAtMilli,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          // if (responseEvent.status === ResponseStatusCode.OK) {
          if (responseEvent !== null) {
            console.log('Get Messages OK', responseEvent);

            const messagesObject = {};
            messagesObject.messages = responseEvent;

            okCallback(messagesObject);
          } else {
            console.log('Get Messages FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    // Sends a load contact messaging account chat history request
    loadContactMsgAccountChatHistory(
      accountId,
      viaBotAccount,
      viaMsgProvider,
      okCallback,
      failCallback,
      requestId = null
    ) {
      let queryString = `?contact-msg-account-name=${encodeURIComponent(
        accountId
      )}`;

      if (!StringUtils.isBlank(requestId))
        queryString += `&rqId=${encodeURIComponent(requestId)}`;
      if (viaBotAccount)
        queryString += `&via-bot-account=${encodeURIComponent(viaBotAccount)}`;
      if (viaMsgProvider)
        queryString += `&via-msg-provider=${encodeURIComponent(
          viaMsgProvider
        )}`;

      logger.log('Fetching chat history:', queryString);

      fetch(`${confEndpointUrl}/agent/chat/load-chat-history${queryString}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          logger.log('Response to load-chat-history OK:', responseEvent);

          okCallback(responseEvent);
        })
        .catch(error => {
          logger.log('Response to load-chat-history ERROR:', error);
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    // Sends a save new contact request, given the ID of the bot it belongs to
    saveNewContact(
      { botId, contactId, provider, newContact },
      okCallback,
      failCallback
    ) {
      fetch(`${confEndpointUrl}/services/contact`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          botId,
          contact: newContact,
          contactMessagingAccount: {
            accountId: contactId,
            provider,
          },
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.id) {
            okCallback(responseEvent.id);
          } else {
            throw new Error(
              'Add new contact failed. Contact ID missing in response'
            );
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    // Sends a save new contact request, given a messaging account of the bot it belongs to
    saveNewContactOfBotAccount(
      botMsgAccount,
      contactMsgAccount,
      contact,
      okCallback,
      failCallback
    ) {
      fetch(`${confEndpointUrl}/services/contact`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          botMessagingAccount: botMsgAccount,
          contact,
          contactMessagingAccount: {
            accountId: contactMsgAccount.accountId,
            provider: contactMsgAccount.provider,
          },
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.id) {
            okCallback(responseEvent.id);
          } else {
            throw new Error(
              'Add new contact failed. Contact ID missing in response'
            );
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    // Finds a contact of a specific merchant given their uniqeue IDs
    findContactOfMerchant(merchantId, contactId, okCallback, failCallback) {
      const searchCriteria = {
        merchantId,
        id: contactId,
      };

      fetch(`${confEndpointUrl}/services/contact/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(searchCriteria),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(arrResults => {
          if (arrResults && arrResults.length > 0) {
            okCallback(arrResults[0]);
          } else {
            okCallback(null);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    // Search contacts of a specific bot by diverse criteria that should be set as attributes of the contact argument
    searchContactOfBot(botId, contact, okCallback, failCallback) {
      const searchCriteria = { botId };

      // Set each attribute defined in the contact argument as a search criteria
      for (const key in contact) {
        if (contact.hasOwnProperty(key)) {
          searchCriteria[key] = contact[key];
        }
      }

      fetch(`${confEndpointUrl}/services/contact/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(searchCriteria),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          okCallback(responseEvent);
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    // Sends a save contact request, for a specific merchant
    updateContact(contact, activeChatId, okCallback, failCallback) {
      // If the contact has an non-null ID field, it's an existing contact to be modified
      if (contact.id && String(contact.id).trim() !== '') {
        fetch(
          `${confEndpointUrl}/services/contact/${contact.id}/${activeChatId}`,
          {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(contact),
          }
        )
          .then(checkHttpFetchStatus)
          .then(httpResponse => {
            okCallback();
          })
          .catch(error => {
            failCallback(
              ResponseStatusCode.ERROR,
              `HTTP error: ${error.message}`,
              error.response
            );
          });
      } else {
        // The contact to be updated must have a valid ID set as property
        throw new Error('Error updating contact. ID property not set');
      }
    },

    // Declares the intention of an agent to export a chat to the specified CRM service
    exportChatToCrm(merchant, chatId, crmConnector, okCallback, failCallback) {
      const exportChatToCrmUrl = `${confEndpointUrl}/services/crm/${crmConnector.toLowerCase()}/createTicket`;

      const agentChatToExport = JSON.stringify({
        id: chatId,
        merchant: { id: merchant.id, name: merchant.name },
      });

      fetch(exportChatToCrmUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: agentChatToExport,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.status === ResponseStatusCode.OK) {
            okCallback(responseEvent.ticketId);
          } else {
            failCallback(ResponseStatusCode.ERROR, 'Ticket creation failed');
          }
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Requests the backend to start a chat between the current user and the specified contact, via a bot account
    startChatWithContact(
      botId,
      viaBotAccount,
      viaMsgProvider,
      contactMsgAccount,
      contactId,
      templatedMsgId,
      okCallback,
      failCallback
    ) {
      // Generate an ID for the start-chat request
      const rqId = `${String(botId)}.${String(viaBotAccount)}.${Date.now()}`;

      let startChatUrl = `${confEndpointUrl}/agent/chat/start?request-id=${encodeURIComponent(
        rqId
      )}`;

      // Set the arguments provided as querystring parameters
      if (!TypeUtils.isUndefinedOrNull(botId)) {
        startChatUrl += `&bot-id=${encodeURIComponent(botId)}`;
      }

      if (!TypeUtils.isUndefinedOrNull(viaBotAccount)) {
        startChatUrl += `&via-bot-account=${encodeURIComponent(viaBotAccount)}`;
      }

      if (!TypeUtils.isUndefinedOrNull(viaMsgProvider)) {
        startChatUrl += `&via-msg-provider=${encodeURIComponent(
          viaMsgProvider
        )}`;
      }

      if (!TypeUtils.isUndefinedOrNull(contactMsgAccount)) {
        startChatUrl += `&contact-account=${encodeURIComponent(
          contactMsgAccount
        )}`;
      }

      // If the contact with which the chat is to be started is specified, send it as parameter. Otherwise the
      // start-chat-service will attempt to infer the contact from the contact messaging account
      if (!TypeUtils.isUndefinedOrNull(contactId)) {
        startChatUrl += `&contact-id=${encodeURIComponent(contactId)}`;
      }

      if (!TypeUtils.isUndefinedOrNull(templatedMsgId)) {
        startChatUrl += `&templated-msg-id=${encodeURIComponent(
          templatedMsgId
        )}`;
      }

      fetch(startChatUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(respObj => {
          if (respObj && respObj.chatId) {
            okCallback(rqId, respObj.chatId);
          } else {
            failCallback(
              rqId,
              ResponseStatusCode.ERROR,
              `Start chat request failed. Bad response ${String(respObj)}`
            );
          }
        })
        .catch(error => {
          if (error.response) {
            error.response
              .json()
              .then(errorDetails => {
                failCallback(
                  rqId,
                  ResponseStatusCode.ERROR,
                  'Start chat request failed',
                  errorDetails
                );
              })
              .catch(errorParsingDetails => {
                if (error.response) {
                  failCallback(
                    rqId,
                    ResponseStatusCode.ERROR,
                    `Unprocessable response ${errorParsingDetails}. R/: ${String(
                      error.response.status
                    )} ${String(error.response.statusText)}. URL: ${String(
                      error.response.url
                    )}`
                  );
                } else {
                  failCallback(
                    rqId,
                    ResponseStatusCode.ERROR,
                    `Unprocessable response ${errorParsingDetails}`
                  );
                }
              });
          } else {
            failCallback(
              rqId,
              ResponseStatusCode.ERROR,
              `Error in response. ${error || ''}`
            );
          }
        });

      return rqId;
    },

    // Retrieves the set of bots that belong to the merchant the current user is associated with
    getCurrentUserBots(
      excludeApi = false,
      okCallback = null,
      failCallback = null
    ) {
      const url = `${confEndpointUrl}/services/bot/list-user-bots?excludeApi=${encodeURIComponent(
        excludeApi
      )}`;

      return fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (!_.isNil(responseEvent)) {
            _.defaultTo(okCallback, _.noop)(responseEvent);
          } else {
            throw new Error('Empty response loading Bots');
          }

          return responseEvent;
        })
        .catch(error => {
          _.defaultTo(failCallback, _.noop)(ResponseStatusCode.ERROR, error);

          return Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: 'Error loading bots',
            details: error,
          });
        });
    },

    // Retrieves the set of bots that belong to the merchant the current user is associated with
    getCurrentUserStandardBots() {
      const url = `${confEndpointUrl}/services/bot/list-user-standard-bots`;

      return fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (_.isNil(responseEvent)) {
            throw new Error('Empty response loading Bots');
          }

          return responseEvent;
        })
        .catch(error =>
          Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: 'Error loading bots',
            details: error,
          })
        );
    },

    // Generates a new Livechat account
    generateLivechatAccount(botId, okCallback, failCallback) {
      const createLivechatAccountUrl = `${confEndpointUrl}/services/bot/auto-generate-livechat`;

      fetch(createLivechatAccountUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: String(botId),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(resp => {
          if (
            resp != null &&
            resp.hasOwnProperty('botMsgAccount') &&
            resp.hasOwnProperty('widgetJSCode')
          ) {
            okCallback(resp);
          } else {
            throw new Error(
              `Got invalid response from create-default-live service ${resp}.`
            );
          }
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Generates a new Livechat account
    getLivechatAccount(accountId, okCallback, failCallback) {
      const getLivechatAccountUrl = `${confEndpointUrl}/services/bot/get-livechat-account/${String(
        accountId
      )}`;

      fetch(getLivechatAccountUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(resp => {
          if (
            resp != null &&
            resp.hasOwnProperty('botMsgAccount') &&
            resp.hasOwnProperty('widgetJSCode')
          ) {
            okCallback(resp);
          } else {
            throw new Error(
              `Got invalid response from create-default-live service ${resp}.`
            );
          }
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Generates a new Livechat account
    emailLivechatScript(botMsgAccount, email, okCallback, failCallback) {
      const emailLivechatScriptUrl = `${confEndpointUrl}/services/bot/${String(
        botMsgAccount.botId
      )}/email-livechat-script/`;

      const leanBotAccount = {
        id: botMsgAccount.id,
        botId: botMsgAccount.botId,
        username: botMsgAccount.username,
        accessToken: botMsgAccount.accessToken,
      };

      fetch(emailLivechatScriptUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          destEmail: String(email),
          botAccount: leanBotAccount,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(resp => {
          okCallback(resp);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Stores the configuration of a Livechat account, given it's ID
    configureLivechatAccount(
      botMsgAccount,
      oLivechatAccountConfig,
      okCallback,
      failCallback
    ) {
      const configLivechatScriptUrl = `${confEndpointUrl}/services/bot/${String(
        oLivechatAccountConfig.botId
      )}/configure-livechat/`;

      const data = new FormData();
      data.append('_csrf', csrfToken);

      // Add all properties of the messaging account being configured
      if (botMsgAccount) {
        if (botMsgAccount.id) {
          data.append('botAccountId', botMsgAccount.id);
        }

        if (botMsgAccount.username) {
          data.append('username', botMsgAccount.username);
        }

        if (botMsgAccount.accessToken) {
          data.append('accessToken', botMsgAccount.accessToken);
        }

        if (botMsgAccount.provider) {
          data.append('provider', botMsgAccount.provider);
        }
      }

      // Add all properties provided in the configuration object
      if (oLivechatAccountConfig) {
        Object.getOwnPropertyNames(oLivechatAccountConfig).forEach(propName => {
          if (oLivechatAccountConfig[propName] !== null) {
            data.append(propName, oLivechatAccountConfig[propName]);
          }
        });
      }

      fetch(configLivechatScriptUrl, {
        method: 'POST',
        credentials: 'include',
        body: data,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(resp => {
          okCallback(resp);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Submits an account setup request to the backend services
    submitSpecialAccountSetupRequest(
      specAcctRequest,
      okCallback,
      failCallback
    ) {
      const submitSpecAcctReqUrl = `${confEndpointUrl}/services/bot/request-special-account`;

      fetch(submitSpecAcctReqUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(specAcctRequest),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(resp => {
          okCallback(resp);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Loads all bot messaging accounts associated with the current merchant, filtered by the specified provider (if no
    // provider is specified, retrieves all bot messaging accounts)
    getBotAccountsByProvider(provider) {
      let listBotAccountsUrl = `${confEndpointUrl}/services/bot/list-user-bot-accounts`;

      // Set provider filter as URL parameter, if specified
      if (!StringUtils.isBlank(provider)) {
        listBotAccountsUrl = `${listBotAccountsUrl}?provider=${provider}`;
      }

      return fetch(listBotAccountsUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json());
    },

    // Creates a new default bot for the current user's merchant, optionally setting a bot
    createDefaultBot(jsonMsgAccount = null, okCallback, failCallback) {
      const createDefaultBotUrl = `${confEndpointUrl}/services/bot/create-default-bot`;

      fetch(createDefaultBotUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: jsonMsgAccount,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          okCallback(response);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Creates a new default bot for the current user's merchant and sets
    // the specified messaging account as its first account
    createDefaultBotWithMsgAccount(
      provider,
      accountId,
      token,
      alias,
      okCallback,
      failCallback
    ) {
      const jsonMsgAccount = buildBackendMessagingAccount(
        null,
        provider,
        accountId,
        token,
        alias
      );

      this.createDefaultBot(jsonMsgAccount, okCallback, failCallback);
    },

    // Updates a messaging account of a bot given its ID
    updateBotMessagingAccount(
      accountId,
      botId,
      provider,
      username,
      token,
      alias,
      okCallback,
      failCallback
    ) {
      const updateBotAccountUrl = `${confEndpointUrl}/services/bot/${botId}/msg-account`;

      const jsonMsgAccount = buildBackendMessagingAccount(
        accountId,
        provider,
        username,
        token,
        alias
      );

      fetch(updateBotAccountUrl, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: jsonMsgAccount,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          okCallback(response);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Requests the Facebook API to exchange a Short-Lived user access token for a Long-Lived one
    requestFbLongLivedAccessToken(
      shortLivedUserToken,
      okCallback,
      failCallback
    ) {
      const reqFbLLAccessTokenUrl = `${confEndpointUrl}/services/utility/request-fb-ll-access-token`;

      fetch(reqFbLLAccessTokenUrl, {
        method: 'POST',
        credentials: 'include',
        body: shortLivedUserToken,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(longLivedUserToken => {
          okCallback(longLivedUserToken);
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    // Registers a new customer, which results in a new merchant and an associated admin user being created
    registerNewCustomer(
      fullName,
      merchantName,
      email,
      phone,
      username,
      password,
      passwordConfirm,
      country,
      city,
      acceptsTerms,
      acceptsDataPrivacyPolicy,
      reCaptchaValue,
      merchantDetails,
      okCallback,
      failCallback
    ) {
      const regMerchantUrl = `${confEndpointUrl}/services/merchant/register`;

      // Determine the location of the client
      this.getLocation(location => {
        // Attempt to determine location and language settings automatically (on a best-effort basis)
        const fromCountryCode =
          location && location.country_code ? location.country_code : null;
        const timeZone =
          location && location.time_zone ? location.time_zone : null;
        const language = window.clientSelectedLanguage
          ? window.clientSelectedLanguage
          : null;

        // Once the location has been resolved, complete the registration
        const jsonRegistrationReq = buildBackendCustomerRegistration(
          fullName,
          merchantName,
          email,
          phone,
          username,
          password,
          passwordConfirm,
          country,
          city,
          fromCountryCode,
          timeZone,
          language,
          acceptsTerms,
          acceptsDataPrivacyPolicy,
          reCaptchaValue,
          merchantDetails
        );

        // Submit registration request
        fetch(regMerchantUrl, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: jsonRegistrationReq,
        })
          .then(checkHttpFetchStatus)
          .then(httpResponse => httpResponse.json())
          .then(response => {
            okCallback(response);
          })
          .catch(error => {
            failWithErrorReport(error, failCallback);
          });
      });
    },

    // Determines the location of the current client, based on its IP address. The callback is invoked on success and
    // on failure, passing a location object in the former case or null in the latter case
    getLocation(callback) {
      // TODO: Add freegeoip URL to properties file
      fetch('https://freegeoip.net/json/', {
        method: 'GET',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(location => {
          callback(location);
        })
        .catch(error => {
          callback(null);
        });
    },

    getKeenioKey(okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/merchant/get-keenio-key`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.status === ResponseStatusCode.OK) {
            okCallback(responseEvent.response);
          }
        })
        .catch(error => {
          console.log(error);
          failCallback(error);
        });
    },

    requestPasswordChange(usernameOrEmail, okCallback, failCallback) {
      fetch(
        `${confEndpointUrl}/request-password-change?usernameOrEmail=${encodeURIComponent(
          usernameOrEmail
        )}`,
        {
          method: 'GET',
          headers: { 'Content-type': 'application/json' },
          credentials: 'include',
        }
      )
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.status === ResponseStatusCode.OK) {
            okCallback(responseEvent.response);
          } else {
            failCallback();
          }
        })
        .catch(error => {
          console.log(error);
          failCallback(error);
        });
    },

    validateToken(token, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/validate-token/${token}`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.status === ResponseStatusCode.OK) {
            okCallback(responseEvent.response);
          } else {
            failCallback();
          }
        })
        .catch(error => {
          console.log(error);
          failCallback(error);
        });
    },

    changePassword(
      username,
      password,
      matchPassword,
      resetToken,
      okCallback,
      failCallback
    ) {
      fetch(`${confEndpointUrl}/change-password`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          username: username.trim(),
          password: password.trim(),
          matchPassword: matchPassword.trim(),
          resetToken: resetToken.trim(),
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          console.log(responseEvent);
          if (responseEvent.status === ResponseStatusCode.OK) {
            okCallback(responseEvent);
          } else {
            okCallback(responseEvent);
          }
        })
        .catch(error => {
          console.log(error);
          failCallback(error);
        });
    },

    // Loads all provinces of the specified country, as defined in the data repository
    getCountryProvinces(countryIsoCode, okCallback, failCallback) {
      const getCountryProvicesUrl = `${confEndpointUrl}/services/utility/province/${
        countryIsoCode || ''
      }`;

      fetch(getCountryProvicesUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(arrProvinces => {
          okCallback(arrProvinces);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    loginAuthentication(
      userName,
      password,
      rememberMe,
      okCallback,
      failCallback
    ) {
      const credentials = `username=${userName}&password=${password}&remember-me=${rememberMe}`;
      const apiUrl = `${confEndpointUrl}/login`;

      fetch(apiUrl, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: credentials,
      })
        .then(responseEvent => {
          if (responseEvent.status === 200) {
            this.getCurrentUser(okCallback, failCallback);
          } else {
            responseEvent
              .json()
              .then(errorInfo => {
                failCallback({
                  code: 'REJECTED',
                  status: responseEvent.status,
                  payload: errorInfo,
                });
              })
              .catch(errorReason => {
                failCallback({
                  code: 'FAILED',
                  status: responseEvent.status,
                  payload: responseEvent,
                });
              });
          }
        })
        .catch(error => {
          failCallback({ code: 'FAILED', status: null, payload: error });
        });
    },
    getCurrentUser(okCallback, failCallback) {
      const getCurrentUser = `${confEndpointUrl}/services/user/current`;

      fetch(getCurrentUser, {
        credentials: 'include',
      })
        .then(httpResponse => httpResponse.json())
        .then(responseBody => {
          okCallback(responseBody);
        })
        .catch(error => {
          console.log(error);
          failCallback({
            code: 'GET_USER_FAILED',
            status: null,
            payload: error,
          });
        });
    },
    logOut(okCallback, failCallback) {
      const logoutUrl = `${confEndpointUrl}/logout`;
      fetch(logoutUrl, {
        method: 'POST',
        credentials: 'include',
      })
        .then(httpResponse => {
          console.log(httpResponse, 'httpResponse');
          if (httpResponse.status === 200) {
            okCallback(httpResponse);
          } else {
            failCallback(httpResponse);
          }
        })
        .catch(e => {
          console.log(error);
          let error = { response: { status: 404 } };
          failCallback(error);
        });
    },
    // TODO: Remove. Replace with multi-purpose method to load StandardBots
    // to get the list of satisfaction bots
    satisfactionBotList(okCallback, failCallback) {
      let reponseVar = null;
      return fetch(`${confEndpointUrl}/services/bot/list-user-standard-bots`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
        .then(httpResponse => {
          reponseVar = httpResponse;
          return httpResponse.json();
        })
        .then(responseEvent => {
          if (reponseVar.ok) {
            return okCallback(responseEvent);
          }
          failCallback(responseEvent);
        })
        .catch(e => {
          let error = e.toString();
          error =
            (error.includes('Forbidden') || error.includes('error')) &
            { response: { status: 403 } };
          failCallback(e);
        });
    },
    // to get the bot specific configuration
    botSpecificConfigurationApi(botId, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/satisfaction/${botId}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            failCallback(responseEvent);
          }
        })
        .catch(e => {
          let error = e.toString();
          error = error.includes('Forbidden')
            ? { response: { status: 403 } }
            : { response: { status: 404 } };
          failCallback(error);
        });
    },
    // to get the list of message account
    //module could be: csm (customer satisfaction module), faqs (Frequent Asked Questions)
    // if module is not passed, csm is assumed

    messageAccountList(botId, okCallback, failCallback, module) {
      if (!module) {
        module = 'csm';
      }
      let reponseVar = null;
      fetch(
        `${confEndpointUrl}/services/merchant/${module}/accounts/${botId}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        }
      )
        .then(httpResponse => {
          reponseVar = httpResponse;
          let httpResponseVar = null;
          if (httpResponse.status !== 404) {
            httpResponseVar = httpResponse.json();
          } else {
            httpResponseVar = [];
          }
          return httpResponseVar;
        })
        .then(responseEvent => {
          if (reponseVar.ok) {
            okCallback(responseEvent);
          } else {
            failCallback(responseEvent);
          }
        })
        .catch(e => {
          // let error = e.toString();
          // error = (error.includes("Forbidden")) ? { 'response': { 'status': 403 } } : { 'response': { 'status': 404 } }
          failCallback(e);
        });
    },
    // to get configuration default messages
    getDefaultMesagesApi(okCallback, failCallback) {
      fetch(
        `${confEndpointUrl}/services/merchant/defaultMessagesForMerchant/CSM`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        }
      )
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            failCallback(responseEvent);
          }
        })
        .catch(e => {
          let error = e.toString();
          error = error.includes('Forbidden')
            ? { response: { status: 403 } }
            : { response: { status: 404 } };
          failCallback(error);
        });
    },
    getAgentList(merchantId, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/agent/search`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            failCallback(responseEvent);
          }
        })
        .catch(e => {
          let error = e.toString();
          error = error.includes('Forbidden')
            ? { response: { status: 403 } }
            : { response: { status: 404 } };
          failCallback(error);
        });
    },
    saveBotConfigurationApi(botId, saveBotJson, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/satisfaction/${botId}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(saveBotJson),
      })
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.status === 200) {
            okCallback(responseEvent);
          } else {
            failCallback(responseEvent);
          }
        })
        .catch(e => {
          let error = e.toString();
          error = error.includes('Forbidden')
            ? { response: { status: 403 } }
            : { response: { status: 404 } };
          failCallback(error);
        });
    },
    exportChartDetail(botId, chartDetail, okCallback, failCallback) {
      fetch(
        `https://private-anon-cf7a554e7b-satisfactionmoduleapi.apiary-mock.com/services/satisfaction/${botId}/export`,
        {
          method: 'POST',
          // credentials: 'include',
          body: JSON.stringify(chartDetail),
        }
      )
        .then(httpResponse => {
          console.log(httpResponse, 'httpResponse');
          if (httpResponse.status == 200) {
            okCallback(httpResponse);
          } else {
            failCallback(httpResponse);
          }
        })
        .catch(e => {
          let error = e.toString();
          error = error.includes('Forbidden')
            ? { response: { status: 403 } }
            : { response: { status: 404 } };
          failCallback(error);
        });
    },
    generalSetting(botIt, okCallback, failCallback) {
      let reponseVar = null;
      const apiUrl = `${confEndpointUrl}/services/contact/attributes/${botIt}`;
      return fetch(apiUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        credentials: 'include',
      })
        .then(response => {
          reponseVar = response;
          return response.json();
        })
        .then(responseEvent => {
          if (reponseVar.ok) {
            return okCallback(responseEvent);
          }
          failCallback(responseEvent);
        })
        .catch(error => {
          failCallback(error);
        });
    },
    updateFiledStatus(botId, customField, okCallback, failCallback) {
      let reponseVar = null;
      const apiUrl = `${confEndpointUrl}/services/contact/attributes/${botId}`;
      fetch(apiUrl, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(customField),
      })
        .then(response => {
          reponseVar = response;
          return response.json();
        })
        .then(responseEvent => {
          if (reponseVar.ok) {
            okCallback(responseEvent);
          } else {
            failCallback(responseEvent);
          }
        })
        .catch(error => {
          failCallback(error);
        });
    },
    // Loads all cities defined in the backend data repository
    getAllCities() {
      // TODO: Load cities in a more appropriate way
      return [];
    },

    auditExport(filters, success, fail) {
      const endpoint = `${confEndpointUrl}/services/auditexport`;
      fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(filters),
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    auditChats(filters, success, fail) {
      fetch(`${confEndpointUrl}/services/audit/chats`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(filters),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    auditChatMessages(chatId, success, fail) {
      fetch(`${confEndpointUrl}/services/audit/chat/${chatId}/messages`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    getAuditFilterParams() {
      const getAuditFilterParams = `${confEndpointUrl}/services/audit/filterparams`;

      return fetch(getAuditFilterParams, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that the user does not yet have a DepartmentTree
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .catch(error =>
          Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: 'Error loading AuditFilterParams',
            details: error,
          })
        );
    },

    getAuditDaysLimit(merchantId, success, fail) {
      fetch(
        `${confEndpointUrl}/services/audit/daysLimitByMerchant/${merchantId}`,
        {
          method: 'GET',
          headers: { 'Content-type': 'application/json' },
          credentials: 'include',
        }
      )
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(response => {
          success(response === '' ? null : parseInt(response));
        })
        .catch(e => {
          fail?.(e);
        });
    },

    requestAgentChatTransfer(request, success, fail) {
      fetch(`${confEndpointUrl}/agent/chat/chat-xfer/request`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          chatId: request.chatId,
          sourceAgent: request.sourceAgent.id,
          targetAgent: request.targetAgent.id,
          targetBizProcessId: _.get(request, 'targetBizProcess.id'),
          targetBizProcessCode: _.get(request, 'targetBizProcess.code'),
          targetBizProcessName: _.get(request, 'targetBizProcess.name'),
          comments: request.comments,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    pickupAgentChatTransfer(request, success, fail) {
      fetch(`${confEndpointUrl}/agent/chat/chat-xfer/pickup`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: request.chatId,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    cancelAgentChatTransfer(request, success, fail) {
      fetch(`${confEndpointUrl}/agent/chat/chat-xfer/cancel`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: request.chatId,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    rejectAgentChatTransfer(request, success, fail) {
      fetch(`${confEndpointUrl}/agent/chat/chat-xfer/reject`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          chatId: request.chatId,
          reason: request.rejectReason,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    acceptAgentChatTransfer(request, success, fail) {
      fetch(`${confEndpointUrl}/agent/chat/chat-xfer/accept`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
        body: request.chatId,
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          success(response);
        })
        .catch(e => {
          fail(e);
        });
    },

    canStartChatWithContacts(merchantId, onSuccess, onFail) {
      const endpoint = `${confEndpointUrl}/services/merchant/${merchantId}/canStartChatWithContacts`;
      fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        body: null,
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          onSuccess(response);
        })
        .catch(e => {
          console.log(e.toString());
        });
    },

    getUserAdditionPrice(onSuccess, onFail) {
      const endpoint = `${confEndpointUrl}/services/user/getUserAdditionPrice`;
      fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        body: null,
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          onSuccess(response);
        })
        .catch(e => {
          console.log(e.toString());
        });
    },

    getZohoAnalyticsEmbedUrl(workspace, criteria, object, onSuccess) {
      const endpoint =
        confEndpointUrl +
        '/services/zohoanalytics/embedurl?workspace={workspace}&criteria={criteria}&object={object}'
          .replace('{workspace}', workspace)
          .replace('{criteria}', criteria)
          .replace('{object}', object);

      fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        body: null,
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(response => {
          onSuccess(response);
        })
        .catch(e => {
          console.error(e.toString());
        });
    },

    getZohoSSOSignInTicket(zohoUser, onSuccess) {
      const endpoint = `${confEndpointUrl}/services/zohoanalytics/ssosignin?zohoUser=${zohoUser}`;
      fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        body: null,
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.text())
        .then(response => {
          onSuccess(response);
        })
        .catch(e => {
          console.error(e.toString());
        });
    },

    refreshBotApiCredentials(merchantId, botApiBrokerId, onSuccess) {
      botApiBrokerId = !botApiBrokerId ? 0 : botApiBrokerId;
      const endpoint = `${confEndpointUrl}/services/merchant/${merchantId}/botapibroker/${botApiBrokerId}/refresh`;
      fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        body: null,
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          onSuccess(response);
        })
        .catch(e => {
          console.error(e.toString());
        });
    },

    // Submits a request to search for the bot-accounts of the currently authenticated merchant, that match the
    // filter criteria. Returns a promise that resolves with the response, or is rejected with an error
    searchBotAccounts(searchParams) {
      const endpoint = `${confEndpointUrl}/services/merchant/search-bot-accounts`;

      const jsonResponsePromise = fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(searchParams),
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json());

      return jsonResponsePromise;
    },

    // Retrieves a bot-account given its ID
    getBotAccount(accountId, okCallback, failCallback) {
      const getBotAccountUrl = `${confEndpointUrl}/services/bot/get-account/${String(
        accountId
      )}`;

      fetch(getBotAccountUrl, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(resp => {
          if (resp != null) {
            okCallback(resp);
          } else {
            throw new Error(
              `Got invalid response from bot/get-account service ${resp}.`
            );
          }
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Retrieves a bot-account provided by Smooch and its configuration, given its ID. Returns a promise, which will
    // get resolved with an object containing the response, or in case of error, will be rejected with three arguments:
    // ResponseStatusCode, error-message, error-details
    getSmoochAccountConfig(accountId, okCallback, failCallback) {
      const getBotAccountUrl = `${confEndpointUrl}/services/bot/smooch-account-profile/${encodeURIComponent(
        accountId
      )}`;

      return new Promise((resolve, reject) => {
        fetch(getBotAccountUrl, {
          method: 'GET',
          credentials: 'include',
        })
          .then(checkHttpFetchStatus)
          .then(httpResponse => httpResponse.json())
          .then(resp => {
            if (resp != null) {
              resolve(resp);
            } else {
              reject({
                status: ResponseStatusCode.ERROR,
                errorMessage: `Got invalid response from bot/get-account service ${resp}.`,
                errorDetails: null,
              });
            }
          })
          .catch(error => {
            failWithErrorReport(error, (status, errorMessage, errorDetails) => {
              reject({
                status,
                errorMessage,
                errorDetails,
              });
            });
          });
      });
    },

    // Stores the configuration of a Smooch account, given it's ID
    configureSmoochAccount(botAccountId, integrationProfile) {
      const configSmoochAccountUrl = `${confEndpointUrl}/services/bot/smooch-account-profile/${encodeURIComponent(
        botAccountId
      )}`;

      const data = new FormData();
      data.append('_csrf', csrfToken);

      // Add all properties provided in the integrationProfile object
      if (integrationProfile) {
        Object.getOwnPropertyNames(integrationProfile).forEach(propName => {
          if (integrationProfile[propName] !== null) {
            data.append(propName, integrationProfile[propName]);
          }
        });
      }

      return new Promise((resolve, reject) => {
        fetch(configSmoochAccountUrl, {
          method: 'POST',
          credentials: 'include',
          body: data,
        })
          .then(checkHttpFetchStatus)
          .then(httpResponse => httpResponse.text())
          .then(resp => {
            resolve(resp);
          })
          .catch(error => {
            failWithErrorReport(error, (status, errorMessage, errorDetails) => {
              reject({
                status,
                errorMessage,
                errorDetails,
              });
            });
          });
      });
    },

    // Changes the state (a.k.a status) of the bot-account having the specified ID
    switchBotAccountState(botId, botAccountId, newState) {
      const switchAccountStateUrl = `${confEndpointUrl}/services/bot/${encodeURIComponent(
        botId
      )}/msg-account/${encodeURIComponent(
        botAccountId
      )}?state=${encodeURIComponent(newState)}`;

      return new Promise((resolve, reject) => {
        fetch(switchAccountStateUrl, {
          method: 'PUT',
          credentials: 'include',
        })
          .then(checkHttpFetchStatus)
          .then(httpResponse => httpResponse.json())
          .then(resp => {
            if (resp != null) {
              resolve(resp);
            } else {
              reject({
                status: ResponseStatusCode.ERROR,
                errorMessage: `Got invalid response from switch-state-of-msg-account service ${resp}.`,
                errorDetails: null,
              });
            }
          })
          .catch(error => {
            failWithErrorReport(error, (status, errorMessage, errorDetails) => {
              reject({
                status,
                errorMessage,
                errorDetails,
              });
            });
          });
      });
    },

    // Fetches a lean list containing all Users of the current User's Merchant
    leanListUsersOfCurrentMerchant() {
      const getUsersList = `${confEndpointUrl}/services/user/list`;

      return fetch(getUsersList, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that the user does not yet have a DepartmentTree
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .catch(error =>
          Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: 'Error loading Users',
            details: error,
          })
        );
    },

    // Fetches the plan of the current user's merchant and how much of its characteristics remain available
    getCurrentMerchantPlanUsage() {
      const getMerchantPlanUsageUrl = `${confEndpointUrl}/services/merchant/merchant-plan-usage`;

      return new Promise((resolve, reject) => {
        fetch(getMerchantPlanUsageUrl, {
          method: 'GET',
          credentials: 'include',
        })
          .then(checkHttpFetchStatus)
          .then(httpResponse => httpResponse.json())
          .then(mercPlanUsage => {
            if (mercPlanUsage != null) {
              resolve(mercPlanUsage);
            } else {
              reject({
                status: ResponseStatusCode.ERROR,
                errorMessage: `Got invalid response from merchant-plan-usage service.`,
                errorDetails: null,
              });
            }
          })
          .catch(error => {
            failWithErrorReport(error, (status, errorMessage, errorDetails) => {
              reject({
                status,
                errorMessage,
                errorDetails,
              });
            });
          });
      });
    },

    // Retrieves the DepartmentTree of the current User. Returns a promise that gets resolved to the result
    // Resolve: argument is the DepartmentTree of the user.
    // Reject: argument is the error, with format {code:<str>, message:<str>, detail:<obj>}
    getDepartmentTreeOfUser() {
      return fetch(`${confEndpointUrl}/services/departments`, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that the user does not yet have a DepartmentTree
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .then(responseEvent => responseEvent)
        .catch(error =>
          Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: 'Error loading DepartmentTree',
            details: error,
          })
        );
    },

    // Loads settings of the FAQs StandardService for a specific Bot. Returns a promise that resolves with the result
    getFaqSettingsByBot(botId) {
      const targetServiceUrl = `/services/bot/${encodeURIComponent(
        botId
      )}/faqs/settingswithmessages`;

      return fetch(confEndpointUrl + targetServiceUrl, {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that there are no FAQ settings yet for the Bot
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .then(responseEvent => responseEvent)
        .catch(error =>
          Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: `Error loading FAQ Settings. Bot: ${botId}`,
            details: error,
          })
        );
    },

    switchFaqSettingsOfBot(botId, enabled) {
      const targetServiceUrl = `/services/bot/${encodeURIComponent(
        botId
      )}/faqs/state?enabled=${encodeURIComponent(enabled)}`;

      return fetch(confEndpointUrl + targetServiceUrl, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that there are no FAQ settings yet for the Bot
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .then(responseEvent => responseEvent)
        .catch(error =>
          Promise.reject({
            code: ResponseStatusCode.ERROR,
            message: `Error switching FAQ Settings state to: ${enabled}. Bot: ${botId}`,
            details: error,
          })
        );
    },

    // Updates settings of the FAQ service for a specific Bot
    // Resolve: argument is a wrapper contaning all the settings updated.
    // Reject: argument is the error, with format {code:<str>, message:<str>, details:<obj>}
    updateFaqServiceSettings(botId, faqSettings = null) {
      const targetServiceUrl = `/services/bot/${botId}/faqs/settings`;

      return fetch(confEndpointUrl + targetServiceUrl, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(faqSettings),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that the DepartmentNode failed to be created
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .then(response => response)
        .catch(
          error =>
            // On error, return a Promise that gets rejected as soon as the error is read and deserialized
            new Promise((resolve, reject) => {
              failWithErrorReport(error, (errCode, errMsg, errDetails) => {
                reject({ code: errCode, message: errMsg, details: errDetails });
              });
            })
        );
    },

    // Creates or updates a new DepartmentTree. Returns a promise with the result.
    // Resolve: argument is the new DepartmentTree.
    // Reject: argument is the error, with format {code:<str>, message:<str>, details:<obj>}
    saveDepartmentTree(departmentTree) {
      let uri = null;
      let method = null;

      if (_.isNil(departmentTree.id)) {
        uri = `${confEndpointUrl}/services/departments`;
        method = 'POST';
      } else {
        uri = `${confEndpointUrl}/services/departments/${departmentTree.id}`;
        method = 'PUT';
      }

      // Submit registration request
      return fetch(uri, {
        method,
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(departmentTree),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that the DepartmentTree failed to be created
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .then(response => response)
        .catch(
          error =>
            // On error, return a Promise that gets rejected as soon as the error is read and deserialized
            new Promise((resolve, reject) => {
              failWithErrorReport(error, (errCode, errMsg, errDetails) => {
                reject({ code: errCode, message: errMsg, details: errDetails });
              });
            })
        );
    },

    // Updates the set of Children of a DepartmentNode. Returns a promise with the result.
    // Resolve: void
    // Reject: argument is the error, with format {code:<str>, message:<str>, details:<obj>}
    setChildrenOfDepartmentNode(
      departmentTreeId,
      parentNodeId,
      lstChildNodeIds
    ) {
      const uri = `${confEndpointUrl}/services/departments/${departmentTreeId}/nodes/${parentNodeId}/children`;

      return fetch(uri, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(lstChildNodeIds),
      })
        .then(checkHttpFetchStatus)
        .catch(
          error =>
            // On error, return a Promise that gets rejected as soon as the error is read and deserialized
            new Promise((resolve, reject) => {
              failWithErrorReport(error, (errCode, errMsg, errDetails) => {
                reject({ code: errCode, message: errMsg, details: errDetails });
              });
            })
        );
    },

    // Creates a new DepartmentNode or updates it if already exists. Returns a promise with the result.
    // Resolve: argument is the DepartmentNode updated.
    // Reject: argument is the error, with format {code:<str>, message:<str>, details:<obj>}
    saveDepartmentNode(departmentTreeId, departmentNode) {
      let uri = null;
      let method = null;

      if (_.isNil(departmentNode.id)) {
        // Create a new DepartmentNode
        uri = `${confEndpointUrl}/services/departments/${departmentTreeId}/nodes`;
        method = 'POST';
      } else {
        // Update an existing DepartmentNode
        uri = `${confEndpointUrl}/services/departments/${departmentTreeId}/nodes/${departmentNode.id}`;
        method = 'PUT';
      }

      return fetch(uri, {
        method,
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(departmentNode),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => {
          // Check if the response is empty, thus showing that the DepartmentNode failed to be created
          if (
            !_.startsWith(
              getResponseHeader(httpResponse, 'content-type'),
              'application/json'
            )
          ) {
            return null;
          }

          return httpResponse.json();
        })
        .then(response => response)
        .catch(
          error =>
            // On error, return a Promise that gets rejected as soon as the error is read and deserialized
            new Promise((resolve, reject) => {
              failWithErrorReport(error, (errCode, errMsg, errDetails) => {
                reject({ code: errCode, message: errMsg, details: errDetails });
              });
            })
        );
    },

    // Reloads information about the user currently logged in. Returns a promise with the result.
    // Resolve: argument is an object containing the the user currently logged in.
    // Reject: argument is the error, with format {code:<str>, message:<str>, details:<obj>}
    reloadCurrentUser: () => {
      const uri = `${confEndpointUrl}/services/user/reloadCurrent`;

      return fetch(uri, {
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .catch(
          error =>
            // On error, return a Promise that gets rejected as soon as the error is read and deserialized
            new Promise((resolve, reject) => {
              failWithErrorReport(error, (errCode, errMsg, errDetails) => {
                reject({ code: errCode, message: errMsg, details: errDetails });
              });
            })
        );
    },

    // Loads an open chat given its ID. Returns a promise with the result.
    // Resolve: argument is an array containing the chats of the specified tray
    // Reject: argument is the error, with format {code:<str>, message:<str>, details:<obj>}
    loadChatById: (userId, id) => {
      const uri = `${confEndpointUrl}/services/agent/${userId}/chat/${encodeURIComponent(
        id
      )}`;

      return fetch(uri, {
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .catch(
          error =>
            // On error, return a Promise that gets rejected as soon as the error is read and deserialized
            new Promise((resolve, reject) => {
              failWithErrorReport(error, (errCode, errMsg, errDetails) => {
                reject({ code: errCode, message: errMsg, details: errDetails });
              });
            })
        );
    },

    getMerchantSubscription: () => {
      const subscriptionByMerchant = `${confEndpointUrl}/services/subscription/subscriptionByMerchant`;

      return fetch(subscriptionByMerchant, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json());
    },

    getCarouselImages: () =>
      fetch('https://s3.amazonaws.com/static.b2chat/prd/carousel-login.json', {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json()),
  };
})(env.WEB_SERVICE_BASE_URI);

export default BackendProxy;
