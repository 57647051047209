import BackendProxy from '../logic/backendproxy';

export const START_CHECKING_DEFAULT_BOT =
  'LC_ACCOUNT_SETUP.START_CHECKING_DEFAULT_BOT';
export const START_CREATING_ACCOUNT = 'LC_ACCOUNT_SETUP.START_CREATING_ACCOUNT';
export const START_LOADING_ACCOUNT_TO_EDIT =
  'LC_ACCOUNT_SETUP.START_LOADING_ACCOUNT_TO_EDIT';
export const SET_CONFIGURE_ACCOUNT = 'LC_ACCOUNT_SETUP.SET_CONFIGURE_ACCOUNT';
export const START_APPLYING_ACCOUNT_CONFIGURATION =
  'LC_ACCOUNT_SETUP.START_APPLYING_ACCOUNT_CONFIGURATION';
export const SET_CONFIGURE_ACCOUNT_OK =
  'LC_ACCOUNT_SETUP.SET_CONFIGURE_ACCOUNT_OK';
export const SET_CONFIGURE_ACCOUNT_FAIL =
  'LC_ACCOUNT_SETUP.SET_CONFIGURE_ACCOUNT_FAIL';

// List of states (i.e. steps) involved in the setup process of a B2ChatLive account
export const enumSetupProcessStates = {
  SELECT_ACCOUNT_TO_CONFIGURE: 'SELECT_ACCOUNT_TO_CONFIGURE',
  CHECKING_DEFAULT_BOT: 'CHECKING_DEFAULT_BOT',
  CREATING_ACCOUNT: 'CREATING_ACCOUNT',
  LOADING_ACCOUNT: 'LOADING_ACCOUNT',
  CONFIGURE_ACCOUNT: 'CONFIGURE_ACCOUNT',
  APPLYING_ACCOUNT_CONFIGURATION: 'APPLYING_ACCOUNT_CONFIGURATION',
  ACCOUNT_CONFIGURATION_FAILED: 'ACCOUNT_CONFIGURATION_FAILED',
  ACCOUNT_READY: 'ACCOUNT_READY',
};

export function startCheckingDefaultBot() {
  return {
    type: START_CHECKING_DEFAULT_BOT,
  };
}

export function startCreatingAccount(botId) {
  return {
    type: START_CREATING_ACCOUNT,
    linkedBotId: botId,
  };
}

export function startLoadAccountToEdit(accountId) {
  return {
    type: START_LOADING_ACCOUNT_TO_EDIT,
    accountId,
  };
}

export function setConfigureAccount(msgAccount, livechatConfig, widgetScript) {
  return {
    type: SET_CONFIGURE_ACCOUNT,
    msgAccount,
    livechatConfig,
    widgetScript,
  };
}

export function startApplyingAccountConfiguration() {
  return {
    type: START_APPLYING_ACCOUNT_CONFIGURATION,
  };
}

export function setConfigureAccountOk() {
  return {
    type: SET_CONFIGURE_ACCOUNT_OK,
  };
}

export function setConfigureAccountFail(errDetails) {
  return {
    type: SET_CONFIGURE_ACCOUNT_FAIL,
    errDetails,
  };
}

// Thunk action. Sends a create Livechat account request to be associated with the current merchant's default bot.
// Creates a default bot too, if the current merchant doesn't have one already
export function createNewAccount() {
  return (dispatch, getState) => {
    // Inform that the Livechat account setup process is being initialized
    dispatch(startCheckingDefaultBot());

    // Firstly, create a default bot for the current user's merchant, if it still has none
    BackendProxy.createDefaultBot(
      null,
      botId => {
        // Inform that the account creation process is now started
        dispatch(startCreatingAccount(botId));

        // Then create a default Livechat account and associate it with the default bot
        BackendProxy.generateLivechatAccount(
          botId,
          resp => {
            const newLivechatAccount = resp.botMsgAccount;
            const { livechatConfig } = resp;
            const widgetScript = resp.widgetJSCode;

            dispatch(
              setConfigureAccount(
                newLivechatAccount,
                livechatConfig,
                widgetScript
              )
            );
          },
          (error, details) => {
            console.log('Error attempting to create default bot: ', details);
            throw new Error(`Error attempting to create default bot: ${error}`);
          }
        );
      },
      (error, details) => {
        console.log('Error attempting to create default bot: ', details);
        throw new Error(`Error attempting to create default bot: ${error}`);
      }
    );
  };
}

// Thunk action. Retrieves a messaging account given its ID
export function loadAccountToEdit(accountId) {
  return (dispatch, getState) => {
    // Inform that the requested Livechat account is being loaded from the backend
    dispatch(startLoadAccountToEdit());

    // Request the Livechat account to be loaded by ID
    BackendProxy.getLivechatAccount(
      accountId,
      resp => {
        const livechatAccount = resp.botMsgAccount;
        const { livechatConfig } = resp;
        const widgetScript = resp.widgetJSCode;

        dispatch(
          setConfigureAccount(livechatAccount, livechatConfig, widgetScript)
        );
      },
      (error, details) => {
        console.log('Error attempting to create default bot: ', details);
        throw new Error(`Error attempting to create default bot: ${error}`);
      }
    );
  };
}

// Thunk action. Configures the Livechat account currently being setup by invoking the pertinent backend service
export function configureLivechatAccount(
  livechatMsgAccount,
  oLivechatAccountConfig
) {
  return (dispatch, getState) => {
    // Inform that the Livechat account is being configured in the backend
    dispatch(startApplyingAccountConfiguration());

    // Ask the backend to apply the configuration to the specified messaging account
    BackendProxy.configureLivechatAccount(
      livechatMsgAccount,
      oLivechatAccountConfig,
      res => {
        dispatch(setConfigureAccountOk());
      },
      (errCode, errMsg, errDetails) => {
        dispatch(setConfigureAccountFail(errDetails));
      }
    );
  };
}
