// eslint-disable-next-line import/no-cycle
import { DepartmentHeadings, DepartmentsMessagingAccount } from '@src/types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

export const States = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const BooleanToState = (val: boolean) => {
  if (val) return 'ACTIVE';
  return 'INACTIVE';
};

export const StateToBoolean = {
  ACTIVE: true,
  INACTIVE: false,
};

export const buildMaxInvalidSselections = () => [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
  { value: 6, text: '6' },
  { value: 7, text: '7' },
  { value: 8, text: '8' },
  { value: 9, text: '9' },
  { value: 10, text: '10' },
];

type FormErrorMessageProps = {
  fieldName: string;
  errorState: string;
};

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  fieldName,
  errorState,
}) =>
  errorState ? (
    <FormattedMessage
      id={`Departments.errors.${fieldName}${errorState}`}
      defaultMessage="Error"
    />
  ) : null;

export const formatTimestamp = (timestamp: number) => {
  if (!_.isNil(timestamp)) {
    return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }

  return '';
};

export const buildHeadingList = (
  items: DepartmentHeadings[],
  messagingAccounts: DepartmentsMessagingAccount[]
) =>
  items
    .map(item => {
      const msgAccount = messagingAccounts.find(
        account => `${account.key}` === `${item.botAccountId}`
      );
      if (msgAccount)
        return {
          key: msgAccount.key,
          name: msgAccount.name,
          provider: msgAccount.provider,
          headerText: item.headerText,
        };
      return {};
    })
    .filter(item => item.key);

export default States;
