import { MediaType } from '@src/model/frontendmodel';
import { PageInfo } from '@src/reducers/shopify/types';

export interface IHandleEditFromTable {
  id: string;
  field: string;
  value: boolean;
}

// eslint-disable-next-line no-shadow
export const enum CredentialType {
  SHOPIFY = 'SHOPIFY',
}

export type ShopifyCredential = {
  id?: number;
  type: CredentialType.SHOPIFY;
  merchantId: number;
  config: {
    stores: ShopifyStoreCredentials[];
  };
};

export type SaveShopifyStoreCredentials = {
  type: CredentialType.SHOPIFY;
  merchantId: number;
  config: {
    stores: ShopifyStoreCredentials[];
  };
};

export type ShopifyStoreCredentials = {
  id: string;
  store?: string;
  storeUrl: string;
  tokenStoreFront: string;
  token: string;
  showAvailabilityFilter: boolean;
  default: boolean;
  canAddItem: boolean;
};

export type ShopifyStore = {
  id: string;
  store: string;
  showAvailabilityFilter: boolean;
  isDefault: boolean;
  canAddItem: boolean;
};

export type ShopifyConfigStores = {
  currentStoreId: string | null;
  currentLocationId: number | null;
  stores: ShopifyStore[];
};

export type ShopifyProductTag = {
  name: string;
};

export type ShopifyProductImage = {
  src: string;
  width: number;
  height: number;
  variants: number[];
};

export type ShopifyCollection = {
  id: number;
  name: string;
  handle: string;
};

export type ShopifyVariant = {
  id: number;
  title: string;
  barcode: string;
  productId: number;
  inventoryItemId: number;
  sku: string;
  price: string;
  compareAtPrice: string;
  inventoryQuantity: number;
  active: boolean;
  weightUnit: string;
  adminGraphqlApiId: string;
  image: {
    src: string;
  };
  currencyCode: string;
  currencySymbol: string;
};
export type ShopifyOption = {
  name: string;
  position: number;
  values: string[];
};

export type ShopifyProduct = {
  id: number;
  title: string;
  bodyHtml: string;
  handle: string;
  createAt: string;
  vendor: string;
  prices: number[];
  variants: ShopifyVariant[];
  tags: ShopifyProductTag[];
  images: ShopifyProductImage[];
  collections: ShopifyCollection[];
  active: boolean;
  options: ShopifyOption[];
};

// eslint-disable-next-line no-shadow
export const enum ShopifyProductOrigin {
  LIST = 'LIST',
  DETAIL = 'DETAIL',
}

export type SendShopifyProduct = {
  type: MediaType.PRODUCT_OVERVIEW;
  name: string;
  imageUrl: string;
  description: string;
  price: string;
  information: ShopifyProductInfo;
};

export type SendProductVariants = {
  name: string;
  value: string;
}[];

export type ShopifyProductInfo = {
  productId: number;
  locationId: number;
  variantId?: number;
  productUrlVariant: string;
  store: string;
  vendor: string;
  variants: SendProductVariants;
  collections: string[];
};

export type ShopifyLocation = {
  id: number;
  name: string;
  country?: string;
};

export type InventoryFilterOptions = {
  title?: string;
  collections?: string;
  tags?: string;
  status?: boolean;
  vendor?: string;
  price?: string;
};

export type ShopifyInventory = {
  inventoryFilterOptions: InventoryFilterOptions;
  products: ShopifyProduct[];
  pageInfo?: PageInfo;
  totalProducts: number;
};

export type ShopifyVendor = {
  name: string;
};

export type ShopifyTag = ShopifyVendor;

export type ShopifyShop = {
  id: number;
  name: string;
  domain: string;
  myShopifyDomain: string;
  tags: ShopifyProductTag[];
  vendors: ShopifyVendor[];
  locations: ShopifyLocation[];
  collections: ShopifyCollection[];
};

export type ShoppingCartProduct = {
  id: string;
  title: string;
  image: {
    url: string;
  };
  price: {
    amount: string;
    currencyCode: string;
  };
  weight?: number;
  weightUnit?: string;
  quantity: number;
  isFirst?: boolean;
  isLast?: boolean;
  productVariantId?: string;
  titleVariant?: string;
  titleProduct?: string;
};

export type ShoppingCart = {
  id: string;
  checkoutUrl: string;
  contactId: string;
  merchantId: string;
  cost: {
    totalAmount: {
      amount: string;
      currencyCode: string;
    };
    subtotalAmount: {
      amount: string;
      currencyCode: string;
    };
  };
  products: ShoppingCartProduct[];
};

export type ShoppingCartOrder = {
  orderNumber: string;
  id: string;
  email: string;
  phone: string;
  orderAt: string;
  productQuantity: number;
  status:
    | 'PAID'
    | 'PENDING'
    | 'CANCELLED'
    | 'AUTHORIZED'
    | 'PARTIALLY_PAID'
    | 'REFUNDED'
    | 'PARTIALLY_REFUNDED'
    | 'VOIDED';
  totalAmount: {
    amount: string;
    currencyCode: string;
  };
  totalTax: {
    amount: string;
    currencyCode: string;
  };
  totalShipping: {
    amount: string;
    currencyCode: string;
  };
  subTotal: {
    amount: string;
    currencyCode: string;
  };
};

export type ShoppingCartOrderUrl = {
  orderNumber: string;
  productQuantity: number;
  orderStatusUrl: string;
};

export type FetchOrdersHistoryType = 'EMAIL' | 'PHONE';

// eslint-disable-next-line no-shadow
export enum UpdateCredentialStatusValues {
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
}

export type UpdateCredentialStatus = UpdateCredentialStatusValues | undefined;
