/* eslint-disable import/prefer-default-export */
import { RootState } from '@types';

export const selectBotAccounts = (state: RootState) => state.botAccounts;

export const getBotButtons = (state: RootState) =>
  state.botAccounts.messaging.buttonsSettings;

export const getBotList = (state: RootState) =>
  state.botAccounts.messaging.interactiveListSettings;

export const selectBotAccountsRaw = (state: RootState) =>
  selectBotAccounts(state).data;
