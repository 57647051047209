/* eslint-disable no-console */
import dayjs from 'dayjs';
import _ from 'lodash';
import { appConfig } from '../config/appConfig';
import { ResponseStatusCode } from '../model/frontendmodel';
import { checkHttpFetchStatus, failWithErrorReport } from './backendproxyutils';

// Proxy module that provides access to services provided by the backend, that
// are related to contacts
// eslint-disable-next-line func-names
const ContactBackendProxy = (function (endpointUrl) {
  const confEndpointUrl = endpointUrl;

  return {
    // TODO: Remove this method after removing old contacts component (AdminHome/Contact)
    exportContactData(merchantId, filters, msg, okCallback, failCallback) {
      let dateStart = '';
      let dateEnd = '';
      let day = '';
      let month = '';
      let year = '';

      if (
        filters.timeframe_start_date !== null &&
        filters.timeframe_end_date !== null
      ) {
        dateStart = new Date(filters.timeframe_start_date);
        dateEnd = new Date(filters.timeframe_end_date);

        day =
          `${dateStart.getDate()}`.length < 2
            ? `0${dateStart.getDate()}`
            : dateStart.getDate();
        month =
          `${dateStart.getMonth()}`.length < 2
            ? `0${dateStart.getMonth() + 1}`
            : dateStart.getMonth() + 1;
        year = dateStart.getFullYear();

        dateStart = `${day}/${month}/${year}`;

        day =
          `${dateEnd.getDate()}`.length < 2
            ? `0${dateEnd.getDate()}`
            : dateEnd.getDate();
        month =
          `${dateEnd.getMonth()}`.length < 2
            ? `0${dateEnd.getMonth() + 1}`
            : dateEnd.getMonth() + 1;
        year = dateEnd.getFullYear();

        dateEnd = `${day}/${month}/${year}`;
      }

      fetch(`${confEndpointUrl}/services/contact/export`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
          generic: filters.general,
          city: filters.byCity,
          dateRangeType: filters.byDate,
          timeframe: filters.timeframe,
          timeframeStartDate: dateStart,
          timeframeEndDate: dateEnd,
          dataEmail: msg,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Get Contacts FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getContactsByFilters(merchantId, filters, okCallback, failCallback) {
      let dateStart = '';
      let dateEnd = '';
      let day = '';
      let month = '';
      let year = '';

      if (
        filters.timeframe_start_date !== null &&
        filters.timeframe_end_date !== null
      ) {
        dateStart = new Date(filters.timeframe_start_date);
        dateEnd = new Date(filters.timeframe_end_date);

        day =
          `${dateStart.getDate()}`.length < 2
            ? `0${dateStart.getDate()}`
            : dateStart.getDate();
        month =
          `${dateStart.getMonth()}`.length < 2
            ? `0${dateStart.getMonth() + 1}`
            : dateStart.getMonth() + 1;
        year = dateStart.getFullYear();

        dateStart = `${day}/${month}/${year}`;

        day =
          `${dateEnd.getDate()}`.length < 2
            ? `0${dateEnd.getDate()}`
            : dateEnd.getDate();
        month =
          `${dateEnd.getMonth()}`.length < 2
            ? `0${dateEnd.getMonth() + 1}`
            : dateEnd.getMonth() + 1;
        year = dateEnd.getFullYear();

        dateEnd = `${day}/${month}/${year}`;
      }

      fetch(`${confEndpointUrl}/services/contact/searchContactsByFilters`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
          generic: filters.general,
          city: filters.byCity,
          dateRangeType: filters.byDate,
          timeframe: filters.timeframe,
          timeframeStartDate: dateStart,
          timeframeEndDate: dateEnd,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            const contactsJson = responseEvent;
            const contactsByMerchant = { contacts: [] };

            contactsByMerchant.contacts = contactsJson; // = contactsJson;

            okCallback(contactsByMerchant);
          } else {
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getContactsWithoutFilters(merchantId, filters, okCallback, failCallback) {
      const dateStart = '';
      const dateEnd = '';

      fetch(`${confEndpointUrl}/services/contact/searchContactsByFilters`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          merchantId,
          generic: filters.general,
          city: '',
          dateRangeType: '',
          timeframe: '',
          timeframeStartDate: dateStart,
          timeframeEndDate: dateEnd,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            const contactsJson = responseEvent;
            const contactsByMerchant = { contacts: [] };

            contactsByMerchant.contacts = contactsJson; // = contactsJson;

            okCallback(contactsByMerchant);
          } else {
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    // Loads all information about a contact a given its unique ID and also the bot the contact belongs to.
    // The okCallback function should expect two arguments: 1) the loaded contact and 2) its associated bot
    loadContact(contactId, okCallback, failCallback) {
      return fetch(`${confEndpointUrl}/services/contact/get/${contactId}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          let contact = null;
          let bot = null;

          // Extract the contact and its associated bot from the response
          if (responseEvent !== undefined && responseEvent !== null) {
            contact = responseEvent.contact;
            bot = responseEvent.bot;
          }

          return okCallback(contact, bot);
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Submits a create new contact request, intended to add a contact associated
    // with a bot having the specified ID. Optionally, a contact-messaging-account
    // can be provided to be associated with the new contact
    createContact(botId, contact, msgAccount, okCallback, failCallback) {
      let argContactMsgAccount = null;

      // If a messaging account is provided, specify set it as associated with
      // the contact to be created. For that, the account ID and provider suffice
      if (typeof msgAccount !== 'undefined' && msgAccount !== null) {
        argContactMsgAccount = {
          accountId: msgAccount.accountId,
          provider: msgAccount.provider,
        };
      }

      fetch(`${confEndpointUrl}/services/contact`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          botId,
          contact,
          contactMessagingAccount: argContactMsgAccount,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.id) {
            okCallback(responseEvent.id);
          } else {
            throw new Error(
              'Add new contact failed. Contact ID missing in response'
            );
          }
        })
        .catch(error => {
          failWithErrorReport(error, failCallback);
        });
    },

    // Submits an update contact request
    updateExistingContact(contact, okCallback, failCallback) {
      // The contact to be updated must have a valid ID set as property
      if (contact && contact.id && String(contact.id).trim() !== '') {
        fetch(
          `${confEndpointUrl}/services/contact/${encodeURIComponent(
            contact.id
          )}`,
          {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(contact),
          }
        )
          .then(checkHttpFetchStatus)
          .then(() => {
            okCallback(contact.id);
          })
          .catch(error => {
            failWithErrorReport(error, failCallback);
          });
      } else {
        throw new Error('Error updating contact. ID property not set');
      }
    },

    // Submits a delete contact request
    deleteContact(contactId, okCallback, failCallback) {
      // The contact to be removed must be provided as an object and should have a valid id property
      if (contactId && String(contactId).trim() !== '') {
        fetch(
          `${confEndpointUrl}/services/contact/${encodeURIComponent(
            contactId
          )}`,
          {
            method: 'DELETE',
            headers: {
              'Content-type': 'application/json',
            },
            credentials: 'include',
          }
        )
          .then(checkHttpFetchStatus)
          .then(() => {
            okCallback(contactId);
          })
          .catch(error => {
            failWithErrorReport(error, failCallback);
          });
      } else {
        throw new Error('Error deleting contact. Contact ID not specified');
      }
    },

    // Submits a block contact request
    blockContact(contactId, okCallback, failCallback) {
      // The contact to be removed must be provided as an object and should have a valid id property
      if (contactId && String(contactId).trim() !== '') {
        fetch(`${confEndpointUrl}/admin/contactblacklist/${contactId}`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        })
          .then(checkHttpFetchStatus)
          .then(() => okCallback())
          .catch(error => {
            failWithErrorReport(error, failCallback);
          });
      } else {
        throw new Error('Error blocking contact. Contact ID not specified');
      }
    },

    // Submits a block contact request
    unblockContact(contactId, okCallback, failCallback) {
      // The contact to be removed must be provided as an object and should have a valid id property
      if (contactId && String(contactId).trim() !== '') {
        fetch(`${confEndpointUrl}/admin/contactblacklist/${contactId}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        })
          .then(checkHttpFetchStatus)
          .then(() => okCallback())
          .catch(error => {
            failWithErrorReport(error, failCallback);
          });
      } else {
        throw new Error('Error blocking contact. Contact ID not specified');
      }
    },

    // TODO: Remove. Replace with createContact(botId, cma, co)
    // Sends a save new contact request, for an existing contact
    saveNewContact(
      botId,
      contactMsgAccount,
      contact,
      okCallback,
      failCallback
    ) {
      fetch(`${confEndpointUrl}/services/contact`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          botId,
          contact,
          contactMessagingAccount: {
            accountId: contactMsgAccount.accountId,
            provider: contactMsgAccount.provider,
          },
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.id) {
            okCallback(responseEvent.id);
          } else {
            throw new Error(
              'Add new contact failed. Contact ID missing in response'
            );
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    // TODO: Remove. Replace with createContact(botId, null, co)
    saveNewContactWithOutMsgAccount(botId, contact, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/contact`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          botId,
          contact,
          contactMessagingAccount: null,
        }),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent.id) {
            okCallback(responseEvent.id);
          } else {
            throw new Error(
              'Add new contact failed. Contact ID missing in response'
            );
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`,
            error.response
          );
        });
    },

    getStandardBotsData(merchantId, okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/bot/list-user-standard-bots`, {
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Get Provider FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    // Sends a save contact request, for a specific merchant
    updateContact(contact, okCallback, failCallback) {
      // If the contact has an non-null ID field, it's an existing contact to be modified
      if (contact.id && String(contact.id).trim() !== '') {
        let date = null;

        if (contact.createAt !== null && contact.createAt !== '') {
          date = contact.createAt;
          date = date.split('/');
          date = `${date[2]}/${date[1]}/${date[0]}`;
          date = new Date(date);
          contact.createAt = date.getTime();
        } else {
          contact.createAt = new Date().getTime();
        }

        contact.lastUpdate = new Date().getTime();

        fetch(`${confEndpointUrl}/services/contact/${contact.id}`, {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(contact),
        })
          .then(checkHttpFetchStatus)
          .then(httpResponse => {
            okCallback(httpResponse);
          })
          .catch(error => {
            failCallback(
              ResponseStatusCode.ERROR,
              `HTTP error: ${error.message}`,
              error.response
            );
          });
      } else {
        // The contact to be updated must have a valid ID set as property
        throw new Error('Error updating contact. ID property not set');
      }
    },

    getCitiesByCountry(merchantId, value, text, okCallback, failCallback) {
      fetch(
        `${confEndpointUrl}/services/contact/getCitiesByCountry/${encodeURIComponent(
          value
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        }
      )
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Get Contacts FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    getAvailableMessagingAccounts(
      merchantId,
      contact,
      okCallback,
      failCallback
    ) {
      fetch(
        `${confEndpointUrl}/services/contact/getBotAccounts/${encodeURIComponent(
          contact.id
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        }
      )
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(responseEvent => {
          if (responseEvent !== null) {
            okCallback(responseEvent);
          } else {
            console.log('Get Contacts FAIL', responseEvent);
            failCallback(responseEvent.status, responseEvent.error);
          }
        })
        .catch(error => {
          failCallback(
            ResponseStatusCode.ERROR,
            `HTTP error: ${error.message}`
          );
        });
    },

    checkWhatsAppAccount(mobileNumber) {
      return fetch(
        `${confEndpointUrl}/services/contact/check-account/whatsapp/${encodeURIComponent(
          mobileNumber
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        }
      ).catch(e => {
        console.error('error', e);
      });
    },

    // Submits a request to search for contacts of the currently authenticated merchant, that match the
    // filter criteria. Returns a promise that resolves with the response, or is rejected with an error
    searchContacts(searchParams) {
      const endpoint = `${confEndpointUrl}/services/contact/search-paged-sorted`;

      const jsonResponsePromise = fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(searchParams),
        headers: { 'Content-type': 'application/json' },
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json());

      return jsonResponsePromise;
    },

    // Submits a request to search for contacts of the currently authenticated merchant, that match the
    // filter criteria. Returns a promise that resolves with the response, or is rejected with an error
    exportFilteredContacts(
      emailMsg,
      searchFilter,
      createdAtFrom,
      createdAtTo,
      onlyBlacklisted,
      searchByLastUpdate,
      company,
      tagFilter,
      assignTo
    ) {
      const dateFormatExpectedByService = 'DD/MM/YYYY';

      const serviceArgs = {
        dataEmail: emailMsg,
        generic: searchFilter,
        dateRangeType: '0',
        timeframe: 'custom_timeframe',
        onlyBlacklisted,
        searchByLastUpdate,
        company,
        tagFilter,
        assignTo,
      };

      if (!_.isNil(createdAtFrom)) {
        serviceArgs.timeframeStartDate = dayjs(createdAtFrom).format(
          dateFormatExpectedByService
        );
      }

      if (!_.isNil(createdAtTo)) {
        serviceArgs.timeframeEndDate = dayjs(createdAtTo).format(
          dateFormatExpectedByService
        );
      }

      return fetch(`${confEndpointUrl}/services/contact/export`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(serviceArgs),
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json());
    },
  };
})(appConfig.webServicesBaseUri);

export default ContactBackendProxy;
