import B2ChatClient from '@client-sdk';
import { StateToBoolean } from '@components/AdminHome/Departments/utils';
import {
  changeDepartmentStatus,
  changeDepartmentStatusFailure,
  changeDepartmentStatusFulfill,
  changeDepartmentStatusSuccess,
  changeStatusAutoAssignNoDepartments,
  changeStatusAutoAssignNoDepartmentsFailure,
  changeStatusAutoAssignNoDepartmentsFulfill,
  changeStatusAutoAssignNoDepartmentsSuccess,
  createDepartment,
  createDepartmentFailure,
  createDepartmentFulfill,
  createDepartmentSuccess,
  getAllDepartmentSettings,
  getAllDepartmentSettingsFailure,
  getAllDepartmentSettingsFulfill,
  getAllDepartmentSettingsSuccess,
  getDepartmentSettings,
  getDepartmentSettingsFailure,
  getDepartmentSettingsFulfill,
  getDepartmentSettingsSuccess,
  getMerchantSettings,
  getMerchantSettingsFailure,
  getMerchantSettingsFulfill,
  getMerchantSettingsSuccess,
  getUserApplicableSettings,
  getUserApplicableSettingsFailure,
  getUserApplicableSettingsFulfill,
  getUserApplicableSettingsSuccess,
  getUserSettings,
  getUserSettingsFulfill,
  getUserSettingsSuccess,
  HandleCreateDepartmentPayload,
  updateMerchantSettings,
  updateMerchantSettingsFailure,
  updateMerchantSettingsFulfill,
  updateMerchantSettingsSuccess,
  updateUserSettings,
  updateUserSettingsFailure,
  updateUserSettingsFulfill,
  updateUserSettingsSuccess,
} from '@reducers/chatAutoAssignment';
import { fetchDepartments } from '@reducers/departments';
import { Department, ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLatest } from 'redux-saga/effects';

function* changeDepartmentStatusSaga(
  action: ReturnType<typeof changeDepartmentStatus>
) {
  const data = action.payload;

  try {
    const response = (yield call(
      [
        B2ChatClient.resources.chatAutoAssignment.actions,
        'changeDepartmentStatus',
      ],
      {
        data,
      }
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.ChangeState>;

    if (response.error != null) {
      yield put(changeDepartmentStatusFailure(response.error));
    } else {
      yield put(changeDepartmentStatusSuccess(data));
    }
  } catch (error) {
    yield put(changeDepartmentStatusFailure(error as ErrorData));
  } finally {
    yield put(changeDepartmentStatusFulfill());
  }
}

function* changeStatusAutoAssignNoDepartmentsSaga(
  action: ReturnType<typeof changeStatusAutoAssignNoDepartments>
) {
  const data = action.payload;
  const booleanValue = StateToBoolean[data[0].status];

  try {
    const response = (yield call(
      [
        B2ChatClient.resources.chatAutoAssignment.actions,
        'changeDepartmentStatus',
      ],
      {
        data,
      }
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.ChangeState>;

    if (response.error != null) {
      yield put(changeStatusAutoAssignNoDepartmentsFailure(response.error));
    } else {
      yield put(changeStatusAutoAssignNoDepartmentsSuccess(booleanValue));
    }
  } catch (error) {
    yield put(changeStatusAutoAssignNoDepartmentsFailure(error as ErrorData));
  } finally {
    yield put(changeStatusAutoAssignNoDepartmentsFulfill());
  }
}

function* getDepartmentSettingsSaga(
  action: ReturnType<typeof getDepartmentSettings>
) {
  const { botId } = action.payload;
  try {
    const response = (yield call(
      [
        B2ChatClient.resources.chatAutoAssignment.actions,
        'getDepartmentSettings',
      ],
      {
        params: {
          botId,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.GetDepartmentSettings>;

    if (response.error != null) {
      yield put(getDepartmentSettingsFailure(response.error));
    } else {
      yield put(getDepartmentSettingsSuccess(response.data));
    }
  } catch (error) {
    yield put(getDepartmentSettingsFailure(error as ErrorData));
  } finally {
    yield put(getDepartmentSettingsFulfill());
  }
}

function* getAllDepartmentSettingsSaga() {
  try {
    const response = (yield call([
      B2ChatClient.resources.chatAutoAssignment.actions,
      'getAllDepartmentSettings',
    ])) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.GetAllDepartmentSettings>;

    if (response.error != null) {
      yield put(getAllDepartmentSettingsFailure(response.error));
    } else {
      yield put(getAllDepartmentSettingsSuccess(response.data));
    }
  } catch (error) {
    yield put(getAllDepartmentSettingsFailure(error as ErrorData));
  } finally {
    yield put(getAllDepartmentSettingsFulfill());
  }
}

function* updateMerchantSettingsSaga(
  action: ReturnType<typeof updateMerchantSettings>
) {
  const merchantSettings = action.payload;
  try {
    const response = (yield call(
      [
        B2ChatClient.resources.chatAutoAssignment.actions,
        'updateMerchantSettings',
      ],
      {
        data: merchantSettings,
      }
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.updateMerchantSettings>;

    if (response.error != null) {
      yield put(updateMerchantSettingsFailure(response.error));
    } else {
      yield put(updateMerchantSettingsSuccess(merchantSettings));
    }
  } catch (error) {
    yield put(updateMerchantSettingsFailure(error as ErrorData));
  } finally {
    yield put(updateMerchantSettingsFulfill());
  }
}

function* getMerchantSettingsSaga() {
  try {
    const response = (yield call([
      B2ChatClient.resources.chatAutoAssignment.actions,
      'getMerchantSettings',
    ])) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.GetMerchantSettings>;

    if (response.error != null) {
      yield put(getMerchantSettingsFailure(response.error));
    } else {
      yield put(getMerchantSettingsSuccess(response.data));
    }
  } catch (error) {
    yield put(getMerchantSettingsFailure(error as ErrorData));
  } finally {
    yield put(getMerchantSettingsFulfill());
  }
}

function* createDepartmentSetting(data: HandleCreateDepartmentPayload) {
  const { treeId, departmentNode } = data;
  try {
    const response = (yield call(
      [B2ChatClient.resources.departments.actions, 'createDepartment'],
      {
        data: departmentNode,
        params: {
          treeId,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Departments.CreateDepartment>;

    if (response.error != null) {
      yield put(createDepartmentFailure(response.error));
    } else {
      yield put(createDepartmentSuccess(response.data));
    }
  } catch (error) {
    yield put(createDepartmentFailure(error as ErrorData));
  } finally {
    yield put(createDepartmentFulfill());
  }
}

function* editDepartmentSetting(data: HandleCreateDepartmentPayload) {
  const { treeId, nodeId, departmentNode } = data;
  try {
    const response = (yield call(
      [B2ChatClient.resources.departments.actions, 'editDepartment'],
      {
        data: departmentNode,
        params: {
          treeId,
          nodeId: `${nodeId}`,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Departments.CreateDepartment>;

    const depData = {
      ...response.data,
      autoChatAssign: departmentNode.autoChatAssign,
    } as Department;

    if (response.error != null) {
      yield put(createDepartmentFailure(response.error));
    } else {
      yield put(createDepartmentSuccess(depData));
    }
  } catch (error) {
    yield put(createDepartmentFailure(error as ErrorData));
  } finally {
    yield put(createDepartmentFulfill());
  }
}

function* createEditSaga(action: ReturnType<typeof createDepartment>) {
  const { nodeId } = action.payload;

  if (nodeId) {
    yield call(editDepartmentSetting, action.payload);
  } else {
    yield call(createDepartmentSetting, action.payload);
  }
  yield put(fetchDepartments());
}

function* getUserSettingsSaga(action: ReturnType<typeof getUserSettings>) {
  const { userId } = action.payload;
  try {
    yield put(
      getUserSettingsSuccess({
        traySizeLimitEnabled: false,
        traySizeLimit: -1,
        userId: undefined,
      })
    );
    const response = (yield call(
      [B2ChatClient.resources.chatAutoAssignment.actions, 'getUserSettings'],
      {
        params: {
          userId,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.GetUserSettings>;

    if (response.error != null) {
      throw response.error;
    } else {
      yield put(getUserSettingsSuccess(response.data));
    }
  } catch (error) {
    yield put(
      getUserSettingsSuccess({
        userId,
        traySizeLimit: 10,
        traySizeLimitEnabled: false,
      })
    );
  } finally {
    yield put(getUserSettingsFulfill());
  }
}

function* getUserApplicableSettingsSaga() {
  try {
    const response = (yield call(
      [
        B2ChatClient.resources.chatAutoAssignment.actions,
        'getApplicableSettings',
      ],
      {}
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.GetUserApplicableSettings>;

    if (response.error != null) {
      yield put(getUserApplicableSettingsFailure(response.error));
    } else {
      yield put(getUserApplicableSettingsSuccess(response.data));
    }
  } catch (error) {
    yield put(getUserApplicableSettingsFailure(error as ErrorData));
  } finally {
    yield put(getUserApplicableSettingsFulfill());
  }
}

function* updateUserSettingsSaga(
  action: ReturnType<typeof updateUserSettings>
) {
  const { userId, traySizeLimitEnabled, traySizeLimit } = action.payload;
  try {
    const response = (yield call(
      [B2ChatClient.resources.chatAutoAssignment.actions, 'updateUserSettings'],
      {
        data: {
          userId,
          traySizeLimitEnabled,
          traySizeLimit,
        },
        params: { userId },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.ChatAutoAssignment.UpdateUserSettings>;
    if (response.error != null) {
      yield put(updateUserSettingsFailure(response.error));
    } else {
      yield put(updateUserSettingsSuccess(response.data));
    }
  } catch (error) {
    yield put(updateUserSettingsFailure(error as ErrorData));
  } finally {
    yield put(updateUserSettingsFulfill());
  }
}

function* ChatAutoAssignmentSaga() {
  yield takeLatest(changeDepartmentStatus, changeDepartmentStatusSaga);
  yield takeLatest(
    changeStatusAutoAssignNoDepartments,
    changeStatusAutoAssignNoDepartmentsSaga
  );
  yield takeLatest(getDepartmentSettings, getDepartmentSettingsSaga);
  yield takeLatest(getAllDepartmentSettings, getAllDepartmentSettingsSaga);
  yield takeLatest(updateMerchantSettings, updateMerchantSettingsSaga);
  yield takeLatest(getMerchantSettings, getMerchantSettingsSaga);
  yield takeLatest(getUserApplicableSettings, getUserApplicableSettingsSaga);
  yield takeLatest(createDepartment, createEditSaga);
  yield takeLatest(getUserSettings, getUserSettingsSaga);
  yield takeLatest(updateUserSettings, updateUserSettingsSaga);
}

export default ChatAutoAssignmentSaga;
