import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GeneralContactInfo } from '@src/types/contact';
import type { ErrorData } from '@types';

type Loading = 'fetch-contact';

type ContactRToolkitReducer<P = void> = (
  state: InitialState,
  action: PayloadAction<P>
) => void;

type InitialState = {
  contact: GeneralContactInfo;
  error: ErrorData | null;
  loading: Loading[];
};

const initialState: InitialState = {
  error: null,
  contact: {
    id: 0,
    botId: 0,
    fullName: '',
    identificationNumber: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    country: { isoCode: 'CO', name: 'Colombia', languageCode: 'es' },
    cityName: '',
    address: '',
    company: '',
    attributes: [],
    attributesAugmented: [],
  },
  loading: [],
};

export type HandleFetchContactById = { id: string };
export const handleFetchContactById: ContactRToolkitReducer<
  HandleFetchContactById
> = state => {
  state.loading.push('fetch-contact');
  state.error = null;
};

export type HandleFetchContactByIdSuccess = {
  bot: unknown;
  contact: GeneralContactInfo;
};

const handleFetchContactByIdSuccess: ContactRToolkitReducer<
  HandleFetchContactByIdSuccess
> = (state, action) => {
  state.contact = action.payload.contact;
};

const handleFetchContactByIdFailure: ContactRToolkitReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchContactByIdFulfill: ContactRToolkitReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-contact');
};

const contactRToolkit = createSlice({
  name: 'Contact',
  initialState,
  reducers: {
    fetchContactById: handleFetchContactById,
    fetchFechContactByIdSuccess: handleFetchContactByIdSuccess,
    fetchFechContactByIdFailure: handleFetchContactByIdFailure,
    fetchFechContactByIdFulfill: handleFetchContactByIdFulfill,
  },
});

const { actions, reducer } = contactRToolkit;
export const {
  fetchContactById,
  fetchFechContactByIdSuccess,
  fetchFechContactByIdFailure,
  fetchFechContactByIdFulfill,
} = actions;

export default reducer;
