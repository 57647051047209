import StringUtils from '../utils/strings';
import {
  Agent,
  AgentChat,
  Bot,
  Contact,
  Message,
  MessagingAccount,
  MessagingProvider,
} from './frontendmodel';

export function normalizeBot(rawBot) {
  if (rawBot) {
    const botId = rawBot.id ? rawBot.id : null;
    const botName = rawBot.name ? rawBot.name : null;

    return new Bot(botId, botName);
  }

  return null;
}

// Transforms an agent from the backend model into the normalized frontend model
export function normalizeAgent(rawAgent) {
  const agent = Object.create(Agent.prototype);
  Object.assign(agent, rawAgent);

  return agent;
}

// Transforms the initial agent chat status handed in by the backend server into the normalized frontend model
export function normalizeAgentChatStatus(agentChatStatus) {
  let activeChatId = null;
  const arrQueues = [];
  const arrChatRequests = [];
  const arrChatsOnHold = [];
  const arrAgents = [];
  const arrContacts = [];
  const arrMessagingAccounts = [];

  // Utilitarian function. Decomposes a normalized agent chats and add its parts to the appropriate maps
  const decomposeNormAgentChat = (
    { agent, contact, messagingAccount, agentChat },
    arrChatsByStatus
  ) => {
    if (agent && agent.id) {
      arrAgents.push(agent);
    }

    if (contact && contact.id) {
      arrContacts.push(contact);
    }

    if (messagingAccount && messagingAccount.id) {
      arrMessagingAccounts.push(messagingAccount);
    }

    if (agentChat && agentChat.id) {
      arrChatsByStatus.push(agentChat);
    }
  };

  // Process the active chat
  if (agentChatStatus.activeChat) {
    activeChatId = agentChatStatus.activeChat;
  }

  // Process each chat request. Chat requests are expected to be an array, which supports the forEach function
  if (agentChatStatus.chatRequests && agentChatStatus.chatRequests.forEach) {
    agentChatStatus.chatRequests.forEach(rawAgentChat => {
      decomposeNormAgentChat(normalizeAgentChat(rawAgentChat), arrChatRequests);
    });
  }

  // Process each chat on-hold. Also expected to be an iterable array
  if (agentChatStatus.chatsOnHold && agentChatStatus.chatsOnHold.forEach) {
    agentChatStatus.chatsOnHold.forEach(rawAgentChat => {
      decomposeNormAgentChat(normalizeAgentChat(rawAgentChat), arrChatsOnHold);
    });
  }

  // Process each agent, an iterable array too
  if (agentChatStatus.agents && agentChatStatus.agents.forEach) {
    // Normalize each agent chat request and decompose it in its parts
    agentChatStatus.agents.forEach(rawAgent => {
      arrAgents.push(normalizeAgent(rawAgent));
    });
  }

  return {
    activeChatId,
    arrChatRequests,
    arrChatsOnHold,
    arrAgents,
    arrContacts,
    arrMessagingAccounts,
    arrQueues,
  };
}

// Transforms an agent chat from the backend model into the normalized frontend model
export function normalizeAgentChat(backendAgentChat) {
  const agentChat = Object.create(AgentChat.prototype);
  Object.assign(agentChat, backendAgentChat);

  let messagingAccount = null;
  let contact = null;
  let agent = null;

  if (
    backendAgentChat?.contactAccount &&
    backendAgentChat?.contactAccount.accountOwner
  ) {
    contact = Object.create(Contact.prototype);
    Object.assign(contact, backendAgentChat.contactAccount.accountOwner);

    // A contact is regarded as new if its Id attribute is null
    if (!contact.id) {
      // Messaging accounts refer to contacts via a contact id. Hence every contact must have a unique id even if new.
      // Autogenerate a temporary ID for this contact until it's created
      contact.id = `TMP_${StringUtils.getUuid()}`;
    }
  }

  if (backendAgentChat?.contactAccount) {
    messagingAccount = Object.create(MessagingAccount.prototype);
    Object.assign(messagingAccount, backendAgentChat.contactAccount);

    messagingAccount.provider =
      MessagingProvider[backendAgentChat.contactAccount.provider];
    messagingAccount.uid = messagingAccount.id;
    messagingAccount.id = `${messagingAccount.accountId}@${messagingAccount.provider}`;
    messagingAccount.contactId = null;

    if (contact) {
      messagingAccount.contactId = contact.id;
      delete messagingAccount.accountOwner;
    }
  }

  if (backendAgentChat?.assignedTo) {
    agent = normalizeAgent(backendAgentChat.assignedTo);
  }

  agentChat.agentId = agent ? agent.id : null;
  agentChat.contactAccountId = messagingAccount ? messagingAccount.id : null;
  delete agentChat.agent;
  delete agentChat.contactAccount;

  return { agent, contact, messagingAccount, agentChat };
}

// Transforms a message from the backend model into the normalized frontend model
export function normalizeMessage(agentChatId, backendMessage) {
  const message = Object.create(Message.prototype);
  Object.assign(message, backendMessage);

  // Set the ID of the chat the message was received in
  message.chatId = agentChatId;

  return message;
}

// Transforms the array of countries sent as response by the list-countries service into a representation
// suitable to feed an autocomplete component
export function normalizeCountriesSet(arrBackendCountries) {
  if (arrBackendCountries) {
    const arrDsCountries = [];

    for (let i = 0; i < arrBackendCountries.length; i++) {
      const curDsCountry = {};
      const curBackendCountry = arrBackendCountries[i];

      // An ID field is required in order to uniquely identify the city and a name to display
      if (
        curBackendCountry &&
        curBackendCountry.isoCode &&
        curBackendCountry.name
      ) {
        curDsCountry.value = curBackendCountry.isoCode;
        curDsCountry.text = curBackendCountry.name;
        arrDsCountries.push(curDsCountry);
      }
    }

    return arrDsCountries;
  }

  return null;
}

// Transforms the array of cities sent as response by the list-cities service into a representation
// suitable to feed an autocomplete component
export function normalizeCitiesSet(arrBackendCities) {
  if (arrBackendCities) {
    const arrDsCities = [];

    for (let i = 0; i < arrBackendCities.length; i++) {
      const curDsCity = {};
      const curBackendCity = arrBackendCities[i];

      // An ID field is required in order to uniquely identify the city and a name to display
      if (curBackendCity && curBackendCity.id && curBackendCity.name) {
        curDsCity.value = curBackendCity.id;
        curDsCity.text = curBackendCity.name;

        // Build the city name including the name of the province and country it's part of
        if (curBackendCity.province && curBackendCity.province.name) {
          curDsCity.text = `${curDsCity.text}, ${curBackendCity.province.name}`;

          if (
            curBackendCity.province.country &&
            curBackendCity.province.country.name
          ) {
            curDsCity.text = `${curDsCity.text}. ${curBackendCity.province.country.name}`;
          }
        }

        arrDsCities.push(curDsCity);
      }
    }

    return arrDsCities;
  }

  return null;
}
