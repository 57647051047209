import { SideMenuTabs } from '@src/components/AgentConsole/SideMenu';
import { countUnrepliedMessagesInChat } from '../logic/summarizestate';
import { ChatStatus } from '../model/frontendmodel';

// Constructs an initial state suitable to be set into the store
const buildInitialAgentConsoleState = (
  isLoaded,
  isConnected,
  loggedAgent,
  activeChatId,
  arrChatRequests,
  arrChatsOnHold,
  arrAgents,
  arrContacts,
  arrMessagingAccounts,
  arrQueues = [],
  argLatestStartChatRequest,
  isVisible,
  totalChatRequests,
  totalChatsOnHold,
  config = {}
) => {
  const refActiveChat = {};
  const mapAllChats = {};
  const mapChatRequests = {};
  const mapChatsOngoing = {};
  const mapAgents = {};
  const mapContacts = {};
  const mapMessagingAccounts = {};

  // Is initial status loaded? Is the frontend now connected to the backend services?
  const stateIsLoaded = !!isLoaded;
  const stateIsConnected = !!isConnected;

  // If there wasn't a latest chat request recorded in the state, initialize it
  let latestStartChatRequest = argLatestStartChatRequest;

  if (!latestStartChatRequest) {
    latestStartChatRequest = {
      state: null,
      chatId: null,
      timestamp: null,
      errorCode: null,
      errorDetails: null,
    };
  }

  // Process each agent, an iterable array too
  if (arrAgents && arrAgents.forEach) {
    arrAgents.forEach(agent => {
      if (agent && agent.id) {
        mapAgents[agent.id] = agent;
      }
    });
  }

  // Process each contact, an iterable array too
  if (arrContacts && arrContacts.forEach) {
    arrContacts.forEach(contact => {
      if (contact && contact.id) {
        mapContacts[contact.id] = contact;
      }
    });
  }

  // Process each contact, an iterable array too
  if (arrMessagingAccounts && arrMessagingAccounts.forEach) {
    arrMessagingAccounts.forEach(messagingAccount => {
      if (messagingAccount && messagingAccount.id) {
        mapMessagingAccounts[messagingAccount.id] = messagingAccount;
      }
    });
  }

  // Process each chat request. Chat requests are expected to be arrays
  if (arrChatRequests && arrChatRequests.length) {
    let agentChat = null;

    for (let i = 0; i < arrChatRequests.length; i += 1) {
      agentChat = arrChatRequests[i];

      if (agentChat && agentChat.id) {
        let curUnrepliedMessages = countUnrepliedMessagesInChat(agentChat);
        let isClean = false;

        if (curUnrepliedMessages <= 0) {
          curUnrepliedMessages = 1;
          isClean = true;
        }

        mapAllChats[agentChat.id] = agentChat;
        mapChatRequests[agentChat.id] = {
          chatId: agentChat.id,
          countUnseen: curUnrepliedMessages,
          isClean,
        };
      }
    }
  }

  // Process each chat on-hold. Also expected to be an iterable array
  let arrSortOngoingChats = null;

  if (arrChatsOnHold && arrChatsOnHold.length) {
    let agentChat = null;

    arrSortOngoingChats = new Array(arrChatsOnHold.length);

    for (let i = 0; i < arrChatsOnHold.length; i += 1) {
      agentChat = arrChatsOnHold[i];
      agentChat.pinned = Number.isInteger(agentChat.pinId);

      if (agentChat && agentChat.id && agentChat.status !== ChatStatus.CLOSED) {
        mapAllChats[agentChat.id] = agentChat;
        mapChatsOngoing[agentChat.id] = { chatId: agentChat.id };
        arrSortOngoingChats[i] = {
          chatId: agentChat.id,
          startTimestamp: agentChat.startTimestamp,
        };
      }
    }
  }

  // Process the active chat, which is deemed as an ongoing chat
  if (activeChatId && mapAllChats[activeChatId]) {
    refActiveChat.chatId = activeChatId;
  }

  return {
    backendConn: {
      isLoaded: stateIsLoaded,
      isConnected: stateIsConnected,
      isLoggingOut: false,
      wasConnectionLost: false,
      closeEvent: null,
    },
    queues: {
      items: arrQueues,
    },

    agents: mapAgents,
    messagingAccounts: mapMessagingAccounts,
    contacts: mapContacts,
    currentContactChatHistory: {
      messagingAccountId: null,
      closedChats: [],
      chatbotHistory: null,
      chatbotHistoryError: null,
    },
    // AgentConsole State - UI State
    messageComposing: {
      writtenMsgsCache: [],
      isSendingMessage: false,
      errorSendingMessage: null,
    },
    emoticonSelector: {
      open: false,
      anchorEl: null,
    },
    chatContextMenu: {
      isOpened: true,
      selectedOption: SideMenuTabs.Contact,
    },
    activeChatArea: {
      isChatBeingDragged: false,
      isAwaitingChatActivation: null,
      isAwaitingChatClose: null,
      lastChatCloseError: null,
      isAwaitingChatExportedToCrm: null,
      lastChatCrmExportError: null,
    },
    dashboard: {
      testingProp: '',
    },
    contactForm: {
      fields: {
        id: { value: '', error: undefined },
        fullName: { value: '', error: undefined },
        identificationNumber: { value: '', error: undefined },
        email: { value: '', error: undefined },
        phoneNumber: { value: '', error: undefined },
        mobileNumber: { value: '', error: undefined },
        address: { value: '', error: undefined },
        company: { value: '', error: undefined },
        city: { value: -1, error: undefined },
      },
      errors: 0,
      state: null,
    },
    latestStartChatRequest,
    isVisible,
  };
};

export default buildInitialAgentConsoleState;
