import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';
import { format } from 'date-fns';

export type TagsReportExportRequest = {
  dataEmail: {
    to: string;
    subject: string;
    body: string;
    untilNextTime: string;
    b2ChatTeam: string;
    b2ChatNote: string;
  };
  startDate?: Date | null;
  endDate?: Date | null;
  tags: (string | number | undefined)[];
  isContactTagReport: boolean;
  isContactWithoutTag: boolean;
  isContactCreatedAt: boolean;
  agentId?: number | string;
};

export const tagsReportExportApi = createApi({
  reducerPath: 'tagReportExport',
  baseQuery: b2chatBaseQuery,
  endpoints: ({ query }) => ({
    tagsReportExport: query<undefined, TagsReportExportRequest>({
      query: request =>
        apiResource(B2ChatClient.resources.contacts.actions.export, {
          data: {
            dataEmail: request.dataEmail,
            generic: null,
            dateRangeType: '0',
            timeframe: 'custom_timeframe',
            onlyBlacklisted: false,
            searchByLastUpdate: false,
            company: null,
            timeframeStartDate: request.startDate
              ? format(request.startDate, 'dd/MM/yyyy')
              : undefined,
            timeframeEndDate: request.endDate
              ? format(request.endDate, 'dd/MM/yyyy')
              : undefined,
            tagFilter: request.tags.length > 0 ? request.tags : undefined,
            isContactTagReport: request.isContactTagReport,
            agentId: request.agentId,
            isContactCreatedAt: request.isContactCreatedAt,
            isContactWithoutTag: request.isContactWithoutTag,
          },
        }),
    }),
  }),
});

export const { useLazyTagsReportExportQuery, useTagsReportExportQuery } =
  tagsReportExportApi;
