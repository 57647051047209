import {
  Merchant,
  ContactOrder,
  OrderItem,
  Order,
} from '../model/frontendmodel';
//import BackendProxy from'../components/AdminHome/CreateOrder/CreateOrderMock.js';
import BackendProxy from '../logic/backendproxy';

export const LOAD_PRODUCT_FIELD = 'CREATEORDER.LOAD_PRODUCT_FIELD';

export const START_LOADING_CONTACTS_LIST =
  'CREATEORDER.START_LOADING_CONTACTS_LIST';
export const OK_LOADED_CONTACTS_LIST = 'CREATEORDER.OK_LOADED_CONTACTS_LIST';
export const ERROR_LOADED_CONTACTS_LIST =
  'CREATEORDER.ERROR_LOADED_CONTACTS_LIST';

export const START_LOADING_PRODUCTS_LIST =
  'CREATEORDER.START_LOADING_PRODUCTS_LIST';
export const OK_LOADED_PRODUCTS_LIST = 'CREATEORDER.OK_LOADED_PRODUCTS_LIST';
export const ERROR_LOADED_PRODUCTS_LIST =
  'CREATEORDER.ERROR_LOADED_PRODUCTS_LIST';

export const UPDATE_CONTACT = 'CREATEORDER.UPDATE_CONTACT';
export const DELETE_CONTACT = 'CREATEORDER.DELETE_CONTACT';

export const UPDATING_PRODUCTS_IN_ORDER =
  'CREATEORDER.UPDATING_PRODUCTS_IN_ORDER';
export const UPDATING_CURRENT_REFERENCE_IN_CART =
  'CREATEORDER.UPDATING_CURRENT_REFERENCE_IN_CART';

export const START_CREATING_ORDER = 'CREATEORDER.START_CREATING_ORDER';
export const OK_CREATED_ORDER = 'CREATEORDER.OK_CREATED_ORDER';
export const ERROR_CREATING_ORDER = 'CREATEORDER.ERROR_CREATING_ORDER';

export const CLOSING_DIALOG = 'CREATEORDER.CLOSING_DIALOG';

//#################### Products Manager ##############
export function changeEmptyProductText(newValue) {
  return {
    type: LOAD_PRODUCT_FIELD,
    productValue: newValue,
  };
}

export function createOrder(products) {
  return (dispatch, getState) => {
    const merchant = new Merchant(
      getState().loginAuthentication.success.employer.name
    );
    const contact = new ContactOrder(getState().adminHome.createOrder.contact);

    const orderItems = [];
    let orderItem;

    dispatch(startCreatingOrder());

    for (let k = 0; k < products.length; k++) {
      orderItem = new OrderItem(products[k], products[k].quantitySelected);
      orderItems.push(orderItem);
    }
    const order = new Order(merchant, contact, orderItems);

    BackendProxy.createOrder(
      order,
      respData => {
        console.log('OK, Creating order: ', respData);
        const orderNumber = respData;

        setTimeout(() => {
          dispatch(okCreatedOrder(orderNumber));
        }, 1000);
      },
      (respStatus, error) => {
        console.log(`Error creating order! Status code: ${respStatus}`, error);
        dispatch(errorCreatingOrder(error));
      }
    );
  };
}
export function startCreatingOrder() {
  return {
    type: START_CREATING_ORDER,
  };
}
export function okCreatedOrder(orderNumber) {
  return {
    type: OK_CREATED_ORDER,
    orderNumber,
  };
}

export function errorCreatingOrder(error) {
  return {
    type: ERROR_CREATING_ORDER,
    cause: error,
  };
}

export function loadProducts() {
  return (dispatch, getState) => {
    dispatch(startLoadingProductsList());

    const merchantId = getState().loginAuthentication.success.employer.id;
    const merchantName = getState().loginAuthentication.success.employer.name;

    BackendProxy.getProducts(
      merchantId,
      merchantName,
      respData => {
        console.log('OK, loadProducts list >>>: ', respData);
        const products = respData.lstPagedItems[0].listReferences;
        getProductsByPagedItems(respData.lstPagedItems);

        setTimeout(() => {
          dispatch(okLoadedProductsList(products));
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log('Error Getting products! Status code: ', respStatus, error);
        dispatch(errorLoadedProductsList(error));
      }
    );
  };
}

function getProductsByPagedItems(lstPagedItems) {
  const products = [];
  for (let a = 0; a < lstPagedItems.length; a++) {
    products.push(lstPagedItems[a].listReferences);
  }
  console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>products ', products);
  return products;
}

export function startLoadingProductsList() {
  return {
    type: START_LOADING_PRODUCTS_LIST,
  };
}

export function okLoadedProductsList(results) {
  return {
    type: OK_LOADED_PRODUCTS_LIST,
    results,
  };
}
export function errorLoadedProductsList(error) {
  return {
    type: ERROR_LOADED_PRODUCTS_LIST,
    message: error,
  };
}

//#################### Contacts Manager ##############
export function loadContacts() {
  return (dispatch, getState) => {
    dispatch(startLoadingContactsList());

    const merchantId = getState().loginAuthentication.success.employer.id;

    BackendProxy.getContacts(
      merchantId,
      respData => {
        console.log('OK, Contacts list: ', respData);
        const contacts = respData;

        setTimeout(() => {
          dispatch(okLoadedContactsList(contacts));
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        dispatch(errorLoadedContactsList(error));
      }
    );
  };
}

export function startLoadingContactsList() {
  return {
    type: START_LOADING_CONTACTS_LIST,
  };
}

export function okLoadedContactsList(results) {
  return {
    type: OK_LOADED_CONTACTS_LIST,
    results,
  };
}
export function errorLoadedContactsList(error) {
  return {
    type: ERROR_LOADED_CONTACTS_LIST,
    message: error,
  };
}

export function updateContact(contact, contactValue) {
  return {
    type: UPDATE_CONTACT,
    id: contact.id,
    fullName: contact.fullName,
    identificationNumber: contact.identificationNumber,
    phoneNumber: contact.phoneNumber,
    mobileNumber: contact.mobileNumber,
    email: contact.email,
    city: contact.city,
    address: contact.address,
    contactValue,
  };
}

export function deleteContact() {
  return {
    type: DELETE_CONTACT,
    id: '',
    fullName: '',
    identificationNumber: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    city: '',
    address: '',
    contactValue: '',
  };
}

export function addProductToOrder(product, count) {
  return (dispatch, getState) => {
    const addedProducts = getState().adminHome.createOrder.cart.products;

    let productWasAdded = false;
    if (addedProducts !== null && addedProducts.length > 0) {
      for (let i = 0; i < addedProducts.length; i++) {
        if (addedProducts[i].skuReference === product.skuReference) {
          productWasAdded = true;
        }
      }
    }
    if (!productWasAdded) {
      addedProducts.push(calculateProductPrice(product, count));
    }
    dispatch(updatingOrder(addedProducts));
  };
}

export function updatingProductInOrder(product, count) {
  return (dispatch, getState) => {
    const addedProducts = getState().adminHome.createOrder.cart.products;
    const newProductsItems = [];

    if (addedProducts !== null && addedProducts.length > 0) {
      for (let i = 0; i < addedProducts.length; i++) {
        if (addedProducts[i].skuReference === product.skuReference) {
          newProductsItems.push(calculateProductPrice(product, count));
        } else {
          newProductsItems.push(addedProducts[i]);
        }
      }
    }

    dispatch(updatingOrder(newProductsItems));
    dispatch(setCurReferenceInCart(product.skuReference));
  };
}

export function calculateProductPrice(product, count) {
  const quantitySelected = count;
  const unitValue = product.price;
  const priceByQuantitySelected = quantitySelected * unitValue;

  product.quantitySelected = quantitySelected;
  product.priceByQuantitySelected = priceByQuantitySelected;

  return product;
}

export function updatingOrder(products) {
  return {
    type: UPDATING_PRODUCTS_IN_ORDER,
    results: products,
    productValue: '',
  };
}
export function setCurReferenceInCart(sku) {
  return {
    type: UPDATING_CURRENT_REFERENCE_IN_CART,
    reference: sku,
  };
}

export function deleteProductToOrder(skuReference) {
  return (dispatch, getState) => {
    const addedProducts = getState().adminHome.createOrder.cart.products;
    const newProductsItems = [];

    if (addedProducts !== null && addedProducts.length > 0) {
      for (let i = 0; i < addedProducts.length; i++) {
        if (addedProducts[i].skuReference !== skuReference) {
          newProductsItems.push(addedProducts[i]);
        }
      }
    }
    dispatch(updatingOrder(newProductsItems));
  };
}

export function clickCloseDialog() {
  return {
    type: CLOSING_DIALOG,
  };
}
