import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ErrorPayload, isErrorPayload } from '../services/service';
import {
  BroadcastError,
  validateCampaignPOST,
} from '../services/whatsappBroadcast.service';

export type ValidateCampaignNameState = {
  loading: 'idle' | 'pending';
  names: string[];
  error?: ErrorPayload | BroadcastError;
  count: number;
};

const initialState: ValidateCampaignNameState = {
  loading: 'idle',
  count: 0,
  names: [],
  error: undefined,
};

export const fetchValidateCampaignName = createAsyncThunk(
  'whatsapp-broadcast/fetchValidateCampaignName',
  async (campaign: string, { signal, rejectWithValue }) => {
    try {
      return await validateCampaignPOST(campaign, signal);
    } catch (error) {
      if (isErrorPayload(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  }
);

export const validateCampaignNameSlice = createSlice({
  name: 'validate-campaign',
  initialState,
  reducers: {
    clearCampaignNameError(state) {
      state.error = undefined;
      state.loading = 'idle';
    },
  },
  extraReducers({ addCase }) {
    const { pending, fulfilled, rejected } = fetchValidateCampaignName;
    addCase(pending, state => {
      state.loading = 'pending';
      state.error = undefined;
      state.count += 1;
    });
    addCase(fulfilled, state => {
      state.loading = 'idle';
      state.error = undefined;
    });
    addCase(rejected, (state, action) => {
      state.loading = 'idle';
      state.error = (action.payload as ErrorPayload) || {
        details: action.error.message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: action.meta.requestId,
      };
    });
  },
});

const { reducer } = validateCampaignNameSlice;
export const { clearCampaignNameError } = validateCampaignNameSlice.actions;

export default reducer;
