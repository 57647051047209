// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: migrate this file to typescript
import { GOT_GENERAL_PROPERTIES } from '../actions/common';

const generalProperties = (state = {}, action) => {
  switch (action.type) {
    case GOT_GENERAL_PROPERTIES:
      return { ...state, ...action.props };
    //    case LOGIN_AUTHENTICATION_FAIL:
    //      return Object.assign({}, state,{failure: action.data,status:false});
    default:
      return state;
  }
};

export default generalProperties;
