//import BackendProxy from'../components/AdminHome/OrderDetail/OrderDetailMock.js';
import BackendProxy from '../logic/backendproxy';

export const START_LOADING_ORDER_DETAIL =
  'ORDER_DETAIL.START_LOADING_ORDER_DETAIL';
export const OK_LOADING_ORDER_DETAIL = 'ORDER_DETAIL.OK_LOADING_ORDER_DETAIL';
export const ERROR_LOADED_ORDER_DETAIL =
  'ORDER_DETAIL.ERROR_LOADED_ORDER_DETAIL';

export const OK_LOADING_ORDER_STATES = 'ORDER_DETAIL.OK_LOADING_ORDER_STATES';
export const ERROR_LOADED_ORDER_STATES =
  'ORDER_DETAIL.ERROR_LOADED_ORDER_STATES';

export const OK_UPDATE_ORDER_STATE = 'ORDER_DETAIL.OK_UPDATE_ORDER_STATE';
export const ERROR_UPDATE_ORDER_STATE = 'ORDER_DETAIL.ERROR_UPDATE_ORDER_STATE';

export const OK_LOADING_MAP_URL = 'ORDER_DETAIL.OK_LOADING_MAP_URL';

export function changeMapUrl() {
  return (dispatch, getState) => {
    const { order } = getState().adminHome.orderDetail;
    const { contactOrder } = order;

    const googleMapsClient = require('@google/maps').createClient({
      key: 'AIzaSyC8Zf5rZpLFNNEtngyJaF_G2s3LjTs3oPQ',
    });

    let addressUrl = null;
    // Geocode an address.
    googleMapsClient.geocode(
      {
        address: `${contactOrder.address} ${
          contactOrder.city != null ? contactOrder.city.name : ''
        } ${
          contactOrder.city != null && contactOrder.city.province
            ? contactOrder.city.province.name
            : ''
        }`, // '1600 Amphitheatre Parkway, Mountain View, CA'
      },
      (err, response) => {
        if (!err) {
          console.log(response.json.results);
          if (response.json.status === 'OK') {
            const { lat } = response.json.results[0].geometry.location;
            const lon = response.json.results[0].geometry.location.lng;
            addressUrl = `https://www.google.it/maps/dir/${lat},${lon}/@${lat},${lon},17z/`;
          } else {
            addressUrl = 'https://maps.google.com/';
          }
        } else {
          addressUrl = 'https://maps.google.com/';
        }
        dispatch(okLoadedMapUrl(addressUrl));
      }
    );
  };
}

export function changeOrderState(newOrderState) {
  return (dispatch, getState) => {
    const { orderNumber } = getState().adminHome.orderDetail;
    const merchantName = getState().loginAuthentication.success.employer.name;
    const merchantId = getState().loginAuthentication.success.employer.id;
    newOrderState.orderNumber = orderNumber;
    newOrderState.merchantName = merchantName;
    newOrderState.merchantId = merchantId;

    BackendProxy.updateOrderState(
      newOrderState,
      respData => {
        console.log('OK, Change order state: ', respData);
        const orderResults = respData;
        setTimeout(() => {
          dispatch(updateOrderState(orderResults.state));
          const { order } = getState().adminHome.orderDetail;
          let toAdd = true;
          const { listOrderStateLogs } = order;
          for (let i = 0; i < listOrderStateLogs.length; i++) {
            const orderStateLog = listOrderStateLogs[i];
            if (orderResults.changeAt === orderStateLog.changeAt) {
              toAdd = false;
              break;
            }
          }
          if (toAdd) {
            listOrderStateLogs.push(orderResults);
            order.state = orderResults.state;
            order.listOrderStateLogs = listOrderStateLogs;
            dispatch(okLoadedOrderDetail(order));
          }
          const merchantName =
            getState().loginAuthentication.success.employer.name;
          const merchantId = getState().loginAuthentication.success.employer.id;
          const filters = {};
          filters.merchantId = merchantId;
          filters.merchantName = merchantName;
          filters.orderNumber = getState().adminHome.orderDetail.orderNumber;
          BackendProxy.getAllOrderStates(
            filters,
            respData => {
              console.log('OK, getOrderStates: ', respData);
              const orderStates = respData;
              console.log('.------------------', orderStates);
              setTimeout(() => {
                dispatch(okLoadedOrderStates(orderStates));
              }, 1000);
            },
            (respStatus, error) => {
              // Handle errors that might occur while uploading media
              console.log(
                `Error Getting States! Status code: ${respStatus}`,
                error
              );
              dispatch(errorLoadedOrderStates(error));
            }
          );
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting Updating state Orders! Status code: ${respStatus}`,
          error
        );
        dispatch(errorUpdateOrderState(error));
      }
    );
  };
}

export function cancelOrder() {
  return (dispatch, getState) => {
    const data = {};
    const { orderNumber } = getState().adminHome.orderDetail;
    const merchantName = getState().loginAuthentication.success.employer.name;
    data.orderNumber = orderNumber;
    data.merchantName = merchantName;
    BackendProxy.cancelOrderState(
      data,
      respData => {
        console.log('OK, Change order state: ', respData);
        const orderResults = respData;
        setTimeout(() => {
          console.log('updateOrderState -----------', orderResults);
          dispatch(updateOrderState(orderResults.state));
          const { order } = getState().adminHome.orderDetail;
          let toAdd = true;
          const { listOrderStateLogs } = order;
          for (let i = 0; i < listOrderStateLogs.length; i++) {
            const orderStateLog = listOrderStateLogs[i];
            if (orderResults.changeAt === orderStateLog.changeAt) {
              toAdd = false;
              break;
            }
          }
          if (toAdd) {
            console.log('asdddd ----------- toAdd');
            listOrderStateLogs.push(orderResults);
            order.state = orderResults.state;
            order.listOrderStateLogs = listOrderStateLogs;
            dispatch(okLoadedOrderDetail(order));
          }
          const merchantName =
            getState().loginAuthentication.success.employer.name;
          const merchantId = getState().loginAuthentication.success.employer.id;
          const filters = {};
          filters.merchantId = merchantId;
          filters.merchantName = merchantName;
          filters.orderNumber = getState().adminHome.orderDetail.orderNumber;

          BackendProxy.getAllOrderStates(
            filters,
            respData => {
              console.log('OK, getOrderStates: ', respData);
              const orderStates = respData;
              console.log('.------------------', orderStates);
              setTimeout(() => {
                dispatch(okLoadedOrderStates(orderStates));
              }, 1000);
            },
            (respStatus, error) => {
              // Handle errors that might occur while uploading media
              console.log(
                `Error Getting States! Status code: ${respStatus}`,
                error
              );
              dispatch(errorLoadedOrderStates(error));
            }
          );
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting Updating state Orders! Status code: ${respStatus}`,
          error
        );
        dispatch(errorUpdateOrderState(error));
      }
    );
  };
}

export function loadOrderbyOrderNumber(orderNumber) {
  return (dispatch, getState) => {
    console.log('orderNumber', orderNumber);
    dispatch(startLoadingOrderDetail(orderNumber));
    commonLoadOrderDetail(dispatch, getState);
  };
}

export function startLoadingOrderDetail(orderNumber) {
  return {
    type: START_LOADING_ORDER_DETAIL,
    orderNumber,
  };
}

function commonLoadOrderDetail(dispatch, getState) {
  const merchantName = getState().loginAuthentication.success.employer.name;
  const merchantId = getState().loginAuthentication.success.employer.id;
  const filters = {};
  filters.merchantId = merchantId;
  filters.merchantName = merchantName;
  filters.orderNumber = getState().adminHome.orderDetail.orderNumber;

  BackendProxy.getOrderDetail(
    filters,
    respData => {
      console.log('OK, Order list: ', respData);
      const orderResults = respData;
      setTimeout(() => {
        dispatch(okLoadedOrderDetail(orderResults));
      }, 1000);
    },
    (respStatus, error) => {
      // Handle errors that might occur while uploading media
      console.log(`Error Getting Orders! Status code: ${respStatus}`, error);
      dispatch(errorLoadedOrderDetail(error));
    }
  );
  BackendProxy.getAllOrderStates(
    filters,
    respData => {
      console.log('OK, getOrderStates: ', respData);
      const orderStates = respData;
      setTimeout(() => {
        dispatch(okLoadedOrderStates(orderStates));
      }, 1000);
    },
    (respStatus, error) => {
      // Handle errors that might occur while uploading media
      console.log(`Error Getting States! Status code: ${respStatus}`, error);
      dispatch(errorLoadedOrderStates(error));
    }
  );
}

export function okLoadedOrderDetail(order) {
  return {
    type: OK_LOADING_ORDER_DETAIL,
    order,
  };
}

export function okLoadedMapUrl(mapUrl) {
  return {
    type: OK_LOADING_MAP_URL,
    mapUrl,
  };
}

export function errorLoadedOrderDetail(error) {
  return {
    type: ERROR_LOADED_ORDER_DETAIL,
    message: error,
  };
}

export function okLoadedOrderStates(orderStates) {
  return {
    type: OK_LOADING_ORDER_STATES,
    orderStates,
  };
}

export function errorLoadedOrderStates(error) {
  return {
    type: ERROR_LOADED_ORDER_STATES,
    message: error,
  };
}

export function updateOrderState(orderState) {
  return {
    type: OK_UPDATE_ORDER_STATE,
    orderState,
  };
}

export function errorUpdateOrderState(error) {
  return {
    type: ERROR_UPDATE_ORDER_STATE,
    message: error,
  };
}
