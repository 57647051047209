import { BotAccount, LoginSuccess, RootState, Selector } from '@types';

type Item = {
  key: number;
  text: string;
  value: number;
  disabled: boolean;
  accountType: string;
};

export const getUserWAAccount: Selector<Item[]> = state => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = state.adminHome?.userWaAccounts?.whatsAppAccounts || [];
  return data
    .map((item: BotAccount) => ({
      key: item.id,
      text: item.identifier || item.alias,
      value: item.id,
      disabled: !item.identifier,
      accountType: item.accountType || 'all',
    }))
    .sort((l: Item, r: Item) => (l.disabled > r.disabled ? 1 : -1));
};

export const getUserId: Selector<number> = state =>
  state.loginAuthentication.success?.id || -1;

export const isLoggedInSelect: Selector<boolean> = state =>
  !!state.loginAuthentication.success;

export const userState: Selector<boolean | undefined> = state =>
  state.user.userState?.online;

export const selectAuthenticationInfo = (state: RootState) =>
  state.loginAuthentication.success as LoginSuccess;

export const isShowingTrial = (state: RootState) => {
  const merchantState = state.loginAuthentication.success?.employer.state;
  return merchantState === 'TRIAL' || merchantState === 'LOSTTRIAL';
};

export const userLogged = (state: RootState) =>
  state.loginAuthentication.success;

export const getMerchantId: Selector<number> = (state: RootState) =>
  state.loginAuthentication.success?.employer?.id;

export const getMerchantName: Selector<string> = (state: RootState) =>
  state.loginAuthentication?.success?.employer?.name;

export const getLoggedUserEmail = (state: RootState) =>
  state.loginAuthentication.success?.email;

export default getUserWAAccount;
