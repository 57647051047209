import B2ChatClient from '@client-sdk';
import {
  fetchUploadFiles,
  fetchUploadFilesFailure,
  fetchUploadFilesFulfill,
  fetchUploadFilesSuccess,
} from '@reducers/uploadFiles';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchUploadFilesSaga(action: ReturnType<typeof fetchUploadFiles>) {
  const { files, prefix, fieldName } = action.payload;
  const formData = new FormData();
  files.forEach(file => formData.append('file', file));

  try {
    const response = (yield call(
      [B2ChatClient.resources.files.actions, 'uploadFiles'],
      {
        data: formData,
        params: { prefix: prefix || '' },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.Files.UploadFiles>;

    if (response.error != null) {
      yield put(fetchUploadFilesFailure(response.error));
    } else {
      const data = [
        {
          ...response.data[0],
          fieldName,
        },
      ];

      yield put(fetchUploadFilesSuccess(data));
    }
  } catch (error) {
    yield put(fetchUploadFilesFailure(error as ErrorData));
  } finally {
    yield put(fetchUploadFilesFulfill());
  }
}

function* UploadFilesSaga() {
  yield takeLatest(fetchUploadFiles, fetchUploadFilesSaga);
}

export default UploadFilesSaga;
