export const OPEN_EMOTICON_SELECTOR0 = 'FAQS.OPEN_EMOTICON_SELECTOR0';
export const OPEN_EMOTICON_SELECTOR1 = 'FAQS.OPEN_EMOTICON_SELECTOR1';
export const OPEN_EMOTICON_SELECTOR2 = 'FAQS.OPEN_EMOTICON_SELECTOR2';
export const OPEN_EMOTICON_SELECTOR3 = 'FAQS.OPEN_EMOTICON_SELECTOR3';

export const CLOSE_EMOTICON_SELECTOR0 = 'FAQS.CLOSE_EMOTICON_SELECTOR0';
export const CLOSE_EMOTICON_SELECTOR1 = 'FAQS.CLOSE_EMOTICON_SELECTOR1';
export const CLOSE_EMOTICON_SELECTOR2 = 'FAQS.CLOSE_EMOTICON_SELECTOR2';
export const CLOSE_EMOTICON_SELECTOR3 = 'FAQS.CLOSE_EMOTICON_SELECTOR3';

/* bloque de accciones para abrir la caja de emoticones de cada area de texto
   de los 4 primeros custom msg
*/

export function openEmoticonSelector0(anchorEl) {
  return {
    type: OPEN_EMOTICON_SELECTOR0,
    anchorEl,
  };
}

export function openEmoticonSelector1(anchorEl) {
  return {
    type: OPEN_EMOTICON_SELECTOR1,
    anchorEl,
  };
}

export function openEmoticonSelector2(anchorEl) {
  return {
    type: OPEN_EMOTICON_SELECTOR2,
    anchorEl,
  };
}

export function openEmoticonSelector3(anchorEl) {
  return {
    type: OPEN_EMOTICON_SELECTOR3,
    anchorEl,
  };
}

/* bloque de accciones para cerrar la caja de emoticones de cada area de texto
   de los 4 primeros custom msg
*/

export function closeEmoticonSelector0() {
  return {
    type: CLOSE_EMOTICON_SELECTOR0,
  };
}

export function closeEmoticonSelector1() {
  return {
    type: CLOSE_EMOTICON_SELECTOR1,
  };
}

export function closeEmoticonSelector2() {
  return {
    type: CLOSE_EMOTICON_SELECTOR2,
  };
}

export function closeEmoticonSelector3() {
  return {
    type: CLOSE_EMOTICON_SELECTOR3,
  };
}
