import { PayloadAction } from '@reduxjs/toolkit';
import type {
  CredentialType,
  FetchOrdersHistoryType,
  ShopifyCollection,
  ShopifyConfigStores,
  ShopifyCredential,
  ShopifyInventory,
  ShopifyLocation,
  ShopifyProduct,
  ShopifyProductOrigin,
  ShopifyShop,
  ShopifyTag,
  ShopifyVendor,
  ShoppingCart,
  ShoppingCartOrder,
  ShoppingCartOrderUrl,
  UpdateCredentialStatus,
} from '@src/types/shopify';

// eslint-disable-next-line no-shadow
export const enum FilterKeys {
  COLLECTIONS = 'collection',
  TAGS = 'tags',
  PRICES = 'prices',
  VENDOR = 'vendor',
  SHOW_ONLY_AVAILABLE_PRODUCTS = 'showOnlyAvailableProducts',
}

export const LabelShowOnlyAvailableProducts =
  'Shopify.Filter.showOnlyAvaialableProducts.text';

export type Loading =
  | 'fetch-shopify-credentials'
  | 'save-shopify-store-credentials'
  | 'add-shopify-store-credentials'
  | 'fetch-shopify-collections'
  | 'delete-shopify-store-credentials'
  | 'set-shopify-credentials'
  | 'update-shopify-credentials'
  | 'fetch-shopify-inventory'
  | 'fetch-shopify-shop'
  | 'fetch-shopify-single-product'
  | 'fetch-shopify-shopping-cart'
  | 'update-product-shoppint-cart'
  | 'add-shopping-cart'
  | 'remove-product-from-shopping-cart'
  | 'fetch-orders-history'
  | 'fetch-order-history-by-id';

export type FilterParamsItemType = 'checkbox' | 'range' | 'radio';

export type FilterParamsItem = {
  type: FilterParamsItemType;
  label: string;
  checked?: boolean;
  value?: number[];
  min?: number;
  step?: number;
  max?: number;
  id?: number;
};

export type FilterParams = {
  key: string;
  items: FilterParamsItem[];
};

export type OrderByParam = 'TITLE' | 'CREATED_AT&reverse' | 'CREATED_AT';

export type ShopifyLocationState = {
  currentLocation: number | null;
  locations: ShopifyLocation[];
};

export type ShopifyCredentialsState = Omit<
  ShopifyCredential,
  'id' | 'merchantId' | 'type'
>;

export type ShopifyState = {
  error?: unknown;
  productDetailError?: unknown;
  loading: Loading[];
  shop: ShopifyShop | undefined;
  credentials: ShopifyCredentialsState | undefined;
  configStores: ShopifyConfigStores | undefined;
  updateCredentialStatus: UpdateCredentialStatus;
  updatingCredentials: boolean;
  alreadyHasStores: boolean;
  products: ShopifyProduct[];
  productDetail: ShopifyProduct | undefined;
  collections: ShopifyCollection[];
  locations: ShopifyLocationState;
  inventory: ShopifyInventory;
  vendors: ShopifyVendor[];
  tags: ShopifyTag[];
  selectedProductId: number | undefined;
  currentTabView: ShopifyTabsView;
  selectedVariantArrId: number[];
  /* Represent an array where the index is the variant option index, and the value is the option value.
  Shopify product ->
  "options": [
    {
      "name": "cantidad",
      "position": 1,
      "values": ["500 gramos", "100 gramos", "250 gramos", "1000 gramos"]
    },
    {
      "name": "Corte",
      "position": 2,
      "values": ["lomo", "solomo", "posta", "entre pierna"]
    }
  ]

  selectedVariantArrId = [0,3] this represent the variant "500 gramos / entre pierna"
  selectedVariantArrId = [2,1] this represent the variant "250 gramos / solomo"
*/
  selectedLocationId: number | undefined;
  selectedStoreId: string | undefined;
  currentCarouselImage: { [key: string]: string };
  previousSelectedLocationId: number | undefined;
  searchPattern: string;
  filterParams: FilterParams[];
  filterParamsEditing: FilterParams[];
  showOnlyAvailableProducts: boolean;
  orderByParam: {
    sort: OrderByParam;
    reverse: boolean;
  };
  shoppingCart: {
    [contactId: string]: ShoppingCart;
  };
  shoppingCartItem: UpdateProductToShoppingCartPayload | undefined;
  ordersHistory: ShoppingCartOrder[];
  ordersHistoryPageInfo?: PageInfo;
  orderUrl: ShoppingCartOrderUrl | undefined;
  orderInputValue: FetchOrdersHistoryType;
  tryToRetrieveOnlyAvailableProducts: boolean;
};

export type ShopifyReducer<P = void> = (
  state: ShopifyState,
  action: PayloadAction<P>
) => void;

export type HandleSetShopifyCredentials = {
  config: {
    storeUrl: string;
    token: string;
    tokenStoreFront: string;
    showAvailabilityFilter: boolean;
  };
  id: number;
  merchantId: number;
  type: CredentialType;
};

export interface HandleUpdateCheckboxFilter {
  key: string;
  label: string;
  checked: boolean;
  type?: FilterParamsItemType;
}

export type UpdateProductToShoppingCartPayload = {
  variantId: string;
  quantity: number;
  cartLinesId: string[];
  origin: ShopifyProductOrigin;
};

// eslint-disable-next-line no-shadow
export const enum ShopifyTabsView {
  PRODUCT_LIST = 'product_list',
  PRODUCT_DETAILS = 'product_details',
  SHOPPING_CART = 'shopping_cart',
  ORDERS_HISTORY = 'orders_history',
}

// eslint-disable-next-line no-shadow
export const enum ShopifyOrderInputValue {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export type PageInfo = {
  startCursor: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  endCursor: string;
};
