/* eslint-disable */
// @ts-nocheck
import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-cycle
import {
  ACCEPT_ASSIGNED_CHAT_REQUEST,
  ACK_LAST_CHAT_CLOSE_ERROR,
  ACK_LAST_CHAT_CRM_EXPORT_ERROR,
  ACTIVE_CHAT_CLOSED_BY_AGENT,
  AGENT_CHAT_PICKUP_FAIL,
  AGENT_STARTED_CHAT_FAIL,
  AGENT_STARTED_CHAT_OK,
  AGENT_STARTED_CHAT_RESET,
  AGENT_START_CHAT_REQUESTED,
  AWAIT_AGENT_CHAT_ACTIVATION,
  AWAIT_AGENT_CHAT_CLOSE,
  AWAIT_AGENT_CHAT_EXPORTED_TO_CRM,
  AWAIT_AGENT_STARTED_CHAT,
  CHANGE_CONTACT_FORM_FIELD,
  CHANGE_CUSTOM_CONTACT_FORM_FIELD,
  CHAT_CLOSE_FAIL,
  CHAT_CLOSE_OK,
  CHAT_EXPORT_CRM_FAIL,
  CHAT_EXPORT_CRM_OK,
  CHAT_REQUEST_PICKED_UP_BY_PEER,
  CLOSE_EMOTICON_SELECTOR,
  CONNECTED_TO_BACKEND,
  DISCONNECTED_FROM_BACKEND,
  FAILED_TO_PICKUP_ALREADY_ASSIGNED_CHAT,
  LOADED_CHAT_HISTORY_FAILED,
  LOADED_CHAT_HISTORY_OK,
  LOADING_CHAT_HISTORY,
  LOAD_CONTACT_FORM,
  NEW_AGENT_CHAT_FOR_AGENT,
  OPEN_EMOTICON_SELECTOR,
  RESET_AGENT_CONSOLE_STATE,
  SAVE_CONTACT_FAIL,
  SAVE_CONTACT_OK,
  SAVE_CONTACT_REQUEST_INVALID,
  SELECT_ACTIVE_QUEUE,
  SELECT_CHAT_CONTEXT_MENU_OPTION,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE_OK,
  SET_ACTIVE_CHAT,
  SET_ACTIVE_CHAT_FAIL,
  SET_VISIBILITY,
  START_DRAGGING_CHAT,
  START_SAVING_CONTACT,
  START_SENDING_MESSAGE,
  STOP_DRAGGING_CHAT,
  TOGGLE_CHAT_CONTEXT_MENU,
  UNSET_AGENTCHAT_TRANSFER,
  UPDATE_CONTACT_FORM,
} from '../actions/agentconsole';
import { LOGGING_OUT, LOG_OUT, LOG_OUT_FAIL } from '../actions/common';
import AgentChatManager from '../logic/agentchatmanager';
import ContactForm from '../logic/contactform';
import chatSearch from './chatSearch';
import contact from './contactRToolkit';
import shopify from './shopify';

const queuesReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case SELECT_ACTIVE_QUEUE:
      return {
        ...state,
        items: state.items.map((val, ix, arr) => ({
          ...val,
          isSelected: val.id === action.selectedQueueId,
        })),
      };
    default:
      return state;
  }
};

// Child reducer. Parent: activeChatReducer. Handles: activeChat.messageComposing
const messageComposingReducer = (
  state = {
    writtenMsgsCache: {},
    isSendingMessage: false,
    errorSendingMessage: null,
  },
  action
) => {
  let newWrittenMsgsCache = null;

  let {
    writtenMsgsCache: {},
    isSendingMessage,
    errorSendingMessage,
  } = state;

  switch (action.type) {
    case START_SENDING_MESSAGE:
      return {
        ...state,
        isSendingMessage: true,
        errorSendingMessage: null,
      };

    case SEND_MESSAGE_OK:
      return { ...state, isSendingMessage: false, errorSendingMessage: null };

    case SEND_MESSAGE_FAIL:
      return {
        ...state,
        isSendingMessage: false,
        errorSendingMessage: action.error,
      };

    case CHAT_CLOSE_OK:
    case ACTIVE_CHAT_CLOSED_BY_AGENT:
      // Delete text from the compose message box when the agent closes the chat
      newWrittenMsgsCache = { ...state.writtenMsgsCache };
      delete newWrittenMsgsCache[action.chat.id];

      return { ...state, writtenMsgsCache: newWrittenMsgsCache };

    case SET_ACTIVE_CHAT:
      // Ensure that the message composer field will be editable anytime a chat is activated
      return { ...state, isSendingMessage: false };

    default:
      return state;
  }
};

const Break = Symbol('Break');

const currentContactChatHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_CHAT:
      // Reset chat history anytime the active chat is switched
      return {
        messagringAccountId: null,
        closedChats: [],
        chatbotHistory: null,
        chatbotHistoryError: null,
        awaitingRequestId: null,
      };

    case LOADING_CHAT_HISTORY:
      // Initiate load chat history request
      const updates = { awaitingRequestId: action.requestId };

      if (action.clearHistory) {
        // Clear the currently loaded history
        updates.closedChats = null;
      }

      return { ...state, ...updates };

    case LOADED_CHAT_HISTORY_OK:
      // Ensure that the history loaded matches the last request. This is to prevent
      // potential issues caused by users switching the active chat between submission of the
      // load chat history request and reception of the response
      if (action.requestId === state.awaitingRequestId) {
        // Append the chunk of history just loaded to the existing history (sorted from oldest to newest)
        let joinedClosedChats = action.chatHistory.closedChats;

        if (state.closedChats) {
          joinedClosedChats = [
            ...action.chatHistory.closedChats,
            ...state.closedChats,
          ];
        }

        return {
          closedChats: joinedClosedChats,
          chatbotHistory: action.chatHistory.chatbotHistory,
          startOfHistory: action.chatHistory.startOfHistory,
          gotMsgFromContactInPast24h:
            action.chatHistory.gotMsgFromContactInPast24h,
          chatbotHistoryError: null,
          awaitingRequestId: null,
        };
      }

      // The history just gotten does not correspond to the last load history request. Discard
      return state;

    case LOADED_CHAT_HISTORY_FAILED:
      // An error occurred while attempting to load chat history for the contact messaging account of the current chat
      return {
        closedChats: null,
        chatbotHistory: null,
        chatbotHistoryError: {
          errorMsg: action.errorMsg,
          errorResp: action.errorResp,
          timestamp: action.timestamp,
        },
        awaitingRequestId: null,
      };

    case CHAT_CLOSE_OK:
    case ACTIVE_CHAT_CLOSED_BY_AGENT:
      // Reset chat history as there's no longer an active chat
      return {
        messagingAccountId: null,
        closedChats: [],
        chatbotHistory: null,
        chatbotHistoryError: null,
        awaitingRequestId: null,
      };

    case UNSET_AGENTCHAT_TRANSFER:
      if (action.canRemove) {
        return {
          messagingAccountId: null,
          closedChats: [],
          chatbotHistory: null,
          chatbotHistoryError: null,
          awaitingRequestId: null,
        };
      }

      return state;

    default:
      return state;
  }
};

const chatContextMenuReducer = (
  state = { isOpened: true, selectedOption: 1 },
  action
) => {
  switch (action.type) {
    case TOGGLE_CHAT_CONTEXT_MENU:
      return { ...state, isOpened: action.opened };

    case SELECT_CHAT_CONTEXT_MENU_OPTION:
      return { ...state, selectedOption: action.selectedOption };

    default:
      return state;
  }
};

const activeChatAreaReducer = (
  state = { isChatBeingDragged: false },
  action
) => {
  switch (action.type) {
    case START_DRAGGING_CHAT:
      return { ...state, isChatBeingDragged: true };

    case STOP_DRAGGING_CHAT:
      return { ...state, isChatBeingDragged: false };

    case AWAIT_AGENT_CHAT_ACTIVATION:
      return { ...state, isAwaitingChatActivation: action.chatId };

    case AWAIT_AGENT_CHAT_CLOSE:
      return {
        ...state,
        isAwaitingChatClose: action.chatId,
        lastChatCloseError: null,
      };

    case AWAIT_AGENT_CHAT_EXPORTED_TO_CRM:
      return { ...state, isAwaitingChatExportedToCrm: action.chatId };

    case CHAT_EXPORT_CRM_OK:
      return { ...state, isAwaitingChatExportedToCrm: null };

    case CHAT_EXPORT_CRM_FAIL:
      return {
        ...state,
        isAwaitingChatExportedToCrm: null,
        lastChatCrmExportError: {
          errorMessage: action.errorMessage,
          errorDetails: action.errorDetails,
        },
      };

    case ACCEPT_ASSIGNED_CHAT_REQUEST:
      return { ...state, isAwaitingChatPickup: null };

    case CHAT_REQUEST_PICKED_UP_BY_PEER:
    case FAILED_TO_PICKUP_ALREADY_ASSIGNED_CHAT:
      return { ...state, isAwaitingChatPickup: null };

    case AGENT_CHAT_PICKUP_FAIL:
      return { ...state, isAwaitingChatPickup: null };

    case SET_ACTIVE_CHAT:
      return { ...state, isAwaitingChatActivation: null };

    case SET_ACTIVE_CHAT_FAIL:
      return { ...state, isAwaitingChatActivation: null };

    case CHAT_CLOSE_OK:
      return { ...state, isAwaitingChatClose: null };

    case CHAT_CLOSE_FAIL:
      return {
        ...state,
        isAwaitingChatClose: null,
        lastChatCloseError: {
          errorType: action.errorType,
          errorDetails: action.errorDetails,
        },
      };

    case ACK_LAST_CHAT_CLOSE_ERROR:
      return { ...state, lastChatCloseError: null };

    case ACK_LAST_CHAT_CRM_EXPORT_ERROR:
      return { ...state, lastChatCrmExportError: null };

    default:
      return state;
  }
};

const dashboardReducer = (state = { testingProp: 'Hola' }, action) => {
  switch (action.type) {
    case 'ABC':
      return { ...state, testingProp: action.prop };
    default:
      return { ...state, testingProp: 'default property' };
  }
};

const emoticonSelectorReducer = (
  state = { open: false, anchorEl: null },
  action
) => {
  switch (action.type) {
    case OPEN_EMOTICON_SELECTOR:
      return { ...state, open: true, anchorEl: action.anchorEl };
    case CLOSE_EMOTICON_SELECTOR:
      return { ...state, open: false };
    default:
      return state;
  }
};

const backendConnReducer = (
  state = { isLoaded: false, isConnected: false },
  action
) => {
  switch (action.type) {
    case LOGGING_OUT:
      return { ...state, isLoggingOut: true };

    case LOG_OUT:
    case LOG_OUT_FAIL:
      return { ...state, isLoggingOut: true };

    case CONNECTED_TO_BACKEND:
      return { ...state, isConnected: true, wasConnectionLost: false };

    case DISCONNECTED_FROM_BACKEND:
      let wasConnectionLost = false;
      let closeEvent = null;

      // Determine whether the disconnection occurred after a connection with the backend was established,
      // which normally means that the login session expired and was not caused by the user choosing to logout
      if (!state.isLoggingOut) {
        wasConnectionLost = true;
        closeEvent = action.closeEvent;
      }

      return {
        ...state,
        closeEvent,
        isLoaded: false,
        isConnected: false,
        wasConnectionLost,
      };

    default:
      return state;
  }
};

const contactFormReducer = (state = {}, action) => {
  let newContactForm = null;
  let contactId = null;
  let contactFormId = null;

  switch (action.type) {
    case LOAD_CONTACT_FORM:
      // If the newly activated chat has a contact, use it to fill up the contact form. Otherwise clean up the form
      if (action.contact) {
        // Make sure that the active contact, if set, is not a provisional new contact but has been loaded from the database
        if (
          !action.contact.id ||
          (action.contact.id.indexOf && action.contact.id.indexOf('TMP') >= 0)
        ) {
          newContactForm = ContactForm.load(
            state,
            null,
            action.arrCitiesDataSet
          );
          const provName = action.contact.fullName
            ? action.contact.fullName
            : '';
          ContactForm.setField(
            newContactForm,
            ContactForm.FIELDS.FULL_NAME,
            provName,
            false
          );
        } else {
          newContactForm = ContactForm.load(
            state,
            action.contact,
            action.arrCitiesDataSet
          );
        }
      } else {
        newContactForm = ContactForm.load(state, null, action.arrCitiesDataSet);
      }
      newContactForm.state = ContactForm.CLEAR;

      return newContactForm;

    case UPDATE_CONTACT_FORM:
      newContactForm = { ...state };
      contactFormId = state.fields.id.value;
      contactId = action.contact.id;

      if (contactId === contactFormId) {
        newContactForm = ContactForm.load(
          state,
          action.contact,
          action.arrCitiesDataSet
        );
      }
      newContactForm.state = ContactForm.STATES.LOADED;

      return newContactForm;

    case START_SAVING_CONTACT:
      return { ...state, state: ContactForm.STATES.SAVING };

    case CHANGE_CONTACT_FORM_FIELD:
      return ContactForm.setField(
        state,
        action.fieldName,
        action.fieldValue,
        true
      );

    case SAVE_CONTACT_REQUEST_INVALID:
      return { ...state, errors: action.errors };

    case SAVE_CONTACT_OK:
      return { ...state, state: ContactForm.STATES.LOADED };

    case SAVE_CONTACT_FAIL:
      return { ...state, state: action.error };

    default:
      return state;
  }
};

const customContactFormReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_CUSTOM_CONTACT_FORM_FIELD:
      const { attrId, fieldValue } = action;
      return {
        ...state,
        [attrId]: {
          value: fieldValue,
        },
      };

    default:
      return state;
  }
};

const latestStartChatRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENT_START_CHAT_REQUESTED:
      return {
        ...state,
        state: AgentChatManager.START_CHAT_REQUEST_STATES.REQUESTED,
        timestamp: action.requestTimestamp,
        rqId: null,
      };

    case AWAIT_AGENT_STARTED_CHAT:
      return {
        ...state,
        state: AgentChatManager.START_CHAT_REQUEST_STATES.AWAITING,
        chatId: action.chatId,
        timestamp: action.requestTimestamp,
        rqId: action.rqId,
      };

    case AGENT_STARTED_CHAT_OK:
      return {
        ...state,
        state: AgentChatManager.START_CHAT_REQUEST_STATES.SUCCESSFUL,
      };

    case AGENT_STARTED_CHAT_FAIL:
      return {
        ...state,
        state: AgentChatManager.START_CHAT_REQUEST_STATES.FAILED,
        timestamp: action.requestTimestamp,
        errorCode: action.errorCode,
        errorDetails: action.errorDetails,
      };

    case NEW_AGENT_CHAT_FOR_AGENT:
      if (
        action.agentChat &&
        action.agentChat.id &&
        action.agentChat.id === state.chatId
      ) {
        return {
          ...state,
          state: AgentChatManager.START_CHAT_REQUEST_STATES.SUCCESSFUL,
        };
      }

      return state;

    case AGENT_STARTED_CHAT_RESET:
      return {
        ...state,
        state: null,
        chatId: null,
        timestamp: null,
        errorCode: null,
        errorDetails: null,
        rqId: null,
      };

    default:
      return state;
  }
};

const visibilityReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_VISIBILITY:
      return action.isVisible;
    default:
      return state;
  }
};

const agentConsoleReducer = combineReducers({
  backendConn: backendConnReducer,
  queues: queuesReducer,
  currentContactChatHistory: currentContactChatHistoryReducer,
  messageComposing: messageComposingReducer,
  emoticonSelector: emoticonSelectorReducer,
  chatContextMenu: chatContextMenuReducer,
  activeChatArea: activeChatAreaReducer,
  dashboard: dashboardReducer,
  contactForm: contactFormReducer,
  customForm: customContactFormReducer,
  latestStartChatRequest: latestStartChatRequestReducer,
  isVisible: visibilityReducer,
  chatSearch,
  shopify,
  contact,
});

const rootAgentConsoleReducer = (state = {}, action) => {
  if (action.type === RESET_AGENT_CONSOLE_STATE) {
    return action.state;
  }

  return agentConsoleReducer(state, action);
};

export default rootAgentConsoleReducer;
