/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '@types';

export type MerchantPlanUsage = {
  merchantId: number;
  planMaxChannels: number;
  totalUsedChannels: number;
  canAddExtraChannel: boolean;
  priceExtraChannel: number;
  currency: string;
};

export type MerchantPlanUsageState = MerchantPlanUsage & {
  loading: ('pending' | 'success' | 'failure' | 'fulfill')[];
  error?: ErrorPayload;
};
const initialPlaUsage: MerchantPlanUsageState = {
  loading: [],
  canAddExtraChannel: false,
  currency: '',
  merchantId: 0,
  planMaxChannels: 0,
  priceExtraChannel: 0,
  totalUsedChannels: 0,
};
const merchantPlanUsageSlice = createSlice({
  name: 'merchantPlanUsage',
  initialState: initialPlaUsage,
  reducers: {
    fetchMerchantPlanUsage(state) {
      state.loading = ['pending'];
    },
    fetchMerchantPlanUsageSuccess(
      state,
      action: PayloadAction<MerchantPlanUsage>
    ) {
      return {
        ...state,
        ...action.payload,
        loading: ['success'],
      };
    },
    fetchMerchantPlanUsageFailure(state, action: PayloadAction<ErrorPayload>) {
      state.loading = ['failure'];
      state.error = action.payload;
    },
    fetchMerchantPlanUsageFulfill(state) {
      state.loading.push('fulfill');
    },
  },
});

export default merchantPlanUsageSlice.reducer;

export const {
  fetchMerchantPlanUsage,
  fetchMerchantPlanUsageSuccess,
  fetchMerchantPlanUsageFailure,
  fetchMerchantPlanUsageFulfill,
} = merchantPlanUsageSlice.actions;
