/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, delay, put, takeLatest } from '@redux-saga/core/effects';
import { fetchWsConversationReportFailure } from '@src/reducers/wsConversationReport';
import {
  createWsFlow,
  deleteWsFlow,
  deleteWsFlowFailure,
  deleteWsFlowSuccess,
  getWsFlow,
  getWsFlowContent,
  getWsFlowPreview,
  getWsFlowPreviewSuccess,
  updateWsFlow,
  updateWsFlowContent,
  wsFlowContentFailure,
  wsFlowContentSuccess,
  wsFlowFailure,
  wsFlowSuccess,
} from '@src/reducers/wsFlowEditor';
import { B2ChatAPI } from '@src/types/api';
import { WsFlow } from '@src/types/wsFlows';
import { ErrorData, isErrorPayload } from '@types';

type MetaResponseError = {
  error: {
    message: string;
    error_user_title: string;
    error_user_msg: string;
  };
};

function* getFlowSaga({ payload: flowId }: ReturnType<typeof getWsFlow>) {
  try {
    const response: B2ChatAPI.Response<WsFlow> = yield call(
      B2ChatClient.resources.wsFlows.actions.getFlow,
      { params: { flowId } }
    );

    if (response?.error) throw response.error;
    yield put(wsFlowSuccess({ flow: response.data }));
  } catch (error) {
    yield put(wsFlowFailure());
  } finally {
    yield delay(3000);
    yield put(wsFlowSuccess({ fullFill: true }));
  }
}

function* createWsFlowSaga({ payload: flow }: ReturnType<typeof createWsFlow>) {
  try {
    const response: B2ChatAPI.Response<{ id: string }> = yield call(
      B2ChatClient.resources.wsFlows.actions.createFlow,
      { data: flow }
    );

    if (response?.error) throw response.error;

    const flowId = response.data.id;
    yield put(wsFlowSuccess({ flow: { id: flowId, ...flow } }));
    yield put(getWsFlowContent(flowId));
    yield delay(3000);
    yield put(wsFlowSuccess({ fullFill: true }));
  } catch (error) {
    if (isErrorPayload(error) && error?.details) {
      const metaResponseError = JSON.parse(error.details) as MetaResponseError;
      const errorMessage = metaResponseError?.error?.error_user_title || '';
      yield put(wsFlowFailure(errorMessage));
    }
  }
}

function* updateWsFlowSaga({ payload }: ReturnType<typeof updateWsFlow>) {
  try {
    const { id: flowId, ...flow } = payload;
    const response: B2ChatAPI.Response<{ success: boolean }> = yield call(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      B2ChatClient.resources.wsFlows.actions.updateFlow,
      { params: { flowId }, data: flow }
    );

    if (response?.error || !response?.data?.success) throw response.error;

    yield put(wsFlowSuccess({ flow: payload }));
  } catch (error) {
    yield put(wsFlowFailure());
  } finally {
    yield delay(3000);
    yield put(wsFlowSuccess({ fullFill: true }));
  }
}

function* deleteWsFlowSaga({
  payload: flowId,
}: ReturnType<typeof deleteWsFlow>) {
  try {
    const response: B2ChatAPI.Response<{ success: boolean }> = yield call(
      B2ChatClient.resources.wsFlows.actions.deleteFlow,
      { params: { flowId } }
    );

    if (response?.error || !response?.data?.success) throw response.error;

    yield put(deleteWsFlowSuccess());
  } catch (error) {
    yield put(deleteWsFlowFailure());
  } finally {
    yield delay(3000);
    yield put(deleteWsFlowSuccess({ fullFill: true }));
  }
}

function* getFlowContentSaga({
  payload: flowId,
}: ReturnType<typeof getWsFlowContent>) {
  try {
    const response: B2ChatAPI.Response<{ download_url: string }> = yield call(
      B2ChatClient.resources.wsFlows.actions.getFlowContent,
      { params: { flowId } }
    );

    if (response?.error) throw response.error;
    yield put(wsFlowContentSuccess({ flowContent: response.data }));
  } catch (error) {
    yield put(wsFlowContentFailure());
  } finally {
    yield delay(3000);
    yield put(wsFlowContentSuccess({ fullFill: true }));
  }
}

function* updateFlowContentSaga({
  payload,
}: ReturnType<typeof updateWsFlowContent>) {
  try {
    const payloadBlob = new Blob([JSON.stringify(payload?.flowContent)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('file', payloadBlob, 'flow.json');

    const response: B2ChatAPI.Response<{ validation_errors: unknown[] }> =
      yield call(B2ChatClient.resources.wsFlows.actions.updateFlowContent, {
        data: formData,
        params: { flowId: payload.flowId },
      });

    if (response?.error || !!response.data?.validation_errors?.length)
      throw response.error;

    yield put(wsFlowContentSuccess({ flowContent: payload.flowContent }));
  } catch (error) {
    yield put(wsFlowContentFailure());
  } finally {
    yield delay(3000);
    yield put(wsFlowContentSuccess({ fullFill: true }));
  }
}

function* getFlowPreviewSaga({
  payload: flowId,
}: ReturnType<typeof getWsFlowPreview>) {
  try {
    const response: B2ChatAPI.Response<{
      id: string;
      preview: { preview_url: string };
    }> = yield call(B2ChatClient.resources.wsFlows.actions.getFlowPreview, {
      params: { flowId },
    });

    if (response?.error) throw response.error;
    yield put(
      getWsFlowPreviewSuccess({
        previewUrl: response.data.preview.preview_url,
      })
    );
  } catch (error) {
    yield put(fetchWsConversationReportFailure(error as ErrorData));
  } finally {
    yield put(getWsFlowPreviewSuccess({ fullFill: true }));
  }
}

export default function* wsFlowsSaga() {
  yield takeLatest(getWsFlow, getFlowSaga);
  yield takeLatest(createWsFlow, createWsFlowSaga);
  yield takeLatest(updateWsFlow, updateWsFlowSaga);
  yield takeLatest(deleteWsFlow, deleteWsFlowSaga);
  yield takeLatest(getWsFlowContent, getFlowContentSaga);
  yield takeLatest(updateWsFlowContent, updateFlowContentSaga);
  yield takeLatest(getWsFlowPreview, getFlowPreviewSaga);
}
