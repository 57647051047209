/* eslint-disable */
import intl, { parseMessageValues } from '@src/locales/intl';
import { chatHistoryActions } from '@src/reducers/chatHistory';
import {
  activeChatSuccess,
  addChatOnHold,
  addChatRequest,
  awaitChatExportedToCrm,
  awaitChatPickUp,
  changeLastMessageChat,
  chatAutoAssign,
  chatClosed,
  chatClosedByContact,
  chatExportedToCrmFailure,
  chatExportToCrm,
  chatLeftByContact,
  chatPickedByAnotherAgent,
  chatPickUp,
  chatPickUpFailure,
  chatReopened,
  chatsOnHoldFocused,
  incrementTotalChatsOnHold,
  receivedMessageChat,
  resumeChatRequested,
  setPinningChat,
} from '@src/reducers/chats';
import { chatAssigned } from '@src/reducers/chats/chatAssign';
import {
  acceptChatTransfer,
  cancelChatTransfer,
  cleanChatTransfer,
  rejectChatTransfer,
} from '@src/reducers/chats/chatTransfer';
import { updateChatConversationAction } from '@src/reducers/chats/waConversation';
import { chatViewerActions } from '@src/reducers/chatViewer';
import {
  activeChatSelector,
  activeChatSideSelector,
  chatByIdSelector,
  chatsOnHoldIdsSelector,
  chatsRequestIdsSelector,
  isActiveConsoleWindow,
  sideByChatIdSelector,
} from '@src/selectors/chats';
import {
  isValidChatToAllFiltersOnHoldSelector,
  isValidChatToAllFiltersRequestSelector,
} from '@src/selectors/chatSearch';
import { showChatWithoutDeptToAllAgentsPreferencesSelect } from '@src/selectors/preferences.ts';
import { selectAuthenticationInfo } from '@src/selectors/user';
import { ChatSideType } from '@src/types/chats';
import StringUtils from '@src/utils/strings';
import { defineMessages } from 'react-intl';
import {
  agentStartChatRequested,
  agentStartedChatFailed,
  agentStartedChatOk,
  awaitAgentStartedChat,
  connectedToBackend,
  disconnectedFromBackend,
  execValidateAndSaveContactAction,
  loadedChatHistoryFailed,
  loadedChatHistoryOk,
  loadingChatHistory,
  markIncomingReaction,
  markOutMessageDeliveryAck,
  markOutMessageDeliveryFailed,
  markOutMessageReadByContact,
  markOutMessageReceipt,
  newAutomaticMessage,
  resetAgentStartedChat,
  startSavingContact,
  startSendingMessage,
} from '../actions/agentconsole';
import { reloadAuthenticatedUser } from '../actions/loginAuthenticationAction';
import B2ChatClient from '../client';
import {
  AgentChatTransferStatus,
  EventType,
  MediaType,
} from '../model/frontendmodel';
import { normalizeCitiesSet } from '../model/transform';
import { fetchDeleteBanner, fetchUpdateBanner } from '../reducers/banner';
import { B2ChatBLogo } from '../resources';
import Logger from '../utils/logging';
import spawnNotification from '../utils/spawnNotification';
import TypeUtils from '../utils/typeutils';
import BackendProxy from './backendproxy';
import { SpecialMessageParser } from './specialmessageprocessing';
import { countUnrepliedMessagesInChat } from './summarizestate';
import WebSocketConnector from './websocketconnector';

// ------------------------ TESTING MODE ENABLED -----------------------------
//import BackendProxy from '../tests/mockbackendproxy';
// ---------------------------------------------------------------------------

const _ = require('lodash');

const logger = Logger.getLogger('acmanager');

const i18zdTexts = defineMessages({
  newChatNotifTitle: {
    id: 'Notification.newChatRequestTitle',
  },
  newChatMessageTitle: {
    id: 'Notification.newChatMessageTitle',
  },
  newChatNotifBody: {
    id: 'Notification.newChatRequestBody',
  },
  saysVerb: {
    id: 'Notification.saysVerb',
  },
  msgNotDeliveredLbl: {
    id: 'ChatEvent.ErrorMsgNotDelivered',
  },
});

// Module (singleton object) that is responsible of handling all events that might occur on an Agent Chat
const AgentChatManager = (function () {
  // States a start chat request issued by an agent might be in
  const START_CHAT_REQUEST_STATES = {
    REQUESTED: 'REQUESTED',
    AWAITING: 'AWAITING',
    SUCCESSFUL: 'SUCCESSFUL',
    FAILED: 'FAILED',
  };

  const backendProxy = BackendProxy;

  let targetStore = null;
  let agentId = null;
  let merchantId = null;
  let loggedAgent = null;
  let currentUser = null;

  // The purpose of this variable is just to keep a reference to the most recent instance of the contact's
  //CustomField component. This is part of a hack to allow the ActiveChat component to save the contact prior to
  // closing the active chat. Refer to methods: getContactCustomFields and setRefToContactCustomFields
  let customFieldsComponent = null;

  // --- Private methods

  // Validates an agent chat event, expected to be a Javascript object
  const validateAgentChatEvent = function (chatId, agentChatEvent) {
    // Verify that a non-null chat ID is specified
    if (!chatId) {
      throw new Error('AgentChatEvent is invalid: chat ID unspecified or null');
    }
    // Validate that the event object has the required type and payload properties
    if (!agentChatEvent) {
      throw new Error(
        'AgentChatEvent invalid: type property not set or unrecognized'
      );
    }
    if (!agentChatEvent.type || !(agentChatEvent.type in EventType)) {
      throw new Error(
        'AgentChatEvent invalid: type property not set or unrecognized'
      );
    }
    if (!agentChatEvent.payload) {
      throw new Error('AgentChatEvent invalid: payload property not set');
    }

    return agentChatEvent;
  };

  /* -------------------------------------------------------------------------- */
  /*                           OTHER EVENTS FUNCTIONS                           */
  /* -------------------------------------------------------------------------- */

  // Handle Banner's management (update and remove)
  const handleManagementBanner = data => {
    if (data.type === 'NEW_BANNER') {
      window.localStorage.removeItem('userid');
      targetStore.dispatch(fetchUpdateBanner(data.payload));
    }

    if (data.type === 'BANNER_REMOVED') {
      targetStore.dispatch(fetchDeleteBanner());
    }
  };

  // Play an audio, given the name of the DOM media element
  let playAudio = async function (audioName) {
    try {
      if (document.getElementById(audioName)) {
        await document.getElementById(audioName).play();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error playing message-received audio ${audioName}`, err);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           MESSAGE EVENT FUNCTIONS                          */
  /* -------------------------------------------------------------------------- */

  // NOTE is message
  // Invoked when a message previously submitted by the agent is confirmed to have been read by the contact
  const handleOutMessageReadNotification = function (chatId, notifEvent) {
    targetStore.dispatch(markOutMessageReadByContact(chatId, notifEvent));
  };

  // NOTE is message
  const handleMarkIncomingReaction = function (chatId, notifEvent) {
    targetStore.dispatch(markIncomingReaction(chatId, notifEvent));
  };

  // NOTE is message
  // Invoked when a message delivery is been acknowledged by the messaging provider
  const handleOutMessageAckNotification = function (chatId, notifEvent) {
    let msgId = null;

    if (notifEvent) {
      msgId = notifEvent.messageId
        ? notifEvent.messageId
        : notifEvent.providerMessageId;
    }

    targetStore.dispatch(markOutMessageDeliveryAck(chatId, msgId));
  };

  // NOTE is message
  // Invoked when a message delivery is received by the destinatary
  const handleOutMessageReceiptNotification = function (chatId, notifEvent) {
    let msgId = null;

    if (notifEvent) {
      msgId = notifEvent.messageId
        ? notifEvent.messageId
        : notifEvent.providerMessageId;
    }

    targetStore.dispatch(markOutMessageReceipt(chatId, msgId));
  };

  // NOTE is message
  // Invoked when a message fails to be delivered to the destinatary
  const handleOutMessageDeliveryFailedNotification = function (
    chatId,
    notifEvent
  ) {
    let msgId = null;

    if (notifEvent) {
      msgId = notifEvent.messageId
        ? notifEvent.messageId
        : notifEvent.providerMessageId;
    }

    targetStore.dispatch(markOutMessageDeliveryFailed(chatId, msgId));
  };

  // NOTE is message
  const handleOutgoingMessage = function (agentChatId, payload) {
    if (payload && agentChatId) {
      payload.chatId = agentChatId;
      targetStore.dispatch(startSendingMessage(agentChatId, payload));
      targetStore.dispatch(
        changeLastMessageChat({
          chatId: agentChatId,
          message: {
            text: payload.text,
            messageId: payload.messageId,
            direction: payload.direction || 'OUTGOING',
            mediaType: payload.type || 'TEXT',
            locationName: payload?.locationName,
            sendingTime: payload.sendingTime,
            isCaptionDefined:
              [MediaType.IMAGE, MediaType.VIDEO].includes(payload.type) &&
              !!payload.text?.length,
            isBroadcastWapMsg: payload?.isBroadcastWapMsg,
          },
        })
      );
    } else {
      console.log(
        'WARN: Ignored null OUTGOING_MESSAGE event or with no chat ID specified'
      );
    }
  };

  //TODO handleAutomaticMessage show its use in chatHistory - message move to bottom
  const handleAutomaticMessage = (chatId, payload) => {
    targetStore.dispatch(newAutomaticMessage({ chatId, chatEvent: payload }));
  };

  //NOTE: handleReceivedMessage already refactored
  // Invoked upon arrival of a new message on a chat
  const handleReceivedMessage = (
    chatId,
    message,
    eventTimestamp,
    chatIsAssigned = null
  ) => {
    const curState = targetStore.getState();
    const activeChat = activeChatSelector(curState);
    const chat = chatByIdSelector(chatId)(curState);

    if (!chat?.id && activeChat.id !== chatId) {
      return getOnceChatById(chatId).then(agentChat => {
        AgentChatManager.triggerNewChatReloaded(agentChat, true);
      });
    } else {
      if (!message?.mediaType) message.mediaType = MediaType.TEXT;

      targetStore.dispatch(
        receivedMessageChat({
          chatId,
          message,
          eventTimestamp,
          chatIsAssigned,
        })
      );
      playAudio('newMsgAudio');

      let contactName = '';
      // Try to determine the name of the contact who sent the message
      if (chat?.contact && chat?.contact?.fullName) {
        contactName = `${chat.contact.fullName} ${intl.formatMessage(
          i18zdTexts.saysVerb
        )}: `;
      }

      const notifTag = `newChatMsgNotif_${message.messageId}`;
      let newChatMessageBody = '';

      if (message.text) {
        // Check if the message corresponds to a notification
        const notDeliveredNotifData =
          SpecialMessageParser.isMsgNotDeliveredNotification(message.text);

        if (notDeliveredNotifData !== null) {
          newChatMessageBody = intl.formatMessage(
            i18zdTexts.msgNotDeliveredLbl
          );
        } else {
          newChatMessageBody = `${contactName}"${message.text.substring(
            0,
            Math.min(message.text.length, 500)
          )}"`;
        }
      } else {
        newChatMessageBody = contactName;
      }

      spawnNotification(intl.formatMessage(i18zdTexts.newChatMessageTitle), {
        tag: notifTag,
        body: newChatMessageBody,
        icon: B2ChatBLogo,
      }).then(() => {
        const sideChat = sideByChatIdSelector(chat.id)(targetStore.getState());
        if (sideChat === ChatSideType.ON_HOLD) {
          setChatAsActive(chat);
          targetStore.dispatch(chatsOnHoldFocused());
        } else if (sideChat === ChatSideType.REQUEST) {
          AgentChatManager.requestChatPickup(chat.id, agentId);
        }
      });
    }
  };

  const handleNotifyTimeLimit = (chatId, { contactName, timeLimit }) => {
    const curState = targetStore.getState();
    const chat = chatByIdSelector(chatId)(curState);
    const title = parseMessageValues('Notification.TimeLimit.Title', {
      time: timeLimit,
    });
    const body = parseMessageValues('Notification.TimeLimit.Description', {
      contactName,
      time: timeLimit,
    });

    if (chat?.id) {
      playAudio('newMsgAudio');
      spawnNotification(
        title,
        {
          body,
          icon: B2ChatBLogo,
        },
        true
      ).then(() => {
        setChatAsActive(chat);
        targetStore.dispatch(chatsOnHoldFocused());
      });
    }
  };

  const getOnceChatById = chatId => {
    const state = targetStore.getState();
    const userId = state?.loginAuthentication?.success?.id;

    return new Promise((resolve, reject) => {
      BackendProxy.loadChatById(userId, chatId)
        .then(rawAgentChat => {
          if (rawAgentChat.events?.length) {
            rawAgentChat.messagesSinceLastResponse =
              countUnrepliedMessagesInChat(rawAgentChat);

            const lastEvent = rawAgentChat.events.at(-1);
            rawAgentChat.lastMessage = lastEvent.payload;
            rawAgentChat.lastMessage.mediaType =
              rawAgentChat.lastMessage.mediaType || 'TEXT';
          }

          if (rawAgentChat.contactAccount) {
            rawAgentChat.contact = {
              id: rawAgentChat?.contactAccount?.accountOwner?.id,
              fullName: rawAgentChat?.contactAccount?.accountOwner?.fullName,
              avatarUrl: rawAgentChat?.contactAccount?.avatarUrl,
            };
          }
          rawAgentChat.show = false;
          resolve(rawAgentChat);
        })
        .catch(reject);
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                            CHAT EVENT FUNCTIONS                            */
  /* -------------------------------------------------------------------------- */

  const setChatAsActive = function (agentChat) {
    const activeChatSide = activeChatSideSelector(targetStore.getState());
    targetStore.dispatch(activeChatSuccess(agentChat.id));

    if (activeChatSide === ChatSideType.REQUEST) {
      const searchOnHold = targetStore.getState().chats.chatsSearch;

      if (searchOnHold?.length) {
        targetStore.dispatch(chatsOnHoldFocused());
        return targetStore.dispatch(fetchSearchChats(''));
      }

      targetStore.dispatch(chatsOnHoldFocused());
    }
  };

  // NOTE handleNewChat is refactored
  const handleNewChat = (
    rawAgentChat,
    isReloaded = false,
    options = { autofocus: false }
  ) => {
    const agentChat = rawAgentChat?.assignedTo;
    const isAssignedChat = !!agentChat?.id;
    const isTransferChat = !!rawAgentChat.chatXferRequest;
    const isTransferRequestedChat =
      rawAgentChat?.chatXferRequest?.status ===
      AgentChatTransferStatus.REQUESTED;
    const businessProcessNode = rawAgentChat.businessProcessNode;

    const state = targetStore.getState();
    const myActiveBussinessProcesses =
      state?.loginAuthentication?.success?.businessProcesses.filter(
        ({ state }) => state === 'ACTIVE'
      );

    const chatHasBusinessProcess = businessProcessNode?.id;
    const showChatWithoutDeptToAllAgents =
      showChatWithoutDeptToAllAgentsPreferencesSelect(state);
    const hasBussinesProcess = myActiveBussinessProcesses?.length > 0;
    const isChatAssignedToMe =
      state?.loginAuthentication?.success.id === agentChat?.id;

    // if departments are not desactivated then validate that it was sent to my departments
    if (!isTransferRequestedChat) {
      if (
        !chatHasBusinessProcess &&
        !showChatWithoutDeptToAllAgents &&
        hasBussinesProcess
      ) {
        return;
      }

      if (chatHasBusinessProcess) {
        const isChatInMyDepartmentsNodes =
          myActiveBussinessProcesses?.some(
            node => node.id === businessProcessNode?.id
          ) ?? false;

        // Note: if the chat isn't in my departments, check it's assigned to the logged agent, if not, then ignore it
        if (!isChatInMyDepartmentsNodes && !isChatAssignedToMe) return;
      }

      // Note: if the chat is in my departments, but it's assigned to another agent, then ignore it.
      if (isAssignedChat && !isChatAssignedToMe) return;
    }

    const chat = {
      id: rawAgentChat.id,
      botId: rawAgentChat.botId,
      viaBotAccountId: rawAgentChat.viaBotAccountId,
      viaBotAccountAlias: rawAgentChat.viaBotAccountAlias,
      viaBotAccount: rawAgentChat.viaBotAccount,
      viaBotAccountIconUrl: rawAgentChat.viaBotAccountIconUrl,
      startTimestamp: rawAgentChat.startTimestamp,
      lastMsgReceivedAt: rawAgentChat.lastMsgReceivedAt,
      lastMsgSentAt: rawAgentChat.lastMsgSentAt,
      lastMessage: rawAgentChat?.lastMessage,
      tags: rawAgentChat.tags,
      provider: rawAgentChat.contactAccount.provider,
      messagesSinceLastResponse: rawAgentChat.messagesSinceLastResponse,
      hasPassedMaxTime: rawAgentChat.hasPassedMaxTime,
      adId: rawAgentChat.adId,
      departmentCode: rawAgentChat.departmentCode,
      closeToMaxTime: rawAgentChat.closeToMaxTime,
      status: rawAgentChat.status,
      lastWAConversationStartedAt: rawAgentChat.lastWAConversationStartedAt,
      waConversationsCounter: rawAgentChat.waConversationsCounter,
      crmTicketId: rawAgentChat.crmTicketId,
      crmProvider: rawAgentChat.crmProvider,
      chatXferRequest: rawAgentChat?.chatXferRequest,
      businessProcessNode: rawAgentChat?.businessProcessNode,
      contact: {
        ...rawAgentChat.contact,
        tags: rawAgentChat?.contactAccount?.accountOwner?.tags ?? [],
      },
      tags: rawAgentChat?.tags,
      assignedTo: rawAgentChat?.assignedTo,
      assignedBy: rawAgentChat?.assignedBy,
      segment: rawAgentChat.segment,
    };

    if (!isAssignedChat || isTransferRequestedChat) {
      //CHAT REQUEST CONDITION
      const ifFiltersApplyForThisChat = isValidChatToAllFiltersRequestSelector(
        targetStore.getState()
      )(chat);

      ifFiltersApplyForThisChat
        ? targetStore.dispatch(addChatRequest({ ...chat, show: true }))
        : targetStore.dispatch(incrementTotalChatsRequest(chat));
    } else {
      //CHAT ON_HOLD CONDITION
      const ifFiltersApplyForThisChat = isValidChatToAllFiltersOnHoldSelector(
        targetStore.getState()
      )(chat);

      ifFiltersApplyForThisChat
        ? targetStore.dispatch(addChatOnHold({ ...chat, show: true }))
        : targetStore.dispatch(incrementTotalChatsOnHold(chat));

      if (!isReloaded) {
        targetStore.dispatch(chatsOnHoldFocused());
        if (options?.autofocus) setChatAsActive(agentChat);
      }
    }

    // Deploy browser notification if and only if the focus is not set on B2Chat
    if (!isTransferChat) {
      playAudio('newMsgAudio');
      const notifTag = `newChatNotif_${chat.id}`;
      const newChatNotifBody = `${chat?.contact?.fullName} ${intl.formatMessage(
        i18zdTexts.newChatNotifBody
      )}`;

      spawnNotification(intl.formatMessage(i18zdTexts.newChatNotifTitle), {
        tag: notifTag,
        body: newChatNotifBody,
        icon: B2ChatBLogo,
      }).then(() => {
        const sideChat = sideByChatIdSelector(chat.id)(targetStore.getState());
        if (sideChat === ChatSideType.ON_HOLD) {
          setChatAsActive(chat);
          targetStore.dispatch(chatsOnHoldFocused());
        } else if (sideChat === ChatSideType.REQUEST) {
          AgentChatManager.requestChatPickup(chat.id, agentId);
        }
      });
    }
  };

  // NOTE handleAgentPickedUpChat is refactored
  // Invoked when an agent picked up a chat request
  const handleAgentPickedUpChat = function (rawAgentChat) {
    const chatId = rawAgentChat?.id;
    const assignedAgentId = rawAgentChat?.assignedTo?.id;

    const chat = chatByIdSelector(chatId)(targetStore.getState());
    const isValidToAppliedFiltersOnHold = isValidChatToAllFiltersOnHoldSelector(
      targetStore.getState()
    )(chat);

    if (!isValidToAppliedFiltersOnHold) {
      targetStore.dispatch(chatPickedByAnotherAgent(chatId));
      targetStore.dispatch(chatsOnHoldFocused());
    } else if (rawAgentChat.assignedBy?.agent?.id) {
      handleNewChat(rawAgentChat);
    } else if (assignedAgentId === currentUser?.id) {
      targetStore.dispatch(chatPickUp(chatId));
      targetStore.dispatch(chatsOnHoldFocused());
    } else targetStore.dispatch(chatPickedByAnotherAgent(chatId));
  };

  // NOTE handleChatForcefullyAssignedToAgent is refactored
  // Invoked when an agent picked up a chat request
  //TODO implement filter chat here in merge with refactor agentconsole
  const handleChatForcefullyAssignedToAgent = function (rawAgentChat) {
    const agentId = rawAgentChat?.assignedTo.id;
    if (agentId !== currentUser?.id) {
      targetStore.dispatch(chatPickedByAnotherAgent(rawAgentChat.id));
    } else {
      targetStore.dispatch(chatAutoAssign(rawAgentChat.id));
    }
  };

  // Invoked when an agent switched its active chat
  const handleSwitchedActiveChat = function (chat) {
    const agentId = window.store.getState()?.loginAuthentication?.success?.id;
    setChatAsActive(chat);
    logger.log(
      'handleSwitchedActiveChat',
      chat?.id,
      'with assigned agent:',
      agentId
    );
  };

  //NOTE: handleChatClosed is refectored
  // Invoked when a chat is reported as closed by the agent
  const handleChatClosed = function (chatId, agentChat) {
    targetStore.dispatch(chatClosed(chatId));
  };

  //NOTE: handleContactClosedChat is refectored
  // Invoked when a chat is reported as closed by the contact
  const handleContactClosedChat = function (chatId, payload) {
    targetStore.dispatch(chatClosedByContact(chatId));
  };

  //NOTE: handleContactLeftChat is refectored
  // Invoked when a chat is reported as left (abandoned) by the contact
  const handleContactLeftChat = function (chatId, payload) {
    targetStore.dispatch(chatLeftByContact(chatId));
  };

  //TODO implement this function in new chats
  // Invoked when a chat is requested to be re-opened (most likely by the contact)
  const handleReopenedChat = function (agentChatId, payload) {
    targetStore.dispatch(chatReopened(agentChatId));
  };

  const handleChatTakenFromAudit = function (chatId, payload) {
    targetStore.dispatch(
      chatViewerActions.handleTakeChatWebSocketEvent(chatId, payload)
    );
  };

  // Handle and classify incoming agent events

  const isAgentEvent = type => {
    const agentEventTypes = [
      EventType.NEW_CHAT.id,
      EventType.AGENT_PICKED_UP_CHAT.id,
      EventType.CHAT_FORCED_ASSIGNED_TO_AGENT.id,
      EventType.INCOMING_MESSAGE.id,
      EventType.CONTACT_CLOSED_CHAT.id,
      EventType.CONTACT_LEFT_CHAT.id,
      EventType.REOPENED_CHAT.id,
      EventType.OUT_MESSAGE_DELIVERY_CONFIRMED.id,
      EventType.OUT_MESSAGE_DELIVERY_FAILED.id,
      EventType.OUT_MESSAGE_RECEIPT.id,
      EventType.OUT_MESSAGE_READ.id,
      EventType.INCOMING_REACTION.id,
      EventType.OUTGOING_MESSAGE.id,
      EventType.XFER_REQUESTED.id,
      EventType.XFER_CANCELLED.id,
      EventType.XFER_REJECTED.id,
      EventType.XFER_ACCEPTED.id,
      EventType.NEW_CONVERSATION.id,
      EventType.NEW_AUTOMATIC_MESSAGE.id,
      EventType.CHAT_PIN_TOGGLED.id,
      EventType.CHAT_TAKEN_MESSAGE.id,
      EventType.CHAT_ASSIGNED.id,
      EventType.CHAT_ASSIGN_MESSAGE.id,
    ];

    return agentEventTypes.includes(type);
  };

  const handleAgentEvent = (
    agentChatId,
    agentChatEvent,
    agentChatBizProcId = null,
    chatIsAssigned = null
  ) => {
    const state = targetStore.getState();
    const isActiveConsole = isActiveConsoleWindow(state);

    const isAnIncomingOrOutgoingMessageOrChatTakenMessage =
      agentChatEvent.type === EventType.INCOMING_MESSAGE.id ||
      agentChatEvent.type === EventType.OUTGOING_MESSAGE.id ||
      agentChatEvent.type === EventType.CHAT_TAKEN_MESSAGE.id ||
      agentChatEvent.type === EventType.CHAT_ASSIGN_MESSAGE.id;

    try {
      if (agentChatEvent.type === EventType.NO_ANSWER_FROM_AGENT.id) {
        handleNotifyTimeLimit(agentChatId, agentChatEvent.payload);
        return;
      }

      if (
        (!isAgentEvent(agentChatEvent.type) || !isActiveConsole) &&
        // allow only incoming and outgoing messages to be handled and avoid inconsistencies in the timeline,
        // when a message is received and the console is not active
        !isAnIncomingOrOutgoingMessageOrChatTakenMessage
      )
        return;

      validateAgentChatEvent(agentChatId, agentChatEvent);
      // Determine the type of event that occurred and map it to the appropriate handler
      if (agentChatEvent.type === EventType.NEW_CHAT.id) {
        handleNewChat(agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.AGENT_PICKED_UP_CHAT.id) {
        const { pinId, id } = agentChatEvent.payload;
        handleAgentPickedUpChat(agentChatEvent.payload);
        if (pinId) handlePinChat(id, pinId);
      } else if (
        agentChatEvent.type === EventType.CHAT_FORCED_ASSIGNED_TO_AGENT.id
      ) {
        handleChatForcefullyAssignedToAgent(agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.INCOMING_MESSAGE.id) {
        handleReceivedMessage(
          agentChatId,
          agentChatEvent.payload,
          agentChatEvent.timestamp,
          chatIsAssigned
        );
      } else if (agentChatEvent.type === EventType.CONTACT_CLOSED_CHAT.id) {
        handleContactClosedChat(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.CONTACT_LEFT_CHAT.id) {
        handleContactLeftChat(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.REOPENED_CHAT.id) {
        handleReopenedChat(agentChatId, agentChatEvent.payload);
      } else if (
        agentChatEvent.type === EventType.OUT_MESSAGE_DELIVERY_CONFIRMED.id
      ) {
        handleOutMessageAckNotification(agentChatId, agentChatEvent.payload);
      } else if (
        agentChatEvent.type === EventType.OUT_MESSAGE_DELIVERY_FAILED.id
      ) {
        handleOutMessageDeliveryFailedNotification(
          agentChatId,
          agentChatEvent.payload
        );
      } else if (agentChatEvent.type === EventType.OUT_MESSAGE_RECEIPT.id) {
        handleOutMessageReceiptNotification(
          agentChatId,
          agentChatEvent.payload
        );
      } else if (agentChatEvent.type === EventType.OUT_MESSAGE_READ.id) {
        handleOutMessageReadNotification(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.INCOMING_REACTION.id) {
        handleMarkIncomingReaction(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.OUTGOING_MESSAGE.id) {
        handleOutgoingMessage(agentChatId, {
          ...agentChatEvent.payload,
          isBroadcastWapMsg: agentChatEvent?.isBroadcastWapMsg,
          isMessageFromBot: agentChatEvent?.isMessageFromBot,
        });
      } else if (agentChatEvent.type === EventType.XFER_REQUESTED.id) {
        handleAgentChatTransferRequested(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.XFER_CANCELLED.id) {
        handleAgentChatTransferCancelled(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.XFER_REJECTED.id) {
        handleAgentChatTransferRejected(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.XFER_ACCEPTED.id) {
        handleAgentChatTransferAccepted(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.NEW_CONVERSATION.id) {
        handleNewConversation(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.NEW_AUTOMATIC_MESSAGE.id) {
        handleAutomaticMessage(agentChatId, agentChatEvent);
      } else if (agentChatEvent.type === EventType.CHAT_PIN_TOGGLED.id) {
        const { chatId, pinId } = agentChatEvent.payload;
        handlePinChat(chatId, pinId);
      } else if (agentChatEvent.type === EventType.CHAT_TAKEN_MESSAGE.id) {
        handleChatTakenMessage(agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.CHAT_ASSIGNED.id) {
        handleChatAssigned(agentChatId, agentChatEvent.payload);
      } else if (agentChatEvent.type === EventType.CHAT_ASSIGN_MESSAGE.id) {
        handleChatAssignMessage(agentChatEvent.payload);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //NOTE: handleNewConversation is refectored
  const handleNewConversation = (chatId, payload) => {
    targetStore.dispatch(updateChatConversationAction({ chatId, ...payload }));
  };

  //NOTE: handleUserEvent is refectored

  const isUserEvent = type => {
    const userEventTypes = [
      EventType.SWITCHED_ACTIVE_CHAT.id,
      EventType.AGENT_CLOSED_CHAT.id,
      EventType.NEW_CONVERSATION.id,
      EventType.CHAT_TAKEN.id,
      EventType.NEW_CHAT.id,
    ];

    return userEventTypes.includes(type);
  };

  // Handle and classify incoming user topic events
  const handleUserEvent = (userChatId, userChatEvent) => {
    if (!isUserEvent(userChatEvent.type)) return;
    if (userChatEvent.type === EventType.SWITCHED_ACTIVE_CHAT.id) {
      handleSwitchedActiveChat(userChatEvent.payload);
    } else if (userChatEvent.type === EventType.AGENT_CLOSED_CHAT.id) {
      handleChatClosed(userChatId, userChatEvent.payload);
    } else if (userChatEvent.type === EventType.NEW_CONVERSATION.id) {
      handleNewConversation(userChatId, userChatEvent.payload);
    } else if (
      userChatEvent.type === EventType.CHAT_TAKEN.id ||
      // Note: NEW_CHAT is required in order to know when a chat has been taken by auditor,
      //       the event only comes for the user who is taking the chat.
      userChatEvent.type === EventType.NEW_CHAT.id
    ) {
      handleChatTakenFromAudit(userChatId, userChatEvent);
    }
  };

  // Handle and classify management events
  const handleManagementEvent = function (managementEvent) {
    // eslint-disable-next-line no-console
    console.debug('Got management event: %s', managementEvent);

    if (managementEvent === 'USER_DEPARTMENT_MEMBERSHIP_UPDATED') {
      targetStore.dispatch(reloadAuthenticatedUser());
    }
  };

  //NOTE: handleResponseEvent is refactored
  // Handle events sent by the backend in response to a request previously made by the frontend (e.g. send-message)
  const handleResponseEvent = function (respEvent) {
    if (
      respEvent !== null &&
      respEvent.response === 'AGENT_PICKED_UP_CHAT' &&
      respEvent.status === 'ERROR'
    ) {
      const chatId = respEvent?.requestId;
      const reason = respEvent?.error?.code ?? '';

      if (reason.indexOf('ChatAssignmentConflictException') >= 0) {
        const reasonParts = reason.split(':');
        const agentId = reasonParts.length > 1 ? reasonParts[1] : null;
        targetStore.dispatch(chatPickedByAnotherAgent(chatId));
        console.warn(
          `Chat already picked-up by peer: ${chatId}. Agent: ${agentId}`
        );
      } else {
        console.warn(`Chat pickup failed: ${chatId}. Reason: ${reason}`);
        targetStore.dispatch(chatPickUpFailure());
      }
    }
  };

  //NOTE: handlePinChat function use the new reducer
  const handlePinChat = (id, pinId) => {
    targetStore.dispatch(setPinningChat({ id, pinId }));
  };

  /* -------------------------------------------------------------------------- */
  /*      REQUEST, ACCEPT, CANCEL & REJECT TRANSFERS  SOCKET RESPONSE EVENT     */
  /* -------------------------------------------------------------------------- */
  const handleAgentChatTransferRequested = function (agentChatId, payload) {
    const { chat } = payload;

    // Ignore message with chat.contactAccount as undefined
    if (!chat.contactAccount || !chat?.chatXferRequest) return;

    spawnNotification(
      intl.formatMessage({
        id: 'ChatTransfer.transferRequestBrowserTitle',
      }),
      {
        tag: 'agentChatTransferRequested',
        body: intl.formatMessage(
          { id: 'ChatTransfer.transferRequestBrowserMessage' },
          {
            sourceAgent: chat.chatXferRequest.sourceAgent.fullName,
            comments: chat.chatXferRequest.comments,
          }
        ),
        icon: B2ChatBLogo,
      }
    ).then(() => {
      const sideChat = sideByChatIdSelector(chat.id)(targetStore.getState());
      if (sideChat === ChatSideType.REQUEST) {
        AgentChatManager.requestChatPickup(chat.id, agentId);
      }
    });
    playAudio('agentChatTransferRequestAudio');

    handleNewChat(chat);
  };

  const handleAgentChatTransferCancelled = function (agentChatId, payload) {
    // Ignore message with payload.chat different of undefined
    const transfer = payload?.chat?.chatXferRequest;
    if (!transfer) return;

    spawnNotification(
      intl.formatMessage({
        id: 'ChatTransfer.transferCancelBrowserTitle',
      }),
      {
        tag: 'agentChatTransferCancelled',
        body: intl.formatMessage(
          { id: 'ChatTransfer.transferCancelBrowserMessage' },
          {
            sourceAgent: transfer.sourceAgent.fullName,
          }
        ),
        icon: B2ChatBLogo,
        silent: true,
      }
    );
    playAudio('agentChatTransferCancelAudio');
    targetStore.dispatch(cancelChatTransfer(transfer.chatId));
  };

  const handleAgentChatTransferRejected = function (agentChatId, payload) {
    // Ignore message with payload.chat different of undefined
    const transfer = payload?.chat?.chatXferRequest;
    if (!transfer) return;

    spawnNotification(
      intl.formatMessage({
        id: 'ChatTransfer.transferRejectBrowserTitle',
      }),
      {
        tag: 'agentChatTransferRejected',
        body: intl.formatMessage(
          { id: 'ChatTransfer.transferRejectBrowserMessage' },
          {
            targetAgent: transfer.targetAgent.fullName,
          }
        ),
        icon: B2ChatBLogo,
        silent: true,
      }
    );
    playAudio('agentChatTransferRejectAudio');

    targetStore.dispatch(rejectChatTransfer(transfer));
    // TODO remove this when implementing alerts in console
    setTimeout(
      () => targetStore.dispatch(cleanChatTransfer(transfer.chatId)),
      20000
    );
  };

  const handleAgentChatTransferAccepted = function (agentChatId, payload) {
    // Ignore message with payload.chat different of undefined
    const transfer = payload?.chat?.chatXferRequest;
    const transferredToChatId = payload?.transferredToChatId;
    if (!transfer) return;

    spawnNotification(
      intl.formatMessage({
        id: 'ChatTransfer.transferAcceptBrowserTitle',
      }),
      {
        tag: 'agentChatTransferAccepted',
        body: intl.formatMessage(
          { id: 'ChatTransfer.transferAcceptBrowserMessage' },
          {
            targetAgent: transfer.targetAgent.fullName,
          }
        ),
        icon: B2ChatBLogo,
        silent: true,
      }
    );
    playAudio('agentChatTransferApproveAudio');

    targetStore.dispatch(acceptChatTransfer({ transfer, transferredToChatId }));
    const currentUser = targetStore.getState().loginAuthentication.success;
    targetStore.dispatch(
      chatHistoryActions.unshiftEvent({
        referenceChatId: transferredToChatId,
        chatId: transfer.chatId,
        event: {
          type: 'TRANSFER',
          fromChatId: payload.chatId,
          toChatId: payload.transferredToChatId,
          timestamp: transfer.pickedUpTimestamp,
          fromAgentUsername: transfer.sourceAgent.fullName,
          toAgentUsername: currentUser.localUsername,
        },
        status: 'CLOSED',
      })
    );
  };

  const handleChatTakenMessage = function (payload) {
    const { agent, contact, id } = payload;
    spawnNotification(
      intl.formatMessage({
        id: 'ChatTaken.notification.title',
      }),
      {
        tag: `chatTakenByAnotherAgent-${id}`,
        body: intl.formatMessage(
          {
            id: 'ChatTaken.notification.content',
          },
          {
            contactName: contact.fullName,
            agentName: agent.fullName,
          }
        ),
        icon: B2ChatBLogo,
      },
      true
    );
    playAudio('agentChatTakenByAuditorAudio');
  };

  const handleChatAssigned = (chatId, payload) => {
    targetStore.dispatch(
      chatAssigned({
        chatId,
        assignedBy: {
          agent: payload.agentWhoAssigned,
          date: payload.timestamp,
        },
        assignedTo: payload.agentToAssign,
      })
    );
  };

  const handleChatAssignMessage = function ({
    id,
    agentAssigned,
    agentWhoAssigned,
    contact,
  }) {
    const contactName = contact?.fullName;
    const assignedTo = agentAssigned?.fullName;
    const assignedBy = agentWhoAssigned?.fullName;

    if (assignedTo && assignedBy && contactName) {
      spawnNotification(
        intl.formatMessage({ id: 'ChatAssign.Notification.Title' }),
        {
          tag: `chatAssignMsg-${id || contactName}`,
          body: intl.formatMessage(
            { id: 'ChatAssign.Notification.Content' },
            { contactName, assignedTo, assignedBy }
          ),
          icon: B2ChatBLogo,
        },
        true
      );
      playAudio('agentChatTakenByAuditorAudio');
    }
  };

  return {
    // --- Public fields
    START_CHAT_REQUEST_STATES,

    // --- Public methods, to be directly invoked by the UI components

    // Sets private attributes AgentChatManager to the backend, through the BackendProxy module, but doesn't connect websockets
    initialize(store) {
      targetStore = store;
      currentUser = targetStore.getState().loginAuthentication.success;
      console.info('Initializing AgentChatManager', currentUser);

      // Get logged agent and the merchant it belongs to
      agentId = currentUser?.id || null;
      merchantId = currentUser?.employer?.id || null;
      loggedAgent = {
        id: agentId || null,
        login: '',
        merchantId: merchantId || null,
      };
    },

    // Starts listening for events coming from the backend-side as soon as the connection gets established
    // connects with websockets via the BackendProxy module
    connectWebsockets() {
      if (!WebSocketConnector.isConnected() && currentUser) {
        WebSocketConnector.connect(
          () => {
            targetStore.dispatch(connectedToBackend());
          },
          closeEvent => {
            targetStore.dispatch(disconnectedFromBackend(closeEvent));
          },
          handleAgentEvent,
          handleUserEvent,
          handleManagementEvent,
          handleResponseEvent,
          currentUser,
          handleManagementBanner
        );
      }
    },

    // Stops listening for events coming from the backend-side.
    // That is, closes websockets connections established by the BackendProxy
    disconnectWebsockets() {
      WebSocketConnector.disconnect();
    },

    setCurrentLoggedInUser(user) {
      currentUser = user;
    },
    // Sends a web service request to loads the chat history of the specified contact
    // messaging account and that is related to a specific bot account
    loadChatBotHistory(accountId, viaBotAccount, provider) {
      const requestId = StringUtils.getUuid();
      try {
        logger.log("Calling backend's loadContactMsgAccountChatHistory...");
        targetStore.dispatch(loadingChatHistory(requestId, true));
        backendProxy.loadContactMsgAccountChatHistory(
          accountId,
          viaBotAccount,
          provider,
          // OK callback
          chatHistory => {
            try {
              logger.log(
                'Load chat history OK. Processing response:',
                typeof chatHistory
              );

              // Validate that the contact messaging account of the currently active chat matches the history obtained
              const activeChat = activeChatSelector(targetStore.getState());

              // Ensure that the contact messaging account of the active chat matches that of the history just loaded,
              // otherwise it means that the active chat was switched while loading this history, hence discard it
              if (activeChat?.contact.cmaId === accountId) {
                logger.log(
                  'Load chat history OK. Dispatching loadedChatHistoryOk'
                );

                targetStore.dispatch(
                  loadedChatHistoryOk(chatHistory, requestId)
                );
              }
            } catch (error) {
              const errorMsg = `Failed processing response: ${error}`;
              targetStore.dispatch(
                loadedChatHistoryFailed(errorMsg, null, requestId)
              );
            }
          },
          // Fail callback
          (resultCode, error, resp) => {
            logger.log(
              'Load chat history failed. Result code, error, resp:',
              resultCode,
              error,
              resp
            );

            targetStore.dispatch(
              loadedChatHistoryFailed(error, resp, requestId)
            );
          },
          requestId
        );
      } catch (error) {
        logger.log('Catched error on loadChatHistory:', error);

        const errorMsg = `Failed invoking chat history retrieval service: ${error}`;
        targetStore.dispatch(
          loadedChatHistoryFailed(errorMsg, null, requestId)
        );
      }
    },

    notifyReadMessages(providerMessageIds, chatId, okCallback, failCallback) {
      backendProxy.notifyReadMessages(
        chatId,
        providerMessageIds,
        okCallback,
        failCallback
      );
    },

    // Requests the backend to export the chat with the specified Id to a CRM service
    requestExportChatToCrm(chatId, crmProvider) {
      // Get the chat to be exported
      const authUser = selectAuthenticationInfo(targetStore.getState());
      const chatsOnHoldIds = chatsOnHoldIdsSelector(targetStore.getState());
      const merchant = authUser.employer;

      if (chatsOnHoldIds.includes(chatId)) {
        // Signal that an export-chat-to-CRM request is pending for confirmation
        targetStore.dispatch(awaitChatExportedToCrm());
        backendProxy.exportChatToCrm(
          merchant,
          chatId,
          crmProvider,
          crmTicketId => {
            targetStore.dispatch(
              chatExportToCrm({ chatId, crmTicketId, crmProvider })
            );
          },
          (resultCode, errorMessage, errorDetails) => {
            targetStore.dispatch(
              chatExportedToCrmFailure({
                traceId: errorDetails.traceId,
                code: errorDetails.errorCode,
                timestamp: errorDetails.timestamp,
                message: errorDetails.details,
              })
            );
          }
        );
      }
    },
    //TODO requestStartChat move latestStartChatRequest outside of agentconsole in next iteration
    // Requests the backend to start a new chat with the specified contact, through a bot messaging account
    requestStartChat(
      botId,
      viaBotAccount,
      viaMsgProvider,
      contactMsgAccount,
      contactId,
      templatedMsgId
    ) {
      const MAX_RETRIES = 5;
      const timestamp = Date.now();

      // Signal the store that a new agent-started-chat is being requested to the backend
      targetStore.dispatch(
        agentStartChatRequested(contactMsgAccount, timestamp)
      );

      // Submit a start-new-agent-chat request to the backend
      backendProxy.startChatWithContact(
        botId,
        viaBotAccount,
        viaMsgProvider,
        contactMsgAccount,
        contactId,
        templatedMsgId,
        (rqId, chatId) => {
          // Start-new-agent-chat request was successfully delivered to the backend...
          targetStore.dispatch(
            awaitAgentStartedChat(contactMsgAccount, chatId, timestamp, rqId)
          );

          const state = targetStore.getState();
          const { latestStartChatRequest } = state.agentConsole;

          if (
            latestStartChatRequest?.state &&
            ![
              START_CHAT_REQUEST_STATES.SUCCESSFUL,
              START_CHAT_REQUEST_STATES.FAILED,
            ].includes(latestStartChatRequest?.state)
          ) {
            getOnceChatById(chatId)
              .then(() => {
                AgentChatManager.triggerNewChatReloaded(
                  rawAgentChat,
                  true,
                  true
                );
                targetStore.dispatch(agentStartedChatOk(chatId, rqId));
              })
              .catch(e => {
                console.log('catch error', e);
                targetStore.dispatch(resetAgentStartedChat());
              });
          }
        },
        (rqId, resultCode, error, details) => {
          let errorCode = String(resultCode);
          let objDetails = null;

          // Extract further information about the causes of the error from the details argument (if available)
          if (details) {
            if (details.errorDetails) {
              errorCode = details.errorCode
                ? details.errorCode
                : String(resultCode);
              objDetails = JSON.parse(details.errorDetails);
            } else {
              // Try to make something of the details argument if not clear what it contains
              objDetails = {
                error,
                status: details.status,
                details: details.error,
                exception: details.exception,
              };
            }
          } else {
            objDetails = error;
          }

          // Start-new-agent-chat request failed or rejected by the backend
          targetStore.dispatch(
            agentStartedChatFailed(
              contactMsgAccount,
              timestamp,
              errorCode,
              objDetails,
              rqId
            )
          );
        }
      );
    },

    // Saves a contact (creates if new, updates if existing) into the backend repository
    saveContact({ botId, contactId, provider, newContact }) {
      // Executor function to be run within the promise returned by this invocation
      const fnExecutor = (resolve, reject) => {
        targetStore.dispatch(startSavingContact(newContact));

        // If the contact has an non-null ID field, it's an existing contact to be modified
        if (newContact.id && !!String(newContact.id).trim()?.length) {
          const activeChat = activeChatSelector(targetStore.getState());
          const activeChatId = activeChat.id;
          // Update an existing contact request to the backend via the backend proxy
          backendProxy.updateContact(
            newContact,
            activeChatId,
            () => {
              resolve(newContact.id);
            },
            (status, error, errorResp) => {
              // TODO: TEMPORARY SOLUTION!!! PREVENT NULLPOINTERS FROM CLOSING CHATS!!!
              console.log(
                'IGNORING NULL POINTER! THIS IS A TEMPORARY FIX',
                errorResp
              );

              if (_.get(errorResp, 'text') != null) {
                errorResp.text().then(errD => {
                  if (errD.includes('NullPointerException')) {
                    console.log(
                      'IGNORING NULL POINTER! THIS IS A TEMPORARY FIX'
                    );
                    resolve(newContact.id);
                  } else {
                    reject({
                      errorType: 'UPDATE_CONTACT_SERVICE_ERROR',
                      errorDetails: errorResp,
                    });
                  }
                });
              } else {
                // Handle errors that might occur while saving the contact
                reject({
                  errorType: 'UPDATE_CONTACT_SERVICE_ERROR',
                  errorDetails: errorResp,
                });
              }
            }
          );
        } else {
          // Send a save new contact request to the backend via the backend proxy
          backendProxy.saveNewContact(
            {
              botId,
              contactId,
              provider,
              newContact,
            },
            () => resolve(newContact.id),
            (status, error, errorResp) => {
              // Handle errors that might occur while saving the contact
              reject({
                errorType: 'CREATE_CONTACT_SERVICE_ERROR',
                errorDetails: errorResp,
              });
            }
          );
        }
      };

      return new Promise(fnExecutor);
    },

    // Loads the dataset to feed the countries AutoComplete field
    getCountriesDataSet() {
      return targetStore.getState().getGeneralProperties.arrCountries;
    },

    // Loads the dataset to feed the cities AutoComplete field
    getCitiesDataSet(state) {
      // Retrieve the cities from the backend only if there isn't a non-empty cities dataset in the state
      if (!state.citiesDataSet || state.citiesDataSet.length <= 0) {
        return normalizeCitiesSet(BackendProxy.getAllCities());
      }

      return targetStore.getState().citiesDataSet;
    },

    //NOTE: pickupAgentChatTransfer is refactored
    pickupAgentChatTransfer(agentChatTransfer) {
      backendProxy.pickupAgentChatTransfer(
        agentChatTransfer,
        response => {
          targetStore.dispatch(chatPickUp(agentChatTransfer.chatId));
          targetStore.dispatch(chatsOnHoldFocused());
        },
        e => {
          targetStore.dispatch(chatPickUpFailure());
        }
      );
    },

    // The purpose of the following two methods is just to keep a reference to the most recent instance of the
    // contact's CustomField component. This is a hack to allow the ActiveChat component to save the contact prior to
    // closing the active chat. This is outrageous, but see no other (easy) way. Please forgive me for this hideous sin
    setRefToContactCustomFields(refCustomFields) {
      customFieldsComponent = refCustomFields;
    },

    getContactCustomFields() {
      if (
        customFieldsComponent &&
        TypeUtils.isFunction(customFieldsComponent.sendFieldDetail)
      ) {
        return customFieldsComponent.sendFieldDetail();
      }

      return null;
    },

    //NOTE: triggerNewChatReloaded is refactored
    // Triggers a chat reloaded into tray event
    triggerNewChatReloaded: (rawAgentChat, isReload, autofocus = false) => {
      handleNewChat(rawAgentChat, isReload, { autofocus });
    },

    /* -------------------------------------------------------------------------- */
    /*                             SELECT ACTIVE CHAT                             */
    /* -------------------------------------------------------------------------- */

    // NOTE: requestSetActiveChat function is refactored
    // Makes a chat active, under control of the specified agent
    requestSetActiveChat(chatId, agentId) {
      const state = targetStore.getState();
      const chatsIds = chatsOnHoldIdsSelector(state);
      // Only on-going chats can be closed
      if (chatsIds?.includes(chatId)) {
        setChatAsActive({ id: chatId });
      }
    },

    /* -------------------------------------------------------------------------- */
    /*                                  PIN CHAT                                  */
    /* -------------------------------------------------------------------------- */

    // NOTE: requestPinChat function is refactored
    requestPinChat(chatId) {
      WebSocketConnector.pinChat(chatId);
    },

    /* -------------------------------------------------------------------------- */
    /*                                 PICKUP CHAT                                */
    /* -------------------------------------------------------------------------- */
    // NOTE: requestChatPickup function is refactored
    // Request a chat request to be picked up by the specified agent
    requestChatPickup(chatId, agentId) {
      const chatsRequestIds = chatsRequestIdsSelector(targetStore.getState());
      if (chatsRequestIds.includes(chatId)) {
        try {
          // Send a chat-activation-request to the backend...
          WebSocketConnector.pickupChat(chatId, agentId);
          // Signal that a chat-pickup-request is pending for confirmation
          targetStore.dispatch(awaitChatPickUp());
        } catch (err) {
          targetStore.dispatch(chatPickUpFailure());
        }
      }
    },

    /* -------------------------------------------------------------------------- */
    /*                 REQUEST, ACCEPT, CANCEL & REJECT TRANSFERS                 */
    /* -------------------------------------------------------------------------- */

    // NOTE: cancelAgentChatTransfer function is refactored
    cancelAgentChatTransfer(agentChatTransfer) {
      backendProxy.cancelAgentChatTransfer(
        agentChatTransfer,
        response => {},
        e => {}
      );
    },

    // NOTE: rejectAgentChatTransfer function is refactored
    rejectAgentChatTransfer(agentChatTransfer) {
      backendProxy.rejectAgentChatTransfer(
        agentChatTransfer,
        response => {},
        e => {}
      );
    },

    // NOTE: acceptAgentChatTransfer function is refactored
    acceptAgentChatTransfer(agentChatTransfer) {
      backendProxy.acceptAgentChatTransfer(
        agentChatTransfer,
        response => {},
        e => {}
      );
    },

    /* -------------------------------------------------------------------------- */
    /*                            CLOSE & RESUME CHATS                            */
    /* -------------------------------------------------------------------------- */

    // NOTE: requestCloseChat function is refactored
    requestCloseChat(chatId) {
      // Executor function to be run within the promise returned by this invocation
      return new Promise(async (resolve, reject) => {
        const state = targetStore.getState();
        const closedChat = chatByIdSelector(chatId)(state);
        const customFormFields = [];

        if (state.agentConsole.customForm) {
          const { customForm } = state.agentConsole;
          Object.keys(customForm).forEach(key => {
            const { value } = customForm[key];
            customFormFields.push({ attrId: key, value });
          });
        }

        // Only on-going chats can be closed
        if (Object.entries(closedChat).length) {
          try {
            await execValidateAndSaveContactAction({
              fields: customFormFields,
              chatId: closedChat?.id,
              provider: closedChat?.provider,
              botId: closedChat?.botId,
              contactId: closedChat?.contact?.id,
              cities: state.citiesDataSet,
            });

            const response =
              await B2ChatClient.resources.agentConsoleChat.actions.closeChat({
                params: { chatId },
              });
            if (!response.error) resolve(closedChat);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error closing chat ${chatId}`);
          }
        } else {
          reject({ errorType: 'INVALID_CHAT_NOT_ACTIVE', errorDetails: null });
        }
      });
    },

    // NOTE: requestResumeChat function is refactored
    requestResumeChat(chatId, templatedMsgId) {
      const state = targetStore.getState();
      const chatsIds = chatsOnHoldIdsSelector(state);
      // Only on-going chats can be closed
      if (chatsIds?.includes(chatId)) {
        WebSocketConnector.requestResumeChat(
          chatId,
          templatedMsgId,
          responseEvent => {
            targetStore.dispatch(
              resumeChatRequested({
                chatId,
                lastMsgSentAt: responseEvent.response,
              })
            );
          },
          (resultCode, error) => {
            console.error('Error resuming chat', `chat id: ${chatId}`, error);
          }
        );
      }
    },
  };
})();

export default AgentChatManager;
