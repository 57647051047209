/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOGIN_AUTHENTICATION_REQUEST } from '@src/actions/loginAuthenticationAction';
import { appConfig } from '@src/config/appConfig';
import { ChatStatus } from '@src/model/frontendmodel';
import {
  ErrorData,
  ErrorPayload,
  QuotedMessage,
  TextActionEnum,
} from '@src/types';
import {
  ChatActionsStatus,
  ChatActionType,
  ChatContact,
  ChatLastMessage,
  ChatSideType,
  ChatsOnHold,
  ChatsRequest,
  ChatTray,
  ResponseStatus,
} from '@src/types/chats';
import { differenceBy, unionBy } from 'lodash';
import { chatAssignBuilder } from './chatAssign';
import { chatTransferBuilder } from './chatTransfer';
import initialState, {
  ChatActionErrorDetail,
  ChatsState,
  NotificationTimeLimit,
  PayloadNotification,
  Segments,
  TransformTextAction,
} from './initialState';
import { updateChatConversationBuilder } from './waConversation';

const removeActiveChat = (state: ChatsState, chat: ChatTray) => {
  if (state.activeChat?.id === chat.id) delete state.activeChat;
};
const updateActiveChat = (state: ChatsState, chat: ChatTray) => {
  if (state.activeChat?.id === chat.id) state.activeChat = chat;
};

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    activeConsoleWindow(state) {
      state.isActiveWindow = true;
    },

    inactiveConsoleWindow(state) {
      state.isActiveWindow = false;
    },

    fetchChatsOnHold(state) {
      state.chatsOnHold = {
        ...initialState.chatsOnHold,
        totalChatsOnHold: state.chatsOnHold.totalChatsOnHold,
        loading: 'pending',
      };
    },
    increaseOffsetChatsOnHold(state) {
      const qtyChatsOnHold = state.chatsOnHold.tray.filter(
        ({ show }) => show
      ).length;

      if (state.chatsOnHold.totalFilteredChatsOnHold > qtyChatsOnHold)
        state.chatsOnHold.offset = qtyChatsOnHold;
    },
    fetchNotificationTimeLimit(
      state,
      _action: PayloadAction<PayloadNotification>
    ) {
      const { timeLimit } = state;
      state.timeLimit = {
        ...timeLimit,
        loading: ResponseStatus.PENDING,
      };
    },
    setNotificationTimeLimit(
      state,
      action: PayloadAction<NotificationTimeLimit>
    ) {
      state.timeLimit = action.payload;
    },
    fetchNotificationTimeLimitFailure(state) {
      const { timeLimit } = state;
      state.timeLimit = {
        ...timeLimit,
        loading: ResponseStatus.FAILURE,
      };
    },
    fetchCRMbutton(state, _action: PayloadAction<string>) {
      return state;
    },
    fetchCRMbuttonSuccess(state, action: PayloadAction<boolean>) {
      state.crmButton = {
        loading: 'success',
        show: action.payload,
        sendInfo: 'idle',
      };
    },
    fetchCRMbuttonFailure(state) {
      state.crmButton = {
        loading: 'failure',
        show: false,
        sendInfo: 'idle',
      };
    },
    fetchCRMinfo(state, _action: PayloadAction<string>) {
      const { crmButton } = state;
      state.crmButton = {
        ...crmButton,
        sendInfo: 'pending',
      };
    },
    fetchCRMinfoSuccess(state) {
      const { crmButton } = state;
      state.crmButton = {
        ...crmButton,
        sendInfo: 'success',
      };
    },
    fetchCRMinfoFailure(state) {
      const { crmButton } = state;
      state.crmButton = {
        ...crmButton,
        sendInfo: 'failure',
      };
    },
    fetchChatsOnHoldSuccess(
      state,
      { payload }: PayloadAction<ChatsOnHold & { activeChat: string }>
    ) {
      const isFetchingNext = state.chatsOnHold.offset > 0;
      const isSearching = state.chatsSearch.length > 0;

      if (isFetchingNext) {
        const chatsIds = state.chatsOnHold.tray
          .filter(({ show }) => show)
          .map(chat => chat.id);

        payload.tray.forEach(chat => {
          if (!chatsIds.includes(chat.id))
            state.chatsOnHold.tray.push({ ...chat, show: true });
        });
      } else {
        let { tray: chatsOnHold } = state.chatsOnHold;
        const { tray: chatsFiltered } = payload;

        if (chatsOnHold.length === 0) {
          chatsOnHold = chatsFiltered.map(chat => ({ ...chat, show: true }));
        } else {
          chatsOnHold = chatsOnHold.map(chat => ({ ...chat, show: false }));
          chatsFiltered.forEach(chat => {
            const indexChat = chatsOnHold.findIndex(
              item => item.id === chat.id
            );
            if (indexChat >= 0) chatsOnHold.splice(indexChat, 1);
            chatsOnHold.push({ ...chat, show: true });
          });
        }

        state.chatsOnHold.tray = chatsOnHold;
      }

      if (!isSearching && !isFetchingNext) {
        const activeChat = payload.tray.find(
          chat => chat.id === payload.activeChat
        );
        state.activeChat = activeChat;
      }

      state.chatsOnHold.totalChatsOnHold = payload.totalChatsOnHold;
      state.chatsOnHold.totalFilteredChatsOnHold =
        payload.totalFilteredChatsOnHold;
    },
    fetchChatsOnHoldFailure(state, action: PayloadAction<ErrorData>) {
      state.chatsOnHold.error = action.payload;
    },
    fetchChatsOnHoldFulfill(state) {
      state.chatsOnHold.loading = 'idle';
    },

    fetchChatsRequest(state) {
      state.chatsRequest = {
        ...initialState.chatsRequest,
        totalChatRequests: state.chatsRequest.totalChatRequests,
        loading: 'pending',
      };
    },
    increaseOffsetChatsRequest(state) {
      const qtyChatsRequest = state.chatsRequest.tray.filter(
        ({ show }) => show
      ).length;

      if (state.chatsRequest.totalFilteredChatRequests > qtyChatsRequest)
        state.chatsRequest.offset = qtyChatsRequest;
    },
    fetchChatsRequestSuccess(state, { payload }: PayloadAction<ChatsRequest>) {
      const isFetchingNext = state.chatsRequest.offset > 0;

      if (isFetchingNext) {
        const chatsIds = state.chatsRequest.tray
          .filter(({ show }) => show)
          .map(chat => chat.id);

        payload.tray.forEach(chat => {
          if (!chatsIds.includes(chat.id))
            state.chatsRequest.tray.push({ ...chat, show: true });
        });
      } else {
        let { tray: chatsRequest } = state.chatsRequest;
        const { tray: chatsFiltered } = payload;

        if (chatsRequest.length === 0) {
          chatsRequest = chatsFiltered.map(item => ({ ...item, show: true }));
        } else {
          chatsRequest = chatsRequest.map(chat => ({ ...chat, show: false }));
          chatsFiltered.forEach(chat => {
            const indexChat = chatsRequest.findIndex(
              item => item.id === chat.id
            );
            if (indexChat >= 0) chatsRequest.splice(indexChat, 1);
            chatsRequest.push({ ...chat, show: true });
          });
        }

        state.chatsRequest.tray = chatsRequest;
      }

      state.chatsRequest.totalChatRequests = payload.totalChatRequests;
      state.chatsRequest.totalFilteredChatRequests =
        payload.totalFilteredChatRequests;
    },
    fetchChatsRequestFailure(state, action: PayloadAction<ErrorData>) {
      state.chatsRequest.error = action.payload;
    },
    fetchChatsRequestFulfill(state) {
      state.chatsRequest.loading = 'idle';
    },
    fetchSearchChats(state, action: PayloadAction<string>) {
      state.chatsSearch = action.payload;

      state.chatsOnHold.offset = initialState.chatsOnHold.offset;
      state.chatsOnHold.limit = initialState.chatsOnHold.limit;

      state.chatsRequest.offset = initialState.chatsRequest.offset;
      state.chatsRequest.limit = initialState.chatsRequest.limit;
    },

    chatsOnHoldFocused(state) {
      state.activeChatSide = ChatSideType.ON_HOLD;
    },

    chatsRequestFocused(state) {
      state.activeChatSide = ChatSideType.REQUEST;
    },

    addChatOnHold(state, action: PayloadAction<ChatTray>) {
      const chat = action.payload;
      const index = state.chatsOnHold.tray.findIndex(
        ({ id }) => id === chat.id
      );
      if (index === -1) {
        state.chatsOnHold.tray.push(chat);
        state.chatsOnHold.totalChatsOnHold += 1;
      } else {
        state.chatsOnHold.tray[index] = chat;
      }

      const indexRequest = state.chatsRequest?.tray?.findIndex(
        ({ id }) => id === chat.id
      );
      if (indexRequest !== -1) {
        state.chatsRequest.tray.splice(indexRequest, 1);
        state.chatsRequest.totalChatRequests -= 1;
      }
    },
    updateChatOnHold(
      state,
      action: PayloadAction<{
        id: string;
        fullName?: string;
        tags?: ChatTray['tags'];
        assignTags?: ChatTray['tags'];
        unassignTags?: ChatTray['tags'];
      }>
    ) {
      const chat = action.payload;
      const index = state.chatsOnHold.tray.findIndex(
        ({ id }) => id === chat.id
      );

      if (index !== -1) {
        const selected = state.chatsOnHold.tray[index];
        if (chat.fullName) selected.contact.fullName = chat.fullName;

        if (chat.tags) selected.tags = chat.tags;

        if (chat.assignTags)
          selected.tags = unionBy(selected.tags, chat.assignTags, 'tagName');

        if (chat.unassignTags)
          selected.tags = differenceBy(
            selected.tags,
            chat.unassignTags,
            'tagName'
          );

        if (state.activeChat?.id === selected.id) state.activeChat = selected;
      }
    },
    incrementTotalChatsOnHold(state) {
      state.chatsOnHold.totalChatsOnHold += 1;
    },
    addChatRequest(state, action: PayloadAction<ChatTray>) {
      const chat = action.payload;
      const index = state.chatsRequest.tray.findIndex(
        ({ id }) => id === chat.id
      );
      if (index === -1) {
        state.chatsRequest.tray.push(chat);
        state.chatsRequest.totalChatRequests += 1;
      } else {
        state.chatsRequest.tray[index] = chat;
      }
    },
    incrementTotalChatsRequest(state) {
      state.chatsRequest.totalChatRequests += 1;
    },

    setPinningChat(
      state,
      action: PayloadAction<{ id: string; pinId: number }>
    ) {
      const { id: chatId, pinId } = action.payload;
      const chatIndex = state.chatsOnHold.tray.findIndex(
        chat => chat.id === chatId
      );
      if (chatIndex !== -1) {
        state.chatsOnHold.tray[chatIndex].pinId = pinId;
      }
    },

    deleteChatAction(state) {
      delete state.actionChat;
    },

    awaitActivateChat(state) {
      state.transformTextAI = initialState.transformTextAI;
      state.actionChat = {
        type: ChatActionType.ACTIVATION_CHAT,
        status: ChatActionsStatus.AWAIT,
      };
    },

    activeChatSuccess(state, action: PayloadAction<string>) {
      const activeChatId = action.payload;
      const activeChat = state.chatsOnHold?.tray?.find(
        chat => chat.id === activeChatId
      );
      if (!activeChat) return;
      appConfig.setData('activeChat', activeChatId);
      state.activeChat = activeChat;

      state.actionChat = {
        type: ChatActionType.ACTIVATION_CHAT,
        status: ChatActionsStatus.SUCCESS,
      };
    },

    activeChatFailure(state) {
      state.actionChat = {
        type: ChatActionType.ACTIVATION_CHAT,
        status: ChatActionsStatus.FAILURE,
      };
    },

    resumeChatRequested(
      state,
      { payload }: PayloadAction<{ chatId: string; lastMsgSentAt: number }>
    ) {
      const chat = state.chatsOnHold.tray.find(
        ({ id }) => id === payload.chatId
      );
      if (chat) {
        chat.lastMsgSentAt = payload.lastMsgSentAt;
      }
    },

    awaitChatClose(state) {
      state.actionChat = {
        type: ChatActionType.CLOSE_CHAT,
        status: ChatActionsStatus.AWAIT,
      };
    },

    chatCloseFailure(state, { payload }: PayloadAction<ChatActionErrorDetail>) {
      state.actionChat = {
        type: ChatActionType.CLOSE_CHAT,
        status: ChatActionsStatus.FAILURE,
        details: payload,
      };
    },

    chatClosed(state, { payload: chatId }: PayloadAction<string>) {
      const index =
        state.chatsOnHold?.tray?.findIndex(chat => chat.id === chatId) ?? -1;
      if (index !== -1) {
        state.chatsOnHold.tray.splice(index, 1);
        state.chatsOnHold.totalChatsOnHold -= 1;
        delete state.activeChat;
        state.actionChat = {
          type: ChatActionType.CLOSE_CHAT,
          status: ChatActionsStatus.SUCCESS,
        };
      }
    },

    awaitChatExportedToCrm(state) {
      state.actionChat = {
        type: ChatActionType.EXPORT_CRM_CHAT,
        status: ChatActionsStatus.AWAIT,
      };
    },

    chatExportedToCrmFailure(
      state,
      { payload }: PayloadAction<ChatActionErrorDetail>
    ) {
      state.actionChat = {
        type: ChatActionType.EXPORT_CRM_CHAT,
        status: ChatActionsStatus.FAILURE,
        details: payload,
      };
    },

    chatExportToCrm(
      state,
      {
        payload: { chatId, crmTicketId, crmProvider },
      }: PayloadAction<{
        chatId: string;
        crmTicketId: number;
        crmProvider: string;
      }>
    ) {
      const chat = state.chatsOnHold?.tray?.find(({ id }) => id === chatId);
      if (chat?.id) {
        chat.crmProvider = crmProvider;
        chat.crmTicketId = crmTicketId;
        updateActiveChat(state, chat);
        state.actionChat = {
          type: ChatActionType.EXPORT_CRM_CHAT,
          status: ChatActionsStatus.SUCCESS,
        };
      }
    },

    chatLeftByContact(state, { payload: chatId }: PayloadAction<string>) {
      const chat = state.chatsOnHold?.tray?.find(({ id }) => id === chatId);
      if (chat?.id) {
        chat.status = ChatStatus.LEFT_BY_CONTACT as keyof typeof ChatStatus;
        updateActiveChat(state, chat);
      }
    },

    chatClosedByContact(state, { payload: chatId }: PayloadAction<string>) {
      const chat = state.chatsOnHold?.tray?.find(({ id }) => id === chatId);
      if (chat?.id) {
        chat.status = ChatStatus.CLOSED_BY_CONTACT as keyof typeof ChatStatus;
        updateActiveChat(state, chat);
      }
    },

    chatReopened(state, { payload: chatId }: PayloadAction<string>) {
      const chat = state.chatsOnHold?.tray?.find(({ id }) => id === chatId);
      if (chat?.id) {
        chat.status = ChatStatus.PICKED_UP as keyof typeof ChatStatus;
        updateActiveChat(state, chat);
      }
    },

    awaitChatPickUp(state) {
      state.actionChat = {
        type: ChatActionType.PICKUP_CHAT,
        status: ChatActionsStatus.AWAIT,
      };
    },

    chatPickUp(state, action: PayloadAction<string>) {
      const chatId = action.payload;
      const index = state.chatsRequest.tray.findIndex(
        chat => chat.id === chatId
      );
      if (index !== -1) {
        const [chat] = state.chatsRequest.tray.splice(index, 1);
        chat.status = ChatStatus.PICKED_UP as keyof typeof ChatStatus;
        state.activeChat = chat;
        state.chatsOnHold.totalChatsOnHold += 1;
        state.chatsRequest.totalChatRequests -= 1;
        state.chatsOnHold.tray.push(chat);
        state.actionChat = {
          type: ChatActionType.PICKUP_CHAT,
          status: ChatActionsStatus.SUCCESS,
        };
      }
    },

    chatPickUpFailure(state) {
      state.actionChat = {
        type: ChatActionType.PICKUP_CHAT,
        status: ChatActionsStatus.FAILURE,
      };
    },

    chatPickedByAnotherAgent(state, action: PayloadAction<string>) {
      const chatId = action.payload;
      const index = state.chatsRequest.tray.findIndex(
        chat => chat.id === chatId
      );
      if (index !== -1) {
        removeActiveChat(state, state.chatsRequest.tray[index]);
        state.chatsRequest.tray.splice(index, 1);
        state.chatsRequest.totalChatRequests -= 1;
        delete state.actionChat;
      }
    },

    chatAutoAssign(state, action: PayloadAction<string>) {
      const chatId = action.payload;
      const index = state.chatsRequest.tray.findIndex(
        chat => chat.id === chatId
      );
      if (index !== -1) {
        const [chat] = state.chatsRequest.tray.splice(index, 1);
        chat.status = ChatStatus.PICKED_UP as keyof typeof ChatStatus;
        state.chatsOnHold.tray.push(chat);
        state.chatsOnHold.totalChatsOnHold += 1;
        state.chatsRequest.totalChatRequests -= 1;
      }
    },

    saveContactChat(
      state,
      {
        payload: { contact, chatId },
      }: PayloadAction<{ contact: ChatContact; chatId: string }>
    ) {
      const index = state.chatsOnHold?.tray?.findIndex(
        ({ id }) => id === chatId
      );
      if (index !== -1) {
        state.chatsOnHold.tray[index].contact = contact;
        updateActiveChat(state, state.chatsOnHold.tray[index]);
      }
    },

    receivedMessageChat(
      state,
      {
        payload,
      }: PayloadAction<{
        chatId: string;
        message: ChatLastMessage;
        timestamp: number;
        chatIsAssigned: boolean;
      }>
    ) {
      const chats: ChatTray[] = payload.chatIsAssigned
        ? state.chatsOnHold.tray
        : state.chatsRequest.tray;
      const chat = chats.find(({ id }) => id === payload.chatId) as ChatTray;
      if (chat?.id) {
        chat.lastMessage = payload.message;
        chat.lastMsgReceivedAt = payload.message?.sendingTime
          ? payload.message?.sendingTime
          : payload.timestamp;
        chat.hasPassedMaxTime = false;
        chat.messagesSinceLastResponse += 1;

        const isClosedChat = [
          ChatStatus.CLOSED,
          ChatStatus.CLOSED_BY_CONTACT,
          ChatStatus.LEFT_BY_CONTACT,
        ].includes(chat.status);

        if (isClosedChat)
          chat.status = ChatStatus.OPENED as keyof typeof ChatStatus;

        updateActiveChat(state, chat);
      }
    },

    addQuotedMessage(
      state,
      {
        payload: { chatId, quotedMessage },
      }: PayloadAction<{ chatId: string; quotedMessage: QuotedMessage }>
    ) {
      if (!state.quotedMessages[chatId])
        state.quotedMessages[chatId] = quotedMessage;
    },

    removeQuotedMessage(state, { payload: chatId }: PayloadAction<string>) {
      if (state.quotedMessages[chatId]) delete state.quotedMessages[chatId];
    },

    changeWrittenMessage(
      state,
      {
        payload: { chatId, message },
      }: PayloadAction<{ chatId: string; message: string }>
    ) {
      state.writtenMessages[chatId] = message;
    },

    changeLastMessageChat(
      state,
      {
        payload: { chatId, message },
      }: PayloadAction<{ chatId: string; message: ChatLastMessage }>
    ) {
      const chat = state.chatsOnHold.tray.find(({ id }) => id === chatId);
      if (chat) {
        chat.lastMessage = message;
        chat.messagesSinceLastResponse = 0;

        if (message.direction === 'INCOMING') {
          chat.lastMsgReceivedAt = message?.sendingTime
            ? message?.sendingTime
            : chat.lastMsgReceivedAt;
        } else {
          chat.lastMsgSentAt = message?.sendingTime
            ? message?.sendingTime
            : chat.lastMsgReceivedAt;
        }

        updateActiveChat(state, chat);
      }
    },

    changeMessagesSinceLastResponseChat(
      state,
      {
        payload,
      }: PayloadAction<{ chatId: string; messagesSinceLastResponse: number }>
    ) {
      const chat = state.chatsOnHold.tray.find(
        ({ id }) => id === payload.chatId
      );
      if (chat) {
        chat.messagesSinceLastResponse = payload.messagesSinceLastResponse;
        updateActiveChat(state, chat);
      }
    },
    fetchTransformTextAI(state, action: PayloadAction<TransformTextAction>) {
      const { command, apiName, language } = action.payload;
      state.transformTextAI = {
        loading: 'pending',
        requestParams: {
          command,
          language: language || 'spanish',
          apiName: apiName || 'claude',
        },
        closeErrorMsg: true,
        error: undefined,
        outputText: undefined,
        disabledStop: false,
      };
    },
    fetchTransformTextAISuccess(state, action: PayloadAction<string>) {
      const { transformTextAI } = state;
      state.transformTextAI = {
        ...transformTextAI,
        outputText: action.payload,
        loading: 'success',
      };
    },
    changeTransformTextAI(state, action: PayloadAction<string>) {
      const { transformTextAI } = state;
      state.transformTextAI = {
        ...transformTextAI,
        outputText: action.payload,
        disabledStop: true,
      };
    },
    fetchTransformTextAIFailure(state, action: PayloadAction<ErrorPayload>) {
      const { transformTextAI } = state;
      state.transformTextAI = {
        ...transformTextAI,
        loading: 'failure',
        error: action.payload as ErrorPayload<'OPERATION_FAILED'>,
        closeErrorMsg: false,
      };
    },
    fetchCloseErrorMsg(state) {
      const { transformTextAI } = state;
      state.transformTextAI = {
        ...transformTextAI,
        closeErrorMsg: true,
        error: undefined,
      };
    },
    fetchTextActionAI(state, action: PayloadAction<string>) {
      const { transformTextAI, writtenMessages, activeChat } = state;
      if (action.payload === TextActionEnum.REPLACE) {
        if (activeChat && transformTextAI.outputText)
          writtenMessages[activeChat.id] = transformTextAI.outputText;
      }
      if (action.payload === TextActionEnum.REPLACE_SEND) {
        if (activeChat && transformTextAI.outputText) {
          writtenMessages[activeChat.id] = '';
        }
      }

      state.transformTextAI = initialState.transformTextAI;
    },
    fetchSegments(state) {
      state.segments = {
        data: [],
        loading: 'pending',
      };
    },
    fetchSegmentsSuccess(state, action: PayloadAction<Segments[]>) {
      state.segments = {
        data: action.payload,
        loading: 'success',
      };
    },
    fetchSegmentsFailure(state) {
      state.segments = {
        data: [],
        loading: 'failure',
      };
    },
  },
  extraReducers: builder => {
    chatAssignBuilder(builder);
    chatTransferBuilder(builder);
    updateChatConversationBuilder(builder);
    builder.addCase(LOGIN_AUTHENTICATION_REQUEST, (state, payload) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      state.activeAgentId = payload?.data?.id;
    });
  },
});

export default chatsSlice.reducer;

export const {
  fetchChatsOnHold,
  fetchChatsOnHoldFailure,
  fetchChatsOnHoldSuccess,
  fetchChatsOnHoldFulfill,
  fetchChatsRequest,
  fetchChatsRequestSuccess,
  fetchChatsRequestFailure,
  fetchChatsRequestFulfill,
  fetchCRMbutton,
  fetchCRMbuttonSuccess,
  fetchCRMbuttonFailure,
  fetchCRMinfo,
  fetchCRMinfoSuccess,
  fetchCRMinfoFailure,
  fetchNotificationTimeLimit,
  fetchNotificationTimeLimitFailure,
  setNotificationTimeLimit,
  fetchSearchChats,
  addChatRequest,
  addChatOnHold,
  updateChatOnHold,
  chatsRequestFocused,
  chatsOnHoldFocused,
  setPinningChat,
  awaitActivateChat,
  activeChatSuccess,
  activeChatFailure,
  resumeChatRequested,
  addQuotedMessage,
  removeQuotedMessage,
  changeWrittenMessage,
  awaitChatClose,
  chatClosed,
  chatCloseFailure,
  awaitChatPickUp,
  chatPickUp,
  chatPickUpFailure,
  chatPickedByAnotherAgent,
  saveContactChat,
  receivedMessageChat,
  chatLeftByContact,
  chatClosedByContact,
  awaitChatExportedToCrm,
  chatExportedToCrmFailure,
  chatExportToCrm,
  deleteChatAction,
  changeLastMessageChat,
  changeMessagesSinceLastResponseChat,
  increaseOffsetChatsOnHold,
  increaseOffsetChatsRequest,
  chatReopened,
  fetchTransformTextAI,
  fetchTransformTextAISuccess,
  fetchTransformTextAIFailure,
  fetchCloseErrorMsg,
  fetchTextActionAI,
  changeTransformTextAI,
  incrementTotalChatsOnHold,
  incrementTotalChatsRequest,
  chatAutoAssign,
  inactiveConsoleWindow,
  activeConsoleWindow,
  fetchSegments,
  fetchSegmentsSuccess,
  fetchSegmentsFailure,
} = chatsSlice.actions;
