/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
  fetchCountries,
  fetchCountriesFailure,
  fetchCountriesFulfill,
  fetchCountriesSuccess,
} from '@src/reducers/countries';
import { B2ChatAPI } from '@src/types/api';
import { ErrorData, MetaCountry } from '@types';

function* getCountriesSaga() {
  try {
    const response: B2ChatAPI.Response<MetaCountry[]> = yield call(
      B2ChatClient.resources.countries.actions.getMetaContries,
      {}
    );

    if (response.error) {
      yield put(fetchCountriesFailure(response.error));
    } else {
      yield put(fetchCountriesSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchCountriesFailure(error as ErrorData));
  } finally {
    yield put(fetchCountriesFulfill());
  }
}

export default function* countriesSaga() {
  yield takeEvery(fetchCountries, getCountriesSaga);
}
