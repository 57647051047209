export const UPDATE_FILED_STATUS_SUCCESS = 'UPDATE_FILED_STATUS_SUCCESS';
export const UPDATE_FILED_STATUS_FAIL = 'UPDATE_FILED_STATUS_FAIL';

const customFieldStatusUpdate = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FILED_STATUS_SUCCESS:
      return { ...state, success: action.data, status: true };
    case UPDATE_FILED_STATUS_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default customFieldStatusUpdate;
