// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: migrate this file to typescript
import { combineReducers } from 'redux';
import { CONNECTED_TO_BACKEND } from '../actions/agentconsole';
import {
  CHECK_CURRENT_ERROR,
  CLOSE_DIALOG_VIEWER,
  CLOSE_IMAGE_VIEWER,
  LOAD_IMAGE_ERROR,
  OPEN_DIALOG_VIEWER,
  OPEN_IMAGE_VIEWER,
  RAISE_NEW_ERROR,
  REMOVE_RECORD_IMG_ERROR,
} from '../actions/common';
import {
  INVALID_PASSWORD_LENGTH,
  INVALID_TOKEN,
  INVALID_USERNAME,
  MATCH_PASSWORD_CHANGED,
  PASSWORD_CHANGED,
  PASSWORD_CHANGED_ERROR,
  PASSWORD_CHANGED_SUCCESS,
  PASSWORD_CHANGE_REQUEST_ERROR,
  PASSWORD_CHANGE_REQUEST_SUCCESS,
  TOKEN_EXPIRED,
  UNMATCHED_PASSWORDS,
  USERNAME_CHANGED,
  USERNAME_EMAIL_CHANGED,
  USERNAME_REQUIRED,
  VALIDATED_TOKEN,
} from '../actions/password';
import AgentChatManager from '../logic/agentchatmanager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loginReducer = (state = { hasLoginError: false }, action) => state;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const countriesDataSetReducer = (state = {}, action) => state;

export const passwordChangeReqReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_CHANGE_REQUEST_SUCCESS:
      return { ...state, success: true, hasError: false };
    case PASSWORD_CHANGE_REQUEST_ERROR:
      return { ...state, success: false, hasError: true };
    case USERNAME_EMAIL_CHANGED:
      return { ...state, usernameOrEmail: action.usernameOrEmail };
    default:
      return state;
  }
};

export const changePasswordFormReducer = (state = {}, action) => {
  switch (action.type) {
    case VALIDATED_TOKEN:
      return { ...state, validatedToken: true, resetToken: action.resetToken };
    case INVALID_TOKEN:
      return { ...state, validatedToken: false };
    case USERNAME_CHANGED:
      return { ...state, username: action.username };
    case PASSWORD_CHANGED:
      return { ...state, password: action.password };
    case MATCH_PASSWORD_CHANGED:
      return { ...state, matchPassword: action.matchPassword };
    case PASSWORD_CHANGED_ERROR:
      return {
        ...state,
        tokenExpired: false,
        unknownError: true,
        passwordError: false,
        matchingPasswordError: false,
        success: false,
        usernameRequired: false,
        usernameInvalid: false,
      };
    case USERNAME_REQUIRED:
      return {
        ...state,
        tokenExpired: false,
        unknownError: false,
        passwordError: false,
        matchingPasswordError: false,
        success: false,
        usernameRequired: true,
        usernameInvalid: false,
      };
    case INVALID_USERNAME:
      return {
        ...state,
        tokenExpired: false,
        unknownError: false,
        passwordError: false,
        matchingPasswordError: false,
        success: false,
        usernameRequired: false,
        usernameInvalid: true,
      };
    case INVALID_PASSWORD_LENGTH:
      return {
        ...state,
        tokenExpired: false,
        unknownError: false,
        passwordError: true,
        matchingPasswordError: false,
        success: false,
        usernameRequired: false,
        usernameInvalid: false,
      };
    case TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: true,
        unknownError: false,
        passwordError: false,
        matchingPasswordError: false,
        success: false,
        usernameRequired: false,
        usernameInvalid: false,
      };
    case UNMATCHED_PASSWORDS:
      return {
        ...state,
        tokenExpired: false,
        unknownError: false,
        passwordError: false,
        matchingPasswordError: true,
        success: false,
        usernameRequired: false,
        usernameInvalid: false,
      };
    case PASSWORD_CHANGED_SUCCESS:
      return {
        ...state,
        tokenExpired: false,
        unknownError: false,
        passwordError: false,
        matchingPasswordError: false,
        success: true,
        usernameRequired: false,
        usernameInvalid: false,
      };
    default:
      return state;
  }
};

export const citiesDataSetReducer = (state = {}, action) => {
  switch (action.type) {
    case CONNECTED_TO_BACKEND:
      return AgentChatManager.getCitiesDataSet(state);
    default:
      return state;
  }
};

export const imageViewerReducer = (
  state = { open: false, imageUrl: null },
  action
) => {
  switch (action.type) {
    case OPEN_IMAGE_VIEWER:
      // Check that a valid image URL has been provided
      if (action.imageUrl != null && action.imageUrl !== '') {
        return { ...state, open: true, imageUrl: action.imageUrl };
      }

      return state;

    case CLOSE_IMAGE_VIEWER:
      return { ...state, open: false };

    default:
      return state;
  }
};

export const dialogViewerReducer = (state = {}, action) => {
  switch (action.type) {
    case OPEN_DIALOG_VIEWER:
      return {
        ...state,
        open: action.open,
        dialogType: action.dialogType,
        buttonLabel: action.buttonLabel,
        message: action.message,
        internalMessage: action.internalMessage,
      };
    case CLOSE_DIALOG_VIEWER:
      return {
        ...state,
        open: false,
        dialogType: 'Some title',
        buttonLabel: 'CLOSE',
        message: 'Something',
      };

    default:
      return state;
  }
};

export const forgotPasswordReducer = combineReducers({
  passwordChangeReqForm: passwordChangeReqReducer,
  changePasswordForm: changePasswordFormReducer,
});

export const errorsReducer = (state = {}, action) => {
  switch (action.type) {
    case RAISE_NEW_ERROR:
      return {
        ...state,
        hasUnreckonedError: true,
        currentError: {
          messsage: action.errorMsg,
        },
      };

    case CHECK_CURRENT_ERROR:
      return {
        ...state,
        hasUnreckonedError: false,
        currentError: {
          messsage: action.errorMsg,
        },
      };

    case LOAD_IMAGE_ERROR: {
      const { loadedImagesErrors } = state;
      const { file, maxSizeMB, notAllowedFileMsg, compressMsg } =
        action.errorMsg;

      return {
        ...state,
        loadedImagesErrors: [
          ...loadedImagesErrors,
          {
            file: {
              name: file.name,
              size: file.size / 1000000,
            },
            maxSizeMB,
            notAllowedFileMsg,
            compressMsg,
          },
        ],
      };
    }

    case REMOVE_RECORD_IMG_ERROR: {
      const { loadedImagesErrors } = state;
      const { fileName } = action;

      return {
        ...state,
        loadedImagesErrors: loadedImagesErrors.filter(
          image => image.file.name !== fileName
        ),
      };
    }

    default:
      return state;
  }
};
