export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';

const userDetails = (state = {}, action) => {
  switch (action.type) {
    case LOG_OUT:
      return { ...state, success: action.data, status: true };
    case LOG_OUT_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};

// const loginAuthenticationReducer = combineReducers({
//   loginAuthentication:loginAuthentication
// });

export default userDetails;
