import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData, MetaCountry } from '@types';

type Loading = 'fetch-countries';

export type CountriesState = {
  error: ErrorData | null;
  loading: Loading[];
  items: MetaCountry[];
};

type CountriesReducer<P = void> = (
  state: CountriesState,
  action: PayloadAction<P>
) => void;

const initialState: CountriesState = {
  error: null,
  loading: [],
  items: [],
};

const handleFetchCountries: CountriesReducer = state => {
  state.loading.push('fetch-countries');
  state.error = null;
};

const handleFetchCountriesSuccess: CountriesReducer<MetaCountry[]> = (
  state,
  action
) => {
  state.items = action.payload;
};

const handleFetchCountriesFailure: CountriesReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchCountriesFulfill: CountriesReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-countries');
};

const countriesSlice = createSlice({
  name: 'countriesSlice',
  initialState,
  reducers: {
    fetchCountries: handleFetchCountries,
    fetchCountriesSuccess: handleFetchCountriesSuccess,
    fetchCountriesFailure: handleFetchCountriesFailure,
    fetchCountriesFulfill: handleFetchCountriesFulfill,
  },
});

const { actions, reducer } = countriesSlice;

export const {
  fetchCountries,
  fetchCountriesSuccess,
  fetchCountriesFailure,
  fetchCountriesFulfill,
} = actions;

export default reducer;
