import { combineReducers } from 'redux';
import {
  LOAD_DEFAULT_FIELDS,
  LOADING_FILTERS,
  START_LOADING_CHATS_LIST,
  OK_LOADED_ORDERS_LIST,
  RESET_ALL_FILTER_VALUES,
  SELECTING_CHAT_ROW,
  START_LOADING_CONTACTS_LIST,
  OK_LOADED_CONTACTS_LIST,
  START_LOADING_STATES_LIST,
  OK_LOADED_STATES_LIST,
  ERROR_LOADED_CONTACTS_LIST,
  ERROR_LOADED_CHATS_LIST,
  START_LOADING_EMAIL,
} from '../actions/order';

const exportReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_EMAIL:
      return { ...state, email: action.email };
    default:
      return state;
  }
};

const filtersReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_DEFAULT_FIELDS:
      return {
        ...state,
        orderNumberLike: action.orderNumberLike,
        orderStateId: action.orderStateId,
        contactName: action.contactName,
        contactIdentificationNumber: action.contactIdentificationNumber,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case RESET_ALL_FILTER_VALUES:
      return {
        ...state,
        orderNumberLike: action.orderNumberLike,
        orderStateId: action.orderStateId,
        contactIdentificationNumber: action.contactIdentificationNumber,
        contactName: action.contactName,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    default:
      return state;
  }
};

const orderToolBarReducer = (state = {}, action) => {
  switch (action.type) {
    case LOADING_FILTERS:
      return { ...state, filters: action.filters };
    case LOAD_DEFAULT_FIELDS:
      return { ...state, filters: filtersReducer('', action) };
    case RESET_ALL_FILTER_VALUES:
      return { ...state, filters: filtersReducer('', action) };
    case START_LOADING_CONTACTS_LIST:
      return { ...state, contactLoadStatus: 'LOADING' };
    case OK_LOADED_CONTACTS_LIST:
      return {
        ...state,
        contactLoadStatus: 'OK_LOADED',
        contacts: action.results.contacts,
      };
    case ERROR_LOADED_CONTACTS_LIST:
      return {
        ...state,
        contactLoadStatus: 'ERROR_LOADED',
        errorContactMessage: action.message,
      };
    case START_LOADING_STATES_LIST:
      return { ...state, orderStateLoadStatus: 'LOADING' };
    case OK_LOADED_STATES_LIST:
      return {
        ...state,
        orderStateLoadStatus: 'OK_LOADED',
        orderStates: action.results.states,
      };

    default:
      return state;
  }
};

const orderChatReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_CHATS_LIST:
      return { ...state, loadStatus: 'LOADING', orderIdSelected: '' };
    case OK_LOADED_ORDERS_LIST:
      return {
        ...state,
        loadStatus: 'OK_LOADED',
        orders: action.results.orders,
        filterCount: action.filterCount,
        pageCount: action.pageCount,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
      };
    case RESET_ALL_FILTER_VALUES:
      return {
        ...state,
        loadStatus: null,
        orderIdSelected: '',
        orders: action.orders,
        filterCount: action.filterCount,
        pageCount: action.pageCount,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
      };
    case SELECTING_CHAT_ROW:
      return { ...state, orderIdSelected: action.orderIdSelected };
    case ERROR_LOADED_CHATS_LIST:
      return {
        ...state,
        loadStatus: 'ERROR_LOADED',
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

const orderReducer = combineReducers({
  toolbar: orderToolBarReducer,
  order: orderChatReducer,
  export: exportReducer,
});

export default orderReducer;
