import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorData, StandardMessage } from '@types';

export type StandardMessageState = {
  loading: 'pending' | 'idle' | 'saving';
  botId: number;
  messages: StandardMessage[];
  defaultMessages?: { [code: string]: StandardMessage['standardMessage'] };
  error?: ErrorData;
};

const initialState: StandardMessageState = {
  loading: 'idle',
  messages: [],
  defaultMessages: undefined,
  botId: -1,
};

export type FetchStandardMessagesAction = PayloadAction<{ botId: number }>;

const standardMessagesSlice = createSlice({
  name: 'standardMessages',
  initialState,
  reducers: {
    fetchStandardMessages(state, action: FetchStandardMessagesAction) {
      state.loading = 'pending';
      state.messages = [];
      state.botId = action.payload.botId;
      state.error = undefined;
    },
    fetchStandardMessagesSuccess(
      state,
      action: PayloadAction<
        Partial<Pick<StandardMessageState, 'defaultMessages' | 'messages'>>
      >
    ) {
      if (action.payload.messages)
        state.messages = action.payload.messages.sort((l, r) =>
          r.standardMessage.code.localeCompare(l.standardMessage.code)
        );

      if (action.payload.defaultMessages)
        state.defaultMessages = action.payload.defaultMessages;
    },
    fetchStandardMessagesFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchStandardMessagesFulfill(state) {
      state.loading = 'idle';
    },
    saveStandardMessages(state) {
      state.loading = 'saving';
    },
    saveStandardMessagesFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    saveStandardMessagesFullfill(state) {
      state.loading = 'idle';
    },
    updateStandardMessageValue(
      state,
      action: PayloadAction<{ code: string; value: string }>
    ) {
      const message = state.messages.find(
        m => m.standardMessage.code === action.payload.code
      );
      if (message) message.standardMessage.value = action.payload.value;
    },
    enableStandardMessage(
      state,
      action: PayloadAction<{ code: string; enabled: boolean }>
    ) {
      const message = state.messages.find(
        m => m.standardMessage.code === action.payload.code
      );
      if (message) message.enabled = action.payload.enabled;
    },
  },
});

export const {
  fetchStandardMessages,
  fetchStandardMessagesFailure,
  fetchStandardMessagesFulfill,
  fetchStandardMessagesSuccess,
  saveStandardMessages,
  saveStandardMessagesFailure,
  saveStandardMessagesFullfill,
  updateStandardMessageValue,
  enableStandardMessage,
} = standardMessagesSlice.actions;

export default standardMessagesSlice.reducer;
