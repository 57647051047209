import B2ChatClient from '@client-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from '@redux-saga/core/effects';
// eslint-disable-next-line import/no-unresolved
import { SagaIterator } from '@redux-saga/types';
import {
  AutomaticMessage,
  AutomaticMessagesState,
  fetchEventTypes,
  fetchEventTypesSuccess,
  searchMessages,
  searchMessagesFailure,
  searchMessagesFulfill,
  searchMessagesSuccess,
  updateMessage,
  updateMessageFailure,
  updateMessageFulfill,
  updateMessageSuccess,
} from '@src/reducers/automaticMessages';
import { selectAutomaticMessages } from '@src/selectors/automaticMessages';
import { isErrorPayload } from '@src/services/service';
import { Pagination } from '@src/types';
import { B2ChatAPI } from '@types-api';

export function* searchAutomaticMessagesSaga(): SagaIterator {
  try {
    const { messages }: AutomaticMessagesState = yield select(
      selectAutomaticMessages
    );
    const response: B2ChatAPI.Response<Pagination<AutomaticMessage>> =
      yield call(
        B2ChatClient.resources.conversationsFollowUpMessages.actions.search,
        {
          data: {
            ...messages.filter,
            department: messages.filter.department || 'ALL',
          },
        }
      );

    if (response.error) throw response.error;

    yield put(searchMessagesSuccess(response.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (isErrorPayload(e)) {
      yield put(searchMessagesFailure(e));
    } else {
      yield put(
        searchMessagesFailure({
          errorCode: 'OPERATION_FAILED',
          details: (e as Error).message,
          timestamp: Date.now(),
          traceId: '0',
        })
      );
    }
  } finally {
    yield put(searchMessagesFulfill());
  }
}
export function* fetchEventTypesSaga() {
  try {
    const response: B2ChatAPI.Response<AutomaticMessage.EventType[]> =
      yield call(
        B2ChatClient.resources.conversationsFollowUpMessages.actions.listEvents
      );

    if (response.data) {
      yield put(fetchEventTypesSuccess(response.data));
    } else {
      throw response.error;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function* updateAutomaticMessageSaga(
  action: ReturnType<typeof updateMessage>
) {
  try {
    const { mode, data, skipWarnings } = action.payload;
    const response: B2ChatAPI.Response<AutomaticMessage> = yield call(
      [B2ChatClient.resources.conversationsFollowUpMessages.actions, mode],
      {
        params:
          mode === 'update' || mode === 'delete'
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              { messageId: data.id! }
            : undefined,
        data: {
          state: data.state,
          name: data.name,
          hoursAfterStart: data.hoursAfterStart,
          hoursBeforeLastMessage: Math.min(
            data.hoursBeforeLastMessage || 23,
            data.hoursAfterStart || 23
          ),
          event: data.event,
          message: data.message,
          departmentIds:
            data.departmentNodes?.[0].name === 'allDepartments'
              ? undefined
              : data.departmentNodes?.map(dep => dep.departmentId),
          skipWarnings,
        },
      }
    );

    if (response.error) {
      throw response.error;
    }

    yield put(updateMessageSuccess(action.payload.data));

    const { messages }: AutomaticMessagesState = yield select(
      selectAutomaticMessages
    );
    yield put(searchMessages(messages.filter));
  } catch (e) {
    if (isErrorPayload(e)) {
      yield put(updateMessageFailure(e));
    } else if (e instanceof Error) {
      yield put(
        updateMessageFailure({
          details: e.message,
          errorCode: 'OPERATION_FAILED',
          timestamp: Date.now(),
          traceId: '',
        })
      );
    }
  } finally {
    yield put(updateMessageFulfill());
  }
}

export default function* automaticMessages() {
  yield takeLatest(searchMessages, searchAutomaticMessagesSaga);
  yield takeLeading(fetchEventTypes, fetchEventTypesSaga);
  yield takeLeading(updateMessage, updateAutomaticMessageSaga);
}
