import { validateE164Phone } from '../components/CustomMaterialUI/E164PhoneField';

const ContactForm = (function () {
  // States a contact form might be in
  const STATES = {
    CLEAR: '',
    LOADED: 'LOADED',
    SAVING: 'SAVING',
  };

  // Define the names of all the fields that make up the contact form
  const FIELDS = {
    ID: 'id',
    FULL_NAME: 'fullName',
    ID_NUMBER: 'identificationNumber',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    MOBILE_NUMBER: 'mobileNumber',
    ADDRESS: 'address',
    CITY_NAME: 'cityName',
    COUNTRY: 'country',
    COUNTRY_PARTIAL_NAME: 'countryPartialName',
    PROVINCE: 'province',
    PROVINCE_PARTIAL_NAME: 'provincePartialName',
    CITY: 'city',
    COMPANY: 'company',
  };

  // Verifies that the new value to be set on the specified field is valid
  const validateFieldValue = (fieldName, newValue) => {
    switch (fieldName) {
      case FIELDS.FULL_NAME:
        if (!newValue || String(newValue).trim() === '') {
          return 'FormError.Contact.FullName.NotBlank';
        }
        return undefined;
      case FIELDS.PHONE_NUMBER:
        if (!validateE164Phone(newValue)) {
          return 'ContactEditorForm.errors.phoneNumberINVALID_VALUE';
        }
        return undefined;
      case FIELDS.MOBILE_NUMBER:
        if (!validateE164Phone(newValue)) {
          return 'ContactEditorForm.errors.mobileNumberINVALID_VALUE';
        }
        return undefined;
      default:
        return undefined;
    }
  };

  // Update the value of a specific field of a contact form. Returns the updated contact form (or an updated clone)
  const setField = (contactForm, fieldName, newValue, cloneForm = true) => {
    const newValueObj = {
      value: newValue !== null && newValue !== undefined ? newValue : '',
    };
    let updatedContactForm = contactForm;

    // Check if this call to setField should update the provided contact form or clone it and update the clone
    if (cloneForm) {
      updatedContactForm = { ...contactForm };
    }

    // Validate the field value
    newValueObj.error = validateFieldValue(fieldName, newValue);

    contactForm.fields[fieldName] = newValueObj;
    return updatedContactForm;
  };

  // Load form object from the specified contact object, or if null, initialize an empty form
  const load = (contactForm, contact, citiesDataSet, cloneForm = true) => {
    let newContactForm = contactForm;

    // Check if this call to setField should update the provided contact form or clone it and update the clone
    if (cloneForm) {
      newContactForm = { ...contactForm };
    }

    newContactForm.fields[FIELDS.ID] = { value: '' };
    newContactForm.fields[FIELDS.FULL_NAME] = { value: '' };
    newContactForm.fields[FIELDS.ID_NUMBER] = { value: '' };
    newContactForm.fields[FIELDS.EMAIL] = { value: '' };
    newContactForm.fields[FIELDS.PHONE_NUMBER] = { value: '' };
    newContactForm.fields[FIELDS.MOBILE_NUMBER] = { value: '' };
    newContactForm.fields[FIELDS.ADDRESS] = { value: '' };
    newContactForm.fields[FIELDS.CITY_NAME] = { value: '' };
    // Note that the value of the city field in the form object is an integer which corresponds to the INDEX of the
    // contact's city in the citiesDataSet (the datasource that feeds the respective autocomplete field)
    newContactForm.fields[FIELDS.CITY] = { value: -1 };
    // Country on the other hand, is an object made up of the isoCode and name of the country
    newContactForm.fields[FIELDS.COUNTRY] = {
      value: { isoCode: null, name: '' },
    };
    newContactForm.fields[FIELDS.COUNTRY_PARTIAL_NAME] = { value: '' };
    // Province is also an object made up of the id and name of the province
    newContactForm.fields[FIELDS.PROVINCE] = { value: { id: null, name: '' } };
    newContactForm.fields[FIELDS.PROVINCE_PARTIAL_NAME] = { value: '' };

    if (contact) {
      setField(newContactForm, FIELDS.ID, contact[FIELDS.ID], false);
      setField(
        newContactForm,
        FIELDS.FULL_NAME,
        contact[FIELDS.FULL_NAME],
        false
      );
      setField(
        newContactForm,
        FIELDS.ID_NUMBER,
        contact[FIELDS.ID_NUMBER],
        false
      );
      setField(newContactForm, FIELDS.EMAIL, contact[FIELDS.EMAIL], false);
      setField(newContactForm, FIELDS.ADDRESS, contact[FIELDS.ADDRESS], false);
      setField(newContactForm, FIELDS.COMPANY, contact[FIELDS.COMPANY], false);
      setField(
        newContactForm,
        FIELDS.CITY_NAME,
        contact[FIELDS.CITY_NAME],
        false
      );

      // Make sure phone numbers are properly formatted
      let phoneNumber = contact[FIELDS.PHONE_NUMBER]
        ? contact[FIELDS.PHONE_NUMBER]
        : '';
      let mobilePhoneNumber = contact[FIELDS.MOBILE_NUMBER]
        ? contact[FIELDS.MOBILE_NUMBER]
        : '';

      phoneNumber =
        (phoneNumber === '' || phoneNumber.indexOf('+') === 0 ? '' : '+') +
        phoneNumber;
      mobilePhoneNumber =
        (mobilePhoneNumber === '' || mobilePhoneNumber.indexOf('+') === 0
          ? ''
          : '+') + mobilePhoneNumber;

      setField(newContactForm, FIELDS.PHONE_NUMBER, phoneNumber, false);
      setField(newContactForm, FIELDS.MOBILE_NUMBER, mobilePhoneNumber, false);

      // Find the index of the contact's city in the dataset that feeds the autocomplete field
      let contactCityId = null;
      let cityIxInDataSet = -1;

      if (contact[FIELDS.CITY] && contact[FIELDS.CITY].id) {
        contactCityId = contact[FIELDS.CITY].id;
      }

      for (
        let c = 0;
        citiesDataSet && contactCityId && c < citiesDataSet.length;
        c++
      ) {
        if (citiesDataSet[c].value === contactCityId) {
          cityIxInDataSet = c;
          break;
        }
      }

      setField(newContactForm, FIELDS.CITY, cityIxInDataSet, false);

      if (contact[FIELDS.COUNTRY] && contact[FIELDS.COUNTRY].isoCode) {
        const countryValue = {
          isoCode: contact[FIELDS.COUNTRY].isoCode,
          name: contact[FIELDS.COUNTRY].name,
        };
        setField(newContactForm, FIELDS.COUNTRY, countryValue, false);
        setField(
          newContactForm,
          FIELDS.COUNTRY_PARTIAL_NAME,
          countryValue.name,
          false
        );
      }

      if (contact[FIELDS.PROVINCE] && contact[FIELDS.PROVINCE].id) {
        const provinceValue = {
          id: contact[FIELDS.PROVINCE].id,
          name: contact[FIELDS.PROVINCE].name,
        };
        setField(newContactForm, FIELDS.PROVINCE, provinceValue, false);
        setField(
          newContactForm,
          FIELDS.PROVINCE_PARTIAL_NAME,
          provinceValue.name,
          false
        );
      }
    }

    return newContactForm;
  };

  // Validates all the fields of the specified form and sets the errors field with the number of errors found (0 if none)
  const validateForm = contactForm => {
    let curError = null;
    const arrFieldsWithError = [];

    for (const formField in contactForm.fields) {
      if (!contactForm.fields.hasOwnProperty(formField)) {
        continue;
      }

      // Validate the current field value
      curError = validateFieldValue(
        formField,
        contactForm.fields[formField].value
      );

      if (curError) {
        arrFieldsWithError.push({ fieldName: formField, error: curError });
      }
    }

    return arrFieldsWithError;
  };

  // Build a contact object using the information currently held into the fields of the specified form
  const toContactObject = (contactForm, citiesDataSet) => {
    const contactObj = {};

    contactObj[FIELDS.ID] = contactForm.fields[FIELDS.ID].value;
    contactObj[FIELDS.FULL_NAME] = contactForm.fields[FIELDS.FULL_NAME].value;
    contactObj[FIELDS.ID_NUMBER] = contactForm.fields[FIELDS.ID_NUMBER].value;
    contactObj[FIELDS.EMAIL] = contactForm.fields[FIELDS.EMAIL].value;
    contactObj[FIELDS.PHONE_NUMBER] =
      contactForm.fields[FIELDS.PHONE_NUMBER].value;
    contactObj[FIELDS.MOBILE_NUMBER] =
      contactForm.fields[FIELDS.MOBILE_NUMBER].value;
    contactObj[FIELDS.ADDRESS] = contactForm.fields[FIELDS.ADDRESS].value;
    contactObj[FIELDS.COMPANY] = contactForm.fields[FIELDS.COMPANY].value;
    contactObj[FIELDS.CITY_NAME] = contactForm.fields[FIELDS.CITY_NAME].value;
    contactObj[FIELDS.COUNTRY] = contactForm.fields[FIELDS.COUNTRY].value;
    contactObj[FIELDS.PROVINCE] = contactForm.fields[FIELDS.PROVINCE].value;

    // Map the index of the city selected in the form to the ID of the city as stored in the backed repository
    const contactCityIxInDataSet = contactForm.fields[FIELDS.CITY].value;
    const contactCityId =
      contactCityIxInDataSet >= 0
        ? citiesDataSet[contactCityIxInDataSet].value
        : null;

    contactObj[FIELDS.CITY] = {
      id: contactCityId,
    };

    return contactObj;
  };

  return {
    // States a contact form might be in
    STATES,
    // Field names
    FIELDS,
    // Load form object from the specified contact object, or if null, initialize an empty form
    load,
    // Update the value of a specific field of a contact form. Returns the updated contact form (or an updated clone)
    setField,
    // Validates all the fields of the specified form and sets the errors field with the number of errors found (0 if none)
    validateForm,
    // Build a contact object using the information currently held into the fields of the specified form
    toContactObject,
  };
})();

export default ContactForm;
