import { EventType } from '../model/frontendmodel';

// Counts the total number of messages in the agent chat that have been received after the
// last message sent by the agent, these are regarded as unreplied messages
export const countUnrepliedMessagesInChat = function (agentChat) {
  let totalUnrepliedMgsg = 0;

  if (agentChat) {
    if (
      agentChat.events &&
      agentChat.events.length &&
      agentChat.events.length > 0
    ) {
      // Iterate backwards over the chat events and count received messages until a sent message is found
      for (let m = agentChat.events.length - 1; m >= 0; m--) {
        const curEvent = agentChat.events[m];

        if (curEvent.type) {
          if (curEvent.type === EventType.INCOMING_MESSAGE.id) {
            totalUnrepliedMgsg += 1;
          } else if (curEvent.type === EventType.OUTGOING_MESSAGE.id) {
            break;
          }
        }
      }
    }
  }

  return totalUnrepliedMgsg;
};

// Counts the total number of unreplied messages in a set of agent chats,
// All the IDs of which are provided as argument
export const countUnrepliedMessagesInChatSet = function (arrAgentChats) {
  let countTotalMsgs = 0;

  if (arrAgentChats && arrAgentChats.forEach) {
    arrAgentChats.forEach(curAgentChat => {
      countTotalMsgs += countUnrepliedMessagesInChat(curAgentChat);
    });
  } else {
    throw new Error('Could not iterate over array of agent chat IDs');
  }

  return countTotalMsgs;
};

// Counts the total number of chats in the provided set, that have no events
// (i.e. are new chat requests for which no incoming messages have been received)
export const countChatsWithNoMessagesInSet = function (arrAgentChats) {
  let countTotalChatsNoMsg = 0;

  if (arrAgentChats && arrAgentChats.forEach) {
    arrAgentChats.forEach(curAgentChat => {
      countTotalChatsNoMsg +=
        curAgentChat.events && curAgentChat.events.length > 0 ? 0 : 1;
    });
  } else {
    throw new Error('Could not iterate over array of agent chat IDs');
  }

  return countTotalChatsNoMsg;
};

// Given an agent chat, determines the timestamp of the instant in which it was started
export const getChatStartTime = function (agentChat) {
  let chatStartTime = null;

  if (agentChat && agentChat.events) {
    // Search for the first message received event and take its time as start time of the chat
    for (let i = 0; i < agentChat.events.length; i++) {
      const curEvent = agentChat.events[i];
      if (curEvent.type && curEvent.type === EventType.INCOMING_MESSAGE.id) {
        if (curEvent.payload && curEvent.payload.sendingTime) {
          chatStartTime = curEvent.payload.sendingTime;
          break;
        }
      }
    }
  }

  return chatStartTime;
};
