import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '@types';

const codesWithAgentFullname = ['AGENT_REQUESTED_CHAT', 'AGENT_PICKED_UP_CHAT'];

export const standardMessagesSelector = (state: RootState) =>
  state.standardMessages;

export const standardMessagesFixedSelector = createDraftSafeSelector(
  standardMessagesSelector,
  state =>
    state.messages.map(
      ({ botId, enabled, standardMessage: { code, value } }) => ({
        botId,
        enabled,
        code,
        value: codesWithAgentFullname.includes(code)
          ? value.replaceAll(`\${AGENT_FULL_NAME}`, '{0}')
          : value,
      })
    )
);
