function safeJSONParse<T = unknown>(json: string): T | undefined;
function safeJSONParse<T = unknown>(json: string, defaultValue: T): T;

// eslint-disable-next-line @typescript-eslint/ban-types
function safeJSONParse(json: string, defaultValue?: object) {
  try {
    if (typeof json === 'string') return JSON.parse(json);
    // eslint-disable-next-line no-empty
  } catch {}
  return defaultValue;
}

export default safeJSONParse;
