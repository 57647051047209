import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FirstMessage, SendTo } from '@src/components/ChatsBehavior/types';
import type { ErrorData } from '@types';

type Loading =
  | 'fetch-message-types'
  | 'create-config'
  | 'fetch-config-list'
  | 'delete-config-list'
  | 'update-config-list';

export type MessageTypes = (
  | 'PRODUCT'
  | 'SHOPPING_CART'
  | 'MEDIA'
  | 'AD'
  | 'IG_POST'
)[];

export type ChatsBehaviorState = {
  error: ErrorData | null;
  loading: Loading[];
  messageTypes: MessageTypes;
  configList: HandleCreateChatsBehaviorConfig[];
};

type ChatsBehaviorReducer<P = void> = (
  state: ChatsBehaviorState,
  action: PayloadAction<P>
) => void;

const initialState: ChatsBehaviorState = {
  error: null,
  loading: [],
  messageTypes: [],
  configList: [],
};

const handleFetchChatsBehaviorMessageTypes: ChatsBehaviorReducer = state => {
  state.loading.push('fetch-message-types');
  state.error = null;
};

const handleFetchChatsBehaviorMessageTypesSuccess: ChatsBehaviorReducer<
  MessageTypes
> = (state, action) => {
  state.messageTypes = action.payload;
};

const handleFetchChatsBehaviorMessageTypesFailure: ChatsBehaviorReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

const handleFetchChatsBehaviorMessageTypesFulfill: ChatsBehaviorReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'fetch-message-types');
  };

export type HandleCreateChatsBehaviorConfig = {
  id: string;
  incomingMessage: FirstMessage;
  target: {
    type: SendTo;
    id: string;
  };
  configurationId?: string;
};

const handleCreateChatsBehaviorConfig: ChatsBehaviorReducer<
  Omit<HandleCreateChatsBehaviorConfig, 'id'>
> = state => {
  state.loading.push('create-config');
  state.error = null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleCreateChatsBehaviorConfigSuccess: ChatsBehaviorReducer = () => {};

const handleCreateChatsBehaviorConfigFailure: ChatsBehaviorReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

const handleCreateChatsBehaviorConfigFulfill: ChatsBehaviorReducer = state => {
  state.loading = state.loading.filter(l => l !== 'create-config');
};

export type HandlefetchChatsBehaviorConfigList =
  HandleCreateChatsBehaviorConfig;

const handlefetchChatsBehaviorConfigList: ChatsBehaviorReducer = state => {
  state.loading.push('fetch-config-list');
  state.error = null;
};

const handlefetchChatsBehaviorConfigListSuccess: ChatsBehaviorReducer<
  HandlefetchChatsBehaviorConfigList[]
> = (state, action) => {
  state.configList = action.payload;
};

const handlefetchChatsBehaviorConfigListFailure: ChatsBehaviorReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
  state.configList = [];
};

const handlefetchChatsBehaviorConfigListFulfill: ChatsBehaviorReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'fetch-config-list');
  };

export type HandleDeleteChatsBehaviorConfig = {
  configurationId: string;
};

const handleDeleteChatsBehaviorConfig: ChatsBehaviorReducer<
  HandleDeleteChatsBehaviorConfig
> = state => {
  state.loading.push('delete-config-list');
  state.error = null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleDeleteChatsBehaviorConfigSuccess: ChatsBehaviorReducer = () => {};

const handleDeleteChatsBehaviorConfigFailure: ChatsBehaviorReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

const handleDeleteChatsBehaviorConfigFulfill: ChatsBehaviorReducer = state => {
  state.loading = state.loading.filter(l => l !== 'delete-config-list');
};

export type HandleUpdateChatsBehaviorConfig = Omit<
  HandleCreateChatsBehaviorConfig,
  'id'
>;

const handleUpdateChatsBehaviorConfig: ChatsBehaviorReducer<
  HandleUpdateChatsBehaviorConfig
> = state => {
  state.loading.push('update-config-list');
  state.error = null;
};
const handleUpdateChatsBehaviorConfigSuccess =
  handleDeleteChatsBehaviorConfigSuccess;
const handleUpdateChatsBehaviorConfigFailure =
  handleDeleteChatsBehaviorConfigFailure;
const handleUpdateChatsBehaviorConfigFulfill: ChatsBehaviorReducer = state => {
  state.loading = state.loading.filter(l => l !== 'update-config-list');
};

const chatsBehaviorSlice = createSlice({
  name: 'ChatsBehaviorSlice',
  initialState,
  reducers: {
    fetchChatsBehaviorMessageTypes: handleFetchChatsBehaviorMessageTypes,
    fetchChatsBehaviorMessageTypesSuccess:
      handleFetchChatsBehaviorMessageTypesSuccess,
    fetchChatsBehaviorMessageTypesFailure:
      handleFetchChatsBehaviorMessageTypesFailure,
    fetchChatsBehaviorMessageTypesFulfill:
      handleFetchChatsBehaviorMessageTypesFulfill,
    createChatsBehaviorConfig: handleCreateChatsBehaviorConfig,
    createChatsBehaviorConfigSuccess: handleCreateChatsBehaviorConfigSuccess,
    createChatsBehaviorConfigFailure: handleCreateChatsBehaviorConfigFailure,
    createChatsBehaviorConfigFulfill: handleCreateChatsBehaviorConfigFulfill,
    fetchChatsBehaviorConfigList: handlefetchChatsBehaviorConfigList,
    fetchChatsBehaviorConfigListSuccess:
      handlefetchChatsBehaviorConfigListSuccess,
    fetchChatsBehaviorConfigListFailure:
      handlefetchChatsBehaviorConfigListFailure,
    fetchChatsBehaviorConfigListFulfill:
      handlefetchChatsBehaviorConfigListFulfill,
    deleteChatsBehaviorConfig: handleDeleteChatsBehaviorConfig,
    deleteChatsBehaviorConfigSuccess: handleDeleteChatsBehaviorConfigSuccess,
    deleteChatsBehaviorConfigFailure: handleDeleteChatsBehaviorConfigFailure,
    deleteChatsBehaviorConfigFulfill: handleDeleteChatsBehaviorConfigFulfill,
    updateChatsBehaviorConfig: handleUpdateChatsBehaviorConfig,
    updateChatsBehaviorConfigSuccess: handleUpdateChatsBehaviorConfigSuccess,
    updateChatsBehaviorConfigFailure: handleUpdateChatsBehaviorConfigFailure,
    updateChatsBehaviorConfigFulfill: handleUpdateChatsBehaviorConfigFulfill,
  },
});

const { actions, reducer } = chatsBehaviorSlice;

export const {
  fetchChatsBehaviorMessageTypes,
  fetchChatsBehaviorMessageTypesSuccess,
  fetchChatsBehaviorMessageTypesFailure,
  fetchChatsBehaviorMessageTypesFulfill,
  createChatsBehaviorConfig,
  createChatsBehaviorConfigSuccess,
  createChatsBehaviorConfigFailure,
  createChatsBehaviorConfigFulfill,
  fetchChatsBehaviorConfigList,
  fetchChatsBehaviorConfigListSuccess,
  fetchChatsBehaviorConfigListFailure,
  fetchChatsBehaviorConfigListFulfill,
  deleteChatsBehaviorConfig,
  deleteChatsBehaviorConfigSuccess,
  deleteChatsBehaviorConfigFailure,
  deleteChatsBehaviorConfigFulfill,
  updateChatsBehaviorConfig,
  updateChatsBehaviorConfigSuccess,
  updateChatsBehaviorConfigFailure,
  updateChatsBehaviorConfigFulfill,
} = actions;

export default reducer;
