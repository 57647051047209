import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '@src/types';

type RequestSpecialAccountState = {
  loading: ('pending' | 'success' | 'failure' | 'fulfill')[];
  error?: ErrorPayload;
};

const initialState: RequestSpecialAccountState = {
  loading: [],
};

type RequestEspecialAccount = {
  description: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  provider: string;
};

const requestSpecialAccountSlice = createSlice({
  name: 'requestSpecialAccount',
  initialState,
  reducers: {
    requestSpecialAccount(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<RequestEspecialAccount>
    ) {
      state.loading = ['pending'];
    },
    requestSpecialAccountSuccess(state) {
      state.loading.push('success');
    },
    requestSpecialAccountFailure(
      state,
      action: PayloadAction<{ error: ErrorPayload }>
    ) {
      state.loading.push('failure');
      state.error = action.payload.error;
    },
    requestSpecialAccountFulfill(state) {
      state.loading = [
        ...state.loading.filter(v => v === 'pending'),
        'fulfill',
      ];
    },
    resetRequestSpecialAccount() {
      return { loading: [] };
    },
  },
});

export const {
  requestSpecialAccount,
  requestSpecialAccountSuccess,
  requestSpecialAccountFailure,
  requestSpecialAccountFulfill,
  resetRequestSpecialAccount,
} = requestSpecialAccountSlice.actions;

export default requestSpecialAccountSlice.reducer;
