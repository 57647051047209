/* eslint-disable no-shadow */
import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import env from '../utils/env';

type Options = {
  mock?: boolean;
  query?: Record<string, string | number | boolean | Date>;
  params?: Record<string, string | number | boolean>;
};

/**
 * @brief if mock is true and NODE_ENV == 'development'
 * then returns mock endpoint else returns a real service endpoint
 * @returns {string}
 */
export default function service(path: string, options?: Options) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let query: any = '';
  let base = env.WEB_SERVICE_BASE_URI;

  if (options) {
    if (options.mock && env.SERVICE_MOCK) {
      base = 'http://localhost:3030';
    }
    if (options.params) {
      // eslint-disable-next-line no-param-reassign
      path = Object.entries(options.params).reduce(
        (path, [param, value]) =>
          path.replace(`:${param}`, encodeURIComponent(value)),
        path
      );
    }
    if (options.query) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformDateToISOString(options.query as any);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      query = new URLSearchParams(options.query as any);
    }
  }

  const url = new URL(path, base);
  url.search = query;

  return url.href;
}

export function transformDateToISOString(
  record: Record<string, Date | string>
) {
  if (record) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in record) {
      if (record[prop] instanceof Date)
        record[prop] = dayjs(record[prop]).format('YYYY-MM-DDTHH:mm:ssZ');
    }
  }
  return record;
}

export type ErrorPayload = {
  timestamp: number;
  errorCode: string;
  details: string;
  traceId: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorPayload(value: any): value is ErrorPayload {
  return value && ('errorCode' in value || 'error' in value);
}
