const buildInitialProductState = function () {
  return {
    toolbar: {
      productNameLike: '',
      active: true,
      categoryId: '',
      categories: [],
      statusLoadingCategories: '',
      errorMessage: '',
    },
    table: {
      products: [],
      statusLoadingProducts: '',
      errorMessage: '',
    },
    export: {
      email: '',
      open: false,
      errorMessage: '',
    },
  };
};

export default buildInitialProductState;
