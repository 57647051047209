import { MessagingProvider } from '../model/frontendmodel';

const MobileNumberValidator = (() => {
  const colMobileNumRegexp = /^\+?57\d{10}$/;

  return {
    //Validates for 'Colombia country code', for other ones always return true
    isValidForCountry(countryIsoCode, cellPhoneNumber) {
      if (countryIsoCode === 'CO') {
        return colMobileNumRegexp.test(cellPhoneNumber);
      }
      return true;
    },
  };
})();

export const maximumAllowedCharacters = provider => {
  const getMax = {
    DEFAULT: 5000,
    [MessagingProvider.INSTAGRAM]: 1000,
  };

  return getMax[provider] || getMax.DEFAULT;
};

export const isValidUrl = url => {
  const regExp = new RegExp(`^https?:\\/\\/[\\w\\-]+(\\.[\\w\\-]+)+[/#?]?.*$`);
  return regExp.test(url);
};

export const getFirstWordFromText = text => {
  const words = text?.trim().split(' ');
  return words?.length === 1 ? words[0] : '';
};

export const itsJustOneUrl = text => isValidUrl(getFirstWordFromText(text));

const PasswordValidator = (() => {
  // At least 10 characters. Must contain at least a lowercase letter, an uppercase letter and a number
  const STRONG_PASS_REGEX = /^(?=.{10,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;

  return {
    isStrongPassword(password) {
      if (!password) {
        return false;
      }

      return STRONG_PASS_REGEX.test(password);
    },
  };
})();

export const REQUIRED = 'REQUIRED';
export const DUPLICATE = 'DUPLICATE';
export const INVALID_VALUE = 'INVALID_VALUE';
export const EXCEEDED_MAX = 'EXCEEDED_MAX';
export const INVALID_PREFIX = 'INVALID_PREFIX';

export { MobileNumberValidator, PasswordValidator };
