/* eslint-disable no-shadow */
import { ErrorData } from '@b2chat/chat-center-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { QuickMessage } from '@types';

type Loading =
  | 'fetch-quick-message-list'
  | 'remove-quick-message'
  | 'save-quick-message';

// eslint-disable-next-line @typescript-eslint/ban-types
type Filter = { botId: string; filters: object };

export type SaveQuickMessageItemPayload = {
  botId: number;
  quickMessage: QuickMessage;
};

export type QuickMessagesState = {
  error?: ErrorData;
  loading: Loading[];
  filter?: Filter;
  items: QuickMessage[];
  item: QuickMessage | null;
};

const initialState: QuickMessagesState = {
  loading: [],
  items: [],
  item: null,
};

const quickMessagesSlice = createSlice({
  initialState,
  name: 'quickMessages',
  reducers: {
    fetchQuickMessageList(state, action: PayloadAction<Filter>) {
      state.loading.push('fetch-quick-message-list');
      state.filter = action.payload;
      state.error = undefined;
      state.items = [];
    },
    fetchQuickMessageListSuccess(state, action: PayloadAction<QuickMessage[]>) {
      state.items = action.payload;
    },
    fetchQuickMessageListFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchQuickMessageListFulfill(state) {
      state.loading = state.loading.filter(
        l => l !== 'fetch-quick-message-list'
      );
    },
    removeQuickMessageItem(state, action: PayloadAction<string>) {
      state.loading.push('remove-quick-message');
      const item = state.items.find(item => item.id === action.payload);
      if (item) item.hidden = true;
    },
    removeQuickMessageItemSuccess(state, action: PayloadAction<string>) {
      const index = state.items.findIndex(item => item.id === action.payload);
      if (index !== -1) state.items.splice(index, 1);
    },
    removeQuickMessageItemFailure(
      state,
      action: PayloadAction<{ id: string; error: ErrorData }>
    ) {
      // state.error = action.payload;
      const item = state.items.find(item => item.id === action.payload.id);
      if (item) item.hidden = false;
    },
    removeQuickMessageItemFulfill(state) {
      state.loading = state.loading.filter(l => l !== 'remove-quick-message');
    },
    saveQuickMessageItem(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<SaveQuickMessageItemPayload>
    ) {
      state.loading.push('save-quick-message');
      state.item = null;
    },
    saveQuickMessageItemSuccess(state, action: PayloadAction<QuickMessage>) {
      state.item = action.payload;
    },
    saveQuickMessageItemFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    saveQuickMessageItemFulfill(state) {
      state.loading = state.loading.filter(l => l !== 'save-quick-message');
    },
  },
});

export default quickMessagesSlice.reducer;

export const {
  fetchQuickMessageList,
  fetchQuickMessageListSuccess,
  fetchQuickMessageListFailure,
  fetchQuickMessageListFulfill,
  removeQuickMessageItem,
  removeQuickMessageItemSuccess,
  removeQuickMessageItemFailure,
  removeQuickMessageItemFulfill,
  saveQuickMessageItem,
  saveQuickMessageItemSuccess,
  saveQuickMessageItemFailure,
  saveQuickMessageItemFulfill,
} = quickMessagesSlice.actions;
