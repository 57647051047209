export const MENU_OPTION_SELECTED = 'MENU_OPTION_SELECTED';
export const MENU_OPTION_CLOSED = 'MENU_OPTION_CLOSED';

export function onItemSelected(itemIndex) {
  return {
    type: MENU_OPTION_SELECTED,
    itemIndex,
  };
}

export function onCloseMenu() {
  return (dispatch, getState) => {
    const { close } = getState().adminHome.menu;

    if (!close) {
      dispatch(closeMenu(true));
    } else {
      dispatch(closeMenu(false));
    }
  };
}

export function closeMenu(newValue) {
  return {
    type: MENU_OPTION_CLOSED,
    close: newValue,
  };
}
