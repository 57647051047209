export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_FAIL = 'REPORT_FAIL';
export const OPERATION_MODE = 'OPERATION_MODE';

const satisfactionReport = (state = {}, action) => {
  switch (action.type) {
    case REPORT_REQUEST:
      return { ...state, success: action.data, status: true };
    case REPORT_FAIL:
      return { ...state, failure: action.data, status: false };
    case OPERATION_MODE:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default satisfactionReport;
