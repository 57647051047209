export const SAVE_BOT_CONFIGURATION_REQUEST = 'SAVE_BOT_CONFIGURATION_REQUEST';
export const SAVE_BOT_CONFIGURATION_FAIL = 'SAVE_BOT_CONFIGURATION_FAIL';

const saveSatisfactionConfiguration = (state = {}, action) => {
  switch (action.type) {
    case SAVE_BOT_CONFIGURATION_REQUEST:
      return { ...state, success: action.data, status: true };
    case SAVE_BOT_CONFIGURATION_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default saveSatisfactionConfiguration;
