import B2ChatClient from '@client-sdk';
import {
  fetchIntegrations,
  fetchIntegrationsFailure,
  fetchIntegrationsFullfill,
  fetchIntegrationsSuccess,
  Integration,
  updateIntegration,
  updateIntegrationFailure,
  updateIntegrationFulfill,
  updateIntegrationSuccess,
} from '@src/reducers/integrations';
import { getMerchantId } from '@src/selectors/user';
import { isErrorPayload } from '@src/services/service';
import { B2ChatAPI } from '@types-api';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import uploadFileSaga from './uploadFileSaga';

function* fetchIntegrationsSaga() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: B2ChatAPI.Response<Record<string, any>[]> = yield call(
      B2ChatClient.resources.integrations.actions.getIntegration,
      {}
    );

    if (response.error) {
      throw response;
    }

    response.data
      .sort((a, b) => a.id - b.id)
      .forEach(item => {
        item.config = JSON.parse(item.config);
      });

    yield put(fetchIntegrationsSuccess(response.data as Integration[]));
  } catch (error) {
    if (isErrorPayload(error)) yield put(fetchIntegrationsFailure(error));
  } finally {
    yield put(fetchIntegrationsFullfill());
  }
}

function* updateIntegrationSaga(action: ReturnType<typeof updateIntegration>) {
  try {
    const { payload } = action;

    if (payload.config?.iconUrl instanceof File) {
      const result: { url: string } = yield call(
        uploadFileSaga,
        payload.config.iconUrl
      );

      payload.config.iconUrl = result.url;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: B2ChatAPI.Response<Record<string, any>[]>;

    if (payload.id) {
      response = yield call(
        B2ChatClient.resources.integrations.actions.updateIntegration,
        {
          data: {
            id: action.payload.id,
            config: JSON.stringify(action.payload.config),
            active: action.payload.active,
          },
        }
      );
    } else {
      const merchantId: number = yield select(getMerchantId);
      response = yield call(
        B2ChatClient.resources.integrations.actions.addIntegration,
        {
          data: {
            type: 'B2CHAT_JS_SDK',
            merchantId,
            config: JSON.stringify(action.payload.config),
            active: true,
          },
        }
      );
    }

    if (response.error) {
      throw response;
    }

    response.data.forEach(item => {
      item.config = JSON.parse(item.config);
    });

    yield put(updateIntegrationSuccess());
  } catch (error) {
    if (isErrorPayload(error)) yield put(updateIntegrationFailure(error));
  } finally {
    yield put(updateIntegrationFulfill());
    yield put(fetchIntegrations());
  }
}

export default function* itegrationsSaga() {
  yield takeLeading(fetchIntegrations, fetchIntegrationsSaga);
  yield takeLeading(updateIntegration, updateIntegrationSaga);
}
