const buildInitialFaqsCommonsState = function () {
  return {
    listBotsFaqsCommonsObject: {
      listBots: [],
      botValue: '',
      botValueConservate: false,
      //uniqueBot: false
    },
  };
};

export default buildInitialFaqsCommonsState;
