// eslint-disable-next-line import/no-cycle
import { ErrorData } from '@src/types';
import {
  UpdateCredentialStatusValues,
  type FetchOrdersHistoryType,
  type SaveShopifyStoreCredentials,
  type ShopifyCollection,
  type ShopifyConfigStores,
  type ShopifyCredential,
  type ShopifyInventory,
  type ShopifyProduct,
  type ShopifyProductOrigin,
  type ShopifyShop,
  type ShopifyStoreCredentials,
  type ShoppingCart,
  type ShoppingCartOrder,
  type ShoppingCartOrderUrl,
  type UpdateCredentialStatus,
} from '@src/types/shopify';
import {
  FilterKeys,
  HandleSetShopifyCredentials,
  HandleUpdateCheckboxFilter,
  OrderByParam,
  PageInfo,
  ShopifyCredentialsState,
  ShopifyReducer,
  ShopifyState,
  ShopifyTabsView,
  UpdateProductToShoppingCartPayload,
} from './types';
import {
  handleInitializeFilterParamsState,
  initPriceRangeFilter,
} from './utils';

export const handleFetchShopifyCredentials: ShopifyReducer<{
  merchantId: number;
}> = state => {
  state.error = null;
  state.loading.push('fetch-shopify-credentials');
};

export const handleFetchShopifyCredentialsSuccess: ShopifyReducer<
  ShopifyCredential
> = (state, action) => {
  state.credentials = action.payload;
  state.updatingCredentials = false;
};

export const handleFetchShopifyCredentialsFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  const { message } = action.payload;
  if (message.includes('The credential is not found with ID'))
    state.credentials = undefined;
  state.error = action.payload;
};

export const handleFetchShopifyCredentialsFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-shopify-credentials');
};

export const handleFetchShopifyStores: ShopifyReducer = state => {
  state.error = null;
  state.loading.push('fetch-shopify-credentials');
};

export const handleFetchShopifyStoresSuccess: ShopifyReducer<
  ShopifyConfigStores
> = (state, action) => {
  state.configStores = action.payload;
  state.alreadyHasStores = action.payload?.stores.length > 0;
  state.selectedLocationId = action.payload.currentLocationId || undefined;
  state.selectedStoreId =
    action.payload.currentStoreId || action.payload.stores?.at(0)?.id;
};

export const handleFetchShopifyStoresFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleFetchShopifyStoresFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-shopify-credentials');
};

export const handleSetShopifyCredentials: ShopifyReducer<
  HandleSetShopifyCredentials
> = state => {
  state.error = null;
  state.loading.push('set-shopify-credentials');
};

export const handleSetShopifyCredentialsSuccess: ShopifyReducer<
  ShopifyCredential
> = (state, action) => {
  state.updateCredentialStatus = UpdateCredentialStatusValues.SUCCESS;
  state.credentials = action.payload;
};

export const handleSetShopifyCredentialsFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.updateCredentialStatus = UpdateCredentialStatusValues.FAIL;
  state.error = action.payload;
};

export const handleSetShopifyCredentialsFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'set-shopify-credentials');
};

export const handleSaveShopifyStoreCredentials: ShopifyReducer<
  ShopifyCredentialsState
> = state => {
  state.error = null;
  state.loading.push('save-shopify-store-credentials');
};

export const handleSaveShopifyStoreCredentialsSuccess: ShopifyReducer<
  ShopifyCredentialsState
> = (state, action) => {
  state.credentials = action.payload;
  state.updateCredentialStatus = UpdateCredentialStatusValues.SUCCESS;
};

export const handleSaveShopifyStoreCredentialsFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.updateCredentialStatus = UpdateCredentialStatusValues.FAIL;
  state.error = action.payload;
};

export const handleSaveShopifyStoreCredentialsFulfill: ShopifyReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'save-shopify-store-credentials'
    );
  };

export const handleAddShopifyStoreCredentials: ShopifyReducer<
  ShopifyStoreCredentials
> = state => {
  state.error = null;
  state.loading.push('add-shopify-store-credentials');
};

export const handleAddShopifyStoreCredentialsSuccess: ShopifyReducer =
  state => {
    state.updateCredentialStatus = UpdateCredentialStatusValues.SUCCESS;
  };

export const handleAddShopifyStoreCredentialsFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.updateCredentialStatus = UpdateCredentialStatusValues.FAIL;
  state.error = action.payload;
};

export const handleAddShopifyStoreCredentialsFulfill: ShopifyReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'add-shopify-store-credentials'
    );
  };

export const handleDeleteShopifyStoreCredentials: ShopifyReducer<{
  storeId: string;
}> = state => {
  state.error = null;
  state.loading.push('delete-shopify-store-credentials');
};

export const handleDeleteShopifyStoreCredentialsSuccess: ShopifyReducer =
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {};

export const handleDeleteShopifyStoreCredentialsFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

export const handleDeleteShopifyStoreCredentialsFulfill: ShopifyReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'delete-shopify-store-credentials'
    );
  };

export const handleUpdateCredentialStatus: ShopifyReducer<
  UpdateCredentialStatus
> = (state, action) => {
  state.updateCredentialStatus = action.payload;
  state.updatingCredentials = false;
};

export const handleSetProductId: ShopifyReducer<number | undefined> = (
  state,
  action
) => {
  state.selectedProductId = action.payload;

  const product = state.inventory?.products.filter(
    item => item.id === action.payload
  );

  if (product) [state.productDetail] = product;
  else {
    // eslint-disable-next-line no-console
    console.error('Error: state.inventory.content and state.product are empty');
    return;
  }

  state.selectedVariantArrId = Array(state.productDetail.options.length).fill(
    0
  );
};

export const handleSetVariantId: ShopifyReducer<{
  indexOption: number;
  indexValue: number;
}> = (state, action) => {
  const { indexOption, indexValue } = action.payload;
  state.selectedVariantArrId = [
    ...state.selectedVariantArrId?.slice(0, indexOption),
    indexValue,
    ...state.selectedVariantArrId?.slice(indexOption + 1),
  ];
};

export const handleSendShopifyProduct: ShopifyReducer<{
  sendVariants?: boolean;
  origin: ShopifyProductOrigin;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}> = () => {};

export const handleFetchShopifyInventory: ShopifyReducer = state => {
  state.error = null;
  state.loading.push('fetch-shopify-inventory');
};

export const handleFetchShopifyInventorySuccess: ShopifyReducer<
  ShopifyInventory
> = (state, action) => {
  const { products, ...rest } = action.payload;
  if (state.inventory.products.length > 0) {
    state.inventory = {
      ...state.inventory,
      products: [...state.inventory.products, ...products],
      ...rest,
    };
  } else {
    state.inventory = action.payload;
  }
};

export const handleFetchShopifyInventoryFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleFetchShopifyInventoryFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-shopify-inventory');
};

export const handleFetchShopifyShop: ShopifyReducer<{
  storeId: string;
}> = state => {
  state.error = null;
  state.loading.push('fetch-shopify-shop');
};

export const handleFetchShopifyShopSuccess: ShopifyReducer<ShopifyShop> = (
  state,
  action
) => {
  state.shop = action.payload;
};

export const handleFetchShopifyShopFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleFetchShopifyShopFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-shopify-shop');
};

export const handleUpdateCheckboxFilter: ShopifyReducer<
  HandleUpdateCheckboxFilter
> = (state, action) => {
  const { key, label, checked, type } = action.payload;
  const [section] = state.filterParamsEditing.filter(fil => fil.key === key);
  if (!section) return;

  const [sectionItem] = section?.items.filter(item => item.label === label);
  if (sectionItem) {
    sectionItem.checked = checked;
    if (type === 'radio') {
      section.items.forEach(item => {
        if (item.label !== label) {
          item.checked = false;
        }
      });
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Error with parameters: {key, label}: ', action.payload);
  }
};

export const handleRemoveFilter: ShopifyReducer<{
  key: string;
  label: string;
}> = (state, action) => {
  const { key, label } = action.payload;
  const [section] = state.filterParams.filter(
    fil =>
      fil.key === key &&
      (fil.items.some(item => item.label === label) ||
        fil.items.at(0)?.type === 'range')
  );

  const [filterItem] = section.items.filter(
    item => item.label === label || item.type === 'range'
  );

  if (!section && !filterItem)
    // eslint-disable-next-line no-console
    console.error(
      `Error key/label doesn't exist: ${JSON.stringify(action.payload)}`
    );

  filterItem.checked = false;
  if (filterItem.type === 'range')
    filterItem.value = initPriceRangeFilter(state).value;

  state.filterParamsEditing = state.filterParams;

  handleResetOffset(state);
};

export const handleBuildFilterParamsState: ShopifyReducer = state => {
  if (
    state.filterParams.length &&
    state.previousSelectedLocationId !== state.selectedLocationId
  ) {
    const ind = state.filterParams.findIndex(
      item => item.key === FilterKeys.PRICES
    );
    const [prevItem] = state.filterParams[ind].items;

    state.filterParams = [
      ...state.filterParams.slice(0, ind),
      {
        key: FilterKeys.PRICES,
        items: [initPriceRangeFilter(state, prevItem)],
      },
      ...state.filterParams.slice(ind + 1),
    ];
  } else handleInitializeFilterParamsState(state);
};

export const handleSelectedLocationId: ShopifyReducer<number> = (
  state,
  action
) => {
  state.previousSelectedLocationId = state.selectedLocationId;
  state.selectedLocationId = action.payload;
  handleResetOffset(state);
};

export const handleSelectedStoreId: ShopifyReducer<string> = (
  state,
  action
) => {
  state.selectedStoreId = action.payload;

  if (state.shop) {
    const [firstLocation] = state.shop.locations;
    state.selectedLocationId = firstLocation.id;
  }

  handleResetOffset(state);
  handleInitializeFilterParamsState(state);
};

export const handleUpdateSearchPattern: ShopifyReducer<string> = (
  state,
  action
) => {
  state.searchPattern = action.payload;
  handleResetOffset(state);
};

export const handleUpdateOrderByParam: ShopifyReducer<OrderByParam> = (
  state,
  action
) => {
  const [sort, reverse] = action.payload.split('&');
  state.orderByParam.sort = sort as OrderByParam;
  state.orderByParam.reverse = !!reverse as boolean;

  handleResetOffset(state);
};

export const handleUpdatePriceRange: ShopifyReducer<number[]> = (
  state,
  action
) => {
  const [priceRange] = state.filterParamsEditing.filter(
    item => item.key === FilterKeys.PRICES
  );
  if (priceRange.items.length) {
    const [item] = priceRange.items;
    item.value = action.payload;
    item.checked = true;
  }
};

export const handleResetSearch = (state: ShopifyState) => {
  if (state.inventory) {
    state.inventory = {
      products: [],
      inventoryFilterOptions: {},
      totalProducts: 0,
    };
    delete state.inventory.pageInfo;
  }
};

export const handleResetOffset = (state: ShopifyState) => {
  if (state.inventory) {
    delete state.inventory.pageInfo;
  }
  handleResetSearch(state);
};

export const handleSendShopifyCatalog: ShopifyReducer = state => {
  state.error = null;
  state.loading.push('fetch-shopify-shop');
};

export const handleSendShopifyCatalogSuccess: ShopifyReducer<ShopifyShop> = (
  state,
  action
) => {
  state.shop = action.payload;
};

export const handleSendShopifyCatalogFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleSendShopifyCatalogFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-shopify-shop');
};

type IHandleFetchShopifySingleProduct = {
  productId: number;
  locationId: number;
};

export const handleFetchShopifySingleProduct: ShopifyReducer<
  IHandleFetchShopifySingleProduct
> = (
  state,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _action
) => {
  state.error = null;
  state.loading.push('fetch-shopify-single-product');
  state.selectedProductId = undefined;
};

export const handleFetchShopifySingleProductSuccess: ShopifyReducer<
  ShopifyProduct
> = (state, action) => {
  state.productDetail = action.payload;
  state.selectedProductId = action.payload.id;
  state.selectedVariantArrId = new Array(action.payload.options.length).fill(0);
};

export const handleFetchShopifySingleProductFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.productDetailError = action.payload;
  state.selectedProductId = undefined;
};

export const handleFetchShopifySingleProductFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(
    l => l !== 'fetch-shopify-single-product'
  );
};

export const handleCleanShopifyProductDetail: ShopifyReducer = state => {
  state.selectedProductId = undefined;
  state.selectedVariantArrId = [];
  state.productDetail = undefined;
  state.currentTabView = ShopifyTabsView.PRODUCT_LIST;
  handleResetSearch(state);
  handleCleanProductDetailError(state);
};

export const handleCleanProductDetailError = (state: ShopifyState) => {
  state.productDetailError = null;
};

export const handleCleanFilters = (state: ShopifyState) => {
  handleInitializeFilterParamsState(state);
  handleResetOffset(state);
};

export const handleSetShoppingTabView: ShopifyReducer<ShopifyTabsView> = (
  state,
  action?
) => {
  if (action?.payload) state.currentTabView = action.payload;
  else state.currentTabView = ShopifyTabsView.PRODUCT_LIST;
};

export const handleApplyFilters: ShopifyReducer = state => {
  state.filterParams = state.filterParamsEditing;
  handleResetOffset(state);
};

interface HandleUpdateCarouselImage {
  productId: number;
  imageUrl: string;
}

export const handleUpdateCarouselImage: ShopifyReducer<
  HandleUpdateCarouselImage
> = (state, action) => {
  const { productId, imageUrl } = action.payload;
  state.currentCarouselImage = {
    ...state.currentCarouselImage,
    [productId]: imageUrl,
  };
};

export const handleFetchShopifyShoppingCart: ShopifyReducer = state => {
  state.error = null;
  state.loading.push('fetch-shopify-shopping-cart');
};

export interface IHandleFetchShopifyShoppingCartSuccess {
  shoppingCart: ShoppingCart;
  contactId: string;
}

export const handleFetchShopifyShoppingCartSuccess: ShopifyReducer<
  IHandleFetchShopifyShoppingCartSuccess
> = (state, action) => {
  const { shoppingCart, contactId } = action.payload;
  state.shoppingCart = {
    [contactId]: shoppingCart,
  };
};

export const handleFetchShopifyShoppingCartFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

export const handleFetchShopifyShoppingCartFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(
    l => l !== 'fetch-shopify-shopping-cart'
  );
};

export const handleUpdateProductToShoppingCart: ShopifyReducer<
  UpdateProductToShoppingCartPayload
> = state => {
  state.loading.push('update-product-shoppint-cart');
};

export const handleUpdateProductToShoppingCartFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

export const handleUpdateProductToShoppingCartFulfill: ShopifyReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'update-product-shoppint-cart'
    );
  };
interface IHandleAddShoppingCart {
  variantId: string;
  quantity: number;
  quantityTotal?: number;
  origin: ShopifyProductOrigin;
}

export const handleAddShoppingCart: ShopifyReducer<
  IHandleAddShoppingCart
> = state => {
  state.loading.push('add-shopping-cart');
};

export const handleAddShoppingCartSuccess: ShopifyReducer<
  IHandleFetchShopifyShoppingCartSuccess
> = (state, action) => {
  const { contactId, shoppingCart } = action.payload;
  state.shoppingCart[contactId] = shoppingCart;
};

export const handleAddShoppingCartFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleAddShoppingCartFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'add-shopping-cart');
};

export interface IHandleRemoveProductFromShoppingCart {
  cartLinesId: string[];
}

export const handleRemoveProductFromShoppingCart: ShopifyReducer<
  IHandleRemoveProductFromShoppingCart
> = state => {
  state.loading.push('remove-product-from-shopping-cart');
};

export const handleRemoveProductFromShoppingCartFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

export const handleRemoveProductFromShoppingCartFulfill: ShopifyReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'remove-product-from-shopping-cart'
    );
  };

export const handleUpdateShopifyCredentials: ShopifyReducer<
  SaveShopifyStoreCredentials
> = state => {
  state.error = null;
  state.loading.push('update-shopify-credentials');
};

export const handleUpdateShopifyCredentialsSuccess: ShopifyReducer<
  ShopifyCredential
> = (state, action) => {
  state.updateCredentialStatus = UpdateCredentialStatusValues.SUCCESS;
  state.credentials = action.payload;
};

export const handleUpdateShopifyCredentialsFailure: ShopifyReducer<
  ErrorData
> = (state, action) => {
  state.updateCredentialStatus = UpdateCredentialStatusValues.FAIL;
  state.error = action.payload;
};

export const handleUpdateShopifyCredentialsFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'set-shopify-credentials');
};

export const handleUpdatingCredentials: ShopifyReducer = state => {
  state.updatingCredentials = true;
};

export interface IHandleFetchOrdersHistory {
  value: string;
  type: FetchOrdersHistoryType;
  pageInfo?: {
    startCursor?: string;
    endCursor?: string;
  };
  clean?: boolean;
}

export const handleFetchOrdersHistory: ShopifyReducer<
  IHandleFetchOrdersHistory
> = (state, action) => {
  if (action.payload.clean) state.ordersHistory = [];
  state.error = undefined;
  state.loading.push('fetch-orders-history');
};

export const handleCreateShopifyOrder: ShopifyReducer<{
  textMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}> = () => {};

export interface HandleFetchOrdersHistorySuccess {
  orders: ShoppingCartOrder[];
  pageInfo: PageInfo;
}

export const handleFetchOrdersHistorySuccess: ShopifyReducer<
  HandleFetchOrdersHistorySuccess
> = (state, action) => {
  const { orders, pageInfo } = action.payload;
  if (state.ordersHistory.length > 0) {
    state.ordersHistory = [...state.ordersHistory, ...orders];
    state.ordersHistory = state.ordersHistory.filter(
      (item, ind, self) => ind === self.findIndex(other => other.id === item.id)
    );
  } else {
    state.ordersHistory = orders;
  }
  state.ordersHistoryPageInfo = pageInfo;
};

export const handleFetchOrdersHistoryFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.ordersHistory = [];
};

export const handleFetchOrdersHistoryFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-orders-history');
};

export const handleFetchOrderHistoryById: ShopifyReducer<{
  id: string;
  sendUrl?: boolean;
}> = state => {
  state.loading.push('fetch-order-history-by-id');
};

export const handleFetchOrderHistoryByIdSuccess: ShopifyReducer<
  ShoppingCartOrderUrl
> = (state, action) => {
  state.orderUrl = action.payload;
};

export const handleFetchOrderHistoryByIdFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleFetchOrderHistoryByIdFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-order-history-by-id');
};

export const handleUpdateOrderInputValue: ShopifyReducer<
  FetchOrdersHistoryType
> = (state, action) => {
  state.orderInputValue = action.payload;
};

export const handleShopifyProductDetails: ShopifyReducer<
  IHandleFetchShopifySingleProduct
  // eslint-disable-next-line @typescript-eslint/no-empty-function
> = () => {};

export const handleOnClickSendShopifyProduct: ShopifyReducer<
  IHandleFetchShopifySingleProduct & {
    sendVariants?: boolean;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
> = () => {};

export const handleUpdateTryToRetrieveOnlyAvailableProducts: ShopifyReducer<
  boolean
> = (state, action) => {
  state.tryToRetrieveOnlyAvailableProducts = action.payload;
};

export const handleFetchShopifyCollections: ShopifyReducer = state => {
  state.error = null;
  state.loading.push('fetch-shopify-collections');
};

export const handleFetchShopifyCollectionsSuccess: ShopifyReducer<
  ShopifyCollection[]
> = (state, action) => {
  state.collections = action.payload;
};

export const handleFetchShopifyCollectionsFailure: ShopifyReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

export const handleFetchShopifyCollectionsFulfill: ShopifyReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-shopify-collections');
};

export const handleResetShopifyError: ShopifyReducer = state => {
  state.error = null;
};
