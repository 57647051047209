import B2ChatClient from '@client-sdk';
import {
  BotAccountItem,
  BotAccountState,
  fetchBotAccounts,
  fetchBotAccountsFailure,
  fetchBotAccountsFulfill,
  fetchBotAccountsSuccess,
  fetchBotButtons,
  fetchBotButtonsFailure,
  fetchBotButtonsFulfill,
  fetchBotButtonsSuccess,
  Messaging,
  onVerifyWhatsappButtons,
  updateBotAccount,
  updateBotAccountFailure,
  updateBotAccountSuccess,
  updateBotButtons,
  updateBotButtonsFailure,
  updateBotButtonsFulfill,
  updateBotButtonsSuccess,
} from '@reducers/botAccounts';
import { selectBotAccounts } from '@src/selectors/botAccounts';
import { isErrorPayload } from '@src/services/service';
import { Pagination } from '@src/types';
import { B2ChatAPI } from '@types-api';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

function* fetchBotAccountsSaga() {
  const { limit, offset, filter, sorting }: BotAccountState = yield select(
    selectBotAccounts
  );

  try {
    const response: B2ChatAPI.Response<Pagination<BotAccountItem>> = yield call(
      B2ChatClient.resources.merchant.actions.searchBotAccounts,
      {
        data: { limit, offset, filter, sorting },
      }
    );

    if (response.error) {
      throw response.error;
    }

    yield put(fetchBotAccountsSuccess(response.data));
  } catch (error) {
    if (isErrorPayload(error)) {
      yield put(fetchBotAccountsFailure(error));
    } else {
      yield put(
        fetchBotAccountsFailure({
          errorCode: 'UNKNOWN',
          details: (error as Error).message,
        })
      );
    }
  } finally {
    yield put(fetchBotAccountsFulfill());
  }
}

function* updateBotAccountSaga(action: ReturnType<typeof updateBotAccount>) {
  const { status, botAccount } = action.payload;

  try {
    const response: B2ChatAPI.Response<'ACTIVE' | 'SUSPENDED'> = yield call(
      B2ChatClient.resources.botServices.actions.botAccountState,
      {
        params: {
          botId: botAccount?.botId || '',
          botAccountId: botAccount?.id || '',
          state: status || '',
        },
        transformResponse: data => JSON.parse(data),
      }
    );

    if (response.error) {
      throw response.error;
    }

    yield put(
      updateBotAccountSuccess({ ...action.payload, status: response.data })
    );
  } catch (error) {
    if (isErrorPayload(error)) {
      yield put(updateBotAccountFailure(error));
    } else {
      yield put(
        updateBotAccountFailure({
          errorCode: 'UNKNOWN',
          details: (error as Error).message,
        })
      );
    }
  } finally {
    yield put(fetchBotAccountsFulfill());
  }
}

function* fetchBotButtonsSaga(action: ReturnType<typeof fetchBotButtons>) {
  try {
    const response: B2ChatAPI.Response<Messaging> = yield call(
      B2ChatClient.resources.config.actions.getBotButtons,
      {
        params: {
          modulename: action.payload,
        },
      }
    );

    const { data, error } = response;
    if (data) {
      // const {...rest, merchantId, module}=data;
      yield put(fetchBotButtonsSuccess(data));
    } else {
      throw error;
    }
  } catch (error: unknown) {
    yield put(
      fetchBotButtonsFailure({
        details: (error as Error)?.message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Error: Get bot buttons configuration',
      })
    );
  } finally {
    yield put(fetchBotButtonsFulfill());
    yield put(onVerifyWhatsappButtons());
  }
}

function* updateBotButtonsSaga(action: ReturnType<typeof updateBotButtons>) {
  try {
    const { messaging, modulename } = action.payload;
    const response: B2ChatAPI.Response<Messaging> = yield call(
      B2ChatClient.resources.config.actions.updateBotButtons,
      {
        params: {
          modulename,
        },
        data: messaging,
      }
    );

    const { error } = response;
    if (!error) {
      yield put(updateBotButtonsSuccess(messaging));
    } else {
      throw error;
    }
  } catch (error: unknown) {
    yield put(
      updateBotButtonsFailure({
        details: (error as Error)?.message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Error: Update bot buttons configuration',
      })
    );
  } finally {
    yield put(updateBotButtonsFulfill());
    yield put(onVerifyWhatsappButtons());
  }
}

function* botAccounts() {
  yield takeLatest(fetchBotAccounts, fetchBotAccountsSaga);
  yield takeEvery(updateBotAccount, updateBotAccountSaga);
  yield takeLatest(fetchBotButtons, fetchBotButtonsSaga);
  yield takeLatest(updateBotButtons, updateBotButtonsSaga);
}

export default botAccounts;
