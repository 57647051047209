/* eslint-disable global-require */
// Internally, it uses whatwg-fetch polyfill on the client, and node-fetch on
// the server, Be aware that any fetch polyfill assumes a Promise polyfill i
// is already present. The easiest way to ensure you have a Promise polyfill is
// to enable Babel's ES6 polyfill in your entry point before any other code runs
// import 'babel-polyfill'; /* Deprecated package: use  core-js & regenerator-runtime instead */
// backend mock
import AppUpdate from '@components/AppUpdate';
import SingletonTab from '@components/SingletonSession';
import { appUpdateFound } from '@reducers/appUpdate';
import 'core-js/stable';
// Required by MaterialUI
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { RawIntlProvider } from 'react-intl';
// i18n via React Intl
import { Provider } from 'react-redux';
// React Router is used to implement routes
import { datadogLogs } from '@datadog/browser-logs';
import { ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import 'regenerator-runtime/runtime';
// Error box component
// React component to asynchronously load other components dependending on routes (code-splitting)
import asyncComponent from './components/AsyncComponent/AsyncComponent';
import { BrowserDetect } from './components/BrowserDetect';
import LogDisplay from './components/LogDisplay';
import RedirectRoute from './components/RedirectRoute';
import './index.css';
import intl, { intlPolyfill } from './locales/intl';
// AgentChatManager module
import AgentChatManager from './logic/agentchatmanager';
import BackendProxy from './logic/backendproxy';
// Global ErrorHandler module
import ErrorHandler from './logic/errorhandler';
// Theming
import muiTheme, { CustomMuiV4ThemeProvider } from './muithemes/b2ChatTheme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// Redux store
import store from './store/reduxstore';
// use Spanish datetime format globally
import globalHistory from './globalHistory';
import { DataDogRumTracker } from './trackers/datadog';
import ErrorPage from './utils/ErrorPage';

import './utils/dayjs';

// service worker registration and setup on update
const configuration = {
  onUpdate() {
    store.dispatch(appUpdateFound());
  },
};

serviceWorkerRegistration.register(configuration);

// Connect the AgentChatManager module to the backend through a BackendProxy module
AgentChatManager.initialize(store);

// Link the global ErrorHandler module with the Redux store, to enable it to trigger actions
ErrorHandler.initialize(store);

// Start module in charge of loading chunks of chats whenever appropriate

const asyncLogin = () => import('./components/Login');
const asyncChatViewer = () => import('./components/ChatViewer');
const asyncUserActivation = () => import('./components/UserActivation');
const asyncUserAggregator = () => import('./components/UserAggregator');
const asyncAgentConsole = () => import('./components/AgentConsole');
const asyncAdminHome = () => import('./components/AdminHome');
const asyncForgotPassword = () => import('./components/Password');
const asyncChangePassword = () =>
  import('./components/Password/ChangePassword');
const asyncSignUp = () => import('./components/SignUp');
const asyncUnitTestRunner = () => import('./tests/UnitTestRunner');
const asyncWspOnboarding = () => import('./components/WspOnboarding');
const asyncVersionInfo = () => import('./components/VersionInfo');

const RootComponent = () => (
  <RawIntlProvider value={intl}>
    <Provider store={store}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <CustomMuiV4ThemeProvider>
          <SnackbarProvider maxSnack={5}>
            <div
              style={{
                minHeight: '100vh',
                height: '100vh',
                width: '100vw',
                overflow: 'auto',
              }}
            >
              <BrowserDetect />
              <SingletonTab />
              <LogDisplay topic="main" />

              <ErrorBoundary
                FallbackComponent={ErrorPage}
                onReset={() => {
                  window.location.reload();
                }}
                onError={(error: Error, info: ErrorInfo) =>
                  datadogLogs.logger.error(error.message, info)
                }
              >
                <BrowserRouter>
                  <Router history={globalHistory}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        component={asyncComponent(asyncLogin)}
                      />
                      <Route
                        path="/viewer/:chatId/:merchantToken"
                        component={asyncComponent(asyncChatViewer)}
                      />
                      <Route
                        path="/login"
                        component={asyncComponent(asyncLogin)}
                      />
                      <Route
                        path="/user-activation/:token"
                        component={asyncComponent(asyncUserActivation)}
                      />
                      <Route
                        path="/config-user-aggregator"
                        component={asyncComponent(asyncUserAggregator)}
                      />
                      <Route
                        path="/forgot-password"
                        component={asyncComponent(asyncForgotPassword)}
                      />
                      <Route
                        path="/change-password/:token"
                        component={asyncComponent(asyncChangePassword)}
                      />
                      <Route
                        path="/agent/chat"
                        component={asyncComponent(asyncAgentConsole)}
                      />
                      <Route
                        path="/admin"
                        component={asyncComponent(asyncAdminHome)}
                      />
                      <Route
                        path="/signup"
                        component={asyncComponent(asyncSignUp)}
                      />
                      <Route
                        path="/unittest"
                        component={asyncComponent(asyncUnitTestRunner)}
                      />

                      <Route
                        exact
                        path="/whatsapp-onboarding"
                        component={asyncComponent(asyncWspOnboarding)}
                      />

                      <Route
                        exact
                        path="/logout"
                        render={() => {
                          BackendProxy.logOut(
                            () => {
                              window.location.pathname = '/login';
                            },
                            () => {
                              window.location.pathname = '/login';
                            }
                          );
                        }}
                      />

                      <Route
                        exact
                        path="/version"
                        component={asyncComponent(asyncVersionInfo)}
                      />

                      <RedirectRoute />
                    </Switch>
                  </Router>
                  <DataDogRumTracker />
                  <AppUpdate />
                </BrowserRouter>
              </ErrorBoundary>
            </div>
          </SnackbarProvider>
        </CustomMuiV4ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </RawIntlProvider>
);

(async function renderRoot() {
  await intlPolyfill();
  ReactDOM.render(RootComponent(), document.getElementById('root'));
})();

// eslint-disable-next-line no-console
console.log('VERSION', window.version);
