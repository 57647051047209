/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '@src/types';

export type WhatsAppProfile = {
  alias: string;
  about: string;
  description?: string;
  address?: string;
  email?: string;
  vertical: string;
  websites: string[];
  photoFile?: File | string;
};

export type WhatsAppProfileState = {
  accountId: string;
  data: WhatsAppProfile;
  loading: {
    fetch: ('pending' | 'success' | 'failure' | 'fulfill')[];
    update: ('pending' | 'success' | 'failure' | 'fulfill')[];
  };
  error?: ErrorPayload;
};

export const whatsAppProfileInitialValues: WhatsAppProfile = {
  alias: '',
  about: '',
  description: '',
  address: '',
  email: '',
  vertical: '',
  websites: [],
};

const initialState: WhatsAppProfileState = {
  accountId: '',
  data: whatsAppProfileInitialValues,
  loading: {
    fetch: [],
    update: [],
  },
};

const whatsAppProfileSlice = createSlice({
  name: 'whatsAppProfile',
  initialState,
  reducers: {
    fetchWhatsAppProfile(state, action: PayloadAction<{ accountId: string }>) {
      state.loading.fetch = ['pending'];
      state.loading.update = [];
      state.data = initialState.data;
      state.accountId = action.payload.accountId;
    },
    fetchWhatsAppProfileSuccess(
      state,
      action: PayloadAction<{ accountId: string; data: WhatsAppProfile }>
    ) {
      const { accountId, data } = action.payload;
      if (accountId !== state.accountId) return;

      state.data = data;
      state.loading.fetch.push('success');
    },
    fetchWhatsAppProfileFailure(
      state,
      action: PayloadAction<{ accountId: string; error: ErrorPayload }>
    ) {
      const { accountId, error } = action.payload;
      if (accountId !== state.accountId) return;

      state.error = error;
      state.loading.fetch.push('failure');
    },
    fetchWhatsAppProfileFulfill(
      state,
      action: PayloadAction<{ accountId: string }>
    ) {
      const { accountId } = action.payload;
      if (accountId !== state.accountId) return;

      state.loading.fetch = [
        ...state.loading.fetch.filter(value => value !== 'pending'),
        'fulfill',
      ];
    },
    updateWhatsAppProfile(
      state,
      action: PayloadAction<{ accountId: string; data: WhatsAppProfile }>
    ) {
      const { accountId } = action.payload;
      if (accountId !== state.accountId) return;

      state.loading.update = ['pending'];
    },
    updateWhatsAppProfileSuccess(
      state,
      action: PayloadAction<{ accountId: string; data: WhatsAppProfile }>
    ) {
      const { accountId, data } = action.payload;
      if (accountId !== state.accountId) return;

      state.data = data;
      state.loading.update.push('success');
    },
    updateWhatsAppProfileFailure(
      state,
      action: PayloadAction<{ accountId: string; error: ErrorPayload }>
    ) {
      const { accountId, error } = action.payload;
      if (accountId !== state.accountId) return;

      state.error = error;
      state.loading.update.push('failure');
    },
    updateWhatsAppProfileFulfill(
      state,
      action: PayloadAction<{ accountId: string }>
    ) {
      const { accountId } = action.payload;
      if (accountId !== state.accountId) return;

      state.loading.update = [
        ...state.loading.update.filter(value => value !== 'pending'),
        'fulfill',
      ];
    },
  },
});

export default whatsAppProfileSlice.reducer;

export const {
  fetchWhatsAppProfile,
  fetchWhatsAppProfileSuccess,
  fetchWhatsAppProfileFailure,
  fetchWhatsAppProfileFulfill,
  updateWhatsAppProfile,
  updateWhatsAppProfileFailure,
  updateWhatsAppProfileFulfill,
  updateWhatsAppProfileSuccess,
} = whatsAppProfileSlice.actions;
