import { combineReducers } from 'redux';

import {
  START_LOADING_CONTACTS_LIST,
  OK_LOADED_CONTACTS_LIST,
  ERROR_LOADED_CONTACTS_LIST,
  START_LOADING_PRODUCTS_LIST,
  OK_LOADED_PRODUCTS_LIST,
  ERROR_LOADED_PRODUCTS_LIST,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  UPDATING_PRODUCTS_IN_ORDER,
  LOAD_PRODUCT_FIELD,
  START_CREATING_ORDER,
  OK_CREATED_ORDER,
  ERROR_CREATING_ORDER,
  UPDATING_CURRENT_REFERENCE_IN_CART,
  CLOSING_DIALOG,
} from '../actions/createorder';

const createOrderToolBarReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_CONTACTS_LIST:
      return { ...state, contactLoadStatus: 'LOADING' };
    case OK_LOADED_CONTACTS_LIST:
      return {
        ...state,
        contactLoadStatus: 'OK_LOADED',
        contacts: action.results.contacts,
      };
    case ERROR_LOADED_CONTACTS_LIST:
      return {
        ...state,
        contactLoadStatus: 'ERROR_LOADED',
        errorContactMessage: action.message,
      };

    case START_LOADING_PRODUCTS_LIST:
      return { ...state, productLoadStatus: 'LOADING' };
    case OK_LOADED_PRODUCTS_LIST:
      return {
        ...state,
        productLoadStatus: 'OK_LOADED',
        products: action.results,
      };
    case ERROR_LOADED_PRODUCTS_LIST:
      return {
        ...state,
        productLoadStatus: 'ERROR_LOADED',
        errorProductMessage: action.message,
      };
    case UPDATE_CONTACT:
      return { ...state, contactValue: action.contactValue };
    case DELETE_CONTACT:
      return {
        ...state,
        contactValue: action.contactValue,
        contactLoadStatus: 'OK_DELETED',
        contacts: [],
      };
    case UPDATING_PRODUCTS_IN_ORDER:
      return { ...state, productValue: action.productValue };
    case LOAD_PRODUCT_FIELD:
      return { ...state, productValue: action.productValue };
    default:
      return state;
  }
};

const createOrderContactReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CONTACT:
      return {
        ...state,
        loadStatus: 'OK_LOADED',
        id: action.id,
        fullName: action.fullName,
        identificationNumber: action.identificationNumber,
        phoneNumber: action.phoneNumber,
        mobileNumber: action.mobileNumber,
        email: action.email,
        city: action.city,
        address: action.address,
      };
    case DELETE_CONTACT:
      return {
        ...state,
        loadStatus: 'OK_DELETED',
        id: action.id,
        fullName: action.fullName,
        identificationNumber: action.identificationNumber,
        phoneNumber: action.phoneNumber,
        mobileNumber: action.mobileNumber,
        email: action.email,
        city: action.city,
        address: action.address,
      };
    default:
      return state;
  }
};
const createOrderProductReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATING_PRODUCTS_IN_ORDER:
      return { ...state, products: action.results };
    case UPDATING_CURRENT_REFERENCE_IN_CART:
      return { ...state, curReference: action.reference };

    default:
      return state;
  }
};
const createOrderDialogReducer = (state = {}, action) => {
  switch (action.type) {
    case ERROR_LOADED_PRODUCTS_LIST:
      return { ...state, openErrorModal: true };
    case ERROR_LOADED_CONTACTS_LIST:
      return { ...state, openErrorModal: true };
    case CLOSING_DIALOG:
      return { ...state, openErrorModal: false };
    default:
      return state;
  }
};
const createOrderNewOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case START_CREATING_ORDER:
      return { ...state, status: 'START_ORDER_CREATION' };
    case OK_CREATED_ORDER:
      return {
        ...state,
        status: 'OK_CREATED',
        orderNumber: action.orderNumber,
      };
    case ERROR_CREATING_ORDER:
      return { ...state, status: 'ERROR_CREATING', cause: action.cause };

    default:
      return state;
  }
};

const createOrderReducer = combineReducers({
  toolbar: createOrderToolBarReducer,
  contact: createOrderContactReducer,
  cart: createOrderProductReducer,
  dialog: createOrderDialogReducer,
  order: createOrderNewOrderReducer,
});

export default createOrderReducer;
