import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const TabButton = withStyles(theme => ({
  root: {
    minWidth: '60px',
    height: '60px',
    backgroundColor: '#FFF',
    opacity: 1,
    boxShadow:
      '0px -4px 8px rgba(0, 0, 0, 0.08), 0px 8px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: '0px 8px 8px 0px',
    fill: '#71717A',
  },
  selected: {
    fill: theme.palette.primary.main,
    marginLeft: '0px',
  },
}))(Tab);

export default TabButton;
