// eslint-disable-next-line import/no-cycle
import { Department, DepartmentType, RootState } from '@types';

const PADDING_LEFT = 10;

export const departmentsSelector = (state: RootState) => state.departments;

export const departmentItemByIdSelector =
  (state: RootState) => (departmentId: number) =>
    state.departments.items.find(
      department => departmentId && department.departmentId === departmentId
    );

export const isDepartmentModuleActiveSelector = (state: RootState) =>
  state.departments.isDepartmentModuleActive;

export const departmentsHierarchy = (state: RootState) => {
  let departments: Department[] = [];
  const parents = state.departments.items.filter(
    dep => dep.type === DepartmentType.DEPARTMENT && !dep.parentNodeId
  );

  const getChildrens = (childrens: Department[], pl: number) => {
    childrens.forEach(children => {
      const paddingLeft = pl + PADDING_LEFT;
      departments = [...departments, { ...children, paddingLeft }];

      if (children?.childrens) {
        getChildrens(children?.childrens, paddingLeft);
      }
    });
  };

  parents.forEach(parent => {
    departments = [...departments, { ...parent, paddingLeft: PADDING_LEFT }];
    if (parent?.childrens) {
      getChildrens(parent?.childrens, PADDING_LEFT);
    }
  });

  return departments;
};
