import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'fetch-partners-custom-ui-config';

export type PartnersCustomUiConfig = {
  primaryColor: string;
  secondaryColor: string;
  logoUrl: string;
  webSiteUrl: string;
};

export type PartnersCustomUiConfigState = {
  error: ErrorData | null;
  loading: Loading[];
  config: PartnersCustomUiConfig | undefined;
};

type PartnersCustomUiConfigReducer<P = void> = (
  state: PartnersCustomUiConfigState,
  action: PayloadAction<P>
) => void;

const initialState: PartnersCustomUiConfigState = {
  error: null,
  loading: [],
  config: undefined,
};

const handlePartnersCustomUiConfig: PartnersCustomUiConfigReducer = state => {
  state.loading.push('fetch-partners-custom-ui-config');
  state.error = null;
};

const handlePartnersCustomUiConfigSuccess: PartnersCustomUiConfigReducer<
  PartnersCustomUiConfig
> = (state, action) => {
  state.config = action.payload;
};

const handlePartnersCustomUiConfigFailure: PartnersCustomUiConfigReducer<
  ErrorData
> = (state, action) => {
  state.error = action.payload;
};

const handlePartnersCustomUiConfigFulfill: PartnersCustomUiConfigReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'fetch-partners-custom-ui-config'
    );
  };

const wsCatalogueSlice = createSlice({
  name: 'PartnersCustomUi',
  initialState,
  reducers: {
    fetchPartnersCustomUiConfig: handlePartnersCustomUiConfig,
    fetchPartnersCustomUiConfigSuccess: handlePartnersCustomUiConfigSuccess,
    fetchPartnersCustomUiConfigFailure: handlePartnersCustomUiConfigFailure,
    fetchPartnersCustomUiConfigFulfill: handlePartnersCustomUiConfigFulfill,
  },
});

const { actions, reducer } = wsCatalogueSlice;

export const {
  fetchPartnersCustomUiConfig,
  fetchPartnersCustomUiConfigSuccess,
  fetchPartnersCustomUiConfigFailure,
  fetchPartnersCustomUiConfigFulfill,
} = actions;

export default reducer;
