import TypeUtils from './typeutils';

// Builds an instance of a line appended to the log
const buildLogEntry = (msg, source) => ({
  time: new Date().getTime(),
  source,
  msg,
});

// Logging module. A singleton that can be invoked from anywhere in the application
const Log = (() => {
  let isLoggingEnabled = false;

  if (String(window.location.href).indexOf('debugMode=true') >= 0) {
    isLoggingEnabled = true;
  }

  // Contains all lines in the log
  const entries = [];

  // Listener functions. Callbacks invoked when the log gets updated
  let onNewEntry = () => {};
  let onReset = () => {};

  // Private function. Appends a new log entry
  const appendEntry = (msg, source = 'unknown') => {
    const newEntry = buildLogEntry(msg, source);

    entries.push(newEntry);

    onNewEntry(newEntry);
  };

  return {
    // Says if logging is currently enabled
    isLoggingEnabled: () => isLoggingEnabled,

    // Returns all lines in the log
    getLogEntries: () => entries,

    // Resets the logging instance
    reset: () => {
      if (TypeUtils.isFunction(onReset)) {
        onReset();
      }
    },

    // Sets up the listener functions. Each function will be called when the log gets updated in a certain way
    subscribe: (fnOnNewEntry, fnOnReset) => {
      if (TypeUtils.isFunction(fnOnNewEntry)) {
        onNewEntry = fnOnNewEntry;
      }

      if (TypeUtils.isFunction(fnOnReset)) {
        onReset = fnOnReset;
      }
    },

    // Builds an instance of a logger, a wrapper object to write lines to the log
    getLogger: (source = null) => ({
      log: (msg, ...args) => {
        if (!isLoggingEnabled) {
          return;
        }

        try {
          let strArgs = '';

          if (args && args.length && args.length > 0) {
            strArgs = ` ${String(args[0])}`;

            for (let i = 1; i < args.length; i++) {
              strArgs = `${strArgs}, ${String(args[i])}`;
            }
          }

          appendEntry(msg + strArgs, source);
        } catch (error) {
          if (console && console.log) {
            console.log('Error appending entry to log', error);
          }
        }
      },
    }),
  };
})();

export default Log;
