import { combineReducers } from 'redux';

import {
  STORE_LIST_BOTS,
  SELECT_BOT_VALUE,
  CONSERVATE_BOT_VALUE,
} from '../actions/faqscommons';

const listBotsCommonsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSERVATE_BOT_VALUE:
      var curState3 = { ...state };

      curState3.botValueConservate = true;

      return curState3;

    case SELECT_BOT_VALUE:
      var curState2 = { ...state };

      curState2.botValue = action.botValue;

      return curState2;

    case STORE_LIST_BOTS:
      console.log('REDUCER FAQS SETUP list bot object', action.listBots);

      var curState = { ...state };
      curState.listBots = action.listBots;
      //curState35.botValue = action.listBots[0].name;

      return curState;

    default:
      return state;
  } //fin switch
}; //fin reducer

const faqsCommonsReducer = combineReducers({
  listBotsFaqsCommonsObject: listBotsCommonsReducer,
});

export default faqsCommonsReducer;
