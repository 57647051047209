import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WsFlow, WsFlowContent } from '@src/types/wsFlows';

export type WsFlowLoading = 'idle' | 'pending' | 'success' | 'failure';

export type FlowsState = {
  wsFlow: {
    flow: WsFlow;
    status: WsFlowLoading;
    errorMsg?: string;
  };
  wsFlowContent: {
    status: WsFlowLoading;
    content: WsFlowContent;
  };
  wsFlowPreview: {
    status: WsFlowLoading;
    previewUrl: string;
  };
};

type FlowsReducer<P = void> = (
  state: FlowsState,
  action: PayloadAction<P>
) => void;

export type FlowPayloadSuccess = { fullFill?: boolean } | undefined;

const initialState: FlowsState = {
  wsFlow: {
    flow: {
      name: '',
      categories: [],
      phoneNumber: '',
    },
    status: 'idle',
  },
  wsFlowContent: {
    content: {},
    status: 'idle',
  },
  wsFlowPreview: {
    status: 'idle',
    previewUrl: '',
  },
};

const handleGetFlow: FlowsReducer<string> = state => {
  state.wsFlow.status = 'pending';
};

const handleCreateWsFlow: FlowsReducer<WsFlow> = state => {
  state.wsFlow.status = 'pending';
  delete state.wsFlow.errorMsg;
};

const handleUpdateWsFlow: FlowsReducer<WsFlow> = state => {
  state.wsFlow.status = 'pending';
  delete state.wsFlow.errorMsg;
};

const handleWsFlowSuccess: FlowsReducer<
  { flow?: WsFlow } & FlowPayloadSuccess
> = (state, { payload }) => {
  if (payload.flow) state.wsFlow.flow = payload.flow;
  state.wsFlow.status = payload?.fullFill ? 'idle' : 'success';
};

const handleWsFlowFailure: FlowsReducer<string | undefined> = (
  state,
  { payload: errorMsg }
) => {
  state.wsFlow.status = 'failure';
  state.wsFlow.errorMsg = errorMsg;
};

const handleDeleteWsFlow: FlowsReducer<string> = state => {
  state.wsFlow.status = 'pending';
  delete state.wsFlow.errorMsg;
};

const handleDeleteWsFlowSuccess: FlowsReducer<FlowPayloadSuccess> = (
  state,
  { payload }
) => {
  if (!payload?.fullFill) {
    state.wsFlow = {
      ...initialState.wsFlow,
      status: 'success',
    };
    state.wsFlowContent = initialState.wsFlowContent;
    state.wsFlowPreview = initialState.wsFlowPreview;
  } else state.wsFlow.status = 'idle';
};

const handleDeleteWsFlowFailure: FlowsReducer<string | undefined> = (
  state,
  { payload: errorMsg }
) => {
  state.wsFlow.status = 'failure';
  state.wsFlow.errorMsg = errorMsg;
};

const handleCleanWsFlow: FlowsReducer = state => {
  state.wsFlow = initialState.wsFlow;
};

const handleGetWsFlowContent: FlowsReducer<string> = state => {
  state.wsFlowContent.status = 'pending';
};

const handleUpdateWsFlowContent: FlowsReducer<{
  flowId: string;
  flowContent: WsFlowContent;
}> = state => {
  state.wsFlowContent.status = 'pending';
};

const handleWsFlowContentSuccess: FlowsReducer<
  { flowContent?: WsFlowContent } & FlowPayloadSuccess
> = (state, { payload }) => {
  if (payload.flowContent) state.wsFlowContent.content = payload.flowContent;
  state.wsFlowContent.status = payload?.fullFill ? 'idle' : 'success';
};

const handleWsFlowContentFailure: FlowsReducer = state => {
  state.wsFlowContent.status = 'failure';
};

const handleCleanWsFlowContent: FlowsReducer = state => {
  state.wsFlowContent = initialState.wsFlowContent;
};

const handleGetWsFlowPreview: FlowsReducer<string> = state => {
  state.wsFlowPreview.status = 'pending';
};

const handleGetWsFlowPreviewSuccess: FlowsReducer<
  { previewUrl?: string } & FlowPayloadSuccess
> = (state, { payload = {} }) => {
  if (payload?.previewUrl) state.wsFlowPreview.previewUrl = payload.previewUrl;
  state.wsFlowPreview.status = payload?.fullFill ? 'idle' : 'success';
};

const handleGetWsFlowPreviewFailure: FlowsReducer = state => {
  state.wsFlowPreview.status = 'failure';
};

const wsFlowsSlice = createSlice({
  name: 'wsFlowEditor',
  initialState,
  reducers: {
    getWsFlow: handleGetFlow,

    createWsFlow: handleCreateWsFlow,
    updateWsFlow: handleUpdateWsFlow,
    wsFlowSuccess: handleWsFlowSuccess,
    wsFlowFailure: handleWsFlowFailure,
    deleteWsFlow: handleDeleteWsFlow,
    deleteWsFlowSuccess: handleDeleteWsFlowSuccess,
    deleteWsFlowFailure: handleDeleteWsFlowFailure,

    cleanWsFlow: handleCleanWsFlow,
    cleanWsFlowContent: handleCleanWsFlowContent,

    getWsFlowContent: handleGetWsFlowContent,
    updateWsFlowContent: handleUpdateWsFlowContent,
    wsFlowContentSuccess: handleWsFlowContentSuccess,
    wsFlowContentFailure: handleWsFlowContentFailure,

    getWsFlowPreview: handleGetWsFlowPreview,
    getWsFlowPreviewSuccess: handleGetWsFlowPreviewSuccess,
    getWsFlowPreviewFailure: handleGetWsFlowPreviewFailure,
  },
});

const { actions, reducer } = wsFlowsSlice;

export const {
  getWsFlow,
  createWsFlow,
  updateWsFlow,
  wsFlowSuccess,
  wsFlowFailure,
  deleteWsFlow,
  deleteWsFlowSuccess,
  deleteWsFlowFailure,
  cleanWsFlow,
  getWsFlowContent,
  updateWsFlowContent,
  wsFlowContentSuccess,
  wsFlowContentFailure,
  getWsFlowPreview,
  getWsFlowPreviewSuccess,
  getWsFlowPreviewFailure,
  cleanWsFlowContent,
} = actions;

export default reducer;
