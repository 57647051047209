const images = [
  'Plus_B2Chat_1.webp',
  'B2Bot-Cansado.webp',
  'SmartphoneB2Chat.webp',
  'B2Bot-Cool.webp',
  'B2Bot-Muerto.webp',
  'B2Bot-Picando.webp',
  'B2Chat.webp',
  'B2Chater.webp',
  'CrecerJuntos.webp',
  'ILoveB2Chat.webp',
  'MuchasGracias.webp',
  'OK.webp',
  'SerParteB2Chat.webp',
];

const genData = images.map((img, ind) => ({
  imgUrl: `/resources/stickers/b2chat/${img}`,
  id: ind === 0 ? 'add-own-stickers-button.custom' : `b2chat-${ind}.custom`,
  names: ['b2chat', 'b2', 'chat', 'bot'],
}));

export default genData;
