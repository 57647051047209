/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { updateWebSocketReadyState } from '@src/reducers/websocket';
// eslint-disable-next-line import/no-cycle
import { allChatsSelector } from '@src/selectors/chats';
import _ from 'lodash-es';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {
  AgentChatEvent,
  AgentChatStatus,
  ResponseEvent,
  ResponseStatusCode,
  UserChatEvent,
} from '../model/frontendmodel';
import env from '../utils/env';
import incrementalThrottle from '../utils/incrementalThrottle';
import StringUtils from '../utils/strings';

export const WebSocketEventTarget = new EventTarget();

const WebSocketConnector = (() => {
  let stompClient = null;
  let sockJsSocket = null;
  let isConnected = false;
  let handleAgentEvent = _.noop;
  let handleUserEvent = _.noop;
  let handleManagementEvent = _.noop;
  let handleResponseEvent = _.noop;
  let handleConnected = _.noop;
  let handleDisconnected = _.noop;
  let handleManagementBanner = _.noop;
  let currentUser = null;
  const setPendingReqs = {};

  // Makes a function to wrap handlers of events that are susceptible of being accummulated while loading the Agentspace
  const handle = handler => eventWrapper => {
    handler(eventWrapper);
  };

  // Parses an event, which can be provided either as a JSON string or as a Javascript object
  const parseAgentChatEvent = rawEvent => {
    // Check if the raw event has been provided as JSON, in that case, parse it
    if (typeof rawEvent === 'string') {
      // eslint-disable-next-line no-param-reassign
      rawEvent = JSON.parse(rawEvent);
    }

    // Form an event with the appropriate prototype, as hinted by the contents of the raw contents of the event
    let agentChatEvent = null;

    if (Object.prototype.hasOwnProperty.call(rawEvent, 'event')) {
      agentChatEvent = Object.assign(
        Object.create(AgentChatEvent.prototype),
        rawEvent
      );
    } else if (Object.prototype.hasOwnProperty.call(rawEvent, 'status')) {
      agentChatEvent = Object.assign(
        Object.create(ResponseEvent.prototype),
        rawEvent
      );
    } else {
      agentChatEvent = Object.assign(
        Object.create(AgentChatStatus.prototype),
        rawEvent
      );
    }

    return agentChatEvent;
  };

  const parseUserChatEvent = rawEvent => {
    let userChatEvent = null;
    try {
      const parsedEvent = JSON.parse(rawEvent);
      const state = window.store.getState();
      const chats = allChatsSelector(state);
      if (parsedEvent?.event) {
        parsedEvent.event.payload = chats.find(
          chat => chat.id === parsedEvent.chatId
        );
        userChatEvent = Object.assign(
          Object.create(UserChatEvent.prototype),
          parsedEvent
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error while parsing the raw event.', err);
    }
    return userChatEvent;
  };

  // Parses a management event, which can be provided either as a JSON string or as a Javascript object
  const parseManagementEvent = rawEvent => _.defaultTo(rawEvent, null);

  // Sends a request via STOMP/websocket, in such a way that the response will be provided via the specified callback
  const submitStompRequest = (
    url,
    message,
    responseCallback,
    timeout = 75000
  ) => {
    // Generate a requestId and add it to the set of pending stomp requests
    const requestId = StringUtils.getUuid();
    const reqUrl = `${url}/${requestId}`;
    setPendingReqs[requestId] = responseCallback;

    // Submit the request
    send(reqUrl, {}, message);

    // Trigger a timeout check, to assure that either, a response is obtained for this request it just times out
    setTimeout(() => {
      // Check that the request has been resolved, otherwise respond with a timeout error
      if (setPendingReqs[requestId]) {
        delete setPendingReqs[requestId];

        const timeoutResponse = new ResponseEvent();
        timeoutResponse.status = ResponseStatusCode.TIMEOUT;
        timeoutResponse.error = 'Timed out';
        responseCallback(timeoutResponse);
      }
    }, timeout);
  };

  // Consumes an event corresponding to the Agentspace (received through /topic/{merchantId}-agentevents
  const handleAgentsTopicMsg = eventWrapper => {
    const objEvent = parseAgentChatEvent(eventWrapper.body);

    // Two kinds of events can be received here: initial status and general events about the agent chat
    if (
      objEvent &&
      Object.getPrototypeOf(objEvent) === AgentChatEvent.prototype
    ) {
      handleAgentEvent(
        objEvent.chatId,
        objEvent.event,
        objEvent.businessProcessId,
        false
      );
    } else {
      console.log('Unknown Agentspace event');
    }
  };

  const handleChatQueueMsg = eventWrapper => {
    const objEvent = parseAgentChatEvent(eventWrapper.body);
    let reqResponseCallback = null;

    // Two kinds of events are handled here: events about the agent chat and responses to prior requests
    if (Object.getPrototypeOf(objEvent) === AgentChatEvent.prototype) {
      handleAgentEvent(
        objEvent.chatId,
        objEvent.event,
        objEvent.businessProcessId,
        true
      );
    } else if (Object.getPrototypeOf(objEvent) === ResponseEvent.prototype) {
      // Check if the response corresponds to a pending request
      if (objEvent.requestId && setPendingReqs[objEvent.requestId]) {
        // Resolve the pending request
        reqResponseCallback = setPendingReqs[objEvent.requestId];
        delete setPendingReqs[objEvent.requestId];

        reqResponseCallback(objEvent);
      } else {
        // Bubble the response event to the specified handler
        handleResponseEvent(objEvent);
      }
    }
  };

  const handleUserTopicMsg = eventWrapper => {
    const objEvent = parseUserChatEvent(eventWrapper.body);
    // Events about broadcasting over the user topic
    if (
      objEvent &&
      Object.getPrototypeOf(objEvent) === UserChatEvent.prototype
    ) {
      handleUserEvent(
        objEvent.chatId,
        objEvent.event,
        objEvent.businessProcessId,
        true
      );
    }
  };

  const handleManagementQueueMsg = eventWrapper => {
    handleManagementEvent(
      parseManagementEvent(_.get(eventWrapper, 'body', null))
    );
  };

  const handleMiscEvent = eventWrapper => {
    const data = parseManagementEvent(_.get(eventWrapper, 'body', null));
    if (data) handleManagementBanner(JSON.parse(data));
  };

  // Connects to the backend via STOMP Client over SockJS
  const connectToBackend = incrementalThrottle(
    merchantId => {
      console.log(`Reconnect WS/Stomp in ${connectToBackend.wait}ms...`);
      if (!merchantId || suspended) return;
      try {
        sockJsSocket = new SockJS(`${env.WEB_SOCKET_BASE_URI}/agentchatws`);

        stompClient = Stomp.over(sockJsSocket);
        stompClient.heartbeat.outgoing = 0; // Client will send heartbeats every Xms
        stompClient.heartbeat.incoming = 23000; // Client wants to receive heartbeats from server every Xms (0: disable)
        sockJsSocket.onclose = () => {
          window.store.dispatch(
            updateWebSocketReadyState({ readyState: WebSocket.CLOSED })
          );
        };
        sockJsSocket.onerror = () => {
          window.store.dispatch(
            updateWebSocketReadyState({ readyState: WebSocket.CLOSED })
          );
        };
        window.store.dispatch(
          updateWebSocketReadyState({ readyState: sockJsSocket.readyState })
        );

        // Headers should contain headers such as login, password, client-id, etc.
        const headers = { login: '', password: '' };

        const { localUsername } =
          window.store.getState().loginAuthentication?.success;

        // Make sure the event accummulator is clean & ready for a brand new connection attempt

        // Establish a connection with the WS server by using the Headers form of the connect function:
        // client.connect(headers, connectCallback[, errorCallback, host]).
        stompClient.connect(
          headers,
          () => {
            // Enable the event accumulator, so that we enqueue events received before getting the current Agentspace
            if (!stompClient.connected) return;
            connectToBackend.restartWaitStep();
            window.store.dispatch(
              updateWebSocketReadyState({ readyState: WebSocket.OPEN })
            );

            // Subscribe to the AgentEvents topic, which receives events aimed at all users
            stompClient.subscribe(
              `/topic/${merchantId}-agentevents`,
              handle(handleAgentsTopicMsg)
            );

            /*
             * Subscribe to the user queue, which receives events aimed specifically at the current user. Destinations prefixed with /user/ are recognized by Spring's STOMP to target the specific user who sent the request
             * Subscribe to the managemenet queue. Receives events about changes in the configuration of the merchant
             * Subscribe to the managemenet queue. Receives events about changes in the configuration of the merchant
             */
            stompClient.subscribe(
              `/topic/${localUsername}-user`,
              handle(payload => {
                handleChatQueueMsg(payload);
                handleManagementQueueMsg(payload);
                handleUserTopicMsg(payload);
              })
            );

            // Subscribe to the managemenet queue. Receives events about changes in the configuration of the banner
            stompClient.subscribe('/topic/misc', handle(handleMiscEvent));

            console.debug(
              'WS/Stomp. Subscribed. Loading current Agentspace...'
            );

            isConnected = true;
            handleConnected();
          },
          err => {
            console.error('Error connecting STOMP Cient', err);
          }
        );

        // Handle an eventual disconnection from the websocket
        sockJsSocket.onclose = function r(ev) {
          // eslint-disable-next-line no-console
          console.info('WebSocket connection closed!', ev);

          const closeEvent = {
            code: ev.code,
            reason: ev.reason,
          };

          const info = window.store.getState().loginAuthentication?.success;
          const report = {
            ...closeEvent,
            user: {
              id: info.id,
              name: info.fullName,
              username: info.localUsername,
            },
            merchant: {
              id: info.employer.id,
              name: info.employer.name,
            },
            environment: env.APP_ENV,
          };

          datadogLogs.logger.warn('WS connection closed', report);

          // Errors
          // 1008: This connection was established under an authenticated HTTP Session that has expired
          // 1001: Shutdown

          reConnectToBackend(closeEvent);
        };
      } catch (err) {
        // If connecting to the backend fails for any reason, just keep trying
        // eslint-disable-next-line no-console
        console.error('ERROR connecting to backend. Re-trying...', err);

        const closeEvent = {
          code: 'ERROR',
          reason: 'ERROR',
        };

        reConnectToBackend(closeEvent);
      }
    },
    [100, 1_000, 4_000, 10_000]
  );

  const reConnectToBackend = closeEvent => {
    isConnected = false;

    try {
      handleDisconnected(closeEvent);
    } catch (e) {
      console.error('ERROR handling disconnected event ', e.message, e);
    }

    // Disconnect stomp client and try to reconnect when ready
    if (stompClient) {
      try {
        stompClient.disconnect(() => {
          console.log('STOMP client disconnected. Attempting reconnect...');
          // CloseEvent code 4001: Failed to load Agentspace. Do not reconnect in that case
          if (_.get(closeEvent, 'code') !== 4001) {
            connectToBackend(currentUser?.employer?.id);
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log('No STOMP client initialized. Attempting reconnect...');
      // CloseEvent code 4001: Failed to load Agentspace. Do not reconnect in that case
      if (_.get(closeEvent, 'code') !== 4001) {
        connectToBackend(currentUser?.employer?.id);
      }
    }
  };

  WebSocketEventTarget.addEventListener('reconnect', () => {
    connectToBackend.restartWaitStep();
    reConnectToBackend(connectToBackend.cache.merchantId);
  });

  let suspended = false;

  function send(destination, headers, body) {
    try {
      stompClient?.send(destination, headers, body);
    } catch (e) {
      console.log(e);
    }
  }

  return {
    // Says whether the proxy is already connected to the backend
    isConnected: () => isConnected,

    // Connects the proxy module to the websockets available on the backend services
    connect: (
      onConnected,
      onDisconnected,
      onAgentEvent,
      onUserEvent,
      onManagementEvent,
      onResponseEvent,
      currUser,
      onManagementBanner
    ) => {
      handleConnected = _.defaultTo(onConnected, _.noop);
      handleDisconnected = _.defaultTo(onDisconnected, _.noop);
      handleAgentEvent = _.defaultTo(onAgentEvent, _.noop);
      handleUserEvent = _.defaultTo(onUserEvent, _.noop);
      handleManagementEvent = _.defaultTo(onManagementEvent, _.noop);
      handleResponseEvent = _.defaultTo(onResponseEvent, _.noop);
      currentUser = currUser;
      suspended = false;
      handleManagementBanner = _.defaultTo(onManagementBanner, _.noop);

      if (!isConnected) {
        connectToBackend(currentUser.employer.id);
      } else {
        console.log('Backend proxy is already connected');
      }
    },

    // Disconnects the backend proxy. Closes all websockets used by this module
    disconnect: () => {
      // Disconnect the STOMP client (TODO: Question: does it also close sockJsSocket?)
      try {
        stompClient.disconnect(() => {
          console.log('See you next time!');
        });
      } catch (e) {
        console.log(e);
      }
    },

    activate: () => {
      try {
        suspended = false;
        if (currentUser?.employer?.id) console.log('Activate invoked');
      } catch (error) {
        console.log('error: ', error);
      }
    },

    deactivate: () => {
      try {
        suspended = true;
        stompClient?.disconnect(() => {
          console.log('Websocket connection suspended!');
        });
      } catch (error) {
        console.log('error: ', error);
      }
    },

    // Requests the specified agent chat to be activated for an agent
    activateChat: agentChatId => {
      send(`/messaging/switch-active-chat/${agentChatId}`, {}, null);
    },

    // Requests the specified agent chat to be picked up by an agent
    pickupChat: agentChatId => {
      send(`/messaging/pickupchat/${agentChatId}`, {}, null);
    },

    pinChat: chatId => {
      send(`/messaging/${chatId}/pintoggle`, {}, null);
    },

    requestResumeChat: (
      agentChatId,
      templatedMsgId,
      okCallback,
      failCallback
    ) => {
      const urlParts = ['/messaging/resume-chat', agentChatId, templatedMsgId];
      const requestResumeChatUrl = urlParts.join('/');

      submitStompRequest(
        requestResumeChatUrl,
        agentChatId,
        responseEvent => {
          if (responseEvent) {
            if (responseEvent.status === ResponseStatusCode.OK) {
              okCallback(responseEvent);
            } else {
              failCallback(responseEvent.status, responseEvent.error);
            }
          }
        },
        5000
      );
    },
  };
})();

export default WebSocketConnector;
