import BackendProxy from '../logic/backendproxy';
import GeneralPropertiesBackend from '../logic/GeneralPropertiesBackend';

export const RESET_STATE = 'RESET_STATE';
export const GOT_RESPONSE_EVENT = 'GOT_RESPONSE_EVENT';
export const LOGGING_OUT = 'LOGGING_OUT';
export const LOG_OUT = 'LOG_OUT';
export const OPEN_IMAGE_VIEWER = 'OPEN_IMAGE_VIEWER';
export const CLOSE_IMAGE_VIEWER = 'CLOSE_IMAGE_VIEWER';

export const OPEN_DIALOG_VIEWER = 'OPEN_DIALOG_VIEWER';
export const CLOSE_DIALOG_VIEWER = 'CLOSE_DIALOG_VIEWER';

export const RAISE_NEW_ERROR = 'RAISE_NEW_ERROR';
export const CHECK_CURRENT_ERROR = 'CHECK_CURRENT_ERROR';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';
export const GOT_GENERAL_PROPERTIES = 'GOT_GENERAL_PROPERTIES';

export const LOAD_IMAGE_ERROR = 'AGENT_CONSOLE.LOAD_IMAGE_ERROR';
export const REMOVE_RECORD_IMG_ERROR = 'AGENT_CONSOLE.REMOVE_RECORD_IMG_ERROR';

export function resetState(newState) {
  return {
    type: RESET_STATE,
    state: newState,
  };
}

export function gotResponseEvent(response) {
  return {
    type: GOT_RESPONSE_EVENT,
    response,
  };
}

// Thunk Action Creator: user requested to logout of the application
export function requestLogout() {
  return dispatch => {
    dispatch(loggingOut());

    BackendProxy.logOut(
      data => {
        dispatch(logout(data));
      },
      error => {
        dispatch(logoutFailureAction(error));
      }
    );
  };
}

export function getGeneralProperties() {
  return dispatch => {
    GeneralPropertiesBackend.getGeneralProperties(props => {
      dispatch(generalPropertiesReceived(props));
    });
  };
}

export function generalPropertiesReceived(props) {
  return {
    type: GOT_GENERAL_PROPERTIES,
    props,
  };
}

export function loggingOut() {
  return {
    type: LOGGING_OUT,
  };
}

export function logout(data) {
  return {
    type: LOG_OUT,
    data,
  };
}

export function logoutFailureAction(data) {
  return {
    type: LOG_OUT_FAIL,
    data,
  };
}

export function openImageViewer(imageUrl) {
  return {
    type: OPEN_IMAGE_VIEWER,
    imageUrl,
  };
}

export function closeImageViewer() {
  return {
    type: CLOSE_IMAGE_VIEWER,
  };
}

export function openDialogViewer(
  open,
  dialogType,
  buttonLabel,
  message,
  internalMessage
) {
  return {
    type: OPEN_DIALOG_VIEWER,
    open,
    dialogType,
    buttonLabel,
    message,
    internalMessage,
  };
}

export function closeDialogViewer() {
  return {
    type: CLOSE_DIALOG_VIEWER,
  };
}

export function raiseNewError(errorMsg) {
  return {
    type: RAISE_NEW_ERROR,
    errorMsg,
  };
}

export function checkCurrentError() {
  return {
    type: CHECK_CURRENT_ERROR,
  };
}

export function loadedImagesErrors(errorMsg) {
  return {
    type: LOAD_IMAGE_ERROR,
    errorMsg,
  };
}

export function removeRecordImgError(fileName) {
  return {
    type: REMOVE_RECORD_IMG_ERROR,
    fileName,
  };
}
