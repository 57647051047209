import service, { ErrorPayload } from './service';

export type ExcelAuditResult = {
  id: string;
  user: string;
  originalFileUrl: string;
  modifiedFileUrl: string;
  totalNumberOfContacts: string;
  state: 'VALIDATED' | 'SENT';
  campaign: string;
  from: string;
  createdAtInMillis: number;
  invalidRows: number;
  validRows: number;
  loading?: boolean;
};

export type BroadcastError = ErrorPayload & {
  errorCode:
    | 'INVALID_EXCEL_EXTENSION'
    | 'EMPTY_FILE'
    | 'INVALID_FILE_SIZE'
    | 'SENDINGS_NUMBER_EXCEEDED'
    | 'SENDINGS_PER_DAY_EXCEEDED'
    | 'DUPLICATED_CAMPAIGN'
    | 'OPERATION_FAILED';
  totalSent: number;
  currentTotalInExcel: number;
  maxAllowedPerDay: number;
  hoursToResetCount: number;
};

export type Campaign = {
  file: File;
  templateTitle: string;
  templateId: number;
  botAccountId: number;
  campaign: string;
  departmentTarget?: number;
  agentTarget?: number;
};

export async function validateExcelFilePOST(
  params: Partial<Campaign> & { auditId?: string }
): Promise<ExcelAuditResult | BroadcastError> {
  const data = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    // TODO: set the correct type for "value"
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (value) data.append(key, value as unknown);
  });

  return (
    await fetch(service('admin/whatsapp-broadcast/validate'), {
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
      body: data,
    })
  ).json();
}

export async function sendBroadcastGET(params: {
  auditId: string;
  botAccountId: number;
  scheduledAt?: string;
}): Promise<BroadcastError> {
  const response = await fetch(
    service('admin/whatsapp-broadcast/schedule/', {
      query: { ...params },
    }),
    {
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
      headers: { 'content-type': 'application/json' },
    }
  );
  return response.json().catch(() => ({}));
}

export async function validateCampaignPOST(
  campaign: string,
  signal: AbortSignal
): Promise<void | BroadcastError> {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(
      service('admin/whatsapp-broadcast/validate-campaign', {
        query: { campaign },
      }),
      {
        method: 'POST',
        credentials: 'include',
        cache: 'no-cache',
        headers: { 'content-type': 'application/json' },
        signal,
      }
    );
    if (!response.ok) throw await response.json();
  } catch (error) {
    throw error;
  }
}
