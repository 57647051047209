import { combineReducers } from 'redux';
import {
  OPEN_EMOTICON_SELECTOR0,
  OPEN_EMOTICON_SELECTOR1,
  OPEN_EMOTICON_SELECTOR2,
  OPEN_EMOTICON_SELECTOR3,
  CLOSE_EMOTICON_SELECTOR0,
  CLOSE_EMOTICON_SELECTOR1,
  CLOSE_EMOTICON_SELECTOR2,
  CLOSE_EMOTICON_SELECTOR3,
} from '../actions/faqsSetup';

const emoticonSelectorReducer = (state = {}, action) => {
  switch (action.type) {
    case OPEN_EMOTICON_SELECTOR0:
      var curState3 = { ...state };

      curState3.popover0.open = true;
      curState3.popover0.anchorEl = action.anchorEl;

      return curState3;
    //return Object.assign({}, state, {open: true, anchorEl: action.anchorEl});
    case OPEN_EMOTICON_SELECTOR1:
      var curState4 = { ...state };

      curState4.popover1.open = true;
      curState4.popover1.anchorEl = action.anchorEl;

      return curState4;

    case OPEN_EMOTICON_SELECTOR2:
      var curState5 = { ...state };

      curState5.popover2.open = true;
      curState5.popover2.anchorEl = action.anchorEl;

      return curState5;

    case OPEN_EMOTICON_SELECTOR3:
      var curState6 = { ...state };

      curState6.popover3.open = true;
      curState6.popover3.anchorEl = action.anchorEl;

      return curState6;

    case CLOSE_EMOTICON_SELECTOR0:
      var curState7 = { ...state };

      curState7.popover0.open = false;

      return curState7;

    case CLOSE_EMOTICON_SELECTOR1:
      var curState8 = { ...state };

      curState8.popover1.open = false;

      return curState8;

    case CLOSE_EMOTICON_SELECTOR2:
      var curState9 = { ...state };

      curState9.popover2.open = false;

      return curState9;

    case CLOSE_EMOTICON_SELECTOR3:
      var curState10 = { ...state };

      curState10.popover3.open = false;

      return curState10;

    default:
      return state;
  }
};

//se definen cuales reducers modifican cual parte del estado
const faqsSetupReducer = combineReducers({
  emoticonSelector: emoticonSelectorReducer,
});

export default faqsSetupReducer;
