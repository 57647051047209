import {
  LOAD_FILE,
  LOAD_IMAGE_PREVIEW_URL,
  LOAD_PRODUCT_ID,
  LOAD_PRODUCT_NAME,
  LOAD_PRODUCT_DESC,
  LOAD_ATTRIBUTE_FIELD,
  LOAD_VALUE_FIELD,
  LOAD_LIST_VARIANTS_FIELD,
  LOAD_LIST_ATTRIBUTES_FIELD,
  LOAD_LIST_COMBINATIONS,
  LOAD_SELECTED_INDEXES,
  LOAD_ERROR_MESSAGE,
  OK_LOADED_CATEGORIES_LIST,
  ERROR_LOADED_CATEGORIES_LIST,
  LOAD_CATEGORY,
  LOAD_SCROLL_TOP,
  LOAD_LIST_REFERENCES,
  SET_IMAGE_ERROR,
  SET_CATEGORIES_LIST_STATUS,
  SET_CHANGE_PRICE,
  SET_CHANGE_SKU,
  SET_STATE_ACTIVE,
  SET_SAVING_PRODUCT,
  SET_CATEGORY_MODAL_OPEN,
  SET_NEW_CATEGORY,
} from '../actions/createproduct';

const createProductReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PRODUCT_ID:
      return { ...state, productId: action.value };
    case LOAD_PRODUCT_NAME:
      return { ...state, productName: action.value };
    case LOAD_PRODUCT_DESC:
      return { ...state, description: action.value };
    case LOAD_ATTRIBUTE_FIELD:
      return { ...state, currentAttribute: action.value };
    case LOAD_VALUE_FIELD:
      return { ...state, currentValue: action.value };
    case LOAD_LIST_VARIANTS_FIELD:
      return { ...state, listValues: action.value };
    case LOAD_LIST_ATTRIBUTES_FIELD:
      return { ...state, listAttributes: action.value };
    case LOAD_LIST_COMBINATIONS:
      return { ...state, listCombinations: action.value };
    case LOAD_FILE:
      return { ...state, file: action.value };
    case LOAD_IMAGE_PREVIEW_URL:
      return { ...state, imagePreviewUrl: action.value };
    case LOAD_SELECTED_INDEXES:
      return { ...state, selectedIndexes: action.value };
    case LOAD_ERROR_MESSAGE:
      return { ...state, errorMessage: action.value };
    case OK_LOADED_CATEGORIES_LIST:
      return {
        ...state,
        categoryId: '',
        categoriesListStatus: 'OK_LOADED',
        categoriesList: action.value,
      };
    case ERROR_LOADED_CATEGORIES_LIST:
      return {
        ...state,
        categoryId: '',
        categoriesListStatus: 'ERROR_LOADED',
        categoriesList: [],
      };
    case SET_CATEGORIES_LIST_STATUS:
      return { ...state, categoryId: '', categoriesListStatus: action.value };

    case LOAD_CATEGORY:
      return { ...state, categoryId: action.value };
    case LOAD_SCROLL_TOP:
      return { ...state, scrollTop: action.value };
    case LOAD_LIST_REFERENCES:
      return { ...state, listReferences: action.value };
    case SET_IMAGE_ERROR:
      return { ...state, imageError: action.value };
    case SET_CHANGE_PRICE:
      return { ...state, price: action.value };
    case SET_CHANGE_SKU:
      return { ...state, skuReference: action.value };
    case SET_STATE_ACTIVE:
      return { ...state, active: action.value };
    case SET_SAVING_PRODUCT:
      return { ...state, savingProduct: action.value };
    case SET_CATEGORY_MODAL_OPEN:
      return { ...state, categoryModalOpen: action.value };
    case SET_NEW_CATEGORY:
      return { ...state, newCategoryName: action.value };

    default:
      return state;
  }
};

export default createProductReducer;
