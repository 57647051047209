import { Categories } from 'emoji-picker-react';
import b2ChatStickers from './b2ChatStickers';
import goodMorningStickers from './goodMorningStickers';
import ikblijfmeedoenStickers from './ikblijfmeedoenStickers';
import meEmojiStickers from './meEmojiStickers';

export const StickersKeyName = 'customStickersArray';

export const AddOwnStickersButtonId = 'add-own-stickers-button';

export const SuggestedLocalStorageId = 'epr_suggested';

export const MaximumSuggestedListSize = 14;

export const EmojisCategories = [
  {
    category: Categories.SUGGESTED,
    name: 'Frequently Used',
  },
  {
    category: Categories.CUSTOM,
    name: 'Stickers',
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: 'Smileys & People',
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: 'Animals & Nature',
  },
  {
    category: Categories.FOOD_DRINK,
    name: 'Food & Drink',
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: 'Travel & Places',
  },
  {
    category: Categories.ACTIVITIES,
    name: 'Activities',
  },
  {
    category: Categories.OBJECTS,
    name: 'Objects',
  },
  {
    category: Categories.SYMBOLS,
    name: 'Symbols',
  },
  {
    category: Categories.FLAGS,
    name: 'Flags',
  },
];

export const CustomEmojisList = [
  ...b2ChatStickers,
  ...goodMorningStickers,
  ...ikblijfmeedoenStickers,
  ...meEmojiStickers,
];

export default EmojisCategories;
