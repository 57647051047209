import {
  FilterKeys,
  FilterParamsItem,
  LabelShowOnlyAvailableProducts,
  ShopifyOrderInputValue,
  ShopifyState,
  ShopifyTabsView,
} from './types';

export const initPriceRangeFilter = (
  _state: ShopifyState,
  prevVal?: FilterParamsItem
) => {
  const MAX_PRICE = 0;
  const MIN_PRICE = 0;

  let value = [MIN_PRICE, MAX_PRICE];
  let checked = false;

  if (prevVal?.value && prevVal.max === MAX_PRICE) {
    value = prevVal?.value;
    checked = !!prevVal.checked;
  }
  return {
    type: 'range',
    label: '',
    max: MAX_PRICE,
    value,
    min: MIN_PRICE,
    step: (MAX_PRICE * 10) / 100,
    checked,
  } as FilterParamsItem;
};

export const initialState: ShopifyState = {
  error: null,
  productDetailError: null,
  loading: [],
  shop: undefined,
  credentials: undefined,
  configStores: undefined,
  updateCredentialStatus: undefined,
  updatingCredentials: false,
  alreadyHasStores: false,
  products: [],
  productDetail: undefined,
  collections: [],
  locations: {
    currentLocation: null,
    locations: [],
  },
  inventory: { products: [], inventoryFilterOptions: {}, totalProducts: 0 },
  vendors: [],
  tags: [],
  filterParams: [],
  showOnlyAvailableProducts: false,
  filterParamsEditing: [],
  orderByParam: { sort: 'TITLE', reverse: false },
  selectedProductId: undefined,
  currentCarouselImage: {},
  currentTabView: ShopifyTabsView.PRODUCT_LIST,
  selectedVariantArrId: [],
  selectedLocationId: undefined,
  selectedStoreId: undefined,
  previousSelectedLocationId: undefined,
  searchPattern: '',
  shoppingCart: {},
  shoppingCartItem: undefined,
  ordersHistory: [],
  ordersHistoryPageInfo: undefined,
  orderUrl: undefined,
  orderInputValue: ShopifyOrderInputValue.PHONE,
  tryToRetrieveOnlyAvailableProducts: true,
};

export const handleInitializeFilterParamsState = (state: ShopifyState) => {
  state.filterParams = [
    {
      key: FilterKeys.COLLECTIONS,
      items:
        state.collections.map(col => ({
          type: 'radio',
          label: col.name,
          id: col.id,
        })) || [],
    },
    {
      key: FilterKeys.TAGS,
      items:
        state.shop?.tags.map(tag => ({
          type: 'checkbox',
          label: tag.name,
        })) || [],
    },
    {
      key: FilterKeys.PRICES,
      items: [initPriceRangeFilter(state)],
    },
    {
      key: FilterKeys.VENDOR,
      items:
        state.shop?.vendors.map(item => ({
          type: 'radio',
          label: item.name,
        })) || [],
    },
    {
      key: FilterKeys.SHOW_ONLY_AVAILABLE_PRODUCTS,
      items: [
        {
          type: 'checkbox',
          label: LabelShowOnlyAvailableProducts,
          checked: false,
        },
      ],
    },
  ];
  state.filterParamsEditing = state.filterParams;
};

export default initPriceRangeFilter;
