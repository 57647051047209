/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { appConfig } from '@src/config/appConfig';
import {
  fetchPartnersCustomUiConfig,
  fetchPartnersCustomUiConfigFailure,
  fetchPartnersCustomUiConfigFulfill,
  fetchPartnersCustomUiConfigSuccess,
  PartnersCustomUiConfig,
} from '@src/reducers/partnersCustomUi';
import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';

function* fetchPartnersCustomUiConfigSaga() {
  try {
    const response: B2ChatAPI.Response<PartnersCustomUiConfig> = yield call(
      B2ChatClient.resources.partnersCustomUi.actions.getConfig,
      {}
    );
    if (response.error) {
      yield put(fetchPartnersCustomUiConfigFailure(response.error));
    } else {
      appConfig.setData('partnersCustomUiConfig', response.data);
      yield put(fetchPartnersCustomUiConfigSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchPartnersCustomUiConfigFailure(error as ErrorData));
  } finally {
    yield put(fetchPartnersCustomUiConfigFulfill());
  }
}

export default function* partnersCustomUiConfigSaga() {
  yield takeLatest(
    fetchPartnersCustomUiConfig,
    fetchPartnersCustomUiConfigSaga
  );
}
