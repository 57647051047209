import { Component } from 'react';

// Asynchronously loads other components by invoking the specified importComponent function.
// This is in order to implement code-splitting as described at:
// http://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const { component: Component } = this.state;

      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
