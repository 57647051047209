import B2ChatClient from '@client-sdk';
import {
  fetchStandardMessages,
  FetchStandardMessagesAction,
  fetchStandardMessagesFailure,
  fetchStandardMessagesFulfill,
  fetchStandardMessagesSuccess,
  saveStandardMessages,
  saveStandardMessagesFailure,
  saveStandardMessagesFullfill,
  StandardMessageState,
} from '@reducers/standardMessages';
import {
  standardMessagesFixedSelector,
  standardMessagesSelector,
} from '@selectors/standardMessages';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const codesWithAgentFullname = ['AGENT_REQUESTED_CHAT', 'AGENT_PICKED_UP_CHAT'];
function transformPlaceholders(text: string) {
  return text.replaceAll('{0}', `\${AGENT\\_FULL\\_NAME}`);
}

function* fetchDefaultStandardMessagesSaga() {
  /* get default standard messages */
  const standardMessageState: ReturnType<typeof standardMessagesSelector> =
    yield select(standardMessagesSelector);

  if (!standardMessageState.defaultMessages) {
    const response: B2ChatAPI.Response<B2ChatAPI.Merchant.DefaultStandardMessages> =
      yield call([
        B2ChatClient.resources.merchant.actions,
        'defaultStandardMessages',
      ]);

    if (response.error) {
      yield put(fetchStandardMessagesFailure(response.error));
    } else {
      const standardMessages = response.data.filter(msg =>
        codesWithAgentFullname.includes(msg.code)
      );

      standardMessages.forEach(msg => {
        if (msg) {
          msg.value = transformPlaceholders(msg.value);
        }
      });

      const defaultMessages = response.data.reduce(
        (obj, m) => ({ ...obj, [m.code]: m }),
        {}
      );

      yield put(fetchStandardMessagesSuccess({ defaultMessages }));
    }
  }
}

function* fetchStandardMessagesSaga(action: FetchStandardMessagesAction) {
  try {
    /* get default standard messages */
    yield call(fetchDefaultStandardMessagesSaga);

    if (action.payload.botId === -1) return;

    /* get standard messages */
    const response: B2ChatAPI.Response<B2ChatAPI.StandardMessagesBot.Retrieve> =
      yield call(
        [B2ChatClient.resources.standardMessages.actions, 'hierarchyMessages'],
        { params: { id: action.payload.botId } }
      );

    if (response.error) {
      yield put(fetchStandardMessagesFailure(response.error));
    } else {
      const standardMessages = response.data.filter(msg =>
        codesWithAgentFullname.includes(msg.standardMessage.code)
      );

      standardMessages.forEach(msg => {
        if (msg) {
          msg.standardMessage.value = transformPlaceholders(
            msg.standardMessage.value
          );
        }
      });

      yield put(fetchStandardMessagesSuccess({ messages: response.data }));
    }
  } catch (error) {
    yield put(fetchStandardMessagesFailure(error as ErrorData));
  } finally {
    yield put(fetchStandardMessagesFulfill());
  }
}

function* saveStandardMessagesSaga() {
  try {
    const standardMessageState: ReturnType<
      typeof standardMessagesFixedSelector
    > = yield select(standardMessagesFixedSelector);

    /* update messsage value */ {
      const response: B2ChatAPI.Response<StandardMessageState>[] = yield all(
        standardMessageState.map(({ botId, code, value, enabled }) =>
          call(
            [B2ChatClient.resources.standardMessages.actions, 'updateMessage'],
            {
              params: { id: botId },
              data: { code, value, enabled },
            }
          )
        )
      );

      const error = response.find(r => r.error)?.error;
      if (error) {
        yield put(saveStandardMessagesFailure(error));
      }
    }
  } catch (error) {
    yield put(saveStandardMessagesFailure(error as ErrorData));
  } finally {
    yield put(saveStandardMessagesFullfill());
  }
}

export default function* standardMessagesSaga() {
  yield takeLatest(fetchStandardMessages, fetchStandardMessagesSaga);
  yield takeLatest(saveStandardMessages, saveStandardMessagesSaga);
}
