/* eslint-disable no-case-declarations */
/* eslint-disable no-plusplus */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import { combineReducers } from 'redux';

import {
  AVAILABLE_MESSAGIN_ACCOUNTS,
  BOTS_DATA_LIST,
  CITY_DATA_LIST,
  CLEAR_CONTACT_DATA,
  CUSTOM_TIMEFRAME,
  DATA_NEW_CONTACT,
  OK_LOADED_CONTACTS_LIST,
  SEARCH_CONTACT_LOCAL_DATA,
  STATUS_UPDATE_CONTACT,
  TIMEFRAME_END_DATE_SELECTED,
  TIMEFRAME_SELECTED,
  TIMEFRAME_START_DATE_SELECTED,
  UPDATE_FILTER_BYCITY,
  UPDATE_FILTER_BY_DATE,
  UPDATE_FILTER_GENERAL,
} from '../actions/contact';

const notificationsReducer = (state = [], action) => {
  const newState = { ...state };
  newState.type = action.type;
  newState.message = action.message;
  return newState;
};

const contactsReducer = (state = [], action) => {
  const newState = { ...state };

  switch (action.type) {
    case OK_LOADED_CONTACTS_LIST:
      var index = 0;
      var day = '';
      var month = '';
      var year = '';

      while (index < action.contacts.length) {
        const curCont = action.contacts[index];

        const lastUpdate =
          curCont.lastUpdate !== null ? curCont.lastUpdate : '';
        const creatAt = curCont.createAt !== null ? curCont.createAt : '';

        if (creatAt !== '') {
          const dateCreatAt = new Date(creatAt);
          day =
            `${dateCreatAt.getDate()}`.length < 2
              ? `0${dateCreatAt.getDate()}`
              : dateCreatAt.getDate();
          month =
            `${dateCreatAt.getMonth()}`.length < 2
              ? `0${dateCreatAt.getMonth() + 1}`
              : dateCreatAt.getMonth() + 1;
          year = dateCreatAt.getFullYear();

          const stringDateCreatAt = `${day}/${month}/${year}`;
          curCont.createAt = stringDateCreatAt;
        }

        if (lastUpdate !== '') {
          const dateLastUpdate = new Date(lastUpdate);

          day =
            `${dateLastUpdate.getDate()}`.length < 2
              ? `0${dateLastUpdate.getDate()}`
              : dateLastUpdate.getDate();
          month =
            `${dateLastUpdate.getMonth()}`.length < 2
              ? `0${dateLastUpdate.getMonth() + 1}`
              : dateLastUpdate.getMonth() + 1;
          year = dateLastUpdate.getFullYear();

          const stringDateLastUpdate = `${day}/${month}/${year}`;

          curCont.lastUpdate = stringDateLastUpdate;
          action.contacts[index] = curCont;
        }

        index++;
      }

      newState.data = [...action.contacts];
      newState.filters.data = [...action.contacts];
      return newState;

    case BOTS_DATA_LIST:
      newState.bots = action.bots;
      return newState;

    case CITY_DATA_LIST:
      newState.citiesDataSet = action.cities;
      return newState;

    case SEARCH_CONTACT_LOCAL_DATA:
      // console.log("REDUCERRR ",SEARCH_CONTACT_LOCAL_DATA);
      const localContacts = newState.data;
      let i = 0;
      // console.log("BUSCANDO  "+action.contact.id);
      while (i < localContacts.length) {
        if (localContacts[i].id === action.contact.id) {
          newState.newContact = localContacts[i];
          break;
        }

        i++;
      }

      return newState;

    case AVAILABLE_MESSAGIN_ACCOUNTS:
      newState.conversation.availableMessaginAccount = action.messaginAccount;
      newState.conversation.contact = action.contact;
      return newState;

    case UPDATE_FILTER_GENERAL:
      newState.filters.general = action.value;
      return newState;
    case UPDATE_FILTER_BYCITY:
      newState.filters.byCity = action.value;
      return state;
    case UPDATE_FILTER_BY_DATE:
      newState.filters.byDate = action.value;
      return state;

    case DATA_NEW_CONTACT:
      const val = action.value;

      if (action.field === 'botId') {
        newState.botId = val;
      } else {
        newState.newContact = val;
      }

      return newState;

    case CLEAR_CONTACT_DATA:
      newState.newContact.fullName = '';
      newState.newContact.identificationNumber = '';
      newState.newContact.email = '';
      newState.newContact.phoneNumber = '';
      newState.newContact.mobileNumber = '';
      newState.newContact.address = '';
      newState.newContact.company = '';
      newState.newContact.country.isoCode = '';
      newState.newContact.country.languageCode = '';
      newState.newContact.country.name = '';
      newState.newContact.cityName = '';
      newState.botId = '';

      return newState;

    case STATUS_UPDATE_CONTACT:
      if (
        action.response.status === 200 &&
        action.response.statusText.toLowerCase() === 'ok'
      ) {
        let cont = 0;
        while (cont < newState.data.length) {
          if (newState.data[cont].id === action.contact.id) {
            const lastUpdate = convertLongToDateString(
              action.contact.lastUpdate
            );
            const createAt = convertLongToDateString(action.contact.createAt);

            action.contact.createAt = createAt;
            action.contact.lastUpdate = lastUpdate;

            newState.data[cont] = action.contact;
            break;
          }
          cont++;
        }
        newState.filters.data = newState.data;
      }

      return newState;

    case TIMEFRAME_SELECTED:
      if (action.timeframe === CUSTOM_TIMEFRAME) {
        newState.filters.custom_timeframe = true;
      } else {
        newState.filters.custom_timeframe = false;
        newState.filters.timeframe_start_date = null;
        newState.filters.timeframe_end_date = null;
      }

      newState.filters.timeframe = action.timeframe;
      return newState;
    case TIMEFRAME_START_DATE_SELECTED:
      newState.filters.timeframe_start_date = action.date;
      return newState;

    case TIMEFRAME_END_DATE_SELECTED:
      newState.filters.timeframe_end_date = action.date;
      return newState;

    default:
      return state;
  }
};

const botsReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

function convertLongToDateString(long) {
  const date = new Date(long);

  const day =
    `${date.getDate()}`.length < 2 ? `0${date.getDate()}` : date.getDate();
  const month =
    `${date.getMonth()}`.length < 2
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1;
  const year = date.getFullYear();

  const stringDate = `${day}/${month}/${year}`;

  return stringDate;
}

const contactReducer = combineReducers({
  contacts: contactsReducer,
  notifications: notificationsReducer,
  bots: botsReducer,
});

export default contactReducer;
