/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { WhatsAppTemplateFormValues } from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppTemplates/types';

// eslint-disable-next-line import/no-cycle
import { ErrorPayload } from '@src/types';

type saveWhatsAppTemplateState = {
  loading: ('pending' | 'success' | 'failure' | 'fulfill')[];
  error?: ErrorPayload;
};

const initialState: saveWhatsAppTemplateState = {
  loading: [],
};

const saveWhatsAppTemplateSlice = createSlice({
  name: 'saveWhatsAppTemplate',
  initialState,
  reducers: {
    saveWhatsAppTemplate(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        accountId: string | number;
        provider?: string;
        formValues: WhatsAppTemplateFormValues;
      }>
    ) {
      state.loading = ['pending'];
    },
    saveWhatsAppTemplateStatus(
      state,
      _action: PayloadAction<{
        accountId: string | number;
        name: string;
        state: 'ACTIVE' | 'INACTIVE';
      }>
    ) {
      state.loading = ['pending'];
      state.error = undefined;
    },
    saveWhatsAppTemplateSuccess(state) {
      state.loading.push('success');
    },
    saveWhatsAppTemplateFailure(
      state,
      action: PayloadAction<{ error: ErrorPayload }>
    ) {
      state.loading.push('failure');
      state.error = action.payload.error;
    },
    saveWhatsAppTemplateFulfill(state) {
      state.loading = [
        ...state.loading.filter(v => v !== 'pending'),
        'fulfill',
      ];
    },
    resetSaveWhatsAppTemplate(state) {
      state.loading = [];
      state.error = undefined;
    },
  },
});

export default saveWhatsAppTemplateSlice.reducer;

export const {
  saveWhatsAppTemplateStatus,
  saveWhatsAppTemplate,
  saveWhatsAppTemplateFailure,
  saveWhatsAppTemplateFulfill,
  saveWhatsAppTemplateSuccess,
  resetSaveWhatsAppTemplate,
} = saveWhatsAppTemplateSlice.actions;
