import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AgentChatTransferStatus } from '@src/model/frontendmodel';
// eslint-disable-next-line import/no-cycle
import { ErrorData, ErrorPayload } from '@src/types';
import {
  ChatSideType,
  ChatTray,
  ChatTrayXfer,
  ChatsOnHold,
  ChatsRequest,
} from '@src/types/chats';

export const requestChatTransfer = createAction<ChatTrayXfer>(
  'requestChatTransfer'
);

export const acceptChatTransfer = createAction<{
  transfer: ChatTrayXfer;
  transferredToChatId: string;
}>('acceptChatTransfer');

export const receivedChatTransfer = createAction<ChatTrayXfer>(
  'receivedChatTransfer'
);

export const cancelChatTransfer = createAction<string>('cancelChatTransfer');

export const rejectChatTransfer =
  createAction<ChatTrayXfer>('rejectChatTransfer');

export const cleanChatTransfer = createAction<string>('cleanChatTransfer');

export const chatTransferBuilder = (
  build: ActionReducerMapBuilder<{
    chatsOnHold: {
      loading: 'idle' | 'pending';
      error?: ErrorData | ErrorPayload;
    } & ChatsOnHold;
    chatsRequest: {
      loading: 'idle' | 'pending';
      error?: ErrorData | ErrorPayload;
    } & ChatsRequest;
    activeAgentId?: number;
    activeChat?: ChatTray;
  }>
) => {
  build
    .addCase(requestChatTransfer, (state, action) => {
      const { chatId } = action.payload;
      const index = state.chatsOnHold.tray.findIndex(({ id }) => id === chatId);
      if (index !== -1) {
        state.chatsOnHold.tray[index].chatXferRequest = action.payload;
        if (state.activeChat?.id === chatId)
          state.activeChat = state.chatsOnHold.tray[index];
      }
    })
    .addCase(receivedChatTransfer, (state, action) => {
      const chatTransfer = action.payload;

      const isTransferredToMe =
        chatTransfer?.targetAgent?.id === state?.activeAgentId;
      if (
        isTransferredToMe &&
        chatTransfer.status === AgentChatTransferStatus.REQUESTED
      ) {
        state.chatsRequest.totalChatRequests += 1;
      }
    })
    .addCase(acceptChatTransfer, (state, { payload }) => {
      const { transfer: chatTransfer, transferredToChatId } = payload;
      const index = state.chatsOnHold.tray.findIndex(
        ({ id }) => id === chatTransfer.chatId
      );
      if (index !== -1) {
        const isTransferredToMe =
          chatTransfer?.targetAgent?.id === state?.activeAgentId;
        if (
          isTransferredToMe &&
          chatTransfer.status === AgentChatTransferStatus.ACCEPTED
        ) {
          state.chatsOnHold.tray[index].assignedBy = undefined;
          state.chatsOnHold.tray[index].chatXferRequest = undefined;
          state.chatsOnHold.tray[index].id = transferredToChatId;
          state.chatsOnHold.tray[index].businessProcessNode =
            chatTransfer.targetBizProcessNode;

          if (state.activeChat?.id === chatTransfer.chatId)
            state.activeChat = state.chatsOnHold.tray[index];
        } else {
          state.chatsOnHold.tray.splice(index, 1);
          state.chatsOnHold.totalChatsOnHold -= 1;
          if (state?.activeChat?.id === chatTransfer.chatId)
            delete state.activeChat;
        }
      }
    })
    .addCase(cancelChatTransfer, (state, action) => {
      const chatId = action.payload;
      const chatsOnHoldItems = state?.chatsOnHold?.tray;
      const chatsRequestItems = state?.chatsRequest?.tray;

      let chat: ChatTray;
      let chatSide: ChatSideType;
      let index = chatsOnHoldItems?.findIndex(({ id }) => id === chatId);

      if (index !== -1) {
        chat = chatsOnHoldItems[index];
        chatSide = ChatSideType.ON_HOLD;
      } else {
        index = chatsRequestItems?.findIndex(({ id }) => id === chatId);
        chat = chatsRequestItems[index];
        chatSide = ChatSideType.REQUEST;
      }

      const isTransferredToMe =
        chat.chatXferRequest?.targetAgent?.id === state?.activeAgentId;

      if (isTransferredToMe && chatSide === ChatSideType.ON_HOLD) {
        state.chatsOnHold.tray.splice(index, 1);
        state.chatsOnHold.totalChatsOnHold -= 1;
      }

      if (isTransferredToMe && chatSide === ChatSideType.REQUEST) {
        state.chatsRequest.tray.splice(index, 1);
        state.chatsRequest.totalChatRequests -= 1;
      }

      if (isTransferredToMe) {
        if (state?.activeChat?.id === chatId) delete state.activeChat;
      } else {
        chat.chatXferRequest = undefined;
        if (state.activeChat?.id === chat.id) state.activeChat = chat;
      }
    })
    .addCase(rejectChatTransfer, (state, action) => {
      const { chatId, rejectReason = '' } = action.payload;
      const index = state?.chatsOnHold?.tray?.findIndex(
        ({ id }) => id === chatId
      );
      if (index === -1) return;

      const chat = state.chatsOnHold.tray[index];
      const isTransferredToMe =
        chat.chatXferRequest?.targetAgent?.id === state?.activeAgentId;

      if (isTransferredToMe) {
        state.chatsOnHold.tray.splice(index, 1);
        state.chatsOnHold.totalChatsOnHold -= 1;
        if (state?.activeChat?.id === chatId) delete state.activeChat;
      } else if (chat?.chatXferRequest) {
        chat.chatXferRequest.status = AgentChatTransferStatus.REJECTED;
        chat.chatXferRequest.rejectReason = rejectReason;
        if (state.activeChat?.id === chat.id) state.activeChat = chat;
      }
    })
    .addCase(cleanChatTransfer, (state, action) => {
      const chatId = action.payload;
      const chat = state?.chatsOnHold?.tray?.find(({ id }) => id === chatId);
      if (chat) {
        delete chat.chatXferRequest;
        if (state.activeChat?.id === chat.id) state.activeChat = chat;
      }
    });
};
