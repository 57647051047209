//import BackendProxy from'../components/AdminHome/Order/OrderMock.js';
import BackendProxy from '../logic/backendproxy';

export const LOAD_DEFAULT_FIELDS = 'ORDER.LOAD_DEFAULT_FIELDS';
export const LOADING_FILTERS = 'ORDER.LOADING_FILTERS';

export const START_LOADING_CHATS_LIST = 'ORDER.START_LOADING_CHATS_LIST';
export const OK_LOADED_ORDERS_LIST = 'ORDER.OK_LOADED_ORDERS_LIST';
export const ERROR_LOADED_CHATS_LIST = 'ORDER.ERROR_LOADED_CHATS_LIST';

export const RESET_ALL_FILTER_VALUES = 'ORDER.RESET_ALL_FILTER_VALUES';
export const SELECTING_CHAT_ROW = 'ORDER.SELECTING_CHAT_ROW';

export const START_LOADING_CONTACTS_LIST = 'ORDER.START_LOADING_CONTACTS_LIST';
export const OK_LOADED_CONTACTS_LIST = 'ORDER.OK_LOADED_CONTACTS_LIST';
export const ERROR_LOADED_CONTACTS_LIST = 'ORDER.ERROR_LOADED_CONTACTS_LIST';

export const START_LOADING_STATES_LIST = 'ORDER.START_LOADING_STATES_LIST';
export const OK_LOADED_STATES_LIST = 'ORDER.OK_LOADED_STATES_LIST';
export const ERROR_LOADED_STATES_LIST = 'ORDER.ERROR_LOADED_STATES_LIST';
export const START_LOADING_EMAIL = 'ORDER.START_LOADING_EMAIL';

export function changeFilters(newFilters) {
  return (dispatch, getState) => {
    dispatch(loadingFilters(newFilters));
    dispatch(startLoadingChatsList());
    commonLoadOrders(dispatch, getState);
  };
}

export function blurOrderNumber(newText) {
  return (dispatch, getState) => {
    dispatch(startLoadingChatsList());
    commonLoadOrders(dispatch, getState);
  };
}

function commonLoadOrders(dispatch, getState) {
  const merchantName = getState().loginAuthentication.success.employer.name;
  const merchantId = getState().loginAuthentication.success.employer.id;
  const { toolbar } = getState().adminHome.order;
  const { filters } = toolbar;
  filters.merchantName = merchantName;
  filters.merchantId = merchantId;

  BackendProxy.getOrdersList(
    filters,
    respData => {
      console.log('OK, Order list: ', respData);
      const orderResults = respData;
      setTimeout(() => {
        dispatch(okLoadedOrdersList(orderResults));
      }, 1000);
    },
    (respStatus, error) => {
      // Handle errors that might occur while uploading media
      console.log(`Error Getting Orders! Status code: ${respStatus}`, error);
      dispatch(errorLoadedChatsList(error));
    }
  );
}

export function loadingFilters(filters) {
  return {
    type: LOADING_FILTERS,
    filters,
  };
}

export function changeOrderNumber(filters) {
  return {
    type: LOADING_FILTERS,
    filters,
  };
}

export function loadDefaultOrders(minDate, startDate) {
  return (dispatch, getState) => {
    dispatch(loadDefaultFields(minDate, startDate));
    commonLoadOrders(dispatch, getState);
  };
}

export function loadDefaultFields(minDate, startDate) {
  return {
    type: LOAD_DEFAULT_FIELDS,
    orderNumberLike: '',
    orderStateId: '',
    contactIdentificationNumber: '',
    contactName: '',
    startDate: minDate,
    endDate: startDate,
  };
}

export function startLoadingChatsList() {
  return {
    type: START_LOADING_CHATS_LIST,
  };
}

export function okLoadedOrdersList(results) {
  return {
    type: OK_LOADED_ORDERS_LIST,
    results,
    filterCount: 100,
    pageCount: 10,
    currentPage: 1,
    pageSize: 10,
  };
}

export function errorLoadedChatsList(error) {
  return {
    type: ERROR_LOADED_CHATS_LIST,
    message: error,
  };
}

export function clickResetAllFilters() {
  return {
    type: RESET_ALL_FILTER_VALUES,
    orderNumberLike: '',
    contactIdentificationNumber: '',
    contactName: '',
    startDate: new Date(),
    endDate: new Date(),
    orders: [],
    filterCount: 0,
    pageCount: 0,
    currentPage: 0,
    pageSize: 0,
    loadStatus: null,
  };
}

//#################### Contacts Manager ##############
export function loadContacts() {
  return (dispatch, getState) => {
    dispatch(startLoadingContactsList());
    const merchantId = getState().loginAuthentication.success.employer.id;
    BackendProxy.getOrderContacts(
      merchantId,
      respData => {
        console.log('OK, Contacts list: ', respData);
        const contacts = respData;
        dispatch(okLoadedContactsList(contacts));
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        dispatch(errorLoadedContactsList(error));
      }
    );
  };
}

export function startLoadingContactsList() {
  return {
    type: START_LOADING_CONTACTS_LIST,
  };
}

export function okLoadedContactsList(results) {
  return {
    type: OK_LOADED_CONTACTS_LIST,
    results,
  };
}

export function errorLoadedContactsList(error) {
  return {
    type: ERROR_LOADED_CONTACTS_LIST,
    message: error,
  };
}

//#################### States Manager ##############
export function loadStates() {
  return (dispatch, getState) => {
    dispatch(startLoadingAgentsList());
    const merchantId = getState().loginAuthentication.success.employer.id;
    BackendProxy.getOrderStates(
      merchantId,
      respData => {
        console.log('OK, States list: ', respData);
        const states = respData;
        setTimeout(() => {
          dispatch(okLoadedStatesList(states));
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(`Error Getting States! Status code: ${respStatus}`, error);
        dispatch(errorLoadedStatesList(error));
      }
    );
  };
}

export function startLoadingAgentsList() {
  return {
    type: START_LOADING_STATES_LIST,
  };
}

export function okLoadedStatesList(results) {
  return {
    type: OK_LOADED_STATES_LIST,
    results,
  };
}

export function errorLoadedStatesList(error) {
  return {
    type: ERROR_LOADED_STATES_LIST,
    message: error,
  };
}

export function setSelectedChatId(newOrderId) {
  return {
    type: SELECTING_CHAT_ROW,
    orderIdSelected: newOrderId,
  };
}

export function changeEmail(email) {
  return {
    type: START_LOADING_EMAIL,
    email,
  };
}

export function exportOrder(msg) {
  return (dispatch, getState) => {
    const { email } = getState().adminHome.order.export;
    let { filters } = getState().adminHome.order.toolbar;
    filters.emailToSend = email;
    filters = { ...filters, ...msg };
    dispatch(changeEmail(''));
    //var merchantId = getState().loginAuthentication.success.employer.id;
    BackendProxy.orderExport(
      filters,
      respData => {
        console.log('OK, exportOrder: ', respData);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(`Error exportOrder! Status code: ${respStatus}`, error);
      }
    );
  };
}
