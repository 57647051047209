import { Tchart } from '@reducers/deliveriesMassive';

const language = window.navigator.language.split(/[-_]/)[0];

const getDay = (daynumber: string): string => {
  let result = '';
  switch (daynumber) {
    case '1':
      result = language === 'es' ? 'Lunes' : 'Monday';
      break;
    case '2':
      result = language === 'es' ? 'Martes' : 'Tuesday';
      break;
    case '3':
      result = language === 'es' ? 'Miércoles' : 'Wednesday';
      break;
    case '4':
      result = language === 'es' ? 'Jueves' : 'Thursday';
      break;
    case '5':
      result = language === 'es' ? 'Viernes' : 'Friday';
      break;
    case '6':
      result = language === 'es' ? 'Sábado' : 'Saturday';
      break;
    case '7':
      result = language === 'es' ? 'Domingo' : 'Sunday';
      break;
    default:
      break;
  }
  return result;
};

const formatDay = (labels: string[]) =>
  labels.map(dayNumber => getDay(dayNumber));

const chartjs2 = (chart: Tchart, rangeTime: string) => ({
  config: {
    // Array for axing X
    labels: rangeTime === '7d' ? formatDay(chart.labels) : chart.labels || [],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Mensajes leidos',
        data: chart.readsMessages || [],
        backgroundColor: ['#4c33ff'],
        borderRadius: 3,
        // width column
        barThickness: 5,
      },
      {
        label: 'Mensajes respondidos',
        data: chart.repliedMessages || [],
        backgroundColor: ['#F8981D'],
        borderRadius: 3,
        barThickness: 5,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 10,
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
    },
  },
});

export default chartjs2;

const CHART_PALLETE = [
  '#FACC15',
  '#323444',
  '#86CB7B',
  '#4C33FF',
  '#F27468',
  '#478ECC',
  '#F8981D',
  '#7C69FF',
  '#E0B300',
  '#A1A1AA',
  '#A4D89C',
  '#004E93',
  '#F5978E',
  '#75AAD9',
  '#C52E1F',
  '#71717A',
];

export function chartColor(index: number) {
  return CHART_PALLETE[index % CHART_PALLETE.length];
}
