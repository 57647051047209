const buildInitialCreateOrderState = function () {
  return {
    toolbar: {
      contacts: [],
      products: [],
      contactLoadStatus: null,
      productLoadStatus: null,
      errorContactMessage: null,
      errorProductMessage: null,
      contactValue: '',
      productValue: '',
    },
    contact: {
      loadStatus: null,
      errorMessage: null,
    },
    cart: {
      products: [],
      loadStatus: null,
      errorMessage: null,
      curReference: null,
    },
    dialog: {
      openErrorModal: false,
    },
    order: {
      status: '',
      orderNumber: '',
      cause: '',
    },
  };
};

export default buildInitialCreateOrderState;
