import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { selectAuthenticationInfo } from '@selectors/user';
import { LoginSuccess } from '@src/types';
import env from '@utils/env';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';

const service = 'chat-center-app';

const datadogRumInitConfig: RumInitConfiguration = {
  applicationId: '94f7dd57-ed57-42db-85f9-25f9fa0cf517',
  clientToken: 'pub55cc61d179fd86b58d908a0af6ec8f43',
  site: 'datadoghq.com',
  service,
  env: env.APP_ENV,
  version: env.VERSION,
  sampleRate: 100,
  trackInteractions: false,
};

const datadogLogsInitConfig: LogsInitConfiguration = {
  clientToken: 'pub55cc61d179fd86b58d908a0af6ec8f43',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  sampleRate: 100,
};

if (!datadogLogs.getInitConfiguration())
  datadogLogs.init(datadogLogsInitConfig);

const MerchantWhiteList = [1482, 392, 1480, 1462, 1150, 1368, 1370, 1828];

function isAllowed(auth?: LoginSuccess) {
  if (MerchantWhiteList.includes(auth?.employer.id || -1)) return true;

  if (['beta'].includes(env.APP_ENV)) return true;

  return false;
}

// eslint-disable-next-line import/prefer-default-export
export const DataDogRumTracker = withRouter(({ location }) => {
  const auth = useSelector(selectAuthenticationInfo);

  useEffect(() => {
    if (!auth || !isAllowed(auth)) {
      datadogRum.stopSessionReplayRecording();
      datadogRum.removeUser();
      return;
    }

    if (location.pathname.startsWith('/agent/chat')) {
      if (!datadogRum.getInitConfiguration())
        datadogRum.init(datadogRumInitConfig);

      datadogRum.setUser({
        id: auth.id.toString(),
        username: auth.localUsername,
        merchant: {
          id: auth.employer.id,
          name: auth.employer.name,
        },
      });
      datadogRum.startSessionReplayRecording();
    } else {
      datadogRum.stopSessionReplayRecording();
    }
  }, [auth, location.pathname]);

  return null;
});
