const TypeUtils = (function () {
  return {
    // Pure duck-typing implementation taken from Underscore.js
    // Taken from: https://jsperf.com/alternative-isfunction-implementations
    isFunction(f) {
      return !!(f && f.constructor && f.call && f.apply);
    },
    // Checks if the specified variable contains an undefined or null value
    // This may look like a straightforward task, but in JavaScript it is not quite. Refer to:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/undefined
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Data_structures
    // https://stackoverflow.com/questions/858181/how-to-check-a-not-defined-variable-in-javascript
    isUndefinedOrNull(o) {
      return o === undefined || o === null;
    },
    // Checks if the argument is an array
    isArray(o) {
      return Object.prototype.toString.call(o) === '[object Array]';
    },
  };
})();

export default TypeUtils;
