export type ContactAssignment = {
  id: number;
  type: ContactAssignmentType;
  description: string;
};

export const ContactAssignmentOptions = {
  AGENT: 'AGENT',
  DEPARTMENT: 'DEPARTMENT',
  UNASIGNED: 'UNASIGNED',
};

export type ContactAssignmentType = keyof typeof ContactAssignmentOptions;
