import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  apiResource,
  b2chatBaseQuery,
  B2ChatClientContacts,
} from '@src/client';

export type AssignmentConfig = {
  prioritizeManagerRedirection: boolean; //true or false
  assignmentMode: 'AUTOMATIC' | 'MANUAL';
  assignmentSetterAllowed: 'ADMIN' | 'ALL';
};

export type GetAssignmentConfigResponse = AssignmentConfig;

export type UpdateAssignmentConfigRequest = AssignmentConfig;

export type AutoAssigneeRequest = {
  contactId: string;
};

export type AutoAssigneeResponse = {
  id: number;
  type: string;
  description: string;
};

export const assigmentManagerApi = createApi({
  reducerPath: 'assignmentManager',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['assignment-config', 'auto-assignee'],
  endpoints: ({ query, mutation }) => ({
    getAssignmentConfig: query<GetAssignmentConfigResponse, void>({
      providesTags: ['assignment-config'],
      query: () =>
        apiResource(
          B2ChatClientContacts.resources.managerAssignment.actions
            .getAssignmentConfig,
          {}
        ),
    }),
    updateAssignmentConfig: mutation<void, UpdateAssignmentConfigRequest>({
      invalidatesTags: ['assignment-config'],
      query: request =>
        apiResource(
          B2ChatClientContacts.resources.managerAssignment.actions
            .updateAssignmentConfig,
          {
            data: request,
          }
        ),
    }),
    setAutoAssignee: mutation<void, AutoAssigneeRequest>({
      invalidatesTags: ['auto-assignee'],
      query: request =>
        apiResource(
          B2ChatClientContacts.resources.assignedContact.actions
            .setAutoAssignee,
          { params: request }
        ),
    }),
    getAssignee: query<AutoAssigneeResponse, AutoAssigneeRequest>({
      providesTags: ['auto-assignee'],
      query: request =>
        apiResource(
          B2ChatClientContacts.resources.assignedContact.actions
            .getAssignedContact,
          { params: request }
        ),
    }),
  }),
});

export const {
  useGetAssignmentConfigQuery,
  useLazyGetAssignmentConfigQuery,
  useUpdateAssignmentConfigMutation,
  useSetAutoAssigneeMutation,
  useGetAssigneeQuery,
} = assigmentManagerApi;
