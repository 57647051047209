/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, select, takeLatest } from '@redux-saga/core/effects';

import {
  assignToContactTags,
  assignToContactTagsFailure,
  assignToContactTagsFulfill,
  assignToContactTagsSuccess,
  cleanAndFetchContactTagsById,
  cleanContactTagsById,
  createContactTag,
  createContactTagFailure,
  createContactTagFulfill,
  createContactTagSuccess,
  deleteContactTag,
  deleteContactTagFailure,
  deleteContactTagFulfill,
  fetchContactTags,
  fetchContactTagsById,
  fetchContactTagsByIdFailure,
  fetchContactTagsByIdFulfill,
  fetchContactTagsByIdSuccess,
  fetchContactTagsFailure,
  fetchContactTagsFulfill,
  fetchContactTagsSuccess,
  setForceToUpdateTagList,
  TagsPagination,
  TagsPaginationResponse,
  unassignToContactTags,
  unassignToContactTagsFailure,
  unassignToContactTagsFulfill,
  unassignToContactTagsSuccess,
  updateContactTag,
  updateContactTagFailure,
  updateContactTagFulfill,
  updateContactTagSuccess,
} from '@src/reducers/tags';
import { getContactTagsPagination, getEditingTag } from '@src/selectors/tags';
import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';
import {
  GenericTag,
  getTagData,
  NewTag,
  TagType,
} from '../components/AdminHome/Tags/utils';

function* fetchContactTagsSaga(action: ReturnType<typeof fetchContactTags>) {
  const { type, pagination } = action.payload;
  const currentPagination: TagsPagination = yield select(
    getContactTagsPagination
  );

  try {
    const response: B2ChatAPI.Response<TagsPaginationResponse> = yield call(
      B2ChatClient.resources.newTags.actions.getTags,
      {
        params: {
          type,
          ...(pagination || {
            pageNum: 0,
            pageSize: currentPagination.pageSize || 10,
          }),
        },
      }
    );

    if (response.error) {
      yield put(fetchContactTagsFailure(response.error));
    } else {
      yield put(fetchContactTagsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchContactTagsFailure(error as ErrorData));
  } finally {
    yield put(fetchContactTagsFulfill());
  }
}

function* createContactTagSaga() {
  const tag: NewTag = yield select(getEditingTag);
  try {
    const response: B2ChatAPI.Response<NewTag> = yield call(
      B2ChatClient.resources.newTagsAdmin.actions.create,
      {
        data: tag,
      }
    );

    if (response.error) {
      yield put(createContactTagFailure(response.error));
    } else {
      yield put(createContactTagSuccess(response.data));
      yield put(fetchContactTags({ type: 'CONTACT' }));
    }
  } catch (error) {
    yield put(createContactTagFailure(error as ErrorData));
  } finally {
    yield put(createContactTagFulfill());
  }
}

function* updateContactTagSaga() {
  const tag: GenericTag = yield select(getEditingTag);
  if (!tag) {
    // eslint-disable-next-line no-console
    console.error('No tag to be edited!');
    return;
  }
  const tagTmp: NewTag = {
    ...getTagData(tag),
    type: TagType.CONTACT,
  };

  const { tagId, ...rest } = tagTmp;

  try {
    const response: B2ChatAPI.Response<NewTag> = yield call(
      B2ChatClient.resources.newTagsAdmin.actions.update,
      {
        params: { tagId: `${tagId}` },
        data: rest,
      }
    );

    if (response.error) {
      yield put(updateContactTagFailure(response.error));
    } else {
      yield put(updateContactTagSuccess(response.data));
      yield put(fetchContactTags({ type: 'CONTACT' }));
    }
  } catch (error) {
    yield put(updateContactTagFailure(error as ErrorData));
  } finally {
    yield put(updateContactTagFulfill());
  }
}

function* deleteContactTagSaga(action: ReturnType<typeof deleteContactTag>) {
  const { tagId } = action.payload;
  try {
    const response: B2ChatAPI.Response<NewTag> = yield call(
      B2ChatClient.resources.newTagsAdmin.actions.delete,
      {
        params: { tagId: `${tagId}` },
      }
    );

    if (response.error) {
      yield put(deleteContactTagFailure(response.error));
    } else {
      yield put(fetchContactTags({ type: 'CONTACT' }));
    }
  } catch (error) {
    yield put(deleteContactTagFailure(error as ErrorData));
  } finally {
    yield put(deleteContactTagFulfill());
  }
}

function* assignToContactTagsSaga({
  payload,
}: ReturnType<typeof assignToContactTags>) {
  const { contactId, tagIds, chatId } = payload;
  try {
    const assignToContactPayload = {
      data: { contactId, tagIds },
      ...(chatId && { params: { chatId } }),
    };
    const response: B2ChatAPI.Response<unknown> = yield call(
      chatId
        ? B2ChatClient.resources.newTags.actions.assignToContactChat
        : B2ChatClient.resources.newTags.actions.assignToContact,
      assignToContactPayload
    );

    if (response.error) {
      yield put(assignToContactTagsFailure(response.error));
    } else {
      yield put(assignToContactTagsSuccess());
      yield put(fetchContactTagsById({ contactId }));
    }
  } catch (error) {
    yield put(assignToContactTagsFailure(error as ErrorData));
  } finally {
    yield put(assignToContactTagsFulfill());
  }
}

function* fetchContactTagsByIdSaga(
  action: ReturnType<typeof fetchContactTagsById>
) {
  const { contactId } = action.payload;
  try {
    const response: B2ChatAPI.Response<NewTag[]> = yield call(
      B2ChatClient.resources.newTags.actions.getTagsByContact,
      {
        params: { contactId },
      }
    );

    if (response.error) {
      yield put(fetchContactTagsByIdFailure(response.error));
    } else {
      yield put(fetchContactTagsByIdSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchContactTagsByIdFailure(error as ErrorData));
  } finally {
    yield put(fetchContactTagsByIdFulfill());
    yield put(setForceToUpdateTagList(true));
  }
}

function* unassignToContactTagsSaga({
  payload,
}: ReturnType<typeof unassignToContactTags>) {
  try {
    const { contactId, tagId, chatId } = payload;
    const unassignToContactPayload = {
      params: {
        contactId,
        tagId,
        ...(chatId && { chatId }),
      },
    };
    const response: B2ChatAPI.Response<NewTag[]> = yield call(
      chatId
        ? B2ChatClient.resources.newTags.actions.unassignToContactChat
        : B2ChatClient.resources.newTags.actions.unassignToContact,
      unassignToContactPayload
    );

    if (response.error) {
      yield put(unassignToContactTagsFailure(response.error));
    } else {
      yield put(unassignToContactTagsSuccess());
      yield put(fetchContactTagsById({ contactId }));
    }
  } catch (error) {
    yield put(unassignToContactTagsFailure(error as ErrorData));
  } finally {
    yield put(unassignToContactTagsFulfill());
    yield put(setForceToUpdateTagList(true));
  }
}

function* cleanAndFetchContactTagsByIdSaga(
  action: ReturnType<typeof cleanAndFetchContactTagsById>
) {
  yield put(cleanContactTagsById());
  yield put(fetchContactTagsById(action.payload));
}

export default function* contactTagsSaga() {
  yield takeLatest(fetchContactTags, fetchContactTagsSaga);
  yield takeLatest(createContactTag, createContactTagSaga);
  yield takeLatest(updateContactTag, updateContactTagSaga);
  yield takeLatest(deleteContactTag, deleteContactTagSaga);
  yield takeLatest(assignToContactTags, assignToContactTagsSaga);
  yield takeLatest(fetchContactTagsById, fetchContactTagsByIdSaga);
  yield takeLatest(unassignToContactTags, unassignToContactTagsSaga);
  yield takeLatest(
    cleanAndFetchContactTagsById,
    cleanAndFetchContactTagsByIdSaga
  );
}
