const buildInitialContactState = function () {
  return {
    contacts: {
      conversation: {
        availableMessaginAccount: [],
        contact: null,
        optionSelected: '',
      },
      citiesDataSet: [],
      data: [],
      filters: {
        byDate: '0',
        byCity: '',
        general: '',
        data: [],
        timeframe: 'this_7_days', //'this_30_days',
        timeframe_start_date: null,
        timeframe_end_date: null,
        custom_timeframe: false,
        provider: 'all',
      },
      botId: '',
      newContact: {
        fullName: '',
        identificationNumber: '',
        email: '',
        phoneNumber: '',
        mobileNumber: '',
        address: '',
        cityName: '',
        country: { isoCode: '', name: '', languageCode: '' },
        city: {
          id: '',
        },
      },
      bots: null,
    },
    notifications: {
      type: '',
      message: '',
    },
  };
};

export default buildInitialContactState;
