import { combineReducers } from 'redux';
import {
  LOAD_DEFAULT_FIELDS,
  LOADING_FILTERS,
  START_LOADING_CHATS_LIST,
  OK_LOADED_CHATS_LIST,
  RESET_ALL_FILTER_VALUES,
  SELECTING_CHAT_ROW,
  START_LOADING_CONTACTS_LIST,
  OK_LOADED_CONTACTS_LIST,
  START_LOADING_AGENTS_LIST,
  OK_LOADED_AGENTS_LIST,
  START_LOADING_MESSAGES_LIST,
  OK_LOADED_MESSAGES_LIST,
  ERROR_LOADED_AGENTS_LIST,
  ERROR_LOADED_CONTACTS_LIST,
  ERROR_LOADED_CHATS_LIST,
  ERROR_LOADED_MESSAGES_LIST,
  TIMEFRAME_SELECTED,
  TIMEFRAME_START_DATE_SELECTED,
  TIMEFRAME_END_DATE_SELECTED,
  DISABLE_OR_ENABLE_FILTERS,
  SHOW_OR_HIDE_DATE_RANGE_VALIDATION,
} from '../actions/audit';

const filtersReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_DEFAULT_FIELDS:
      return {
        ...state,
        agentId: action.agentId,
        contactValue: action.contactValue,
        startDate: action.startDate,
        endDate: action.endDate,
        text: action.text,
        activeChat: action.activeChat,
        timeframe: action.timeframe,
        timeframe_start_date: action.timeframe_start_date,
        timeframe_end_date: action.timeframe_end_date,
        custom_timeframe: action.custom_timeframe,
      };
    case RESET_ALL_FILTER_VALUES:
      return {
        ...state,
        agentId: action.agentId,
        contactValue: action.contactValue,
        contactId: action.contactId,
        startDate: action.startDate,
        endDate: action.endDate,
        text: action.text,
        activeChat: action.activeChat,
        timeframe: action.timeframe,
        timeframe_start_date: action.timeframe_start_date,
        timeframe_end_date: action.timeframe_end_date,
        custom_timeframe: action.custom_timeframe,
      };

    case TIMEFRAME_SELECTED:
      return {
        ...state,
        custom_timeframe: action.custom_timeframe,
        timeframe: action.timeframe,
        startDate: action.startDate,
        endDate: action.endDate,
        timeframe_start_date: action.timeframe_start_date,
        timeframe_end_date: action.timeframe_end_date,
      };

    case TIMEFRAME_START_DATE_SELECTED:
      return {
        ...state,
        timeframe_start_date: action.date,
        startDate: action.date,
      };

    case TIMEFRAME_END_DATE_SELECTED:
      return {
        ...state,
        timeframe_end_date: action.date,
        endDate: action.date,
      };

    case DISABLE_OR_ENABLE_FILTERS:
      return { ...state, disabled: action.disable };
    case SHOW_OR_HIDE_DATE_RANGE_VALIDATION:
      return { ...state, displayRangeOfDatesValidation: action.show };
    default:
      return state;
  }
};

const auditToolBarReducer = (state = {}, action) => {
  switch (action.type) {
    case LOADING_FILTERS:
      return { ...state, filters: action.filters };

    case LOAD_DEFAULT_FIELDS:
      return { ...state, filters: filtersReducer(state.filters, action) };

    case RESET_ALL_FILTER_VALUES:
      return { ...state, filters: filtersReducer(state.filters, action) };

    case TIMEFRAME_SELECTED:
      return { ...state, filters: filtersReducer(state.filters, action) };

    case TIMEFRAME_START_DATE_SELECTED:
      return { ...state, filters: filtersReducer(state.filters, action) };

    case TIMEFRAME_END_DATE_SELECTED:
      return { ...state, filters: filtersReducer(state.filters, action) };

    case START_LOADING_CONTACTS_LIST:
      return { ...state, contactLoadStatus: 'LOADING' };
    case OK_LOADED_CONTACTS_LIST:
      return {
        ...state,
        contactLoadStatus: 'OK_LOADED',
        contacts: action.results.contacts,
      };
    case ERROR_LOADED_CONTACTS_LIST:
      return {
        ...state,
        contactLoadStatus: 'ERROR_LOADED',
        errorContactMessage: action.message,
      };
    case START_LOADING_AGENTS_LIST:
      return { ...state, agentLoadStatus: 'LOADING' };
    case OK_LOADED_AGENTS_LIST:
      return {
        ...state,
        agentLoadStatus: 'OK_LOADED',
        agents: action.results.userStates,
      };
    case ERROR_LOADED_AGENTS_LIST:
      return {
        ...state,
        agentLoadStatus: 'ERROR_LOADED',
        errorAgentMessage: action.message,
      };
    case DISABLE_OR_ENABLE_FILTERS:
      return { ...state, filters: filtersReducer(state.filters, action) };
    case SHOW_OR_HIDE_DATE_RANGE_VALIDATION:
      return { ...state, filters: filtersReducer(state.filters, action) };

    default:
      return state;
  }
};

const auditChatReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_CHATS_LIST:
      return { ...state, loadStatus: 'LOADING' };
    case OK_LOADED_CHATS_LIST:
      return {
        ...state,
        loadStatus: 'OK_LOADED',
        chats: action.results.chats,
        filterCount: action.filterCount,
        pageCount: action.pageCount,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
      };
    case RESET_ALL_FILTER_VALUES:
      return {
        ...state,
        loadStatus: null,
        errorMessage: null,
        chats: action.chats,
        filterCount: action.filterCount,
        pageCount: action.pageCount,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
      };

    case ERROR_LOADED_CHATS_LIST:
      return {
        ...state,
        loadStatus: 'ERROR_LOADED',
        errorMessage: action.message,
      };

    default:
      return state;
  }
};

const auditMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_MESSAGES_LIST:
      return { ...state, loadStatus: 'LOADING' };
    case OK_LOADED_MESSAGES_LIST:
      return {
        ...state,
        loadStatus: 'OK_LOADED',
        messages: action.results.messages,
      };
    case RESET_ALL_FILTER_VALUES:
      return {
        ...state,
        loadStatus: null,
        chatIdSelected: '',
        messages: action.messages,
      };
    case ERROR_LOADED_MESSAGES_LIST:
      return {
        ...state,
        loadStatus: 'ERROR_LOADED',
        errorMessage: action.message,
      };
    case LOADING_FILTERS:
      return { ...state, loadStatus: null, messages: [] };
    case SELECTING_CHAT_ROW:
      return { ...state, chatIdSelected: action.chatIdSelected };

    default:
      return state;
  }
};

const auditReducer = combineReducers({
  toolbar: auditToolBarReducer,
  chat: auditChatReducer,
  message: auditMessageReducer,
});

export default auditReducer;
