import { Employer, LoginAuthentication, Selector } from '@types';

export const getMerchantId: Selector<number> = store => {
  const loginAuth = store.loginAuthentication as LoginAuthentication;
  return loginAuth?.success?.employer?.id;
};

export const selectEmployer: Selector<Employer> = store => {
  const loginAuth = store.loginAuthentication as LoginAuthentication;
  return loginAuth.success.employer;
};

export default getMerchantId;
