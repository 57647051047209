const buildInitialOrderDetailState = function () {
  return {
    orderNumber: null,
    order: null,
    loadStatus: null,
    errorMessage: null,
    currentContactOrder: null,
    orderStates: null,
    currentOrderState: null,
    mapUrl: null,
  };
};

export default buildInitialOrderDetailState;
