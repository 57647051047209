import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { ContactAccountOwner } from '@src/types';
import { ResponseStatus } from '@src/types/chats';

const contactVCardSlice = createSlice({
  name: 'contactVCard',
  initialState: {
    contactExists: {},
  } as {
    createContact: {
      status: ResponseStatus;
      key?: string;
    };
    contactExists: {
      [key: string]: {
        status: ResponseStatus;
        key?: string;
      };
    };
  },
  reducers: {
    createContact(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      {
        payload: { contact },
      }: PayloadAction<{
        botId: string;
        contact: Partial<ContactAccountOwner>;
      }>
    ) {
      state.createContact = {
        key: contact.mobileNumber,
        status: ResponseStatus.PENDING,
      };
    },
    createContactSuccess(state) {
      state.createContact = {
        ...state.createContact,
        status: ResponseStatus.SUCCESS,
      };
    },

    createContactFailure(state) {
      state.createContact = {
        ...state.createContact,
        status: ResponseStatus.FAILURE,
      };
    },

    checkContactExists(
      state,
      {
        payload,
      }: PayloadAction<{
        mobilePhoneNumber: string;
      }>
    ) {
      const { mobilePhoneNumber } = payload;
      state.contactExists[mobilePhoneNumber] = {
        ...state.contactExists[mobilePhoneNumber],
        status: ResponseStatus.PENDING,
        key: mobilePhoneNumber,
      };
    },

    checkContactExistsSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        mobilePhoneNumber: string;
      }>
    ) {
      const { mobilePhoneNumber } = payload;
      state.contactExists[mobilePhoneNumber] = {
        ...state.contactExists[mobilePhoneNumber],
        status: ResponseStatus.SUCCESS,
      };
    },

    checkContactExistsFailure(
      state,
      {
        payload,
      }: PayloadAction<{
        mobilePhoneNumber: string;
      }>
    ) {
      const { mobilePhoneNumber } = payload;
      state.contactExists[mobilePhoneNumber] = {
        ...state.contactExists[mobilePhoneNumber],
        status: ResponseStatus.FAILURE,
      };
    },
  },
});

const { actions, reducer } = contactVCardSlice;

export const {
  createContact,
  createContactSuccess,
  createContactFailure,
  checkContactExists,
  checkContactExistsSuccess,
  checkContactExistsFailure,
} = actions;

export default reducer;
