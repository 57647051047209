// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { combineReducers } from 'redux';
import { MENU_OPTION_CLOSED, MENU_OPTION_SELECTED } from '../actions/mainmenu';
import auditReducer from './audit';
// eslint-disable-next-line import/no-cycle
import auditUsers from './auditUsers';
import botSetupReducer from './botsetup';
import chatAutoAssignment from './chatAutoAssignment';
import chatsBehavior from './chatsBehavior';
import contactReducer from './contact';
import contactAssignmentReducer from './contactAssignment';
import contactsImport from './contactsImport';
import countries from './countries';
import createOrderReducer from './createorder';
import createProductReducer from './createproduct';
import faqsReportsReducer from './faqsReports';
import faqsSetupReducer from './faqsSetup';
import faqsCommonsReducer from './faqscommons';
import menuNav from './menuNav';
import orderReducer from './order';
import orderDetailReducer from './orderdetail';
import productReducer from './product';
import tags from './tags';
import userReducer from './user';
import userWaAccounts from './userWaAccounts';
import wsCatalogue from './wsCatalogue';
import wsConversationReport from './wsConversationReport';
import wsFlows from './wsFlows';

const adminMenuReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_OPTION_SELECTED:
      return { ...state, selectedOption: action.itemIndex };
    case MENU_OPTION_CLOSED:
      return { ...state, close: action.close };
    default:
      return state;
  }
};

const adminHomeReducer = combineReducers({
  menu: adminMenuReducer,
  audit: auditReducer,
  user: userReducer,
  contact: contactReducer,
  order: orderReducer,
  orderDetail: orderDetailReducer,
  createOrder: createOrderReducer,
  createProduct: createProductReducer,
  product: productReducer,
  botSetupState: botSetupReducer,
  faqsCommons: faqsCommonsReducer,
  faqsSetup: faqsSetupReducer,
  faqsReports: faqsReportsReducer,
  userWaAccounts,
  contactsImport,
  chatAutoAssignment,
  countries,
  wsConversationReport,
  wsCatalogue,
  chatsBehavior,
  auditUsers,
  tags,
  menuNav,
  contactAssignment: contactAssignmentReducer,
  wsFlows,
});

export default adminHomeReducer;
