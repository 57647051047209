import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { selectAppUpdate } from '@src/selectors/appUpdate';
import timeout from '@utils/timeout';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const AppUpdate: React.FC<{ intl: IntlShape } & RouteComponentProps> = ({
  location,
  intl: { formatMessage },
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const key = useSelector(selectAppUpdate);

  async function accept() {
    closeSnackbar(key);

    try {
      if (navigator.serviceWorker) {
        const registration = await navigator.serviceWorker.getRegistration();
        registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    await timeout(1_000);
    window.location.href = `/redirect?path=${encodeURIComponent(
      location.pathname
    )}`;
  }

  useEffect(() => {
    if (key === 'redundant') return;

    enqueueSnackbar(
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6" align="center">
            {formatMessage({ id: 'AppUpdate.Title' })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center">
            {formatMessage({ id: 'AppUpdate.Subtitle' })}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={accept}
            style={{ marginRight: '0.5rem' }}
          >
            {formatMessage({ id: 'AppUpdate.UpdateNow' })}
          </Button>
        </Grid>
      </Grid>,
      {
        key,
        persist: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        // eslint-disable-next-line no-shadow
        content: (key, message) => (
          <Paper
            key={key}
            style={{ maxWidth: 280, padding: '1.2rem 1rem' }}
            elevation={3}
          >
            {message}
          </Paper>
        ),
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return null;
};

export default withRouter(injectIntl(AppUpdate));
