import { createSlice } from '@reduxjs/toolkit';

export type SingletonSessionState = {
  state: 'active' | 'suspended' | 'inactive';
};

const initialState: SingletonSessionState = {
  state: 'active',
};

const singletonSession = createSlice({
  name: 'singletonSession',
  initialState,
  reducers: {
    activateSession(state) {
      state.state = 'active';
    },
    suspendSession(state) {
      state.state = 'suspended';
    },
    deactivateSession(state) {
      state.state = 'inactive';
    },
  },
});

export default singletonSession.reducer;

export const { activateSession, deactivateSession, suspendSession } =
  singletonSession.actions;
