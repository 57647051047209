export const DEFAULT_MESSAGES_REQUEST = 'DEFAULT_MESSAGES_REQUEST';
export const DEFAULT_MESSAGES_FAIL = 'DEFAULT_MESSAGES_FAIL';

const getDefaultMessages = (state = {}, action) => {
  switch (action.type) {
    case DEFAULT_MESSAGES_REQUEST:
      return { ...state, success: action.data, status: true };
    case DEFAULT_MESSAGES_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
// const loginAuthenticationReducer = combineReducers({
//   loginAuthentication:loginAuthentication
// });

export default getDefaultMessages;
