import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { QuickMessage } from '@types';

export type ActiveChatQuickMessagesState = {
  loading: 'idle' | 'pending';
  generalMessages: { botId: number; items: QuickMessage[] };
  inheritMessages: { botId: number; department: string; items: QuickMessage[] };
  currentMessages: { botId: number; department: string; items: QuickMessage[] };
  showQuickMsg: boolean;
  heightQuickMsg: number;
};

const initialState: ActiveChatQuickMessagesState = {
  loading: 'idle',
  generalMessages: { botId: -1, items: [] },
  inheritMessages: { botId: -1, department: '', items: [] },
  currentMessages: { botId: -1, department: '', items: [] },
  showQuickMsg: true,
  heightQuickMsg: 300,
};

type SucessAction = PayloadAction<
  Partial<
    Pick<
      ActiveChatQuickMessagesState,
      'generalMessages' | 'inheritMessages' | 'currentMessages'
    >
  >
>;

const activeChatQuickMessagesSlice = createSlice({
  name: 'activeChatQuickMessages',
  initialState,
  reducers: {
    clearActiveChatQuickMessages() {
      return initialState;
    },
    fetchActiveChatQuickMessages(state) {
      state.loading = 'pending';
    },
    fetchActiveChatQuickMessagesSuccess(state, { payload }: SucessAction) {
      if (payload.generalMessages) {
        state.generalMessages = payload.generalMessages;
      }
      if (payload.inheritMessages) {
        state.inheritMessages = payload.inheritMessages;
      }
      if (payload.currentMessages) {
        state.currentMessages = payload.currentMessages;
      }
      state.showQuickMsg = true;
    },
    fetchActiveChatQuickMessagesFailure() {
      return initialState;
    },
    fetchActiveChatQuickMessagesFulfill(state) {
      state.loading = 'idle';
    },
    setShowQuickMsg(state, action: PayloadAction<boolean>) {
      state.showQuickMsg = action.payload;
    },
    setHeightQuickMsg(state, action: PayloadAction<number>) {
      state.heightQuickMsg = action.payload;
    },
  },
});

export default activeChatQuickMessagesSlice.reducer;

export const {
  clearActiveChatQuickMessages,
  fetchActiveChatQuickMessages,
  fetchActiveChatQuickMessagesSuccess,
  fetchActiveChatQuickMessagesFailure,
  fetchActiveChatQuickMessagesFulfill,
  setShowQuickMsg,
  setHeightQuickMsg,
} = activeChatQuickMessagesSlice.actions;
