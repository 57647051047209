/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/extensions */
import { setDefaultOptions } from 'date-fns';
import { createIntl, createIntlCache } from 'react-intl';
import en from './en.json';
import es from './es.json';

const ALLOWED_LOCALES = ['en', 'es', 'ca'] as const;

type Locale = (typeof ALLOWED_LOCALES)[number];

function currentLocale(): Locale {
  const userLanguage = window.navigator.language.split(/[-_]/)[0];

  if (ALLOWED_LOCALES.includes(userLanguage as Locale)) {
    return userLanguage as Locale;
  }

  return 'en';
}

export const locale = currentLocale();

const cache = createIntlCache();

const intl = createIntl(
  {
    locale,
    messages: { es, ca: es, en }[locale],
    textComponent: 'span',
  },
  cache
);

export async function intlPolyfill() {
  if (!window.Intl) {
    await Promise.all([
      import('intl'),
      // @ts-ignore
      import('intl/locale-data/jsonp/en.js'),
      // @ts-ignore
      import('intl/locale-data/jsonp/es.js'),
    ]);
  }
}

export const parseMessage = (id: string, key?: string): string =>
  key
    ? intl.formatMessage({ id }, { key })
    : intl.formatMessage({ id }, {}, { ignoreTag: true });

export const parseMessageValues = (
  id: string,
  values: Record<string, string | number | boolean>
): string => intl.formatMessage({ id }, values);

export default intl;

async function initDateFnsLocale() {
  if (locale === 'es' || locale === 'ca') {
    setDefaultOptions({ locale: (await import('date-fns/locale/es')).default });
  }
}

initDateFnsLocale();
