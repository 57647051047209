const buildInitialFaqsReportsState = function () {
  return {
    /*    listBotsObject:{

      listBots: [],
      botValue: '',
      botValueConservate: '',
      uniqueBot: false
    }, */

    reportsObject: {
      message: 'HOla',
    },
  }; //fin return
}; //fin function

export default buildInitialFaqsReportsState;
