/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ErrorData } from '@b2chat/chat-center-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TimelineChat, TimelineEvent } from '@types';
import omit from 'lodash/omit';

export interface ChatHistoryItem {
  itemIndex: number;
  chat: Omit<TimelineChat, 'events'>;
  event: TimelineEvent;
}

export type ChatHistoryState = {
  [referenceChatId: string]: {
    referenceChatId: string;
    /**
     * Chats are sorted by descending date order
     * @example [Recent, ..., Oldest]
     */
    history: ChatHistoryItem[];
    firstItemIndex: number;
    limit: number;
    offset: number;
    completed: boolean;
    loading: boolean;
    error?: ErrorData;
    lastSeen: number;
  };
};

export const chatHistoryInitialState: ChatHistoryState[string] = {
  referenceChatId: '',
  history: [],
  firstItemIndex: 10_000_001,
  limit: 20,
  offset: 0,
  completed: false,
  loading: false,
  error: undefined,
  lastSeen: 0,
};

const chatHistorySlice = createSlice({
  initialState: {} as ChatHistoryState,
  name: 'ChatHistory',
  reducers: {
    fetchEvents(
      state,
      action: PayloadAction<{ referenceChatId: string; resetOffset?: boolean }>
    ) {
      const { referenceChatId } = action.payload;
      if (!state[referenceChatId]) {
        state[referenceChatId] = {
          ...chatHistoryInitialState,
          history: [],
          lastSeen: Date.now(),
        };
        state[referenceChatId].referenceChatId = referenceChatId;
      }

      state[referenceChatId].loading = true;
      state[referenceChatId].error = undefined;
    },
    fetchEventsFailure(
      state,
      action: PayloadAction<{ referenceChatId: string; error: ErrorData }>
    ) {
      const { referenceChatId, error } = action.payload;

      if (!state[referenceChatId]) return;

      state[referenceChatId].error = error;
    },
    fetchEventsFulfill(
      state,
      action: PayloadAction<{ referenceChatId: string }>
    ) {
      const { referenceChatId } = action.payload;

      if (!state[referenceChatId]) return;

      state[referenceChatId].loading = false;
    },
    cancelFetchEvents(
      state,
      action: PayloadAction<{ referenceChatId: string }>
    ) {
      const { referenceChatId } = action.payload;

      if (!state[referenceChatId]) return;

      state[referenceChatId].loading = false;
    },
    appendChat(
      state,
      action: PayloadAction<{
        referenceChatId: string;
        chunks: TimelineChat[];
        reset?: boolean;
      }>
    ) {
      const { chunks, referenceChatId, reset } = action.payload;

      if (reset) {
        Object.keys(state).forEach(key => {
          state[key] = {
            ...chatHistoryInitialState,
            history: [],
            lastSeen: Date.now(),
          };
        });
      }
      if (!state[referenceChatId]) return;

      const { history } = state[referenceChatId];

      let lastSendingTime = 0;

      const oldHistory: ChatHistoryItem[] = chunks
        .map(chunk => {
          const oldestChat = history.at(-1)?.chat;
          const chat =
            oldestChat?.id === chunk.id ? oldestChat : omit(chunk, 'events');
          // @ts-ignore
          return chunk.events.map(event => {
            state[referenceChatId].firstItemIndex -= 1;

            lastSendingTime =
              event.sendingTime || event.timestamp || lastSendingTime;

            return {
              itemIndex: state[referenceChatId].firstItemIndex,
              chat,
              event: {
                ...event,
                sendingTime: lastSendingTime,
              },
            };
          });
        })
        .flat(1);

      history.push(...oldHistory);
    },
    unshiftEvent(
      state,
      action: PayloadAction<{
        referenceChatId: string;
        chatId: string;
        event: Partial<TimelineEvent>;
        status?: TimelineChat['status'];
        assignedTo?: TimelineChat['assignedTo'];
      }>
    ) {
      const { referenceChatId, chatId, event, status, assignedTo } =
        action.payload;

      if (!state[referenceChatId]) return;

      const { history } = state[referenceChatId];

      const eventUpdated =
        !!event?.messageId &&
        history
          .filter(item => item.chat.id === chatId)
          .some(item => {
            if (item?.event?.messageId === event?.messageId) {
              item.event = { ...item.event, ...event };
              return true;
            }

            return false;
          });

      if (eventUpdated) return;

      if (!event.sendingTime) {
        event.sendingTime = event.sendingTime || event.timestamp || Date.now();
      }

      const currentChat: ChatHistoryItem = history.at(0) || {
        itemIndex: 10_000_001,
        event: event as TimelineEvent,
        chat: {
          id: chatId,
          status: status || 'OPENED',
          assignedTo: { id: 0, fullName: '', avatarUrl: '' },
        },
      };

      history.unshift({
        itemIndex: currentChat.itemIndex + 1,
        chat: {
          id: chatId,
          status: status || 'OPENED',
          assignedTo: assignedTo || currentChat.chat.assignedTo,
        },
        event: event as TimelineEvent,
      });
    },
    increaseOffset(state, action: PayloadAction<{ referenceChatId: string }>) {
      const { referenceChatId } = action.payload;

      if (!state[referenceChatId]) return;

      state[referenceChatId].offset += state[referenceChatId].limit;
    },
    completed(state, action: PayloadAction<{ referenceChatId: string }>) {
      const { referenceChatId } = action.payload;

      if (!state[referenceChatId]) return;

      state[referenceChatId].completed = true;
    },
    removeChatHistory(
      state,
      action: PayloadAction<{ referenceChatId: string }>
    ) {
      const { referenceChatId } = action.payload;

      delete state[referenceChatId];
    },
    activateChatHistory(
      state,
      action: PayloadAction<{ referenceChatId: string }>
    ) {
      const { referenceChatId } = action.payload;

      if (!state[referenceChatId]) return;

      state[referenceChatId].lastSeen = Date.now();
    },
    updateReferenceChatId(
      state,
      action: PayloadAction<{
        referenceChatId: string;
        newReferenceChatId: string;
      }>
    ) {
      const { newReferenceChatId, referenceChatId } = action.payload;

      if (referenceChatId in state) {
        state[newReferenceChatId] = state[referenceChatId];
        state[newReferenceChatId].referenceChatId = newReferenceChatId;
        delete state[referenceChatId];
      }
    },
  },
});

export default chatHistorySlice.reducer;

export const chatHistoryActions = chatHistorySlice.actions;
