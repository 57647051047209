import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { DepartmentValue } from '@src/components/DepartmentSelect/DepartmentSelect';
import { ErrorPayload } from '../types';

export type AutomaticMessage = {
  id: string;
  state: AutomaticMessage.State;
  name: string;
  hoursAfterStart: number;
  hoursBeforeLastMessage: number;
  event: AutomaticMessage.EventType | '';
  message: string;
  merchantId: number;
  createdAtInMillis: number;
  totalRows: number;
  departmentNodes: DepartmentValue[];
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AutomaticMessage {
  export type EventType = 'CONVERSATION' | 'WINDOW_SERVICE';
  export type State = 'INACTIVE' | 'ACTIVE';
  export type Error = ErrorPayload<'DUPLICATED_NAME' | 'CONFLICT_MESSAGES'> & {
    errorMessages?: AutomaticMessage[];
    warningMessages?: AutomaticMessage[];
  };
}

export type AutomaticMessagesState = {
  eventTypes: AutomaticMessage.EventType[];
  messages: {
    loading: ('idle' | 'pending' | 'success' | 'failure' | 'fulfill')[];
    filter: {
      name?: string;
      department?: number | string;
      event?: AutomaticMessage.EventType | '';
      state?: AutomaticMessage.State;
      hoursAfterStart?: number;
      offset: number;
      limit: number;
    };
    data: AutomaticMessage[];
    error?: ErrorPayload<'OPERATION_FAILED'>;
    total: number;
  };
  update: {
    loading: ('idle' | 'pending' | 'success' | 'failure' | 'fulfill')[];
    error?: AutomaticMessage.Error;
  };
};

const initialState: AutomaticMessagesState = {
  eventTypes: ['WINDOW_SERVICE'],
  messages: {
    loading: ['idle'],
    filter: {
      limit: 50,
      offset: 0,
    },
    data: [],
    total: 0,
  },
  update: {
    loading: ['idle'],
  },
};

const AutomaticMessageSlice = createSlice({
  initialState,
  name: 'automaticMessages',
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchEventTypes() {},
    fetchEventTypesSuccess(
      state,
      action: PayloadAction<AutomaticMessage.EventType[]>
    ) {
      state.eventTypes = action.payload;
    },
    searchMessages(
      state,
      action: PayloadAction<
        Partial<AutomaticMessagesState['messages']['filter']>
      >
    ) {
      const { messages } = state;

      messages.loading = ['pending'];
      messages.error = undefined;
      messages.filter = { ...messages.filter, ...action.payload };
    },
    searchMessagesSuccess(
      state,
      action: PayloadAction<{ total: number; data: AutomaticMessage[] }>
    ) {
      const { messages } = state;

      messages.loading = ['success'];
      messages.data = action.payload.data.map(item => ({
        ...item,
        departmentNodes:
          item.departmentNodes.length === 0
            ? [{ name: 'allDepartments' }]
            : item.departmentNodes.map(normalizeDepartmentNode),
      }));

      messages.total = action.payload.total;
    },
    searchMessagesFailure(state, action: PayloadAction<ErrorPayload>) {
      const { messages } = state;

      messages.loading = ['failure'];
      messages.data = [];
      messages.total = 0;
      messages.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
    searchMessagesFulfill(state) {
      const { messages } = state;

      messages.loading.push('fulfill');
    },
    startUpdateMessage(state) {
      state.update.loading = ['idle'];
      state.update.error = undefined;
    },
    updateMessage(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        mode: 'update' | 'create' | 'delete';
        data: Partial<AutomaticMessage>;
        skipWarnings?: boolean;
      }>
    ) {
      state.update.loading = ['pending'];
      state.update.error = undefined;
    },
    updateMessageFailure(state, action: PayloadAction<ErrorPayload>) {
      state.update.loading = ['failure'];
      const error = action.payload as AutomaticMessage.Error;

      // remapping departmentNodes
      error.errorMessages = error.errorMessages?.map(item => ({
        ...item,
        departmentNodes: item.departmentNodes.map(normalizeDepartmentNode),
      }));
      error.warningMessages = error.warningMessages?.map(item => ({
        ...item,
        departmentNodes: item.departmentNodes.map(normalizeDepartmentNode),
      }));

      state.update.error = error;
    },
    updateMessageSuccess(
      state,
      action: PayloadAction<Partial<AutomaticMessage>>
    ) {
      const { id } = action.payload;
      const { messages } = state;

      if (id) {
        const index = messages.data.findIndex(item => item.id === id);
        if (index !== -1) {
          messages.data[index] = { ...messages.data[index], ...action.payload };
        }
      }

      state.update.loading = ['success'];
    },
    updateMessageFulfill(state) {
      state.update.loading.push('fulfill');
    },
  },
});

export default AutomaticMessageSlice.reducer;

export const {
  fetchEventTypes,
  fetchEventTypesSuccess,
  searchMessages,
  searchMessagesSuccess,
  searchMessagesFailure,
  searchMessagesFulfill,
  startUpdateMessage,
  updateMessage,
  updateMessageSuccess,
  updateMessageFailure,
  updateMessageFulfill,
} = AutomaticMessageSlice.actions;

function normalizeDepartmentNode(node: Record<string, unknown>): {
  departmentId?: number;
  name?: string;
} {
  return { departmentId: node.id as number, ...node };
}
