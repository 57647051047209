const buildInitialOrderState = function () {
  return {
    toolbar: {
      orderId: null,
      orderStates: [],
      contacts: [],
      orderNumberLoadStatus: null,
      orderStateLoadStatus: null,
      contactLoadStatus: null,
      errorOrderNumberMessage: null,
      errorOrderStateMessage: null,
      errorContactMessage: null,
      filters: {
        orderNumberLike: '',
        orderStateId: '',
        contactIdentificationNumber: '',
        contactName: '',
        startDate: null,
        endDate: null,
      },
    },
    order: {
      orders: [],
      filterCount: 0,
      pageCount: 0,
      currentPage: 0,
      pageSize: 0,
      loadStatus: null,
      errorMessage: null,
      orderIdSelected: null,
    },
    export: {
      email: '',
    },
  };
};

export default buildInitialOrderState;
