/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { CreateReportPayload, ErrorPayload, WhatsAppTemplate } from '../types';

export type Clicks = { [keyof: string]: number };

export type TdeliveryMassive = {
  id: string;
  campaign: string;
  contacts: number;
  createdAt: string | Date;
  sendAt: string | Date;
  template: string;
  state: string;
  totalDelivered: number;
  totalRead: number;
  totalReplied: number;
  username: string;
  totalFailed: number;
  scheduledAt: string | Date;
  detailsAvailable: boolean;
  urlOriginalFile: string;
  templateResponses: Clicks[];
};

export type TactionPayload = {
  data: TdeliveryMassive[];
  limit: number;
  total: number;
  offset: number;
};

type TsortBy = {
  campaign: boolean;
  createdAt: boolean;
  template: boolean;
};

export type TdeliveryMassiveFilter = {
  campaign?: string;
  state?: string;
  dateInit?: string | Date;
  dateEnd?: string | Date;
  offset: number;
  limit: number;
  orderby: string;
  sortTableBy: TsortBy;
};

export type Tchart = {
  labels: string[];
  readsMessages: number[];
  repliedMessages: number[];
};

export type TapiReport = {
  labels: string[];
  failed: number[];
  noDelivered: number[];
  delivered: number[];
  read: number[];
  answers: number[];
} | null;

export type TapiChartReport = {
  labels: string[];
  failedMessages: number[];
  sendMessages: number[];
  deliveredMessages: number[];
  readsMessages: number[];
  repliedMessages: number[];
} | null;

export type TfailedApiChart = {
  labels: string[];
  failedMessagesPerReasonsMap: any;
} | null;

export type ApiReportFilter = {
  dateInit?: string;
  dateEnd?: string;
  template?: string;
  campaign?: string;
  from?: string;
  rangeCode?: string;
  origin?: string;
};

export type TCampaign = {
  campaign: string;
  detailsAvailable: boolean;
};

export type TapiStatistics = {
  totalDelivered: number;
  totalRead: number;
  totalReplied: number;
  totalSend: number;
  totalFailed: number;
  detailsAvailable: boolean;
  templateResponses: Clicks[];
};

type TDateRange = {
  dateInit: string;
  dateEnd: string;
};

const initialStatistics = {
  totalDelivered: 0,
  totalRead: 0,
  totalReplied: 0,
  totalSend: 0,
  totalFailed: 0,
  detailsAvailable: false,
  templateResponses: [],
};

const INITIAL_RANGECODE = '24h';

type Trequest = ('idle' | 'pending' | 'success' | 'failure' | 'fulfill')[];

export type TdeliveryMassiveState = {
  messages: {
    loading: Trequest;
    data: TdeliveryMassive[];
    filter: TdeliveryMassiveFilter;
    total: number;
    error?: ErrorPayload<'OPERATION_FAILED'>;
  };
  detail: {
    data: TdeliveryMassive | null;
    loading: Trequest;
    error?: ErrorPayload<'OPERATION_FAILED'>;
    openDialog: boolean;
    downloadReport: Trequest;
    reportType: string | null;
    reportName: string | null;
    templateResponsesFor?: string;
  };
  openDialog: boolean;
  responseCode: string;
  newRecord: boolean;
  changeInfo: boolean;
  rowSelected: TdeliveryMassive | null;
  chart: {
    data: unknown;
    loading: Trequest;
    error?: ErrorPayload<'OPERATION_FAILED'>;
    rangeTime: string;
  };
  apiChart: {
    data: any;
    loading: Trequest;
    error?: ErrorPayload<'OPERATION_FAILED'>;
    rangeCode: string;
    filters: {
      templates: {
        data: string[];
        loading: Trequest;
      };
      campaigns: {
        data: string[];
        loading: Trequest;
      };
      error?: ErrorPayload<'OPERATION_FAILED'>;
    };
    statistics: {
      data: TapiStatistics;
      loading: Trequest;
    };
    faileds: {
      data?: any;
      loading: Trequest;
    };
  };
};

const initialState: TdeliveryMassiveState = {
  messages: {
    loading: ['idle'],
    data: [],
    filter: {
      limit: 10,
      offset: 0,
      orderby: 'createdAt',
      sortTableBy: {
        campaign: false,
        template: false,
        createdAt: false,
      },
    },
    total: 0,
  },
  detail: {
    data: null,
    loading: ['idle'],
    openDialog: false,
    downloadReport: ['idle'],
    reportType: null,
    reportName: null,
  },
  openDialog: false,
  responseCode: '200',
  newRecord: false,
  changeInfo: false,
  rowSelected: null,
  chart: {
    data: null,
    loading: ['idle'],
    rangeTime: '24h',
  },
  apiChart: {
    data: null,
    loading: ['idle'],
    rangeCode: INITIAL_RANGECODE,
    filters: {
      templates: {
        data: [],
        loading: ['idle'],
      },
      campaigns: {
        data: [],
        loading: ['idle'],
      },
    },
    statistics: {
      data: initialStatistics,
      loading: ['idle'],
    },
    faileds: {
      loading: ['idle'],
    },
  },
};

const DeliveriresMassiveSlice = createSlice({
  initialState,
  name: 'deliveriesMassive',
  reducers: {
    applySearchFilter(state, action: PayloadAction<TdeliveryMassiveFilter>) {
      const { messages } = state;
      const { orderby, sortTableBy } = messages.filter;
      messages.loading = ['pending'];
      messages.error = undefined;

      const { offset, limit, campaign, dateInit, dateEnd } = action.payload;
      let filter: TdeliveryMassiveFilter = {
        orderby,
        offset,
        limit,
        sortTableBy,
      };
      filter = dateInit && dateEnd ? { ...filter, dateInit, dateEnd } : filter;
      filter = action.payload.state
        ? { ...filter, state: action.payload.state }
        : filter;
      filter = campaign
        ? { ...filter, campaign: campaign.toLowerCase().trim() }
        : filter;

      messages.filter = filter;
      state.detail = {
        ...state.detail,
        data: null,
        loading: ['idle'],
      };
    },
    fetchDeliveriesMassiveSuccess(
      state,
      action: PayloadAction<TactionPayload>
    ) {
      const { data, limit, offset, total } = action.payload;
      const { sortTableBy } = state.messages.filter;
      let newData = data;

      Object.entries(sortTableBy).forEach(([key, value]) => {
        const keyName = key as keyof TsortBy;
        if (value)
          newData = data.sort((a, b) => (a[keyName] < b[keyName] ? -1 : 1));
      });

      const filter = { ...state.messages.filter, limit, offset };
      state.messages = { loading: ['success'], data: newData, filter, total };
    },
    fetchDeliveriesMassiveFailure(state, action: PayloadAction<ErrorPayload>) {
      const { messages } = state;

      messages.data.length = 0;
      state.messages = {
        ...messages,
        loading: ['failure'],
        total: 0,
        error: action.payload as ErrorPayload<'OPERATION_FAILED'>,
      };
    },
    fetchDeliveriesMassiveFulfill(state) {
      const { messages } = state;
      messages.loading = ['fulfill'];
    },
    fetchGetByIdRequest(state, _action: PayloadAction<string>) {
      const { detail } = state;
      detail.loading = ['pending'];
    },
    fetchGetByIdSuccess(state, action: PayloadAction<TdeliveryMassive>) {
      state.detail = {
        ...state.detail,
        data: action.payload,
        loading: ['success'],
      };
    },
    fetchGetByIdFailure(state, action: PayloadAction<ErrorPayload>) {
      state.detail = {
        ...state.detail,
        loading: ['failure'],
        data: null,
        error: action.payload as ErrorPayload<'OPERATION_FAILED'>,
      };
    },
    fetchGetByIdFulfill(state) {
      const { detail } = state;
      detail.loading = ['fulfill'];
    },
    fetchCancelDeliveryRequest(state) {
      // Status 102, it mean is processing
      state.responseCode = '102';
    },
    fetchCancelDeliverySuccess(state, action: PayloadAction<string>) {
      state.responseCode = action.payload;
    },
    fetchGetChartByRangeCode(
      state,
      action: PayloadAction<{ broadcastId: string; rangeTime: string }>
    ) {
      const { chart } = state;
      chart.loading = ['pending'];
      chart.rangeTime = action.payload.rangeTime;
    },
    fetchGetChartByRangeCodeSuccess(state, action: PayloadAction<unknown>) {
      const { chart } = state;
      chart.loading = ['success'];
      chart.data = action.payload;
    },
    fetchGetApiChart(state, action: PayloadAction<ApiReportFilter>) {
      const { apiChart } = state;
      apiChart.loading = ['pending'];
      apiChart.rangeCode = action.payload.rangeCode || INITIAL_RANGECODE;
    },
    fetchGetApiChartSuccess(state, action: PayloadAction<any>) {
      const { apiChart } = state;
      apiChart.data = action.payload;
      apiChart.faileds.data = null;
      apiChart.loading = ['success'];
    },
    fetchGetApiChartFailure(state, action: PayloadAction<ErrorPayload>) {
      const { apiChart } = state;
      apiChart.loading = ['failure'];
      apiChart.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
    fetchGetFailedApiChart(state, action: PayloadAction<ApiReportFilter>) {
      const { apiChart } = state;
      apiChart.faileds.loading = ['pending'];
      apiChart.rangeCode = action.payload.rangeCode || INITIAL_RANGECODE;
    },
    fetchGetFailedApiChartSuccess(state, action: PayloadAction<any>) {
      const { apiChart } = state;
      apiChart.data.config = null;
      apiChart.faileds.data = action.payload;
      apiChart.faileds.loading = ['success'];
    },
    fetchGetFailedApiChartFailure(state, action: PayloadAction<ErrorPayload>) {
      const { apiChart } = state;
      apiChart.faileds.loading = ['failure'];
      apiChart.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
    clearDataApiChart(state) {
      const { apiChart } = state;
      apiChart.data = null;
      apiChart.loading = ['idle'];
      apiChart.faileds = {
        loading: ['idle'],
      };
      apiChart.statistics = {
        data: initialStatistics,
        loading: ['idle'],
      };
    },
    fetchGetChartByRangeCodeFailure(
      state,
      action: PayloadAction<ErrorPayload>
    ) {
      state.chart = {
        loading: ['failure'],
        data: null,
        error: action.payload as ErrorPayload<'OPERATION_FAILED'>,
        rangeTime: state.chart.rangeTime,
      };
    },
    fetchGetApiStatistics(state, _action: PayloadAction<ApiReportFilter>) {
      const { apiChart } = state;
      apiChart.statistics = {
        data: initialStatistics,
        loading: ['pending'],
      };
    },
    fetchGetApiStatisticsSuccess(state, action: PayloadAction<TapiStatistics>) {
      const { apiChart } = state;
      apiChart.statistics.data = action.payload;
      apiChart.statistics.loading = ['success'];
    },
    fetchGetApiStatisticsFailure(state, action: PayloadAction<ErrorPayload>) {
      const { apiChart } = state;
      apiChart.statistics = {
        data: initialStatistics,
        loading: ['failure'],
      };
      apiChart.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
    fetchDownloadReport(state, _action: PayloadAction<CreateReportPayload>) {
      const { detail } = state;
      detail.downloadReport = ['pending'];
    },
    fetchDownloadReportSuccess(state) {
      const { detail } = state;
      detail.downloadReport = ['success'];
    },
    fetchDownloadReportFailure(state, action: PayloadAction<ErrorPayload>) {
      const { detail } = state;
      detail.downloadReport = ['failure'];
      detail.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
    setResponseCode(state, action: PayloadAction<string>) {
      state.responseCode = action.payload;
    },
    setNewRecord(state) {
      const { newRecord } = state;
      state.newRecord = !newRecord;
    },
    setOpenDialog(state) {
      const { openDialog } = state;
      state.openDialog = !openDialog;
    },
    setOpenDialogDetail(
      state,
      action: PayloadAction<{
        reportType: string | null;
        reportName: string | null;
        whatsappButtonName?: string;
      }>
    ) {
      const { openDialog, downloadReport } = state.detail;
      state.detail = {
        ...state.detail,
        openDialog: !openDialog,
        downloadReport: !openDialog ? ['idle'] : downloadReport,
        reportType: !openDialog ? action.payload.reportType : null,
        reportName: !openDialog ? action.payload.reportName : null,
        templateResponsesFor: !openDialog
          ? action.payload.whatsappButtonName
          : undefined,
      };
    },
    setChangeInfo(state) {
      const { changeInfo } = state;
      state.changeInfo = !changeInfo;
    },
    setRangeTime(state, action: PayloadAction<string>) {
      state.chart.rangeTime = action.payload;
    },
    setRowSelected(state, action: PayloadAction<TdeliveryMassive>) {
      state.rowSelected = action.payload;
    },
    setOrderBy(state, action: PayloadAction<string>) {
      const { data, filter } = state.messages;
      const key = action.payload as keyof TdeliveryMassive;
      const sortBy = action.payload as keyof TsortBy;
      const newValue = !filter.sortTableBy[sortBy];

      Object.entries(filter.sortTableBy).forEach(([keyName]) => {
        const prop = keyName as keyof TsortBy;
        if (prop === sortBy) state.messages.filter.sortTableBy[prop] = newValue;
        else state.messages.filter.sortTableBy[prop] = false;
      });

      if (newValue) data.sort((a, b) => (a[key] < b[key] ? -1 : 1));
      else data.sort((a, b) => (a[key] > b[key] ? -1 : 1));
    },
    setCleanFilter(state) {
      state.messages.filter = initialState.messages.filter;
      state.newRecord = false;
      window.localStorage.removeItem('broadcastId');
    },
    fetchGetCampaigns(state, _action: PayloadAction<TDateRange>) {
      const { apiChart } = state;
      apiChart.filters.campaigns.loading = ['pending'];
    },
    fetchGetCampaignsSuccess(state, action: PayloadAction<TCampaign[]>) {
      const { apiChart } = state;
      apiChart.filters.campaigns.loading = ['success'];
      const { payload } = action;
      const data = payload
        .map(item => item.campaign.trim())
        .filter(item => item.length > 0)
        .sort((a: string, b: string) =>
          a.toLowerCase() > b.toLowerCase() ? 0 : -1
        );
      apiChart.filters.campaigns.data = [...data];
    },
    fetchGetCampaignsFailure(state, action: PayloadAction<ErrorPayload>) {
      const { apiChart } = state;
      apiChart.filters.campaigns = {
        loading: ['failure'],
        data: [],
      };
      apiChart.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
    fetchGetTemplates(state) {
      const { apiChart } = state;
      apiChart.filters.templates.loading = ['pending'];
    },
    fetchGetTemplatesSuccess(state, action: PayloadAction<WhatsAppTemplate[]>) {
      const { apiChart } = state;
      apiChart.filters.templates.loading = ['success'];
      const { payload } = action;
      const data = payload
        .map(item => item.name)
        .sort((a: string, b: string) =>
          a.toLowerCase() > b.toLowerCase() ? 0 : -1
        );
      apiChart.filters.templates.data = Array.from(new Set(data));
    },
    fetchGetTemplatesFailure(state, action: PayloadAction<ErrorPayload>) {
      const { apiChart } = state;
      apiChart.filters.templates.loading = ['failure'];
      apiChart.error = action.payload as ErrorPayload<'OPERATION_FAILED'>;
    },
  },
});

export default DeliveriresMassiveSlice.reducer;

export const {
  applySearchFilter,
  clearDataApiChart,
  fetchDeliveriesMassiveSuccess,
  fetchDeliveriesMassiveFailure,
  fetchDeliveriesMassiveFulfill,
  fetchGetByIdRequest,
  fetchGetByIdSuccess,
  fetchGetByIdFailure,
  fetchGetByIdFulfill,
  fetchCancelDeliveryRequest,
  fetchCancelDeliverySuccess,
  fetchGetChartByRangeCode,
  fetchGetChartByRangeCodeSuccess,
  fetchGetChartByRangeCodeFailure,
  fetchDownloadReport,
  fetchDownloadReportSuccess,
  fetchDownloadReportFailure,
  setChangeInfo,
  setNewRecord,
  setOpenDialog,
  setOpenDialogDetail,
  setResponseCode,
  setRowSelected,
  setRangeTime,
  setOrderBy,
  setCleanFilter,
  fetchGetApiChart,
  fetchGetApiChartSuccess,
  fetchGetApiChartFailure,
  fetchGetFailedApiChart,
  fetchGetFailedApiChartSuccess,
  fetchGetFailedApiChartFailure,
  fetchGetCampaigns,
  fetchGetCampaignsSuccess,
  fetchGetCampaignsFailure,
  fetchGetTemplates,
  fetchGetTemplatesSuccess,
  fetchGetTemplatesFailure,
  fetchGetApiStatistics,
  fetchGetApiStatisticsSuccess,
  fetchGetApiStatisticsFailure,
} = DeliveriresMassiveSlice.actions;
