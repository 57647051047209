import {
  CHECK_LOGIN_STATUS,
  CHECK_PERMISSIONS,
  enumSetupProcessStates,
  LINK_UP_USER_PAGE_WITH_BOT,
  LOAD_USER_PAGES_AND_BOTS,
  REASK_PERMISSIONS,
  SET_CURRENT_PROVIDER,
  SET_DENIED_SOME_PERMISSIONS,
  SET_GRANTED_ALL_PERMISSIONS,
  SET_LINKED_UP_PAGE_WITH_BOT_FAIL,
  SET_LINKED_UP_PAGE_WITH_BOT_OK,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_READY_TO_LINK_PAGE_WITH_BOT,
} from '../actions/fbaccountsetup';
import { DUPLICATE } from '../utils/validation';

const fbAccountSetupReducer = (state = { currentState: null }, action) => {
  switch (action.type) {
    case CHECK_LOGIN_STATUS:
      return {
        ...state,
        currentState: enumSetupProcessStates.CHECKING_LOGIN_STATUS,
      };

    case CHECK_PERMISSIONS:
      return {
        ...state,
        currentState: enumSetupProcessStates.CHECKING_PERMISSIONS,
      };

    case REASK_PERMISSIONS:
      return {
        ...state,
        currentState: enumSetupProcessStates.REASKING_PERMISSIONS,
      };

    case SET_LOGGED_IN:
      return {
        ...state,
        currentState: enumSetupProcessStates.UNSPECIFIED_PERMISSIONS,
        loginData: action.loginData,
        llAccessToken: action.llAccessToken,
      };

    case SET_LOGGED_OUT:
      return {
        ...state,
        currentState: enumSetupProcessStates.LOGGED_OUT,
        loginData: null,
        mapPermissions: null,
      };

    case SET_DENIED_SOME_PERMISSIONS:
      let newState = enumSetupProcessStates.DENIED_PERMISSIONS_ONCE;

      // If the permissions were denied while re-asking others previously denied, consider them definitely denied
      if (
        state.currentState === enumSetupProcessStates.DENIED_PERMISSIONS_ONCE ||
        state.currentState ===
          enumSetupProcessStates.DEFINITELY_DENIED_PERMISSIONS
      ) {
        newState = enumSetupProcessStates.DEFINITELY_DENIED_PERMISSIONS;
      }

      return {
        ...state,
        currentState: newState,
        mapPermissions: action.mapPermissions,
      };

    case SET_GRANTED_ALL_PERMISSIONS:
      return {
        ...state,
        currentState: enumSetupProcessStates.GRANTED_ALL_PERMISSIONS,
        mapPermissions: action.mapPermissions,
      };

    case LOAD_USER_PAGES_AND_BOTS:
      return {
        ...state,
        currentState: enumSetupProcessStates.LOADING_USER_PAGES_AND_BOTS,
      };

    case SET_READY_TO_LINK_PAGE_WITH_BOT:
      return {
        ...state,
        currentState: enumSetupProcessStates.READY_TO_LINK_PAGE_WITH_BOT,
        lstUserPages: action.lstUserPages,
        lstUserBots: action.lstUserBots,
      };

    case LINK_UP_USER_PAGE_WITH_BOT:
      return {
        ...state,
        currentState: enumSetupProcessStates.LINKING_UP_PAGE_WITH_BOT,
        linkedPageId: null,
        linkedBotId: null,
      };

    case SET_LINKED_UP_PAGE_WITH_BOT_OK:
      return {
        ...state,
        currentState: enumSetupProcessStates.PAGE_SETUP_COMPLETED,
        linkedPageId: action.pageId,
        linkedBotId: action.botId,
      };

    case SET_LINKED_UP_PAGE_WITH_BOT_FAIL:
      const { errDetails } = action;
      let failureState = enumSetupProcessStates.PAGE_SETUP_FAILED;

      // If details about the error that caused the page setup to fail were provided, it might be
      // possible to set a more specific error condition
      if (
        errDetails &&
        Object.prototype.hasOwnProperty.call(errDetails, 'errorCode') &&
        Object.prototype.hasOwnProperty.call(errDetails, 'fieldName')
      ) {
        // An error of type DUPLICATE on the botId field, means that the account is already associated to another bot
        if (
          errDetails.errorCode === DUPLICATE &&
          errDetails.fieldName === 'botId'
        ) {
          failureState = enumSetupProcessStates.PAGE_SETUP_FAILED_BOT_CONFLICT;
        }
      }

      return {
        ...state,
        currentState: failureState,
        linkedPageId: null,
        linkedBotId: null,
      };

    case SET_CURRENT_PROVIDER:
      return {
        ...state,
        currentProvider: action.currentProvider,
      };
    default:
      return state;
  }
};

export default fbAccountSetupReducer;
