import B2ChatClient from '@client-sdk';
import {
  requestSpecialAccount,
  requestSpecialAccountFailure,
  requestSpecialAccountFulfill,
  requestSpecialAccountSuccess,
} from '@src/reducers/requestSpecialAccount';

import { B2ChatAPI } from '@src/types/api';
import { isErrorPayload } from '@types';
import { call, put, takeLeading } from 'redux-saga/effects';

export default function* requestSpecialAccountSaga() {
  yield takeLeading(requestSpecialAccount, function* worker(action) {
    try {
      const response: B2ChatAPI.Response<void> = yield call(
        B2ChatClient.resources.botServices.actions.requestSpecialAccount,
        {
          data: action.payload,
        }
      );

      if (response.error) throw response.error;

      yield put(requestSpecialAccountSuccess());
    } catch (error) {
      if (isErrorPayload(error)) {
        yield put(requestSpecialAccountFailure({ error }));
      }

      if (error instanceof Error) {
        yield put(
          requestSpecialAccountFailure({
            error: { details: error.message, errorCode: error.name },
          })
        );
      }
    } finally {
      yield put(requestSpecialAccountFulfill());
    }
  });
}
