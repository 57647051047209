import { appConfig } from '../config/appConfig';
import { checkHttpFetchStatus } from './backendproxyutils';

const GeneralPropertiesBackend = (function (endpointUrl) {
  const confEndpointUrl = endpointUrl;

  return {
    getAllCountries(okCallback) {
      fetch(`${confEndpointUrl}/services/utility/country`, {
        method: 'GET',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          okCallback(response);
        })
        .catch(error => {
          throw new Error(`Error loading countries: ${String(error)}`);
        });
    },

    getGeneralProperties(okCallback) {
      fetch(`${confEndpointUrl}/services/utility/properties`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          okCallback(response);
        })
        .catch(error => {
          // HTTP 403: Forbidden means that session expired or was invalidated somehow
          if (error && error.response && error.response.status === 403) {
            console.debug('Forbidden, going back to Login...', error);
            window.location.replace('/login');
          } else {
            console.debug('Error getting general properties', error);
          }
        });
    },
    getPagesWithoutAccess(okCallback, failCallback) {
      fetch(`${confEndpointUrl}/services/utility/pagesWithoutAccess`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
        .then(checkHttpFetchStatus)
        .then(httpResponse => httpResponse.json())
        .then(response => {
          okCallback(response);
        })
        .catch(error => {
          // HTTP 403: Forbidden means that session expired or was invalidated somehow
          if (error && error.response && error.response.status === 403) {
            console.error('Forbidden, going back to Login...', error);
            window.location.replace('/login');
          } else {
            console.error('Error getting general properties', error);
          }
        });
    },
  };
})(appConfig.webServicesBaseUri);

export default GeneralPropertiesBackend;
