// eslint-disable-next-line import/no-cycle
import { StateToBoolean } from '@components/AdminHome/Departments/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Department, ErrorData, ErrorPayload } from '@types';

type Loading =
  | 'change-state'
  | 'get-department-settings'
  | 'get-all-department-settings'
  | 'change-merchant-status'
  | 'get-merchant-settings'
  | 'create-department'
  | 'change-merchant-department-state'
  | 'edit-department'
  | 'get-user-settings'
  | 'update-user-settings'
  | 'get-user-applicable-settings';

export type ErrorCode = 'NOT_FOUND' | 'INVALID_VALUE';
export type Status = 'ACTIVE' | 'INACTIVE';

export type MerchantSettings = {
  merchantId?: number;
  status: Status;
  traySizeLimitEnabled: boolean;
  traySizeLimit: number;
  allowTakingChatsManually?: boolean;
};

export type UserApplicableSettings = {
  merchantId: number;
  status: Status;
  traySizeLimitEnabled: boolean;
  traySizeLimit: number;
  allowTakingChatsManually: boolean;
};

export type UserSettings = {
  userId?: number;
  traySizeLimitEnabled: boolean;
  traySizeLimit: number;
};

export type ChatAutoAssignmentResponse = {
  botId: number;
  status: Status;
};

export type ChangeStateError = {
  timestamp: number;
  errorCode: ErrorCode;
  details: string;
  traceId: string;
  fieldName: string;
};

export type ChatAutoAssignmentState = {
  error: ErrorData | ErrorPayload | null;
  loading: Loading[];
  activeAutoAssignment: boolean | null;
  merchantSettings: MerchantSettings;
  departmentSettings: ChatAutoAssignmentResponse[];
  savedDepartment: Department | null;
  userSettings: UserSettings;
  userApplicableSettings: UserApplicableSettings;
};

type ChatAutoAssignmentStateReducer<P = void> = (
  state: ChatAutoAssignmentState,
  action: PayloadAction<P>
) => void;

const initialState: ChatAutoAssignmentState = {
  error: null,
  loading: [],
  activeAutoAssignment: false,
  merchantSettings: {
    status: 'INACTIVE',
    traySizeLimitEnabled: false,
    traySizeLimit: 10,
  },
  departmentSettings: [],
  savedDepartment: null,
  userSettings: {
    traySizeLimitEnabled: false,
    traySizeLimit: -1,
  },
  userApplicableSettings: {
    merchantId: 0,
    status: 'ACTIVE',
    traySizeLimitEnabled: true,
    traySizeLimit: 10,
    allowTakingChatsManually: true,
  },
};

const handleChangeDepartmentStatus: ChatAutoAssignmentStateReducer<
  ChatAutoAssignmentResponse[]
> = state => {
  state.loading.push('change-state');
  state.error = null;
};

const handleChangeDepartmentStatusSuccess: ChatAutoAssignmentStateReducer<
  ChatAutoAssignmentResponse[]
> = (state, action) => {
  state.departmentSettings = action.payload;
};

const handleFailure: ChatAutoAssignmentStateReducer<
  ErrorData | ErrorPayload
> = (state, action) => {
  state.error = action.payload;
};

const handleChangeDepartmentStatusFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'change-state');
  };

const handleChangeStatusAutoAssignNoDepartments: ChatAutoAssignmentStateReducer<
  ChatAutoAssignmentResponse[]
> = state => {
  state.loading.push('change-merchant-department-state');
  state.error = null;
};

const handleChangeStatusAutoAssignNoDepartmentsSuccess: ChatAutoAssignmentStateReducer<
  boolean
> = (state, action) => {
  state.activeAutoAssignment = action.payload;
};

const handleChangeStatusAutoAssignNoDepartmentsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'change-merchant-department-state'
    );
  };

type HandleGetDepartmentSettingsPayload = {
  botId: number;
};

const handleGetDepartmentSettings: ChatAutoAssignmentStateReducer<
  HandleGetDepartmentSettingsPayload
> = state => {
  state.loading.push('get-department-settings');
  state.error = null;
};

const handleGetDepartmentSettingsSuccess: ChatAutoAssignmentStateReducer<
  ChatAutoAssignmentResponse
> = (state, action) => {
  const item = action.payload;
  state.activeAutoAssignment = StateToBoolean[item.status];
};

const handleGetDepartmentSettingsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'get-department-settings');
  };

const handleGetAllDepartmentSettings: ChatAutoAssignmentStateReducer<
  ChatAutoAssignmentResponse[]
> = state => {
  state.loading.push('get-all-department-settings');
  state.error = null;
};

const handleGetAllDepartmentSettingsSuccess: ChatAutoAssignmentStateReducer<
  ChatAutoAssignmentResponse[]
> = (state, action) => {
  state.departmentSettings = action.payload;
};

const handleGetAllDepartmentSettingsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'get-all-department-settings'
    );
  };

const handleupdateMerchantSettings: ChatAutoAssignmentStateReducer<
  MerchantSettings
> = state => {
  state.loading.push('change-merchant-status');
  state.error = null;
};

const handleupdateMerchantSettingsSuccess: ChatAutoAssignmentStateReducer<
  MerchantSettings
> = (state, action) => {
  state.merchantSettings = action.payload;
};

const handleupdateMerchantSettingsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'change-merchant-status');
  };

const handleGetMerchantSettings: ChatAutoAssignmentStateReducer = state => {
  state.loading.push('get-merchant-settings');
  state.error = null;
};

const handleGetMerchantSettingsSuccess: ChatAutoAssignmentStateReducer<
  MerchantSettings
> = (state, action) => {
  state.merchantSettings = action.payload;
};

const handleGetMerchantSettingsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'get-merchant-settings');
  };

export type HandleCreateDepartmentPayload = {
  treeId: number;
  nodeId?: number;
  departmentNode: Department;
  autoChatAssign: {
    status: Status;
  };
};

const handleCreateDepartment: ChatAutoAssignmentStateReducer<
  HandleCreateDepartmentPayload
> = state => {
  state.loading.push('create-department');
  state.error = null;
};

const handleCreateDepartmentSuccess: ChatAutoAssignmentStateReducer<
  Department
> = (state, action) => {
  const index = state.departmentSettings.findIndex(
    item => item.botId === action.payload.bot.id
  );
  const departments = state.departmentSettings;
  state.departmentSettings = [
    ...departments.slice(0, index),
    {
      ...departments[index],
      status: action.payload.autoChatAssign?.status || 'INACTIVE',
    },
    ...departments.slice(index + 1),
  ];
  state.savedDepartment = action.payload;
};

const handleCreateDepartmentFulfill: ChatAutoAssignmentStateReducer = state => {
  state.loading = state.loading.filter(l => l !== 'create-department');
};
interface HandleGetUserSettingsPayload {
  userId: number;
}

const handleGetUserSettings: ChatAutoAssignmentStateReducer<
  HandleGetUserSettingsPayload
> = state => {
  state.loading.push('get-user-settings');
  state.error = null;
};

const handleGetUserSettingsSuccess: ChatAutoAssignmentStateReducer<
  UserSettings
> = (state, action) => {
  state.userSettings = action.payload;
};

const handleGetUserSettingsFulfill: ChatAutoAssignmentStateReducer = state => {
  state.loading = state.loading.filter(l => l !== 'get-user-settings');
};

interface HandleUpdateUserSettingsPayload {
  userId: number;
  traySizeLimitEnabled: boolean;
  traySizeLimit: number;
}

const handleUpdateUserSettings: ChatAutoAssignmentStateReducer<
  HandleUpdateUserSettingsPayload
> = state => {
  state.loading.push('update-user-settings');
  state.error = null;
};

const handleUpdateUserSettingsSuccess: ChatAutoAssignmentStateReducer<
  UserSettings
> = (state, action) => {
  state.userSettings = action.payload;
};

const handleUpdateUserSettingsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(l => l !== 'update-user-settings');
  };

const handleGetUserApplicableSettings: ChatAutoAssignmentStateReducer<
  HandleUpdateUserSettingsPayload
> = state => {
  state.loading.push('get-user-applicable-settings');
  state.error = null;
};

const handleGetUserApplicableSettingsSuccess: ChatAutoAssignmentStateReducer<
  UserApplicableSettings
> = (state, action) => {
  state.userApplicableSettings = action.payload;
};

const handleGetUserApplicableSettingsFulfill: ChatAutoAssignmentStateReducer =
  state => {
    state.loading = state.loading.filter(
      l => l !== 'get-user-applicable-settings'
    );
  };

const chatAutoAssignment = createSlice({
  name: 'chatAutoAssignment',
  initialState,
  reducers: {
    changeDepartmentStatus: handleChangeDepartmentStatus,
    changeDepartmentStatusSuccess: handleChangeDepartmentStatusSuccess,
    changeDepartmentStatusFailure: handleFailure,
    changeDepartmentStatusFulfill: handleChangeDepartmentStatusFulfill,
    changeStatusAutoAssignNoDepartments:
      handleChangeStatusAutoAssignNoDepartments,
    changeStatusAutoAssignNoDepartmentsSuccess:
      handleChangeStatusAutoAssignNoDepartmentsSuccess,
    changeStatusAutoAssignNoDepartmentsFailure: handleFailure,
    changeStatusAutoAssignNoDepartmentsFulfill:
      handleChangeStatusAutoAssignNoDepartmentsFulfill,
    getDepartmentSettings: handleGetDepartmentSettings,
    getDepartmentSettingsSuccess: handleGetDepartmentSettingsSuccess,
    getDepartmentSettingsFailure: handleFailure,
    getDepartmentSettingsFulfill: handleGetDepartmentSettingsFulfill,
    getAllDepartmentSettings: handleGetAllDepartmentSettings,
    getAllDepartmentSettingsSuccess: handleGetAllDepartmentSettingsSuccess,
    getAllDepartmentSettingsFailure: handleFailure,
    getAllDepartmentSettingsFulfill: handleGetAllDepartmentSettingsFulfill,
    updateMerchantSettings: handleupdateMerchantSettings,
    updateMerchantSettingsSuccess: handleupdateMerchantSettingsSuccess,
    updateMerchantSettingsFailure: handleFailure,
    updateMerchantSettingsFulfill: handleupdateMerchantSettingsFulfill,
    getMerchantSettings: handleGetMerchantSettings,
    getMerchantSettingsSuccess: handleGetMerchantSettingsSuccess,
    getMerchantSettingsFailure: handleFailure,
    getMerchantSettingsFulfill: handleGetMerchantSettingsFulfill,
    createDepartment: handleCreateDepartment,
    createDepartmentSuccess: handleCreateDepartmentSuccess,
    createDepartmentFailure: handleFailure,
    createDepartmentFulfill: handleCreateDepartmentFulfill,
    getUserSettings: handleGetUserSettings,
    getUserSettingsSuccess: handleGetUserSettingsSuccess,
    getUserSettingsFailure: handleFailure,
    getUserSettingsFulfill: handleGetUserSettingsFulfill,
    updateUserSettings: handleUpdateUserSettings,
    updateUserSettingsSuccess: handleUpdateUserSettingsSuccess,
    updateUserSettingsFailure: handleFailure,
    updateUserSettingsFulfill: handleUpdateUserSettingsFulfill,
    getUserApplicableSettings: handleGetUserApplicableSettings,
    getUserApplicableSettingsSuccess: handleGetUserApplicableSettingsSuccess,
    getUserApplicableSettingsFailure: handleFailure,
    getUserApplicableSettingsFulfill: handleGetUserApplicableSettingsFulfill,
  },
});

const { actions, reducer } = chatAutoAssignment;

export const {
  changeDepartmentStatus,
  changeDepartmentStatusSuccess,
  changeDepartmentStatusFailure,
  changeDepartmentStatusFulfill,
  changeStatusAutoAssignNoDepartments,
  changeStatusAutoAssignNoDepartmentsSuccess,
  changeStatusAutoAssignNoDepartmentsFailure,
  changeStatusAutoAssignNoDepartmentsFulfill,
  getDepartmentSettings,
  getDepartmentSettingsSuccess,
  getDepartmentSettingsFailure,
  getDepartmentSettingsFulfill,
  getAllDepartmentSettings,
  getAllDepartmentSettingsSuccess,
  getAllDepartmentSettingsFailure,
  getAllDepartmentSettingsFulfill,
  updateMerchantSettings,
  updateMerchantSettingsSuccess,
  updateMerchantSettingsFailure,
  updateMerchantSettingsFulfill,
  getMerchantSettings,
  getMerchantSettingsSuccess,
  getMerchantSettingsFailure,
  getMerchantSettingsFulfill,
  createDepartment,
  createDepartmentSuccess,
  createDepartmentFailure,
  createDepartmentFulfill,
  getUserSettings,
  getUserSettingsSuccess,
  getUserSettingsFailure,
  getUserSettingsFulfill,
  updateUserSettings,
  updateUserSettingsSuccess,
  updateUserSettingsFailure,
  updateUserSettingsFulfill,
  getUserApplicableSettings,
  getUserApplicableSettingsSuccess,
  getUserApplicableSettingsFailure,
  getUserApplicableSettingsFulfill,
} = actions;

export default reducer;
