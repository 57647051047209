export const ExclamationIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z"
      fill="current"
    />
  </svg>
);

export const TagsIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM5.5 7C4.67 7 4 6.33 4 5.5C4 4.67 4.67 4 5.5 4C6.33 4 7 4.67 7 5.5C7 6.33 6.33 7 5.5 7Z"
      fill="current"
    />
  </svg>
);

export const ShopifyIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.5H17C17 3.74 14.76 1.5 12 1.5C9.24 1.5 7 3.74 7 6.5H5C3.9 6.5 3 7.4 3 8.5V20.5C3 21.6 3.9 22.5 5 22.5H19C20.1 22.5 21 21.6 21 20.5V8.5C21 7.4 20.1 6.5 19 6.5ZM12 3.5C13.66 3.5 15 4.84 15 6.5H9C9 4.84 10.34 3.5 12 3.5ZM12 13.5C9.67 13.5 7.71 11.91 7.16 9.75C6.99 9.12 7.48 8.5 8.13 8.5C8.6 8.5 8.98 8.84 9.11 9.3C9.46 10.57 10.62 11.5 12 11.5C13.38 11.5 14.54 10.57 14.89 9.3C15.02 8.84 15.4 8.5 15.87 8.5C16.52 8.5 17 9.12 16.84 9.75C16.29 11.91 14.33 13.5 12 13.5Z"
      fill="current"
    />
  </svg>
);

export const ArrowLeftIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
      fill="current"
    />
  </svg>
);

export const ArrowRightIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z"
      fill="current"
    />
  </svg>
);

export const ChatBotIcon = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 7.5V5.5C19 4.4 18.1 3.5 17 3.5H14C14 1.84 12.66 0.5 11 0.5C9.34 0.5 8 1.84 8 3.5H5C3.9 3.5 3 4.4 3 5.5V7.5C1.34 7.5 0 8.84 0 10.5C0 12.16 1.34 13.5 3 13.5V17.5C3 18.6 3.9 19.5 5 19.5H17C18.1 19.5 19 18.6 19 17.5V13.5C20.66 13.5 22 12.16 22 10.5C22 8.84 20.66 7.5 19 7.5ZM6.5 10C6.5 9.17 7.17 8.5 8 8.5C8.83 8.5 9.5 9.17 9.5 10C9.5 10.83 8.83 11.5 8 11.5C7.17 11.5 6.5 10.83 6.5 10ZM14 15.5H8C7.45 15.5 7 15.05 7 14.5C7 13.95 7.45 13.5 8 13.5H14C14.55 13.5 15 13.95 15 14.5C15 15.05 14.55 15.5 14 15.5ZM14 11.5C13.17 11.5 12.5 10.83 12.5 10C12.5 9.17 13.17 8.5 14 8.5C14.83 8.5 15.5 9.17 15.5 10C15.5 10.83 14.83 11.5 14 11.5Z"
      fill="current"
    />
  </svg>
);

export const WsCatalogueOrdersIcon = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1674_3791)">
      <path
        d="M8.66667 20.7996H8.32028C5.89205 20.7996 3.46382 20.7996 1.03647 20.7996C0.32234 20.7996 0 20.4747 0 19.7553C0 13.5186 0 7.28192 0 1.04524C0 0.325857 0.32323 0 1.03558 0C6.12624 0 11.2169 0 16.3084 0C17.0048 0 17.3333 0.329418 17.3342 1.02743C17.3342 2.30949 17.3342 3.59066 17.3342 4.87272C17.3342 4.97065 17.3342 5.06859 17.3342 5.19947H17.6672C20.1044 5.19947 22.5415 5.19947 24.9787 5.19947C25.6723 5.19947 26.0018 5.53066 26.0018 6.22957C26.0018 12.4752 26.0018 18.7216 26.0018 24.9672C26.0018 25.6706 25.6768 25.9982 24.9796 25.9982C19.88 25.9982 14.7804 25.9982 9.67999 25.9982C9.00147 25.9982 8.66845 25.6635 8.66756 24.9841C8.66756 23.6033 8.66756 22.2224 8.66756 20.7988L8.66667 20.7996ZM10.4191 6.94538V24.2452H24.2521V6.94538H10.4191ZM1.75328 1.74057V19.0537H8.66667V18.7225C8.66667 14.5968 8.6729 10.471 8.65865 6.3462C8.65687 5.85385 8.8216 5.5182 9.21607 5.22707C10.6835 4.14533 12.1332 3.04044 13.589 1.94357C13.6603 1.89015 13.7279 1.8305 13.8366 1.74057H1.75328ZM15.5907 2.60953C14.4599 3.458 13.3718 4.27353 12.2828 5.08818C12.2551 5.10865 12.2169 5.11488 12.1839 5.12824C12.1973 5.14694 12.2106 5.16563 12.2249 5.18433H15.5907V2.60953Z"
        fill="current"
      />
      <path
        d="M6.0084 4.73301C5.20888 4.73301 4.40935 4.73657 3.60983 4.73123C3.29298 4.72856 3.039 4.39825 3.00387 3.96555C2.96996 3.54354 3.16229 3.13756 3.45572 3.02983C3.52722 3.00312 3.60366 3.00134 3.67825 3.00134C5.22737 2.99955 6.77649 2.99955 8.32622 3.00134C8.72444 3.00134 8.99814 3.3539 8.99999 3.86317C9.00184 4.37332 8.72752 4.73301 8.33238 4.7339C7.55752 4.73568 6.78327 4.7339 6.0084 4.7339V4.73301Z"
        fill="current"
      />
      <path
        d="M3.46825 11.6908C3.46825 11.5376 3.46113 11.3836 3.46914 11.2314C3.49318 10.7657 3.87785 10.3998 4.33554 10.4007C4.78343 10.4016 5.17166 10.7559 5.19481 11.2082C5.21173 11.5323 5.21084 11.8582 5.19481 12.1813C5.17344 12.647 4.77631 13.0067 4.31773 12.9986C3.87162 12.9906 3.49496 12.6301 3.46914 12.1778C3.46023 12.0157 3.46736 11.8537 3.46736 11.6908H3.46825Z"
        fill="current"
      />
      <path
        d="M5.19837 16.0471C5.19837 16.2003 5.2055 16.3543 5.19659 16.5065C5.16988 16.9731 4.78432 17.3354 4.32574 17.3319C3.86716 17.3283 3.48605 16.9606 3.46913 16.4923C3.45845 16.186 3.45845 15.8789 3.46913 15.5726C3.48605 15.1052 3.86894 14.7366 4.32663 14.7339C4.78521 14.7312 5.17077 15.0945 5.19659 15.5601C5.2055 15.7222 5.19837 15.8842 5.19837 16.0471Z"
        fill="current"
      />
      <path
        d="M17.292 20H16.7448C16.6028 19.984 16.4609 19.9714 16.3189 19.9519C15.6034 19.8523 14.9394 19.612 14.3315 19.2228C14.2743 19.1862 14.2251 19.1793 14.1598 19.201C13.8267 19.3109 13.4924 19.4174 13.1581 19.5238C12.8868 19.6108 12.6166 19.6967 12.3316 19.7871C12.3442 19.7447 12.3522 19.7184 12.3613 19.6921C12.5434 19.1495 12.7219 18.607 12.9085 18.0667C12.944 17.966 12.9314 17.895 12.8719 17.8091C12.3785 17.096 12.0969 16.3039 12.0213 15.442C11.9274 14.3729 12.1415 13.3656 12.7048 12.451C13.6401 10.9332 15.0093 10.1114 16.7894 10.0083C17.7751 9.9511 18.7024 10.1892 19.5473 10.7031C20.8455 11.4941 21.6411 12.6422 21.9216 14.1394C21.9571 14.3271 21.9743 14.5171 21.9995 14.7071V15.2932C21.9915 15.363 21.9834 15.434 21.9754 15.5038C21.8564 16.5763 21.4477 17.523 20.7264 18.3265C19.956 19.185 18.9989 19.7207 17.861 19.9222C17.6732 19.9554 17.4821 19.9748 17.2932 20H17.292ZM18.6063 17.8183C18.7757 17.7862 18.9176 17.7759 19.047 17.7313C19.7522 17.4875 19.9926 17.1475 20.0121 16.4928C20.0155 16.3932 19.9789 16.3257 19.8884 16.2822C19.5129 16.1036 19.1397 15.9193 18.7631 15.7442C18.5776 15.6583 18.4654 15.695 18.3407 15.8598C18.2411 15.9926 18.1449 16.1276 18.043 16.2581C17.8953 16.447 17.7854 16.4733 17.5588 16.3818C16.7219 16.0406 16.1037 15.4523 15.6423 14.6865C15.5725 14.5698 15.5782 14.5022 15.6687 14.3935C15.766 14.2756 15.8702 14.1634 15.9709 14.0478C16.09 13.9116 16.1232 13.7697 16.043 13.5922C15.8725 13.2168 15.7202 12.8333 15.5542 12.4556C15.5302 12.4018 15.4764 12.3366 15.4237 12.324C15.1615 12.2599 14.8868 12.2461 14.6681 12.4247C14.1816 12.823 14.027 13.3564 14.1232 13.9643C14.1942 14.413 14.4128 14.801 14.6704 15.1696C15.0516 15.7167 15.4809 16.2192 15.9892 16.653C16.5536 17.1349 17.2062 17.4463 17.9102 17.658C18.1449 17.729 18.3887 17.7702 18.6063 17.8194V17.8183Z"
        fill="current"
      />
    </g>
    <defs>
      <clipPath id="clip0_1674_3791">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ExclamationIcon;
