import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WebSocketState = {
  readyState: number;
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    readyState: -1,
  } as WebSocketState,
  reducers: {
    updateWebSocketReadyState(
      state,
      action: PayloadAction<{ readyState: number }>
    ) {
      state.readyState = action.payload.readyState;
    },
  },
});

export default websocketSlice.reducer;

export const { updateWebSocketReadyState } = websocketSlice.actions;

export const selectWebsocketState = (state: { websocket: WebSocketState }) =>
  state.websocket;
