const buildInitialFaqsSetupState = function () {
  return {
    emoticonSelector: {
      popover0: { open: false, anchorEl: null },
      popover1: { open: false, anchorEl: null },
      popover2: { open: false, anchorEl: null },
      popover3: { open: false, anchorEl: null },
    },
  };
};

export default buildInitialFaqsSetupState;
