/**
 * @brief shows a notificatin only if the current tab is hidden
 */
export default function spawnNotification(
  title: string,
  options: NotificationOptions,
  showAlways?: boolean
): Promise<Notification> {
  return new Promise((resolve, reject) => {
    try {
      if (!('Notification' in window)) {
        return;
      }

      if (
        (document.visibilityState === 'hidden' || showAlways) &&
        Notification.permission === 'granted'
      ) {
        const notification = new Notification(title, options);

        notification.addEventListener('click', event => {
          resolve((event.target as Notification)?.data);
          window.focus();
          notification.close();
        });
      }
    } catch (error) {
      reject(error);
    }
  });
}

window.spawnNotification = spawnNotification;

setTimeout(async () => {
  try {
    if (!('Notification' in window)) {
      return;
    }

    await Notification.requestPermission();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}, 5000);
