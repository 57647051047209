import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'get-user-state' | 'update-user-state';

export type UserStateType = {
  user: {
    id: number;
  };
  online: boolean;
};

export type UserState = {
  error: ErrorData | null;
  loading: Loading[];
  userState: UserStateType | null;
};

type UserStateStateReducer<P = void> = (
  state: UserState,
  action: PayloadAction<P>
) => void;

const initialState: UserState = {
  error: null,
  loading: [],
  userState: {
    user: { id: -1 },
    online: false,
  },
};

const handleGetUserState: UserStateStateReducer = state => {
  state.loading.push('get-user-state');
  state.error = null;
};

const handleFailure: UserStateStateReducer<ErrorData> = (state, action) => {
  state.error = action.payload;
};

const handleGetUserStateSuccess: UserStateStateReducer<UserStateType> = (
  state,
  action
) => {
  state.userState = action.payload;
};

const handleGetUserStateFulfill: UserStateStateReducer = state => {
  state.loading = state.loading.filter(l => l !== 'get-user-state');
};

interface HandleUpdateUserStatePayload {
  online: boolean;
}

const handleUpdateUserState: UserStateStateReducer<HandleUpdateUserStatePayload> =
  state => {
    state.loading.push('update-user-state');
    state.error = null;
  };

const handleUpdateUserStateSuccess: UserStateStateReducer<UserStateType> = (
  state,
  action
) => {
  state.userState = action.payload;
};

const handleUpdateUserStateFulfill: UserStateStateReducer = state => {
  state.loading = state.loading.filter(l => l !== 'update-user-state');
};

const userState = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    getUserState: handleGetUserState,
    getUserStateSuccess: handleGetUserStateSuccess,
    getUserStateFailure: handleFailure,
    getUserStateFulfill: handleGetUserStateFulfill,
    updateUserState: handleUpdateUserState,
    updateUserStateSuccess: handleUpdateUserStateSuccess,
    updateUserStateFailure: handleFailure,
    updateUserStateFulfill: handleUpdateUserStateFulfill,
  },
});

const { actions, reducer } = userState;

export const {
  getUserState,
  getUserStateSuccess,
  getUserStateFailure,
  getUserStateFulfill,
  updateUserState,
  updateUserStateSuccess,
  updateUserStateFailure,
  updateUserStateFulfill,
} = actions;

export default reducer;
