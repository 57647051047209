import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { ChatTray } from '@src/types/chats';
// eslint-disable-next-line import/no-cycle
import { ChatsState } from './initialState';

export const chatAssigned = createAction<{
  chatId: string;
  assignedBy: ChatTray['assignedBy'];
  assignedTo: ChatTray['assignedTo'];
}>('chatAssigned');

export const chatAssignBuilder = (
  build: ActionReducerMapBuilder<{
    chatsOnHold: ChatsState['chatsOnHold'];
    activeChat?: ChatsState['activeChat'];
  }>
) => {
  build.addCase(chatAssigned, (state, action) => {
    const { chatId, assignedBy, assignedTo } = action.payload;
    const index = state.chatsOnHold.tray.findIndex(({ id }) => id === chatId);

    if (index !== -1) {
      state.chatsOnHold.tray[index].assignedBy = assignedBy;
      state.chatsOnHold.tray[index].assignedTo = assignedTo;
      if (state.activeChat?.id === chatId)
        state.activeChat = state.chatsOnHold.tray[index];
    }
  });
};
