/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient, { B2ChatClientBroadcast } from '@client-sdk';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  CreateReportResponse,
  WsConversationReport,
  createWsConversationReport,
  createWsConversationReportFailure,
  createWsConversationReportFulfill,
  createWsConversationReportSuccess,
  fetchWsConversationReport,
  fetchWsConversationReportFailure,
  fetchWsConversationReportFulfill,
  fetchWsConversationReportLines,
  fetchWsConversationReportLinesFailure,
  fetchWsConversationReportLinesFulfill,
  fetchWsConversationReportLinesSuccess,
  fetchWsConversationReportSuccess,
} from '@src/reducers/wsConversationReport';
import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';

function* getWsConversationReportSaga(
  action: ReturnType<typeof fetchWsConversationReport>
) {
  if (!action.payload.countryCode) delete action.payload.countryCode;
  if (!action.payload.line) delete action.payload.line;

  try {
    const response: B2ChatAPI.Response<WsConversationReport> = yield call(
      B2ChatClient.resources.conversationReport.actions.wsConversationReport,
      { params: action.payload }
    );

    if (response.error) {
      yield put(fetchWsConversationReportFailure(response.error));
    } else {
      yield put(fetchWsConversationReportSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWsConversationReportFailure(error as ErrorData));
  } finally {
    yield put(fetchWsConversationReportFulfill());
  }
}

function* getWsConversationReportLinesSaga(
  action: ReturnType<typeof fetchWsConversationReportLines>
) {
  if (!action.payload.countryCode) delete action.payload.countryCode;
  if (!action.payload.line) delete action.payload.line;

  try {
    // Note: this request is almost the same of getWsConversationReportSaga, the difference is line='' and country='' are empty,
    //        in order to get all lines for that period of time, because there aren't a dedicated enpoint to get only the lines
    const response: B2ChatAPI.Response<WsConversationReport> = yield call(
      B2ChatClient.resources.conversationReport.actions.wsConversationReport,
      { params: action.payload }
    );

    if (response.error) {
      yield put(fetchWsConversationReportLinesFailure(response.error));
    } else {
      const lines = [...new Set(response.data?.data.map(({ line }) => line))];
      yield put(fetchWsConversationReportLinesSuccess(lines));
    }
  } catch (error) {
    yield put(fetchWsConversationReportLinesFailure(error as ErrorData));
  } finally {
    yield put(fetchWsConversationReportLinesFulfill());
  }
}

function* CreateWsConversationReportSaga(
  action: ReturnType<typeof createWsConversationReport>
) {
  const { broadcastId } = action.payload;
  if (!action.payload.filters.country) delete action.payload.filters.country;
  if (!action.payload.filters.line) delete action.payload.filters.line;
  try {
    const response: B2ChatAPI.Response<CreateReportResponse> = yield call(
      B2ChatClientBroadcast.resources.createConversationReport.actions.report,
      {
        params: { broadcastId },
        data: action.payload,
      }
    );

    if (response.error) {
      yield put(createWsConversationReportFailure(response.error));
    } else {
      yield put(createWsConversationReportSuccess(response.data));
    }
  } catch (error) {
    yield put(createWsConversationReportFailure(error as ErrorData));
  } finally {
    yield put(createWsConversationReportFulfill());
  }
}

export default function* wsConversationReportSaga() {
  yield takeLatest(fetchWsConversationReport, getWsConversationReportSaga);
  yield takeLatest(
    fetchWsConversationReportLines,
    getWsConversationReportLinesSaga
  );
  yield takeLatest(createWsConversationReport, CreateWsConversationReportSaga);
}
