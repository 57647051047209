import BackendProxy from '../logic/backendproxy';

export const PASSWORD_CHANGE_REQUEST_SUCCESS =
  'PASSWORD_CHANGE_REQUEST_SUCCESS';
export const PASSWORD_CHANGE_REQUEST_ERROR = 'PASSWORD_CHANGE_REQUEST_ERROR';
export const USERNAME_EMAIL_CHANGED = 'USERNAME_EMAIL_CHANGED';
export const VALIDATED_TOKEN = 'VALIDATED_TOKEN';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const USERNAME_CHANGED = 'USERNAME_CHANGED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const MATCH_PASSWORD_CHANGED = 'MATCH_PASSWORD_CHANGED';
export const PASSWORD_CHANGED_SUCCESS = 'PASSWORD_CHANGED_SUCCESS';
export const PASSWORD_CHANGED_ERROR = 'PASSWORD_CHANGED_ERROR';
export const INVALID_PASSWORD_LENGTH = 'INVALID_PASSWORD_LENGTH';
export const UNMATCHED_PASSWORDS = 'UNMATCHED_PASSWORDS';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const INVALID_USERNAME = 'INVALID_USERNAME';
export const USERNAME_REQUIRED = 'USERNAME_REQUIRED';

export function passwordChangeRequestSuccess() {
  return {
    type: PASSWORD_CHANGE_REQUEST_SUCCESS,
  };
}

export function passwordChangeRequestError() {
  return {
    type: PASSWORD_CHANGE_REQUEST_ERROR,
  };
}

export function onUsernameOrEmailChange(usernameOrEmail) {
  return {
    type: USERNAME_EMAIL_CHANGED,
    usernameOrEmail,
  };
}

export function onPasswordChangeRequest() {
  return (dispatch, getState) => {
    const { usernameOrEmail } = getState().forgotPassword.passwordChangeReqForm;
    BackendProxy.requestPasswordChange(
      usernameOrEmail,
      response => {
        dispatch(passwordChangeRequestSuccess());
      },
      error => {
        dispatch(passwordChangeRequestError());
      }
    );
  };
}

export function onValidateTokenError() {
  return {
    type: INVALID_TOKEN,
  };
}

export function onValidateTokenSuccess(token) {
  return {
    type: VALIDATED_TOKEN,
    resetToken: token,
  };
}

export function validateToken(token) {
  return (dispatch, getState) => {
    BackendProxy.validateToken(
      token,
      response => {
        dispatch(onValidateTokenSuccess(token));
      },
      error => {
        dispatch(onValidateTokenError());
      }
    );
  };
}

export function onUsernameChange(username) {
  return {
    type: USERNAME_CHANGED,
    username,
  };
}

export function onPasswordChange(password) {
  return {
    type: PASSWORD_CHANGED,
    password,
  };
}

export function onMatchPasswordChange(matchPassword) {
  return {
    type: MATCH_PASSWORD_CHANGED,
    matchPassword,
  };
}

export function passwordChangedSuccess() {
  return {
    type: PASSWORD_CHANGED_SUCCESS,
  };
}

export function passwordChangeError(errorCode) {
  return {
    type: errorCode,
  };
}

export function changePassword() {
  return (dispatch, getState) => {
    const { username } = getState().forgotPassword.changePasswordForm;
    const { password } = getState().forgotPassword.changePasswordForm;
    const { matchPassword } = getState().forgotPassword.changePasswordForm;
    const { resetToken } = getState().forgotPassword.changePasswordForm;

    BackendProxy.changePassword(
      username,
      password,
      matchPassword,
      resetToken,
      response => {
        console.log('response', response);
        if (response.status === 'ERROR') {
          dispatch(passwordChangeError(response.error.code));
        } else {
          dispatch(passwordChangedSuccess());
        }
      },
      response => {
        console.log('Error response', response);
        dispatch(passwordChangeError(PASSWORD_CHANGED_ERROR));
      }
    );
  };
}
