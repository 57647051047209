import { combineReducers } from 'redux';
import { RESET_BOT_SETUP_STATE, SET_USER_BOTS } from '../actions/botsetup';
import buildInitialBotSetupState from '../store/botsetupstate';
import fbAccountSetupReducer from './fbaccountsetup';
import lcAccountSetupReducer from './livechataccountsetup';

const lstUserBotsReducer = (state = null, action) => {
  switch (action.type) {
    case SET_USER_BOTS:
      return action.lstUserBots;
    default:
      return state;
  }
};

const botSetupReducer = combineReducers({
  lstUserBots: lstUserBotsReducer,
  fbAccountSetup: fbAccountSetupReducer,
  lcAccountSetup: lcAccountSetupReducer,
});

const rootBotSetupReducer = (state = {}, action) => {
  if (action.type === RESET_BOT_SETUP_STATE) {
    return buildInitialBotSetupState();
  }

  return botSetupReducer(state, action);
};

export default rootBotSetupReducer;
