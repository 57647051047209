export const SpecialMessageParser = (() => {
  const MSG_NOT_DELIVERED_REGEX =
    /^\[ERROR:NOT_DELIVERED_?(\w*)\]|\[CONTACT_DISCONNECTED_COULDNT_DELIVER_MSG_?(\w*)\]/;

  return {
    // Checks if the provided text message is a message-not-delivered notification. If so, returns an object with two attributes:
    // 1. type: The type of message that could not be delivered as an uppercase string, or UNSPECIFIED if the type of message was not
    // provided in the notification (e.g. IMAGE, TEXT).
    // 2. message: The text of the message embedded in the notification.
    // Otherwise, returns null
    isMsgNotDeliveredNotification: (msgText?: string) => {
      if (msgText === undefined || msgText === null) {
        return null;
      }

      const arrMatchParams = MSG_NOT_DELIVERED_REGEX.exec(msgText);

      if (arrMatchParams != null) {
        const msgType =
          arrMatchParams.length > 0 && arrMatchParams[1] !== ''
            ? arrMatchParams[1]
            : 'UNSPECIFIED';
        const fmtdMsgTxt = msgText.replace(MSG_NOT_DELIVERED_REGEX, '').trim();

        return {
          type: msgType,
          message: fmtdMsgTxt,
        };
      }

      return null;
    },
  };
})();

export default SpecialMessageParser;
