import {
  WSCatalogueProductDetail,
  WSCatalogueShoppingCartDetail,
} from '@src/components/WSCatalogue/types';
import {
  FetchWsCatalogueProductTypes,
  WsCatalogueConfig,
} from '@src/reducers/wsCatalogue';
import { Selector } from '@types';

export const getWsCatalogueConfig: Selector<
  Partial<WsCatalogueConfig>
> = state => state.adminHome.wsCatalogue.config;

export const getWsCatalogueProductDetail: Selector<
  WSCatalogueProductDetail | null
> = state => {
  const product = state.adminHome.wsCatalogue.productDetail;
  if (!product) return null;
  return {
    ...product,
    images: [product.image_url, ...product.images],
  };
};

export const getWsCatalogueShoppingCartDetail: Selector<
  WSCatalogueShoppingCartDetail | null
> = state => {
  const { shoppingCart } = state.adminHome.wsCatalogue;
  if (!shoppingCart) return null;

  return {
    ...shoppingCart,
    products: Object.keys(shoppingCart.productQuantity).map(productId => {
      const [product] = state.adminHome.wsCatalogue.products.filter(
        item => item.id === productId
      );
      if (!product) throw new Error(`No product found: id=${productId}`);
      return {
        ...product,
        images: [product.image_url, ...product.images],
        totalItems: shoppingCart.productQuantity[productId],
      };
    }),
  };
};

export const getWsCatalogueError: Selector<boolean> = state =>
  !!state.adminHome.wsCatalogue.error;

export const getShowWsCatalogueSideMenu: Selector<boolean> = state =>
  state.adminHome.wsCatalogue.showWsCatalogueSideMenu;

export const getWsCatalogueCurrentView: Selector<
  FetchWsCatalogueProductTypes | null
> = state => state.adminHome.wsCatalogue.currentView;

export const getCanGoBack: Selector<boolean, [productId: string]> = (
  state,
  productId
) =>
  state.adminHome.wsCatalogue.products.findIndex(
    item => item.id === productId
  ) !== -1;

export default getWsCatalogueConfig;
