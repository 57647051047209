import {
  enumSetupProcessStates,
  START_CREATING_ACCOUNT,
  SET_CONFIGURE_ACCOUNT,
  START_APPLYING_ACCOUNT_CONFIGURATION,
  SET_CONFIGURE_ACCOUNT_OK,
  START_LOADING_ACCOUNT_TO_EDIT,
  SET_CONFIGURE_ACCOUNT_FAIL,
  START_CHECKING_DEFAULT_BOT,
} from '../actions/livechataccountsetup';

const lcAccountSetupReducer = (state = {}, action) => {
  switch (action.type) {
    case START_CHECKING_DEFAULT_BOT:
      return {
        ...state,
        currentState: enumSetupProcessStates.CHECKING_DEFAULT_BOT,
      };

    case START_CREATING_ACCOUNT:
      return {
        ...state,
        currentState: enumSetupProcessStates.CREATING_ACCOUNT,
        linkedBotId: action.linkedBotId,
        msgAccount: null,
        livechatConfig: null,
        widgetScript: null,
      };

    case START_LOADING_ACCOUNT_TO_EDIT:
      return {
        ...state,
        currentState: enumSetupProcessStates.LOADING_ACCOUNT,
        msgAccount: null,
        livechatConfig: null,
        widgetScript: null,
      };

    case SET_CONFIGURE_ACCOUNT:
      return {
        ...state,
        currentState: enumSetupProcessStates.CONFIGURE_ACCOUNT,
        msgAccount: action.msgAccount,
        livechatConfig: action.livechatConfig,
        widgetScript: action.widgetScript,
      };

    case START_APPLYING_ACCOUNT_CONFIGURATION:
      return {
        ...state,
        currentState: enumSetupProcessStates.APPLYING_ACCOUNT_CONFIGURATION,
        errDetails: null,
      };

    case SET_CONFIGURE_ACCOUNT_OK:
      return {
        ...state,
        currentState: enumSetupProcessStates.ACCOUNT_READY,
        msgAccount: null,
        livechatConfig: null,
        widgetScript: null,
      };

    case SET_CONFIGURE_ACCOUNT_FAIL:
      return {
        ...state,
        currentState: enumSetupProcessStates.ACCOUNT_CONFIGURATION_FAILED,
        errDetails: action.errDetails,
      };

    default:
      return state;
  }
};

export default lcAccountSetupReducer;
