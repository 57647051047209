// eslint-disable-next-line import/no-extraneous-dependencies
import { eventChannel } from '@redux-saga/core';
import safeJSONParse from '@utils/safeJSONParse';

export type StorageChannelEvent<T = unknown> = Readonly<{
  key: string;
  newValue?: T;
  oldValue?: T;
}>;

export function createStorageChannelEvent<T = unknown>(keyFilter: string) {
  return eventChannel<StorageChannelEvent<T>>(emit => {
    const storageHandler = (ev: StorageEvent) => {
      if (ev.key === keyFilter) {
        emit({
          key: ev.key,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          oldValue: safeJSONParse(ev.oldValue!),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          newValue: safeJSONParse(ev.newValue!),
        });
      }
    };

    window.addEventListener('storage', storageHandler);

    return () => {
      window.removeEventListener('storage', storageHandler);
    };
  });
}

export function emitStorageEvent<T>(key: `notification:${string}`, value?: T) {
  localStorage.removeItem(key);
  localStorage.setItem(key, value ? JSON.stringify(value) : '');
}
