import { raiseNewError } from '../actions/common';

const ErrorHandler = (function () {
  // Redux store of the application. Must be set by invoking the initialize method
  let targetStore = null;

  return {
    // Initializes the Error Handler, by linking it to the Redux store
    initialize(store) {
      targetStore = store;
    },

    // Handles the occurrence of a error
    handleError(errorMsg) {
      targetStore.dispatch(raiseNewError(errorMsg));
    },
  };
})();

export default ErrorHandler;
