import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { b2chatBaseQuery } from '@src/client';
import intl from '@src/locales/intl';
import { format, parse } from 'date-fns';
import enUS from 'date-fns/locale/en-US';

export type GetTagsReportRequest = {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  contactCreated: boolean;
  tags: (number | undefined)[];
  agentId: number | string | undefined;
  granularity: 'h' | 'm' | 'd';
  contactsWithoutTags: boolean;
};

type GetTagsReportResponse = {
  summaryTagCounter: {
    amountContactWithTags: number;
    amountContactWithoutTags: number;
    percentageWithTags: number;
    percentageWithoutTags: number;
  };
  summaryTagChart?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
  summaryTagPie?: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
    }[];
  };
};

export const tagsReportApi = createApi({
  reducerPath: 'tagsReport',
  baseQuery: b2chatBaseQuery,
  endpoints: ({ query }) => ({
    getTagsReport: query<GetTagsReportResponse, GetTagsReportRequest>({
      query: request => {
        const params = {
          ...(request.startDate && {
            initDate: format(request.startDate, 'yyyy-MM-dd HH:mm:ss'),
          }),
          ...(request.endDate && {
            finalDate: format(request.endDate, 'yyyy-MM-dd HH:mm:ss'),
          }),
          contactCreated: request.contactCreated.toString(),
          agentId: request.agentId?.toString() ?? '',
          ...(request.tags.length > 0 && { tags: request.tags.join(',') }),
          granularity: request.granularity,
          contactWithoutTags: request.contactsWithoutTags.toString(),
        };

        return {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resource: async (options): Promise<any> => {
            const resource = request.contactCreated
              ? ([
                  B2ChatClient.resources.newTagsAdmin.actions.aggregate,
                  B2ChatClient.resources.newTagsAdmin.actions.pie,
                ] as const)
              : ([
                  undefined,
                  B2ChatClient.resources.newTagsAdmin.actions.timeSeries,
                ] as const);
            const [r1, r2] = await Promise.all([
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              resource[0]?.<any>(options),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              resource[1]<any>(options),
            ]);
            if (r1?.error) return r1;
            if (r2.error) return r2;

            const amountContactWithoutTags =
              r1?.data?.amountContactWithoutTags ?? 0;

            const data = {
              summaryTagCounter: r1
                ? r1.data
                : { amountContactWithTags: r2.data.total },
              ...(request.contactCreated
                ? {
                    summaryTagPie: (r2.data as Record<string, number>[]).reduce(
                      (pie, item) => {
                        const [label, value] = Object.entries(item)[0];
                        pie.labels.push(label);
                        pie.datasets[0].data.push(value);
                        return pie;
                      },
                      {
                        labels:
                          amountContactWithoutTags > 0
                            ? [
                                intl.formatMessage({
                                  id: 'ContactsWithoutTags',
                                }),
                              ]
                            : [],
                        datasets: [
                          {
                            label: '# number of tags',
                            data:
                              amountContactWithoutTags > 0
                                ? [amountContactWithoutTags]
                                : [],
                          },
                        ],
                      }
                    ),
                  }
                : {
                    summaryTagChart: {
                      ...r2.data,
                      labels:
                        request.granularity === 'm'
                          ? r2.data.labels.map(localizeMonth)
                          : r2.data.labels,
                    },
                  }),
            };

            return { data };
          },
          options: { params },
        };
      },
    }),
  }),
});

export const { useGetTagsReportQuery, useLazyGetTagsReportQuery } =
  tagsReportApi;

const refDate = new Date();
const monthFormat = 'MMMM y';

function localizeMonth(dateStr: string) {
  try {
    return format(
      parse(dateStr, monthFormat, refDate, { locale: enUS }),
      monthFormat
    );
  } catch {
    return dateStr;
  }
}
