import singletonSession from '@src/sagas/singletonSession';
import { all } from 'redux-saga/effects';
import adsSaga from './ads';
import auditUsersSaga from './auditUsers';
import automaticMessages from './automaticMessages';
import botAccounts from './botAccounts';
import chatAutoAssignment from './chatAutoAssignment';
import chatHistory from './chatHistory';
import chatMessageQueueSaga from './chatMessageQueue';
import chats from './chats';
import chatsBehavior from './chatsBehavior';
import chatSearch from './chatSearch';
import chatTransfer from './chatTransfer';
import chatViewerSaga from './chatViewer';
import consoleWidgets from './consoleWidgets';
import contact from './contact';
import contactsImport from './contactsImport';
import countriesSaga from './countries';
import deliveriesMassiveSaga from './deliveriesMassive';
import departmentsSaga from './departments';
import integrationsSaga from './integrations';
import merchantPlanUsage from './merchantPlanUsage';
import partnersCustomUiConfigSaga from './partnersCustomUi';
import quickMessageSaga from './quickMessage';
import requestSpecialAccount from './requestSpecialAccount';
import shopifySaga from './shopifySaga';
import standardMessages from './standardMessage';
import tags from './tags';
import uploadFiles from './uploadFiles';
import urlPreview from './urlPreview';
import usersSaga from './users';
import user from './userState';
import userWaAccounts from './userWaAccounts';
import websocketSaga from './websocket';
import whatsAppProfile from './whatsAppProfile';
import whatsAppTemplates from './whatsAppTemplates';
import wsCatalogue from './wsCatalogue';
import wsConversationReportSaga from './wsConversationReport';
import wsFlowEditorSaga from './wsFlowEditor';
import wsFlows from './wsFlows';

function* rootSaga() {
  yield all([
    userWaAccounts(),
    contactsImport(),
    departmentsSaga(),
    standardMessages(),
    uploadFiles(),
    chatSearch(),
    chatAutoAssignment(),
    urlPreview(),
    user(),
    usersSaga(),
    quickMessageSaga(),
    singletonSession(),
    chatMessageQueueSaga(),
    automaticMessages(),
    chatTransfer(),
    botAccounts(),
    merchantPlanUsage(),
    whatsAppProfile(),
    whatsAppTemplates(),
    requestSpecialAccount(),
    deliveriesMassiveSaga(),
    chatHistory(),
    chats(),
    shopifySaga(),
    adsSaga(),
    consoleWidgets(),
    contact(),
    countriesSaga(),
    wsConversationReportSaga(),
    integrationsSaga(),
    wsCatalogue(),
    chatsBehavior(),
    auditUsersSaga(),
    tags(),
    chatViewerSaga(),
    wsFlows(),
    websocketSaga(),
    partnersCustomUiConfigSaga(),
    wsFlowEditorSaga(),
  ]);
}

export default rootSaga;
