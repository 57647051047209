import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { activateSession, deactivateSession } from '@reducers/singletonSession';
import { getSessionState } from '@selectors/singletonSession';
import { isLoggedInSelect } from '@src/selectors/user';
import { useDispatch, useSelector } from 'react-redux';

const SingletonSession: React.FC = () => {
  const state = useSelector(getSessionState);

  const isLoggedIn = useSelector(isLoggedInSelect);

  const dispatch = useDispatch();

  const cancelHandler = () => {
    dispatch(deactivateSession());
  };

  const acceptHandler = () => {
    dispatch(activateSession());
  };

  if (!isLoggedIn) return null;

  return (
    <Dialog
      open={state === 'suspended'}
      closeAfterTransition
      disableEscapeKeyDown
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
    >
      <DialogContent>
        <DialogContentText>
          B2Chat está abierto en otra ventana.
          <br />
          Haz clic en “USAR AQUÍ” para abrir B2Chat en esta ventana.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelHandler} color="primary">
          Cerrar
        </Button>
        <Button onClick={acceptHandler} color="primary" autoFocus>
          Usar aquí
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingletonSession;
