import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MenuNav = {
  visibilitySubOptionsNavDrawer: boolean;
};

type MenuNavReducer<P = void> = (
  state: MenuNav,
  action: PayloadAction<P>
) => void;

const initialState: MenuNav = {
  visibilitySubOptionsNavDrawer: true,
};

const handleSetVisibilitySubOptionsNavDrawer: MenuNavReducer<boolean> = (
  state,
  action
) => {
  state.visibilitySubOptionsNavDrawer = action.payload;
};

const menuNavSlice = createSlice({
  name: 'MenuNavSlice',
  initialState,
  reducers: {
    setVisibilitySubOptionsNavDrawer: handleSetVisibilitySubOptionsNavDrawer,
  },
});

const { actions, reducer } = menuNavSlice;

export const { setVisibilitySubOptionsNavDrawer } = actions;

export default reducer;
