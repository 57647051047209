const buildInitialAuditState = function () {
  return {
    toolbar: {
      agents: [],
      contacts: [],
      agentLoadStatus: null,
      contactLoadStatus: null,
      errorAgentMessage: null,
      errorContactMessage: null,
      filters: {
        agentId: '',
        contactValue: '',
        contactId: '',
        startDate: null,
        endDate: null,
        text: '',
        activeChat: false,
        timeframe: 'this_1_days',
        timeframe_start_date: null,
        timeframe_end_date: null,
        custom_timeframe: false,
        disabled: false,
        displayRangeOfDatesValidation: false,
      },
    },
    chat: {
      chats: [],
      filterCount: 0,
      pageCount: 0,
      currentPage: 0,
      pageSize: 0,
      loadStatus: null,
      errorMessage: null,
    },
    message: {
      messages: [],
      loadStatus: null,
      errorMessage: null,
      chatIdSelected: null,
    },
  };
};

export default buildInitialAuditState;
