export const SATISFACTION_BOT_LIST_REQUEST = 'SATISFACTION_BOT_LIST_REQUEST';
export const SATISFACTION_BOT_LIST_FAIL = 'SATISFACTION_BOT_LIST_FAIL';

const satisfactionBotList = (state = {}, action) => {
  switch (action.type) {
    case SATISFACTION_BOT_LIST_REQUEST:
      return { ...state, success: action.data, status: true };
    case SATISFACTION_BOT_LIST_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default satisfactionBotList;
