import { ErrorData } from '@b2chat/chat-center-sdk';
// eslint-disable-next-line import/no-cycle
import { ErrorPayload, QuotedMessage } from '@src/types';
import {
  ChatActionType,
  ChatActionsStatus,
  ChatSideType,
  ChatTray,
  ChatsOnHold,
  ChatsRequest,
  NotificationStatus,
  NotificationType,
  ResponseStatus,
} from '@src/types/chats';

export type ChatStateAction = {
  type: ChatActionType;
  status: ChatActionsStatus;
  details?: ChatActionErrorDetail;
};

export type ChatActionErrorDetail = {
  traceId?: string;
  code: string;
  message?: string;
  timestamp?: string;
};

export type TransformTextAction = {
  apiName?: string;
  command: string;
  language?: string;
};

type BusinessConditions = {
  notificationRecipients: string[];
  bpnLimits: {
    bpnId: number;
    maxAmountOfChats: number;
  }[];
};

export type NotificationTimeLimit = {
  id?: string;
  type: NotificationType;
  status: NotificationStatus;
  noResponseTimeLimit: number;
  businessConditions?: BusinessConditions;
  loading: ResponseStatus;
};

export type Segments = {
  code: string;
  name: string;
  order: number;
};

type Loading = 'idle' | 'pending' | 'success' | 'failure' | 'fulfill';

export type ChatsState = {
  chatsSearch: string;
  activeAgentId?: number;
  activeChat?: ChatTray;
  activeChatSide: ChatSideType.ON_HOLD | ChatSideType.REQUEST;
  chatsOnHold: {
    loading: 'idle' | 'pending';
    offset: number;
    limit: number;
    error?: ErrorData | ErrorPayload;
  } & ChatsOnHold;
  chatsRequest: {
    loading: 'idle' | 'pending';
    offset: number;
    limit: number;
    error?: ErrorData | ErrorPayload;
  } & ChatsRequest;
  quotedMessages: Record<string, QuotedMessage>;
  writtenMessages: Record<string, string>;
  actionChat?: ChatStateAction;
  transformTextAI: {
    loading: Loading;
    closeErrorMsg: boolean;
    error?: ErrorData | ErrorPayload;
    outputText?: string;
    requestParams?: {
      apiName: string;
      command: string;
      language: string;
    };
    disabledStop: boolean;
  };
  isActiveWindow: boolean;
  crmButton: {
    loading: Loading;
    sendInfo: Loading;
    show: boolean;
  };
  timeLimit: NotificationTimeLimit;
  segments: {
    data: Segments[];
    loading: Loading;
  };
};

export const initialTimeLimit: NotificationTimeLimit = {
  type: NotificationType.NO_RESPONSE_TIME_LIMIT,
  status: NotificationStatus.INACTIVE,
  noResponseTimeLimit: 10,
  loading: ResponseStatus.IDLE,
};

// eslint-disable-next-line no-shadow
export enum ActionMode {
  CREATE = 'create',
  GET = 'get',
  UPDATE = 'update',
}

export type PayloadNotification = {
  mode: ActionMode;
  notificationId?: string;
  limit?: number;
  status?: NotificationStatus;
  type?: NotificationType.NO_RESPONSE_TIME_LIMIT;
};

const initialStateChats: ChatsState = {
  chatsSearch: '',
  activeChatSide: ChatSideType.ON_HOLD,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  chatsOnHold: {
    tray: [],
    offset: 0,
    limit: 20,
    totalChatsOnHold: 0,
    loading: 'idle',
    error: undefined,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  chatsRequest: {
    tray: [],
    offset: 0,
    limit: 20,
    totalChatRequests: 0,
    loading: 'idle',
    error: undefined,
  },

  quotedMessages: {},
  writtenMessages: {},
  transformTextAI: {
    loading: 'idle',
    closeErrorMsg: true,
    disabledStop: false,
  },
  isActiveWindow: false,
  crmButton: {
    loading: 'idle',
    show: false,
    sendInfo: 'idle',
  },
  timeLimit: initialTimeLimit,
  segments: {
    data: [],
    loading: 'idle',
  },
};

export default initialStateChats;
