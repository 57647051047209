/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  HandleCreateChatsBehaviorConfig,
  HandlefetchChatsBehaviorConfigList,
  MessageTypes,
  createChatsBehaviorConfig,
  createChatsBehaviorConfigFailure,
  createChatsBehaviorConfigFulfill,
  createChatsBehaviorConfigSuccess,
  deleteChatsBehaviorConfig,
  deleteChatsBehaviorConfigFailure,
  deleteChatsBehaviorConfigFulfill,
  deleteChatsBehaviorConfigSuccess,
  fetchChatsBehaviorConfigList,
  fetchChatsBehaviorConfigListFailure,
  fetchChatsBehaviorConfigListFulfill,
  fetchChatsBehaviorConfigListSuccess,
  fetchChatsBehaviorMessageTypes,
  fetchChatsBehaviorMessageTypesFailure,
  fetchChatsBehaviorMessageTypesFulfill,
  fetchChatsBehaviorMessageTypesSuccess,
  updateChatsBehaviorConfig,
  updateChatsBehaviorConfigFailure,
  updateChatsBehaviorConfigFulfill,
  updateChatsBehaviorConfigSuccess,
} from '@src/reducers/chatsBehavior';

import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';

function* fetchChatsBehaviorMessageTypesSaga() {
  try {
    const response: B2ChatAPI.Response<MessageTypes> = yield call(
      B2ChatClient.resources.chatsBehaviorAdmin.actions.getMessageTypes,
      {}
    );

    if (response.error) {
      yield put(fetchChatsBehaviorMessageTypesFailure(response.error));
    } else {
      yield put(fetchChatsBehaviorMessageTypesSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchChatsBehaviorMessageTypesFailure(error as ErrorData));
  } finally {
    yield put(fetchChatsBehaviorMessageTypesFulfill());
  }
}

function* createChatsBehaviorConfigSaga(
  action: ReturnType<typeof createChatsBehaviorConfig>
) {
  try {
    const response: B2ChatAPI.Response<HandleCreateChatsBehaviorConfig> =
      yield call(
        B2ChatClient.resources.chatsBehaviorAdmin.actions.createConfig,
        { data: action.payload }
      );

    if (response.error) {
      yield put(createChatsBehaviorConfigFailure(response.error));
    } else {
      yield put(createChatsBehaviorConfigSuccess());
      yield put(fetchChatsBehaviorConfigList());
    }
  } catch (error) {
    yield put(createChatsBehaviorConfigFailure(error as ErrorData));
  } finally {
    yield put(createChatsBehaviorConfigFulfill());
  }
}

function* fetchChatsBehaviorConfigListSaga() {
  try {
    const response: B2ChatAPI.Response<HandlefetchChatsBehaviorConfigList[]> =
      yield call(
        B2ChatClient.resources.chatsBehaviorAdmin.actions.getConfigList,
        {}
      );

    if (response.error) {
      yield put(fetchChatsBehaviorConfigListFailure(response.error));
    } else {
      yield put(fetchChatsBehaviorConfigListSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchChatsBehaviorConfigListFailure(error as ErrorData));
  } finally {
    yield put(fetchChatsBehaviorConfigListFulfill());
  }
}

function* deleteChatsBehaviorConfigSaga(
  action: ReturnType<typeof deleteChatsBehaviorConfig>
) {
  try {
    const response: B2ChatAPI.Response<unknown> = yield call(
      B2ChatClient.resources.chatsBehaviorAdmin.actions.deleteConfig,
      { params: action.payload }
    );

    if (response.error) {
      yield put(deleteChatsBehaviorConfigFailure(response.error));
    } else {
      yield put(deleteChatsBehaviorConfigSuccess());
      yield put(fetchChatsBehaviorConfigList());
    }
  } catch (error) {
    yield put(deleteChatsBehaviorConfigFailure(error as ErrorData));
  } finally {
    yield put(deleteChatsBehaviorConfigFulfill());
  }
}

function* updateChatsBehaviorConfigSaga(
  action: ReturnType<typeof updateChatsBehaviorConfig>
) {
  const { configurationId, ...rest } = action.payload;
  if (!configurationId) throw new Error('"configurationId" param is required!');
  try {
    const response: B2ChatAPI.Response<unknown> = yield call(
      B2ChatClient.resources.chatsBehaviorAdmin.actions.updateConfig,
      { data: rest, params: { configurationId } }
    );

    if (response.error) {
      yield put(updateChatsBehaviorConfigFailure(response.error));
    } else {
      yield put(updateChatsBehaviorConfigSuccess());
      yield put(fetchChatsBehaviorConfigList());
    }
  } catch (error) {
    yield put(updateChatsBehaviorConfigFailure(error as ErrorData));
  } finally {
    yield put(updateChatsBehaviorConfigFulfill());
  }
}

export default function* chatsBehaviorConfigSaga() {
  yield takeLatest(
    fetchChatsBehaviorMessageTypes,
    fetchChatsBehaviorMessageTypesSaga
  );
  yield takeLatest(createChatsBehaviorConfig, createChatsBehaviorConfigSaga);
  yield takeLatest(
    fetchChatsBehaviorConfigList,
    fetchChatsBehaviorConfigListSaga
  );
  yield takeLatest(deleteChatsBehaviorConfig, deleteChatsBehaviorConfigSaga);
  yield takeLatest(updateChatsBehaviorConfig, updateChatsBehaviorConfigSaga);
}
