/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AuditAgents } from '@src/components/AgentSelect/types';
import {
  fetchAuditUsers,
  fetchAuditUsersFailure,
  fetchAuditUsersFulfill,
  fetchAuditUsersSuccess,
} from '@src/reducers/auditUsers';

import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';

function* fetchAuditUsersSaga() {
  try {
    const response: B2ChatAPI.Response<AuditAgents> = yield call(
      B2ChatClient.resources.audit.actions.getUsers,
      {}
    );

    if (response.error) {
      yield put(fetchAuditUsersFailure(response.error));
    } else {
      const { userLoginStates } = response.data;
      yield put(fetchAuditUsersSuccess(userLoginStates));
    }
  } catch (error) {
    yield put(fetchAuditUsersFailure(error as ErrorData));
  } finally {
    yield put(fetchAuditUsersFulfill());
  }
}

export default function* auditUsersSaga() {
  yield takeLatest(fetchAuditUsers, fetchAuditUsersSaga);
}
