export function orderStringList<T>(list: T[], key: keyof T) {
  return list.sort((a, b) => {
    const string = String(a[key]).toUpperCase();
    const nextString = String(b[key]).toUpperCase();
    if (string < nextString) {
      return -1;
    }
    if (string > nextString) {
      return 1;
    }
    return 0;
  });
}

export default orderStringList;
