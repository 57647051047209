import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData, ErrorPayload } from '@types';

export type Ad = {
  id: string;
  name: string;
  merchantId: number;
};

export type AdsState = {
  loading: 'idle' | 'pending';
  items: Ad[];
  error?: ErrorData | ErrorPayload;
};

const initialState: AdsState = {
  loading: 'idle',
  items: [],
  error: undefined,
};

export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    fetchAds(state) {
      state.loading = 'pending';
      state.error = undefined;
      state.items = [];
    },
    fetchAdsSuccess(state, action: PayloadAction<Ad[]>) {
      state.items = action.payload;
    },
    fetchAdsFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchAdsFulfill(state) {
      state.loading = 'idle';
    },
  },
});

export default adsSlice.reducer;

export const { fetchAds, fetchAdsFailure, fetchAdsSuccess, fetchAdsFulfill } =
  adsSlice.actions;
