import Compressor from 'compressorjs';
import { MediaType, MessagingProvider } from '../model/frontendmodel';

export const imageExtensionsAllowed = {
  [MessagingProvider.INSTAGRAM]: ['jpeg', 'jpg', 'gif', 'png', 'ico', 'bmp'],
} as const;

export const stickerImageExtensionsAllowed = ['image/webp'];

export const MAX_ALLOWED_HEIGHT_STICKER = 512;
export const MAX_ALLOWED_WIDTH_STICKER = 512;
export const MAX_ALLOWED_SIZE_STICKER = 102400;

export const commonImageExtensionsAllowed = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const commonVideoExtensionsAllowed = ['video/mp4'];

const byteSize = 1048576;

// Image size limits for sending from Console
// Default: 25 for WhatsApp and Facebook
const ImageSizeLimitsB2Chat = {
  DEFAULT: 25,
  [MessagingProvider.LIVECHAT]: 5,
  [MessagingProvider.TELEGRAM]: 20,
  [MessagingProvider.INSTAGRAM]: 8,
};

export const isStickerImageTypeAllowed = (stickerType: string) =>
  stickerImageExtensionsAllowed.includes(stickerType);

export const isImageTypeAllowed = (imgType: string) =>
  commonImageExtensionsAllowed.includes(imgType);

export const isVideoTypeAllowed = (videoType: string) =>
  commonVideoExtensionsAllowed.includes(videoType);

export const getImageSizeLimitsB2Chat = (providerName: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const imgSizeLimit = ImageSizeLimitsB2Chat[providerName];
  if (imgSizeLimit) {
    return imgSizeLimit;
  }
  return ImageSizeLimitsB2Chat.DEFAULT;
};

export const isImageSizeAllowed = (providerName: string, size: number) =>
  getImageSizeLimitsB2Chat(providerName) * byteSize > size;

const MediaSizeLimitsMb = {
  [MediaType.IMAGE]: {
    DEFAULT: 5,
    [MessagingProvider.WHATSAPP]: 1,
    [MessagingProvider.TELEGRAM]: 10,
    [MessagingProvider.FACEBOOK]: 25,
    [MessagingProvider.INSTAGRAM]: 8,
  },
  [MediaType.VIDEO]: {
    DEFAULT: 50,
    [MessagingProvider.WHATSAPP]: 50,
    [MessagingProvider.TELEGRAM]: 20,
    [MessagingProvider.FACEBOOK]: 25,
  },
  [MediaType.AUDIO]: {
    DEFAULT: 50,
    [MessagingProvider.WHATSAPP]: 50,
    [MessagingProvider.WHATSAPPOFF]: 50,
    [MessagingProvider.TELEGRAM]: 20,
    [MessagingProvider.FACEBOOK]: 25,
    [MessagingProvider.WHATSAPP360]: 50,
  },
  [MediaType.FILE]: {
    DEFAULT: 20,
    [MessagingProvider.WHATSAPP]: 2,
    [MessagingProvider.TELEGRAM]: 20,
    [MessagingProvider.FACEBOOK]: 25,
  },
} as const;

function mimeTypeToMediaType(mime: string) {
  if (mime.startsWith('audio')) return MediaType.AUDIO;

  if (mime.startsWith('image/webp')) return MediaType.STICKER;

  if (mime.startsWith('image')) return MediaType.IMAGE;

  if (mime.startsWith('video')) return MediaType.VIDEO;

  return MediaType.FILE;
}

function getSupportedMediaTypes(msgProvider: MessagingProvider) {
  switch (msgProvider) {
    case MessagingProvider.LIVECHAT:
    case MessagingProvider.BANDWIDTH:
      return [MediaType.IMAGE];
    case MessagingProvider.INSTAGRAM:
      return [MediaType.IMAGE];

    case MessagingProvider.WHATSAPPB2CHAT:
      return [
        MediaType.IMAGE,
        MediaType.VIDEO,
        MediaType.AUDIO,
        MediaType.FILE,
        MediaType.STICKER,
      ];
    default:
      return [
        MediaType.IMAGE,
        MediaType.VIDEO,
        MediaType.AUDIO,
        MediaType.FILE,
      ];
  }
}

function isMediaTypeSupported(
  mediaType: MediaType,
  msgProvider: MessagingProvider
) {
  const supportedMedia = getSupportedMediaTypes(msgProvider);

  return (
    supportedMedia &&
    supportedMedia.indexOf &&
    supportedMedia.indexOf(mediaType) >= 0
  );
}

function getMaxMediaSizeMb(
  mediaType: MediaType,
  msgProvider: MessagingProvider
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mediaLimits = MediaSizeLimitsMb[mediaType];

  if (mediaLimits) {
    const thisProviderLimit = mediaLimits[msgProvider];

    if (thisProviderLimit) {
      return thisProviderLimit as number;
    }
    return mediaLimits.DEFAULT as number;
  }

  return 10;
}

function checkMaxMediaSize(
  mediaType: MediaType,
  msgProvider: MessagingProvider,
  size: number
) {
  const maxMediaMb = getMaxMediaSizeMb(mediaType, msgProvider);

  if (maxMediaMb) return maxMediaMb * byteSize >= size;

  return false;
}

function checkMimeImageSupport(mime: string, msgProvider: MessagingProvider) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const allowedSubtype = imageExtensionsAllowed[msgProvider] || [];
  // If there isn't any restriction for extensions for that provider
  if (allowedSubtype.length === 0) return true;
  // In other case check if that extension exists in the allowed extensions array
  const subtype = mime.split('/')[1] || '';

  return allowedSubtype.includes(subtype);
}

const FILE_ACCEPTED_PDF = '.pdf,application/pdf';
const FILE_ACCEPTED_ALL =
  'aac, 3g2, 3gp, 7z, aac, amr, avi, csv, doc, docx, eml, ics, jfif,  key, log, m4a, m4v, mov, mp3, mp4, mp4a, mpeg, mpg, mpga, neon, numbers, odt, oga,  ogg, ogv, pages, pdf, pps, ppsx, ppt, pptx, qt, svg, tif, tiff, txt, vcf, wav,  webm, webp, wmv, xls, xlsx, xml, yml, yaml';
export default {
  checkMimeImageSupport,
  checkMaxMediaSize,
  getMaxMediaSizeMb,
  getSupportedMediaTypes,
  isMediaTypeSupported,
  imageExtensionsAllowed,
  mimeTypeToMediaType,
  MediaType,
  FILE_ACCEPTED_ALL,
  FILE_ACCEPTED_PDF,
};

export const compressImage = (file: File): Promise<File> =>
  // eslint-disable-next-line consistent-return
  new Promise((resolve, reject) => {
    if (file.type !== MediaType.IMAGE) return resolve(file);
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,
      success: result => resolve(result as File),
      error: err => reject(err),
    });
  });

export const getTimeDuration = (timeDuration: number) => {
  const mins = timeDuration ? Math.trunc(timeDuration / 60) : 0;
  const seconds = timeDuration
    ? '0'.concat(String(Math.trunc(timeDuration % 60))).slice(-2)
    : '00';

  return `${mins}:${seconds}`;
};

export const resizeDimentions = ({
  maxWidth,
  width,
  maxHeight,
  height,
}: {
  maxWidth: number;
  width: number;
  maxHeight: number;
  height: number;
}) => {
  // Change the resizing logic
  if (width > height) {
    if (width > maxWidth) {
      return {
        width: maxWidth,
        height: Math.trunc(height * (maxWidth / width)),
      };
    }
  } else if (height > maxHeight) {
    return {
      width: Math.trunc(width * (maxHeight / height)),
      height: maxHeight,
    };
  }
  return { width, height };
};
