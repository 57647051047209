import {
  ResponsesByFlowToken,
  WhasAppFlowItem,
  WhatsAppFlowResponsesFilters,
  WhatsAppFlowsFilters,
} from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppFlows/types';
import { RootState, Selector } from '@types';

export const getWsFlowsItems = (state: RootState) => {
  const { limit, offset } = state.adminHome.wsFlows.filters;
  const { items } = state.adminHome.wsFlows;
  return items.slice(Number(offset), Number(offset) + Number(limit));
};

export const getWsFlowsItemsLoading: Selector<boolean> = state =>
  state.adminHome.wsFlows.loading.includes('fetch-ws-flows');

export const getWsFlowsTotalItems: Selector<number> = state =>
  state.adminHome.wsFlows.items.length;

export const getWsFlowsFilters: Selector<WhatsAppFlowsFilters> = state =>
  state.adminHome.wsFlows.filters;

export const getWsFlowsFiltersQS: Selector<{
  [key: string]: string;
}> = state => {
  const { filters } = state.adminHome.wsFlows;
  return { ...filters, status: filters.status?.join(',') || '' };
};

export const getWsFlowResponsesFilters: Selector<
  WhatsAppFlowResponsesFilters
> = state => state.adminHome.wsFlows.flowResponsesFilters;

export type WsFlowResponsesData = {
  labels: string[];
  data: { [key: string]: unknown }[];
};

export const getWsFlowResponses: Selector<
  WsFlowResponsesData | null
> = state => {
  const { responses } = state.adminHome.wsFlows;
  if (!responses) return null;

  const { labels: lbs, data } = responses;
  const labels = [
    'contact_phone_number',
    'template_name',
    'campaign_name',
    ...lbs,
  ];
  const values = data.map(item => ({
    contact_phone_number: item.contact_phone_number,
    template_name: item.template_name,
    campaign_name: item.campaign_name,
    ...item.response.reduce((res, { label, value }) => {
      res[label] = value;
      return res;
    }, {} as { [key: string]: unknown }),
  }));
  return { labels, data: values };
};

export const getWsFlowResponsesLoading: Selector<boolean> = state =>
  state.adminHome.wsFlows.loading.includes('fetch-ws-flows-response');

export const getWsFlowSelectedId: Selector<string> = state =>
  state.adminHome.wsFlows.selectedFlowId;

export const getWsFlowScreens: Selector<string[]> = state =>
  state.adminHome.wsFlows.screens;

export const getWsFlowResponsesByFlowToken: Selector<
  ResponsesByFlowToken | null
> = state => state.adminHome.wsFlows.responsesByFlowToken;

export const getCommerceWsFlows: Selector<WhasAppFlowItem[]> = state =>
  state.adminHome.wsFlows.commerceItems || [];

export default getWsFlowsItems;
