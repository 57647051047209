import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const RedirectRoute: React.FC = () => (
  <Route
    exact
    path="/redirect"
    render={({ location }) => {
      const path = new URLSearchParams(location.search).get('path') || '/';

      return <Redirect to={path} />;
    }}
  />
);

export default RedirectRoute;
