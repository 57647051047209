/* eslint-disable @typescript-eslint/ban-ts-comment */
import B2ChatClient from '@client-sdk';
import { getMerchantId } from '@selectors/merchant';
import { DataResponse, DepartmentHeadings, ErrorData, ModeEnum } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  fetchDepartmentHeadings,
  fetchDepartmentHeadingsFailure,
  fetchDepartmentHeadingsSuccess,
  fetchDepartmentVisibility,
  fetchDepartmentVisibilityFailure,
  fetchDepartmentVisibilityFulfill,
  fetchDepartmentVisibilitySuccess,
  fetchDepartments,
  fetchDepartmentsFailure,
  fetchDepartmentsFulfill,
  fetchDepartmentsSuccess,
  setDepartmentModuleActive,
} from '../reducers/departments';

function* fetchDepartmentsSaga() {
  try {
    const merchantId: number = yield select(getMerchantId);

    const response: B2ChatAPI.Response<{
      configurableBotWithStdServices: B2ChatAPI.Merchant.DepartmentList;
      isDepartmentModuleActive: boolean;
    }> = yield call([B2ChatClient.resources.merchant.actions, 'departments'], {
      params: { merchantId },
    });

    if (response.error) {
      yield put(fetchDepartmentsFailure(response.error));
    } else {
      yield put(
        fetchDepartmentsSuccess(response.data.configurableBotWithStdServices)
      );
      yield put(
        setDepartmentModuleActive(response.data.isDepartmentModuleActive)
      );
    }
  } catch (error) {
    yield put(fetchDepartmentsFailure(error as ErrorData));
    yield put(setDepartmentModuleActive(false));
  } finally {
    yield put(fetchDepartmentsFulfill());
  }
}

function* fetchDepartmentVisibilitySaga(
  action: ReturnType<typeof fetchDepartmentVisibility>
) {
  const { departmentId, visible } = action.payload;
  try {
    const response: B2ChatAPI.Response<DataResponse> = yield call(
      B2ChatClient.resources.departments.actions.visibility,
      {
        params: { departmentId, hide: `${visible}` },
      }
    );

    if (response.error === null) {
      yield put(fetchDepartmentVisibilitySuccess({ departmentId }));
    } else {
      throw new Error(
        `Error updating the visibility of a department. Status: ${response.error.status}`
      );
    }
  } catch (error) {
    yield put(
      fetchDepartmentVisibilityFailure({
        details: (error as Error)?.message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Deparment visibility',
      })
    );
  } finally {
    fetchDepartmentVisibilityFulfill();
  }
}

function* fetchDepartmentHeadingsSaga(
  action: ReturnType<typeof fetchDepartmentHeadings>
) {
  const { mode, bizProcessTreeId, botAccountId } = action.payload;
  let params;
  const { data } = action.payload;

  if (mode === ModeEnum.DELETE)
    params = { bot_account: botAccountId, biz_process_tree: bizProcessTreeId };
  if (mode === ModeEnum.LIST) params = { biz_process_tree: bizProcessTreeId };

  try {
    const response: B2ChatAPI.Response<DepartmentHeadings[]> = yield call(
      [B2ChatClient.resources.departmentHeadings.actions, mode],
      {
        params,
        data,
      }
    );

    if (response.error === null) {
      let items = response.data;
      if (mode === ModeEnum.CREATE) {
        items = data ? [data] : [];
      }

      yield put(
        fetchDepartmentHeadingsSuccess({
          items,
          mode,
          botAccountId,
        })
      );
    } else {
      throw new Error(
        `Error in department headings request. Status: ${response.error.status}`
      );
    }
  } catch (error) {
    yield put(
      fetchDepartmentHeadingsFailure({
        details: (error as Error)?.message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Deparment headings',
      })
    );
  }
}

export default function* departmentsSaga() {
  yield takeLatest('LOGGED_IN_USER_RELOADED', fetchDepartmentsSaga);
  yield takeLeading(fetchDepartments, fetchDepartmentsSaga);
  yield takeLatest(fetchDepartmentVisibility, fetchDepartmentVisibilitySaga);
  yield takeLatest(fetchDepartmentHeadings, fetchDepartmentHeadingsSaga);
}
