import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
  handleAddShopifyStoreCredentials,
  handleAddShopifyStoreCredentialsFailure,
  handleAddShopifyStoreCredentialsFulfill,
  handleAddShopifyStoreCredentialsSuccess,
  handleAddShoppingCart,
  handleAddShoppingCartFailure,
  handleAddShoppingCartFulfill,
  handleAddShoppingCartSuccess,
  handleApplyFilters,
  handleBuildFilterParamsState,
  handleCleanFilters,
  handleCleanProductDetailError,
  handleCleanShopifyProductDetail,
  handleCreateShopifyOrder,
  handleDeleteShopifyStoreCredentials,
  handleDeleteShopifyStoreCredentialsFailure,
  handleDeleteShopifyStoreCredentialsFulfill,
  handleDeleteShopifyStoreCredentialsSuccess,
  handleFetchOrderHistoryById,
  handleFetchOrderHistoryByIdFailure,
  handleFetchOrderHistoryByIdFulfill,
  handleFetchOrderHistoryByIdSuccess,
  handleFetchOrdersHistory,
  handleFetchOrdersHistoryFailure,
  handleFetchOrdersHistoryFulfill,
  handleFetchOrdersHistorySuccess,
  handleFetchShopifyCollections,
  handleFetchShopifyCollectionsFailure,
  handleFetchShopifyCollectionsFulfill,
  handleFetchShopifyCollectionsSuccess,
  handleFetchShopifyCredentials,
  handleFetchShopifyCredentialsFailure,
  handleFetchShopifyCredentialsFulfill,
  handleFetchShopifyCredentialsSuccess,
  handleFetchShopifyInventory,
  handleFetchShopifyInventoryFailure,
  handleFetchShopifyInventoryFulfill,
  handleFetchShopifyInventorySuccess,
  handleFetchShopifyShop,
  handleFetchShopifyShopFailure,
  handleFetchShopifyShopFulfill,
  handleFetchShopifyShopSuccess,
  handleFetchShopifyShoppingCart,
  handleFetchShopifyShoppingCartFailure,
  handleFetchShopifyShoppingCartFulfill,
  handleFetchShopifyShoppingCartSuccess,
  handleFetchShopifySingleProduct,
  handleFetchShopifySingleProductFailure,
  handleFetchShopifySingleProductFulfill,
  handleFetchShopifySingleProductSuccess,
  handleFetchShopifyStores,
  handleFetchShopifyStoresFailure,
  handleFetchShopifyStoresFulfill,
  handleFetchShopifyStoresSuccess,
  handleOnClickSendShopifyProduct,
  handleRemoveFilter,
  handleRemoveProductFromShoppingCart,
  handleRemoveProductFromShoppingCartFailure,
  handleRemoveProductFromShoppingCartFulfill,
  handleResetShopifyError,
  handleSaveShopifyStoreCredentials,
  handleSaveShopifyStoreCredentialsFailure,
  handleSaveShopifyStoreCredentialsFulfill,
  handleSaveShopifyStoreCredentialsSuccess,
  handleSelectedLocationId,
  handleSelectedStoreId,
  handleSendShopifyCatalog,
  handleSendShopifyCatalogFailure,
  handleSendShopifyCatalogFulfill,
  handleSendShopifyCatalogSuccess,
  handleSendShopifyProduct,
  handleSetProductId,
  handleSetShopifyCredentials,
  handleSetShopifyCredentialsFailure,
  handleSetShopifyCredentialsFulfill,
  handleSetShopifyCredentialsSuccess,
  handleSetShoppingTabView,
  handleSetVariantId,
  handleShopifyProductDetails,
  handleUpdateCarouselImage,
  handleUpdateCheckboxFilter,
  handleUpdateCredentialStatus,
  handleUpdateOrderByParam,
  handleUpdateOrderInputValue,
  handleUpdatePriceRange,
  handleUpdateProductToShoppingCart,
  handleUpdateProductToShoppingCartFailure,
  handleUpdateProductToShoppingCartFulfill,
  handleUpdateSearchPattern,
  handleUpdateShopifyCredentials,
  handleUpdateShopifyCredentialsFailure,
  handleUpdateShopifyCredentialsFulfill,
  handleUpdateShopifyCredentialsSuccess,
  handleUpdateTryToRetrieveOnlyAvailableProducts,
  handleUpdatingCredentials,
} from './shopify';
import { handleInitializeFilterParamsState, initialState } from './utils';

const shopifySlice = createSlice({
  name: 'Shopify',
  initialState,
  reducers: {
    fetchShopifyCredentials: handleFetchShopifyCredentials,
    fetchShopifyCredentialsSuccess: handleFetchShopifyCredentialsSuccess,
    fetchShopifyCredentialsFailure: handleFetchShopifyCredentialsFailure,
    fetchShopifyCredentialsFulfill: handleFetchShopifyCredentialsFulfill,
    fetchShopifyStores: handleFetchShopifyStores,
    fetchShopifyStoresSuccess: handleFetchShopifyStoresSuccess,
    fetchShopifyStoresFailure: handleFetchShopifyStoresFailure,
    fetchShopifyStoresFulfill: handleFetchShopifyStoresFulfill,
    saveShopifyStoreCredentials: handleSaveShopifyStoreCredentials,
    saveShopifyStoreCredentialsSuccess:
      handleSaveShopifyStoreCredentialsSuccess,
    saveShopifyStoreCredentialsFailure:
      handleSaveShopifyStoreCredentialsFailure,
    saveShopifyStoreCredentialsFulfill:
      handleSaveShopifyStoreCredentialsFulfill,
    addShopifyStoreCredentials: handleAddShopifyStoreCredentials,
    addShopifyStoreCredentialsSuccess: handleAddShopifyStoreCredentialsSuccess,
    addShopifyStoreCredentialsFailure: handleAddShopifyStoreCredentialsFailure,
    addShopifyStoreCredentialsFulfill: handleAddShopifyStoreCredentialsFulfill,
    deleteShopifyStoreCredentials: handleDeleteShopifyStoreCredentials,
    deleteShopifyStoreCredentialsSuccess:
      handleDeleteShopifyStoreCredentialsSuccess,
    deleteShopifyStoreCredentialsFailure:
      handleDeleteShopifyStoreCredentialsFailure,
    deleteShopifyStoreCredentialsFulfill:
      handleDeleteShopifyStoreCredentialsFulfill,
    setShopifyCredentials: handleSetShopifyCredentials,
    setShopifyCredentialsSuccess: handleSetShopifyCredentialsSuccess,
    setShopifyCredentialsFailure: handleSetShopifyCredentialsFailure,
    setShopifyCredentialsFulfill: handleSetShopifyCredentialsFulfill,
    updateShopifyStoresCredentials: handleUpdateShopifyCredentials,
    updateShopifyCredentialsSuccess: handleUpdateShopifyCredentialsSuccess,
    updateShopifyCredentialsFailure: handleUpdateShopifyCredentialsFailure,
    updateShopifyCredentialsFulfill: handleUpdateShopifyCredentialsFulfill,
    updatingCredentials: handleUpdatingCredentials,
    updateCredentialStatus: handleUpdateCredentialStatus,
    fetchShopifySingleProduct: handleFetchShopifySingleProduct,
    fetchShopifySingleProductSuccess: handleFetchShopifySingleProductSuccess,
    fetchShopifySingleProductFailure: handleFetchShopifySingleProductFailure,
    fetchShopifySingleProductFulfill: handleFetchShopifySingleProductFulfill,
    setShopifyProductId: handleSetProductId,
    setShopifyVariantId: handleSetVariantId,
    sendShopifyProduct: handleSendShopifyProduct,
    fetchShopifyCollections: handleFetchShopifyCollections,
    fetchShopifyCollectionsSuccess: handleFetchShopifyCollectionsSuccess,
    fetchShopifyCollectionsFailure: handleFetchShopifyCollectionsFailure,
    fetchShopifyCollectionsFulfill: handleFetchShopifyCollectionsFulfill,
    fetchShopifyInventory: handleFetchShopifyInventory,
    fetchShopifyInventorySuccess: handleFetchShopifyInventorySuccess,
    fetchShopifyInventoryFailure: handleFetchShopifyInventoryFailure,
    fetchShopifyInventoryFulfill: handleFetchShopifyInventoryFulfill,
    fetchShopifyShop: handleFetchShopifyShop,
    fetchShopifyShopSuccess: handleFetchShopifyShopSuccess,
    fetchShopifyShopFailure: handleFetchShopifyShopFailure,
    fetchShopifyShopFulfill: handleFetchShopifyShopFulfill,
    sendShopifyCatalog: handleSendShopifyCatalog,
    sendShopifyCatalogSuccess: handleSendShopifyCatalogSuccess,
    sendShopifyCatalogFailure: handleSendShopifyCatalogFailure,
    sendShopifyCatalogFulfill: handleSendShopifyCatalogFulfill,
    updateCheckboxFilter: handleUpdateCheckboxFilter,
    updateSelectedLocationId: handleSelectedLocationId,
    updateSelectedStoreId: handleSelectedStoreId,
    updateSearchPattern: handleUpdateSearchPattern,
    buildFilterParamsState: handleBuildFilterParamsState,
    updateOrderByParam: handleUpdateOrderByParam,
    updatePriceRange: handleUpdatePriceRange,
    cleanShopifyProductDetail: handleCleanShopifyProductDetail,
    cleanProductDetailError: handleCleanProductDetailError,
    cleanFilters: handleCleanFilters,
    initializeFilterParamsState: handleInitializeFilterParamsState,
    removeFilter: handleRemoveFilter,
    applyFilters: handleApplyFilters,
    updateCarouselImage: handleUpdateCarouselImage,
    setShoppingTabView: handleSetShoppingTabView,
    fetchShopifyShoppingCart: handleFetchShopifyShoppingCart,
    fetchShopifyShoppingCartSuccess: handleFetchShopifyShoppingCartSuccess,
    fetchShopifyShoppingCartFailure: handleFetchShopifyShoppingCartFailure,
    fetchShopifyShoppingCartFulfill: handleFetchShopifyShoppingCartFulfill,
    addShoppingCart: handleAddShoppingCart,
    addShoppingCartSuccess: handleAddShoppingCartSuccess,
    addShoppingCartFailure: handleAddShoppingCartFailure,
    addShoppingCartFulfill: handleAddShoppingCartFulfill,
    updateProductShoppingCart: handleUpdateProductToShoppingCart,
    updateProductShoppingCartFailure: handleUpdateProductToShoppingCartFailure,
    updateProductShoppingCartFulfill: handleUpdateProductToShoppingCartFulfill,
    removeProductFromShoppingCart: handleRemoveProductFromShoppingCart,
    removeProductFromShoppingCartFailure:
      handleRemoveProductFromShoppingCartFailure,
    removeProductFromShoppingCartFulfill:
      handleRemoveProductFromShoppingCartFulfill,
    createShopifyOrder: handleCreateShopifyOrder,
    fetchOrdersHistory: handleFetchOrdersHistory,
    fetchOrdersHistorySuccess: handleFetchOrdersHistorySuccess,
    fetchOrdersHistoryFailure: handleFetchOrdersHistoryFailure,
    fetchOrdersHistoryFulfill: handleFetchOrdersHistoryFulfill,
    fetchOrderHistoryById: handleFetchOrderHistoryById,
    fetchOrderHistoryByIdSuccess: handleFetchOrderHistoryByIdSuccess,
    fetchOrderHistoryByIdFailure: handleFetchOrderHistoryByIdFailure,
    fetchOrderHistoryByIdFulfill: handleFetchOrderHistoryByIdFulfill,
    updateOrderInputValue: handleUpdateOrderInputValue,
    shopifyProductDetails: handleShopifyProductDetails,
    onClickSendShopifyProduct: handleOnClickSendShopifyProduct,
    updateTryToRetrieveOnlyAvailableProducts:
      handleUpdateTryToRetrieveOnlyAvailableProducts,
    resetShopifyError: handleResetShopifyError,
  },
});

const { actions, reducer } = shopifySlice;

export const {
  fetchShopifyCredentials,
  fetchShopifyCredentialsSuccess,
  fetchShopifyCredentialsFailure,
  fetchShopifyCredentialsFulfill,
  fetchShopifyStores,
  fetchShopifyStoresSuccess,
  fetchShopifyStoresFailure,
  fetchShopifyStoresFulfill,
  saveShopifyStoreCredentials,
  saveShopifyStoreCredentialsSuccess,
  saveShopifyStoreCredentialsFailure,
  saveShopifyStoreCredentialsFulfill,
  addShopifyStoreCredentials,
  addShopifyStoreCredentialsSuccess,
  addShopifyStoreCredentialsFailure,
  addShopifyStoreCredentialsFulfill,
  deleteShopifyStoreCredentials,
  deleteShopifyStoreCredentialsSuccess,
  deleteShopifyStoreCredentialsFailure,
  deleteShopifyStoreCredentialsFulfill,
  setShopifyCredentials,
  setShopifyCredentialsSuccess,
  setShopifyCredentialsFailure,
  setShopifyCredentialsFulfill,
  updateShopifyStoresCredentials,
  updateShopifyCredentialsSuccess,
  updateShopifyCredentialsFailure,
  updateShopifyCredentialsFulfill,
  updatingCredentials,
  updateCredentialStatus,
  setShopifyProductId,
  setShopifyVariantId,
  sendShopifyProduct,
  fetchShopifyCollections,
  fetchShopifyCollectionsSuccess,
  fetchShopifyCollectionsFailure,
  fetchShopifyCollectionsFulfill,
  fetchShopifyInventory,
  fetchShopifyInventorySuccess,
  fetchShopifyInventoryFailure,
  fetchShopifyInventoryFulfill,
  fetchShopifyShop,
  fetchShopifyShopSuccess,
  fetchShopifyShopFailure,
  fetchShopifyShopFulfill,
  sendShopifyCatalog,
  sendShopifyCatalogSuccess,
  sendShopifyCatalogFailure,
  sendShopifyCatalogFulfill,
  updateCheckboxFilter,
  updateSelectedLocationId,
  updateSelectedStoreId,
  updateSearchPattern,
  buildFilterParamsState,
  updateOrderByParam,
  updatePriceRange,
  fetchShopifySingleProduct,
  fetchShopifySingleProductSuccess,
  fetchShopifySingleProductFailure,
  fetchShopifySingleProductFulfill,
  cleanShopifyProductDetail,
  cleanProductDetailError,
  cleanFilters,
  initializeFilterParamsState,
  removeFilter,
  applyFilters,
  updateCarouselImage,
  fetchShopifyShoppingCart,
  fetchShopifyShoppingCartSuccess,
  fetchShopifyShoppingCartFailure,
  fetchShopifyShoppingCartFulfill,
  updateProductShoppingCart,
  updateProductShoppingCartFailure,
  updateProductShoppingCartFulfill,
  addShoppingCart,
  addShoppingCartSuccess,
  addShoppingCartFailure,
  addShoppingCartFulfill,
  removeProductFromShoppingCart,
  removeProductFromShoppingCartFailure,
  removeProductFromShoppingCartFulfill,
  createShopifyOrder,
  fetchOrdersHistory,
  fetchOrdersHistorySuccess,
  fetchOrdersHistoryFailure,
  fetchOrdersHistoryFulfill,
  fetchOrderHistoryById,
  fetchOrderHistoryByIdSuccess,
  fetchOrderHistoryByIdFailure,
  fetchOrderHistoryByIdFulfill,
  setShoppingTabView,
  updateOrderInputValue,
  shopifyProductDetails,
  onClickSendShopifyProduct,
  updateTryToRetrieveOnlyAvailableProducts,
  resetShopifyError,
} = actions;

export default reducer;
