/* eslint-disable no-shadow */
import { Middleware, Reducer } from 'redux';
import { assigmentManagerApi } from './assignmentManager';
import { chatServicesApi } from './chatServices';
import { tagsReportApi } from './tagsReport';
import { tagsReportExportApi } from './tagsReportExport';

const apis = reduxApis([
  tagsReportApi,
  tagsReportExportApi,
  chatServicesApi,
  assigmentManagerApi,
]);

export default apis;

/**
 * this helper make a record with all middlewares and reducers for apis created with redux-toolkit createApi
 * @param apis List of apis
 * @returns
 */
function reduxApis<
  Api extends { middleware: Middleware; reducerPath: string; reducer: Reducer }
>(apis: Api[]) {
  type ApiRecord = {
    middleware: Middleware[];
    reducer: {
      [K in Api['reducerPath']]: Api['reducer'];
    };
  };

  return apis.reduce(
    (record, api) => {
      record.middleware.push(api.middleware);
      record.reducer[api.reducerPath as Api['reducerPath']] = api.reducer;
      return record;
    },
    { middleware: [], reducer: {} } as unknown as ApiRecord
  );
}
