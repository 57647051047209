import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { ErrorPayload, RootState } from '@src/types';

export type Integration = {
  id: number;
  type: 'B2CHAT_JS_SDK';
  merchantId: number;
  config: {
    name: string;
    url: string;
    iconUrl: string | File;
  };
  active: boolean;
};

export type IntegrationState = {
  loading: ('loading' | 'fetching' | 'updating')[];
  data: Integration[];
  error?: ErrorPayload;
};

const initialState: IntegrationState = {
  loading: [],
  data: [],
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    fetchIntegrations(state) {
      state.loading = ['loading', 'fetching'];
    },
    fetchIntegrationsSuccess(state, action: PayloadAction<Integration[]>) {
      state.data = action.payload;
    },
    fetchIntegrationsFailure(state, action: PayloadAction<ErrorPayload>) {
      state.data = [];
      state.error = action.payload;
    },
    fetchIntegrationsFullfill(state) {
      state.loading = [];
    },
    updateIntegration(state, action: PayloadAction<Partial<Integration>>) {
      state.loading = ['loading', 'updating'];

      if (action.payload.id) {
        const item = state.data.find(({ id }) => id === action.payload.id);
        if (item) {
          item.active = action.payload.active ?? item.active;
          item.config = action.payload.config ?? item.config;
        } else {
          state.data.push(action.payload as Integration);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateIntegrationSuccess() {},
    updateIntegrationFailure(state, action: PayloadAction<ErrorPayload>) {
      state.error = action.payload;
    },
    updateIntegrationFulfill(state) {
      state.loading = [];
    },
  },
});

export default integrationsSlice.reducer;

export const {
  fetchIntegrations,
  fetchIntegrationsSuccess,
  fetchIntegrationsFailure,
  fetchIntegrationsFullfill,
  updateIntegration,
  updateIntegrationSuccess,
  updateIntegrationFailure,
  updateIntegrationFulfill,
} = integrationsSlice.actions;

export const selectB2ChatJSIntegrations = (state: RootState) =>
  state.integrations.data.filter(item => item.type === 'B2CHAT_JS_SDK');

export const selectIntegrationsLoadingState = (state: RootState) =>
  state.integrations.loading;
