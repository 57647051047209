import { combineReducers } from 'redux';
import {
  CLEANED_DIALOG_FIELDS,
  DELETE_CHATS_POLICY,
  EMAIL_CHANGED,
  ERROR_LOADED_MERCHANT_USERS_LIST,
  ERROR_LOADED_USERS_LIST,
  FULLNAME_CHANGED,
  ID_CHANGED,
  INVITATION_WAS_SENT,
  LOADED_USERS_RANGE,
  LOADING_FILTERS,
  LOAD_FILE,
  LOAD_IMAGE_PREVIEW_URL,
  MOBILE_ACCESS_CHANGED,
  MOBILE_CHANGED,
  OK_LOADED_MERCHANT_USERS_LIST,
  OK_LOADED_USERS_LIST,
  OPENED_CLOSED_ACTIVATION_DIALOG,
  OPENED_CLOSED_CREATE_USER_DIALOG,
  OPENED_CLOSED_SUBSCRIPTION_DIALOG,
  OPENED_CLOSED_VALIDATION_ERROR_DIALOG,
  RESEND_INVITATION,
  RESEND_INVITATION_CLOSE_DIALOG,
  RESEND_INVITATION_ERROR,
  ROLE_CHANGED,
  SAVING_USER,
  SET_IMAGE_ERROR,
  SET_PAGES_WITHOUT_ACCESS,
  START_LOADING_MERCHANT_USERS_LIST,
  START_LOADING_USERS_LIST,
  USERNAME_CHANGED,
  USER_AUTOASSIGN,
  USER_ERROR_SAVED,
  USER_SAVED,
} from '../actions/user';

const userToolBarReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_USERS_LIST:
      return { ...state, userLoadStatus: 'LOADING' };
    case OK_LOADED_USERS_LIST:
      return {
        ...state,
        userLoadStatus: 'OK_LOADED',
        users: action.results.users,
      };
    case ERROR_LOADED_USERS_LIST:
      return {
        ...state,
        userLoadStatus: 'ERROR_LOADED',
        errorUserMessage: action.message,
      };

    case LOADING_FILTERS:
      return { ...state, filters: action.filters };

    default:
      return state;
  }
};

const initReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_MERCHANT_USERS_LIST:
      return { ...state, userLoadStatus: 'LOADING' };
    case OK_LOADED_MERCHANT_USERS_LIST:
      return {
        ...state,
        userLoadStatus: 'OK_LOADED',
        merchantUsers: action.results.users,
      };
    case ERROR_LOADED_MERCHANT_USERS_LIST:
      return {
        ...state,
        userLoadStatus: 'ERROR_LOADED',
        errorUserMessage: action.message,
      };

    case LOADED_USERS_RANGE:
      return {
        ...state,
        maxUsers: action.maxUsers,
        availablesUsers: action.availablesUsers,
        isAllowedCreateUser: action.isAllowedCreateUser,
      };

    default:
      return state;
  }
};

const createUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ID_CHANGED:
      return { ...state, userId: action.id };

    case FULLNAME_CHANGED:
      return { ...state, fullName: action.fullName };

    case MOBILE_CHANGED:
      return { ...state, mobileNumber: action.mobileNumber };

    case EMAIL_CHANGED:
      return { ...state, email: action.email };

    case ROLE_CHANGED:
      return { ...state, role: action.role };

    case USERNAME_CHANGED:
      return { ...state, userName: action.userName };

    case USER_AUTOASSIGN:
      return { ...state, autoAssign: action.autoAssign };

    case MOBILE_ACCESS_CHANGED:
      return { ...state, mobileAccess: action.mobileAccess };

    case DELETE_CHATS_POLICY:
      return { ...state, chatDeletePolicy: action.chatDeletePolicy };

    case SAVING_USER:
      return { ...state, savedStatus: 'SAVING' };

    case USER_SAVED:
      return {
        ...state,
        userId: action.userId,
        savedStatus: action.savedStatus,
      };

    case USER_ERROR_SAVED:
      return {
        ...state,
        errorCode: action.errorCode,
        messageError: action.messageError,
        savedStatus: 'ERROR_SAVED',
      };

    case CLEANED_DIALOG_FIELDS:
      return {
        ...state,
        fullName: '',
        mobileNumber: '',
        email: '',
        role: '',
        userId: '',
        userName: '',
        savedStatus: '',
        errorCode: '',
        messageError: '',
        mobileAccess: 'INACTIVE',
        chatDeletePolicy: false,
      };

    default:
      return state;
  }
};

const dialogViewerContainerReducer = (state = {}, action) => {
  switch (action.type) {
    case OPENED_CLOSED_CREATE_USER_DIALOG:
      return {
        ...state,
        openCreateDialog: action.state,
        isToCreateUser: action.isToCreateUser,
      };

    case OPENED_CLOSED_SUBSCRIPTION_DIALOG:
      return { ...state, openSubscriptionDialog: action.state };

    case OPENED_CLOSED_ACTIVATION_DIALOG:
      return { ...state, openActivationDialog: action.state };

    case OPENED_CLOSED_VALIDATION_ERROR_DIALOG:
      return {
        ...state,
        openValidationErrorDialog: action.state,
      };

    default:
      return state;
  }
};

const activateUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_FILE:
      return { ...state, file: action.file };

    case LOAD_IMAGE_PREVIEW_URL:
      return { ...state, imagePreviewUrl: action.imagePreviewUrl };

    case SET_IMAGE_ERROR:
      return { ...state, imageErrorCode: action.value };

    default:
      return state;
  }
};

const switchUserStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case OPENED_CLOSED_ACTIVATION_DIALOG:
      return { ...state, userId: action.userId };

    case OPENED_CLOSED_VALIDATION_ERROR_DIALOG:
      return {
        ...state,
        openValidationErrorDialog: action.state,
      };
    default:
      return state;
  }
};

const resendInvitationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESEND_INVITATION:
      return { ...state, deliveryStatus: 'SENDING' };

    case INVITATION_WAS_SENT:
      return {
        ...state,
        userId: action.userId,
        deliveryStatus: action.deliveryStatus,
        openDialog: true,
      };

    case RESEND_INVITATION_ERROR:
      return {
        ...state,
        errorCode: action.errorCode,
        messageError: action.messageError,
        deliveryStatus: 'ERROR_RESENDING_INVITATION',
      };

    case RESEND_INVITATION_CLOSE_DIALOG:
      return { ...state, openDialog: action.openDialog };

    default:
      return state;
  }
};

const roleAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PAGES_WITHOUT_ACCESS:
      return { ...state, pagesWithoutAccess: action.payload };

    default:
      return state;
  }
};

const userReducer = combineReducers({
  init: initReducer,
  toolbar: userToolBarReducer,
  createUserDetails: createUserDetailsReducer,
  dialogViewerContainer: dialogViewerContainerReducer,
  activateUserDetails: activateUserDetailsReducer,
  switchUserStatus: switchUserStatusReducer,
  resendInvitation: resendInvitationReducer,
  roleAccess: roleAccessReducer,
});

export default userReducer;
