/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { selectChatContextMenuOption } from '@src/actions/agentconsole';
import { SideMenuTabs } from '@src/components/AgentConsole/SideMenu';
import { WSCatalogueProductDetail } from '@src/components/WSCatalogue/types';
import {
  WsCatalogueConfigResponse,
  fetchWsCatalogueConfig,
  fetchWsCatalogueConfigFailure,
  fetchWsCatalogueConfigFulfill,
  fetchWsCatalogueConfigSuccess,
  fetchWsCatalogueProductFailure,
  fetchWsCatalogueProductFulfill,
  fetchWsCatalogueProductSuccess,
  fetchWsCatalogueProducts,
  handleCloseWsCatalogueSideMenu,
  hideWsCatalogueSideMenu,
  setWsCatalogue,
  setWsCatalogueFailure,
  setWsCatalogueFulfill,
  setWsCatalogueSuccess,
} from '@src/reducers/wsCatalogue';
import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';

function* fetchWsCatalogueSaga(
  action: ReturnType<typeof fetchWsCatalogueConfig>
) {
  try {
    const response: B2ChatAPI.Response<WsCatalogueConfigResponse> = yield call(
      B2ChatClient.resources.wsCatalogueAdmin.actions.getConfig,
      { params: action.payload }
    );

    if (response.error) {
      yield put(fetchWsCatalogueConfigFailure(response.error));
    } else {
      const {
        is_cart_enabled: isCartEnabled,
        is_catalog_visible: isCatalogVisible,
      } = response.data;

      yield put(
        fetchWsCatalogueConfigSuccess({ isCartEnabled, isCatalogVisible })
      );
    }
  } catch (error) {
    yield put(fetchWsCatalogueConfigFailure(error as ErrorData));
  } finally {
    yield put(fetchWsCatalogueConfigFulfill());
  }
}

function* setWsCatalogueSaga(action: ReturnType<typeof setWsCatalogue>) {
  const { botAccountUsername, isCartEnabled, isCatalogVisible } =
    action.payload;
  try {
    const response: B2ChatAPI.Response<WsCatalogueConfigResponse> = yield call(
      B2ChatClient.resources.wsCatalogueAdmin.actions.setConfig,
      {
        params: { botAccountUsername },
        data: {
          is_cart_enabled: isCartEnabled,
          is_catalog_visible: isCatalogVisible,
        },
      }
    );

    if (response.error) {
      yield put(setWsCatalogueFailure(response.error));
    } else {
      yield put(setWsCatalogueSuccess({ isCartEnabled, isCatalogVisible }));
    }
  } catch (error) {
    yield put(setWsCatalogueFailure(error as ErrorData));
  } finally {
    yield put(setWsCatalogueFulfill());
  }
}

function* fetchWsCatalogueProductSaga(
  action: ReturnType<typeof fetchWsCatalogueProducts>
) {
  const { retailerIds, catalogId, type, shoppingCart } = action.payload;
  if (!catalogId || !retailerIds) {
    throw new Error(
      'Error on see product detail: catalogId and retailerIds are required'
    );
  }

  try {
    const response: B2ChatAPI.Response<WSCatalogueProductDetail[]> = yield call(
      B2ChatClient.resources.wsCatalogue.actions.getProductInfo,
      {
        params: {
          retailerIds,
          catalogueId: catalogId,
        },
      }
    );

    if (response.error) {
      yield put(fetchWsCatalogueProductFailure(response.error));
    } else {
      yield put(
        fetchWsCatalogueProductSuccess({
          products: response.data,
          shoppingCart: shoppingCart || null,
          type,
        })
      );
    }
  } catch (error) {
    yield put(fetchWsCatalogueProductFailure(error as ErrorData));
  } finally {
    yield put(fetchWsCatalogueProductFulfill());
    yield put(selectChatContextMenuOption(SideMenuTabs.WsCatalogue));
  }
}

function* handleCloseWsCatalogueSideMenuSaga() {
  yield put(selectChatContextMenuOption(SideMenuTabs.Contact));
  yield put(hideWsCatalogueSideMenu());
}

export default function* wsCatalogueSaga() {
  yield takeLatest(fetchWsCatalogueConfig, fetchWsCatalogueSaga);
  yield takeLatest(setWsCatalogue, setWsCatalogueSaga);
  yield takeLatest(fetchWsCatalogueProducts, fetchWsCatalogueProductSaga);
  yield takeLatest(
    handleCloseWsCatalogueSideMenu,
    handleCloseWsCatalogueSideMenuSaga
  );
}
