/* eslint-disable @typescript-eslint/ban-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { BotAccount, ErrorData } from '@types';

type Loading = 'fetch-list-user-whatsapp-accounts';

export type UserWaAccountsState = {
  error: ErrorData | null;
  loading: Loading[];
  count: number;
  items: {};
  whatsAppAccounts: Array<BotAccount> | null;
};

type UserWaAccoutnsReducer<P = void> = (
  state: UserWaAccountsState,
  action: PayloadAction<P>
) => void;

const initialState: UserWaAccountsState = {
  error: null,
  loading: [],
  count: 0,
  items: {},
  whatsAppAccounts: [],
};

const handleFetchWaAccounts: UserWaAccoutnsReducer = state => {
  state.loading.push('fetch-list-user-whatsapp-accounts');
  state.error = null;
};

const handleFetchWaAccountsSuccess: UserWaAccoutnsReducer<BotAccount[]> = (
  state,
  action
) => {
  state.whatsAppAccounts = action.payload;
};

const handleFetchWaAccountsFailure: UserWaAccoutnsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchWaAccountsFulfill: UserWaAccoutnsReducer = state => {
  state.loading = state.loading.filter(
    l => l !== 'fetch-list-user-whatsapp-accounts'
  );
};

const userWaAccountsSlice = createSlice({
  name: 'userWaAccounts',
  initialState,
  reducers: {
    fetchWaAccounts: handleFetchWaAccounts,
    fetchWaAccountsSuccess: handleFetchWaAccountsSuccess,
    fetchWaAccountsFailure: handleFetchWaAccountsFailure,
    fetchWaAccountsFulfill: handleFetchWaAccountsFulfill,
  },
});

const { actions, reducer } = userWaAccountsSlice;

export const {
  fetchWaAccounts,
  fetchWaAccountsSuccess,
  fetchWaAccountsFailure,
  fetchWaAccountsFulfill,
} = actions;

export default reducer;
