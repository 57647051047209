import B2ChatClient from '@client-sdk';
import { MediaType } from '@src/model/frontendmodel';
import { ChatViewerState, chatViewerActions } from '@src/reducers/chatViewer';
import {
  ChatContactSelector,
  ChatMsgsHistoryStateSelector,
  ChatReferencesStateSelector,
  TotalChatMsgsHistorySelector,
  TotalChatReferencesSelector,
} from '@src/selectors/chatViewer';
import {
  ChatContactAttrs,
  ChatDetails,
  ChatFullContact,
  ChatHistory,
  ChatHistoryDirection,
  ChatReferences,
} from '@src/types/chatViewert';
import type { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import omit from 'lodash/omit';
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

function* fetchChatMsgsHistory(
  action: ReturnType<typeof chatViewerActions.fetchChatMsgsHistory>
) {
  const {
    chatId,
    merchantToken,
    direction,
    resetAndReload = false,
  } = action.payload;

  const {
    direction: {
      [direction]: { offset, limit, completed, loading },
    },
    tempChats,
    firstItemIndex,
    referenceChatId,
  }: ChatViewerState['chatMsgsHistory'] = yield select(
    ChatMsgsHistoryStateSelector
  );

  if (completed || loading || chatId !== referenceChatId) return;

  yield put(chatViewerActions.isLoadingFetchChatMsgsHistory(direction));

  try {
    const { data, error }: B2ChatAPI.Response<ChatHistory> = yield call(
      B2ChatClient.resources.chatTimeLineViewer.actions.chatMessagesHistory,
      {
        params: {
          merchantToken,
        },
        data: {
          limit,
          offset,
          referenceChatId: chatId,
          direction,
        },
      }
    );

    if (error) throw error;

    let totalEvents = 0;
    let newFirstItemIndex = firstItemIndex;
    const chatMessages = data?.chats
      .map(chat =>
        chat.events.map(event => {
          totalEvents += 1;
          newFirstItemIndex -= 1;
          return {
            chat: omit(chat, 'events'),
            event: {
              ...event,
              mediaType: event.type as MediaType,
              sendingTime: event.sendingTime || event.timestamp,
            },
            itemIndex: newFirstItemIndex,
          };
        })
      )
      .flat(1);

    const totalChatsInHistory: number = yield select(
      TotalChatMsgsHistorySelector
    );

    yield put(
      chatViewerActions.fetchChatMsgsHistorySuccess({
        direction,
        resetAndReload,
        chats: chatMessages,
        firstItemIndex: newFirstItemIndex,
      })
    );

    if (totalChatsInHistory === 0 && tempChats.length === 0) {
      yield put(chatViewerActions.initChatMsgsHistoryTopOffset(totalEvents));
      yield put(
        chatViewerActions.fetchChatMsgsHistory({
          direction: ChatHistoryDirection.TOP,
          merchantToken,
          chatId,
        })
      );
    }
  } catch (error) {
    yield put(
      chatViewerActions.fetchChatMsgsHistoryFailure({
        error: error as ErrorData,
        direction,
      })
    );
  }
}

function* fetchChatDetails(
  action: ReturnType<typeof chatViewerActions.fetchChatDetails>
) {
  try {
    const { chatId, merchantToken } = action.payload;
    const { data: chat, error }: B2ChatAPI.Response<ChatDetails> = yield call(
      B2ChatClient.resources.chatTimeLineViewer.actions.chatDetails,
      { params: { chatId, merchantToken } }
    );

    if (error) throw error;
    yield put(chatViewerActions.fetchChatDetailsSuccess(chat));
  } catch (error) {
    yield put(chatViewerActions.fetchChatDetailsFailure(error as ErrorData));
  }
}

function* fetchChatReferences(
  action: ReturnType<typeof chatViewerActions.fetchChatReferences>
) {
  const { chatId, merchantToken, direction } = action.payload;
  const {
    direction: {
      [direction]: { offset, limit, completed, loading },
    },
  }: ChatViewerState['chatRefs'] = yield select(ChatReferencesStateSelector);

  if (completed || loading) return;

  try {
    yield put(chatViewerActions.isLoadingFetchChatReferences(direction));

    const {
      data,
      error,
    }: B2ChatAPI.Response<{ totalChats: number } & ChatReferences> = yield call(
      B2ChatClient.resources.chatTimeLineViewer.actions.chatReferences,
      {
        params: {
          direction,
          merchantToken,
          chatId,
          limit,
          offset,
        },
      }
    );

    if (error) throw error;

    const totalRefs: number = yield select(
      TotalChatReferencesSelector(direction)
    );
    if (totalRefs === 0 && direction === ChatHistoryDirection.TOP)
      yield put(
        chatViewerActions.fetchChatReferences({
          chatId,
          merchantToken,
          direction: ChatHistoryDirection.BOTTOM,
        })
      );

    yield put(
      chatViewerActions.fetchChatRefsSuccess({
        ...data,
        direction,
        referenceChatId: chatId,
      })
    );
  } catch (error) {
    yield put(
      chatViewerActions.fetchChatRefsFailure({
        error: error as ErrorData,
        direction,
      })
    );
  }
}

function* fetchContactInChat(
  action: ReturnType<typeof chatViewerActions.fetchContactInChat>
) {
  try {
    const { contactId, merchantToken } = action.payload;
    const { data, error }: B2ChatAPI.Response<{ contact: ChatFullContact }> =
      yield call(
        B2ChatClient.resources.chatTimeLineViewer.actions.chatContact,
        { params: { contactId, merchantToken } }
      );

    if (error || !data?.contact) throw error;
    yield put(chatViewerActions.fetchContactInChatSuccess(data.contact));
  } catch (error) {
    yield put(chatViewerActions.fetchContactInChatFailure(error as ErrorData));
  }
}

function* fetchContactAttrsInChat(
  action: ReturnType<typeof chatViewerActions.fetchContactAttrsInChat>
) {
  try {
    const { botId, merchantToken } = action.payload;
    const { data, error }: B2ChatAPI.Response<ChatContactAttrs[]> = yield call(
      B2ChatClient.resources.chatTimeLineViewer.actions.chatContactAttrs,
      { params: { botId, merchantToken } }
    );

    if (error) throw error;

    const contact: ChatFullContact = yield select(ChatContactSelector);
    const mappedAttrs =
      data
        ?.filter(({ activated }) => activated)
        ?.map(attr => {
          contact.attributes.forEach(({ attrId, value }) => {
            if (attr.id === attrId) attr.value = value;
          });
          return attr;
        }) ?? [];

    yield put(chatViewerActions.fetchContactAttrsInChatSuccess(mappedAttrs));
  } catch (error) {
    yield put(
      chatViewerActions.fetchContactAttrsInChatFailure(error as ErrorData)
    );
  }
}

function* takeChatFromAudit(
  action: ReturnType<typeof chatViewerActions.takeChatFromAudit>
) {
  try {
    const { chatId } = action.payload;
    const { error }: B2ChatAPI.Response<unknown> = yield call(
      B2ChatClient.resources.agentChat.actions.takeChatFromAudit,
      { params: { chatId } }
    );
    if (error) yield put(chatViewerActions.takeChatFailure(error));
    else yield put(chatViewerActions.takeChatSuccess());
  } catch (error) {
    yield put(chatViewerActions.takeChatFailure(error as ErrorData));
  }
}

function* assignChat(action: ReturnType<typeof chatViewerActions.assignChat>) {
  try {
    const { chatId, agent } = action.payload;
    const { error }: B2ChatAPI.Response<unknown> = yield call(
      B2ChatClient.resources.agentChat.actions.assignChat,
      { params: { chatId, agentId: agent?.id } }
    );
    if (error) throw error;
    else yield put(chatViewerActions.assignChatSuccess({ chatId, agent }));
  } catch (error) {
    yield put(chatViewerActions.assignChatFailure());
  } finally {
    yield delay(2000);
    yield put(chatViewerActions.assignChatFinally());
  }
}

function* chatViewerSaga() {
  yield takeEvery(chatViewerActions.fetchChatDetails, fetchChatDetails);
  yield takeLatest(
    chatViewerActions.fetchChatMsgsHistory,
    fetchChatMsgsHistory
  );
  yield takeEvery(chatViewerActions.fetchChatReferences, fetchChatReferences);
  yield takeLeading(chatViewerActions.fetchContactInChat, fetchContactInChat);
  yield takeLeading(
    chatViewerActions.fetchContactAttrsInChat,
    fetchContactAttrsInChat
  );
  yield takeEvery(chatViewerActions.takeChatFromAudit, takeChatFromAudit);
  yield takeEvery(chatViewerActions.assignChat, assignChat);
}

export default chatViewerSaga;
