import B2ChatClient from '@client-sdk';
import {
  fetchUrl,
  fetchUrlFailure,
  fetchUrlFulfill,
  fetchUrlSuccess,
} from '@reducers/urlPreview';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeEvery } from 'redux-saga/effects';

function* fetchUrlSaga(action: ReturnType<typeof fetchUrl>) {
  const { url } = action.payload;

  try {
    const response = (yield call(
      [B2ChatClient.resources.urlPreview.actions, 'getMetadata'],
      {
        params: {
          url,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.UrlPreview.UrlData>;

    if (response.error != null) {
      yield put(fetchUrlFailure(response.error));
    } else {
      yield put(fetchUrlSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchUrlFailure(error as ErrorData));
  } finally {
    yield put(fetchUrlFulfill());
  }
}

function* UrlPreviewSaga() {
  yield takeEvery(fetchUrl, fetchUrlSaga);
}

export default UrlPreviewSaga;
