import { Tag } from '@src/services/tags.service';

export type GenericTag = NewTag & Partial<Tag>;

// eslint-disable-next-line no-shadow
export enum TagType {
  AGENT = 'AGENT',
  CONTACT = 'CONTACT',
}

export type NewTag = {
  id?: number;
  tagId?: number;
  name: string;
  tagName?: string;
  emoji: string;
  type?: TagType;
  active: boolean;
  color: string;
  assigned?: boolean;
  botId?: number;
};

// eslint-disable-next-line no-shadow
export enum ContactError {
  FETCH = 'fetch',
  CREATE = 'create',
  ASSIGNED_DELETE = 'assigned-delete',
  DELETE = 'delete',
  EDIT = 'edit',
  UNASSIGN = 'unassign',
}

// eslint-disable-next-line no-shadow
export enum ContactSuccess {
  CREATE = 'create',
  ASSIGNED_DELETE = 'assigned-delete',
  DELETE = 'delete',
  EDIT = 'edit',
}

export const getTagData = (tag: GenericTag): NewTag => ({
  name: tag.name || tag.tagName || '',
  emoji: tag.emoji || '',
  active: tag.active,
  color: tag.color,
  tagId: tag.tagId || tag.botId || undefined,
  assigned: tag.assigned,
});

export const getOldTagData = (tag: GenericTag): Tag => ({
  tagName: tag.name || tag.tagName || '',
  emoji: tag.emoji || '',
  active: tag.active,
  color: tag.color,
  assigned: !!tag.assigned,
  botId: tag.botId || tag.tagId || -1,
});

export const WIDTH_CHATLIST = 450;
export const WIDTH_CONTEXT_MENU = 325;

export const positions = {
  under: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  left: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  over: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
  },
};

export const sortTags = (tags: GenericTag[]) => {
  if (!Array.isArray(tags)) {
    // eslint-disable-next-line no-console
    console.error('Provided "tags" param must be an array!');
    return [];
  }
  return tags.sort((a, b) => {
    if (a.name) return a.name < b.name ? -1 : 1;
    return (a.tagName || 0) < (b.tagName || 0) ? -1 : 1;
  });
};

export default getTagData;
