/* eslint-disable import/no-extraneous-dependencies */
import B2ChatClient from '@client-sdk';
import { call, put, select, takeLeading } from '@redux-saga/core/effects';
import {
  ResponsesByFlowToken,
  WhasAppFlowItem,
  WhatsAppFlowResponsesFilters,
} from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppFlows/types';
import {
  FetchWsFlowsResponseSuccess,
  WsFlowsResponse,
  cleanWsFlowsResponse,
  cleanWsFlowsResponsesByFlowToken,
  fetchCommerceWsFlows,
  fetchCommerceWsFlowsFailure,
  fetchCommerceWsFlowsFulfill,
  fetchCommerceWsFlowsSuccess,
  fetchWsFlowResponsesByFlowToken,
  fetchWsFlowResponsesByFlowTokenFailure,
  fetchWsFlowResponsesByFlowTokenSuccess,
  fetchWsFlowResponsesExport,
  fetchWsFlowResponsesExportFailure,
  fetchWsFlowResponsesExportFulfill,
  fetchWsFlowResponsesExportSuccess,
  fetchWsFlows,
  fetchWsFlowsFailure,
  fetchWsFlowsFulfill,
  fetchWsFlowsResponse,
  fetchWsFlowsResponseFailure,
  fetchWsFlowsResponseFulfill,
  fetchWsFlowsResponseSuccess,
  fetchWsFlowsScreens,
  fetchWsFlowsScreensFailure,
  fetchWsFlowsScreensSuccess,
  fetchWsFlowsSuccess,
} from '@src/reducers/wsFlows';
import {
  getWsFlowResponsesFilters,
  getWsFlowSelectedId,
  getWsFlowsFiltersQS,
} from '@src/selectors/wsFlows';
import { B2ChatAPI } from '@src/types/api';
import { ErrorData } from '@types';
import dayjs from 'dayjs';

const STATIC_LIMIT_WS_FLOWS = '100';

function* fetchWsFlowsSaga() {
  const filters: { [key: string]: string } = yield select(getWsFlowsFiltersQS);

  try {
    const response: B2ChatAPI.Response<WsFlowsResponse> = yield call(
      B2ChatClient.resources.wsFlows.actions.getFlows,
      { params: { ...filters, limit: STATIC_LIMIT_WS_FLOWS } }
    );

    if (response.error) {
      yield put(fetchWsFlowsFailure(response.error));
    } else {
      yield put(fetchWsFlowsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWsFlowsFailure(error as ErrorData));
  } finally {
    yield put(fetchWsFlowsFulfill());
  }
}

function* fetchWsFlowsResponseSaga() {
  const flowId: string = yield select(getWsFlowSelectedId);
  const filters: WhatsAppFlowResponsesFilters = yield select(
    getWsFlowResponsesFilters
  );

  yield put(cleanWsFlowsResponse());

  try {
    const response: B2ChatAPI.Response<FetchWsFlowsResponseSuccess> =
      yield call(B2ChatClient.resources.wsFlows.actions.getFlowResponses, {
        params: { flowId, ...filters },
      });

    if (response.error) {
      yield put(fetchWsFlowsResponseFailure(response.error));
    } else {
      yield put(fetchWsFlowsResponseSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWsFlowsResponseFailure(error as ErrorData));
  } finally {
    yield put(fetchWsFlowsResponseFulfill());
  }
}

function* fetchWsFlowsScreensSaga(
  action: ReturnType<typeof fetchWsFlowsScreens>
) {
  const { flowId } = action.payload;

  try {
    const response: B2ChatAPI.Response<string[]> = yield call(
      B2ChatClient.resources.wsFlows.actions.getFlowScreens,
      {
        params: { flowId },
      }
    );

    if (response.error) {
      yield put(fetchWsFlowsScreensFailure(response.error));
    } else {
      yield put(fetchWsFlowsScreensSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWsFlowsScreensFailure(error as ErrorData));
  } finally {
    yield put(fetchWsFlowsFulfill());
  }
}

function* fetchWsFlowResponsesByFlowTokenSaga(
  action: ReturnType<typeof fetchWsFlowResponsesByFlowToken>
) {
  yield put(cleanWsFlowsResponsesByFlowToken());
  const { flowToken } = action.payload;

  try {
    const response: B2ChatAPI.Response<ResponsesByFlowToken> = yield call(
      B2ChatClient.resources.wsFlows.actions.getFlowResponsesByFlowToken,
      {
        params: { flowToken },
      }
    );

    if (response.error) {
      yield put(fetchWsFlowResponsesByFlowTokenFailure(response.error));
    } else {
      yield put(fetchWsFlowResponsesByFlowTokenSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWsFlowResponsesByFlowTokenFailure(error as ErrorData));
  } finally {
    yield put(fetchWsFlowsFulfill());
  }
}

function* fetchWsFlowResponsesExportSaga(
  action: ReturnType<typeof fetchWsFlowResponsesExport>
) {
  const { flowId, emails } = action.payload;
  const filters: WhatsAppFlowResponsesFilters = yield select(
    getWsFlowResponsesFilters
  );

  const startTime =
    filters.start_time ||
    dayjs(new Date()).subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ssZ');

  const endTime =
    filters.end_time || dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

  try {
    const response: B2ChatAPI.Response<{ message: string }> = yield call(
      B2ChatClient.resources.wsFlows.actions.flowResponsesExport,
      {
        params: {
          flowId,
          to: `${emails}`,
          start_time: startTime,
          end_time: endTime,
        },
      }
    );

    if (response.error) {
      yield put(fetchWsFlowResponsesExportFailure(response.error));
    } else {
      yield put(fetchWsFlowResponsesExportSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchWsFlowResponsesExportFailure(error as ErrorData));
  } finally {
    yield put(fetchWsFlowResponsesExportFulfill());
  }
}

function* fetchCommerceWsFlowsSaga() {
  try {
    const response: B2ChatAPI.Response<WhasAppFlowItem[]> = yield call(
      B2ChatClient.resources.wsFlows.actions.getCommerceFlows,
      {}
    );

    if (response.error) {
      yield put(fetchCommerceWsFlowsFailure(response.error));
    } else {
      yield put(fetchCommerceWsFlowsSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchCommerceWsFlowsFailure(error as ErrorData));
  } finally {
    yield put(fetchCommerceWsFlowsFulfill());
  }
}

export default function* wsCatalogueSaga() {
  yield takeLeading(fetchWsFlows, fetchWsFlowsSaga);
  yield takeLeading(fetchWsFlowsResponse, fetchWsFlowsResponseSaga);
  yield takeLeading(fetchWsFlowsScreens, fetchWsFlowsScreensSaga);
  yield takeLeading(
    fetchWsFlowResponsesByFlowToken,
    fetchWsFlowResponsesByFlowTokenSaga
  );
  yield takeLeading(fetchCommerceWsFlows, fetchCommerceWsFlowsSaga);
  yield takeLeading(fetchWsFlowResponsesExport, fetchWsFlowResponsesExportSaga);
}
