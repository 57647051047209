import {
  START_LOADING_ORDER_DETAIL,
  OK_LOADING_ORDER_DETAIL,
  ERROR_LOADED_ORDER_DETAIL,
  OK_LOADING_ORDER_STATES,
  ERROR_LOADED_ORDER_STATES,
  OK_UPDATE_ORDER_STATE,
  ERROR_UPDATE_ORDER_STATE,
  OK_LOADING_MAP_URL,
} from '../actions/orderdetail';

/*const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case OK_UPDATE_ORDER_STATE:
      return Object.assign({}, state, {
        currentOrderState:action.orderState
      });
    case ERROR_UPDATE_ORDER_STATE:
      return Object.assign({}, state, {

      });
    default:
      return state;
  }
};*/

const orderDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING_ORDER_DETAIL:
      return {
        ...state,
        loadStatus: 'LOADING',
        orderNumber: action.orderNumber,
        errorMessage: '',
        order: null,
        mapUrl: null,
      };
    case OK_LOADING_ORDER_DETAIL:
      return {
        ...state,
        loadStatus: 'OK_LOADED',
        order: action.order,
        errorMessage: '',
        currentOrderState: action.order.state,
      };
    case ERROR_LOADED_ORDER_DETAIL:
      return {
        ...state,
        loadStatus: 'ERROR_LOADED',
        order: null,
        errorMessage: action.message,
      };
    case OK_LOADING_ORDER_STATES:
      return {
        ...state,
        loadOrderStates: 'OK_LOADED',
        errorMessageStates: '',
        orderStates: action.orderStates,
      };
    case ERROR_LOADED_ORDER_STATES:
      return {
        ...state,
        loadOrderStates: 'ERROR_LOADED_STATE',
        errorMessageStates: action.message,
        orderStates: null,
      };
    case OK_UPDATE_ORDER_STATE:
      return { ...state, currentOrderState: action.orderState };
    case ERROR_UPDATE_ORDER_STATE:
      return { ...state };
    case OK_LOADING_MAP_URL:
      return { ...state, mapUrl: action.mapUrl };
    /*case OK_UPDATE_ORDER_STATE:
    return Object.assign({}, state, {
      order: orderReducer('', action)
    });
    case ERROR_UPDATE_ORDER_STATE:
    return Object.assign({}, state, {
    });*/
    default:
      return state;
  }
};
/*
const auditReducer = combineReducers({
  order:auditToolBarReducer,
  chat:auditChatReducer,
  message:auditMessageReducer,
  dialog: dialogReducer
});
*/
export default orderDetailReducer;
