//import BackendProxy from'../components/AdminHome/CreateOrder/CreateOrderMock.js';
import BackendProxy from '../logic/backendproxy';

export const START_LOADING_CATEGORIES_LIST =
  'CREATEPRODUCT.START_LOADING_CATEGORIES_LIST';
export const OK_LOADED_CATEGORIES_LIST =
  'CREATEPRODUCT.OK_LOADED_CATEGORIES_LIST';
export const ERROR_LOADED_CATEGORIES_LIST =
  'CREATEPRODUCT.ERROR_LOADED_CATEGORIES_LIST';

export const LOADING_FILTERS = 'CREATEPRODUCT.LOADING_FILTERS';

export const START_LOADING_PRODUCTS_LIST =
  'CREATEPRODUCT.START_LOADING_PRODUCTS_LIST';
export const OK_LOADED_PRODUCT_LIST = 'CREATEPRODUCT.OK_LOADED_PRODUCT_LIST';
export const ERROR_LOADED_PRODUCT_LIST =
  'CREATEPRODUCT.ERROR_LOADED_PRODUCT_LIST';

export const RESET_ALL_FILTER_VALUES = 'CREATEPRODUCT.RESET_ALL_FILTER_VALUES';
export const START_LOADING_EMAIL = 'CREATEPRODUCT.START_LOADING_EMAIL';
export const SET_MODAL_EXPORT_OPEN = 'CREATEPRODUCT.SET_MODAL_EXPORT_OPEN';
export const SET_MODAL_EXPORT_ERROR_MESSAGE =
  'CREATEPRODUCT.SET_MODAL_EXPORT_ERROR_MESSAGE';

export function exportErrorMessage(msg) {
  return {
    type: SET_MODAL_EXPORT_ERROR_MESSAGE,
    errorMessage: msg,
  };
}

export function exportOpen(open) {
  return {
    type: SET_MODAL_EXPORT_OPEN,
    open,
  };
}

export function okLoadedProductsList(products) {
  return {
    type: OK_LOADED_PRODUCT_LIST,
    products,
  };
}

export function errorLoadedProductsList(error) {
  return {
    type: ERROR_LOADED_PRODUCT_LIST,
    message: error,
  };
}

export function startLoadingProductsList() {
  return {
    type: START_LOADING_PRODUCTS_LIST,
  };
}

export function changeFilters(newFilters) {
  return (dispatch, getState) => {
    console.log('newFilters', newFilters);
    dispatch(loadingFilters(newFilters));
    commonLoadProducts(dispatch, getState);
  };
}

function commonLoadProducts(dispatch, getState) {
  const merchantName = getState().loginAuthentication.success.employer.name;
  const merchantId = getState().loginAuthentication.success.employer.id;
  const { toolbar } = getState().adminHome.product;
  const filters = {};
  filters.merchantName = merchantName;
  filters.merchantId = merchantId;
  filters.productNameLike = toolbar.productNameLike;
  filters.categoryId = toolbar.categoryId;
  filters.active = toolbar.active;
  BackendProxy.getProductList(
    filters,
    respData => {
      console.log('OK, getProductList list: ', respData);
      const orderResults = respData;
      /*setTimeout(() => {*/
      dispatch(okLoadedProductsList(orderResults));
      /*}, 1000);*/
    },
    (respStatus, error) => {
      // Handle errors that might occur while uploading media
      console.log(`Error Getting Orders! Status code: ${respStatus}`, error);
      dispatch(errorLoadedProductsList(error));
    }
  );
}

export function loadingFilters(filters) {
  return {
    type: LOADING_FILTERS,
    filters,
  };
}

export function clickResetAllFilters() {
  return (dispatch, getState) => {
    dispatch(clickResetAllFiltersValues());
    commonLoadProducts(dispatch, getState);
  };
}

export function loadCategories() {
  return (dispatch, getState) => {
    dispatch(startLoadingCategoriesList());
    const data = {};
    const merchantId = getState().loginAuthentication.success.employer.id;
    const merchantName = getState().loginAuthentication.success.employer.name;
    data.merchantId = merchantId;
    data.merchantName = merchantName;
    BackendProxy.getCategories(
      data,
      respData => {
        console.log('OK, loadCategories list: ', respData);
        const categories = respData;
        dispatch(okLoadedCategoriesList(categories));
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          'Error Getting categories! Status code: ',
          respStatus,
          error
        );
        dispatch(errorLoadedCategoriesList(error));
      }
    );
  };
}

export function startLoadingCategoriesList() {
  return {
    type: START_LOADING_CATEGORIES_LIST,
  };
}

export function okLoadedCategoriesList(results) {
  return {
    type: OK_LOADED_CATEGORIES_LIST,
    results,
  };
}

export function errorLoadedCategoriesList(error) {
  return {
    type: ERROR_LOADED_CATEGORIES_LIST,
    message: error,
  };
}

export function clickResetAllFiltersValues() {
  return {
    type: RESET_ALL_FILTER_VALUES,
    productNameLike: '',
    categoryId: '',
    categories: [],
    statusLoadingCategories: '',
    errorMessage: '',
    active: '',
  };
}

export function changeEmail(email) {
  return {
    type: START_LOADING_EMAIL,
    email,
  };
}

export function exportProduct(msg) {
  return (dispatch, getState) => {
    const merchantName = getState().loginAuthentication.success.employer.name;
    const merchantId = getState().loginAuthentication.success.employer.id;
    const { email } = getState().adminHome.product.export;
    let filters = getState().adminHome.product.toolbar;
    filters.emailToSend = email;
    filters.merchantName = merchantName;
    filters.merchantId = merchantId;
    filters = { ...filters, ...msg };
    dispatch(changeEmail(''));
    //var merchantId = getState().loginAuthentication.success.employer.id;
    BackendProxy.exportProduct(
      filters,
      respData => {
        console.log('OK, exportOrder: ', respData);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(`Error exportOrder! Status code: ${respStatus}`, error);
      }
    );
  };
}
