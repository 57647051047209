import { TextField } from '@material-ui/core';
import StringUtils from '../../utils/strings';
import TypeUtils from '../../utils/typeutils';

// A regular expression that matches international phone numbers in accordance with the E.164 standard
const e164PartialPhoneRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)?(\d{0,14})$/;
const e164ValidPhoneRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(\d{1,14})$/;

// Clears the provided input of non-numeric characters and tries to match it as a standard E.164 intl. phone number
const clearPhoneInput = phoneInput => {
  if (phoneInput) {
    return `+${phoneInput.replace(/[^\d]/g, '')}`;
  }

  return '';
};

// Validates whether the specified phone number is a valid E.164 phone number (empty values are deemed valid)
const validateE164Phone = phone => {
  if (StringUtils.isBlank(phone)) {
    return true;
  }

  return e164ValidPhoneRegex.test(phone);
};

// Parses a phone input as to the E.164 standard and if valid returns an object with its Country and National Destination codes
const parseE164PhoneInput = clearedPhone => {
  const arrMatches = e164PartialPhoneRegex.exec(clearedPhone);

  // Extract the CC code and National Destination Code from the capture groups of the regex match
  if (arrMatches && arrMatches.length === 3) {
    return {
      ccCode: StringUtils.trimToEmpty(arrMatches[1]),
      destCode: StringUtils.trimToEmpty(arrMatches[2]),
    };
  }

  return null;
};

// A text field to input phone numbers in E.164 standard format
const E164PhoneField = ({ onChange, maxLength, value, disabled, ...props }) => {
  // Wrapper function to handle the onChange event on the text field, which performs phone formatting functionalities
  // and also invokes the handler provided by the user for the same event
  const onChangeWrapper = (ev, newPhone) => {
    let e164FormattedPhone = '';
    let isValid = false;

    // Clear the phone number of non-numeric characters and try to match it as a standard E.164 intl. phone number
    const clearedPhone = clearPhoneInput(newPhone);
    const e164PhoneComponents = parseE164PhoneInput(clearedPhone);

    // Extract the CC code and National Destination Code from the capture groups of the regex match
    if (e164PhoneComponents) {
      e164FormattedPhone = `+${e164PhoneComponents.ccCode}${e164PhoneComponents.destCode}`;

      // The parsed phone is regarded as valid if and only if both, the country code and national destination number aren't empty
      if (
        !StringUtils.isBlank(e164PhoneComponents.ccCode) &&
        !StringUtils.isBlank(e164PhoneComponents.destCode)
      ) {
        isValid = true;
      }
    } else {
      e164FormattedPhone = clearedPhone;
    }

    if (onChange && TypeUtils.isFunction(onChange)) {
      onChange(ev, e164FormattedPhone, isValid);
    }
  };

  // Properly format the text to be displayed on the phone field
  const clearedPhone = clearPhoneInput(value);
  const e164PhoneComponents = parseE164PhoneInput(clearedPhone);

  let displayFormattedPhone = clearedPhone;

  if (
    e164PhoneComponents &&
    !StringUtils.isBlank(e164PhoneComponents.ccCode) &&
    !StringUtils.isBlank(e164PhoneComponents.destCode)
  ) {
    displayFormattedPhone = `+${e164PhoneComponents.ccCode}${e164PhoneComponents.destCode}`;
  }

  // By default, max length is set to be 16 characters
  const actualMaxLength = maxLength || 16;

  return (
    <TextField
      onChange={onChangeWrapper}
      maxLength={actualMaxLength}
      value={displayFormattedPhone}
      {...props}
      disabled={disabled}
    />
  );
};

export { E164PhoneField as default, validateE164Phone };
