import B2ChatClient from '@client-sdk';
import {
  getUserState,
  getUserStateFailure,
  getUserStateFulfill,
  getUserStateSuccess,
  updateUserState,
  updateUserStateFailure,
  updateUserStateFulfill,
  updateUserStateSuccess,
} from '@reducers/userState';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLatest } from 'redux-saga/effects';

function* updateUserStateSaga(action: ReturnType<typeof updateUserState>) {
  const { online } = action.payload;
  try {
    const response = (yield call(
      [B2ChatClient.resources.user.actions, 'updateUserState'],
      { data: { online } }
    )) as B2ChatAPI.Response<B2ChatAPI.User.UpdateUserState>;
    if (response.error != null) {
      yield put(updateUserStateFailure(response.error));
    } else {
      yield put(updateUserStateSuccess(response.data));
    }
  } catch (error) {
    yield put(updateUserStateFailure(error as ErrorData));
  } finally {
    yield put(updateUserStateFulfill());
  }
}

function* getUserStateSaga() {
  try {
    const response = (yield call([
      B2ChatClient.resources.user.actions,
      'getUserState',
    ])) as B2ChatAPI.Response<B2ChatAPI.User.GetUserState>;
    if (response.error != null) {
      yield put(getUserStateFailure(response.error));
    } else {
      yield put(getUserStateSuccess(response.data));
    }
  } catch (error) {
    yield put(getUserStateFailure(error as ErrorData));
  } finally {
    yield put(getUserStateFulfill());
  }
}

function* UserStateSaga() {
  yield takeLatest(getUserState, getUserStateSaga);
  yield takeLatest(updateUserState, updateUserStateSaga);
}

export default UserStateSaga;
