// Connects with Facebook services through the Web API and provides access to the
// functionalities offered by that API. The appId argument should correspond to the
// Facebook application to connect with
const FbApiProxy = (function () {
  // Says whether a connection with the Facebook API has already been established
  let isInitialized = false;

  return {
    // Starts the Facebook Web API up, if not yet initialized
    initialize(fnConnectedCallback, appId) {
      if (!isInitialized) {
        // Connection with the Facebook API is not yet initialized, do it and invoke the conncted-callback when ready
        window.fbAsyncInit = function () {
          const { FB } = window;

          try {
            FB.init({
              appId,
              cookie: true,
              xfbml: true,
              version: 'v13.0',
            });
            FB.AppEvents.logPageView();
          } catch (error) {
            throw new Error(
              `Failed to initialize Facebook API. App: ${String(
                appId
              )}. Reason: ${String(error)}`
            );
          }

          isInitialized = true;

          if (fnConnectedCallback) {
            fnConnectedCallback();
          }
        };

        (function (d, s, id) {
          let js;
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = '//connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      } else {
        // Connection with the Facebook API is already initialized, just invoke the connected-callback
        if (fnConnectedCallback) {
          fnConnectedCallback();
        }
      }
    },

    // Attempts to login via the Facebook API. Delivers the result by invoking the specified fnCallback function.
    // The result is passed to fnCallback as an object argument. By default only basic permissions are requested
    // on login. In order to request additional permissions, list their codes in the arrPermissionsToRequest
    // argument (e.g. ["manage_pages", "pages_show_list", "pages_messaging", "pages_messaging_subscriptions"])
    login(fnCallback, arrPermissionsToRequest, reAskPermissions = false) {
      if (!isInitialized) {
        throw new Error('Facebook API Proxy has not been initialized');
      }

      // By setting the return_scopes option to true in the option object when calling FB.login(),
      // a list of the granted permissions in the grantedScopes field on the authResponse object will be returned
      const loginParams = { return_scopes: true };

      // Check if special permissions are being requested, in that case set the scope field in the login params object
      if (
        arrPermissionsToRequest &&
        arrPermissionsToRequest.constructor === Array
      ) {
        loginParams.scope = arrPermissionsToRequest;
      }

      // Check if the purpose of this login attempt is to re-ask for permissions previously denied by the user
      if (reAskPermissions === true) {
        loginParams.auth_type = 'rerequest';
      }

      window.FB.login(fnCallback, loginParams);
    },

    // Terminates a session previoulsy established via the Facebook API
    // WARNING: Since 2020-02-04 logging out started invalidating the Page Access Token! DO NOT USE THIS ANY LONGER!
    logout(fnCallback) {
      if (!isInitialized) {
        throw new Error('Facebook API Proxy has not been initialized');
      }

      // Disabled. See warning above
      // window.FB.logout((response) => {
      //   fnCallback(response);
      // });

      fnCallback({});
    },

    // Checks if there's a user currently logged into the API proxy and delivers the result by invoking the
    // specified fnCallback function. The result is passed to fnCallback as an object argument
    getLoginStatus(fnCallback) {
      if (!isInitialized) {
        throw new Error('Facebook API Proxy has not been initialized');
      }

      window.FB.getLoginStatus(fnCallback);
    },

    // Check permissions currently granted by the user
    checkPermissions(fnCallback) {
      if (!isInitialized) {
        throw new Error('Facebook API Proxy has not been initialized');
      }

      window.FB.api('/me/permissions', fnCallback);
    },

    // Loads all Facebook pages of the user associated to the specified user access token. Note that this token
    // should be long-lived in order to obtain non-expiring page-access-tokens in the resulting list of pages.
    // The result is passed to the fnCallback as an object argument, the list of pages is in an attribute called data
    listUserPages(longLivedUserToken, fnCallback) {
      if (!isInitialized) {
        throw new Error('Facebook API Proxy has not been initialized');
      }

      let lstAllUserPages = [];

      // Recursively load all pages returned as pages are loaded
      const fnNextPageLoader = latestResp => {
        if (latestResp && latestResp.paging && latestResp.paging.next) {
          window.FB.api(
            latestResp.paging.next,
            'get',
            { access_token: longLivedUserToken },
            response => {
              if (
                response &&
                response.data &&
                response.data.constructor === Array
              ) {
                lstAllUserPages = [...lstAllUserPages, ...response.data];
                fnNextPageLoader(response);
              }
            }
          );
        } else {
          fnCallback(lstAllUserPages);
        }
      };

      window.FB.api(
        '/me/accounts',
        'get',
        { access_token: longLivedUserToken },
        response => {
          if (
            response &&
            response.data &&
            response.data.constructor === Array
          ) {
            lstAllUserPages = response.data;
            fnNextPageLoader(response);
          }
        }
      );
    },

    // Links up the a Facebook page with a bot, so that the latter is able to receive/send messages through the former.
    // A callback function must be provided via the fnCallback attribute, which will be invoked with the response
    // given by the Facebook API as soon as the operation gets completed
    linkUpUserPageWithBot(pageId, pageAccessToken, botId, fnCallback) {
      if (!isInitialized) {
        throw new Error('Facebook API Proxy has not been initialized');
      }

      window.FB.api(
        `/${pageId}/subscribed_apps`,
        'post',
        {
          access_token: pageAccessToken,
          subscribed_fields: [
            'message_mention',
            'messages',
            'message_deliveries',
            'messaging_postbacks',
            'messaging_account_linking',
            'messaging_checkout_updates',
            'message_reads',
            'messaging_optouts',
            'messaging_payments',
            'messaging_pre_checkouts',
            'messaging_referrals',
            'messaging_handovers',
            'messaging_appointments',
            'messaging_direct_sends',
          ],
        },
        response => {
          fnCallback(response);
        }
      );
    },

    // Obtains the name and ID of a Facebook page given its URL (e.g. https://www.facebook.com/my_page_name).
    // Must be logged in for this function to work
    getPageInfoByUrl(pageUrl, fnCallback) {
      window.FB.api(`/${pageUrl}`, response => {
        fnCallback(response);
      });
    },
  };
})();

export default FbApiProxy;
