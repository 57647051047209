/* eslint-disable */
//import BackendProxy from'../components/AdminHome/User/UserMock.js';
import BackendProxy from '../logic/backendproxy';
import { User } from '../model/frontendmodel';
import { loginAuthentication } from './loginAuthenticationAction';

export const START_LOADING_MERCHANT_USERS_LIST =
  'USER.START_LOADING_MERCHANT_USERS_LIST';
export const OK_LOADED_MERCHANT_USERS_LIST =
  'USER.OK_LOADED_MERCHANT_USERS_LIST';
export const ERROR_LOADED_MERCHANT_USERS_LIST =
  'USER.ERROR_LOADED_MERCHANT_USERS_LIST';

export const START_LOADING_USERS_LIST = 'USER.START_LOADING_USERS_LIST';
export const OK_LOADED_USERS_LIST = 'USER.OK_LOADED_USERS_LIST';
export const ERROR_LOADED_USERS_LIST = 'USER.ERROR_LOADED_USERS_LIST';

export const LOADED_USERS_RANGE = 'USER.LOADED_USERS_RANGE';
export const LOADING_FILTERS = 'USER.LOADING_FILTERS';

export const ID_CHANGED = 'USER.ID_CHANGED';
export const FULLNAME_CHANGED = 'USER.FULLNAME_CHANGED';
export const MOBILE_CHANGED = 'USER.MOBILE_CHANGED';
export const EMAIL_CHANGED = 'USER.EMAIL_CHANGED';
export const ROLE_CHANGED = 'USER.ROLE_CHANGED';
export const USERNAME_CHANGED = 'USER.USERNAME_CHANGED';
export const USER_AUTOASSIGN = 'USER.AUTOASSIGN';
export const MOBILE_ACCESS_CHANGED = 'USER.MOBILE_ACCESS_CHANGED';
export const DELETE_CHATS_POLICY = 'USER.DELETE_CHATS_POLICY';

export const SAVING_USER = 'USER.SAVING_USER';
export const USER_SAVED = 'USER.USER_SAVED';
export const USER_ERROR_SAVED = 'USER.USER_ERROR_SAVED';

export const OPENED_CLOSED_CREATE_USER_DIALOG =
  'USER.OPENED_CLOSED_CREATE_USER_DIALOG';
export const OPENED_CLOSED_SUBSCRIPTION_DIALOG =
  'USER.OPENED_CLOSED_SUBSCRIPTION_DIALOG';
export const OPENED_CLOSED_ACTIVATION_DIALOG =
  'USER.OPENED_CLOSED_ACTIVATION_DIALOG';

export const OPENED_CLOSED_VALIDATION_ERROR_DIALOG =
  'USER.OPENED_CLOSED_VALIDATION_DIALOG';

export const CLEANED_DIALOG_FIELDS = 'USER.CLEANED_DIALOG_FIELDS';

export const LOAD_FILE = 'USER.LOAD_FILE';
export const LOAD_IMAGE_PREVIEW_URL = 'USER.LOAD_IMAGE_PREVIEW_URL';
export const SET_IMAGE_ERROR = 'USER.SET_IMAGE_ERROR';

export const RESEND_INVITATION = 'USER.RESEND_INVITATION';
export const INVITATION_WAS_SENT = 'USER.INVITATION_WAS_SENT';
export const RESEND_INVITATION_ERROR = 'USER.RESEND_INVITATION_ERROR';
export const RESEND_INVITATION_CLOSE_DIALOG =
  'USER.RESEND_INVITATION_CLOSE_DIALOG';

export const SET_PAGES_WITHOUT_ACCESS = 'USER.SET_PAGES_WITHOUT_ACCESS';

export function loadInitialState() {
  return (dispatch, getState) => {
    dispatch(restoreFilters());
    dispatch(loadFilterUsers());
    dispatch(loadActiveMerchantUsers());
  };
}

//#################### START Merchant Users Manager ##############
export function loadActiveMerchantUsers() {
  return (dispatch, getState) => {
    dispatch(startLoadingMerchantUsersList());
    commonLoadUsers(
      dispatch,
      getState,
      null,
      okLoadedMerchantUsersList,
      errorLoadedMerchantUsersList
    );

    setTimeout(() => {
      const { maxUsers } = getState().loginAuthentication.success.employer.plan;
      const users = getState().adminHome.user.init.merchantUsers;

      const diff = maxUsers - users.length;
      let isAllowedCreateUser = false;

      if (diff > 0) {
        isAllowedCreateUser = true;
      }

      dispatch(loadUserRange(maxUsers, users.length, isAllowedCreateUser));
    }, 2000);
  };
}

export function startLoadingMerchantUsersList() {
  return {
    type: START_LOADING_MERCHANT_USERS_LIST,
  };
}

export function okLoadedMerchantUsersList(results) {
  return {
    type: OK_LOADED_MERCHANT_USERS_LIST,
    results,
  };
}

export function errorLoadedMerchantUsersList(error) {
  return {
    type: ERROR_LOADED_MERCHANT_USERS_LIST,
    message: error,
  };
}
//#################### END Merchant Users Manager ##############

//#################### START Users Manager ##############
export function loadFilterUsers() {
  return (dispatch, getState) => {
    dispatch(startLoadingUsersList());
    commonLoadUsers(
      dispatch,
      getState,
      undefined,
      okLoadedUsersList,
      errorLoadedUsersList
    );
  };
}

export function startLoadingUsersList() {
  return {
    type: START_LOADING_USERS_LIST,
  };
}

export function okLoadedUsersList(results) {
  return {
    type: OK_LOADED_USERS_LIST,
    results,
  };
}
export function errorLoadedUsersList(error) {
  return {
    type: ERROR_LOADED_USERS_LIST,
    message: error,
  };
}
//#################### END Users Manager ##############

export function loadUserRange(maxUsers, availablesUsers, isAllowedCreateUser) {
  return {
    type: LOADED_USERS_RANGE,
    maxUsers,
    availablesUsers,
    isAllowedCreateUser,
  };
}

//####################COMMOMS FUNCTIONS###############################
function commonLoadUsers(
  dispatch,
  getState,
  userFilter,
  okCallback,
  errorCallback
) {
  if (userFilter === undefined) {
    const { toolbar } = getState().adminHome.user;
    userFilter = toolbar.filters;
  }

  BackendProxy.getUsers(
    userFilter,
    respData => {
      console.log('OK, Users list: ', respData);
      const users = respData;

      setTimeout(() => {
        dispatch(okCallback(users));
      }, 1000);
    },
    (respStatus, error) => {
      // Handle errors that might occur while uploading media
      console.log(`Error Getting Users! Status code: ${respStatus}`, error);
      dispatch(errorCallback(error));
    }
  );
}
//###################################################

export function changeFilters(newFilters) {
  return (dispatch, getState) => {
    dispatch(loadingFilters(newFilters));
    dispatch(loadFilterUsers());
  };
}

export function loadingFilters(filters) {
  return {
    type: LOADING_FILTERS,
    filters,
  };
}

export function setSelectedUserId(id) {
  return {
    id,
    type: ID_CHANGED,
  };
}

export function changedFullName(newValue) {
  return {
    fullName: newValue,
    type: FULLNAME_CHANGED,
  };
}

export function changedMobile(newValue) {
  return {
    mobileNumber: newValue,
    type: MOBILE_CHANGED,
  };
}

export function changedEmail(newValue) {
  return {
    email: newValue,
    type: EMAIL_CHANGED,
  };
}

export function changedRole(newValue) {
  return {
    role: newValue,
    type: ROLE_CHANGED,
  };
}

export function changedUserName(newValue) {
  return {
    userName: newValue,
    type: USERNAME_CHANGED,
  };
}

export function userAutoAssign(newValue) {
  return {
    autoAssign: newValue,
    type: USER_AUTOASSIGN,
  };
}

export function changedMobileAccess(newValue) {
  return {
    mobileAccess: newValue,
    type: MOBILE_ACCESS_CHANGED,
  };
}

export function onChageDeleteChatsPolicy(newValue) {
  return {
    chatDeletePolicy: newValue,
    type: DELETE_CHATS_POLICY,
  };
}

export function userCreationEvent() {
  return (dispatch, getState) => {
    dispatch(savingUser());

    const { createUserDetails } = getState().adminHome.user;
    const { activateUserDetails } = getState().adminHome.user;

    const user = new User(
      createUserDetails.userId,
      createUserDetails.fullName,
      createUserDetails.mobileNumber,
      createUserDetails.email,
      createUserDetails.role,
      createUserDetails.userName,
      '',
      '',
      '',
      true,
      true,
      activateUserDetails.imagePreviewUrl,
      createUserDetails.mobileAccess,
      createUserDetails.chatDeletePolicy
    );

    BackendProxy.saveOrActivateUser(
      user,
      'save',
      respData => {
        console.log('OK, Created User: ', respData);
        const { user } = respData;
        let savedStatus = '';
        setTimeout(() => {
          if (user.id !== null) {
            savedStatus = 'OK_SAVED';
            dispatch(openCloseCreateUserDialog(false, null));
          }
          dispatch(okSavedUser(user.id, savedStatus));
          dispatch(loadFilterUsers());
        }, 1000);
      },
      (errCode, errdesc, errDetails) => {
        let errDetailsFieldName = 'ERROR_SERVER';
        if (errDetails !== null) {
          errDetailsFieldName = errDetails.fieldName;
        }
        dispatch(errorSavingUser(errDetailsFieldName, errDetails));
      }
    );
  };
}

export function userResendInvitationEvent(user) {
  return (dispatch, getState) => {
    dispatch(resendInvitation());

    console.log('[action]', user);

    BackendProxy.saveOrActivateUser(
      user,
      'resendInvitation',
      respData => {
        console.log('OK, Is was sent: ', respData);
        const { user } = respData;
        let deliveryStatus = '';
        setTimeout(() => {
          if (user.id !== null) {
            deliveryStatus = 'OK_WAS_SENT';
          }
          dispatch(okSentInvitation(user.id, deliveryStatus));
          dispatch(loadFilterUsers());
        }, 1000);
      },
      (errCode, errdesc, errDetails) => {
        let errDetailsFieldName = 'ERROR_SERVER';
        if (errDetails !== null) {
          errDetailsFieldName = errDetails.fieldName;
        }
        dispatch(errorSendingInvitation(errDetailsFieldName, errDetails));
      }
    );
  };
}

export function switchUserStatusEvent() {
  return (dispatch, getState) => {
    dispatch(savingUser());

    const { userId } = getState().adminHome.user.switchUserStatus;
    const user = new User(userId);

    console.log('[action]', user);

    BackendProxy.saveOrActivateUser(
      user,
      'switchStatus',
      respData => {
        console.log('OK, Switched User: ', respData);
        const { user } = respData;
        let savedStatus = '';
        setTimeout(() => {
          if (user.id !== null) {
            savedStatus = 'OK_SAVED';
            dispatch(openCloseActivationDialog(false, null));
          }
          dispatch(loadFilterUsers());
          dispatch(okSavedUser(user.id, savedStatus));

          dispatch(loadActiveMerchantUsers());
        }, 1000);
      },
      (errCode, errdesc, errDetails) => {
        let errDetailsFieldName = 'ERROR_SERVER';
        if (errDetails !== null) {
          errDetailsFieldName = errDetails.fieldName;
        }

        if (errDetails?.errorCode === 'INVALID_DEACTIVATION')
          dispatch(openCloseValidationErrorDialog(true));

        dispatch(errorSavingUser(errDetailsFieldName, errDetails));
      }
    );
  };
}

export function savingUser() {
  return {
    type: SAVING_USER,
  };
}

export function okSavedUser(id, savedStatus) {
  return {
    userId: id,
    savedStatus,
    type: USER_SAVED,
  };
}

export function errorSavingUser(errorCode, error) {
  return {
    errorCode,
    messageError: error,
    type: USER_ERROR_SAVED,
  };
}

export function openCloseCreateUserDialog(state, isToCreateUser) {
  return {
    type: OPENED_CLOSED_CREATE_USER_DIALOG,
    state,
    isToCreateUser,
  };
}

export function openCloseSubscriptionDialog(state) {
  return {
    type: OPENED_CLOSED_SUBSCRIPTION_DIALOG,
    state,
  };
}
export function openCloseActivationDialog(state, userId) {
  return {
    type: OPENED_CLOSED_ACTIVATION_DIALOG,
    state,
    userId,
  };
}

export function openCloseValidationErrorDialog(
  state,
  openValidationErrorDialog
) {
  return {
    type: OPENED_CLOSED_VALIDATION_ERROR_DIALOG,
    state,
    openValidationErrorDialog,
  };
}

export function cleanDialogFields() {
  return {
    type: CLEANED_DIALOG_FIELDS,
  };
}

//################################### ACTIVATE USER methods
export function activateUser(user) {
  return (dispatch, getState) => {
    dispatch(savingUser());

    BackendProxy.saveOrActivateUser(
      user,
      'activate',
      respData => {
        console.log('OK, Activated User: ', respData);
        const gotUser = respData.user;
        let savedStatus = '';
        dispatch(loginAuthentication(user.userName, user.password));
        savedStatus = 'OK_SAVED';
        dispatch(okSavedUser(gotUser.id, savedStatus));
      },
      (errCode, errdesc, errDetails) => {
        let errDetailsFieldName = 'ERROR_SERVER';
        if (errDetails !== null) {
          errDetailsFieldName = errDetails.fieldName;
        }
        dispatch(errorSavingUser(errDetailsFieldName, errDetails));
      }
    );
  };
}

export function changeFile(file) {
  return {
    type: LOAD_FILE,
    file,
  };
}

export function changeImagePreviewUrl(imagePreviewUrl) {
  return {
    type: LOAD_IMAGE_PREVIEW_URL,
    imagePreviewUrl,
  };
}

export function setImageError(newValue) {
  return {
    type: SET_IMAGE_ERROR,
    value: newValue,
  };
}

export function restoreFilters() {
  return (dispatch, getState) => {
    const newFilters = { ...getState().adminHome.user.toolbar.filters };
    newFilters.status = 'ACTIVE';
    newFilters.userValue = '';

    dispatch(loadingFilters(newFilters));
  };
}

export function resendInvitation() {
  return {
    type: RESEND_INVITATION,
  };
}
export function okSentInvitation(id, deliveryStatus) {
  return {
    userId: id,
    deliveryStatus,
    type: INVITATION_WAS_SENT,
  };
}

export function errorSendingInvitation(errorCode, error) {
  return {
    errorCode,
    messageError: error,
    type: RESEND_INVITATION_ERROR,
  };
}

export function closeResendInvitationDialog() {
  return {
    type: RESEND_INVITATION_CLOSE_DIALOG,
    openDialog: false,
  };
}
