export const EXPORT_CHART_DETAIL_REQUEST = 'EXPORT_CHART_DETAIL_REQUEST';
export const EXPORT_CHART_DETAIL_FAIL = 'EXPORT_CHART_DETAIL_FAIL';

const exportChartDetail = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_CHART_DETAIL_REQUEST:
      return { ...state, success: action.data, status: true };
    case EXPORT_CHART_DETAIL_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
export default exportChartDetail;
