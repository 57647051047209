import B2ChatClient from '@client-sdk';
import {
  cancelChatTransfer,
  requestChatTransfer,
} from '@src/reducers/chats/chatTransfer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { call, put, select, takeLeading } from '@redux-saga/core/effects';
import { chatByIdSelector } from '@src/selectors/chats';
import { ChatTray } from '@src/types/chats';
import { B2ChatAPI } from '@types-api';

export default function* chatTrasfer() {
  // eslint-disable-next-line func-names
  yield takeLeading(requestChatTransfer, function* (action) {
    const { chatId } = action.payload;

    try {
      const response: B2ChatAPI.Response<string> = yield call(
        B2ChatClient.resources.chatTransfer.actions.request,
        {
          data: {
            chatId,
            sourceAgent: action.payload.sourceAgent.id,
            targetAgent: action.payload.targetAgent.id,
            targetBizProcessId: action.payload.targetBizProcessNode.id,
            targetBizProcessCode: action.payload.targetBizProcessNode.code,
            targetBizProcessName: action.payload.targetBizProcessNode.name,
            comments: action.payload.comments,
          },
        }
      );
      if (response.error) throw response.error;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(cancelChatTransfer(chatId));
    }
  });

  // eslint-disable-next-line func-names
  yield takeLeading(cancelChatTransfer, function* (action) {
    const chatId = action.payload;
    const chat: ChatTray = yield select(chatByIdSelector(chatId));
    if (!chat?.chatXferRequest) return;

    try {
      const response: B2ChatAPI.Response<string> = yield call(
        B2ChatClient.resources.chatTransfer.actions.cancel,
        {
          data: chatId,
          headers: {
            'Content-Type': 'application/json',
          },
          transformRequest: data => data,
        }
      );
      if (response.error) throw response.error;
    } catch {
      yield put(cancelChatTransfer(chatId));
    }
  });
}
