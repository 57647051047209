export const KEENIO_KEY_REQUEST = 'KEENIO_KEY_REQUEST';
export const KEENIO_KEY_FAIL = 'KEENIO_KEY_FAIL';

const getKeenioApiKey = (state = {}, action) => {
  switch (action.type) {
    case KEENIO_KEY_REQUEST:
      return { ...state, success: action.data, status: true };
    case KEENIO_KEY_FAIL:
      return { ...state, failure: action.data, status: false };
    default:
      return state;
  }
};
// const loginAuthenticationReducer = combineReducers({
//   loginAuthentication:loginAuthentication
// });

export default getKeenioApiKey;
