export const STORE_LIST_BOTS = 'FAQCOMMONS.STORE_LIST_BOTS';
export const SELECT_BOT_VALUE = 'FAQCOMMONS.SELECT_BOT_VALUE';
export const CONSERVATE_BOT_VALUE = 'FAQCOMMONS.CONSERVATE_BOT_VALUE';

export function conservateBotValue() {
  return {
    type: CONSERVATE_BOT_VALUE,
  };
}

export function storeBotId(botValueAux) {
  return {
    type: SELECT_BOT_VALUE,
    botValue: botValueAux,
  };
}

export function storeBotsData(botsData) {
  return {
    type: STORE_LIST_BOTS,
    listBots: botsData,
  };
}
