const images = [
  'sticker_1.webp',
  'sticker_2.webp',
  'sticker_3.webp',
  'sticker_4.webp',
  'sticker_5.webp',
  'sticker_6.webp',
  'sticker_7.webp',
  'sticker_8.webp',
  'sticker_9.webp',
  'sticker_10.webp',
  'sticker_11.webp',
  'sticker_12.webp',
  'sticker_13.webp',
  'sticker_14.webp',
  'sticker_15.webp',
  'sticker_16.webp',
  'sticker_17.webp',
  'sticker_18.webp',
  'sticker_19.webp',
  'sticker_20.webp',
  'sticker_21.webp',
  'sticker_22.webp',
  'sticker_23.webp',
  'sticker_24.webp',
  'sticker_25.webp',
  'sticker_26.webp',
  'sticker_27.webp',
  'sticker_28.webp',
  'sticker_29.webp',
  'sticker_30.webp',
];

const genData = images.map((img, ind) => ({
  imgUrl: `/resources/stickers/me-emoji/${img}`,
  id: `me-emoji-${ind + 1}.custom`,
  names: ['me', 'emoji'],
}));

export default genData;
