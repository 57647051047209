import browserDetect from 'browser-detect';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { injectIntl } from 'react-intl';

export const BrowserDetect = injectIntl(({ intl: { formatMessage } }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTimeout(() => {
      const browser = browserDetect();

      // eslint-disable-next-line no-console
      console.log('BROWSER', browser);

      if (browser.name === 'chrome') {
        if ((browser.versionNumber || Infinity) < 84) showAlert();
        return;
      }

      if (browser.name === 'firefox') {
        if ((browser.versionNumber || Infinity) < 80) showAlert();
        return;
      }
      showAlert();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showAlert() {
    enqueueSnackbar(
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            { id: 'BrowserDetect.browserAlert' },
            {},
            { ignoreTag: true }
          ),
        }}
      />,
      {
        variant: 'warning',
        autoHideDuration: 10_000,
      }
    );
  }

  return null;
});

export default BrowserDetect;
