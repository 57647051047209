export const SendIcon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9405 1.00007L1.39884 7.2334C0.723843 7.52507 0.723843 8.47507 1.39884 8.76673L15.9405 15.0001C16.4905 15.2417 17.0988 14.8334 17.0988 14.2417L17.1072 10.4001C17.1072 9.9834 16.7988 9.62507 16.3822 9.57507L4.60718 8.00007L16.3822 6.4334C16.7988 6.37507 17.1072 6.01673 17.1072 5.60007L17.0988 1.7584C17.0988 1.16673 16.4905 0.758401 15.9405 1.00007Z"
      fill="#4C33FF"
    />
  </svg>
);

export const FilterIcon = (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.1667V13.8333H5V12.1667H0ZM0 2.16667V3.83333H8.33333V2.16667H0ZM8.33333 15.5V13.8333H15V12.1667H8.33333V10.5H6.66667V15.5H8.33333ZM3.33333 5.5V7.16667H0V8.83333H3.33333V10.5H5V5.5H3.33333ZM15 8.83333V7.16667H6.66667V8.83333H15ZM10 5.5H11.6667V3.83333H15V2.16667H11.6667V0.5H10V5.5Z"
      fill="#323444"
    />
  </svg>
);

export const ShoppingCartIcon = (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5311 7.66665C11.0311 7.66665 11.4711 7.39331 11.6977 6.97998L14.0844 2.65331C14.3311 2.21331 14.0111 1.66665 13.5044 1.66665H3.63772L3.01105 0.333313H0.831055V1.66665H2.16439L4.56439 6.72665L3.66439 8.35331C3.17772 9.24665 3.81772 10.3333 4.83105 10.3333H12.8311V8.99998H4.83105L5.56439 7.66665H10.5311ZM4.27105 2.99998H12.3711L10.5311 6.33331H5.85105L4.27105 2.99998ZM4.83105 11C4.09772 11 3.50439 11.6 3.50439 12.3333C3.50439 13.0666 4.09772 13.6666 4.83105 13.6666C5.56439 13.6666 6.16439 13.0666 6.16439 12.3333C6.16439 11.6 5.56439 11 4.83105 11ZM11.4977 11C10.7644 11 10.1711 11.6 10.1711 12.3333C10.1711 13.0666 10.7644 13.6666 11.4977 13.6666C12.2311 13.6666 12.8311 13.0666 12.8311 12.3333C12.8311 11.6 12.2311 11 11.4977 11Z"
      fill="white"
    />
  </svg>
);

export const ArrowLeftIcon = (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.2095 0.404181C8.80117 -0.00415243 8.14284 -0.00415243 7.7345 0.404181L0.726172 7.41251C0.401172 7.73751 0.401172 8.26252 0.726172 8.58751L7.7345 15.5958C8.14284 16.0042 8.80117 16.0042 9.2095 15.5958C9.61784 15.1875 9.61784 14.5292 9.2095 14.1208L3.09284 8.00418L9.21784 1.87918C9.61784 1.47085 9.61784 0.812514 9.2095 0.404181Z"
      fill="#71717A"
    />
  </svg>
);

export const ArrowRightIcon = (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.831714 15.5125C1.24005 15.9208 1.89838 15.9208 2.30671 15.5125L9.23171 8.58749C9.55671 8.26249 9.55671 7.73749 9.23171 7.41249L2.30671 0.487494C1.89838 0.0791606 1.24005 0.0791606 0.831714 0.487494C0.423381 0.895827 0.423381 1.55416 0.831714 1.96249L6.86505 8.00416L0.82338 14.0458C0.42338 14.4458 0.423381 15.1125 0.831714 15.5125Z"
      fill="#71717A"
    />
  </svg>
);

export const GoBackIcon = (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
      fill="black"
    />
  </svg>
);

export const NotFoundIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.0003 0.666626C15.6003 0.666626 0.666992 15.6 0.666992 34C0.666992 52.4 15.6003 67.3333 34.0003 67.3333C52.4003 67.3333 67.3337 52.4 67.3337 34C67.3337 15.6 52.4003 0.666626 34.0003 0.666626ZM34.0003 60.6666C19.267 60.6666 7.33366 48.7333 7.33366 34C7.33366 27.8333 9.43366 22.1666 12.967 17.6666L50.3337 55.0333C45.8337 58.5666 40.167 60.6666 34.0003 60.6666ZM55.0337 50.3333L17.667 12.9666C22.167 9.43329 27.8337 7.33329 34.0003 7.33329C48.7337 7.33329 60.667 19.2666 60.667 34C60.667 40.1666 58.567 45.8333 55.0337 50.3333Z"
      fill="#323444"
    />
  </svg>
);

export const RemoveIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.15035 0.854985C6.95535 0.659985 6.64035 0.659985 6.44535 0.854985L4.00035 3.29499L1.55535 0.849985C1.36035 0.654985 1.04535 0.654985 0.850352 0.849985C0.655352 1.04499 0.655352 1.35999 0.850352 1.55499L3.29535 3.99999L0.850352 6.44498C0.655352 6.63998 0.655352 6.95498 0.850352 7.14998C1.04535 7.34498 1.36035 7.34498 1.55535 7.14998L4.00035 4.70498L6.44535 7.14998C6.64035 7.34498 6.95535 7.34498 7.15035 7.14998C7.34535 6.95498 7.34535 6.63998 7.15035 6.44498L4.70535 3.99999L7.15035 1.55499C7.34035 1.36499 7.34035 1.04499 7.15035 0.854985Z"
      fill="#323444"
    />
  </svg>
);

export const ShowMoreIcon = (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.665762 13.01C0.992428 13.3367 1.51909 13.3367 1.84576 13.01L7.38576 7.46999C7.64576 7.20999 7.64576 6.78999 7.38576 6.52999L1.84576 0.989995C1.51909 0.663328 0.992428 0.663328 0.665762 0.989995C0.339095 1.31666 0.339095 1.84333 0.665762 2.17L5.49243 7.00333L0.659095 11.8367C0.339095 12.1567 0.339095 12.69 0.665762 13.01Z"
      fill="#4C33FF"
    />
  </svg>
);

export const EmptyShoppingCartIcon = (
  <svg
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.58379 0.883301L0.883789 5.5833L15.5171 20.2166L22.8838 35.75L18.3838 43.9166C17.8505 44.85 17.5505 45.95 17.5505 47.1166C17.5505 50.7833 20.5505 53.7833 24.2171 53.7833H49.0838L53.6838 58.3833C52.0171 59.5833 50.9171 61.55 50.9171 63.7833C50.9171 67.45 53.8838 70.45 57.5505 70.45C59.7838 70.45 61.7505 69.35 62.9505 67.65L72.4171 77.1166L77.1171 72.4166L5.58379 0.883301ZM24.2171 47.1166L27.8838 40.45H35.7505L42.4171 47.1166H24.2171ZM67.5505 10.45H24.6171L31.2838 17.1166H61.9171L52.7171 33.7833H47.9171L54.3838 40.25C56.1838 39.7833 57.6838 38.6166 58.5505 37.0166L70.4838 15.3833C71.7171 13.1833 70.0838 10.45 67.5505 10.45ZM24.2171 57.1166C20.5505 57.1166 17.5838 60.1166 17.5838 63.7833C17.5838 67.45 20.5505 70.45 24.2171 70.45C27.8838 70.45 30.8838 67.45 30.8838 63.7833C30.8838 60.1166 27.8838 57.1166 24.2171 57.1166Z"
      fill="#323444"
    />
  </svg>
);

export const ShoppingCartBlueIcon = (
  <svg
    width="88"
    height="80"
    viewBox="0 0 88 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1220_11132)">
      <rect x="8" width="72" height="72" rx="36" fill="#4C33FF" />
      <path
        d="M51.5771 37.6667C52.8271 37.6667 53.9271 36.9834 54.4938 35.95L60.4605 25.1334C61.0771 24.0334 60.2771 22.6667 59.0105 22.6667H34.3438L32.7771 19.3334H27.3271V22.6667H30.6605L36.6605 35.3167L34.4105 39.3834C33.1938 41.6167 34.7938 44.3334 37.3271 44.3334H57.3271V41H37.3271L39.1605 37.6667H51.5771ZM35.9271 26H56.1771L51.5771 34.3334H39.8771L35.9271 26ZM37.3271 46C35.4938 46 34.0105 47.5 34.0105 49.3334C34.0105 51.1667 35.4938 52.6667 37.3271 52.6667C39.1605 52.6667 40.6605 51.1667 40.6605 49.3334C40.6605 47.5 39.1605 46 37.3271 46ZM53.9938 46C52.1605 46 50.6771 47.5 50.6771 49.3334C50.6771 51.1667 52.1605 52.6667 53.9938 52.6667C55.8271 52.6667 57.3271 51.1667 57.3271 49.3334C57.3271 47.5 55.8271 46 53.9938 46Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1220_11132"
        x="0"
        y="0"
        width="88"
        height="88"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1220_11132"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1220_11132"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CounterMinusIcon = (
  <svg
    width="8"
    height="2"
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24967 1.54171H0.749674C0.451758 1.54171 0.208008 1.29796 0.208008 1.00004C0.208008 0.702124 0.451758 0.458374 0.749674 0.458374H7.24967C7.54759 0.458374 7.79134 0.702124 7.79134 1.00004C7.79134 1.29796 7.54759 1.54171 7.24967 1.54171Z"
      fill="#71717A"
    />
  </svg>
);

export const DividerPriceRangeIcon = (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2H1C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0H13C13.55 0 14 0.45 14 1C14 1.55 13.55 2 13 2Z"
      fill="#323444"
    />
  </svg>
);

export const CounterPlusIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24967 4.54171H4.54134V7.25004C4.54134 7.54796 4.29759 7.79171 3.99967 7.79171C3.70176 7.79171 3.45801 7.54796 3.45801 7.25004V4.54171H0.749674C0.451758 4.54171 0.208008 4.29796 0.208008 4.00004C0.208008 3.70212 0.451758 3.45837 0.749674 3.45837H3.45801V0.750041C3.45801 0.452124 3.70176 0.208374 3.99967 0.208374C4.29759 0.208374 4.54134 0.452124 4.54134 0.750041V3.45837H7.24967C7.54759 3.45837 7.79134 3.70212 7.79134 4.00004C7.79134 4.29796 7.54759 4.54171 7.24967 4.54171Z"
      fill="#71717A"
    />
  </svg>
);

export const CleanPricesIcon = (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66667 7.33329H8V1.99996C8 1.26663 7.4 0.666626 6.66667 0.666626H5.33333C4.6 0.666626 4 1.26663 4 1.99996V7.33329H3.33333C1.49333 7.33329 0 8.82663 0 10.6666V15.3333H12V10.6666C12 8.82663 10.5067 7.33329 8.66667 7.33329ZM5.33333 1.99996H6.66667V7.33329H5.33333V1.99996ZM10.6667 14H9.33333V12C9.33333 11.6333 9.03333 11.3333 8.66667 11.3333C8.3 11.3333 8 11.6333 8 12V14H6.66667V12C6.66667 11.6333 6.36667 11.3333 6 11.3333C5.63333 11.3333 5.33333 11.6333 5.33333 12V14H4V12C4 11.6333 3.7 11.3333 3.33333 11.3333C2.96667 11.3333 2.66667 11.6333 2.66667 12V14H1.33333V10.6666C1.33333 9.56663 2.23333 8.66663 3.33333 8.66663H8.66667C9.76667 8.66663 10.6667 9.56663 10.6667 10.6666V14Z"
      fill="#323444"
    />
  </svg>
);

export const RemoveItemIcon = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.20047 0.806647C8.94047 0.546647 8.52047 0.546647 8.26047 0.806647L5.00047 4.05998L1.74047 0.79998C1.48047 0.53998 1.06047 0.53998 0.800469 0.79998C0.540469 1.05998 0.540469 1.47998 0.800469 1.73998L4.06047 4.99998L0.800469 8.25998C0.540469 8.51998 0.540469 8.93998 0.800469 9.19998C1.06047 9.45998 1.48047 9.45998 1.74047 9.19998L5.00047 5.93998L8.26047 9.19998C8.52047 9.45998 8.94047 9.45998 9.20047 9.19998C9.46047 8.93998 9.46047 8.51998 9.20047 8.25998L5.94047 4.99998L9.20047 1.73998C9.4538 1.48665 9.4538 1.05998 9.20047 0.806647Z"
      fill="#323444"
    />
  </svg>
);

export const addProductIcon = (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1663 5.66671H6.16634V9.66671H4.83301V5.66671H0.833008V4.33337H4.83301V0.333374H6.16634V4.33337H10.1663V5.66671Z"
      fill="#4C33FF"
    />
  </svg>
);

export const SendBlackIcon = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.34 2.02L6.34667 4.16667L1.33333 3.5L1.34 2.02ZM6.34 7.83333L1.33333 9.98V8.5L6.34 7.83333ZM0.00666666 0L0 4.66667L10 6L0 7.33333L0.00666666 12L14 6L0.00666666 0Z"
      fill="#323444"
    />
  </svg>
);

export const ShopifyIcon: React.FC<{ size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={size}
    height={size}
  >
    <path
      fill="#7cb342"
      d="M37.216,11.78c-0.023-0.211-0.211-0.305-0.351-0.305s-3.21-0.234-3.21-0.234s-2.132-2.132-2.39-2.343	c-0.234-0.234-0.68-0.164-0.867-0.117c-0.023,0-0.469,0.141-1.195,0.375c-0.726-2.086-1.968-3.984-4.194-3.984h-0.211	C24.187,4.375,23.391,4,22.735,4c-5.155,0-7.639,6.444-8.412,9.725c-2.015,0.633-3.445,1.054-3.609,1.125	c-1.125,0.351-1.148,0.375-1.289,1.429c-0.117,0.797-3.046,23.456-3.046,23.456L29.179,44l12.373-2.671	C41.575,41.282,37.24,11.991,37.216,11.78z M27.937,9.483c-0.562,0.164-1.242,0.375-1.921,0.609V9.671	c0-1.265-0.164-2.296-0.469-3.117C26.718,6.695,27.445,7.984,27.937,9.483L27.937,9.483z M24.117,6.812	c0.305,0.797,0.516,1.922,0.516,3.468v0.234c-1.265,0.398-2.601,0.797-3.984,1.242C21.422,8.804,22.899,7.351,24.117,6.812	L24.117,6.812z M22.617,5.359c0.234,0,0.469,0.094,0.656,0.234c-1.664,0.773-3.421,2.718-4.148,6.655	c-1.101,0.351-2.156,0.656-3.163,0.984C16.806,10.233,18.915,5.359,22.617,5.359z"
    />
    <path
      fill="#558b2f"
      d="M36.865,11.428c-0.141,0-3.21-0.234-3.21-0.234s-2.132-2.132-2.39-2.343	C31.17,8.757,31.053,8.71,30.96,8.71L29.249,44l12.373-2.671c0,0-4.335-29.338-4.359-29.549	C37.169,11.569,37.005,11.475,36.865,11.428z"
    />
    <path
      fill="#fff"
      d="M24.792,18.593l-1.475,4.449c0,0-1.337-0.715-2.927-0.715c-2.374,0-2.489,1.498-2.489,1.867	c0,2.028,5.301,2.812,5.301,7.583c0,3.757-2.374,6.177-5.578,6.177c-3.872,0-5.808-2.397-5.808-2.397l1.037-3.411	c0,0,2.028,1.752,3.734,1.752c1.129,0,1.59-0.876,1.59-1.521c0-2.651-4.333-2.766-4.333-7.145c0-3.665,2.628-7.214,7.952-7.214	C23.777,17.994,24.792,18.593,24.792,18.593z"
    />
  </svg>
);

export const ShopifyShoppingIcon = (isActive: boolean) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3636 8.29092H11.4545C11.4545 6.1211 9.74591 4.36365 7.63636 4.36365C5.52682 4.36365 3.81818 6.1211 3.81818 8.29092H1.90909C0.859091 8.29092 0 9.17456 0 10.2546V22.0364C0 23.1164 0.859091 24 1.90909 24H13.3636C14.4136 24 15.2727 23.1164 15.2727 22.0364V10.2546C15.2727 9.17456 14.4136 8.29092 13.3636 8.29092ZM5.72727 12.2182C5.72727 12.7582 5.29773 13.2 4.77273 13.2C4.24773 13.2 3.81818 12.7582 3.81818 12.2182V10.2546H5.72727V12.2182ZM7.63636 6.32728C8.68636 6.32728 9.54545 7.21092 9.54545 8.29092H5.72727C5.72727 7.21092 6.58636 6.32728 7.63636 6.32728ZM11.4545 12.2182C11.4545 12.7582 11.025 13.2 10.5 13.2C9.975 13.2 9.54545 12.7582 9.54545 12.2182V10.2546H11.4545V12.2182Z"
      fill={isActive ? '#4C33FF' : '#71717A'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9433 5.16044L19.3645 3.92761C19.3645 3.92761 19.0799 3.76941 18.5064 3.76941C17.0261 3.76941 16.2892 4.75132 16.2892 5.76595C16.2892 6.97151 17.5013 7.00424 17.5013 7.73521C17.5013 7.91522 17.3757 8.15524 17.0649 8.15524C16.5891 8.15524 16.0265 7.6752 16.0265 7.6752L15.737 8.61892C15.737 8.61892 16.2892 9.27898 17.3598 9.27898C18.2551 9.27898 18.916 8.61347 18.916 7.57701C18.916 6.2569 17.4412 6.04416 17.4412 5.47683C17.4412 5.37864 17.4745 4.96952 18.1295 4.96952C18.5774 4.96952 18.9433 5.16044 18.9433 5.16044ZM17.6652 2.09473C17.3866 2.1711 17.1845 2.40566 17.1517 2.68932C17.1304 2.91843 17.261 3.153 17.5341 3.13118C17.8072 3.10936 18.0257 2.85297 18.0803 2.60204C18.1404 2.33475 17.982 2.03472 17.6652 2.09473ZM18.4026 0.621872C18.2278 0.621872 18.0093 0.921352 17.9437 1.36375L18.8182 1.15101C18.7139 0.801887 18.5282 0.621872 18.4026 0.621872ZM19.4568 1.11282C19.6316 1.16192 19.796 1.27102 19.8992 1.42921L20.7841 2.59113C20.8715 2.7166 20.8912 2.84097 20.8824 3.01662L20.7568 5.65685L20.5766 9.3008L20.5225 10.37C20.5225 10.4409 20.5165 10.5118 20.5055 10.5773C20.4569 10.8446 20.2275 10.9428 19.9762 10.8991L17.0261 10.3482L15.2508 10.0154C14.9723 9.96631 14.6664 9.93358 14.3933 9.85721C14.0655 9.76393 14.2075 9.25716 14.2409 9.01168L14.437 7.49519L14.8849 4.09672C14.9018 3.98216 14.9122 3.8676 14.9286 3.75305C14.9559 3.60031 15.0214 3.45302 15.1143 3.32756L16.0319 2.02381C16.1308 1.87107 16.2892 1.76197 16.4689 1.72378L17.1304 1.56013L17.2937 1.52195C17.3429 0.660057 17.8072 0 18.4086 0C18.8996 0 19.3093 0.469131 19.4568 1.11282ZM20.7364 1.091L22.853 2.51803C22.9448 2.60368 23.0038 2.71932 23.0223 2.84424L23.9897 9.94013C24.0165 10.0465 23.9897 10.1589 23.9154 10.2396C23.8444 10.3225 23.7346 10.3634 23.6275 10.3493L21.6562 10.1474L21.6917 9.40608L21.8731 5.66121L22.0102 2.82024C22.0151 2.72096 21.9829 2.62332 21.92 2.54585L20.7364 1.091Z"
      fill={isActive ? '#A1BE52' : '#71717A'}
    />
  </svg>
);

export const OrderHistoryIcon = (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 4H9.16667C9.53333 4 9.83333 3.7 9.83333 3.33333C9.83333 2.96667 9.53333 2.66667 9.16667 2.66667H6.5C6.13333 2.66667 5.83333 2.96667 5.83333 3.33333C5.83333 3.7 6.13333 4 6.5 4ZM6.5 6.66667H9.16667C9.53333 6.66667 9.83333 6.36667 9.83333 6C9.83333 5.63333 9.53333 5.33333 9.16667 5.33333H6.5C6.13333 5.33333 5.83333 5.63333 5.83333 6C5.83333 6.36667 6.13333 6.66667 6.5 6.66667ZM6.5 9.33333H9.16667C9.53333 9.33333 9.83333 9.03333 9.83333 8.66667C9.83333 8.3 9.53333 8 9.16667 8H6.5C6.13333 8 5.83333 8.3 5.83333 8.66667C5.83333 9.03333 6.13333 9.33333 6.5 9.33333ZM3.16667 2.66667H4.5V4H3.16667V2.66667ZM3.16667 5.33333H4.5V6.66667H3.16667V5.33333ZM3.16667 8H4.5V9.33333H3.16667V8ZM11.8333 0H1.16667C0.8 0 0.5 0.3 0.5 0.666667V11.3333C0.5 11.7 0.8 12 1.16667 12H11.8333C12.2 12 12.5 11.7 12.5 11.3333V0.666667C12.5 0.3 12.2 0 11.8333 0ZM11.1667 10.6667H1.83333V1.33333H11.1667V10.6667Z"
      fill="#323444"
    />
  </svg>
);

export const OrdersWarningIcon = (
  <svg
    width="64"
    height="58"
    viewBox="0 0 64 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.9998 7.31251L57.0998 50.6792H6.89975L31.9998 7.31251ZM1.13309 47.3458C-1.43358 51.7792 1.76642 57.3458 6.89975 57.3458H57.0998C62.2331 57.3458 65.4331 51.7792 62.8664 47.3458L37.7664 3.97917C35.1997 -0.454158 28.7998 -0.454158 26.2331 3.97917L1.13309 47.3458ZM28.6664 24.0125V30.6792C28.6664 32.5125 30.1664 34.0125 31.9998 34.0125C33.8331 34.0125 35.3331 32.5125 35.3331 30.6792V24.0125C35.3331 22.1792 33.8331 20.6792 31.9998 20.6792C30.1664 20.6792 28.6664 22.1792 28.6664 24.0125ZM28.6664 40.6792H35.3331V47.3458H28.6664V40.6792Z"
      fill="#FACC15"
    />
  </svg>
);

export const CopyIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 14.5H6.5C6.0875 14.5 5.73438 14.3531 5.44063 14.0594C5.14688 13.7656 5 13.4125 5 13V2.5C5 2.0875 5.14688 1.73438 5.44063 1.44063C5.73438 1.14687 6.0875 1 6.5 1H11.75L16.25 5.5V13C16.25 13.4125 16.1031 13.7656 15.8094 14.0594C15.5156 14.3531 15.1625 14.5 14.75 14.5ZM11 6.25V2.5H6.5V13H14.75V6.25H11ZM3.5 17.5C3.0875 17.5 2.73438 17.3531 2.44063 17.0594C2.14687 16.7656 2 16.4125 2 16V5.5H3.5V16H11.75V17.5H3.5Z"
      fill="#323444"
    />
  </svg>
);

export default SendIcon;
