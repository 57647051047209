/* eslint-disable */
import { LoginAuthentication } from '@types';
import _ from 'lodash';
import {
  FAILED_RELOADING_USER,
  LOGGED_IN_USER_RELOADED,
  LOGIN_AUTHENTICATION_FAIL,
  LOGIN_AUTHENTICATION_REQUEST,
  LOGIN_START,
  STARTED_RELOADING_USER,
  loginAuthenticationFulfill,
} from '../actions/loginAuthenticationAction';

// @ts-ignore
const loginAuthentication = (state: LoginAuthentication = {}, action) => {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, isLoggingIn: true };

    case LOGIN_AUTHENTICATION_REQUEST:
      return {
        ...state,
        success: action.data,
        status: true,
        isLoggingIn: false,
      };
    case LOGIN_AUTHENTICATION_FAIL:
      return {
        ...state,
        failure: action.data,
        status: false,
        isLoggingIn: false,
      };
    case loginAuthenticationFulfill.type:
      return { ...state, failure: null, status: true, isLoggingIn: false };

    case LOGGED_IN_USER_RELOADED:
      if (_.isNil(action.user)) {
        return state;
      }

      // Update fields of the user that got reloaded
      const updatedUser = {
        ...state.success,
        businessProcesses: action.user.businessProcesses,
      };

      return { ...state, success: updatedUser, isUserReloading: false };
    case STARTED_RELOADING_USER:
      return { ...state, isUserReloading: true };
    case FAILED_RELOADING_USER:
      return { ...state, isUserReloading: false };
    default:
      return state;
  }
};

export default loginAuthentication;
