import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserLoginState } from '@src/components/AgentSelect/types';

import type { ErrorData } from '@types';

type Loading = 'fetch-audit-users';

export type AuditUsersState = {
  error: ErrorData | null;
  loading: Loading[];
  userLoginStates: UserLoginState[];
};

type AuditUsersReducer<P = void> = (
  state: AuditUsersState,
  action: PayloadAction<P>
) => void;

const initialState: AuditUsersState = {
  error: null,
  loading: [],
  userLoginStates: [],
};

const handleFetchAuditUsers: AuditUsersReducer = state => {
  state.loading.push('fetch-audit-users');
  state.error = null;
};

const handleFetchAuditUsersSuccess: AuditUsersReducer<UserLoginState[]> = (
  state,
  action
) => {
  state.userLoginStates = action.payload;
};

const handleFetchAuditUsersFailure: AuditUsersReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchAuditUsersFulfill: AuditUsersReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-audit-users');
};

const auditUsersSlice = createSlice({
  name: 'auditUsersSlice',
  initialState,
  reducers: {
    fetchAuditUsers: handleFetchAuditUsers,
    fetchAuditUsersSuccess: handleFetchAuditUsersSuccess,
    fetchAuditUsersFailure: handleFetchAuditUsersFailure,
    fetchAuditUsersFulfill: handleFetchAuditUsersFulfill,
  },
});

const { actions, reducer } = auditUsersSlice;

export const {
  fetchAuditUsers,
  fetchAuditUsersSuccess,
  fetchAuditUsersFailure,
  fetchAuditUsersFulfill,
} = actions;

export default reducer;
