// eslint-disable-next-line import/no-cycle
import { RootState } from '@types';

export const preferencesSelect = (state: RootState) =>
  state.generalProperties?.preferences;

export const consolePreferencesSelect = (state: RootState) =>
  state.generalProperties?.preferences?.agentConsoleConfig;

export const showChatWithoutDeptToAllAgentsPreferencesSelect = (
  state: RootState
) => {
  const showChatWithoutDeptToAllAgentss =
    state.generalProperties?.preferences?.agentConsoleConfig
      ?.showChatWithoutDeptToAllAgents;

  return showChatWithoutDeptToAllAgentss ?? true;
};

export const defaultStandardBotIdSelector = (state: RootState) =>
  state.generalProperties?.defaultStandardBotId;
