/**
 *
 * @type { <Fn extends (...args: any) => any>(fn: Fn, wait: number[]) => Fn
 *  & { cache: { args: Parameters<Fn>, result: ReturnType<Fn> }, restartWaitStep(delay?: number): void, wait: number } }
 */
function incrementalThrottle(fn, wait) {
  /**
   * @type {NodeJS.Timeout}
   */
  let timer = 0;
  const cache = {
    args: [],
    result: undefined,
  };
  let waitStep = 0;

  const callFn = () => {
    waitStep += waitStep + 1 < wait.length ? 1 : 0;
    timer = 0;
    cache.result = fn(...cache.args);
  };

  const throttled = (...args) => {
    cache.args = args;

    if (!timer) {
      if (wait[waitStep] === 0) {
        callFn();
      } else {
        timer = setTimeout(callFn, wait[waitStep]);
      }
    }

    return cache.result;
  };

  let timerRestartWaitStep = 0;

  return Object.defineProperties(throttled, {
    cache: {
      get() {
        return cache;
      },
    },
    restartWaitStep: {
      value(delay = 0) {
        clearTimeout(timerRestartWaitStep);
        if (!delay) {
          waitStep = 0;
        } else {
          timerRestartWaitStep = setTimeout(() => void (waitStep = 0), delay);
        }
      },
      writable: false,
    },
    wait: {
      get() {
        return wait[waitStep];
      },
    },
  });
}

export default incrementalThrottle;
