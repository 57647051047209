import B2ChatClient from '@client-sdk';
import { chatSearchActions } from '@src/reducers/chatSearch';
import {
  FilterBotAccounts,
  FilterDepartments,
  FilterTags,
} from '@src/types/chatsSearch';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchDepartmentsSaga() {
  try {
    const response: B2ChatAPI.Response<FilterDepartments> = yield call(
      B2ChatClient.resources.departments.actions.assignedNodes
    );
    if (response.error) throw response.error;
    yield put(chatSearchActions.fetchDepartmentsSuccess(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('departments request error', error);
  }
}

function* fetchTagsSaga() {
  try {
    const response: B2ChatAPI.Response<FilterTags> = yield call(
      B2ChatClient.resources.tags.actions.tagsInAssignedDepartments
    );
    if (response.error) throw response.error;
    yield put(chatSearchActions.fetchTagsSuccess(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('tags request error', error);
  }
}

function* fetchBotAccountsSaga() {
  try {
    const response: B2ChatAPI.Response<FilterBotAccounts> = yield call(
      B2ChatClient.resources.merchant.actions.allAccounts
    );
    if (response.error) throw response.error;
    yield put(chatSearchActions.fetchBotAccountsSuccess(response.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('bot accounts request error', error);
  }
}

function* ChatsSearchSaga() {
  yield takeLatest(chatSearchActions.fetchDepartments, fetchDepartmentsSaga);
  yield takeLatest(chatSearchActions.fetchTags, fetchTagsSaga);
  yield takeLatest(chatSearchActions.fetchBotAccounts, fetchBotAccountsSaga);
}

export default ChatsSearchSaga;
