/* eslint-disable arrow-body-style */
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { ChatsOnHold } from '@src/types/chats';

export const updateChatConversationAction = createAction<{
  chatId: string;
  lastWAConversationStartedAt: number;
  waConversationsCounter: number;
}>('NEW_CONVERSATION');

export const updateChatConversationBuilder = (
  builder: ActionReducerMapBuilder<{
    chatsOnHold: ChatsOnHold;
  }>
) => {
  builder.addCase(updateChatConversationAction, (state, action) => {
    const { chatId, lastWAConversationStartedAt, waConversationsCounter } =
      action.payload;

    const chat = state?.chatsOnHold?.tray?.find(({ id }) => id === chatId);
    if (chat) {
      chat.lastWAConversationStartedAt = lastWAConversationStartedAt;
      chat.waConversationsCounter = waConversationsCounter;
    }
  });
};
